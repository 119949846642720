import React from 'react';

import { ITabProps } from '../CaseTabConfig';
import { Section } from '../../../common';

import OtherUpdatesSection from './OtherUpdatesSection';
import YourUpdateSection from './YourUpdateSection';

import styles from './StatusTab.module.less';

function StatusTab (props: ITabProps) {
  const { _case } = props;

  return (
    <Section className={styles.root}>
      <YourUpdateSection _case={_case} />
      <OtherUpdatesSection _case={_case} />
    </Section>
  );
}

export default StatusTab;
