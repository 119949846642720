import React, { Component } from 'react';
import autoBindMethods from 'class-autobind-decorator';
import { inject, observer } from 'mobx-react';
import { observable } from 'mobx';

import { ObjectSearch } from '@mighty-justice/fields-ant';
import SmartBool from '@mighty-justice/smart-bool';

import StoresClass from '../../../stores/StoresClass';
import { ISettingLegalOrganization } from '../../../models/User';

import { getMultiSelectLawFirmFieldConfig, multiSelectSelectProps } from './multiSelectConfig';

interface IProps {
  onChange: (value: ISettingLegalOrganization[]) => Promise<void>;
  value: ISettingLegalOrganization[];
}

interface IInjected extends IProps {
  stores: StoresClass;
}

@inject('stores')
@autoBindMethods
@observer
class MultiSelectLawFirmSearch extends Component<IProps> {
  @observable private isLoading = new SmartBool();

  private get injected () { return this.props as IInjected; }

  private onChange (value: ISettingLegalOrganization[]) {
    const { onChange } = this.props;
    this.isLoading.until(onChange(value));
  }

  public render () {
    const { value } = this.props
      , { settings } = this.injected.stores
      , isLoading = settings.isLoading.isTrue || this.isLoading.isTrue
      // These are normally passed down from the Ant form input wrapper
      , manualFormProps: any = {
        disabled: isLoading,
        value,
      }
      ;

    return (
      <ObjectSearch
        fieldConfig={getMultiSelectLawFirmFieldConfig(this.injected.stores)}
        onChange={this.onChange}
        selectProps={{ ...multiSelectSelectProps, loading: isLoading }}
        {...manualFormProps}
      />
    );
  }
}

export default MultiSelectLawFirmSearch;
