import React, { Component } from 'react';
import { get } from 'lodash';
import { inject } from 'mobx-react';
import autoBindMethods from 'class-autobind-decorator';

import ConfirmationOnErrorModal, { IProps as IConfirmationModalProps } from '../common/ConfirmationOnErrorModal';
import StoresClass from '../../stores/StoresClass';
import { ILegalOrganization } from '../../models/Party';
import { Spacer } from '../common';

export const CONTACT_ERROR = 'matches a verified domain';

interface IProps extends Omit<IConfirmationModalProps, 'doesErrorMatch' | 'FormComponent'> {
  FormComponent?: IConfirmationModalProps['FormComponent'];
  dataPath?: string
  lawFirm?: ILegalOrganization | null;
}

interface IInjected extends IProps {
  stores: StoresClass;
}

@inject('stores')
@autoBindMethods
class ConfirmationOnContactErrorModal extends Component<IProps> {
  private get injected () { return this.props as IInjected; }

  private getErrorModalChildren (_error: any, model: any) {
    const { dataPath, lawFirm } = this.props
      , errorQuestion = 'Are you sure you want to add this contact?'
      , dataPrefix = dataPath ? `${dataPath}.` : ''
      , contactEmail = get(model, `${dataPrefix}contacts[0].email`, '')
      , lawFirmData = lawFirm || get(model, `${dataPrefix}law_firm`, '')
    ;

    // istanbul ignore next
    if (!lawFirmData) {
      return errorQuestion;
    }

    return (
      <p>
        This contact's email ({contactEmail}) does not appear to be associated with {lawFirmData.name}
        { lawFirmData.website && ` (${lawFirmData.website})` }.
        <Spacer />
        {errorQuestion}
      </p>
    );
  }

  private doesErrorMatch (error: Error) {
    const { dataPath } = this.props
      , errorPrefix = dataPath ? `.${dataPath}` : ''
      , errorMessage = get(error, `response.data${errorPrefix}.non_field_errors[0]`, '')
      ;

    return errorMessage.includes(CONTACT_ERROR);
  }

  public render () {
    const { stores: { users } } = this.injected;

    return (
      <ConfirmationOnErrorModal
        confirmOnError={!users.isLawFirmUser}
        doesErrorMatch={this.doesErrorMatch}
        getErrorModalChildren={this.getErrorModalChildren}
        { ...this.props }
      />
    );
  }
}

export default ConfirmationOnContactErrorModal;
