import React, { Component } from 'react';
import { observer } from 'mobx-react';
import autoBindMethods from 'class-autobind-decorator';

import { formatDate } from '@mighty-justice/utils';

import InfoCard from '../common/InfoCard';
import UpdateCaseButton from '../../common/UpdateCaseButton';
import { IDetailCase } from '../../../../models/Case';
import { CASE_ACTIVITY_TYPES, OPTION_KEYS } from '../../../../utils/constants';
import { formatOption } from '../../../../utils/utils';

import { CASE_STATUS_WITH_DATE, MISSING_LAW_FIRM, STATUS_UNAVAILABLE } from './constants';
import { caseExtraStatusDate } from './utils';

import { BLACKLISTED_LIENHOLDER_TOOLTIP, CaseIssueTooltip } from './StatusTooltips';

import styles from './StatusTab.module.less';

interface IProps {
  _case: IDetailCase;
  canUpdate?: boolean;
}

@autoBindMethods
@observer
class LawFirmStatusCard extends Component<IProps> {
  private get statusDisplay () {
    const { _case } = this.props
      , lienholderBlacklisted = _case.userLien?.is_lienholder_blacklisted
      , statusWithDate = _case.status && Object.keys(CASE_STATUS_WITH_DATE).includes(_case.status)
      , extraCaseStatusInfo = statusWithDate && caseExtraStatusDate(_case)
      ;

    if (_case.status && !lienholderBlacklisted) {
      return (
        <div className={styles['law-firm-status']}>
          <p className={extraCaseStatusInfo ? styles['status-with-date'] : null}>
            {formatOption(_case.status, OPTION_KEYS.CASE_STATUS)}
          </p>
          <p className={styles.extra}>{extraCaseStatusInfo && extraCaseStatusInfo}</p>
        </div>
      );
    }

    return (
      <div>
        {STATUS_UNAVAILABLE} {lienholderBlacklisted && <CaseIssueTooltip title={BLACKLISTED_LIENHOLDER_TOOLTIP} />}
      </div>
    );
  }

  public render () {
    const { _case, canUpdate } = this.props
      // TODO - or status no change?
      , lienholderBlacklisted = _case.userLien?.is_lienholder_blacklisted
      , latestUpdate = _case.latestActivity(CASE_ACTIVITY_TYPES.CASE_STATUS_UPDATED)
      , lawFirmDisplay = _case.law_firm?.name || MISSING_LAW_FIRM
      , createdByName = latestUpdate?.created_by_name
      , updatedBy = createdByName ? ` by ${createdByName}` : ''
      , showLastUpdated = _case.last_updated_at && !lienholderBlacklisted
      ;

    return (
      <InfoCard
        bottomRightRender={canUpdate && <UpdateCaseButton _case={_case} style={{ fontSize: '14px' }} />}
        content={!lienholderBlacklisted && latestUpdate && latestUpdate.note}
        subtitle={this.statusDisplay}
        title={lawFirmDisplay}
        topRightContent={showLastUpdated ? `Last updated ${formatDate(_case.last_updated_at)}${updatedBy}` : null}
      />
    );
  }
}

export default LawFirmStatusCard;
