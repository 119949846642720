import React, { Component } from 'react';
import { observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import autoBindMethods from 'class-autobind-decorator';
import { debounce, isEmpty, pick, get } from 'lodash';

import { Col, Layout, Menu, Radio, Row } from 'antd';
import { ColumnProps } from 'antd/lib/table';

import SmartBool from '@mighty-justice/smart-bool';
import { IModel } from '@mighty-justice/fields-ant/dist/props';

import AppContent from '../AppContent';
import Client from '../../base-modules/client';
import FacetsDrawer from '../common-facets/FacetsDrawer';
import StoresClass from '../../stores/StoresClass';
import { ClearFiltersButton, FiltersButton, ModalButton, PageLoader, Table, Tag } from '../common';
import { DEBOUNCE_DELAY, DEDUPE_STATUS } from '../../utils/constants';
import { DedupePortfolio, IDedupePortfolio } from '../../models/DedupePortfolio';
import { DEFAULT_PAGINATION } from '../../stores/TableStore';
import { getQuery } from '../../utils/navigationUtils';
import { numFacetsApplied } from '../common-facets/facetUtils';
import { getDedupeFacetSets } from '../common-facets/config/dedupeFacetSets';

import MultiMatchDedupePage from './dedupe/MultiMatchDedupePage';
import VerifiedMatchDrawer from './verification/legal-organizations/VerifiedMatchDrawer';
import {
  organizationVerificationColumns,
} from './verification/legal-organizations/tableColumns';
import { contactVerificationColumns } from './verification/contact/tableColumns';

import {
  ACTION_ICONS,
  ACTIONS,
  DEFAULT_SELECTED_KEY,
  IMenu,
  MENU_MAP,
  TYPES,
  URL_QUERY_KEYS,
} from './consts';

interface IInjected {
  client: Client;
  stores: StoresClass;
}

@inject('client', 'stores')
@autoBindMethods
@observer
class DedupePage extends Component {
  @observable private selectedKey: string;
  private debouncedOnMenuItemClick: (event: any) => void;
  private portfolio: IDedupePortfolio;
  private extraFilters: { [key: string]: string };
  private isChangingTabs = new SmartBool();
  private isAdvancedFacetsVisible = new SmartBool();

  private get injected () { return this.props as IInjected; }

  public constructor (props: {}) {
    super(props);

    this.debouncedOnMenuItemClick = debounce(this.onMenuItemClick, DEBOUNCE_DELAY);
    this.selectedKey = DEFAULT_SELECTED_KEY;
    this.portfolio = DedupePortfolio.create({ baseUrl: this.baseUrl }, this.injected.stores.dependencies);
    this.dedupeTableStore.init({
      baseQuery: this.urlQueryParams,
      defaultPageSize: 10,
      filters: this.filters,
      hydrate: this.portfolio.hydrate,
      ignoredFilters: ['is_user', 'is_verified', 'is_law_firm', 'type', 'status'],
    });
    this.dedupeTableStore.isLoading = true;
    this.extraFilters = {};
  }

  public componentDidMount () {
    this.dedupeTableStore.fetchTableData();
  }

  public get isVerification (): boolean {
    return this.menu.action === ACTIONS.VERIFICATION;
  }

  public get isDedupe (): boolean {
    return this.menu.action === ACTIONS.DEDUPE;
  }

  public get menu (): IMenu {
    return MENU_MAP[this.selectedKey];
  }

  public get filters () {
    return { ...this.menu.filters, ...this.extraFilters };
  }

  private get baseUrl () {
    return this.menu.baseUrl;
  }

  public get dedupeTableStore () { return this.injected.stores.dedupeTableStore; }

  private get urlQueryParams () { return pick(getQuery(), URL_QUERY_KEYS); }

  private get statusKey () { return `${this.isVerification ? 'verification_' : ''}status`; }

  private get facetSets () {
    return getDedupeFacetSets(
      this.menu.action,
      this.menu.searchFields,
      this.menu.extraFacets,
    );
  }

  private getColumnsForType (type: string): Array<ColumnProps<IModel>> {
    const MODEL_COLUMNS = {
      [TYPES.LAW_FIRM_CONTACTS]: contactVerificationColumns,
      [TYPES.LAW_FIRMS]: organizationVerificationColumns,
      [TYPES.LIENHOLDER_CONTACTS]: contactVerificationColumns,
      [TYPES.LIENHOLDERS]: organizationVerificationColumns,
    };

    return MODEL_COLUMNS[type] || [];
  }

  private async onMenuItemClick (event: any) {
    if (this.selectedKey !== event.key) {
      this.isChangingTabs.setTrue();
      this.selectedKey = event.key;
      this.portfolio.switchType(this.baseUrl);
      this.extraFilters = {};

      await this.dedupeTableStore.setAndFetch(DEFAULT_PAGINATION, this.filters, this.menu.ordering);
      this.isChangingTabs.setFalse();
    }
  }

  private async onDismissMatchFilterChange (event: any) {
    this.extraFilters[this.statusKey] = event.target.value;
    await this.dedupeTableStore.setAndFetch(DEFAULT_PAGINATION, this.filters, this.menu.ordering);
  }

  private triggerRerender () {
    return this.portfolio.matches.length;
  }

  public renderFromImportTag () {
    if (isEmpty(this.urlQueryParams)) {
      return null;
    }

    return (
      <Tag type='success' style={{ marginLeft: 15 }}>
        From Import
      </Tag>
    );
  }

  public renderContent () {
    if (this.isChangingTabs.isTrue) {
      return <PageLoader />;
    }

    if (this.isDedupe) {
      return (
        <MultiMatchDedupePage
          filters={this.filters}
          isLoading={this.dedupeTableStore.isLoading}
          modelName={this.menu.modelName || ''}
          ordering={this.menu.ordering || {}}
          portfolio={this.portfolio}
          tableStore={this.dedupeTableStore}
        />
      );
    }

    return (
      <Table
        {...this.dedupeTableStore.tableProps}
        className='ant-page-table'
        columns={this.getColumnsForType(this.menu.type)}
        dataSource={this.portfolio.matches.slice()}
        rowKey='id'
      />
    );
  }

  public renderEditMatchesButton () {
    if (!this.menu.showEditMatches) { return null; }

    return (
      <Col>
        <ModalButton
          buttonText='Edit Verified Matches'
          ModalClass={VerifiedMatchDrawer}
          passThroughProps={{
            isEditable: true,
            ...this.menu.buttonProps,
          }}
        />
      </Col>
    );
  }

  public renderFilters () {
    const hasFilters = Boolean(numFacetsApplied(this.facetSets, this.dedupeTableStore.filterQuery));

    return (
      <>
        <FiltersButton onClick={this.isAdvancedFacetsVisible.toggle} />
        {hasFilters && (
          <ClearFiltersButton onClick={this.dedupeTableStore.resetAll} />
        )}
      </>
    );
  }

  public renderDismissedMatchFilter () {
    const value = get(this.extraFilters, this.statusKey, DEDUPE_STATUS.NEW);

    return (
      <Radio.Group value={value} buttonStyle='solid' onChange={this.onDismissMatchFilterChange}>
        <Radio.Button value={DEDUPE_STATUS.NEW}>No action taken</Radio.Button>
        <Radio.Button value={DEDUPE_STATUS.NEEDS_MORE_INFORMATION}>Needs more information</Radio.Button>
        {this.isDedupe && <Radio.Button value={DEDUPE_STATUS.DISMISSED}>Dismissed</Radio.Button>}
      </Radio.Group>
    );
  }

  public render () {
    this.triggerRerender();

    return (
      <AppContent>
        <Layout className='page page-floating page-style-card page-facet-table'>
          <Layout.Header>
            <Row align='middle' justify='space-between'>
              <Col>
                <Row align='middle'>
                  <Menu
                    defaultSelectedKeys={[DEFAULT_SELECTED_KEY]}
                    mode='horizontal'
                    onClick={this.debouncedOnMenuItemClick}
                  >
                    {Object.values(MENU_MAP).map(menu =>
                      (
                        <Menu.Item key={menu.key} id={menu.key}>
                          {ACTION_ICONS[menu.action]}{' '}
                          {menu.label}
                        </Menu.Item>
                      ))}
                  </Menu>
                  {this.renderFromImportTag()}
                  {this.renderFilters()}
                </Row>
              </Col>

              {this.menu.showDismissedMatchFilter && (
                <Col>
                  <Row gutter={16}>
                    {this.renderDismissedMatchFilter()}
                  </Row>
                </Col>
              )}

              {this.isVerification && (
                <Col>
                  <Row gutter={16}>
                    {this.renderEditMatchesButton()}
                  </Row>
                </Col>
              )}

            </Row>
            <FacetsDrawer
              facetSets={this.facetSets}
              isVisible={this.isAdvancedFacetsVisible}
              tableStore={this.dedupeTableStore}
            />
          </Layout.Header>
          <Layout.Content>
            {this.renderContent()}
          </Layout.Content>
        </Layout>
      </AppContent>
    );
  }
}

export default DedupePage;
