import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { observable } from 'mobx';
import autoBindMethods from 'class-autobind-decorator';

import Client from '../../../../base-modules/client';
import { IDocumentActivityData } from '../../../../models/CaseActivity';
import { EXTERNAL_URL_PROPS } from '../../../../utils/constants';

interface IProps {
  document: IDocumentActivityData;
}

interface IInjected extends IProps {
  client: Client;
}

@inject('client')
@autoBindMethods
@observer
class CaseDocumentDownloadUrl extends Component<IProps> {
  @observable public downloadUrl: string | null = null;

  private get injected () { return this.props as IInjected; }

  public async componentDidMount () {
    const { document } = this.props
      , { client } = this.injected
      , response = await client.get(`/case-documents/${document.id}`)
      ;

    this.downloadUrl = response.download_url;
  }

  public render () {
    const { name } = this.props.document;

    return (
      <p>
        <a {...EXTERNAL_URL_PROPS} href={`${this.downloadUrl}&dl=0`}>
          {name}
        </a>
      </p>
    );
  }
}

export default CaseDocumentDownloadUrl;
