import React, { Component } from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import autoBindMethods from 'class-autobind-decorator';
import { find } from 'lodash';

import { IDetailCase, IListCase } from '../../../models/Case';
import { LoadingBlock } from '../../common';

import CaseHeader, { ListCaseHeader } from './detail-header/CaseHeader';
import CaseTabs from './CaseTabs';
import ListCaseTabs from './ListCaseTabs';
import { TABS } from './CaseTabConfig';

import styles from './CasePage.module.less';

export interface IProps {
  _case: IDetailCase;
}

@autoBindMethods
@observer
class CasePage extends Component<IProps> {
  @observable currentTab = 'info';

  private onChangeTab (newTab: string) {
    this.currentTab = newTab;
  }

  private get tabComponent () {
    // We look for the tab matching the currentTab key here,
    // and if it is not found use the fist component in the tab list
    const foundTab = find(TABS, { key: this.currentTab });
    return foundTab ? foundTab.component : TABS[0].component;
  }

  public render () {
    const { _case } = this.props
      , TabComponent = this.tabComponent;

    return (
      <div className={styles.root}>
        <CaseHeader _case={_case} lien={_case.userLien} />
        <CaseTabs _case={_case} onChangeTab={this.onChangeTab} currentTab={this.currentTab} />
        <TabComponent key={_case.id} _case={_case} lien={_case.userLien} liens={_case.liens} />
      </div>
    );
  }
}

export function ListCasePage ({ _case }: { _case: IListCase }) {
  return (
    <div className={styles.root}>
      <ListCaseHeader _case={_case} />
      <ListCaseTabs />
      <LoadingBlock />
    </div>
  );
}

export default CasePage;
