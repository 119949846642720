import React, { Component } from 'react';
import cx from 'classnames';
import { inject, observer } from 'mobx-react';
import autoBindMethods from 'class-autobind-decorator';

import { getNameOrDefault } from '@mighty-justice/utils';

import { IDetailCase } from '../../../../../models/Case';
import { ICaseThread } from '../../../../../models/CaseThread';
import { IDetailLien } from '../../../../../models/Lien';
import StoresClass from '../../../../../stores/StoresClass';
import { Icon, LinkButton, ModalButton } from '../../../../common';
import InfoCard from '../../common/InfoCard';

import { AUTO_THREAD_RESPONSE_NOTE } from '../constants';

import ChangeOfRepresentationModal from '../../../portfolio-detail/change-of-representation/ChangedRepresentationModal';
import UpdateLawFirmModal from '../../../portfolio-detail/change-of-representation/UpdateLawFirmModal';

import { InboxItemProps } from '../interfaces';

interface IProps {
  _case: IDetailCase;
  lien: IDetailLien;
  passThroughProps: InboxItemProps;
  thread: ICaseThread;
}

interface IInjected extends IProps {
  stores: StoresClass;
}

import styles from './InboxActionButtons.module.less';

@inject('stores')
@autoBindMethods
@observer
class LawFirmChangedThread extends Component<IProps> {
  private get injected () { return this.props as IInjected; }

  private async dismissThread () {
    const { thread } = this.props;
    await thread.dismiss(AUTO_THREAD_RESPONSE_NOTE.DISMISSED_THREAD);
  }

  private get actionButtons () {
    const { stores: { users: { isLawFirmUser } } } = this.injected
      , { _case, lien, thread } = this.props
      , toLawFirm = thread.context.is_to_law_firm
      , fromLienholder = thread.context.is_from_lienholder
      , modal = isLawFirmUser ? ChangeOfRepresentationModal : UpdateLawFirmModal;

    if (toLawFirm || fromLienholder) {
      return (
        <div className={cx(styles.root, styles.override)}>
          <LinkButton id='dismiss-law-firm-changed' className={styles.default} onClick={this.dismissThread}>
            Dismiss <Icon.Ban />
          </LinkButton>
          <ModalButton
            buttonProps={{
              className: styles.primary,
              id: 'update-law-firm',
            }}
            passThroughProps={{ _case, lien, thread }}
            ModalClass={modal}
          >
            Update <Icon.Check />
          </ModalButton>
        </div>
      );
    }

    return (
      <div className={cx(styles.root, styles.override)}>
        <LinkButton id='dismiss-change-of-representation' className={styles.primary} onClick={this.dismissThread}>
          Okay <Icon.Check />
        </LinkButton>
      </div>
    );
  }

  private get resolutionDescription () {
    const { thread } = this.props;

    switch (thread.latest_message?.note) {
    case AUTO_THREAD_RESPONSE_NOTE.DISMISSED_THREAD:
      return `You dismissed: ${this.description}`;

    case AUTO_THREAD_RESPONSE_NOTE.UPDATED_LAW_FIRM:
      return `You updated the law firm to ${thread.context.new_law_firm}`;

    default:
      return 'This thread has been resolved';
    }
  }

  private get description () {
    const { _case: { plaintiff }, thread } = this.props
      , toLawFirm = thread.context.is_to_law_firm
      , fromLienholder = thread.context.is_from_lienholder
      , plaintiffName = getNameOrDefault(plaintiff)
      , newLawFirm = thread.context.new_law_firm ? thread.context.new_law_firm : 'a new law firm';

    if (toLawFirm) {
      return (
        `${thread.created_by_organization_name} has indicated your law firm no longer represents ${plaintiffName}`
      );
    }

    if (fromLienholder) {
      return (
        `Another lienholder on this case reported that ${plaintiffName} is now represented by ${newLawFirm}`
      );
    }

    return `${thread.created_by_organization_name} says that they no longer represent ${plaintiffName}`;
  }

  public render () {
    const { passThroughProps, thread } = this.props
      , isResolved = !!thread.is_resolved
      , description = isResolved ? this.resolutionDescription : this.description;

    return (
      <InfoCard
        bottomRightRender={!isResolved && this.actionButtons}
        content={!isResolved && thread.latest_message?.note}
        subtitle={description}
        title='Contacts'
        {...passThroughProps}
      />
    );
  }
}

export default LawFirmChangedThread;
