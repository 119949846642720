export const MISSING_LAW_FIRM = 'No law firm';
export const STATUS_UNAVAILABLE = 'Status unavailable';
export const DEFAULT_ERROR_MESSAGE = 'An error occurred. Please refresh the page and try again.';

export const CASE_STATUS_WITH_DATE = {
  CASE_SETTLED_NOT_YET_DISBURSED: 'CASE_SETTLED_NOT_YET_DISBURSED',
  IN_LITIGATION: 'IN_LITIGATION',
  MEDIATION_OR_ARBITRATION_SCHEDULED: 'MEDIATION_OR_ARBITRATION_SCHEDULED',
  TRIAL_SCHEDULED: 'TRIAL_SCHEDULED',
};
