import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import autoBindMethods from 'class-autobind-decorator';

import { IFieldSetPartial } from '@mighty-justice/fields-ant';

import Client from '../../../../base-modules/client';
import { ModalButton, Spacer } from '../../../common';
import { IVerifyContactMatch } from '../../../../models/DedupeMatch';
import { URLS } from '../../../../utils/constants';
import ConfirmationOnContactErrorModal from '../../../page-portfolio/ConfirmationOnContactErrorModal';

interface IProps {
  match: IVerifyContactMatch;
}

interface IInjected extends IProps {
  client: Client;
}

const FIELD_SETS: IFieldSetPartial[] = [[
  { field: 'first_name', required: true },
  { field: 'last_name' },
  { field: 'email', required: true },
  { field: 'verification_reason', type: 'text', required: true },
  {
    editProps: { description: 'Verify Email Domain' },
    field: 'verify_email_domain',
    showLabel: false,
    type: 'checkbox',
  },
]]
  ;

@inject('client')
@autoBindMethods
@observer
class VerifyButton extends Component<IProps> {
  private get injected () {
    return this.props as IInjected;
  }

  private async onSave (model: any) {
    const { match } = this.props
      , { client } = this.injected
      , submitData = {
        ...model,
        existing_contact: match.id,
        manually_verify: true,
      }
      , detailUrl = `${URLS.INTERNAL_CONTACTS}${match.id}/`;

    await client.update(detailUrl, submitData);
    match.remove();
  }

  private getUnconfirmedContactError (error: any, model: any) {
    const { match: { legal_organization: { name, website } } } = this.props;

    // error is of the format: "matches a verified domain for {matching_organization.name} ({domains})"
    return (
      <div>
        This contact is listed with {name} { website && `(${website}) ` }but their email
        ({model.email}) {error.response.data.non_field_errors[0]}.
        <Spacer />
        Are you sure you want to verify this contact?
      </div>
    );
  }

  public render () {
    const { match } = this.props;

    return (
      <ModalButton
        buttonProps={{ className: 'btn-verify-contact' }}
        buttonText='Verify'
        ModalClass={ConfirmationOnContactErrorModal}
        passThroughProps={{
          confirmOnError: true,
          errorModalProps: {
            saveText: 'Verify',
            title: 'Confirm Contact Verification',
          },
          getErrorModalChildren: this.getUnconfirmedContactError,
          onSave: this.onSave,
          passThroughProps: {
            fieldSets: FIELD_SETS,
            model: match,
            title: 'Verify Contact',
          }
        }}
      />
    );
  }
}

export default VerifyButton;
