import React, { Component } from 'react';
import { observer } from 'mobx-react';
import cx from 'classnames';
import autoBindMethods from 'class-autobind-decorator';

import { Col, Layout } from 'antd';

import { Card, Icon } from '../common';

import { SUPPORT_EMAIL } from '../../utils/constants';

import LienholderRegistrationLayout from './LienholderRegistrationLayout';

import styles from './RegistrationConfirmationPage.module.less';

const { Footer } = Layout;

@autoBindMethods
@observer
class RegistrationConfirmationPage extends Component<{}> {
  public render () {
    return (
      <LienholderRegistrationLayout>
        <div className={cx(styles.root, styles.override)}>
          <Col>
            <Card>
              <div className={styles.icon}>
                <Icon.HourGlassLight size='4x' />
              </div>
              <div className={styles.header}>
                <p>
                  You'll be verified for updates
                  <br />
                  within a couple of days.
                </p>
              </div>
              <div className={styles.details}>
                <p>
                  We're currently verifying your account and will
                  <br />
                  grant you secure access to case updates soon.
                </p>
              </div>
            </Card>
          </Col>
          <Footer className={styles.footer}>
            <div>
              <p>Need updates faster? Contact {SUPPORT_EMAIL}</p>
            </div>
          </Footer>
        </div>
      </LienholderRegistrationLayout>
    );
  }
}

export default RegistrationConfirmationPage;
