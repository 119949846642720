import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import { IActivityDetailComponentProps } from './interfaces';
import StoresClass from '../../../../stores/StoresClass';
import { OPTION_KEYS } from '../../../../utils/constants';

interface IInjected extends IActivityDetailComponentProps {
  stores: StoresClass;
}

@inject('stores')
@observer
class LienVerificationStatusUpdateActivity extends Component<IActivityDetailComponentProps> {
  private get injected () { return this.props as IInjected; }

  public render () {
    const { activity: { activity_data: { old_status, new_status }, note } } = this.props
      , { options: { getOptionName } } = this.injected.stores
      , oldStatusText = getOptionName(OPTION_KEYS.VERIFICATION_STATUS, (old_status || 'PENDING'))
      , newStatusText = getOptionName(OPTION_KEYS.VERIFICATION_STATUS, (new_status || ''))
      ;

    return (
      <>
        <h4>Lien verification status updated</h4>
        {oldStatusText && <p><b>From:</b> {oldStatusText}</p>}
        <p><b>To:</b> {newStatusText}</p>
        {note && <p className='quote'>{note}</p>}
      </>
    );
  }
}

export default LienVerificationStatusUpdateActivity;
