import React from 'react';
import cx from 'classnames';

import { Layout } from 'antd';

import AppContent from '../AppContent';
import ManualDedupePageHeader from './ManualDedupePageHeader';
import ManualDedupePageContent from './ManualDedupePageContent';

import styles from './ManualDedupePage.module.less';

function ManualDedupePage () {
  return (
    <AppContent className={styles.root} narrow>
      <Layout className={cx('page', styles.layout)}>
        <Layout.Header>
          <ManualDedupePageHeader />
        </Layout.Header>
        <Layout.Content>
          <ManualDedupePageContent />
        </Layout.Content>
      </Layout>
    </AppContent>
  );
}

export default ManualDedupePage;
