import React, { Component } from 'react';
import { observer } from 'mobx-react';
import autoBindMethods from 'class-autobind-decorator';

import { IDetailCase } from '../../../../../models/Case';

import CaseDetail from './CaseDetail';

interface IProps {
  _case: IDetailCase;
}

@autoBindMethods
@observer
class LawFirmCaseDetail extends Component<IProps> {
  private async onUpdateCase (model: any) {
    const { _case } = this.props;

    await _case.update(model);
  }

  public render () {
    const { _case } = this.props;

    return <CaseDetail _case={_case} onSave={this.onUpdateCase} />;
  }
}

export default LawFirmCaseDetail;
