import autoBindMethods from 'class-autobind-decorator';
import Axios from 'axios';
import { DEV_API_HOST, DEV_GOOGLE_CLIENT_ID, REGISTRY_ENVS, TEST_STRIPE_PUBLIC_KEY } from '../utils/constants';

export const isDev = process.env.NODE_ENV === REGISTRY_ENVS.DEVELOPMENT
  , isTest = process.env.NODE_ENV === REGISTRY_ENVS.TEST
  , isProd = !isTest && !isDev
  , HOST = process.env.host || '0.0.0.0'
  , FAKE_UUID = 'cf6c6db8-cf2f-481f-ab22-ae9eff8a8dc3'
  ;

interface EnvironmentResponseData {
  API_HOST: string;
  AUTH_URL: string;
  CSRF_COOKIE_NAME: string;
  DATADOG_RUM_APP_ID: string;
  DATADOG_RUM_CLIENT_KEY: string;
  GIT_REVISION: string;
  GOOGLE_CLIENT_ID: string;
  RECAPTCHA_KEY: string;
  REGISTRY_ENV: string;
  REGISTRY_URL: string;
  STRIPE_PUBLIC_KEY: string;
  ZENDESK_API_KEY: string;
}

@autoBindMethods
class Environment {
  public apiHost: string;
  public authUrl: string;
  public csrfCookieName: string;
  public datadogRUMAppID: string;
  public datadogRUMClientKey: string;
  public gitRevision: string;
  public googleClientID: string;
  public recaptchaKey: string;
  public registryEnv: string;
  public registryUrl: string;
  public stripePublicKey: string;
  public zendeskApiKey: string;

  public get isProd (): boolean { return isProd; }
  public get isDev (): boolean { return isDev; }
  public get isTest (): boolean { return isTest; }
  public get host (): string { return HOST; }

  constructor (environmentData: EnvironmentResponseData) {
    this.apiHost = environmentData.API_HOST;
    this.authUrl = environmentData.AUTH_URL;
    this.csrfCookieName = environmentData.CSRF_COOKIE_NAME;
    this.datadogRUMAppID = environmentData.DATADOG_RUM_APP_ID;
    this.datadogRUMClientKey = environmentData.DATADOG_RUM_CLIENT_KEY;
    this.gitRevision = environmentData.GIT_REVISION;
    this.googleClientID = environmentData.GOOGLE_CLIENT_ID;
    this.recaptchaKey = environmentData.RECAPTCHA_KEY;
    this.registryEnv = environmentData.REGISTRY_ENV;
    this.registryUrl = environmentData.REGISTRY_URL;
    this.stripePublicKey = environmentData.STRIPE_PUBLIC_KEY;
    this.zendeskApiKey = environmentData.ZENDESK_API_KEY;
  }
}

// istanbul ignore next
async function fetchEnvironment (): Promise<Environment> {
  if (isDev) {
    return new Environment({
      API_HOST: DEV_API_HOST,
      AUTH_URL: 'https://auth.local.mighty.com',
      CSRF_COOKIE_NAME: 'csrftoken-DEV',
      DATADOG_RUM_APP_ID: '',
      DATADOG_RUM_CLIENT_KEY: '',
      GIT_REVISION: '',
      GOOGLE_CLIENT_ID: DEV_GOOGLE_CLIENT_ID,
      RECAPTCHA_KEY: process.env.REACT_APP_RECAPTCHA_KEY || '',
      REGISTRY_ENV: REGISTRY_ENVS.DEVELOPMENT,
      REGISTRY_URL: 'https://lfp.local.mighty.com',
      STRIPE_PUBLIC_KEY: TEST_STRIPE_PUBLIC_KEY,
      ZENDESK_API_KEY: '',
    });
  }

  if (isTest) {
    return new Environment({
      API_HOST: 'test.mighty.com',
      AUTH_URL: '',
      CSRF_COOKIE_NAME: '',
      DATADOG_RUM_APP_ID: '',
      DATADOG_RUM_CLIENT_KEY: '',
      GIT_REVISION: '',
      GOOGLE_CLIENT_ID: '',
      RECAPTCHA_KEY: '',
      REGISTRY_ENV: REGISTRY_ENVS.DEVELOPMENT,
      REGISTRY_URL: '',
      STRIPE_PUBLIC_KEY: '',
      ZENDESK_API_KEY: '',
    });
  }

  const response = await Axios.get('/env');
  return new Environment(response.data);
}

export { fetchEnvironment, Environment };
