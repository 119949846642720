import { observable } from 'mobx';

import SmartBool from '@mighty-justice/smart-bool';

import { IDocument } from '../models/Document';

class DocumentsStore {
  @observable public isLoading = new SmartBool();
  @observable public tableDataSource: IDocument[] = [];
}

export default DocumentsStore;
