import React, { Component } from 'react';
import { observer } from 'mobx-react';
import autoBindMethods from 'class-autobind-decorator';

import AccountDetailsPageExplainer from './AccountDetailsPageExplainer';
import LienholderRegistrationLayout from './LienholderRegistrationLayout';

import AccountDetailsForm from './AccountDetailsForm';

import styles from './LienholderRegistration.module.less';

@autoBindMethods
@observer
class AccountDetailsPage extends Component<{}> {
  public render () {
    return (
      <LienholderRegistrationLayout>
        <div className={styles.root}>
          <AccountDetailsForm />
          <AccountDetailsPageExplainer />
        </div>
      </LienholderRegistrationLayout>
    );
  }
}

export default AccountDetailsPage;
