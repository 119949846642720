import React, { Component, CSSProperties } from 'react';
import { inject } from 'mobx-react';
import autoBindMethods from 'class-autobind-decorator';
import { FixedSizeList } from 'react-window';

import { Empty, List } from 'antd';

import SmartBool from '@mighty-justice/smart-bool';

import Client from '../../../base-modules/client';
import { INotification } from '../../../models/Notification';
import { NOTIFICATION_TYPES } from '../../../utils/constants';

import DefaultMightyNotification, { IDefaultNotificationProps } from './types/DefaultMightyNotification';
import DocumentRequestNotification from './types/DocumentRequestNotification';
import MissingContactsNotification from './types/MissingContactsNotification';
import NeedsMoreInformationNotification from './types/NeedsMoreInformationNotification';
import LienholderLawFirmChangedNotification from './types/LienholderLawFirmChangedNotification';

import styles from './NotificationsMenu.module.less';

interface IProps {
  isVisible: SmartBool;
  notifications: INotification[];
}

const NOTIFICATION_TYPE_TO_COMPONENT: { [key: string]: React.ComponentType<IDefaultNotificationProps> } = {
  [NOTIFICATION_TYPES.REGISTRY_LAW_FIRM_CHANGED_BY_LIENHOLDER]: LienholderLawFirmChangedNotification,
  [NOTIFICATION_TYPES.REGISTRY_LAW_FIRM_CHANGED_BY_LIENHOLDER_FOR_LAW_FIRMS]: LienholderLawFirmChangedNotification,
  [NOTIFICATION_TYPES.REGISTRY_LAW_FIRM_DOCUMENT_REQUEST]: DocumentRequestNotification,
  [NOTIFICATION_TYPES.REGISTRY_LEGAL_ORGANIZATION_NEEDS_MORE_INFORMATION]: NeedsMoreInformationNotification,
  [NOTIFICATION_TYPES.REGISTRY_MISSING_LIENHOLDER_CONTACT]: MissingContactsNotification,
};

interface IInjected extends IProps {
  client: Client;
}

const MENU_WIDTH = 350
  , MAX_MENU_HEIGHT = 600
  , ITEM_SIZE = 122
  ;

@inject('client')
@autoBindMethods
class NotificationsMenu extends Component<IProps> {
  private get injected () { return this.props as IInjected; }

  private async defaultOnClick (notification: INotification) {
    const { client } = this.injected
      , data = { notification_ids: [notification.id] }
      ;

    if (!notification.read_at) {
      await notification.markAsRead(client, data);
    }
  }

  private renderActivity ({ index, style }: { index: number, style: CSSProperties }) {
    const { notifications } = this.props
      , notification: INotification = notifications[index]
      , NotificationMenuItem: React.ComponentType<IDefaultNotificationProps> = (
        NOTIFICATION_TYPE_TO_COMPONENT[notification.type] || DefaultMightyNotification
      )
      ;

    return (
      <NotificationMenuItem
        className={notification.read_at ? styles.read : styles.unread}
        isVisible={this.props.isVisible}
        key={notification.id}
        notification={notification}
        onClick={this.defaultOnClick}
        style={style}
      />
    );
  }

  public render () {
    const { notifications } = this.props
      , menuHeight = Math.min(
        ITEM_SIZE * notifications.length,
        MAX_MENU_HEIGHT,
      )
      ;

    if (!notifications.length) {
      return <Empty className={styles.empty} description='You have no notifications at this time!' />;
    }

    return (
      <List className={styles.root}>
        <FixedSizeList
          height={menuHeight}
          itemCount={notifications.length}
          itemSize={122}
          width={MENU_WIDTH}
        >
          {this.renderActivity}
        </FixedSizeList>
      </List>
    );
  }
}

export default NotificationsMenu;
