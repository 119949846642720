import React, { Component } from 'react';
import autoBindMethods from 'class-autobind-decorator';
import { inject, observer } from 'mobx-react';

import Client from '../../base-modules/client';
import LoggedOutLandingPage from '../LoggedOutLandingPage';
import { LinkButton, Spacer } from '../common';
import { TermsAndConditions } from '../modal-action-items-list/common';
import { INavigateProps } from '../../utils/navigationUtils';
import navigationWrapper from '../../utils/navigationWrapper';

import RegistrationForm from './RegistrationForm';


interface IInjected extends INavigateProps {
  client: Client;
}

@inject('client')
@autoBindMethods
@observer
class RegistrationPage extends Component<INavigateProps> {
  private get injected () { return this.props as IInjected; }

  private async registerAccount (data: any) {
    const { navigate } = this.props
      , response = await this.injected.client.create('/register/', data)
      , { data: { is_account_created } } = response
      ;

    navigate(`/registration-complete/?success=${is_account_created}`);
  }

  public render () {
    return (
      <LoggedOutLandingPage title='Register Account'>
        <RegistrationForm title='Register Account' registerAccount={this.registerAccount} />
        <Spacer/>
        Already have an account? <LinkButton href='/login'>Login</LinkButton>
        <TermsAndConditions />
      </LoggedOutLandingPage>
    );
  }
}

export default navigationWrapper(RegistrationPage);
