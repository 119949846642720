import React, { Component } from 'react';
import autoBindMethods from 'class-autobind-decorator';
import cx from 'classnames';
import { observable } from 'mobx';
import { observer } from 'mobx-react';

import { Button, Popconfirm } from 'antd';

import SmartBool from '@mighty-justice/smart-bool';
import { formatDateTime } from '@mighty-justice/utils';

import { Icon } from '../../../common';

import { IBubble } from './interfaces';

import styles from './ChatBubble.module.less';

interface IProps extends IBubble {
  onDelete: (id: string) => Promise<void>;
}

@autoBindMethods
@observer
class ChatBubble extends Component<IProps> {
  @observable private isDeleting = new SmartBool();

  private async onDelete () {
    const { id, onDelete } = this.props;
    await this.isDeleting.until(onDelete(id));
  }

  public render () {
    const { created_at, created_by_organization, created_by_name, sentByMe, note, showSpacer } = this.props;

    return (
      <div className={styles.root}>
        <div className={styles.sender}>
          {created_by_organization}
          <span className={styles.organization}> – {created_by_name}, {formatDateTime(created_at)}</span>
        </div>
        <div className={cx(styles.bubble, sentByMe ? styles.mine : styles.theirs)}>
          {note}
        </div>
        {showSpacer && (
          <div className={styles.spacer}>
            {sentByMe && (
              <Popconfirm
                cancelText='No'
                icon={<Icon.ExclamationCircle className={styles['pop-confirm-icon']} />}
                okText='Yes'
                okType='danger'
                onConfirm={this.onDelete}
                title='Are you sure?'
              >
                <Button
                  className={styles.delete}
                  loading={this.isDeleting.isTrue}
                  size='small'
                  type='link'
                >
                  Delete
                </Button>
              </Popconfirm>
            )}
          </div>
        )}
      </div>
    );
  }
}

export default ChatBubble;
