import React, { Component } from 'react';
import { observer } from 'mobx-react';
import autoBindMethods from 'class-autobind-decorator';

import SmartBool from '@mighty-justice/smart-bool';

import { IBetaDocument } from '../../../../../models/Document';
import { IDetailCase } from '../../../../../models/Case';
import { Button, ModalButton } from '../../../../common';

import EditDocumentModal from './EditDocumentModal';

import styles from './DocumentDetailButtons.module.less';

interface IProps {
  _case: IDetailCase;
  document: IBetaDocument;
  isVisible: SmartBool;
}

@autoBindMethods
@observer
class DocumentDetailButtons extends Component<IProps> {
  public render () {
    const { _case, document, isVisible } = this.props;

    return (
      <div className={styles.root}>
        <Button href={document.download_url}>Download</Button>
        <ModalButton
          buttonProps={{ id: 'btn-edit-document', onClick: isVisible.setFalse }}
          buttonText='Edit'
          ModalClass={EditDocumentModal}
          passThroughProps={{ _case, document }}
        />
        <Button type='primary' onClick={isVisible.setFalse}>Done</Button>
      </div>
    );
  }
}

export default DocumentDetailButtons;
