import React from 'react';
import { observer } from 'mobx-react';

import { Col, Row } from 'antd';

import { IDetailCase } from '../../../../../models/Case';
import { EXTERNAL_URL_PROPS } from '../../../../../utils/constants';

import AddSingleLienDocumentTypeButton from './AddSingleLienDocumentTypeButton';

import styles from './DocumentRequestModalDocumentsSection.module.less';

interface IProps {
  _case: IDetailCase;
  lienId: string;
  typeData: { [key: string]: string };
}

const DocumentRequestRenderDocument = observer((props: IProps) => {
  const { _case, lienId, typeData } = props
    , foundDocument = _case.getLienDocument(lienId, typeData.value);

  return (
    <Row className={styles.document}>
      <Col><b>{typeData.name}:</b></Col>
      <Col>
        {foundDocument
          ? <a {...EXTERNAL_URL_PROPS} href={`${foundDocument.download_url}&dl=0`}>{foundDocument.name}</a>
          : <AddSingleLienDocumentTypeButton _case={_case} documentType={typeData.value} lienId={lienId} />
        }
      </Col>
    </Row>
  );
});

export default DocumentRequestRenderDocument;
