import React, { Component } from 'react';
import { observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import cx from 'classnames';
import autoBindMethods from 'class-autobind-decorator';

import SmartBool from '@mighty-justice/smart-bool';

import { Spin } from 'antd';

import { IDetailCase } from '../../../../../models/Case';
import StoresClass from '../../../../../stores/StoresClass';
import { Card } from '../../../../common';

import AddPolicyLimitForm from './AddPolicyLimitForm';
import PolicyLimitsTable from './PolicyLimitsTable';

import styles from './PolicyLimits.module.less';

interface IProps {
  _case: IDetailCase;
}

interface IInjected extends IProps {
  stores: StoresClass;
}

@inject('stores')
@autoBindMethods
@observer
class PolicyLimitsWrapper extends Component<IProps> {
  @observable private isLoading = new SmartBool();

  private get injected () { return this.props as IInjected; }

  public async componentDidMount () {
    const { _case } = this.props;
    await this.isLoading.until(_case.fetchPolicyLimits());
  }

  public render () {
    const { _case } = this.props
      , { stores: { users: { isLawFirmUser } } } = this.injected;

    return (
      <Spin spinning={this.isLoading.isTrue}>
        <Card className={cx(styles.root, styles.override)} title='Policy Limits'>
          <PolicyLimitsTable _case={_case} />
          {isLawFirmUser && <AddPolicyLimitForm _case={_case}/>}
        </Card>
      </Spin>
    );
  }
}

export default PolicyLimitsWrapper;
