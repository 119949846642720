import { applySnapshot, destroy, detach, flow, Instance, types } from 'mobx-state-tree';
import { find } from 'lodash';

import Client from '../base-modules/client';

import { DedupeMatch, IDedupeMatch, IVerifyOrganizationMatch } from './DedupeMatch';
import { stateTreeDependencyGetters } from '../base-modules/state-tree-dependencies';

export const DedupePortfolio = types
  .model({
    baseUrl: types.string,
    count: types.optional(types.number, 0),
    matches: types.optional(types.array(DedupeMatch), []),
    next: types.maybeNull(types.string),
  })
  .views(stateTreeDependencyGetters)
  .actions(self => {
    const hydrate = flow(function* (queryString: string = '', append = false) {
      const currentUrl = self.baseUrl
        , useNextUrl = append && !!self.next
        , url = useNextUrl ? self.next : `${self.baseUrl}${queryString}`
        , matchData = yield self.client.get(url as string)
        , snapshot = append ? [...self.matches, ...matchData.results] : matchData.results;

      self.count = matchData.count;
      self.next = matchData.next;

      // If menu item has switched during fetch
      if (currentUrl === self.baseUrl) {
        applySnapshot(self.matches, snapshot);
      }

      return matchData;
    });

    const decrementCount = function () {
      self.count -= 1;
    };

    const removeMatch = function (match: IDedupeMatch) {
      detach(match);
      decrementCount();
      destroy(match);
    };

    const mergeMatch = flow(function* (match: IVerifyOrganizationMatch, client: Client, submitData: object) {
      yield client.update(`${self.baseUrl}${match.id}/merge/`, submitData);
      removeMatch(match);
    });

    const couldNotManuallyVerifyMatch = flow(function* (match: IDedupeMatch, client: Client, submitData: object) {
      yield client.update(`${self.baseUrl}${match.id}/`, { ...submitData, could_not_manually_verify: true });
      removeMatch(match);
    });

    const dismissMatch = flow(function* (match: IDedupeMatch, client: Client, submitData?: object) {
      yield client.update(`${self.baseUrl}${match.id}/dismiss/`, submitData || {});
      removeMatch(match);
    });

    const dismissMatchNeedsMoreInfo = flow(function* (match: IDedupeMatch, client: Client) {
      yield dismissMatch(match, client, { needs_more_information: true });
    });

    const verificationNeedsMoreInfo = flow(function* (
      match: IDedupeMatch,
      client: Client,
      submitData: object,
      remove: boolean
    ) {
      if (remove) {
        client.update(`${self.baseUrl}${match.id}/needs_more_information/`, submitData);
        removeMatch(match);
      }
      else {
        const original = find<IDedupeMatch>(self.matches, { id: match.id })
          , result = yield client.update(`${self.baseUrl}${match.id}/needs_more_information/`, submitData);

        if (original === undefined) { throw new Error('No document found'); }

        applySnapshot(original, result.data);
      }
    });

    const switchType = function (baseUrl: string) {
      self.baseUrl = baseUrl;
      applySnapshot(self.matches, []);
    };

    return {
      couldNotManuallyVerifyMatch,
      dismissMatch,
      dismissMatchNeedsMoreInfo,
      hydrate,
      mergeMatch,
      removeMatch,
      switchType,
      verificationNeedsMoreInfo,
    };
  })
  ;

export interface IDedupePortfolio extends Instance<typeof DedupePortfolio> {}
