import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import autoBindMethods from 'class-autobind-decorator';

import SmartBool from '@mighty-justice/smart-bool';

import { EmptyBlock, PageLoader, RegisterCaseButton } from '../../common';
import { getAllUserFacetSets } from '../../common-facets/config';
import { numFacetsApplied } from '../../common-facets/facetUtils';
import StoresClass from '../../../stores/StoresClass';
import { printPerformance } from '../../../utils/utils';
import { INavigateProps } from '../../../utils/navigationUtils';
import navigationWrapper from '../../../utils/navigationWrapper';


interface IProps extends INavigateProps{
  isDrawerAddCaseVisible: SmartBool;
}

interface IInjected extends IProps {
  stores: StoresClass;
}

@inject('stores')
@autoBindMethods
@observer
class NoCaseSelectedPlaceholder extends Component<IProps> {
  private get injected () { return this.props as IInjected; }

  private get hasFilters (): boolean {
    const { stores } = this.injected;
    return Boolean(numFacetsApplied(
      getAllUserFacetSets(stores.users),
      stores.infiniteTableStore.filterQuery,
    ));
  }

  private async resetFilters () {
    const { navigate, params: { id }, searchParams: [, setQuery] } = this.props
      , { infiniteTableStore } = this.injected.stores
      ;

    setQuery({});
    await infiniteTableStore.resetAll();

    if (id === 'null') {
      navigate(infiniteTableStore.firstCaseUrl);
    }
  }

  public render () {
    const { isDrawerAddCaseVisible } = this.props
      , { infiniteTableStore } = this.injected.stores
      , { isLoading } = infiniteTableStore
      , hasCases = Boolean(infiniteTableStore.count)
      ;

    if (isLoading) {
      printPerformance('Case Page Page Loader');
      return <PageLoader />;
    }

    if (this.hasFilters && !hasCases) {
      return (
        <EmptyBlock fullMargin description='These filters match no cases.'>
          Choose different filtering or
          {' '}<a className='link link-danger' onClick={this.resetFilters}>clear the filters</a>.
        </EmptyBlock>
      );
    }

    if (!hasCases) {
      return (
        <EmptyBlock fullMargin description='You currently have no cases.'>
          <RegisterCaseButton
            onClick={isDrawerAddCaseVisible.setTrue}
            size='middle'
            type='default'
          />
        </EmptyBlock>
      );
    }

    return null;
  }
}

export default navigationWrapper(NoCaseSelectedPlaceholder);
