/* istanbul ignore file */

import autoBindMethods from 'class-autobind-decorator';
import * as Sentry from '@sentry/browser';

import { getNameOrDefault } from '@mighty-justice/utils';

import StoresClass from '../stores/StoresClass';
import injectZendeskWidget from '../utils/injectZendeskWidget';

import Client from './client';
import Auth from './auth';
import { Environment } from './environment';

@autoBindMethods
class Analytics {
  private auth: Auth;
  private client: Client;
  private environment: Environment;
  private stores: StoresClass;

  public constructor (client: Client, environment: Environment, stores: StoresClass, auth: Auth) {
    this.auth = auth;
    this.client = client;
    this.environment = environment;
    this.stores = stores;
  }

  private get window () { return window as any; }

  private get isDisabledGlobally (): boolean {
    return this.environment.isDev || this.environment.isTest || this.auth.isImpersonating;
  }

  public initPrePaint () {
    if (this.environment.isDev || this.environment.isTest) { return; }
    this.initSentry();
    this.initDatadogRUM();
  }

  public initOnLoad () {
    if (this.isDisabledGlobally) { return; }

    this.initZendesk();
  }

  public trackPage () {
    if (
      this.window.analytics
      && !this.isDisabledGlobally
    ) {
      this.window.analytics.page();
    }
  }

  public initZendesk () {
    if (
      !this.environment.isProd
      || this.isDisabledGlobally
      || !this.environment.zendeskApiKey
    ) { return; }

    injectZendeskWidget(this.environment.zendeskApiKey);
  }

  public initSentry () {
    // Sentry is unique, because we want error reporting in both staging and impersonation
    if (this.environment.isDev || this.environment.isTest) { return; }

    Sentry.init({
      dsn: 'https://3ed3f742f631424680de2cf8ec2c410a@o223655.ingest.us.sentry.io/1373056',
      environment: this.environment.registryEnv,
      release: this.environment.gitRevision,
    });
  }

  public initDatadogRUM () {
    if (this.window.DD_RUM) {
      this.window.DD_RUM.init({
        applicationId: this.environment.datadogRUMAppID,
        clientToken: this.environment.datadogRUMClientKey,
        defaultPrivacyLevel: 'mask-user-input',
        env: this.environment.registryEnv,
        premiumSampleRate: 100,
        // TODO: decide how to make a reasonable version number
        // version: window.Mighty.GIT_REVISION?
        sampleRate: 100,
        service: 'pi-portal',
        site: 'datadoghq.com',
        trackUserInteractions: true,
      });
    }
  }


  // eslint-disable-next-line complexity
  private _identifyUser () {
    const { user, account, accountStage } = this.stores.users;

    if (!user || !account) { throw new Error('Cannot identify without user'); }
    if (this.isDisabledGlobally) { return; }

    const legal_organization = account.registry_legal_organization
      , userObj = {
        // These values are not well named, but were used prior to Aug 2021.
        // They can be kept in perpetuity, or eventually removed.
        company: account.registry_legal_organization.name,
        created_date_organization: legal_organization.created_at,
        created_date_user: account.created_at,
        plan_type: accountStage,
        role: account.role,
        userId: user.id,

        // Core values should be included with and without prefix
        email: user.email,
        first_name: user.first_name,
        id: user.id,
        last_name: user.last_name,
        name: getNameOrDefault(user),
        username: user.username,

        // User
        user_email: user.email,
        user_first_name: user.first_name,
        user_full_name: getNameOrDefault(user),
        user_id: user.id,
        user_last_name: user.last_name,
        user_username: user.username,

        // Account
        account_created_at: account.created_at,
        account_current_stage: account.currentStage,
        account_id: account.id,
        account_role: account.role,

        // Legal Organization
        legal_organization_created_at: legal_organization.created_at,
        legal_organization_id: legal_organization.id,
        legal_organization_name: legal_organization.name,
        legal_organization_type: legal_organization.type,
      };

    if (this.window.DD_RUM) {
      this.window.DD_RUM.setUser({
        email: user.email,
        id: user.id,
        name: `${user.first_name} ${user.last_name}`,
      });

      this.window.DD_RUM.startSessionReplayRecording();

      if (user.is_first_login) {
        this.window.DD_RUM.addAction('First time logged in');
      }
    }

    Sentry.setUser({
      ...userObj,
      // Sentry requires email to be string or undefined, not null
      email: userObj.email ? userObj.email : undefined,
    });
  }

  public identifyUser () {
    try {
      this._identifyUser();
    }
    catch (err) {
      // eslint-disable-next-line no-console
      console.error('Error identifying user', err);
    }
  }
}

export default Analytics;
