import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import autoBindMethods from 'class-autobind-decorator';
import { capitalize, lowerCase } from 'lodash';

import { Col, Divider, Row } from 'antd';

import { IOption } from '../../../stores/OptionsStore';
import {
  ANT_HALF_COL_WIDTH,
  LIENHOLDER_EMAIL_CATEGORIES_OPTION_KEYS,
  LIENHOLDER_EMAIL_NOTIFICATION_TYPE_LABELS,
  LIENHOLDER_NOTIFICATION_TYPES,
} from '../../../utils/constants';
import { Card as AntdCard } from '../../common';
import StoresClass from '../../../stores/StoresClass';

import styles from './NotificationSettings.module.less';
import NotificationSettingToggle from './NotificationSettingToggle';
import NotificationSubscriptionForm from './NotificationSubscriptionForm';

const GUTTER = 32
  , OUTER_COL_SPAN = 3
  , INNER_COL_SPAN = 18
  ;

interface IProps {
  notificationCategories: IOption[];
}

interface IInjected extends IProps {
  stores: StoresClass;
}

@inject('stores')
@autoBindMethods
@observer
class LienholderNotificationSettings extends Component<IProps> {
  private get injected () {
    return this.props as IInjected;
  }

  private getNotificationSettingToggle (type: IOption): JSX.Element | null {
    const { isLawFirmUploadedDocumentEnabled, isNewCaseEmailEnabled } = this.injected.stores.users
      , { REGISTRY_LAW_FIRM_ADDED_NEW_LIEN, REGISTRY_LAW_FIRM_UPLOADED_DOCUMENT } = LIENHOLDER_NOTIFICATION_TYPES
      , label = LIENHOLDER_EMAIL_NOTIFICATION_TYPE_LABELS[type.value]
      , title = label || capitalize(lowerCase(type.name.replace('Registry ', '')))
      ;

    // To be removed once this notification preference is available for all lienholders
    if (type.value === REGISTRY_LAW_FIRM_ADDED_NEW_LIEN && !isNewCaseEmailEnabled) {
      return null;
    }

    // To be removed once this notification preference is available for all lienholders
    if (type.value === REGISTRY_LAW_FIRM_UPLOADED_DOCUMENT && !isLawFirmUploadedDocumentEnabled) {
      return null;
    }

    return (
      <Row id={type.value} key={type.value} className={styles['notification-type']}>
        <Col span={INNER_COL_SPAN}>
          <span>{title}</span>
        </Col>
        <Col span={OUTER_COL_SPAN}>
          <NotificationSettingToggle notificationItem={type} />
        </Col>
      </Row>
    );
  }

  public render () {
    const { options: { getOptions } } = this.injected.stores
      , { notificationCategories } = this.props;

    return (
      <Row gutter={GUTTER}>
        <Col span={ANT_HALF_COL_WIDTH}>
          <AntdCard title='Email me about...'>
            {notificationCategories.map((notificationCategory: IOption) => {
              const optionKey: string = LIENHOLDER_EMAIL_CATEGORIES_OPTION_KEYS[notificationCategory.value]
                , notificationTypes: IOption[] = getOptions(optionKey)
                , notificationTypeToggles: Array<JSX.Element | null> = notificationTypes.map((type: IOption) => (
                  this.getNotificationSettingToggle(type)
                ))
                ;

              return (
                <div key={notificationCategory.value}>
                  <span className={styles.subtitle}>{notificationCategory.name}</span>
                  {notificationTypeToggles}
                  <Divider />
                </div>
              );
            })}
          </AntdCard>
        </Col>
        <Col span={ANT_HALF_COL_WIDTH}>
          <AntdCard title='Receive Emails From These Law Firms Only'>
            <NotificationSubscriptionForm />
          </AntdCard>
        </Col>
      </Row>
    );
  }
}

export default LienholderNotificationSettings;
