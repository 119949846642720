import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import autoBindMethods from 'class-autobind-decorator';

import { Row } from 'antd';

import { Form } from '@mighty-justice/fields-ant';

import StoresClass from '../../stores/StoresClass';
import { Button } from '../common';
import { INavigateProps, IRedirectTo } from '../../utils/navigationUtils';
import { REGISTRATION_URLS } from './utils/constants';
import navigationWrapper from '../../utils/navigationWrapper';

import styles from './CaseCreationPage.module.less';

interface IInjected extends INavigateProps {
  stores: StoresClass;
}

@inject('stores')
@autoBindMethods
@observer
class ManualCaseForm extends Component<INavigateProps> {
  private get injected () { return this.props as IInjected; }

  private navigateToAccountCreation () {
    this.props.navigate(REGISTRATION_URLS.ACCOUNT_CREATION);
  }

  private async onSave (model: any) {
    const { caseFormStore: { createCases } } = this.injected.stores
      , { navigate } = this.props
      , redirectTo: IRedirectTo = await createCases(model);

    if (redirectTo) {
      navigate(redirectTo);
    }
  }

  public render () {
    const { caseFormStore: { addCase, caseFieldSets, document } } = this.injected.stores;

    return (
      <Row>
        <Form
          cancelText='Back'
          children={(
            <Button className={styles.add} disabled={!!document} id='addCase' onClick={addCase}>
              <p>+ Add another Case</p>
            </Button>
          )}
          fieldSets={caseFieldSets}
          onCancel={this.navigateToAccountCreation}
          onSave={this.onSave}
          resetOnSuccess={false}
          saveText='Next'
          successText={null}
          title={(
            <div>
              <h3 className={styles.option}>Option 2: Individual Upload</h3>
              <p className={styles.subtitle}>Just have a few cases? Enter them one by one below.</p>
            </div>
          )}
        />
      </Row>
    );
  }
}

export default navigationWrapper(ManualCaseForm);
