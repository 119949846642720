/* istanbul ignore file */
/* eslint-disable no-magic-numbers */
import React, { Component } from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import autoBindMethods from 'class-autobind-decorator';
import cx from 'classnames';
import { range } from 'lodash';

import { Layout, Switch, Card as AntdCard } from 'antd';

import SmartBool from '@mighty-justice/smart-bool';
import { Card, IFieldSetPartial, mapFieldSetFields, EditableCard } from '@mighty-justice/fields-ant';

import { EXTERNAL_URL_PROPS } from '../../utils/constants';
import { Steps, Button } from '../common';
import { withColumns } from '../../utils/configCommon';

import styles from './StyleGuidePage.module.less';

function renderDocumentationButton (href: string) {
  return (
    <Button type='primary' href={href} {...EXTERNAL_URL_PROPS}>
      Open Documentation
    </Button>
  );
}

function renderThrowErrorButton () {
  const onClick = () => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    myUndefinedFunction();
  };

  return (
    <Button onClick={onClick}>
      Throw Error
    </Button>
  );
}

const LINKS: IFieldSetPartial[] = [[
  {
    field: 'antd',
    label: 'Ant Design - Our low-level component Library',
    render: renderDocumentationButton,
    value: 'https://ant.design/',
  },
  {
    field: 'fields-ant',
    label: 'Fields-Ant - Our high-level component Library',
    render: renderDocumentationButton,
    value: 'https://mighty-justice.github.io/fields-ant/',
  },
]]
  .map(withColumns(3));

function renderColorSquare (color: string) {
  return (
    <div className={cx(styles['color-square'], styles[`color-square-${color}`])}>
      @{color}
    </div>
  );
}

const COLORS: IFieldSetPartial[] = [
  [
    { field: 'blueish-grey' },
    { field: 'green' },
    { field: 'red' },
    { field: 'yellow' },
  ],
  [
    { field: 'black-blue' },
    { field: 'grey-500' },
    { field: 'grey-400' },
    { field: 'grey-300' },
    { field: 'grey-200' },
    { field: 'grey-100' },
  ],
].map(fieldSet => mapFieldSetFields(fieldSet, fieldConfig => ({
  render: renderColorSquare,
  value: fieldConfig.field,
  ...fieldConfig,
})))
// eslint-disable-next-line no-magic-numbers
  .map(withColumns(6));

@autoBindMethods
@observer
class StyleGuidePage extends Component<{}> {
  @observable borderless = new SmartBool(true);

  public render () {
    const className = cx(
      styles.root,
      styles.override,
      { 'borderless-card-layout': this.borderless.isTrue },
    );

    return (
      <Layout className={className}>
        <h1>Style Guide</h1>

        <AntdCard>
          {renderThrowErrorButton()}
        </AntdCard>

        <AntdCard title='Switch'>
          <Switch
            checked={this.borderless.isTrue}
            onChange={this.borderless.set}
          />{' '}
          Borderless
        </AntdCard>

        <Card
          title='Links'
          fieldSets={LINKS}
        />

        <Card
          fieldSets={COLORS}
          title='Colors'
        />

        <EditableCard
          fieldSets={range(1, 5).map(idx => {
            const columns = 2 * idx;
            return withColumns(columns)({
              legend: `${columns.toString()} Columns`,
              fields: range(columns).map(jdx => ({ field: `field_${jdx + 1}` })),
            });
          })}
          title='EditableCard switches between Card and FormCard'
        />

        <AntdCard title='Steps'>
          <Steps>
            {range(1, 4).map(idx => (
              <Steps.Step
                description={`This is step ${idx}`}
                key={idx}
                title={`Step ${idx}`}
              />
            ))}
          </Steps>
        </AntdCard>
      </Layout>
    );
  }
}

export default StyleGuidePage;
