import React, { Component } from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import autoBindMethods from 'class-autobind-decorator';
import { capitalize } from 'lodash';

import { Col, Empty, Row, Pagination, Spin } from 'antd';
import { Key, SortOrder } from 'antd/es/table/interface';

import SmartBool from '@mighty-justice/smart-bool';
import { pluralize } from '@mighty-justice/utils';

import TableStore from '../../../stores/TableStore';
import { IMultiMatch } from '../../../models/DedupeMatch';
import { IDedupePortfolio } from '../../../models/DedupePortfolio';
import { URLS } from '../../../utils/constants';

import { ButtonToolbar, Icon, PageLoader } from '../../common';

import MultiMatchCard from './MultiMatchCard';
import OrderingDropdown from './OrderingDropdown';

interface IProps {
  filters: { [key: string]: string };
  isLoading: boolean;
  modelName: string;
  ordering: {order?: SortOrder, field?: Key};
  portfolio: IDedupePortfolio;
  tableStore: TableStore;
}

@autoBindMethods
@observer
class MultiMatchDedupePage extends Component<IProps> {
  @observable private isLoading = new SmartBool();

  private async loadPage (page: number) {
    const { ordering, tableStore } = this.props;

    await this.isLoading.until(tableStore.handleChange({ current: page }, tableStore.filters, ordering));
  }

  public renderContent () {
    const { filters, isLoading, portfolio: { count, matches, next }, modelName, tableStore } = this.props
      , canLoadMore = !!next
      , hasCurrentMatches = matches && !!matches.length
      , isEmpty = !hasCurrentMatches && !canLoadMore
      ;

    if (isLoading) {
      return <PageLoader />;
    }

    if (isEmpty) {
      const description =
        (
          <>
            {`You have successfully deduped all ${modelName}s!`}
            <br />
            They will repopulate automatically over the next few days.
          </>
        )
        , image = <Icon.Copy style={{ fontSize: '38px' }} soft />;

      return (
        <Empty
          description={description}
          image={image as any}
        />
      );
    }

    return (
      <Col xs={{ span: 22, offset: 1 }} lg={{ span: 20, offset: 2 }}>
        <Row align='middle' justify='space-between'>
          <Col>
            {!!count && <h3>{count} {capitalize(pluralize(modelName, 's', count))} Left</h3>}
          </Col>
          <Col>
            <a href={URLS.MANUAL_DEDUPE_PAGE}>
              Manually dedupe cases
            </a>
          </Col>
        </Row>
        <Row>
          <OrderingDropdown tableStore={tableStore} />
        </Row>
        {hasCurrentMatches &&
          (matches as IMultiMatch[]).map(match =>
            <MultiMatchCard key={match.id} match={match} filters={filters} />)
        }
        <ButtonToolbar align='right'>
          <Pagination total={count} onChange={this.loadPage} current={tableStore.pagination.current || 1} />
        </ButtonToolbar>
      </Col>
    );
  }

  public render () {
    return (
      <div id='page-dedupe'>
        <Spin spinning={this.isLoading.isTrue} size='large'>
          {this.renderContent()}
        </Spin>
      </div>
    );
  }
}

export default MultiMatchDedupePage;
