import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { action, observable } from 'mobx';
import autoBindMethods from 'class-autobind-decorator';

import { FormModal } from '@mighty-justice/fields-ant';
import SmartBool from '@mighty-justice/smart-bool';
import { getNameOrDefault } from '@mighty-justice/utils';

import { IDetailCase } from '../../../../models/Case';
import { ICaseThread } from '../../../../models/CaseThread';
import StoresClass from '../../../../stores/StoresClass';
import { INavigateProps } from '../../../../utils/navigationUtils';
import navigationWrapper from '../../../../utils/navigationWrapper';
import { AUTO_THREAD_RESPONSE_NOTE } from '../tab-inbox/constants';
import { IBetaDocumentSubmitData } from '../tab-documents/interfaces';

import ChangeRepresentationDocumentUpload from './ChangeRepresentationDocumentUpload';
import { changedRepresentationFieldSets } from './changedRepresentationModalFieldSets';

interface IProps extends INavigateProps {
  _case: IDetailCase;
  isVisible: SmartBool;
  thread?: ICaseThread;
}

interface IInjected extends IProps {
  stores: StoresClass;
}

const MODAL_WIDTH = 600;

@inject('stores')
@autoBindMethods
@observer
class ChangedRepresentationModal extends Component<IProps> {
  @observable public documents: IBetaDocumentSubmitData[] = [];

  private get injected () { return this.props as IInjected; }

  @action
  private setDocuments (documents: IBetaDocumentSubmitData[]) {
    this.documents = [...this.documents, ...documents.map(document => ({ ...document, type: 'CASE_DOCUMENT' }))];
  }

  @action
  private removeDocument (index: number) {
    this.documents.splice(index, 1);
  }

  private async onSave (data: any) {
    const { _case, navigate, thread } = this.props
      , { stores: { infiniteTableStore } } = this.injected
      , submitData = { ...data, documents: this.documents }
      ;

    await _case.changeLawFirm(submitData);

    if (thread) {
      await thread.dismiss(AUTO_THREAD_RESPONSE_NOTE.UPDATED_LAW_FIRM);
    }

    // TODO: destroy case in MST
    await infiniteTableStore.refresh();
    navigate(infiniteTableStore.firstCaseUrl);
    this.documents = [];
  }

  private async uploadDocuments (documents: IBetaDocumentSubmitData[]) {
    return this.setDocuments(documents);
  }

  public render () {
    const { _case, isVisible } = this.props
      , plaintiffName = getNameOrDefault(_case.plaintiff)
      , successText = 'Thanks! Lienholders on this case will be notified that the law firm has ' +
        'changed. It has been removed from your portfolio.'
      ;

    return (
      <FormModal
        children={(
          <ChangeRepresentationDocumentUpload
            _case={_case}
            documents={this.documents}
            removeDocument={this.removeDocument}
            uploadDocuments={this.uploadDocuments}
          />
        )}
        childrenBefore={(
          <div>
            <p><b>For plaintiff:</b> <span className='dd-privacy-mask'>{plaintiffName}</span></p>
            <br />
          </div>
        )}
        fieldSets={changedRepresentationFieldSets}
        isVisible={isVisible}
        onSave={this.onSave}
        saveText='Submit'
        title='Change of Representation'
        width={MODAL_WIDTH}
        successText={successText}
      />
    );
  }
}

export default navigationWrapper(ChangedRepresentationModal);
