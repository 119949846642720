import { Instance, types } from 'mobx-state-tree';

import { CreatedByData } from './Base';
import { ExternalKey } from './ExternalKey';
import { Person } from './Party';

const ManualDedupeLegalOrganization = types.model({
  id: types.identifier,
  name: types.string,
});

export const ManualDedupeCase = types.compose(
  CreatedByData,
  types.model({
    external_keys: types.optional(types.array(ExternalKey), []),
    id: types.identifier,
    date_of_loss: types.maybeNull(types.string),
    law_firm: ManualDedupeLegalOrganization,
    plaintiff: Person,
  })
);

export interface IManualDedupeCase extends Instance<typeof ManualDedupeCase> {}
export interface IManualDedupeLegalOrganization extends Instance<typeof ManualDedupeLegalOrganization> {}
