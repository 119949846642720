import React, { Component } from 'react';
import cx from 'classnames';
import { findIndex } from 'lodash';

import { Steps } from '../common';
import { getPath } from '../../utils/navigationUtils';

import { FINAL_STEP_IDX, ONBOARDING_STEPS } from './utils/constants';

import styles from './LienholderRegistrationSteps.module.less';

export function getCurrentPage (): string | undefined {
  return getPath().split('/').filter((s: string) => !!s).pop();
}

class LienholderRegistrationSteps extends Component {
  public render () {
    const page = getCurrentPage()
      , NOT_FOUND_IDX = -1
      , foundCurrentStepIdx = findIndex(ONBOARDING_STEPS, { page })
      , currentStep = foundCurrentStepIdx === NOT_FOUND_IDX ? FINAL_STEP_IDX : foundCurrentStepIdx
      ;

    return (
      <Steps className={cx(styles.root, styles.override)} current={currentStep}>
        {ONBOARDING_STEPS.map((step: any) => <Steps.Step key={`${step.page}-control`} title={step.title} />)}
      </Steps>
    );
  }
}

export default LienholderRegistrationSteps;
