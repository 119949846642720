import React, { Component } from 'react';
import autoBindMethods from 'class-autobind-decorator';
import { inject, observer } from 'mobx-react';
import { observable, toJS } from 'mobx';

import { notification } from 'antd';

import SmartBool from '@mighty-justice/smart-bool';
import { Form } from '@mighty-justice/fields-ant';
import { IModel } from '@mighty-justice/fields-ant/dist/props';

import Client from '../../base-modules/client';
import navigationWrapper from '../../utils/navigationWrapper';
import { URLS } from '../../utils/constants';
import { Controller } from '../../base-modules/controller';
import { IInvite, Invite } from '../../models/User';
import { PageLoader } from '../common';
import { TermsAndConditions } from '../modal-action-items-list/common';
import { TokenExpiredMessage } from '../page-password-reset/renderUtils';
import { Environment } from '../../base-modules/environment';
import { getQuery, getUrlForNewPath, INavigateProps } from '../../utils/navigationUtils';

interface IHooksProps {
  title: string | React.ComponentType<any>;
}

interface IProps extends IHooksProps, INavigateProps {}

interface IInjected extends IProps {
  client: Client;
  controller: Controller;
  environment: Environment;
}

const renderReadOnlyField = (label: string, value: string) => (
  <>
    <div style={{ textAlign: 'left' }}>
      <p><b>{label}</b></p>
      <p>{value}</p>
    </div>
  </>
);

@inject('client', 'controller', 'environment')
@autoBindMethods
@observer
class SetupForm extends Component<IProps> {
  @observable private model: IInvite = Invite.create();
  @observable private isTokenExpired: SmartBool = new SmartBool();
  @observable private isLoading: SmartBool = new SmartBool(true);

  private get injected () { return this.props as IInjected; }

  public async componentDidMount () {
    try {
      const { navigate } = this.props
        , { client } = this.injected
        , { token } = getQuery()
        , { data } = await client.create('/register/verify-invite/', { token })
        ;

      if (data.redirect) {
        notification.info({
          message: 'Redirecting to Login',
          description: 'We already have a matching account.'
        });
        navigate(getUrlForNewPath(URLS.LOGIN));
        return;
      }

      this.model = data;
    }
    catch (e) {
      this.isTokenExpired.setTrue();
    }
    finally {
      this.isLoading.setFalse();
    }
  }

  private get fieldSets () {
    return [[
      { field: 'first_name', required: true, value: this.model.first_name },
      { field: 'last_name', required: true, value: this.model.last_name },
      { field: 'phone_number', required: false, value: this.model.phone_number },
      { field: 'password', required: true, label: 'Create Your Password' },
    ]];
  }

  private async handleSubmit (model: IModel) {
    const { navigate } = this.props
      , { client, controller, environment } = this.injected
      , submitData = {
        new_password: model.password,
        re_new_password: model.password,
        token: this.model.token,
        uid: this.model.uid,
      }
      , { first_name, last_name, phone_number } = model
      ;

    await client.create(`https://${environment.apiHost}/api/v1/auth/password/reset/confirm/`, submitData);
    const redirectTo = await controller.loginAndGetRedirect(
      this.model.email,
      model.password,
      { first_name, last_name, phone_number },
    );
    navigate(redirectTo);
  }

  public render () {
    const { title } = this.props;

    if (this.isLoading.isTrue) { return <PageLoader />; }

    if (this.isTokenExpired.isTrue) { return <TokenExpiredMessage type='invite' />; }

    return (
      <>
        <h2>{title}</h2>
        {renderReadOnlyField('Email', this.model.email)}
        {renderReadOnlyField('Organization Name', this.model.organization)}
        <Form
          blockSubmit
          fieldSets={this.fieldSets}
          model={toJS(this.model)}
          onSave={this.handleSubmit}
        />
        <TermsAndConditions />
      </>
    );
  }
}

export default navigationWrapper(SetupForm);
