import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import autoBindMethods from 'class-autobind-decorator';

import { Row } from 'antd';

import { varToLabel } from '@mighty-justice/utils';

import StoresClass from '../../stores/StoresClass';
import { INavigateProps } from '../../utils/navigationUtils';
import { SERVICE_STAGES, URLS } from '../../utils/constants';
import navigationWrapper from '../../utils/navigationWrapper';

import { TAB_KEYS } from './settingsUtils';

import styles from './SettingsPage.module.less';

interface IHooksProps {
  tab: string;
}

interface IProps extends IHooksProps, INavigateProps {}

interface IInjected extends IProps {
  stores: StoresClass;
}

@inject('stores')
@autoBindMethods
@observer
class SettingsPageHeader extends Component<IProps> {
  private get injected () { return this.props as IInjected; }

  private onEnterpriseClick () {
    this.props.navigate(URLS.UPGRADE_OVERVIEW);
  }

  public render () {
    const { tab } = this.props
      , { stores: { users: { isLawFirmUser, accountStage } } } = this.injected
      , canUpgrade = !!(accountStage && [SERVICE_STAGES.FREEMIUM, SERVICE_STAGES.LEAD].includes(accountStage))
      , shouldRenderTitle = tab !== TAB_KEYS.COMMUNICATION_PREFERENCES
      , shouldRenderEnterpriseLink = tab === TAB_KEYS.ORGANIZATION && !isLawFirmUser && canUpgrade
      ;

    if (!shouldRenderTitle) { return null; }

    return (
      <Row align='middle' className={styles.title} justify='space-between'>
        <h1>{varToLabel(tab)} Settings</h1>
        {shouldRenderEnterpriseLink &&
          <a id='enterprise-features' onClick={this.onEnterpriseClick}>Learn about our enterprise features</a>
        }
      </Row>
    );
  }
}

export default navigationWrapper(SettingsPageHeader);
