import React from 'react';

import { IDetailCase } from '../../../../../models/Case';
import { SHARED_DOCUMENTS_MAP } from '../constants';

import DocumentRequestRenderDocument from './DocumentRequestRenderDocument';

import styles from './DocumentRequestModalDocumentsSection.module.less';

interface IProps {
  _case: IDetailCase;
  lienId: string;
}

const DocumentRequestModalDocumentsSection = (props: IProps) => (
  <div className={styles.root}>
    {SHARED_DOCUMENTS_MAP.map((typeData: { [key: string]: string }, index: number) => (
      <DocumentRequestRenderDocument key={index} _case={props._case} lienId={props.lienId} typeData={typeData} />
    ))}
  </div>
);

export default DocumentRequestModalDocumentsSection;
