import React from 'react';
import { debounce, DebouncedFunc } from 'lodash';

import { DEBOUNCE_DELAY } from './constants';
import { Subtract } from 'utility-types';

const BASE_SCROLL_HEIGHT = window.innerHeight;

export interface IInfiniteScrollProps {
  infiniteScrollHeight: number;
  renderDetailRef: React.RefObject<HTMLDivElement>;
  setScrollHeight: DebouncedFunc<() => void>;
}

interface IInfiniteScrollState {
  infiniteScrollHeight: number;
}

type GivenProps = Subtract<any, IInfiniteScrollProps>;

const makeInfinitelyScrollable = <P extends IInfiniteScrollProps>(
  Component: React.FunctionComponent<P>
) => (
    class InfiniteScroll extends React.Component<GivenProps, IInfiniteScrollState> {
      public state: IInfiniteScrollState = { infiniteScrollHeight: BASE_SCROLL_HEIGHT };
      public renderDetailRef: React.RefObject<HTMLDivElement> = React.createRef<HTMLDivElement>();
      public setScrollHeight: DebouncedFunc<() => void>;

      public constructor (props: GivenProps) {
        super(props);
        this._setScrollHeight = this._setScrollHeight.bind(this);
        this.setScrollHeight = debounce(this._setScrollHeight, DEBOUNCE_DELAY);
        window.addEventListener('resize', this.setScrollHeight);
      }

      public componentWillUnmount () {
        window.removeEventListener('resize', this.setScrollHeight);
      }

      // Keeps left-hand infinite scroll as tall as the right-hand details page
      // istanbul ignore next
      private _setScrollHeight () {
        if (this.renderDetailRef.current) {
          const detailHeight = this.renderDetailRef.current.clientHeight
            , infiniteScrollHeight = Math.max(detailHeight, BASE_SCROLL_HEIGHT);

          // Only resize the left sidebar to be larger, not smaller, to avoid jerking the page around
          if (this.state.infiniteScrollHeight < infiniteScrollHeight) {
            this.setState(() => ({ infiniteScrollHeight }));
          }
        }
      }

      public render () {
        return (
          <Component
            {...this.props as P}
            infiniteScrollHeight={this.state.infiniteScrollHeight}
            renderDetailRef={this.renderDetailRef}
            setScrollHeight={this.setScrollHeight}
          />
        );
      }
    }
  );

export default makeInfinitelyScrollable;
