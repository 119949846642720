import React from 'react';
import { Row } from 'antd';

import { Icon } from '../../../common';

import styles from './StatusTab.module.less';

export default function SectionTitle (props: { title: string; }) {
  return (
    <Row align='middle' className={styles.title}>
      <Icon.SignalStream className={styles.icon} /> {props.title}
    </Row>
  );
}
