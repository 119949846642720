import React, { Component } from 'react';
import autoBindMethods from 'class-autobind-decorator';
import { observable } from 'mobx';
import { inject, observer } from 'mobx-react';

import { Switch } from 'antd';

import SmartBool from '@mighty-justice/smart-bool';

import StoresClass from '../../../stores/StoresClass';
import { INotificationSetting } from '../../../models/User';
import { IOption } from '../../../stores/OptionsStore';

import { DISABLED_BY_DEFAULT_NOTIFICATION_TYPES } from '../../../utils/constants';

interface IProps {
  notificationItem: IOption;
}

interface IInjected extends IProps {
  stores: StoresClass;
}

@inject('stores')
@autoBindMethods
@observer
class NotificationSettingToggle extends Component<IProps> {
  @observable private isSaving = new SmartBool();

  private get injected () { return this.props as IInjected; }

  private async onChange (checked: boolean) {
    const { notificationItem } = this.props
      , { stores: { users: { user }, settings: { upsertNotificationSetting } } } = this.injected
      , userId = user && user.id
      , data = { mighty_user: userId, notification_category: notificationItem.value, is_enabled: checked }
      ;

    await this.isSaving.until(upsertNotificationSetting(data));
  }

  private isEnabled (notificationItem: IOption) {
    const { stores: { settings } } = this.injected
      , notificationSetting: INotificationSetting | undefined = settings.getNotificationSetting(notificationItem.value)
      ;

    if (notificationSetting) {
      return notificationSetting.is_enabled;
    }

    if (DISABLED_BY_DEFAULT_NOTIFICATION_TYPES.includes(notificationItem.value)) {
      return false;
    }

    return true;
  }

  public render () {
    const { notificationItem } = this.props
      , { stores: { settings } } = this.injected
      , isEnabled = this.isEnabled(notificationItem)
      ;

    return (
      <Switch
        checked={isEnabled}
        checkedChildren='On'
        loading={this.isSaving.isTrue || settings.isLoading.isTrue}
        onChange={this.onChange}
        unCheckedChildren='Off'
      />
    );
  }
}

export default NotificationSettingToggle;
