import React, { Component } from 'react';
import { observer } from 'mobx-react';

import { IDocumentActivityData } from '../../../../models/CaseActivity';
import CaseDocumentDownloadUrl from '../../portfolio-detail/lienholder-specific/CaseDocumentDownloadUrl';

import { IActivityDetailComponentProps } from './interfaces';

@observer
class AttorneyChangedActivity extends Component<IActivityDetailComponentProps> {
  public render () {
    const { activity: { activity_data: { case_documents, old_law_firm_name }, note } } = this.props;

    return (
      <>
        <h4>Plaintiff's Representation changed</h4>
        {old_law_firm_name && <p><b>Was:</b> {old_law_firm_name}</p>}
        {note && <p>{note}</p>}
        {case_documents && case_documents.map((document: IDocumentActivityData) => (
          <CaseDocumentDownloadUrl key={document.id} document={document} />
        ))}
      </>
    );
  }
}

export default AttorneyChangedActivity;
