import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { observable } from 'mobx';
import autoBindMethods from 'class-autobind-decorator';

import SmartBool from '@mighty-justice/smart-bool';

import { APP_NAME } from '../../../../utils/constants';
import StoresClass from '../../../../stores/StoresClass';
import { Button, EmptyBlock, Icon } from '../../../common';
import { IDetailLien } from '../../../../models/Lien';

import UpdateLienBalanceModal from './UpdateLienBalanceModal';


interface IProps {
  lien?: IDetailLien;
}

interface IInjected extends IProps {
  stores: StoresClass;
}

const WELCOME = `Welcome to ${APP_NAME} Finance.`
  , LAW_FIRM_EMPTY_TEXT = `${WELCOME} You’ll be able to see lien balances here as lienholders add them.`
  , LIENHOLDER_EMPTY_TEXT = `${WELCOME} Manage your lien balances, reduction requests, settlement, and more.`
  ;

@inject('stores')
@autoBindMethods
@observer
class EmptyLienBalance extends Component<IProps> {
  @observable isUpdatingLienBalance = new SmartBool();

  private get injected () { return this.props as IInjected; }

  public render () {
    const { stores: { users: { isLawFirmUser } } } = this.injected
      , { lien } = this.props;

    if (isLawFirmUser) {
      return (
        <div>
          <EmptyBlock description={LAW_FIRM_EMPTY_TEXT} icon={Icon.FileInvoiceDollarLight} />
        </div>
      );
    }

    return (
      <div>
        <EmptyBlock description={LIENHOLDER_EMPTY_TEXT} icon={Icon.FileInvoiceDollarLight}>
          <Button type='primary' onClick={this.isUpdatingLienBalance.setTrue}>Add a lien balance</Button>
          <UpdateLienBalanceModal isUpdatingLienBalance={this.isUpdatingLienBalance} lien={lien} />
        </EmptyBlock>
      </div>
    );
  }
}

export default EmptyLienBalance;
