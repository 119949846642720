import React from 'react';
import cx from 'classnames';
import { Spin } from 'antd';

import styles from './PageLoader.module.less';

interface IPageLoaderProps {
  className?: string;
  fullScreen?: boolean;
}

export default function PageLoader (props: IPageLoaderProps) {
  const className = cx(
    styles.root,
    props.className,
    { [styles['full-screen']]: props.fullScreen },
  );

  return (
    <div className={className}>
      <Spin size='large'/>
    </div>
  );
}
