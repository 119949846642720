import React from 'react';

import { formatDate } from '@mighty-justice/utils';

import InfoCard from '../common/InfoCard';
import { IDetailLien } from '../../../../models/Lien';

import ContactDisplay from './ContactDisplay';
import ContactSubtitle from './ContactSubtitle';
import ContactToolbar from './ContactToolbar';

interface IProps {
  lien: IDetailLien;
}

function LienholderCard (props: IProps) {
  const { lien } = props
    , lastActive = lien.lienholder_last_active_at
    ;

  return (
    <InfoCard
      bottomRightRender={<ContactToolbar legalOrganization={lien.lienholder} lien={lien} />}
      content={<ContactDisplay legalOrganization={lien.lienholder} />}
      subtitle={<ContactSubtitle legalOrganization={lien.lienholder} />}
      title={lien.lienholder.name}
      topRightContent={lastActive && `Last Active ${formatDate(lastActive)}`}
    />
  );
}

export default LienholderCard;
