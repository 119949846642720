import React from 'react';

import { ButtonProps } from 'antd/lib/button';

import { Button } from '../../../../common';
import { IIcon } from '../../../../common/Icon';
import UploadWrapper, { IUploadWrapperProps } from '../../../../common/UploadWrapper';

interface IProps extends IUploadWrapperProps {
  buttonProps?: ButtonProps;
  buttonText?: string;
  icon?: IIcon;
  iconPosition?: 'front' | 'back';
}

function AddDocumentButton (props: IProps) {
  const { buttonProps, buttonText, icon: Icon, iconPosition, ...uploadProps } = props;

  return (
    <UploadWrapper { ...uploadProps }>
      <Button id='add-document-button' {...buttonProps}>
        <span>
          {iconPosition === 'front' && Icon && <Icon style={{ marginRight: '8px' }}/>}
          {buttonText || 'Upload'}
          {iconPosition === 'back' && Icon && <Icon style={{ marginLeft: '8px' }}/>}
        </span>
      </Button>
    </UploadWrapper>
  );
}

export default AddDocumentButton;
