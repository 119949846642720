import React, { Component } from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import autoBindMethods from 'class-autobind-decorator';

import { TreeSelect } from 'antd';
import { DataNode } from 'rc-tree-select/lib/interface';

import SmartBool from '@mighty-justice/smart-bool';

import { IQuery } from '../../utils/navigationUtils';

import Button from './Button';
import Icon from './Icon';

import styles from './TreeSelectButton.module.less';

// Required to keep invisible input small
function renderNothing () {
  return <></>;
}

function optionStringsToQuery (values: string[]): IQuery {
  return values.reduce((newQuery: { [key: string]: string[] }, deepValue) => {
    const [category, ...rest] = deepValue.split('.')
      , option = rest.join('.');

    if (!(category in newQuery)) {
      newQuery[category] = [];
    }

    newQuery[category].push(option);
    return newQuery;
  }, {});
}

export interface ITreeCategories {
  [category: string]: {
    name: string;
    options: Array<{ name: string, value: string }>;
  }
}

interface IProps {
  isLoading?: boolean;
  onChange: (value: IQuery) => void;
  treeCategories: ITreeCategories;
}

@autoBindMethods
@observer
class TreeSelectButton extends Component<IProps> {
  @observable isOpen = new SmartBool();
  @observable values: string[] = [];

  private get selectedCategories (): string[] {
    const { treeCategories } = this.props;
    return this.values.filter(value => value in treeCategories);
  }

  private get treeData (): DataNode[] {
    const { treeCategories } = this.props;

    return Object.entries(treeCategories).map(([categoryKey, category]) => {
      const { options } = category
        , children = options.map(({ name, value }) => {
          // This allows us to know both the category and value on change
          const deepValue = [categoryKey, value].join('.');

          return {
            key: deepValue,
            title: name,
            value: deepValue,
          };
        });

      return {
        checkable: false,
        children,
        key: categoryKey,
        title: category.name,
        value: categoryKey,
      };
    });
  }

  // All categories that are selected, but not in values argument
  private removedCategories (values: string[]): string[] {
    return this.selectedCategories.filter(category => !values.includes(category));
  }

  // All categories that are selected, but not in values argument
  private removeCategories (values: string[]): string[] {
    const { treeCategories } = this.props;
    return values.filter(value => !(value in treeCategories));
  }

  // Builds a full list of all values and categories to be selected after the change
  private handleAddOrRemovedCategory (newValues: string[]): string[] {
    const { treeCategories } = this.props;

    return newValues.map(value => {
      // This looks for selected categories, and if it finds them, selects all child options
      if (value in treeCategories) {
        return [
          value,
          ...treeCategories[value].options.map(option => [value, option.value].join('.')),
        ];
      }

      // This looks at all options that are children of newly unselected categories, and removes them
      if (this.removedCategories(newValues).includes(value.split('.')[0])) {
        return [];
      }

      return value;
    }).flat();
  }

  private onChange (values: string[]) {
    const { onChange } = this.props
      , valuesAndCategories = this.handleAddOrRemovedCategory(values)
      , onlyValues = this.removeCategories(valuesAndCategories)
     ;

    // Locally, we store all values and categories and pass it down to TreeSelect
    this.values = valuesAndCategories;

    // But for the component API, we pass up a clean query object of category to string
    onChange(optionStringsToQuery(onlyValues));
  }

  public render () {
    const { children, isLoading } = this.props
      , buttonCaret = this.isOpen.isTrue ? <Icon.CaretUpSolid /> : <Icon.CaretDownSolid />
      ;

    return (
      <div className={styles.root}>
        <TreeSelect
          className={styles.select}
          dropdownClassName={styles.dropdown}
          dropdownMatchSelectWidth={false}
          onChange={this.onChange}
          onDropdownVisibleChange={this.isOpen.set}
          open={this.isOpen.isTrue}
          showArrow={false}
          tagRender={renderNothing}
          treeCheckable
          treeData={this.treeData}
          treeDefaultExpandAll
          value={this.values.slice()}
        />
        <Button
          className={this.isOpen.isTrue ? styles.open : undefined}
          loading={isLoading}
          onClick={this.isOpen.toggle}
        >
          {children}{buttonCaret}
        </Button>
      </div>
    );
  }
}

export default TreeSelectButton;
