import SmartBool from '@mighty-justice/smart-bool';

import { IAccount } from '../../models/User';

import AccountTab from './tab-account/AccountTab';
import CommunicationPreferencesTab from './tab-communication/CommunicationPreferencesTab';
import OrganizationTab from './tab-organization/OrganizationTab';
import ExternalSystemsTab from './tab-external-systems/ExternalSystemsTab';

export interface ISettingTabProps {
  isAdmin: boolean;
  isLoading: SmartBool;
  refresh: () => Promise<void>;
  account: IAccount;
}

export const ENDPOINTS = {
  ACCOUNT: '/accounts/',
  EXTERNAL_SYSTEMS: '/external-systems/',
  LEGAL_ORGANIZATION_EXTERNAL_SYSTEMS: '/legal-organization-external-systems/',
  NOTIFICATION_SETTINGS: '/notification-settings/',
  NOTIFICATION_TYPE_SETTINGS: '/notification-type-settings/',
  NOTIFICATION_SUBSCRIPTION_BLACKLIST_SETTING: '/notification-subscription-blacklist-setting/',
  NOTIFICATION_SUBSCRIPTION_SETTING: '/notification-subscription-setting/',
  USER_FLAGS: '/user-flags/',
};

export const TAB_KEYS = {
  ACCOUNT: 'account',
  COMMUNICATION_PREFERENCES: 'communication',
  ORGANIZATION: 'organization',
  EXTERNAL_SYSTEMS: 'external-systems',
};

export const TAB_COMPONENTS = {
  [TAB_KEYS.ACCOUNT]: AccountTab,
  [TAB_KEYS.COMMUNICATION_PREFERENCES]: CommunicationPreferencesTab,
  [TAB_KEYS.ORGANIZATION]: OrganizationTab,
  [TAB_KEYS.EXTERNAL_SYSTEMS]: ExternalSystemsTab,
};
