export const ADD_INTEGRATION_FIELD_SETS = {
  FILEVINE: [[
    { field: 'api_key', required: true },
    { field: 'api_secret', writeOnly: true, required: true },
  ]],
  LITIFY: [[
    { field: 'litify_username', label: 'username', required: true },
    { field: 'api_key', label: 'password', required: true },
    { field: 'api_secret', label: 'security_token', required: true },
  ]]
};
