import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import autoBindMethods from 'class-autobind-decorator';

import { Col, Row, Upload } from 'antd';

import Button from '../common/Button';
import StoresClass from '../../stores/StoresClass';
import { restrictDocumentSize } from '../../utils/utils';

import styles from './BulkUploadCaseForm.module.less';

interface IInjected {
  stores: StoresClass;
}

@inject('stores')
@autoBindMethods
@observer
class BulkUploadCaseForm extends Component<{}> {
  private get injected () { return this.props as IInjected; }

  public render () {
    const { caseFormStore } = this.injected.stores
      , isDocumentUploaded = !!caseFormStore.document;

    return (
      <Row id='upload-document'>
        <Col className={styles.form}>
          <h3 className={styles.option}>Option 1: Bulk Upload</h3>
          <p>
            Have a long list of cases? Upload a spreadsheet and they’ll be added to the portal.
          </p>
          {isDocumentUploaded && (
            <div className={styles.selected}>
              You selected: <b>{caseFormStore.document && caseFormStore.document.name}</b>
            </div>
          )}
          <Upload
            accept='.xls, .xlsx, .csv, .numbers'
            beforeUpload={restrictDocumentSize(caseFormStore.setDocumentFromList)}
            name='file'
            showUploadList={false}
          >
            <Button>{!isDocumentUploaded ? 'Choose a Spreadsheet File' : 'Replace File'}</Button>
          </Upload>
          {isDocumentUploaded && (
            <Button id='delete-button' className={styles.delete} onClick={caseFormStore.removeDocument}>
              Delete File
            </Button>
          )}
        </Col>
      </Row>
    );
  }
}

export default BulkUploadCaseForm;
