import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import Client from '../../base-modules/client';
import StoresClass from '../../stores/StoresClass';
import { PageLoader } from '../common';

import { TAB_COMPONENTS } from './settingsUtils';

interface IProps {
  tab: string;
}

interface IInjected extends IProps {
  client: Client;
  stores: StoresClass;
}

@inject('client', 'stores')
@observer
class SettingsPageContent extends Component<IProps> {
  private get injected () { return this.props as IInjected; }

  public render () {
    const { tab } = this.props
      , { client, stores: { users: { account }, settings: { fetch, isLoading, users } } } = this.injected
      , isAdmin = !!account?.isAdmin
      , legalOrganization = account?.registry_legal_organization
      , TabComponent = TAB_COMPONENTS[tab]
      , Loader = <PageLoader />
      ;

    if (users && account && legalOrganization) {
      return (
        <TabComponent
          account={account}
          isAdmin={isAdmin}
          isLoading={isLoading}
          refresh={fetch.bind(this, client)}
        />
      );
    }

    // This needs to be after the null checks above or
    // mobx will not notice them changing because ¯\_(ツ)_/¯
    if (isLoading.isTrue) {
      return Loader;
    }

    return null;
  }
}

export default SettingsPageContent;
