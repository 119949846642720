import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';

import SmartBool from '@mighty-justice/smart-bool';

import { LinkButton } from '../../common';

interface IProps {
  apiKey?: string;
}

@observer
class IntegrationApiKeyDisplay extends Component<IProps> {
  @observable isShowingKey = new SmartBool();

  public render () {
    if (this.isShowingKey.isTrue) {
      return (
        <span>
          {this.props.apiKey} <LinkButton onClick={this.isShowingKey.setFalse} id='btn-hide-key'>Hide</LinkButton>
        </span>
      );
    }
    return (
      <span>
        <LinkButton onClick={this.isShowingKey.setTrue} id='btn-show-key'>Show API key</LinkButton>
      </span>
    );
  }
}

export default IntegrationApiKeyDisplay;
