import React, { Component } from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import autoBindMethods from 'class-autobind-decorator';

import SmartBool from '@mighty-justice/smart-bool';

import { IDetailCase } from '../../../../models/Case';
import { IDetailLien } from '../../../../models/Lien';
import { Button, Icon } from '../../../common';
import ActivityHistoryDrawer from '../../activity-history/ActivityHistoryDrawer';

interface IProps {
  _case: IDetailCase;
  lien?: IDetailLien;
}

@autoBindMethods
@observer
class HistoryButton extends Component<IProps> {
  @observable private isViewingHistory = new SmartBool();

  public render () {
    const { _case, lien } = this.props
      , activities = lien ? lien.activities : _case.activities
      , title = lien ? 'Lien History' : 'Case History';

    return (
      <>
        {this.isViewingHistory.isTrue && (
          <ActivityHistoryDrawer
            activities={activities}
            title={title}
            isVisible={this.isViewingHistory}
          />
        )}
        <Button id='history-button' onClick={this.isViewingHistory.setTrue}><Icon.Timeline />History</Button>
      </>
    );
  }
}

export default HistoryButton;
