import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import autoBindMethods from 'class-autobind-decorator';

import { Spin } from 'antd';

import SmartBool from '@mighty-justice/smart-bool';

import PlaintiffFilter from '../../PlaintiffFilter';
import StoresClass from '../../../stores/StoresClass';

import PortfolioListScroll from './PortfolioListScroll';
import PortfolioListScrollHeader from './PortfolioListScrollHeader';

interface IProps {
  infiniteScrollHeight: number;
  queryParamsSet: SmartBool;
}

interface IInjected extends IProps {
  stores: StoresClass;
}

@inject('stores')
@autoBindMethods
@observer
class PortfolioList extends Component<IProps> {
  private get injected () { return this.props as IInjected; }
  private get infiniteTableStore () { return this.injected.stores.infiniteTableStore; }

  public render () {
    const { infiniteScrollHeight, queryParamsSet } = this.props;

    return (
      <Spin spinning={this.infiniteTableStore.isLoading || queryParamsSet.isFalse} size='large'>
        <PlaintiffFilter />
        <PortfolioListScrollHeader />
        <PortfolioListScroll infiniteScrollHeight={infiniteScrollHeight} />
      </Spin>
    );
  }
}

export default PortfolioList;
