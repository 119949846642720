import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import autoBindMethods from 'class-autobind-decorator';

import { Modal } from 'antd';

import StoresClass from '../../../../stores/StoresClass';
import { IDetailLien } from '../../../../models/Lien';

import ChatFooter from './ChatFooter';
import ChatThread from './ChatThread';
import { IChatProps } from './interfaces';

interface IProps extends IChatProps {
  lien: IDetailLien;
  onCancel: () => void;
}

interface IInjected extends IProps {
  stores: StoresClass;
}

@inject('stores')
@autoBindMethods
@observer
class ChatModal extends Component<IProps> {
  private get injected () { return this.props as IInjected; }

  private get title () {
    const { lien } = this.props
      , { isLawFirmUser } = this.injected.stores.users;

    return isLawFirmUser
      ? lien.lienholder.name
      : lien?._case?.law_firm?.name
    ;
  }

  public render () {
    const { lien, onCancel, ...passDownProps } = this.props;

    return (
      <Modal footer={<ChatFooter {...passDownProps} />} onCancel={onCancel} title={this.title} visible>
        <ChatThread lien={lien} />
      </Modal>
    );
  }
}

export default ChatModal;
