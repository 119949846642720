import React from 'react';
import { observer } from 'mobx-react';

import SmartBool from '@mighty-justice/smart-bool';

import { ILegalOrganizationWithContacts } from '../../../../models/Party';

import NewOrganizationContactModal from './NewOrganizationContactModal';
import ManageContactsModal from './ManageContactsModal';

interface IProps {
  isNewOrganization: boolean;
  isVisible: SmartBool;
  legalOrganization: ILegalOrganizationWithContacts | null;
}

// for use after adding an organization
// wraps the two types of contact modals
// (the simple singular add and the manage all contacts modal)
const AddContactsModal = observer((props: IProps) => {
  const { isNewOrganization, isVisible, legalOrganization } = props;

  if (!legalOrganization) { return null; }

  if (isNewOrganization) {
    return (
      <NewOrganizationContactModal isVisible={isVisible} legalOrganization={legalOrganization} />
    );
  }

  return <ManageContactsModal isVisible={isVisible} legalOrganization={legalOrganization} />;
});

export default AddContactsModal;
