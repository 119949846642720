import 'core-js/stable';
import 'regenerator-runtime/runtime';

import React from 'react';
import ReactDOM from 'react-dom';

import AppWrapper from './AppWrapper';

import './base-modules/fonts';
import { printPerformance } from './utils/utils';
import './index.less';

printPerformance('Start');
ReactDOM.render(<AppWrapper />, document.getElementById('root'));
