import React, { Component } from 'react';
import { observer } from 'mobx-react';
import autoBindMethods from 'class-autobind-decorator';
import { findIndex } from 'lodash';
import cx from 'classnames';

import { Steps } from '../common';
import { getPath } from '../../utils/navigationUtils';

import styles from './UpgradeSteps.module.less';

const STEPS = [
  {
    path: 'overview',
    title: 'Overview',
  },
  {
    path: 'choose',
    title: 'Choose Plan',
  },
  {
    path: 'payment',
    title: 'Review & Pay',
  },
];

function getStatus (idx: number, currentStep: number): 'wait' | 'finish' | 'process' {
  if (idx > currentStep) { return 'wait'; }
  if (idx < currentStep) { return 'finish'; }
  return 'process';
}

@autoBindMethods
@observer
class UpgradeSteps extends Component<{}> {
  private get currentPath () {
    return getPath().split('/').filter((s: string) => !!s).pop();
  }

  private get currentStep () {
    return findIndex(STEPS, { path: this.currentPath });
  }

  public render () {
    const currentStep = this.currentStep;

    return (
      <Steps
        className={cx(styles.root, styles.override)}
        current={currentStep}
        type='navigation'
      >
        {STEPS.map(({ path, title }, idx) => (
          <Steps.Step
            key={path}
            status={getStatus(idx, currentStep)}
            title={title}
          />
        ))}
      </Steps>
    );
  }
}

export default UpgradeSteps;
