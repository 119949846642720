import React, { Component } from 'react';
import { observer } from 'mobx-react';
import autoBindMethods from 'class-autobind-decorator';
import { pickBy } from 'lodash';

import { Button } from '../../common';
import { ITableStoreAPI } from '../../../stores/ITableStoreAPI';
import { getQuery, INavigateProps } from '../../../utils/navigationUtils';
import navigationWrapper from '../../../utils/navigationWrapper';

interface IProps extends INavigateProps {
  field: string;
  hasValue: boolean;
  isCollapsed?: boolean;
  tableStore: ITableStoreAPI;
  toggleCollapsed?: () => void;
}

@autoBindMethods
@observer
class FacetHeader extends Component<IProps> {
  private renderButton (onClick: () => void, label: string, buttonProps = {}) {
    return (
      <Button onClick={onClick} size='small' {...buttonProps}>
        {label}
      </Button>
    );
  }

  private async reset () {
    const { field, searchParams: [, setQuery], tableStore } = this.props
      , keys = Object.keys(getQuery()).filter(key => key.startsWith(field))
      , queryChange = Object.fromEntries(keys.map(key => [key, undefined]))
      , newQuery = {
        ...getQuery(),
        ...queryChange,
      }
      , cleanedQuery = pickBy(newQuery, v => v !== undefined);

    setQuery(cleanedQuery);
    await tableStore.onFilterChange(queryChange);
  }

  private renderCollapseIcon () {
    const { hasValue, isCollapsed, toggleCollapsed } = this.props
      , className = 'btn-collapser';

    if (!toggleCollapsed || hasValue) {
      return null;
    }

    if (isCollapsed) {
      return this.renderButton(toggleCollapsed, 'Show', { className });
    }

    return this.renderButton(toggleCollapsed, 'Hide', { className, disabled: hasValue });
  }

  private renderResetIcon () {
    const { hasValue, tableStore } = this.props;

    if (!hasValue) { return null; }

    return this.renderButton(this.reset, 'Clear', {
      className: 'btn-reset',
      loading: tableStore.isLoading,
      type: 'primary',
    });
  }

  public render () {
    return (
      <Button.Group>
        {this.renderCollapseIcon()}
        {this.renderResetIcon()}
      </Button.Group>
    );
  }
}

export default navigationWrapper(FacetHeader);
