import React from 'react';

import LoggedOutLandingPage from '../LoggedOutLandingPage';
import { LoginLink } from '../../utils/utils';

const RequestAlreadyFulfilledPage = () => (
  <LoggedOutLandingPage title='Request Already Fulfilled'>
    <h2>This request has already been fulfilled!</h2>
    <h3>The law firm on file has been notified</h3>
    <LoginLink />
  </LoggedOutLandingPage>
);

export default RequestAlreadyFulfilledPage;
