import React, { Component } from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import autoBindMethods from 'class-autobind-decorator';

import { DOCUMENTS_MAP } from '../constants';
import { getAssociatedOrganizations } from '../../../../../utils/utils';
import { IBetaDocument } from '../../../../../models/Document';
import { IDetailCase } from '../../../../../models/Case';
import { IDocumentFilterData } from '../../../../../interfaces';
import { ITreeCategories } from '../../../../common/TreeSelectButton';
import { TreeSelectButton } from '../../../../common';
import { IQuery } from '../../../../../utils/navigationUtils';

interface IProps extends IDocumentFilterData {
  _case: IDetailCase;
}

@autoBindMethods
@observer
class DocumentsFilter extends Component<IProps> {
  @observable documents: IBetaDocument[] = [];
  @observable numberOfFilters: number = 0;

  public componentDidMount () {
    const { _case: { documents }, filters } = this.props;

    this.documents = documents;
    this.numberOfFilters = 0;
    filters.type = [];
    filters.created_by_organization_id = [];
  }

  private get treeCategories (): ITreeCategories {
    const { _case } = this.props
      , organizations = getAssociatedOrganizations(_case);

    return {
      created_by_organization_id: {
        name: 'Uploaded By:',
        options: organizations.map(({ name, id }) => ({ name, value: id })),
      },
      type: {
        name: 'Document Type:',
        options: DOCUMENTS_MAP,
      },
    };
  }

  private async onChange (filters: IQuery): Promise<void> {
    const { _case, onFiltersChange, isDocumentsLoading } = this.props;

    onFiltersChange(filters);
    this.numberOfFilters = Object.values(filters)
      .reduce((prev, curr) => prev + (curr || []).length, 0);

    await isDocumentsLoading.until(_case.fetchDocuments(filters));
  }

  public render () {
    const { isDocumentsLoading } = this.props
      , filterLabel = this.numberOfFilters ? `(${this.numberOfFilters} applied)` : '';

    return (
      <TreeSelectButton
        isLoading={isDocumentsLoading.isTrue}
        onChange={this.onChange}
        treeCategories={this.treeCategories}
      >
        Filter {filterLabel}
      </TreeSelectButton>
    );
  }
}

export default DocumentsFilter;
