import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import autoBindMethods from 'class-autobind-decorator';
import cx from 'classnames';
import { startCase } from 'lodash';

import { Col, Row } from 'antd';

import { Card, Spacer } from '../../common';

import StoresClass from '../../../stores/StoresClass';
import { ILegalOrganizationExternalSystem } from '../../../models/LegalOrganizationExternalSystem';
import { ISettingTabProps } from '../settingsUtils';

import AddIntegrationButton from './AddIntegrationButton';
import IntegrationApiKeyDisplay from './IntegrationApiKeyDisplay';
import IntegrationSourceLogo from './IntegrationSourceLogo';

import styles from './IntegrationsCard.module.less';

interface IInjected extends ISettingTabProps {
  stores: StoresClass;
}

@inject('stores')
@autoBindMethods
@observer
class IntegrationsCard extends Component<ISettingTabProps> {
  private get injected () { return this.props as IInjected; }

  public render () {
    const { stores: { externalSystems: { integratedSystems } } } = this.injected;

    if (!integratedSystems.length) { return null; }

    return (
      <Card className={styles.root}>
        <Row align='middle' justify='space-between'>
          <Col><h2 className={styles.header}>Integrations</h2></Col>
        </Row>
        <Spacer />
        <Row>
          {integratedSystems.map((system: ILegalOrganizationExternalSystem, index: number) => (
            <Row key={system.external_system.id} className={cx({ [styles.systemRow]: index !== 0 })}>
              <Col span={8} className={styles.title}>
                <IntegrationSourceLogo system={system} />
                <b>&emsp;{startCase(system.external_system.external_system_id)}</b>
              </Col>
              <Col className={styles.credentials}>
                {system.api_key ?
                  <IntegrationApiKeyDisplay apiKey={system.api_key} /> :
                  <AddIntegrationButton system={system} refresh={this.props.refresh} />
                }
              </Col>
            </Row>
          ))}
        </Row>
      </Card>
    );
  }
}

export default IntegrationsCard;
