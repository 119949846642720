import React, { Component } from 'react';
import { observer } from 'mobx-react';
import autoBindMethods from 'class-autobind-decorator';

import TableStore from '../../../stores/TableStore';
import Dropdown, { IDropdownItem } from '../../common/Dropdown';
import { formatSortingParams, unformatOrderingParams } from '../../../utils/utils';

interface IProps {
  tableStore: TableStore;
}

function formatOrderingString (ordering: string): string {
  const desc = ordering[0] === '-';
  return desc ? 'Newest First' : 'Oldest First';
}

function orderingToDropdownItem (ordering: { desc?: boolean, id?: string }): IDropdownItem {
  const value = formatSortingParams(ordering)
    , label = `Sort by Case Creation Date: ${formatOrderingString(value)}`;

  return { label, value };
}

@autoBindMethods
@observer
class OrderingDropdown extends Component<IProps> {
  private async onOptionSelect (option: IDropdownItem) {
    const { tableStore } = this.props;
    tableStore.ordering = unformatOrderingParams(option.value);
    await tableStore.fetchTableData();
  }

  private get selectedOption (): IDropdownItem {
    const { tableStore } = this.props
      , { ordering } = tableStore;

    if (!ordering) { return { label: 'Order by', value: '' }; }
    return orderingToDropdownItem(ordering);
  }

  private get options (): IDropdownItem[] {
    return [
      '-last_created_at',
      'last_created_at',
    ].map(value => ({ label: formatOrderingString(value), value }));
  }

  public render () {
    return (
      <Dropdown
        buttonProps={{ text: this.selectedOption.label }}
        onOptionSelect={this.onOptionSelect}
        options={this.options}
        selectedOption={this.selectedOption}
      />
    );
  }
}

export default OrderingDropdown;
