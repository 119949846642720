export const DOCUMENTS_MAP = [
  { name: 'Liability Document', value: 'LIABILITY_DOCUMENT' },
  { name: 'Signed Lien Letter', value: 'SIGNED_LIEN_LETTER' },
  { name: 'Medical Records', value: 'MEDICAL_RECORDS' },
  { name: 'Bill', value: 'BILL' },
  { name: 'Other', value: 'OTHER' },
  { name: 'HIPAA Release', value: 'HIPAA_RELEASE' },
  { name: 'Letter of Request', value: 'LETTER_OF_REQUEST' },
];

export const SHARED_DOCUMENTS_MAP = [
  { name: 'HIPAA release', value: 'HIPAA_RELEASE' },
  { name: 'Letter of Request', value: 'LETTER_OF_REQUEST' },
];

export const ADD_NEW_LIENHOLDER_OPTION = {
  name: '+ Add an organization to this case',
  value: 'ADD_NEW_LIENHOLDER',
};
