import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { observable } from 'mobx';

import { Dropdown, Menu } from 'antd';

import SmartBool from '@mighty-justice/smart-bool';

import StoresClass from '../../../../stores/StoresClass';
import { IDetailCase } from '../../../../models/Case';
import { IDetailLien } from '../../../../models/Lien';
import { ILegalOrganizationWithContacts } from '../../../../models/Party';
import { Icon } from '../../../common';

import ChangeLawFirmModal from './ChangeLawFirmModal';
import ManageContactsModal from './ManageContactsModal';
import RemoveLienModal from './RemoveLienModal';

import styles from './MoreDropdown.module.less';

interface IProps {
  _case?: IDetailCase;
  legalOrganization: ILegalOrganizationWithContacts;
  lien?: IDetailLien;
}

interface IInjected extends IProps {
  stores: StoresClass;
}

@inject('stores')
@observer
class MoreDropdown extends Component<IProps> {
  @observable private isManagingContacts = new SmartBool();
  @observable private isRemovingLien = new SmartBool();
  @observable private isChangingLawFirm = new SmartBool();

  private get injected () { return this.props as IInjected; }

  private get menu () {
    const { stores: { users: { isLawFirmUser } } } = this.injected
      , { _case, lien } = this.props
      ;

    return (
      <Menu className={styles.moreDropdown}>
        {!isLawFirmUser && _case && (
          <Menu.Item key='change-law-firm' id='change-law-firm' onClick={this.isChangingLawFirm.toggle}>
            <Icon.User />Change Law Firm
          </Menu.Item>
        )}
        <Menu.Item key='manage-contacts' id='manage-contacts' onClick={this.isManagingContacts.toggle}>
          <Icon.User />Manage Contacts
        </Menu.Item>
        {isLawFirmUser && lien && (
          <Menu.Item className={styles.remove} key='remove-lien' id='remove-lien' onClick={this.isRemovingLien.toggle}>
            <Icon.TrashAlt />Remove
          </Menu.Item>
        )}
      </Menu>
    );
  }

  public render () {
    const { _case, lien } = this.props;

    return (
      <>
        <Dropdown
          overlayClassName={styles.dropdown}
          overlay={this.menu}
          placement='bottomLeft'
          trigger={['click']}
        >
          <div id='more-dropdown' className={styles.moreButton}>
            More <Icon.CaretDownSolid />
          </div>
        </Dropdown>
        <ManageContactsModal
          isVisible={this.isManagingContacts}
          legalOrganization={this.props.legalOrganization}
        />
        {lien && (
          <RemoveLienModal
            isVisible={this.isRemovingLien}
            onSave={lien.removeFromCase}
            plaintiffName={lien._case?.plaintiff.fullName}
          />
        )}
        {_case && <ChangeLawFirmModal _case={_case} isVisible={this.isChangingLawFirm} />}
      </>
    );
  }
}

export default MoreDropdown;
