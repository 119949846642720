import { flow, Instance, types } from 'mobx-state-tree';
import { find } from 'lodash';

import { ExternalSystem } from './ExternalSystem';
import Client from '../base-modules/client';
import { ENDPOINTS } from '../components/page-settings/settingsUtils';
import { INTEGRATED_EXTERNAL_SYSTEM_IDS, INTEGRATED_EXTERNAL_SYSTEMS } from '../utils/constants';

export const LegalOrganizationExternalSystem = types
  .model('LegalOrganizationExternalSystem', {
    external_system: ExternalSystem,
    id: types.identifier,
    registry_legal_organization: types.string,
    api_key: '',
  })
  .actions(self => {
    const addIntegration = flow(function* (client: Client, submitData: { api_key: string, api_secret: string }) {
      const endpoint = `${ENDPOINTS.LEGAL_ORGANIZATION_EXTERNAL_SYSTEMS}${self.id}/add_integration/`
        , response = yield client.update(endpoint, submitData);
      self.api_key = submitData.api_key;
      return response.data;
    });

    return { addIntegration };
  })
  .views(self => ({
    get canIntegrate () {
      return INTEGRATED_EXTERNAL_SYSTEM_IDS.includes(self.external_system.external_system_id.toLowerCase());
    },

    get integratedSystemLogoPath () {
      const id = self.external_system.external_system_id.toLowerCase()
        , systemConfig = find(INTEGRATED_EXTERNAL_SYSTEMS, { id })
        ;

      if (!systemConfig || !systemConfig.hasLogo) {
        return null;
      }
      return `${process.env.PUBLIC_URL}/assets/images/${id}-logo.png`;
    },
  }))
  ;

export interface ILegalOrganizationExternalSystem extends Instance<typeof LegalOrganizationExternalSystem> {}
