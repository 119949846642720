import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import autoBindMethods from 'class-autobind-decorator';
import cx from 'classnames';

import { Col, Row } from 'antd';

import { getNameOrDefault, formatDate } from '@mighty-justice/utils';

import ChatButton from '../chat/ChatButton';
import StoresClass from '../../../../stores/StoresClass';
import { ANT_THIRD_COL_WIDTH } from '../../../../utils/constants';
import { Button, Icon, Section } from '../../../common';
import { IDetailCase, IListCase } from '../../../../models/Case';
import { IDetailLien } from '../../../../models/Lien';

import CaseStatusInfo from './CaseStatusInfo';
import LawFirmActionDropdown from './LawFirmActionDropdown';
import LienholderActionDropdown from './LienholderActionDropdown';
import HistoryButton from './HistoryButton';

import styles from './CaseHeader.module.less';

// For now, having both case and lien be passed when logged in as a LH
// assuming that the new API structure will fetch cases for both user types
interface IProps {
  _case: IDetailCase;
  lien?: IDetailLien;
}

interface IInjected extends IProps {
  stores: StoresClass;
}

function renderBasicInfoCol (_case: IDetailCase | IListCase) {
  return (
    <Col span={16}>
      <Row className={cx(styles['plaintiff-name'], 'dd-privacy-mask')}>
        <h1>
          {getNameOrDefault(_case.plaintiff)}
        </h1>
      </Row>
      <Row className={cx(styles['plaintiff-info'], 'dd-privacy-mask')}>
        <p>DOB: {formatDate(_case.plaintiff.birthdate)} | DOL: {formatDate(_case.date_of_loss)}</p>
      </Row>
      <CaseStatusInfo _case={_case} />
    </Col>
  );
}

@inject('stores')
@autoBindMethods
@observer
class CaseHeader extends Component<IProps> {
  private get injected () { return this.props as IInjected; }

  public render () {
    const { _case, lien } = this.props
      , { stores: { users: { isLawFirmUser } } } = this.injected
      ;

    return (
      <Section className={styles.root}>
        <Row align='top' gutter={16} justify='start'>
          {renderBasicInfoCol(_case)}
          <Col span={ANT_THIRD_COL_WIDTH}>
            <Row className={styles['col-header-buttons']}>
              {isLawFirmUser && <LawFirmActionDropdown _case={_case} />}
              {lien && <LienholderActionDropdown _case={_case} lien={lien} />}
              <ChatButton _case={_case} lien={lien} />
              <HistoryButton _case={_case} lien={lien} />
            </Row>
          </Col>
        </Row>
      </Section>
    );
  }
}

export function ListCaseHeader ({ _case }: { _case: IListCase }) {
  return (
    <Section className={styles.root}>
      <Row align='top' gutter={16} justify='start'>
        {renderBasicInfoCol(_case)}
        <Col span={ANT_THIRD_COL_WIDTH}>
          <Row className={styles['col-header-buttons']}>
            <Button disabled>Actions<Icon.CaretDownSolid /></Button>
            <Button loading disabled>Chat<Icon.Comment /></Button>
            <Button disabled><Icon.Timeline />History</Button>
          </Row>
        </Col>
      </Row>
    </Section>
  );
}

export default CaseHeader;
