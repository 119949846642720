import React from 'react';
import { IValue } from '@mighty-justice/fields-ant/dist/props';

import { ON_OFF_OPTIONS, OPTION_KEYS } from '../../../utils/constants';
import { renderOnOff } from '../../../utils/utils';
import { Tag } from '../../common';
import { withColumns } from '../../../utils/configCommon';

export const getUserCardFieldSets = (
  isEmailVisible: boolean,
  isAdmin: boolean,
  isLawFirmUser: boolean,
) => [[
  {
    field: 'role',
    insertIf: () => isAdmin,
    readOnly: true,
    render: () => <Tag>Admin</Tag>,
  },
  { field: 'first_name', required: true, writeOnly: true },
  { field: 'last_name', required: true, writeOnly: true },
  { field: 'email', required: true, editProps: { disabled: true } },
  { field: 'phone_number' },
  {
    field: 'is_email_visible',
    insertIf: () => isLawFirmUser,
    label: 'Email Visible to Other Users',
    options: ON_OFF_OPTIONS,
    render: () => renderOnOff(isEmailVisible),
    toForm: (_value: IValue) => isEmailVisible.toString(),
    type: 'optionSelect',
  },
]].map(withColumns(2));

export const LEGAL_ORGANIZATION_FIELD_SETS = [[
  { field: 'name' },
  { field: 'type', type: 'optionSelect', optionType: OPTION_KEYS.LEGAL_ORGANIZATION_TYPE },
]].map(withColumns(2));
