import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { observable } from 'mobx';
import autoBindMethods from 'class-autobind-decorator';
import { get, pull, some } from 'lodash';

import { Checkbox, notification, Row, Table } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { Key, TableRowSelection } from 'antd/lib/table/interface';

import SmartBool from '@mighty-justice/smart-bool';

import { DEDUPE_STATUS, MODELS } from '../../../utils/constants';
import { Button, ButtonToolbar, Card, LinkButton } from '../../common';
import Client from '../../../base-modules/client';
import { IMultiMatch, IDedupeModel } from '../../../models/DedupeMatch';
import { fillInColumnProfile, IColumnProfile } from '../../../utils/columnCommon';

import { caseDedupeMatchColumnsPartial } from './case/tableColumns';
import { contactDedupeMatchColumnsPartial } from './contact/tableColumns';

interface IProps {
  match: IMultiMatch;
  filters: { [key: string]: string };
}

interface IInjected extends IProps {
  client: Client;
}

@inject('client')
@autoBindMethods
@observer
class MultiMatchCard extends Component<IProps> {
  @observable private isSubmitting = new SmartBool();
  @observable public selectedMasterKey: null | string = null;
  @observable public selectedDuplicateKeys: string[] = [];

  private get injected () {
    return this.props as IInjected;
  }

  private getIsDisabled (statusesToDisable: string[] = []) {
    const currentStatus = get(this.props.filters, 'status');

    return this.isSubmitting.isTrue || [...statusesToDisable, DEDUPE_STATUS.DISMISSED].includes(currentStatus);
  }

  private get modelProps () {
    const { match } = this.props
      , hasUserContacts = some(match.matched_objects, { is_user: true })
      , MODEL_PROPS = {
        [MODELS.CASE]: {
          allowMasterSelection: (_record: IDedupeModel) => true,
          columns: caseDedupeMatchColumnsPartial,
          header: get(match, 'matched_objects[0].law_firm.name'),
          modelName: 'case',
        },
        [MODELS.CONTACT]: {
          allowMasterSelection: (_record: IDedupeModel) => _record.is_user || !hasUserContacts,
          columns: contactDedupeMatchColumnsPartial,
          header: get(match, 'matched_objects[0].legal_organization_name'),
          modelName: 'contact',
        },
      };

    // istanbul ignore next
    if (!MODEL_PROPS[match.model_type]) {
      throw new Error(`modelProps(type='${match.model_type}') not found in ${Object.keys(MODEL_PROPS).join(', ')}`);
    }

    return MODEL_PROPS[match.model_type];
  }

  private get columns (): Array<ColumnProps<IDedupeModel>> {
    const selectForMerge: IColumnProfile<IDedupeModel> = {
      align: 'center',
      field: 'Select for Merge',
      render: (_value: any, record: IDedupeModel) => {
        if (this.selectedMasterKey === record.id) {
          return null;
        }

        return (
          <Checkbox
            checked={this.selectedDuplicateKeys.includes(record.id)}
            disabled={this.getIsDisabled()}
            key={record.id}
            onChange={this.handleDuplicateRowSelect.bind(this, record.id) as any}
          />
        );
      },
      width: 100,
    };

    return [
      selectForMerge,
      ...this.modelProps.columns,
    ].map(fillInColumnProfile) as unknown as Array<ColumnProps<IDedupeModel>>;
  }

  private handleDuplicateRowSelect (key: string, _event: unknown) {
    if (this.selectedDuplicateKeys.includes(key)) {
      pull(this.selectedDuplicateKeys, key);
    }
    else {
      this.selectedDuplicateKeys = [...this.selectedDuplicateKeys, key];
    }
  }

  private handleMasterRowSelect (selectedRowKeysArg: Key[], _selectedRows: unknown) {
    const selectedRowKeys = selectedRowKeysArg as string[];
    this.selectedMasterKey = selectedRowKeys.length ? selectedRowKeys[0] : null;
    if (selectedRowKeys.length) { pull(this.selectedDuplicateKeys, selectedRowKeys[0]); }
  }

  private getMasterCheckboxProps (_record: IDedupeModel) {
    const disabled = this.getIsDisabled() || !this.modelProps.allowMasterSelection(_record);
    return { disabled };
  }

  private get rowSelection (): TableRowSelection<IDedupeModel> {
    return {
      columnTitle: `Master ${this.modelProps.modelName}`,
      columnWidth: '100px',
      getCheckboxProps: this.getMasterCheckboxProps,
      onChange: (selectedRowKeys: Key[], selectedRows: unknown[]) => {
        this.handleMasterRowSelect(selectedRowKeys, selectedRows);
      },
      selectedRowKeys: this.selectedMasterKey ? [this.selectedMasterKey] : [],
      type: 'radio',
    };
  }

  private async merge () {
    const { match } = this.props
      , { client } = this.injected
      , submitData = {
        canonical_id: this.selectedMasterKey,
        merge_ids: this.selectedDuplicateKeys,
      };

    try {
      await this.isSubmitting.until(match.merge(client, submitData));
      notification.success({ message: 'Dedupe', description: 'Match successfully merged!' });
    }
    catch (error) {
      notification.error({ message: 'Dedupe', description: 'Merging error occurred' });
      throw error;
    }
  }

  private dismissMatch () {
    const { match } = this.props,
      { client } = this.injected;

    match.dismiss(client);
    notification.info({ message: 'Dedupe', description: 'Match dismissed.' });
  }

  private dismissNeedsMoreInfo () {
    const { match } = this.props,
      { client } = this.injected;

    match.dismissNeedsMoreInfo(client);
    notification.info({ message: 'Dedupe', description: 'Match marked as needing more information.' });
  }

  public renderMatchHeader () {
    return (
      <Row justify='space-between'>
        <h4>{this.modelProps.header}</h4>
        <ButtonToolbar align='right' noSpacing>
          <LinkButton
            buttonSize='small'
            className='btn-dismiss-match'
            disabled={this.getIsDisabled()}
            onClick={this.dismissMatch}
            type='danger'
          >
            Dismiss
          </LinkButton>
          <LinkButton
            buttonSize='small'
            className='btn-needs-more-info'
            disabled={this.getIsDisabled([DEDUPE_STATUS.NEEDS_MORE_INFORMATION])}
            onClick={this.dismissNeedsMoreInfo}
            type='danger'
          >
            Needs more information
          </LinkButton>
        </ButtonToolbar>
      </Row>
    );
  }

  public renderMatchFooter () {
    const cannotMerge = !this.selectedDuplicateKeys.length || !this.selectedMasterKey
      , isMergeDisabled = cannotMerge || this.getIsDisabled();

    return (
      <ButtonToolbar align='right' noSpacing>
        <Button className='btn-merge' onClick={this.merge} disabled={isMergeDisabled} type='primary'>
          Merge
        </Button>
      </ButtonToolbar>
    );
  }

  public render () {
    const { match } = this.props;

    return (
      <Card className='dedupe-match'>
        {this.renderMatchHeader()}
        <Table
          bordered
          className='ant-table-multi-match-dedupe'
          columns={this.columns}
          dataSource={match.matched_objects.slice()}
          pagination={false}
          rowKey='id'
          rowSelection={this.rowSelection}
        />
        {this.renderMatchFooter()}
      </Card>
    );
  }
}

export default MultiMatchCard;
