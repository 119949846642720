import React from 'react';

import { IDetailCase } from '../../../../models/Case';

import LawFirmCard from './LawFirmCard';
import SectionTitle from './SectionTitle';
import OldLawFirmCard from './OldLawFirmCard';

interface IProps {
  _case: IDetailCase;
}

function LawFirms (props: IProps) {
  const { _case } = props;

  return (
    <div>
      <SectionTitle title='Law Firms' />
      <LawFirmCard _case={_case} />
      <OldLawFirmCard _case={_case} />
    </div>
  );
}

export default LawFirms;
