import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { IActivityDetailComponentProps } from './interfaces';

@observer
class LawFirmPostedNoteActivity extends Component<IActivityDetailComponentProps> {
  public render () {
    return (
      <>
        <h4>Note from law firm</h4>
        <p className='quote'>{this.props.activity.note}</p>
      </>
    );
  }
}

export default LawFirmPostedNoteActivity;
