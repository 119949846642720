import React, { Component } from 'react';
import { observer } from 'mobx-react';
import autoBindMethods from 'class-autobind-decorator';
import { reduce, mapKeys } from 'lodash';
import cx from 'classnames';

import { Col, Row } from 'antd';

import {
  IFieldConfig,
  getFieldSetFields,
  renderLabel,
  renderValue,
  IFieldSet,
} from '@mighty-justice/fields-ant';

import { Card, Icon } from '../../common';
import { IDetailCase } from '../../../models/Case';
import { IManualDedupeCase } from '../../../models/ManualDedupeCase';
import { IPreviewSummary } from '../../../models/ManualDedupeMatch';

import styles from './CaseDedupeCard.module.less';

const PREVIEW_SUMMARY_MAPPING: { [key: string]: string } = {
  law_firm_name: 'law_firm.name',
  plaintiff_birthdate: 'plaintiff.birthdate',
  plaintiff_name: 'plaintiff',
};

function mapPreviewFields (data: { [key: string]: any }) {
  return mapKeys(data, (_value, key: string) => (PREVIEW_SUMMARY_MAPPING[key] || key));
}

interface IProps {
  _case: IDetailCase | IManualDedupeCase;
  className?: string,
  fieldSet: IFieldSet,
  onCaseSelect?: (_case: null) => void;
  previewSummary: IPreviewSummary | null;
  title: string | React.ReactNode;
}

@autoBindMethods
@observer
class CaseDedupeCard extends Component<IProps> {
  private get cardTitle () {
    const { onCaseSelect, title } = this.props;

    return (
      <Row>
        {title}
        {onCaseSelect && (
          <a className={styles['choice-link']} onClick={onCaseSelect.bind(this, null)}>
            Choose different case
          </a>
        )}
      </Row>
    );
  }

  private get selectedFields () {
    // returns a list of field keys in the preview summary that match the given case.
    // if _case.id is 123 and the preview data is { plaintiffName: { source_id: 123, ... } }, then ['plaintiffName']
    const { _case, previewSummary } = this.props;

    if (!previewSummary) {
      return null;
    }

    return reduce(
      // some of the field names in the preview summary response do not match the model accessor names
      mapPreviewFields(previewSummary.data),
      (result: any, value, key) => {
        if (value.source_id === _case.id) { result.push(key); }
        return result;
      },
      [],
    );
  }

  public renderField (fieldConfig: IFieldConfig) {
    const { _case } = this.props
      , isSelected = this.selectedFields && this.selectedFields.includes(fieldConfig.field)
      ;

    return (
      <Row className={styles['field-row']} key={fieldConfig.field}>
        <Col className={styles['checkbox-column']}>
          {isSelected && <Icon.CheckCircle />}
        </Col>
        <Col>
          <Row className={styles['field-value']}>
            <b>{renderValue(fieldConfig, _case)}</b>
          </Row>
          <Row className={styles['field-label']}>
            {renderLabel(fieldConfig)}
          </Row>
        </Col>
      </Row>
    );
  }

  public render () {
    // TODO: Consider replacing all this with a fields-ant Card
    const { className, fieldSet } = this.props;

    return (
      <Card
        bordered={false}
        className={cx(styles.root, className)}
        title={this.cardTitle}
      >
        {getFieldSetFields(fieldSet).map(this.renderField)}
      </Card>
    );
  }
}

export default CaseDedupeCard;
