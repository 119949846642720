import React from 'react';

import { Col, Row } from 'antd';

import { formatMoney } from '@mighty-justice/utils';

interface IProps {
  className?: string;
  name: string;
  price: number;
}

function SummaryLineItem (props: IProps) {
  const { className, name, price } = props;

  return (
    <Row className={className}>
      <Col span={20}>
        {name}
      </Col>
      <Col span={4}>
        {formatMoney(price)}
      </Col>
    </Row>
  );
}

export default SummaryLineItem;
