import { insertIf } from '@mighty-justice/utils';

import { BOOLEAN_STRING, NULL_OPTION } from '../../../utils/constants';
import { IFacet } from '../interfaces';

export function getToggleFacets (isLawFirmUser: boolean, linkedContact: string | undefined): IFacet[] {
  const appendCase = (field: string) => (isLawFirmUser ? field : `liens__${field}`)
    , contactsField = appendCase('contacts');

  return [
    {
      editComponentProps: { disabled: !linkedContact, appliedValue: linkedContact },
      field: contactsField,
      label: 'My Cases',
      type: 'toggle',
    },
    ...insertIf<IFacet>(isLawFirmUser, {
      editComponentProps: { appliedValue: NULL_OPTION.value },
      field: contactsField,
      label: 'Unassigned',
      type: 'toggle',
    }),
    {
      editComponentProps: { appliedValue: BOOLEAN_STRING.TRUE, exclusive: true },
      field: 'needs_action',
      label: 'Needs Update',
      type: 'toggle',
    },
  ];
}
