import React, { Component } from 'react';
import { observer } from 'mobx-react';
import autoBindMethods from 'class-autobind-decorator';

import { Drawer } from 'antd';

import SmartBool from '@mighty-justice/smart-bool';

import { ITableStoreAPI } from '../../stores/ITableStoreAPI';

import FacetSets from './FacetSets';
import { IFacetSet } from './interfaces';

const FACET_DRAWER_WIDTH = 360;

interface IProps {
  facetSets: IFacetSet[];
  isVisible: SmartBool;
  tableStore: ITableStoreAPI;
}

@autoBindMethods
@observer
class FacetsDrawer extends Component<IProps> {
  public render () {
    const { isVisible, facetSets, tableStore } = this.props;

    return (
      <Drawer
        className='drawer-facets'
        closable
        onClose={isVisible.setFalse}
        placement='left'
        title='Filters'
        visible={isVisible.isTrue}
        width={FACET_DRAWER_WIDTH}
      >
        {isVisible.isTrue && <FacetSets facetSets={facetSets} tableStore={tableStore} />}
      </Drawer>
    );
  }
}

export default FacetsDrawer;
