import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import autoBindMethods from 'class-autobind-decorator';

import StoresClass from '../../../../stores/StoresClass';

import DefaultMightyNotification, { IDefaultNotificationProps } from './DefaultMightyNotification';

interface IInjected extends IDefaultNotificationProps {
  stores: StoresClass;
}

@inject('stores')
@autoBindMethods
@observer
class NeedsMoreInformationNotification extends Component<IDefaultNotificationProps> {
  private get injected () {
    return this.props as IInjected;
  }

  public async onClick () {
    const { isVisible, onClick, notification } = this.props
      , { stores } = this.injected
      ;

    await onClick(notification);
    isVisible.setFalse();
    stores.uiStore.addLienholderInfoModalVisible.setTrue();
  }

  public render () {
    return <DefaultMightyNotification {...this.props} onClick={this.onClick} />;
  }
}

export default NeedsMoreInformationNotification;
