import { getParent, Instance, flow, types } from 'mobx-state-tree';

import { stateTreeDependencyGetters } from '../base-modules/state-tree-dependencies';
import { IBetaDocumentSubmitData } from '../components/page-portfolio/portfolio-detail/tab-documents/interfaces';
import { LAW_FIRM_CASE_DOCUMENT_TYPES, LIENHOLDER_BASE_DOCUMENT_TYPES, URLS } from '../utils/constants';
import { mergePathAndQuery } from '../utils/navigationUtils';
import { calculateFileMd5 } from '../utils/utils';

import { IDetailCase } from './Case';
import { IDetailLien } from './Lien';

export const BaseDocument = types
  .model('BaseDocument', {
    added_by_law_firm: types.boolean,
    created_at: types.string,
    description: types.maybeNull(types.string),
    download_url: types.string,
    file: types.string,
    id: types.identifier,
    name: types.string,
    type: types.maybeNull(types.string),
  });

export const CaseDocument = types.compose(
  BaseDocument,
  types.model({
    case: types.string
  }),
);

// TODO - split into separately-typed documents
export const Document = types.compose(
  BaseDocument,
  types.model('Document', {
    is_paid_for: types.boolean,
    last_viewed_by_law_firm: types.maybeNull(types.string),
    order: types.maybeNull(types.integer),
    price: types.maybeNull(types.number),
  })
)
  .views(self => ({
    get needs_payment (): boolean {
      return !!self.price && !self.is_paid_for;
    },
  }))
  ;

export const BetaDocument = types
  .model('BetaDocument', {
    created_at: types.string,
    created_by_organization: types.maybeNull(types.string),
    download_url: types.string,
    id: types.identifier,
    is_paid_for: types.boolean,
    last_opened_at: types.maybeNull(types.string),
    lien: types.maybeNull(types.string),
    name: types.string,
    price: types.maybeNull(types.number),
    type: types.maybeNull(types.string),
  })
  .views(stateTreeDependencyGetters)
  .views(self => ({
    get needsPayment (): boolean {
      return self.users.isLawFirmUser && !!self.price && !self.is_paid_for;
    },

    get uploadedBy (): string | null {
      const parentCase: IDetailCase = getParent(self, 2);

      return parentCase.getOrganization(self.created_by_organization)?.name || null;
    },

    get canEdit (): boolean {
      return self.users.account?.registry_legal_organization.id === self.created_by_organization;
    },

    get canDelete (): boolean {
      return this.canEdit && !self.is_paid_for;
    },

    get path (): string {
      return !!self.lien ? 'lien-documents' : 'case-documents';
    },

    get isCaseDocument (): boolean {
      return LAW_FIRM_CASE_DOCUMENT_TYPES.includes(self.type || '');
    },

    get isLienholderBaseDocument (): boolean {
      return Object.values(LIENHOLDER_BASE_DOCUMENT_TYPES).includes(self?.type || '');
    },

    get paymentUrl (): string {
      const parentCase: IDetailCase = getParent(self, 2)
        , lien: IDetailLien | undefined = parentCase.findLien(self.lien || '');

      return mergePathAndQuery(
        URLS.DOCUMENT_PAYMENT_PAGE,
        {
          document_ids: [self.id],
          lien: lien?.id,
        },
      );
    }
  }))
  ;

export const ModelWithDirectDocumentUpload = types
  // For use with API endpoints that support direct S3 file uploads using `DirectFileTransferMixin`
  .model({})
  .views(stateTreeDependencyGetters)
  .actions(self => {
    const uploadDocumentDirect = flow(function* (
      createPath: string,
      fileData: IBetaDocumentSubmitData,
      fileField: string = 'file',
    ) {
      const
        uploadUrlPath = `${createPath}upload-url/`
        , uploadUrlData = { filename: fileData.name, file_field: fileField, check_md5: true }
        , uploadUrlResponse = yield self.client.create(uploadUrlPath, uploadUrlData)
        , { file_path, upload_url, upload_data } = uploadUrlResponse.data
        , md5Hash = yield calculateFileMd5(fileData.file)
        ;

      upload_data['Content-MD5'] = md5Hash;

      (window as any).DD_RUM.addAction('Uploading file', { md5: md5Hash, size: fileData.file.size });

      if (fileData.file.size === 0) {
        throw { response: { status: 400, data: `File is empty: ${fileData.name}` } };
      }

      try {
        yield self.client.postFile(upload_url, fileData.file, upload_data);
      }
      catch (e) {
        e.response.data = `There was an error uploading the file: ${fileData.name}`;
        throw e;
      }

      return yield self.client.create(createPath, { ...fileData, [fileField]: file_path });
    });

    return { uploadDocumentDirect };
  })
  ;

export interface IBaseDocument extends Instance<typeof BaseDocument> {}
export interface ICaseDocument extends Instance<typeof CaseDocument> {}
export interface IDocument extends Instance<typeof Document> {}
export interface IBetaDocument extends Instance<typeof BetaDocument> {}
