import { ColumnProps } from 'antd/lib/table';

import { OPTION_KEYS } from '../../../../utils/constants';
import { fillInColumnProfile } from '../../../../utils/columnCommon';

const lienDocumentTypeColumn = {
  field: 'type',
  optionType: OPTION_KEYS.LIENHOLDER_BASE_DOCUMENT_TYPES,
  type: 'optionSelect',
};

export const lienDocumentCaseCreateTableColumns = [
  { field: 'name' },
  lienDocumentTypeColumn,
].map(fillInColumnProfile) as Array<ColumnProps<any>>;
