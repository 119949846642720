import React, { ChangeEvent, Component } from 'react';
import { inject, observer } from 'mobx-react';
import autoBindMethods from 'class-autobind-decorator';

import { Button, Input } from 'antd';

import StoresClass from '../../../../stores/StoresClass';

import { IChatProps } from './interfaces';

import styles from './ChatFooter.module.less';

interface IInjected extends IChatProps {
  stores: StoresClass;
}
@inject('stores')
@autoBindMethods
@observer
class ChatFooter extends Component<IChatProps> {
  private get injected () { return this.props as IInjected; }

  private onChange (event: ChangeEvent<HTMLTextAreaElement>) {
    const { onChange } = this.props
      , { value } = event.target;

    onChange(value);
  }

  public render () {
    const { isSaving, inputValue, onSave } = this.props
      , { stores: { users: { account } } } = this.injected
      , organization = account?.registry_legal_organization
      , isNotesDisabled = organization?.notes_disabled
      ;

    return (
      <div className={styles.root}>
        <div className={styles.input}>
          <Input.TextArea
            autoSize={{ minRows: 2, maxRows: 6 }}
            disabled={isNotesDisabled || isSaving.isTrue}
            onChange={this.onChange}
            onPressEnter={onSave}
            placeholder={isNotesDisabled ? `${organization?.name} has disabled Notes on their cases` : 'Type here...'}
            value={inputValue}
          />
        </div>
        <div className={styles.button}>
          <Button
            className='send-chat'
            disabled={!inputValue}
            loading={isSaving.isTrue}
            onClick={onSave}
            size='large'
            type='primary'
          >
            Send
          </Button>
        </div>
      </div>
    );
  }
}

export default ChatFooter;
