import React, { Component } from 'react';
import { observer } from 'mobx-react';
import autoBindMethods from 'class-autobind-decorator';
import moment, { Moment } from 'moment';

import { DatePicker } from 'antd';
import { RangeValue } from 'rc-picker/lib/interface';

import Facet from '../building-blocks/Facet';
import { IFacetProps } from '../interfaces';
import { IQuery } from '../../../utils/navigationUtils';

export function formatDateRange (range: [Moment, Moment], field: string): IQuery {
  return {
    [`${field}_after`]: range[0].format('YYYY-MM-DD'),
    [`${field}_before`]: range[1].format('YYYY-MM-DD'),
  };
}

export function reverseFormatDateRange (range: IQuery, field: string): undefined | RangeValue<Moment> {
  const after = range[`${field}_after`]
    , before = range[`${field}_before`];

  // Very specific due to the unique requirements of DatePicker.RangePicker
  if (!after && !before) { return undefined; }
  if (!before) { return [moment(after), null]; }
  if (!after) { return [null, moment(before)]; }

  return [moment(after), moment(before)];
}

@autoBindMethods
@observer
class FacetDate extends Component<IFacetProps> {
  private get value () {
    const { facet: { field }, tableStore } = this.props;

    return reverseFormatDateRange(tableStore.filters, field);
  }

  private handleFilterChange (dates: RangeValue<Moment>) {
    const { facet: { field }, onFacetChange } = this.props
      , isComplete = (dateRange: RangeValue<Moment>): dateRange is [Moment, Moment] => !!dateRange?.length;

    if (isComplete(dates)) {
      onFacetChange(formatDateRange(dates, field));
    }
    else {
      onFacetChange({
        [`${field}_before`]: undefined,
        [`${field}_after`]: undefined,
      });
    }
  }

  public render () {
    return (
      <Facet hasValue={!!this.value} {...this.props}>
        <DatePicker.RangePicker
          onChange={this.handleFilterChange}
          value={this.value}
        />
      </Facet>
    );
  }
}

export default FacetDate;
