import React, { Component } from 'react';
import cx from 'classnames';
import { observer } from 'mobx-react';
import autoBindMethods from 'class-autobind-decorator';

import { Tag as AntdTag } from 'antd';
import { TagProps } from 'antd/lib/tag';

//
// Right now, the close icon is looking bad and Antd doesn't allow overwriting it.
// Since we don't use closable Tags at the moment, let's not style it and
// revisit when we need it. Maybe new Antd versions will allow it.
// This is to avoid doing hacks for something we're not currently using.
//

type PartialTagProps = Pick<TagProps, Exclude<keyof TagProps, 'color' | 'onClose'>>;

//
// Predefine types to standardize Tags.
// Set proper className and color them in css.
//
type TagType = 'default' | 'info' | 'success' | 'error' | 'warning';

interface ITagProps<T> extends PartialTagProps {
  onClose?: (key: T) => void;
  onCloseKey?: T;
  type?: TagType;
}

const defaultProps = {
  closable: false,
  type: 'default',
  visible: true,
};

export const ANT_TAG_PREFIX_CLS = 'ant-tag';

@autoBindMethods
@observer
class Tag<T> extends Component<ITagProps<T>> {
  public static CheckableTag = AntdTag.CheckableTag;
  public static defaultProps = defaultProps;

  private onClose (_e: React.MouseEvent<HTMLElement>) {
    const { onClose, onCloseKey } = this.props;
    if (!onClose || !onCloseKey) { return; }
    onClose(onCloseKey);
  }

  public render () {
    const { onClose, type, className, onCloseKey, ...passDownProps } = this.props
      , hasType = type && type !== 'default'
      , fullClassName = cx(
        className,
        { [`${ANT_TAG_PREFIX_CLS}-type`]: hasType },
        { [`${ANT_TAG_PREFIX_CLS}-type-${type}`]: hasType },
      )
      , onCloseProps = (onClose && onCloseKey) ? { onClose: this.onClose } : {}
      ;

    return (
      <AntdTag
        {...passDownProps}
        className={fullClassName}
        {...onCloseProps}
      />
    );
  }
}

export default Tag;
