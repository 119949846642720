import React, { Component } from 'react';
import { observer } from 'mobx-react';
import autoBindMethods from 'class-autobind-decorator';

import { IDocumentFilterData } from '../../../../../interfaces';
import { IDetailCase } from '../../../../../models/Case';

import DocumentsFilter from './DocumentsFilter';
import DocumentsHeaderActions from './DocumentsHeaderActions';

import styles from './DocumentsHeader.module.less';

interface IProps extends IDocumentFilterData {
  _case: IDetailCase;
}

@autoBindMethods
@observer
class DocumentsHeader extends Component<IProps> {
  public render () {
    return (
      <div className={styles.root}>
        <DocumentsFilter {...this.props} />
        <DocumentsHeaderActions {...this.props} />
      </div>
    );
  }
}

export default DocumentsHeader;
