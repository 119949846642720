import React from 'react';

import { notification } from 'antd';

import Button from '../components/common/Button';

import { APP_NAME, EXTERNAL_URL_PROPS } from './constants';

export function isOutdatedBrowser () {
  return (window as any).Proxy === undefined;
}

export function warnOutdatedBrowsers () {
  if (isOutdatedBrowser()) {
    const btn = (
        <Button danger href='http://outdatedbrowser.com/en' {...EXTERNAL_URL_PROPS}>
          Update to a modern, secure browser
        </Button>
      )
      , description = (
        `${APP_NAME} is developed using modern web technology. ` +
        'We have detected some of those features are missing, and cannot deliver our best experience.'
      )
      ;

    notification.error({
      btn,
      description,
      duration: null,
      message: 'Missing security updates',
    });
  }
}
