import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import autoBindMethods from 'class-autobind-decorator';
import { find } from 'lodash';

import { Popover } from 'antd';

import { Form } from '@mighty-justice/fields-ant';
import { IModel } from '@mighty-justice/fields-ant/dist/props';

import StoresClass from '../../../../../stores/StoresClass';
import { ILegalOrganizationExternalSystem } from '../../../../../models/LegalOrganizationExternalSystem';
import { IStake } from '../../../../../models/stake';
import { optionSelectConfig } from '../../../../../utils/configCommon';
import { Tag } from '../../../../common';

import styles from '../TagsAndSystemKeys.module.less';

interface IProps {
  stake: IStake;
}

interface IInjected extends IProps {
  stores: StoresClass;
}

@inject('stores')
@autoBindMethods
@observer
class AddExternalKeyForm extends Component<IProps> {
  private get injected () { return this.props as IInjected; }

  private renderExternalSystem (option: ILegalOrganizationExternalSystem) {
    return option.external_system.external_system_id;
  }

  private get fieldSets () {
    const { stores } = this.injected;

    return [[
      {
        field: 'external_system_identifier',
        required: true,
      },
      {
        ...optionSelectConfig,
        editProps: {
          renderOption: this.renderExternalSystem,
          renderSelected: this.renderExternalSystem,
        },
        field: 'external_system',
        insertIf: () => stores.externalSystems.hasMultipleSystems,
        options: stores.externalSystems.allSystems,
        type: 'objectSelect',
      },
    ]];
  }

  private async addNew (model: IModel) {
    const { stake } = this.props
      , { stores } = this.injected
      , allSystems = stores.externalSystems.allSystems
      , externalSystemIdentifier = model.external_system_identifier
      , selectedSystem = find(allSystems, { id: model.external_system })
      , externalSystem = selectedSystem || allSystems[0]
      ;

    await stake.addNewExternalSystem(externalSystemIdentifier, externalSystem);

    // This is to refresh the external key facet counts
    stores.infiniteTableStore.fetchFacetCounts();
  }

  public render () {
    return (
      <Popover
        content={
          <div className={styles.form}>
            <Form
              blockSubmit
              fieldSets={this.fieldSets}
              onSave={this.addNew}
              resetOnSuccess
              saveText='Add'
            />
          </div>
        }
        placement='bottom'
        trigger='click'
      >
        <Tag
          className='btn-toggle-add'
          type='success'
        >
          + Add
        </Tag>
      </Popover>
    );
  }
}

export default AddExternalKeyForm;
