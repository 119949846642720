import React, { Component } from 'react';
import { observable, toJS } from 'mobx';
import { observer } from 'mobx-react';
import autoBindMethods from 'class-autobind-decorator';
import { includes, sortBy } from 'lodash';

import { Checkbox, Radio } from 'antd';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import { RadioChangeEvent } from 'antd/lib/radio';

import SmartBool from '@mighty-justice/smart-bool';

import { MAX_INITIAL_OPTIONS } from '../../../utils/constants';
import { IQueryValue, queryValueToStringArray } from '../../../utils/navigationUtils';
import { Button } from '../../common';
import { ICheckboxOption } from '../interfaces';

import styles from './FacetCheckboxList.module.less';

interface IProps {
  field: string;
  onCheckboxChange?: ((values: CheckboxValueType[]) => void);
  onRadioChange?: ((event: RadioChangeEvent) => void);
  options: ICheckboxOption[];
  radio?: boolean;
  value: IQueryValue;
}

@autoBindMethods
@observer
class FacetCheckboxList extends Component<IProps> {
  @observable private showMore = new SmartBool();

  private get value (): CheckboxValueType[] {
    return queryValueToStringArray(this.props.value);
  }

  private get hasValue () {
    return !!(this.value && this.value.length);
  }

  private get showToggleLink () {
    const { options } = this.props;
    // We don't want to make users click to see fewer than 5 records
    const MIN_TO_HIDE = 5;
    return options.length > (MAX_INITIAL_OPTIONS + MIN_TO_HIDE);
  }

  private get shownOptions () {
    const { options, radio } = this.props
      , sortedOptions = (this.hasValue && !radio)
        ? sortBy(options, option => !includes(this.value, option.value))
        : options
      ;

    return (this.showMore.isTrue || !this.showToggleLink)
      ? sortedOptions
      : sortedOptions.slice(0, MAX_INITIAL_OPTIONS);
  }

  private renderOption (option: ICheckboxOption) {
    const { count, label, value } = option
      , { radio } = this.props
      , className = 'facet-item-label-count'
      , showCount = option.count !== undefined
      , CheckboxComponent = radio ? Radio : Checkbox
      ;

    return (
      <CheckboxComponent value={value} key={value}>
        <div className={className}>
          <span>{label}</span>
          {showCount && <span className={styles.count}>({count})</span>}
        </div>
      </CheckboxComponent>
    );
  }

  private renderGroup () {
    const { onCheckboxChange, onRadioChange, radio } = this.props;

    if (radio) {
      return (
        <Radio.Group
          className={styles.group}
          onChange={onRadioChange}
          value={toJS(this.value[0])}
        >
          {this.shownOptions.map(this.renderOption)}
        </Radio.Group>
      );
    }

    return (
      <Checkbox.Group
        className={styles.group}
        onChange={onCheckboxChange}
        value={toJS(this.value)}
      >
        {this.shownOptions.map(this.renderOption)}
      </Checkbox.Group>
    );
  }

  public render () {
    const { options } = this.props
      , remainingOptionCount = options.length - MAX_INITIAL_OPTIONS;

    return (
      <div className={styles.root}>
        {this.renderGroup()}
        {this.showToggleLink && (
          <Button onClick={this.showMore.toggle} block className={styles.expand}>
            {this.showMore.isTrue ? 'Show fewer' : `Show ${remainingOptionCount} more`}
          </Button>
        )}
      </div>
    );
  }
}

export default FacetCheckboxList;
