import React from 'react';
import { IDetailCase } from '../../../models/Case';
import { IDetailLien } from '../../../models/Lien';

import { varToLabel } from '@mighty-justice/utils';

import InboxTab from './tab-inbox/InboxTab';
import InfoTab from './tab-info/InfoTab';
import ContactsTab from './tab-contacts/ContactsTab';
import DocumentsTab from './tab-documents/DocumentsTab';
import StatusTab from './tab-status/StatusTab';
import FinanceTab from './tab-finance/FinanceTab';

export interface ITabProps {
  _case: IDetailCase;
  lien?: IDetailLien;
  liens: IDetailLien[] | null;
}

export interface ITab {
  component: React.ComponentType<ITabProps>;
  disabled: boolean;
  key: string;
  label: string;
}

export const TABS_PARTIAL: Array<Partial<ITab> & { key: string }> = [
  { key: 'info', component: InfoTab },
  { key: 'inbox', component: InboxTab },
  { key: 'status', component: StatusTab },
  { key: 'documents', component: DocumentsTab },
  { key: 'finance', component: FinanceTab },
  { key: 'contacts', component: ContactsTab },
]
  , TABS: ITab[] = TABS_PARTIAL.map(tab => ({
    component: (_props: {}) => null,
    disabled: !tab.component,
    label: varToLabel(tab.key),
    ...tab,
  }))
;
