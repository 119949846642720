import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { Col, Popover, Progress, Statistic } from 'antd';
import autoBindMethods from 'class-autobind-decorator';

import StoresClass from '../../stores/StoresClass';
import { ANT_HALF_COL_WIDTH } from '../../utils/constants';
import { Icon } from '../common';

import AppHeaderItem from './AppHeaderItem';

import styles from './Rewards.module.less';

interface IInjected {
  stores: StoresClass;
}

@inject('stores')
@autoBindMethods
@observer
class Rewards extends Component {
  private get injected () {
    return this.props as IInjected;
  }

  private get points () {
    return this.injected.stores.pointsStore.points;
  }

  private onVisibleChange (isVisible: boolean) {
    const { stores: { pointsStore } } = this.injected;
    if (isVisible) { pointsStore.fetch(); }
  }

  private get currentBalance () {
    const { total_points, points_to_card } = this.points
      // eslint-disable-next-line no-magic-numbers
      , percentage = (1000 - points_to_card) / 10;

    return (
      <div style={{ width: '200px' }}>
        <Col span={ANT_HALF_COL_WIDTH}>
          <Statistic title='Earned' value={total_points} />
        </Col>
        <Col span={ANT_HALF_COL_WIDTH}>
          <Statistic title='Next Reward' value={points_to_card} />
        </Col>
        <Progress percent={percentage} showInfo={false} />
      </div>
    );
  }

  public render () {
    const { stores: { users } } = this.injected;

    if (!users.isLawFirmUser || !users.isRewardsParticipant) {
      return null;
    }

    return (
      <Popover
        content={this.currentBalance}
        onVisibleChange={this.onVisibleChange}
        placement='bottomLeft'
        style={{ float: 'right' }}
        trigger='click'
      >
        <AppHeaderItem className={styles.root} icon={Icon.Sparkles}>
          <span className={styles.label}>Rewards</span>
        </AppHeaderItem>
      </Popover>
    );
  }
}

export default Rewards;
