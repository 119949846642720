import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import autoBindMethods from 'class-autobind-decorator';

import { List } from 'antd';

import { MightyAvatar } from '../../../common/Avatars';
import NotificationContent from '../NotificationContent';
import NotificationCreatedAt from '../NotificationCreatedAt';
import StoresClass from '../../../../stores/StoresClass';

import { IDefaultNotificationProps } from './DefaultMightyNotification';

interface IInjected extends IDefaultNotificationProps {
  stores: StoresClass;
}

@inject('stores')
@autoBindMethods
@observer
class MissingContactsNotification extends Component<IDefaultNotificationProps> {
  private get injected () {
    return this.props as IInjected;
  }

  public onClick () {
    const { isVisible, onClick, notification } = this.props
      , { stores } = this.injected
      ;

    onClick(notification);
    isVisible.setFalse();
    stores.uiStore.addNewContactsModalVisible.setTrue();
  }

  public render () {
    const { className, notification, style } = this.props;

    return (
      <List.Item key={notification.id} style={style} className={className} onClick={this.onClick}>
        <List.Item.Meta
          avatar={<MightyAvatar />}
          description={<NotificationCreatedAt notification={notification} />}
          title={(
            <NotificationContent
              content={(
                <>
                  {notification.body}
                  <br />
                  Add contact here.
                </>
              )}
              notification={notification}
            />
          )}
        />
      </List.Item>
    );
  }
}

export default MissingContactsNotification;
