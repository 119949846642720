import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import autoBindMethods from 'class-autobind-decorator';
import cx from 'classnames';

import { Row, Col } from 'antd';

import { APP_NAME } from '../../../utils/constants';
import StoresClass from '../../../stores/StoresClass';
import { ISettingTabProps } from '../settingsUtils';

import NotificationSettingsWrapper from './NotificationSettingsWrapper';
import NotificationBlacklistSubscriptionMultiSelect from './NotificationSubscriptionBlacklistForm';

import styles from './CommunicationPreferencesTab.module.less';

interface IInjected extends ISettingTabProps {
  stores: StoresClass;
}

const GUTTER = 32;

@inject('stores')
@autoBindMethods
@observer
class CommunicationPreferencesTab extends Component<ISettingTabProps> {
  private get injected () { return this.props as IInjected; }

  public render () {
    const { users: { isLawFirmUser } } = this.injected.stores;

    return (
      <div className={cx(styles.root, styles.override)}>
        <div>
          <Row gutter={GUTTER}>
            <Col>
              <h2>My Email Notifications</h2>
            </Col>
          </Row>
          <Row className={styles.notification}>
            {!isLawFirmUser && <NotificationSettingsWrapper />}
          </Row>
        </div>
        <div>
          <Row gutter={GUTTER}>
            <Col>
              <h2>Law Firm Reminder Emails</h2>
            </Col>
          </Row>
          <Row align='top' gutter={GUTTER} className={styles.blacklist}>
            <p className={styles.subtitle}>
              {APP_NAME} sends weekly emails to law firms to ask them to update the status of any overdue cases.
            </p>
          </Row>
          <NotificationBlacklistSubscriptionMultiSelect />
        </div>
      </div>
    );
  }
}

export default CommunicationPreferencesTab;
