import React from 'react';
import { observer } from 'mobx-react';

import { Row } from 'antd';

import { formatDate } from '@mighty-justice/utils';

import InfoCard from '../common/InfoCard';
import { IDetailCase } from '../../../../models/Case';

import ContactDisplay from './ContactDisplay';
import ContactSubtitle from './ContactSubtitle';
import ContactToolbar from './ContactToolbar';

import styles from './ContactsTab.module.less';

interface IProps {
  _case: IDetailCase;
}

const LawFirmCard = observer((props: IProps) => {
  const { _case } = props
    , lastActive = _case.law_firm_last_active_at
    ;

  if (!_case.law_firm) {
    return null;
  }

  return (
    <Row className={styles.card}>
      <InfoCard
        bottomRightRender={<ContactToolbar _case={_case} legalOrganization={_case.law_firm} />}
        content={<ContactDisplay legalOrganization={_case.law_firm} />}
        subtitle={<ContactSubtitle legalOrganization={_case.law_firm} />}
        title={_case.law_firm.name}
        topRightContent={lastActive && `Last Active ${formatDate(lastActive)}`}
      />
    </Row>
  );
});

export default LawFirmCard;
