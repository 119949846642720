import React, { Component } from 'react';
import { IActivityDetailComponentProps } from './interfaces';
import { observable } from 'mobx';
import { observer } from 'mobx-react';

import SmartBool from '@mighty-justice/smart-bool';

import MergedCaseDataModal from '../../MergedCaseDataModal';

@observer
class CaseMergedActivity extends Component<IActivityDetailComponentProps> {
  @observable private isViewingOldCase = new SmartBool();

  public render () {
    return (
      <>
        <h4>Case merged</h4>
        <a className='btn-toggle-modal' onClick={this.isViewingOldCase.setTrue}>View Merged Case</a><br/>
        {this.isViewingOldCase.isTrue &&
          <MergedCaseDataModal activity={this.props.activity} isVisible={this.isViewingOldCase}/>
        }
      </>
    );
  }
}

export default CaseMergedActivity;
