import React, { Component } from 'react';
import { action } from 'mobx';
import { observer } from 'mobx-react';
import autoBindMethods from 'class-autobind-decorator';
import { kebabCase, get, isString } from 'lodash';

import Facet from '../building-blocks/Facet';
import { IFacetProps } from '../interfaces';
import { DebouncedControlledInput } from '../../common';

@autoBindMethods
@observer
class FacetString extends Component<IFacetProps> {
  private get value (): string {
    const { facet: { field }, tableStore } = this.props;

    return get(tableStore.filters, field, '').toString();
  }

  @action
  private onSearch (search: string) {
    const { facet: { field }, onFacetChange } = this.props;
    onFacetChange({ [field]: search });
  }

  public render () {
    const { facet: { field, label } } = this.props;

    return (
      <Facet hasValue={!!this.value} {...this.props}>
        <DebouncedControlledInput
          id={`input-${kebabCase(field)}`}
          onChange={this.onSearch}
          placeholder={isString(label) ? label : undefined}
          value={this.value}
        />
      </Facet>
    );
  }
}
export default FacetString;
