import React, { Component } from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import autoBindMethods from 'class-autobind-decorator';
import { orderBy } from 'lodash';

import SmartBool from '@mighty-justice/smart-bool';

import { ICaseThread } from '../../../../models/CaseThread';
import { EmptyBlock, Icon, LoadingBlock, Section } from '../../../common';
import { ITabProps } from '../CaseTabConfig';

import InboxSection from './InboxSection';
import InboxThreadPage from './InboxThreadPage';
import InboxFilterDropdown from './InboxFilterDropdown';

import styles from './InboxTab.module.less';

interface IInboxSection {
  title: string,
  threads: ICaseThread[],
}

@autoBindMethods
@observer
class InboxTab extends Component<ITabProps> {
  @observable private selectedThread: ICaseThread | null = null;
  @observable private alertTitle: string | null = null;
  @observable private filterApplied = new SmartBool();

  private selectThread (thread: ICaseThread | null) {
    this.selectedThread = thread;
  }

  private get sections () {
    const { _case } = this.props;

    return [
      {
        title: 'Action Required',
        threads: orderBy(_case.newThreads, ['created_at'], ['desc']),
      },
      {
        title: 'All',
        threads: orderBy(_case.resolvedThreads, ['created_at'], ['desc']),
      },
    ];
  }

  private setTitle (title: string) {
    this.alertTitle = title;
  }

  public render () {
    const { _case, lien } = this.props
      , sharedProps = { _case, lien, selectThread: this.selectThread, setTitle: this.setTitle }
      ;

    if (_case.threads === null) { return <LoadingBlock />; }

    if (!_case.threads.length && this.filterApplied.isFalse) {
      return (
        <div className={styles.root}>
          <EmptyBlock
            description={<>
              Welcome to your Inbox.<br />
              As you receive notifications and requests, they’ll appear here.
            </>}
            icon={Icon.InboxLight}
          />
        </div>
      );
    }

    if (this.selectedThread) {
      return (
        <div className={styles.root}>
          <InboxThreadPage
            _case={_case}
            lien={lien}
            selectThread={this.selectThread}
            thread={this.selectedThread}
            title={this.alertTitle}
          />
        </div>
      );
    }

    return (
      <>
        <Section>
          <InboxFilterDropdown _case={_case} filterApplied={this.filterApplied} />
        </Section>
        <Section className={styles.root}>
          {this.sections.map((section: IInboxSection) => (
            <InboxSection
              key={section.title}
              passThroughProps={sharedProps}
              threads={section.threads}
              title={section.title}
            />
          ))}
        </Section>
      </>
    );
  }
}

export default InboxTab;
