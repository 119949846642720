import React from 'react';

import { Col, Row } from 'antd';

import styles from './styles/DocumentRequestResponsePage.module.less';

interface IProps {
  contact: string;
  lawFirm: string;
}

const RequestResponseDescription = (props: IProps) => (
  <Row>
    <Col span={12} offset={6}>
      <h4 className={styles.description}>
        {props.contact} at {props.lawFirm} has requested documents from you for the following case:
      </h4>
    </Col>
  </Row>
);

export default RequestResponseDescription;
