import { Instance, types } from 'mobx-state-tree';

export const ExternalSystem = types
  .model('ExternalSystem', {
    external_system_id: types.string,
    external_system_type: types.maybeNull(types.string),
    id: types.identifier,
  })
  ;

export interface IExternalSystem extends Instance<typeof ExternalSystem> {}
