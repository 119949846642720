import { observer } from 'mobx-react';
import React from 'react';
import cx from 'classnames';

import { Table as AntdTable } from 'antd';
import { TableProps } from 'antd/es/table';

export interface ITableProps<T> extends TableProps<T> {
  simple?: boolean;
}

const ANT_TABLE_PREFIX_CLS = 'ant-table';

const Table = observer((props: ITableProps<any>) => {
  const { simple, ...passDownProps } = props
    , className = cx(
      { [`${ANT_TABLE_PREFIX_CLS}-simple`]: simple },
      props.className,
    );

  return (
    <AntdTable
      {...passDownProps}
      className={className}
      pagination={{ hideOnSinglePage: true, ...props.pagination }}
    />
  );
});

export default Table;
