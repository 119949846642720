import React, { Component } from 'react';
import { observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import autoBindMethods from 'class-autobind-decorator';

import { Col, Modal, Row, Spin } from 'antd';
import { ModalProps } from 'antd/lib/modal/Modal';

import { Form, FormManager, IFieldSetPartial } from '@mighty-justice/fields-ant';
import { formatAddress, formatWebsite, toKey } from '@mighty-justice/utils';

import ActionItemsListStore from '../../stores/ActionItemsListStore';
import Client from '../../base-modules/client';
import StoresClass from '../../stores/StoresClass';
import { ANT_HALF_COL_WIDTH, BOOLEAN_STRING, URLS } from '../../utils/constants';
import { LegalOrganization } from '../../models/Party';

import LienholderCaseSummary from './LienholderCaseSummary';
import LienholderSelect from './LienholderSelect';
import { allDoneModalProps, loadingModalProps, MODAL_PROPS, renderFormFooter } from './common';

import styles from './ActionItemsListModal.module.less';

interface IInjected {
  client: Client;
  stores: StoresClass;
}

const NO_INFO_TEXT = 'I don\'t have any contact information for this lienholder'
  , FIELD_SETS: IFieldSetPartial[] = [[
    { field: 'first_name', label: 'Contact First Name', required: true },
    { field: 'last_name', label: 'Contact Last Name' },
    { field: 'email', label: 'Contact Email', required: true },
  ]]
  , VERY_LARGE_SAFE_PAGE_SIZE = 2000
  ;

type LegalOrganizationType = typeof LegalOrganization;

@inject('client', 'stores')
@autoBindMethods
@observer
class AddNewContactsModal extends Component<{}> {
  @observable private store: ActionItemsListStore<LegalOrganizationType>;
  @observable private formManager?: FormManager;
  @observable private formRefLoading = true;

  private get injected () { return this.props as IInjected; }

  public constructor (props: {}) {
    super(props);
    this.store = new ActionItemsListStore<LegalOrganizationType>(
      LegalOrganization,
      this.fetch,
      this.injected.stores.dependencies,
    );
  }

  private async fetch () {
    const { client } = this.injected
      , queryString = toKey({
        lienholder_needs_contacts: BOOLEAN_STRING.TRUE,
        page_size: VERY_LARGE_SAFE_PAGE_SIZE,
      })
      , url = `${URLS.LEGAL_ORGANIZATIONS_LIGHT}${queryString}`;

    return client.get(url);
  }

  private setRefFormManager (formManager: FormManager) {
    this.formManager = formManager;
    this.formRefLoading = false;
  }

  private async save (model: object) {
    const { client } = this.injected
      , action = this.store.action;

    if (action) {
      const submitData = { ...model, legal_organization: action.id };
      await client.create(URLS.CONTACTS_BETA, submitData);
    }
  }

  private renderFormChildren (store: ActionItemsListStore<LegalOrganizationType>) {
    const { action: legalOrganization, remove } = store;
    if (legalOrganization === null) { return null; }

    return (
      <Row gutter={16}>
        <Spin spinning={this.formRefLoading} size='large'>
          <Col span={ANT_HALF_COL_WIDTH} className={styles['lienholder-side']}>
            <LienholderSelect store={store} />

            <p className={styles['lienholder-details']}>
              {formatAddress(legalOrganization.address)}<br />
              {formatWebsite(legalOrganization.website)}
            </p>

            <LienholderCaseSummary
              key={legalOrganization.id}
              lienholder={legalOrganization}
            />
          </Col>
          <Col span={ANT_HALF_COL_WIDTH}>
            <Form
              fieldSets={FIELD_SETS}
              key={legalOrganization.id}
              onSave={this.save}
              onSuccess={remove}
              resetOnSuccess
              setRefFormManager={this.setRefFormManager}
              showControls={false}
            />
          </Col>
        </Spin>
      </Row>
    );
  }

  private getModalProps (
    isLoading: boolean,
    hasNoActions: boolean,
    formManager: FormManager | undefined,
    store: ActionItemsListStore<LegalOrganizationType>,
  ): React.PropsWithChildren<ModalProps> {
    if (isLoading) {
      return loadingModalProps(NO_INFO_TEXT);
    }

    if (hasNoActions) {
      return allDoneModalProps(NO_INFO_TEXT);
    }

    return {
      children: this.renderFormChildren(store),
      footer: renderFormFooter(NO_INFO_TEXT, formManager, store),
    };
  }

  public render () {
    const { stores: { uiStore } } = this.injected
      , isVisible = uiStore.addNewContactsModalVisible

      // Passing around attributes of this class explicitly instead of accessing them
      // in the function is done to fix observer update problems
      , formManager = this.formManager
      , store = this.store
      , noActions = store.noActions
      , isLoading = store.isLoading.isTrue
      , modalProps = this.getModalProps(isLoading, noActions, formManager, store)
      ;

    return (
      <Modal
        {...MODAL_PROPS}
        className={styles.root}
        onCancel={isVisible.setFalse}
        title='Lienholder Contacts'
        {...modalProps}
      />
    );
  }
}

export default AddNewContactsModal;
