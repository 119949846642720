import React, { Component } from 'react';
import { observer } from 'mobx-react';
import autoBindMethods from 'class-autobind-decorator';

import { Row, Col } from 'antd';

import { IDetailCase } from '../../../../models/Case';
import { DOCUMENT_TYPES } from '../../../../utils/constants';

import { IBetaDocumentSubmitData } from '../tab-documents/interfaces';
import ChangeOfRepresentationDocumentButton from './ChangeOfRepresentationDocumentButton';
import DocumentTableRow from './DocumentTableRow';

import styles from './ChangeRepresentationDocumentUpload.module.less';

interface IProps {
  _case: IDetailCase;
  documents: IBetaDocumentSubmitData[];
  removeDocument: (index: number) => void;
  uploadDocuments: (documents: IBetaDocumentSubmitData[]) => Promise<void>;
}

const COL_SPAN_HALF = 12;

@autoBindMethods
@observer
class ChangeRepresentationDocumentUpload extends Component<IProps> {
  public render () {
    const { _case, documents, removeDocument, uploadDocuments } = this.props;

    return (
      <Row>
        <Col span={COL_SPAN_HALF}>
          <ChangeOfRepresentationDocumentButton
            _case={_case}
            uploadDocuments={uploadDocuments}
            documentType={DOCUMENT_TYPES.CHANGE_OF_REPRESENTATION}
          />
        </Col>
        <Col className={styles.root} span={COL_SPAN_HALF}>
          {documents.map((document, index) => (
            <DocumentTableRow document={document} index={index} key={index} removeDocument={removeDocument} />
          ))}
        </Col>
      </Row>
    );
  }
}

export default ChangeRepresentationDocumentUpload;
