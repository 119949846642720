import React, { Component } from 'react';
import { observer } from 'mobx-react';
import autoBindMethods from 'class-autobind-decorator';

import { formatDate } from '@mighty-justice/utils';

import InfoCard from '../common/InfoCard';
import { IDetailLien } from '../../../../models/Lien';
import { formatOption } from '../../../../utils/utils';
import { CASE_ACTIVITY_TYPES, OPTION_KEYS, PLAINTIFF_TREATMENT_STATUSES } from '../../../../utils/constants';

import { DEFAULT_ERROR_MESSAGE, STATUS_UNAVAILABLE } from './constants';
import UpdateTreatmentStatusButton from './UpdateTreatmentStatusButton';

import styles from './StatusTab.module.less';

interface IProps {
  canUpdate?: boolean;
  lien?: IDetailLien;
}

@autoBindMethods
@observer
class LienholderStatusCard extends Component<IProps> {
  private get treatmentStatusDisplay () {
    const { lien } = this.props
      , doneTreating = lien?.plaintiff_treatment_status === PLAINTIFF_TREATMENT_STATUSES.DONE_TREATING
      ;

    return (
      <div className={styles['lienholder-status']}>
        <p className={doneTreating ? styles['status-with-date'] : null}>
          {formatOption(lien?.plaintiff_treatment_status, OPTION_KEYS.TREATMENT_STATUS)}
        </p>
        <p className={styles.extra}>
          {doneTreating && `Final treatment: ${formatDate(lien?.final_treatment_date)}`}
        </p>
      </div>
    );
  }

  public render () {
    const { canUpdate, lien } = this.props;

    if (!lien) {
      return (
        <div>
          {DEFAULT_ERROR_MESSAGE}
        </div>
      );
    }

    const latestUpdate = lien.latestActivity(CASE_ACTIVITY_TYPES.PLAINTIFF_TREATMENT_STATUS_UPDATED)
      , updatedBy = latestUpdate?.created_by_name ? ` by ${latestUpdate?.created_by_name}` : ''
      , statusDisplay = lien.plaintiff_treatment_status
        ? this.treatmentStatusDisplay
        : STATUS_UNAVAILABLE
      ;

    return (
      <InfoCard
        bottomRightRender={canUpdate && <UpdateTreatmentStatusButton lien={lien} />}
        content={latestUpdate && latestUpdate.note}
        subtitle={statusDisplay}
        title={lien.lienholder.name}
        topRightContent={latestUpdate && `Last Updated ${formatDate(latestUpdate.created_at)}${updatedBy}`}
      />
    );
  }
}

export default LienholderStatusCard;
