import React, { Component } from 'react';
import { observer } from 'mobx-react';
import autoBindMethods from 'class-autobind-decorator';

import { IDetailCase } from '../../../../models/Case';
import { DOCUMENT_TYPES } from '../../../../utils/constants';

import { formatDocumentType } from '../tab-documents/utils';
import AddDocumentButton from '../tab-documents/common/AddDocumentButton';
import { IBetaDocumentSubmitData } from '../tab-documents/interfaces';

interface IProps {
  _case: IDetailCase;
  documentType: string;
  uploadDocuments: (documents: IBetaDocumentSubmitData[]) => Promise<void>;
}

@autoBindMethods
@observer
class ChangeOfRepresentationDocumentButton extends Component <IProps> {
  private async onSave (documents: IBetaDocumentSubmitData[]) {
    const { uploadDocuments } = this.props
      , submitData = documents.map((document: IBetaDocumentSubmitData) => (
        { ...document, type: DOCUMENT_TYPES.CHANGE_OF_REPRESENTATION }
      ));

    await uploadDocuments(submitData);
  }

  public render () {
    const { _case, documentType } = this.props
      , modalProps = {
        title: `Upload ${formatDocumentType(documentType)}`,
        hideFields: true,
        saveText: 'Upload',
      };

    return (
      <AddDocumentButton
        _case={_case}
        buttonProps={{ id: 'add-document-button' }}
        buttonText='Add supporting documents'
        uploadDocuments={this.onSave}
        {...modalProps}
      />
    );
  }
}

export default ChangeOfRepresentationDocumentButton;
