import React from 'react';

import { IDocument } from '../../models/Document';

import styles from './DocumentPaymentPage.module.less';
import SummaryLineItem from './SummaryLineItem';

interface IProps {
  document: IDocument;
}

function DocumentOrderItem (props: IProps) {
  const { document: { name, price } } = props;

  return (
    <SummaryLineItem className={styles.item} name={name} price={price || 0} />
  );
}

export default DocumentOrderItem;
