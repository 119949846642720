import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { get } from 'lodash';
import autoBindMethods from 'class-autobind-decorator';

import { Select, Form } from 'antd';

import { getNameOrDefault } from '@mighty-justice/utils';

import ActionItemsListStore from '../../stores/ActionItemsListStore';
import { ILegalOrganization, LegalOrganization } from '../../models/Party';

import styles from './LienholderSelect.module.less';

interface IProps {
  store: ActionItemsListStore<typeof LegalOrganization>;
}

const KEY_BY = 'id';

@autoBindMethods
@observer
class LienholderSelect extends Component<IProps> {
  private onChange (id: string) {
    const { store } = this.props;
    store.setIndexTo({ id });
  }

  private renderOption (option: ILegalOrganization) {
    const key = get(option, KEY_BY);

    return (
      <Select.Option
        key={key}
        title={getNameOrDefault(option)}
        value={key}
      >
        {getNameOrDefault(option)}
      </Select.Option>
    );
  }

  public render () {
    const { store } = this.props
      , selectValue = store.action ? get(store.action, KEY_BY) : undefined;

    return (
      <Form layout='vertical'>
        <Form.Item
          className={styles.root}
          label='Lienholders'
        >
          <Select
            className={styles.root}
            onChange={this.onChange}
            optionFilterProp='children'
            showSearch
            value={selectValue}
          >
            {store.actions.map(this.renderOption)}
          </Select>
        </Form.Item>
      </Form>
    );
  }
}

export default LienholderSelect;
