import { flow, Instance, types } from 'mobx-state-tree';
import { get, head } from 'lodash';

import Client from '../base-modules/client';

export const AliasCaseData = types.model('AliasCaseData', {
  date_of_birth: types.maybeNull(types.string),
  date_of_loss: types.maybeNull(types.string),
  plaintiff_name: types.maybeNull(types.string),
  state_of_incident: types.maybeNull(types.string),
});

export const DocumentActivityData = types.model({
  id: '',
  name: '',
  type: '',
});

export const CaseActivityData = types.model('CaseActivityData', {
  access_link: types.maybeNull(types.string),
  alias_case_data: types.optional(types.array(AliasCaseData), []),
  appearance_date: types.maybeNull(types.string),
  case_documents: types.optional(types.array(DocumentActivityData), []),
  case_manager_name: types.maybeNull(types.string),
  document: types.maybe(DocumentActivityData),
  expired_at: types.maybeNull(types.string),
  final_treatment_date: types.maybeNull(types.string),
  last_dob_entry: types.maybeNull(types.string),
  lienholder_name: '',
  new_contact: types.maybeNull(types.string),
  new_status: types.maybeNull(types.string),
  old_contact: types.maybeNull(types.string),
  old_law_firm_name: types.maybeNull(types.string),
  old_status: types.maybeNull(types.string),
  plaintiff_treatment_status: types.maybeNull(types.string),
  resolution_status: types.maybeNull(types.string),
  updated_item: types.maybeNull(types.string),
});

export const CaseActivity = types
  .model('CaseActivity', {
    activity_data: CaseActivityData,
    case: types.string,
    created_at: types.string,
    created_by_name: types.maybeNull(types.string),
    created_by_organization: types.maybeNull(types.string),
    created_by_organization_id: types.maybeNull(types.string),
    hide_created_by: types.boolean,
    id: types.identifier,
    is_internal_user: types.maybeNull(types.boolean),
    lien: types.maybeNull(types.string),
    note: types.maybeNull(types.string),
    read_at: types.maybeNull(types.string),
    type: types.string,
  })
  .actions(self => {
    const fetchDocumentUrl = flow(function* (client: Client, baseEndpoint: string) {
      const documentId = get(self.activity_data, 'document.id');

      if (documentId) {
        const response = yield client.get(`/${baseEndpoint}/lien-documents/${documentId}/`);

        return response.download_url;
      }

      return null;
    });

    return { fetchDocumentUrl };
  })
  ;

export const ModelWithActivities = types
  .model({
    activities: types.optional(types.maybeNull(types.array(CaseActivity)), null),
  })
  .views(self => ({
    latestActivity (type: string): undefined | ICaseActivity {
      return head(self.activities?.filter(activity => activity.type === type));
    },
  }))
  .actions(self => {
    const setActivities = (activities: any) => {
      if (self.activities !== null) {
        self.activities.replace(activities);
      }
      else {
        self.activities = activities;
      }
    };

    return { setActivities };
  })
  ;

export interface ICaseActivity extends Instance<typeof CaseActivity> {}
export interface IAliasCaseData extends Instance<typeof AliasCaseData> {}
export interface IDocumentActivityData extends Instance<typeof DocumentActivityData> {}
