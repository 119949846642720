import React, { Component } from 'react';
import { observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import autoBindMethods from 'class-autobind-decorator';

import SmartBool from '@mighty-justice/smart-bool';

import { IDetailCase, IListCase } from '../../../models/Case';
import StoresClass from '../../../stores/StoresClass';
import { INavigateProps } from '../../../utils/navigationUtils';
import navigationWrapper from '../../../utils/navigationWrapper';
import { isUUID, printPerformance } from '../../../utils/utils';
import { Button, ErrorBlock, PageLoader } from '../../common';

import CasePage, { ListCasePage } from './CasePage';

interface IProps extends INavigateProps {
  caseId?: string;
  isDrawerAddCaseVisible: SmartBool;
}

interface IInjected extends IProps {
  stores: StoresClass;
}

@inject('stores')
@autoBindMethods
@observer
class CaseLoader extends Component<IProps> {
  @observable private isLoading = new SmartBool();

  private get injected () { return this.props as IInjected; }

  public constructor (props: IProps) {
    super(props);
    const { caseId } = props
      , { portfolio } = this.injected.stores
      ;

    if (isUUID(caseId)) {
      const isCaseLoaded = portfolio.detailCases.has(caseId);

      this.isLoading = new SmartBool(!isCaseLoaded);
      this.load();
    }
  }

  private load () {
    const { caseId } = this.props
      , { portfolio } = this.injected.stores
      ;

    if (isUUID(caseId)) {
      this.isLoading.until(portfolio.fetchCase(caseId));
    }
  }

  private async close () {
    const { navigate } = this.props
      , { infiniteTableStore } = this.injected.stores;

    await infiniteTableStore.refresh();

    navigate(infiniteTableStore.firstCaseUrl);
  }

  public render () {
    const { caseId } = this.props
      , { detailCases, listCases } = this.injected.stores.portfolio
      , _case: IDetailCase | undefined = caseId ? detailCases.get(caseId) : undefined
      , listCase: IListCase | undefined = caseId ? listCases.get(caseId) : undefined
      , isLoading: boolean = this.isLoading.isTrue
      ;

    if (_case) {
      printPerformance('Case Page (Detail View)');
      return (
        <CasePage _case={_case} key={_case.id} />
      );
    }

    // While loading we can show a basic version of the case page from list data
    if (listCase && isLoading) {
      printPerformance('Case Page (List View)');
      return <ListCasePage _case={listCase} key={listCase.id} />;
    }

    // Sometimes on first load we will not have a list case though
    if (isLoading) {
      printPerformance('Case Page Page Loader');
      return <PageLoader />;
    }

    // If no case exists and we are not loading there is a problem
    // This is probably a 404 caused by loading a case on the wrong organization
    return (
      <ErrorBlock fullMargin description='There was a problem loading this case.'>
        <Button size='large' type='primary' onClick={this.load}>Reload</Button>
        {' '}or{' '}
        <Button size='large' onClick={this.close}>Close</Button>
      </ErrorBlock>
    );
  }
}

export default navigationWrapper(CaseLoader);
