import React, { Component, ComponentType } from 'react';
import autoBindMethods from 'class-autobind-decorator';
import { observable } from 'mobx';
import { observer } from 'mobx-react';

import { notification } from 'antd';

import SmartBool from '@mighty-justice/smart-bool';
import { FormModal } from '@mighty-justice/fields-ant';

import styles from './ConfirmationOnErrorModal.module.less';

export type IOnSave = (model: any) => Promise<void>;

export interface IProps {
  FormComponent: React.ComponentType<any>;
  confirmOnError?: boolean;
  doesErrorMatch: (error: Error) => boolean;
  errorModalProps: { [key: string]: any };
  getErrorModalChildren?: (error: Error, model: any) => string | React.ReactNode;
  onConfirm?: (onSave: IOnSave, model: any) => Promise<void>;
  onSave: IOnSave;
  passThroughProps: { [key: string]: any };
}

@autoBindMethods
@observer
class ConfirmationOnErrorModal extends Component<IProps> {
  public static defaultProps = {
    FormComponent: FormModal,
  };

  @observable private isConfirming = new SmartBool();
  @observable private error: Error | null = null;
  @observable private modelData: object | null = null;

  private async onSave (model: any) {
    const { confirmOnError, doesErrorMatch, onSave } = this.props;

    try {
      await onSave(model);
    }
    catch (error) {
      if (confirmOnError && doesErrorMatch(error)) {
        this.modelData = model;
        this.error = error;
        this.isConfirming.setTrue();
      }
      // istanbul ignore next
      else {
        throw error;
      }
    }
  }

  private async onConfirm () {
    const { onConfirm, onSave } = this.props;

    if (onConfirm) {
      await onConfirm(onSave, this.modelData);
    }
    else {
      await onSave({ ...this.modelData, is_confirmed: true });
    }

    this.isConfirming.setFalse();
  }

  private onSuccess () {
    const { passThroughProps: { onSuccess } } = this.props;

    // only show success message if there is no error
    if (this.isConfirming.isFalse) {
      notification.success({
        description: '',
        duration: 3,
        message: 'Success',
      });

      if (onSuccess) {
        onSuccess();
      }
    }
  }

  public render () {
    const { FormComponent, getErrorModalChildren, errorModalProps, passThroughProps } = this.props;

    return (
      <>
        <FormModal
          className={styles.root}
          fieldSets={[]}
          key='confirmation'
          isVisible={this.isConfirming}
          onSave={this.onConfirm}
          onSuccess={this.onSuccess}
          successText={null}
          {...errorModalProps}
        >
          {getErrorModalChildren && this.error && getErrorModalChildren(this.error, this.modelData)}
        </FormModal>
        <FormComponent
          { ...this.props }
          {...passThroughProps}
          key='form'
          onSave={this.onSave}
          onSuccess={this.onSuccess}
          successText={null}
          resetOnSuccess={false}
        />
      </>
    );
  }
}

export default ConfirmationOnErrorModal;
