import React from 'react';

import { Row } from 'antd';

import styles from './ContactsTab.module.less';

interface IProps {
  title: string;
}

function SectionTitle (props: IProps) {
  return (
    <Row align='middle' className={styles.title}>
      {props.title}
    </Row>
  );
}

export default SectionTitle;
