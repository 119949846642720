import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import autoBindMethods from 'class-autobind-decorator';

import { Modal } from 'antd';

import FormManager from '@mighty-justice/fields-ant/dist/utilities/FormManager';
import SmartBool from '@mighty-justice/smart-bool';
import { Form } from '@mighty-justice/fields-ant';

import { ILegalOrganizationWithContacts } from '../../../../models/Party';
import { UNIQUENESS_ERROR, URLS } from '../../../../utils/constants';
import { doesContactNeedUpdate, IContact } from '../../../../models/Contact';
import { objectSearchCreateConfig } from '../../../../utils/configCommon';
import { getHumanReadableError, renderContactOption } from '../../../../utils/utils';

import ConfirmationOnContactErrorModal from './ConfirmationOnContactErrorModal';
import ManageContactsTable from './ManageContactsTable';
import { ADD_OPTION_KEY, CONTACT_FIELDS, DEFAULT_MODAL_WIDTH, DEFAULT_SELECT_PROPS } from './constants';

import styles from './Modal.module.less';

// eslint-disable-next-line no-magic-numbers
const MODAL_WIDTH = DEFAULT_MODAL_WIDTH + 100;

interface IProps {
  isVisible: SmartBool;
  legalOrganization: ILegalOrganizationWithContacts;
}

@autoBindMethods
@observer
class ManageContactsModal extends Component<IProps> {
  @observable private formManager: FormManager | null = null;
  // a hack because the object search component does not reset its smart-bool
  @observable private shouldRerender = new SmartBool();
  @observable private isAddingNew = new SmartBool();

  private setRefFormManager (formManager: FormManager) {
    this.formManager = formManager;
  }

  private async onExistingSelect (option: any) {
    if (option.key !== ADD_OPTION_KEY) {
      await this.formManager?.onFinish();
    }
  }

  private onCancel () {
    const { isVisible } = this.props;

    isVisible.setFalse();
    this.isAddingNew.setFalse();
  }

  private async onSave (model: { [key: string]: any }) {
    const { legalOrganization } = this.props
      , contact = model.contact
      ;

    if (contact.id) {
      await getHumanReadableError(
        legalOrganization.addExistingContact(contact),
        UNIQUENESS_ERROR,
        'Cannot add a duplicate contact',
      );
    }
    else {
      await legalOrganization.addNewContact(contact);
      this.shouldRerender.setTrue();
      this.isAddingNew.setFalse();
      this.shouldRerender.setFalse();
    }
  }

  private get contactFieldSets () {
    const { legalOrganization } = this.props
      , contactIds = legalOrganization.contacts?.map(contact => contact.id) || []
      ;

    return [[
      {
        className: styles.contactSearch,
        endpoint: URLS.CONTACTS_BETA,
        label: '',
        field: 'contact',
        renderOption: renderContactOption,
        type: 'objectSearchCreate',
        createFields: CONTACT_FIELDS,
        editProps: {
          ...objectSearchCreateConfig.editProps,
          isOptionDisabled: (contact: IContact) => (doesContactNeedUpdate(contact) || contactIds.includes(contact.id)),
          onAddNewToggle: this.isAddingNew.toggle,
          searchOnEmpty: true,
          selectProps: {
            ...objectSearchCreateConfig.editProps.selectProps,
            placeholder: `Add someone at ${legalOrganization.name}`,
            onSelect: this.onExistingSelect,
            ...DEFAULT_SELECT_PROPS.selectProps,
          },
        },
        searchFilters: { legal_organization: legalOrganization.id, page_size: 1000 },
        required: true,
      }
    ]];
  }

  public render () {
    const { isVisible, legalOrganization } = this.props;

    return (
      <Modal
        className={styles.modal}
        destroyOnClose
        okText='Done'
        onCancel={this.onCancel}
        onOk={this.onCancel}
        title={`Who at ${legalOrganization.name} is working on this case?`}
        visible={isVisible.isTrue}
        width={MODAL_WIDTH}
      >
        <p className={styles.content}>
          Provide specific contact information to ensure the right person at {legalOrganization.name}
          &nbsp;is notified to join this case.
        </p>
        {this.shouldRerender.isFalse && (
          <ConfirmationOnContactErrorModal
            dataPath='contact'
            legalOrganization={legalOrganization}
            FormComponent={Form}
            onSave={this.onSave}
            passThroughProps={{
              fieldSets: this.contactFieldSets,
              setRefFormManager: this.setRefFormManager,
              showControls: this.isAddingNew.isTrue,
            }}
          />
        )}
        <ManageContactsTable legalOrganization={legalOrganization} />
      </Modal>
    );
  }
}

export default ManageContactsModal;
