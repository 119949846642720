import React, { Component } from 'react';
import { observer } from 'mobx-react';
import autoBindMethods from 'class-autobind-decorator';

import { Divider } from 'antd';

import styles from './SettingToggleList.module.less';

interface IProps {
  children: React.ReactNode[];
}

@autoBindMethods
@observer
class SettingToggleList extends Component<IProps> {
  public render () {
    const { children } = this.props;

    return (
      <div className={styles.root}>
        {children.map((child, idx) => (
          <div key={idx}>
            {child}
            {(idx < children.length - 1) && <Divider className={styles.divider} />}
          </div>
        ))}
      </div>
    );
  }
}

export default SettingToggleList;
