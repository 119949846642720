import React, { Component } from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import autoBindMethods from 'class-autobind-decorator';

import { FormManager, FormModal, IOption } from '@mighty-justice/fields-ant';
import { IModel } from '@mighty-justice/fields-ant/dist/props';
import SmartBool from '@mighty-justice/smart-bool';
import { getNameOrDefault } from '@mighty-justice/utils';

import { IDetailCase } from '../../../../../models/Case';
import { IDetailLien } from '../../../../../models/Lien';
import { LoadingBlock } from '../../../../common';
import SelectOrganizationModal, { SelectOrganizationReturnData } from '../../common/SelectOrganizationModal';
import { ADD_NEW_LIENHOLDER_OPTION } from '../constants';

import DocumentRequestModalDocumentsSection from './DocumentRequestModalDocumentsSection';

import styles from '../../tab-contacts/Modal.module.less';

interface IProps {
  _case: IDetailCase;
  isVisible: SmartBool;
}

@autoBindMethods
@observer
class DocumentRequestModal extends Component<IProps> {
  @observable private isAddOrganizationModalVisible = new SmartBool();
  @observable private formManager?: FormManager;
  @observable private selectedLienId: string = '';
  @observable private selectedOption: { name: string, value: string} | undefined = undefined;

  private get isNewLien (): boolean {
    const { _case } = this.props;
    return !_case.findLien(this.selectedLienId);
  }

  private get options () {
    const { _case } = this.props
      , baseOptions = _case.liens?.map((lien: IDetailLien) => ({ name: lien.lienholder.name, value: lien.id })) || []
      , options = this.selectedOption
        ? [this.selectedOption, ...baseOptions]
        : baseOptions;

    return [...options, ADD_NEW_LIENHOLDER_OPTION];
  }

  private get fieldSets () {
    const { _case } = this.props;

    if (!_case.liens) {
      return [[]];
    }

    return [{
      fields: [
        {
          editProps: {
            keyBy: 'value',
            onChange: this.onLienChange,
            renderOption: (option: IOption) => option.name,
          },
          field: 'lien.id',
          label: 'Lienholders on Case',
          options: this.options,
          required: true,
          type: 'objectSelect',
        },
        {
          field: 'note',
          insertIf: () => !!this.selectedLienId,
          required: true,
        },
      ],
    }];
  }

  private get children () {
    const { _case } = this.props;

    if (!_case.liens) {
      return <LoadingBlock description='Loading lienholders...'/>;
    }

    if (!this.selectedLienId || this.isNewLien) {
      return null;
    }

    return <DocumentRequestModalDocumentsSection _case={_case} lienId={this.selectedLienId} />;
  }

  private onLienChange (value: string) {
    const formManager = this.formManager;

    this.selectedLienId = value;

    if (formManager) {
      formManager.form.setFieldsValue({ lien: { id: this.selectedLienId } });
    }

    if (value === ADD_NEW_LIENHOLDER_OPTION.value) {
      this.isAddOrganizationModalVisible.setTrue();
      this.selectedLienId = '';
      this.selectedOption = undefined;
    }
  }

  private async onSelectNewOrganization (data: SelectOrganizationReturnData) {
    const formManager = this.formManager;

    this.selectedLienId = data.id;
    this.selectedOption = { name: data.name, value: data.id };

    if (formManager) {
      formManager.form.setFieldsValue({ lien: { id: data.id } });
    }
  }

  private async onSave (model: IModel) {
    const { _case } = this.props
      , isNewLien = this.isNewLien
      , submitData = isNewLien ? { ...model, lien: { case: _case.id, lienholder: { id: this.selectedLienId } } } : model
    ;

    await _case.createLawFirmDocumentRequest(submitData, isNewLien);
  }

  private onCancel () {
    const { isVisible } = this.props;
    this.selectedLienId = '';
    this.selectedOption = undefined;

    isVisible.setFalse();
  }

  private setRefFormManager (formManager: FormManager) {
    this.formManager = formManager;
  }

  public render () {
    const { _case, isVisible } = this.props
      , plaintiffName = <span className='dd-privacy-mask'>{getNameOrDefault(_case.plaintiff)}</span>;

    return (
      <>
        <FormModal
          children={this.children}
          className={styles.modal}
          fieldSets={this.fieldSets}
          isVisible={isVisible}
          onCancel={this.onCancel}
          onSave={this.onSave}
          setRefFormManager={this.setRefFormManager}
          saveText='Send Request'
          title={<>Request Documents for {plaintiffName}'s Case</>}
        />
        <SelectOrganizationModal
          isVisible={this.isAddOrganizationModalVisible}
          onSelect={this.onSelectNewOrganization}
          type='lienholder'
        />
      </>
    );
  }
}

export default DocumentRequestModal;
