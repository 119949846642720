import UserStore from '../../../stores/UserStore';
import { IFacetSet } from '../interfaces';
import { facetsToFacetSets } from '../facetUtils';

import { getDropdownFacetSets } from './dropdownFacetSets';
import { getAdvancedFacetSets } from './advancedFacetSets';
import { getToggleFacets } from './toggleFacets';

export function getAllUserFacetSets (userStore: UserStore): IFacetSet[] {
  const { isLawFirmUser, hasTags, account } = userStore
    , linkedContact = account?.linked_contact || undefined
    ;

  return [
    ...getDropdownFacetSets(isLawFirmUser),
    ...getAdvancedFacetSets(isLawFirmUser, hasTags),
    ...facetsToFacetSets(getToggleFacets(isLawFirmUser, linkedContact)),
  ];
}
