import React, { Component } from 'react';
import autoBindMethods from 'class-autobind-decorator';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { omit } from 'lodash';

import { ButtonProps } from 'antd/lib/button';

import SmartBool from '@mighty-justice/smart-bool';
import { FormModal } from '@mighty-justice/fields-ant';

import Button from './Button';
import { IconButton } from './Buttons';
import { IIcon } from './Icon';

export interface IButtonProps extends ButtonProps {
  children?: React.ReactNode;
  onClick?: (event: any) => Promise<void> | boolean;
}

interface IProps {
  buttonProps?: IButtonProps;
  buttonText?: React.ReactNode;
  children?: React.ReactNode;
  icon?: IIcon;
  ModalClass?: React.ComponentType<any> | React.FunctionComponent<any>;
  passThroughProps?: { [key: string]: any, onCancel?: () => void, onSuccess?: () => void };
}

@autoBindMethods
@observer
class ModalButton extends Component<IProps> {
  @observable public isVisible = new SmartBool();

  private onSuccess () {
    const { passThroughProps } = this.props;
    if (passThroughProps && passThroughProps.onSuccess) { passThroughProps.onSuccess(); }
    this.isVisible.setFalse();
  }

  private onCancel () {
    const { passThroughProps } = this.props;
    if (passThroughProps && passThroughProps.onCancel) { passThroughProps.onCancel(); }
    this.isVisible.setFalse();
  }

  private async onClick (event: any) {
    const { buttonProps } = this.props;
    if (buttonProps && buttonProps.onClick) { await buttonProps.onClick(event); }
    this.isVisible.setTrue();
  }

  public render () {
    const { buttonProps, buttonText, children, icon, ModalClass, passThroughProps } = this.props
      , Modal = ModalClass ? ModalClass : FormModal as any;

    return (
      <>
        {icon
          ? <IconButton {...omit(buttonProps, 'size')} icon={icon} onClick={this.onClick} />
          : <Button children={buttonText || children} {...buttonProps} onClick={this.onClick} />
        }

        {this.isVisible.isTrue && (
          <Modal
            {...passThroughProps}
            isVisible={this.isVisible}
            onCancel={this.onCancel}
            onSuccess={this.onSuccess}
          />
        )}
      </>
    );
  }
}

export default ModalButton;
