import React from 'react';

import { Timeline as AntdTimeline } from 'antd';
import { TimelineProps, TimelineItemProps } from 'antd/lib/timeline';


type ColorType = 'gray' | 'green' | 'red';

export interface ITimelineItemProps extends TimelineItemProps {
  children?: React.ReactNode;
  color?: ColorType;
}

const itemDefaultProps = {
  color: 'gray',
};

const getItemColor = (propColor: ColorType | undefined) => {
  if (propColor === 'gray') {
    return '#9AA5B1'; // @btn-default-color
  }

  return propColor;
};

export const TimelineItem = (props: ITimelineItemProps) => (
  <AntdTimeline.Item {...props} color={getItemColor(props.color)} />
);
TimelineItem.defaultProps = itemDefaultProps;

export interface ITimelineProps extends TimelineProps {
  children:
    React.ReactElement<ITimelineItemProps[]>
    | React.ReactElement<ITimelineItemProps>
    | Array<React.ReactElement<ITimelineItemProps>>;
}

const defaultProps = {
  mode: 'left',
  pending: false,
  reverse: false,
};

const Timeline = (props: ITimelineProps) => (
  <AntdTimeline {...props} />
);

Timeline.defaultProps = defaultProps;
Timeline.Item = TimelineItem;

export default Timeline;
