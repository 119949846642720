import React, { Component } from 'react';
import { observer } from 'mobx-react';
import autoBindMethods from 'class-autobind-decorator';
import { find, isString } from 'lodash';

import { Col, Divider, Row } from 'antd';

import { Spacer } from '../../common';

import { IPriceBucket, PRICE_BUCKETS } from '../common';

import styles from './OrderSummary.module.less';
import { ANT_HALF_COL_WIDTH } from '../../../utils/constants';
import { getQuery } from '../../../utils/navigationUtils';

@autoBindMethods
@observer
class OrderSummary extends Component {
  public render () {
    const selection = getQuery().selection
      , cases = isString(selection) ? selection : undefined
      , priceBucket: IPriceBucket | undefined = find(PRICE_BUCKETS, { cases })
      , payPerCase = { label: 'Pay per case', description: '$10/case', cost: '0' }
      , renderParams = priceBucket ?
        {
          description: `${Number(priceBucket.cases) + Number(priceBucket.free)} cases for $${priceBucket.cost}`,
          label: 'Case credits',
          ...priceBucket,
        }
        : payPerCase
      ;

    return (
      <div className={styles.root}>
        <Row className={styles.boldFont}>
          <h3>Your Plan</h3>
        </Row>

        <Row className={styles.itemPrice} justify='space-between'>
          <Col span={ANT_HALF_COL_WIDTH} className={styles.left}>
            <h3>{renderParams.label}</h3>
          </Col>

          <Col span={ANT_HALF_COL_WIDTH} className={styles.right}>
            <h4>{renderParams.description}</h4>
          </Col>
        </Row>

        <Divider type='horizontal' />

        <Row className={styles.itemTotal} justify='space-between'>
          <Col span={ANT_HALF_COL_WIDTH} className={styles.left}>
            <h3>Total</h3>
          </Col>

          <Col span={ANT_HALF_COL_WIDTH} className={styles.right}>
            <h3>${renderParams.cost} today</h3>
          </Col>
        </Row>

        <Spacer />

        {!priceBucket && (
          <Row justify='center'>
            <b>Billed monthly for cases added that month</b>
          </Row>
        )}

      </div>
    );
  }
}

export default OrderSummary;
