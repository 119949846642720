import React from 'react';
import { groupBy } from 'lodash';
import { observer } from 'mobx-react';

import { Row } from 'antd';

import { varToLabel } from '@mighty-justice/utils';

import { IDetailCase } from '../../../../models/Case';
import { IDetailLien } from '../../../../models/Lien';
import { ORGANIZATION_TYPES } from '../../../../utils/constants';
import { LoadingBlock } from '../../../common';

import SectionTitle from './SectionTitle';
import LienholderCard from './LienholderCard';

import styles from './ContactsTab.module.less';

interface IProps {
  _case: IDetailCase;
}

const Lienholders = observer((props: IProps) => {
  const { _case } = props;

  if (_case.liens === null) {
    return (
      <LoadingBlock description='Loading contacts...'/>
    );
  }

  if (!_case.liens.length) {
    return null;
  }

  // handles if lienholder type is null
  const groupByMapper = (lien: IDetailLien) => lien.lienholder.type || ORGANIZATION_TYPES.OTHER
    , liensByType: { [key: string]: IDetailLien[] } = groupBy(_case.liens, groupByMapper)
    , lienTypes = Object.keys(liensByType)
    ;

  return (
    <div>
      {lienTypes.map((type: string) => (
        <Row key={type} className={styles.lienholderSection}>
          <SectionTitle title={`${varToLabel(type)}s`} />
          {liensByType[type].map((lien: IDetailLien) => (
            <Row className={styles.card} key={lien.id} >
              <LienholderCard key={lien.id} lien={lien} />
            </Row>
          ))}
        </Row>
      ))}
    </div>
  );
});

export default Lienholders;
