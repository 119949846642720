import autoBindMethods from 'class-autobind-decorator';
import { action, computed, observable } from 'mobx';

import Client from '../base-modules/client';

import StoresClass from './StoresClass';

const REWARDS_POINTS_NEEDED = 5000;

@autoBindMethods
class PointsStore {
  private client: Client;
  private stores: StoresClass;

  @observable private rewards: {
    points_to_card: number,
    total_points: number,
  } = {
    points_to_card: REWARDS_POINTS_NEEDED,
    total_points: 0,
  };

  public constructor (client: Client, stores: StoresClass) {
    this.client = client;
    this.stores = stores;
  }

  @computed
  public get points () {
    return this.rewards;
  }

  @action
  public async fetch () {
    const { user, isLawFirmUser, isLoggedIn } = this.stores.users;
    if (!isLoggedIn || !isLawFirmUser || !user) { return; }

    this.rewards = await this.client.get(`/escrow-agent/rewards/${user.id}/`);

    if (!this.rewards.total_points) {
      this.rewards.total_points = 0;
      this.rewards.points_to_card = REWARDS_POINTS_NEEDED;
    }
  }
}

export default PointsStore;
