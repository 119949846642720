import React from 'react';

import { REQUEST_CLOSE_STATUS } from '../../../../utils/constants';
import { IActivityDetailComponentProps } from './interfaces';

const DocumentRequestFulfilledActivity = (props: IActivityDetailComponentProps) => (
  <div>
    {props.activity.activity_data.new_status === REQUEST_CLOSE_STATUS.CLOSED_BY_LAW_FIRM ?
      <h4>Documents request closed by law firm</h4> :
      <h4>Documents request fulfilled</h4>
    }
  </div>
);

export default DocumentRequestFulfilledActivity;
