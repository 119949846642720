import React from 'react';
import { Col, Row } from 'antd';

const COL_SIZES = {
  CONTENT: { md: 24, lg: 22, xxl: 20 },
  CONTENT_NARROW: { sm: 24, md: 20, xxl: 16 },
  SIDE: { md: 0, lg: 1, xxl: 2 },
  SIDE_NARROW: { md: 2, xxl: 4 },
};

const AppContent = (props: {
    children: React.ReactNode,
    className?: string,
    narrow?: boolean,
  }) => {
  const colContentSize = COL_SIZES[props.narrow ? 'CONTENT_NARROW' : 'CONTENT']
    , colSideSize = COL_SIZES[props.narrow ? 'SIDE_NARROW' : 'SIDE'];

  return (
    <Row className={props.className}>
      <Col {...colSideSize} />
      <Col {...colContentSize}>
        {props.children}
      </Col>
      <Col {...colSideSize} />
    </Row>
  );
};

export default AppContent;
