import React, { Component } from 'react';
import { head, noop, pick } from 'lodash';
import { inject, observer } from 'mobx-react';
import { observable } from 'mobx';
import autoBindMethods from 'class-autobind-decorator';

import SmartBool from '@mighty-justice/smart-bool';
import { pluralize } from '@mighty-justice/utils';

import { RcFile } from 'antd/lib/upload';
import { Upload } from 'antd';

import DocumentModal from '../page-portfolio/portfolio-detail/tab-documents/common/DocumentModal';
import {
  IBetaDocumentFields,
  IBetaDocumentSubmitData,
  IDocumentModalProps,
} from '../page-portfolio/portfolio-detail/tab-documents/interfaces';
import StoresClass from '../../stores/StoresClass';
import { IDetailCase } from '../../models/Case';
import { OPTION_KEYS } from '../../utils/constants';
import { restrictDocumentSize } from '../../utils/utils';

export interface IUploadWrapperProps {
  _case?: IDetailCase;
  canClick?: boolean;
  canDrag?: boolean;
  hideFields?: boolean;
  modalProps?: Partial<IDocumentModalProps>;
  multiple?: boolean;
  typeOptions?: string;
  uploadDocuments: (documents: IBetaDocumentSubmitData[]) => Promise<void>;
}

interface IInjected extends IUploadWrapperProps {
  stores: StoresClass;
}

/*
 A component intended to wrap Ant Design Upload and Upload.Dragger components,
 handling the upload logic and flow
*/

@inject('stores')
@autoBindMethods
@observer
class UploadWrapper extends Component<IUploadWrapperProps> {
  @observable private documentsToUpload: RcFile[] = [];
  @observable private isModalVisible = new SmartBool();

  private get injected () {
    return this.props as IInjected;
  }

  private beforeUpload (_file: RcFile, fileList: RcFile[]): boolean {
    this.documentsToUpload = fileList;
    return this.isModalVisible.setTrue();
  }

  public get allowMultiple (): boolean {
    return this.props.multiple !== false;
  }

  // For name to auto-populate from RcFile to the form for single documents.
  // Name field does not exist for multiple documents, so passing it is fine
  private get formModel (): { name?: string } {
    const document = head(this.documentsToUpload);

    return pick(document, 'name');
  }

  private async onSave (documents: IBetaDocumentFields[]) {
    const { _case, uploadDocuments } = this.props
      , uploadData = this.documentsToUpload.map((file: any, index: number) => (
        { lien: _case?.userLien?.id, ...documents[index], file }
      ));

    await uploadDocuments(uploadData);
  }

  private get typeOptions (): string {
    const { _case, typeOptions } = this.props
      , { users: { isLawFirmUser } } = this.injected.stores
      , lawFirmTypes = _case?.hasLiens ? OPTION_KEYS.LAW_FIRM_DOCUMENT_TYPES : OPTION_KEYS.LAW_FIRM_CASE_DOCUMENT_TYPES
      , userBasedTypes = isLawFirmUser ? lawFirmTypes : OPTION_KEYS.LIENHOLDER_DOCUMENT_TYPES
      ;

    return typeOptions || userBasedTypes;
  }

  public render () {
    const { _case, canClick, canDrag, children, hideFields, modalProps } = this.props
      , UploadComponent = canDrag ? Upload.Dragger : Upload
      , documentCount = this.documentsToUpload.length
      ;

    return (
      <>
        <UploadComponent
          beforeUpload={restrictDocumentSize(this.beforeUpload)}
          customRequest={noop}
          name='file'
          multiple={this.allowMultiple}
          showUploadList={false}
          openFileDialogOnClick={canClick}
        >
          { children }
        </UploadComponent>
        <DocumentModal
          _case={_case}
          uploadDocuments={this.onSave}
          isVisible={this.isModalVisible}
          model={this.formModel}
          title={pluralize('Upload Document', 's', documentCount)}
          hideFields={!!hideFields}
          typeOptions={this.typeOptions}
          uploadFiles={this.documentsToUpload}
          saveText={pluralize('Upload Document', `s (${documentCount})`, documentCount)}
          {...modalProps}
        />
      </>
    );
  }
}

export default UploadWrapper;
