import React, { Component } from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import autoBindMethods from 'class-autobind-decorator';
import { omit } from 'lodash';

import { Popconfirm } from 'antd';
import { ButtonProps } from 'antd/lib/button';

import SmartBool from '@mighty-justice/smart-bool';

import Button from './Button';

import {
  createDisabledContainer,
  createGuardedContainer,
} from '@mighty-justice/utils';

interface IGuardedButtonProps {
  confirm?: boolean;
  isGuarded?: boolean;
  onClick: () => any;
}

@autoBindMethods
@observer
class GuardedButton extends Component<IGuardedButtonProps & ButtonProps> {
  public guardedContainer: React.ComponentType<any>;
  @observable private isLoading = new SmartBool();

  public constructor (props: IGuardedButtonProps & ButtonProps) {
    super(props);

    const { isGuarded } = this.props
      , disabledComponent = createDisabledContainer(Button);

    this.guardedContainer = createGuardedContainer({
      disabledComponent,
      enabledComponent: Button,
      isGuarded: !!isGuarded,
    });
  }

  private async onClick () {
    const { onClick } = this.props;
    if (!onClick) { return; }

    // onClick might be void but it's perfectly fine to await a void so just cast it
    await this.isLoading.until((onClick as () => Promise<any>)());
  }

  public render () {
    const GuardedContainer = this.guardedContainer
      , omitProps = ['isGuarded'];

    if (this.props.confirm) {
      omitProps.push('confirm');
      omitProps.push('onClick');

      return (
        <Popconfirm
          title='Are you sure?'
          onConfirm={this.onClick}
        >
          <GuardedContainer loading={this.isLoading.isTrue} {...omit(this.props, omitProps)} />
        </Popconfirm>
      );
    }

    return (
      <GuardedContainer {...omit(this.props, omitProps)} />
    );
  }
}

export default GuardedButton;
