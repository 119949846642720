import React, { Component } from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import autoBindMethods from 'class-autobind-decorator';

import SmartBool from '@mighty-justice/smart-bool';

import { Dropdown, Menu } from 'antd';

import { IDocumentFilterData } from '../../../../../interfaces';
import { IBetaDocument } from '../../../../../models/Document';
import { IDetailCase } from '../../../../../models/Case';
import { Icon } from '../../../../common';

import DocumentDetailModal from './DocumentDetailModal';
import DeleteDocumentModal from './DeleteDocumentModal';
import PayToOpen from './PayToOpen';

import styles from './DocumentsTable.module.less';

interface IProps extends IDocumentFilterData{
  _case: IDetailCase;
  document: IBetaDocument;
}

@autoBindMethods
@observer
class DocumentTableActions extends Component<IProps> {
  @observable isDocumentDetailModalVisible = new SmartBool();
  @observable isDeleteDocumentModalVisible = new SmartBool();

  private handleDocumentDownload () {
    const { _case, document } = this.props;

    _case.createViewLienDocumentActivities([document]);
  }

  private renderDownloadLink () {
    const { document } = this.props;

    return <a id='download' href={document.download_url} onClick={this.handleDocumentDownload}>Download</a>;
  }

  private get menu () {
    const { document } = this.props;

    return (
      <Menu className={styles['documents-table-more-actions']}>
        <Menu.Item key='download'>
          <Icon.CloudDownload />{this.renderDownloadLink()}
        </Menu.Item>

        {document.canEdit &&
          <Menu.Item key='details' id='details' onClick={this.isDocumentDetailModalVisible.setTrue} >
            <Icon.InfoCircleLight />Details
          </Menu.Item>
        }

        {document.canDelete &&
          <Menu.Item
            className={styles.delete}
            id='delete'
            key='delete'
            onClick={this.isDeleteDocumentModalVisible.setTrue}
          >
            <Icon.TrashAlt />Delete
          </Menu.Item>
        }
      </Menu>
    );
  }

  public render () {
    const { _case, document, ...passDownProps } = this.props;

    if (document.needsPayment) {
      return <PayToOpen document={document} />;
    }

    if (!document.canEdit && !document.canDelete) {
      return (
        <span className={styles['document-download']}>
          <Icon.CloudDownload /> {this.renderDownloadLink()}
        </span>
      );
    }

    return (
      <>
        <div className={styles.actions}>
          <Dropdown
            overlay={this.menu}
            trigger={['click']}
          >
            <div className={styles['more-items-button']} id='documents-table-actions'>
              <span className={styles.text}>More</span><Icon.CaretDownSolid />
            </div>
          </Dropdown>
        </div>
        <DocumentDetailModal _case={_case} document={document} isVisible={this.isDocumentDetailModalVisible}/>
        <DeleteDocumentModal
          _case={_case}
          document={document}
          isVisible={this.isDeleteDocumentModalVisible}
          {...passDownProps}
        />
      </>
    );
  }
}

export default DocumentTableActions;
