import React from 'react';

import { Col, Row } from 'antd';

import { Card } from '../common';

interface IProps {
  contact?: string;
  note?: string;
}

const RequestResponseNote = (props: IProps) => {
  const { contact, note } = props;

  if (!note) { return null; }

  return (
    <Row>
      <Col span={12} offset={6}>
        <p>Note from {contact || 'contact'}:</p>
        <Card>
          {note}
        </Card>
      </Col>
    </Row>
  );
};

export default RequestResponseNote;
