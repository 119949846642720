import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import autoBindMethods from 'class-autobind-decorator';

import { formatDate } from '@mighty-justice/utils';

import { ICaseThread } from '../../../../../models/CaseThread';
import { IDetailCase } from '../../../../../models/Case';
import { IDetailLien } from '../../../../../models/Lien';
import StoresClass from '../../../../../stores/StoresClass';
import InfoCard from '../../common/InfoCard';
import ReplyButton from '../../common/ReplyButton';
import { InboxItemProps } from '../interfaces';

import UploadDocumentButton from './UploadDocumentButton';

import styles from './DocumentRequestThread.module.less';

interface IProps {
  _case: IDetailCase;
  lien?: IDetailLien;
  passThroughProps: InboxItemProps;
  selectThread: (thread: ICaseThread) => void;
  setTitle: (title: string) => void;
  thread: ICaseThread;
}

interface IInjected extends IProps {
  stores: StoresClass;
}

@inject('stores')
@autoBindMethods
@observer
class DocumentRequestThread extends Component<IProps> {
  private get injected () { return this.props as IInjected; }

  private selectThread () {
    const { selectThread, setTitle, thread } = this.props;
    setTitle(this.description);
    selectThread(thread);
  }

  private get description () {
    const { thread } = this.props
      , initialRequest = thread.message_count === 1
      , description = initialRequest ? this.initialDescription : this.conversationDescription
      ;

    return description;
  }

  private get initialDescription () {
    const { stores: { users: { isLawFirmUser } } } = this.injected
      , { thread } = this.props;

    if (!isLawFirmUser) {
      return `${thread.created_by_organization_name} sent a document request`;
    }

    return `You have an open document request with ${thread.to_organization_name}.`;
  }

  private get conversationDescription () {
    const { stores: { users } } = this.injected
      , { isLawFirmUser } = users
      , { thread } = this.props
      , recipientName = isLawFirmUser ? thread.to_organization_name : thread.created_by_organization_name
      , senderName = thread.latest_message?.created_by_organization_name
      , organizationName = users.account?.registry_legal_organization.name
      , requestDate = formatDate(thread.created_at)
      ;

    if (senderName === organizationName) {
      return `You sent a message regarding a document request with ${recipientName}`;
    }

    return (
      `${senderName} replied to ${isLawFirmUser ? 'your' : 'the'} document request from ${requestDate}`
    );
  }

  private renderActionButtons () {
    const { stores: { users: { isLawFirmUser } } } = this.injected
      , { _case } = this.props;

    if (!isLawFirmUser) {
      return (
        <div className={styles.options}>
          <ReplyButton selectAlert={this.selectThread} title='Reply' />
          <UploadDocumentButton _case={_case} />
        </div>
      );
    }

    return <ReplyButton selectAlert={this.selectThread} title='See details' />;
  }

  public render () {
    const { thread, passThroughProps } = this.props;

    // Eventually will show resolved document requests (in V2)
    if (!!thread.is_resolved) { return null; }

    return (
      <InfoCard
        bottomRightRender={!thread.is_resolved && this.renderActionButtons()}
        content={!thread.is_resolved && thread.latest_message?.note}
        subtitle={this.description}
        title='Documents'
        {...passThroughProps}
      />
    );
  }
}

export default DocumentRequestThread;
