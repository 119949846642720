import { clone, flow, types, Instance } from 'mobx-state-tree';

import { stateTreeDependencyGetters } from '../base-modules/state-tree-dependencies';

import { IManualDedupeCase, ManualDedupeCase } from './ManualDedupeCase';

const PreviewSummaryItem = types.model({
  value: types.maybeNull(types.string),
  source_id: types.string,
});

const PreviewSummaryData = types.model({
  plaintiff_name: PreviewSummaryItem,
  created_by_organization_name: PreviewSummaryItem,
  plaintiff_birthdate: PreviewSummaryItem,
  date_of_loss: PreviewSummaryItem,
  state_of_incident: PreviewSummaryItem,
});

const PreviewSummary = types.model({
  data: PreviewSummaryData,
  last_previewed_at: types.string,
});

const ERROR_MESSAGE = 'A logic error occurred. Please refresh your page and try again.';

export const ManualDedupeMatch = types
  .model({
    baseUrl: types.string,
    legalOrganizationId: types.maybeNull(types.string),
    canonical: types.maybeNull(ManualDedupeCase),
    duplicate: types.maybeNull(ManualDedupeCase),
    previewSummary: types.maybeNull(PreviewSummary),
  })
  .views(stateTreeDependencyGetters)
  .views(self => ({
    get duplicateIds (): string[] {
      if (!self.canonical || !self.duplicate) {
        throw new Error(ERROR_MESSAGE);
      }

      return [self.canonical.id, self.duplicate.id];
    }
  }))
  .actions(self => {
    function resetMatch () {
      self.canonical = null;
      self.duplicate = null;
    }

    function setLegalOrganization (legalOrganizationId: string | null) {
      self.legalOrganizationId = legalOrganizationId;
      resetMatch();
    }

    const setPreviewSummary = flow(function* () {
      const endpoint = `${self.baseUrl}preview-merge/`;

      if (!self.canonical || !self.duplicate) {
        self.previewSummary = null;
        return;
      }

      const result = (yield self.client.create(endpoint, { case_ids: self.duplicateIds })).data
        // the backend returns a . in this field name
        , previewData = { ...result.data, plaintiff_birthdate: result.data['plaintiff.birthdate'] }
        , previewSummary = { ...result, data: previewData }
        ;

      self.previewSummary = previewSummary;
    });

    const setCanonical = flow(function* (value: IManualDedupeCase | null) {
      self.canonical = value;
      yield setPreviewSummary();
    });

    const setDuplicate = flow(function* (value: IManualDedupeCase | null) {
      self.duplicate = value;
      yield setPreviewSummary();
    });

    const swapMatch = flow(function* () {
      const oldDuplicate = self.duplicate && clone(self.duplicate);

      self.previewSummary = null;
      self.duplicate = self.canonical && clone(self.canonical);
      self.canonical = oldDuplicate;
      yield setPreviewSummary();
    });

    const mergeDuplicates = flow(function* () {
      const endpoint = `${self.baseUrl}merge/`;

      if (!self.previewSummary) {
        throw new Error(ERROR_MESSAGE);
      }

      const postData = { case_ids: self.duplicateIds, last_previewed_at: self.previewSummary.last_previewed_at };

      yield self.client.create(endpoint, postData);
      resetMatch();
    });

    return {
      mergeDuplicates,
      setCanonical,
      setDuplicate,
      setLegalOrganization,
      swapMatch,
    };
  })
  .views(self => ({
    get hasDuplicates (): boolean {
      return !!(self.canonical && self.duplicate);
    },
  }))
  ;

export interface IManualDedupeMatch extends Instance<typeof ManualDedupeMatch> {}
export interface IPreviewSummary extends Instance<typeof PreviewSummary> {}
