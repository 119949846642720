import { flow, Instance, types } from 'mobx-state-tree';
import jsonToFormData from 'json-form-data';

import { DOCUMENT_TYPES } from '../utils/constants';
import { stateTreeDependencyGetters } from '../base-modules/state-tree-dependencies';
import { IBetaDocumentSubmitData } from '../components/page-portfolio/portfolio-detail/tab-documents/interfaces';

import { BetaDocument } from './Document';

export const PolicyLimit = types
  .model('PolicyLimit', {
    case: types.string,
    coverage_limit_lower: types.number,
    coverage_limit_upper: types.maybeNull(types.number),
    coverage_type: types.string,
    created_at: types.string,
    document: types.maybeNull(BetaDocument),
    id: types.identifier,
  })
  .views(stateTreeDependencyGetters)
  .actions(self => {
    const uploadDocument = flow(function* (fileData: IBetaDocumentSubmitData) {
      const form = jsonToFormData({ ...fileData, type: DOCUMENT_TYPES.POLICY_LIMIT })
        , returnedDocument = yield self.client.create(`/documents/`, form)
        , response = yield self.client.update(`/policy-limits/${self.id}/`, { document: returnedDocument.data.id })
      ;

      self.document = response.data.document;
    });

    return {
      uploadDocument,
    };
  });

export interface IPolicyLimit extends Instance<typeof PolicyLimit> {}
