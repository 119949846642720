import React from 'react';
import { get } from 'lodash';

import { Col, Row } from 'antd';
import { ColumnProps } from 'antd/lib/table';

import { EMPTY_FIELD } from '@mighty-justice/utils';
import { IFieldConfigOptionSelect, formatOptionSelect } from '@mighty-justice/fields-ant';
import { IModel } from '@mighty-justice/fields-ant/dist/props';

import { LAW_FIRM_TYPES, LIENHOLDER_TYPES, OPTION_KEYS, ORGANIZATION_TYPES } from '../../../../utils/constants';
import { fillInColumnProfile, IColumnProfile } from '../../../../utils/columnCommon';
import { IVerifyOrganizationMatch } from '../../../../models/DedupeMatch';
import { ModalButton } from '../../../common';

import AcceptMatchButton from './AcceptMatchButton';
import CouldNotVerifyButton from './CouldNotVerifyButton';
import VerifiedMatchDrawer from './VerifiedMatchDrawer';
import {
  renderRelatedToCompulsionText,
  renderDate,
  renderEnteredBy,
  renderInfoDiv,
  renderLegalOrganizationDetails,
  renderVerifiedMatchNote,
} from '../../tableColumns';
import VerificationNeedsMoreInformationButton from '../legal-organizations/VerificationNeedsMoreInformationButton';

const renderEntityNameAndType = (value: any, data: IVerifyOrganizationMatch) => {
  const type = formatOptionSelect(
    data.type,
    { optionType: OPTION_KEYS.LEGAL_ORGANIZATION_TYPE } as IFieldConfigOptionSelect,
  );

  return renderInfoDiv(value, type);
};

const renderVerifiedMatch = (_value: any, data: IVerifyOrganizationMatch) => {
  const legalOrganizationTypes = data.type === ORGANIZATION_TYPES.LAW_FIRM ? LAW_FIRM_TYPES : LIENHOLDER_TYPES
    , unformattedType = get(data, 'verified_match.type')
    , type = !unformattedType ? unformattedType : formatOptionSelect(
      unformattedType,
      { optionType: OPTION_KEYS.LEGAL_ORGANIZATION_TYPE } as IFieldConfigOptionSelect,
    );

  return (
    <div>
      {renderInfoDiv(get(data, 'verified_match.name'), type)}
      <br />
      <ModalButton
        buttonProps={{ size: 'small', type: 'primary' }}
        buttonText='Search matches'
        ModalClass={VerifiedMatchDrawer}
        passThroughProps={{
          isEditable: false,
          legalOrganizationTypes,
          onSelect: (match: any) => {
            data.setMatch(match);
          },
          unverifiedData: data,
        }}
      />
    </div>
  );
};

const renderAssociatedSegment = (data: Array<string | null>) => {
  const associatedLegalOrganizations = data.filter(value => !!value);

  if (!associatedLegalOrganizations.length) {
    return null;
  }

  return (
    <>
      {associatedLegalOrganizations.map((legalOrganization: any) => (
        <Row key={legalOrganization} style={{ marginBottom: '10px' }}>{legalOrganization}</Row>
      ))}
    </>
  );
};

const renderAssociatedLegalOrganizations = (_value: any, data: IVerifyOrganizationMatch) => {
  const associatedLawFirms = renderAssociatedSegment(data.associated_law_firms)
    , associatedLienholders = renderAssociatedSegment(data.associated_lienholders)
    ;

  if (!associatedLawFirms && !associatedLienholders) {
    return EMPTY_FIELD;
  }

  return (
    <Col>
      {associatedLawFirms}
      {associatedLienholders}
    </Col>
  );
};

const renderVerifiedMatchDetails = (_value: any, data: IVerifyOrganizationMatch) => {
  const { verified_match } = data;

  if (!verified_match) {
    return EMPTY_FIELD;
  }

  return renderLegalOrganizationDetails(verified_match, undefined);
};

const renderAcceptButton = (_value: any, data: any) => (
  <>
    <AcceptMatchButton match={data} />
    <br/><br/>
    <CouldNotVerifyButton match={data} />
    <br/><br/>
    <VerificationNeedsMoreInformationButton match={data} />
  </>
);

export const organizationVerificationColumns: Array<ColumnProps<IModel>> = [
  {
    field: 'created_at',
    minWidth: 50,
    render: renderDate,
    sorter: true,
    title: 'Created',
  },
  {
    field: 'created_by_organization',
    minWidth: 80,
    render: renderEnteredBy,
    sorter: true,
    title: 'Entered by',
  },
  {
    field: 'name',
    minWidth: 60,
    render: renderEntityNameAndType,
    sorter: true,
    title: 'Entity name and type',
  },
  {
    field: '',
    minWidth: 120,
    render: (_val: any, data: any) => renderLegalOrganizationDetails(data, data),
    title: 'Entity details',
  },
  {
    field: '',
    minWidth: 120,
    render: renderAssociatedLegalOrganizations,
    title: 'Associated Legal Organizations',
  },
  {
    field: 'verified_match.name',
    minWidth: 60,
    render: renderVerifiedMatch,
    sorter: true,
    title: 'Verified match',
  },
  {
    field: '',
    minWidth: 120,
    render: renderVerifiedMatchDetails,
    title: 'Verified match details',
  },
  {
    field: '',
    minWidth: 60,
    render: renderRelatedToCompulsionText,
    title: 'Related to compulsion firm',
  },
  {
    field: '',
    minWidth: 120,
    render: renderVerifiedMatchNote,
    title: 'Note',
  },
  {
    field: '',
    minWidth: 40,
    render: renderAcceptButton,
    title: 'Accept or dismiss the verified match',
  },
].map(fillInColumnProfile);

export const verifiedMatchColumns: Array<IColumnProfile<any>> = [
  {
    field: 'name',
    minWidth: 60,
    render: renderEntityNameAndType,
  },
  {
    field: '',
    minWidth: 120,
    render: (_val, data) => renderLegalOrganizationDetails(data, data),
  },
];
