import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { observable } from 'mobx';
import autoBindMethods from 'class-autobind-decorator';

import { Col, Modal, notification, Row } from 'antd';

import SmartBool from '@mighty-justice/smart-bool';
import { fillInFieldSet } from '@mighty-justice/fields-ant';
import { getNameOrDefault, toKey } from '@mighty-justice/utils';

import Client from '../../../base-modules/client';
import { IDetailCase } from '../../../models/Case';
import { IPreviewSummary } from '../../../models/ManualDedupeMatch';
import StoresClass from '../../../stores/StoresClass';
import { ANT_HALF_COL_WIDTH, OPTION_KEYS, URLS } from '../../../utils/constants';
import { getUrlForNewPath, INavigateProps } from '../../../utils/navigationUtils';
import navigationWrapper from '../../../utils/navigationWrapper';
import { showError } from '../../../utils/utils';

import CaseDedupeCard from './CaseDedupeCard';
import CaseDedupeMergeSection from './CaseDedupeMergeSection';
import UserCaseDedupeSelectCard from './UserCaseDedupeSelectCard';
import { IMergeCaseData } from './interfaces';

import styles from './CaseDedupeModal.module.less';

interface IProps extends INavigateProps{
  _case: IDetailCase;
  isVisible: SmartBool;
  onCancel: () => void;
}

interface IInjected extends IProps {
  client: Client;
  stores: StoresClass;
}

const CASE_FIELD_SET = fillInFieldSet([
  { className: 'dd-privacy-mask-user-input', field: 'plaintiff', render: getNameOrDefault },
  { className: 'dd-privacy-mask-user-input', field: 'plaintiff.birthdate', label: 'date of birth' },
  { field: 'date_of_loss' },
  { field: 'state_of_incident', optionType: OPTION_KEYS.US_STATES, type: 'optionSelect' },
]);

@inject('client', 'stores')
@autoBindMethods
@observer
class CaseDedupeModal extends Component<IProps> {
  @observable private hasAgreed = new SmartBool();
  @observable private isSubmitting = new SmartBool();
  @observable private selectedCase: IDetailCase | null = null;
  @observable private previewSummary: IPreviewSummary | null = null;

  private get injected () {
    return this.props as IInjected;
  }

  private async fetchPreviewSummary () {
    const { _case } = this.props;

    if (!this.selectedCase) {
      return null;
    }

    const caseIds = [_case.id, this.selectedCase.id]
      , result: IPreviewSummary = await _case.fetchPreviewSummary(caseIds)
      ;

    return result;
  }

  private async onCaseSelect (_case: IDetailCase | null) {
    this.hasAgreed.setFalse();
    this.selectedCase = _case;
    this.previewSummary = await this.fetchPreviewSummary();
  }

  public renderCaseSelectCard () {
    const { _case } = this.props;

    if (this.selectedCase) {
      return (
        <CaseDedupeCard
          _case={this.selectedCase}
          fieldSet={CASE_FIELD_SET}
          onCaseSelect={this.onCaseSelect}
          previewSummary={this.previewSummary}
          title='Merging case'
        />
      );
    }

    return (
      <UserCaseDedupeSelectCard _case={_case} onCaseSelect={this.onCaseSelect} />
    );
  }

  private async fetchCanonicalItem (id: string) {
    const { stores: { portfolio } } = this.injected
      , queryString = toKey({ id })
      , response = await portfolio.hydrate(null, queryString)
      , canonicalData = response.results && response.results[0]
      , canonicalId = canonicalData && canonicalData.id
      ;

    return canonicalId;
  }

  private async submit (data: IMergeCaseData) {
    const { _case } = this.props
      , canonicalCaseId = await _case.mergeCases(data)
      ;

    return await this.fetchCanonicalItem(canonicalCaseId);
  }

  private async mergeCases () {
    if (!this.selectedCase || !this.previewSummary) {
      return;
    }

    const { _case, isVisible, navigate } = this.props
      , { stores: { infiniteTableStore } } = this.injected
      , case_ids = [_case.id, this.selectedCase.id]
      , { last_previewed_at } = this.previewSummary
      ;

    try {
      const selectedKey = await this.isSubmitting.until(this.submit({ case_ids, last_previewed_at }));

      notification.success({
        description: '',
        message: 'Your cases have been merged successfully!',
      });
      isVisible.setFalse();

      infiniteTableStore.setSelectedKeyFromUrlParam(selectedKey);
      navigate(getUrlForNewPath(`${URLS.PORTFOLIO_PAGE}/${selectedKey}`));
    }
    catch (err) {
      showError(err, 'while merging the cases');
      isVisible.setFalse();
    }
  }

  public render () {
    const { _case, isVisible, onCancel } = this.props
      , instructionText = this.selectedCase
        ? 'Please verify cases you want to merge'
        : 'Please search and select an appropriate case to merge'
      ;

    return (
      <Modal className={styles.modal} footer={null} onCancel={onCancel} visible={isVisible.isTrue} width='700px'>
        <Row className={styles.header}>
          <b className={styles.title}>Merge Duplicate Cases</b>
          <p className={styles.instructions}>{instructionText}</p>
        </Row>
        <Row>
          <Col span={ANT_HALF_COL_WIDTH}>
            <CaseDedupeCard
              _case={_case}
              fieldSet={CASE_FIELD_SET}
              previewSummary={this.previewSummary}
              title={this.selectedCase ? 'Current case' : null}
            />
          </Col>
          <Col span={ANT_HALF_COL_WIDTH}>
            {this.renderCaseSelectCard()}
          </Col>
        </Row>
        {this.selectedCase && (
          <CaseDedupeMergeSection
            hasAgreed={this.hasAgreed}
            isSubmitting={this.isSubmitting}
            mergeCases={this.mergeCases}
          />
        )}
      </Modal>
    );
  }
}

export default navigationWrapper(CaseDedupeModal);
