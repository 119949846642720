import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import { OPTION_KEYS } from '../../../../utils/constants';
import StoresClass from '../../../../stores/StoresClass';

import { IActivityDetailComponentProps } from './interfaces';

interface IInjected extends IActivityDetailComponentProps {
  stores: StoresClass;
}

@inject('stores')
@observer
class PlaintiffTreatmentStatusUpdatedActivity extends Component<IActivityDetailComponentProps> {
  private get injected () { return this.props as IInjected; }

  public render () {
    const { activity: { activity_data: { plaintiff_treatment_status, final_treatment_date } } } = this.props
      , { options: { getOptionName } } = this.injected.stores
      , statusText = getOptionName(OPTION_KEYS.TREATMENT_STATUS, (plaintiff_treatment_status || ''))
      ;

    return (
      <>
        <h4>Plaintiff treatment status updated</h4>
        <p><b>Status:</b> {statusText}</p>
        {final_treatment_date && <p><b>Final treatment date:</b> {final_treatment_date}</p>}
      </>
    );
  }
}

export default PlaintiffTreatmentStatusUpdatedActivity;
