import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { observable } from 'mobx';
import autoBindMethods from 'class-autobind-decorator';
import cx from 'classnames';

import SmartBool from '@mighty-justice/smart-bool';

import { Row, Popconfirm, notification } from 'antd';

import { IDetailCase } from '../../../../../models/Case';
import { ICaseThread } from '../../../../../models/CaseThread';
import { IDetailLien } from '../../../../../models/Lien';
import StoresClass from '../../../../../stores/StoresClass';
import { REQUEST_CLOSE_STATUS } from '../../../../../utils/constants';
import { showError } from '../../../../../utils/utils';
import { Icon, LinkButton } from '../../../../common';
import InfoCard from '../../common/InfoCard';
import RequestResponseModal from '../../document-requests/RequestResponseModal';

import UploadDocumentButton from './UploadDocumentButton';

import styles from './ReplyOptions.module.less';

interface IProps {
  _case: IDetailCase;
  goBack: () => void;
  lien?: IDetailLien;
  thread: ICaseThread;
}

interface IInjected extends IProps {
  stores: StoresClass;
}

interface ReplyOptions {
  title: string;
  action: React.ReactNode;
}

@inject('stores')
@autoBindMethods
@observer
class DocumentRequestReplyOptions extends Component<IProps> {
  @observable private isRequestResponseModalVisible = new SmartBool();

  private get injected () { return this.props as IInjected; }

  private async onSave (model: any) {
    const { _case, lien, thread } = this.props
      , { stores: { users: { isLawFirmUser } } } = this.injected;

    if (isLawFirmUser) {
      await _case.sendRequestResponse(model, thread);
    }
    else {
      await lien?.sendRequestResponse(model, thread);
    }

    await thread.refresh();
  }

  private async closeRequest () {
    const { _case, thread, goBack } = this.props
      , data = { new_status: REQUEST_CLOSE_STATUS.CLOSED_BY_LAW_FIRM }
      ;

    try {
      await _case.sendRequestResponse(data, thread);

      notification.success({
        description: '',
        message: 'Successfully closed document request',
      });
      goBack();
    }
    catch (err) {
      showError(err, 'while trying to close document request');
    }
  }

  private getReplyLink () {
    return (
      <LinkButton
        className={styles['reply-button']}
        id='request-reply-button'
        onClick={this.isRequestResponseModalVisible.setTrue}
      >
        Compose <Icon.Edit />
      </LinkButton>
    );
  }

  private getReplyOptions (): ReplyOptions[] {
    const { _case, goBack } = this.props
      , { stores: { users: { isLawFirmUser } } } = this.injected;

    if (isLawFirmUser) {
      return [
        {
          title: 'Close Request',
          action: (
            <Popconfirm
              cancelText='No'
              okText='Yes'
              onConfirm={this.closeRequest}
              title='Are you sure?'
            >
              <LinkButton
                className={styles['reply-button']}
                id='close-request-button'
              >
                Close Request <Icon.Check />
              </LinkButton>
            </Popconfirm>
          ),
        },
        {
          title: 'Send a custom message',
          action: this.getReplyLink(),
        }
      ];
    }

    return [
      {
        title: 'Reply with documents',
        action: <UploadDocumentButton _case={_case} goBack={goBack} />,
      },
      {
        title: 'Reply with a custom message',
        action: this.getReplyLink(),
      },
    ];
  }

  public render () {
    const options = this.getReplyOptions()
      , { _case } = this.props
      , { stores: { users: { isLawFirmUser } } } = this.injected
      , recipient = isLawFirmUser ? 'Lienholder' : 'Law Firm'
      ;

    return (
      <>
        <Row className={cx(styles.root, styles.override)}>
          <p>Options:</p>
          {options.map(option => {
            const { title, action } = option;

            return (
              <Row key={title} className={styles['reply-option-row']}>
                <InfoCard
                  bottomRightRender={action}
                  content={null}
                  subtitle={title}
                  title=''
                  topRightContent={null}
                />
              </Row>
            );
          })}
        </Row>
        <RequestResponseModal
          _case={_case}
          isVisible={this.isRequestResponseModalVisible}
          onSave={this.onSave}
          title={`Update the ${recipient}...`}
        />
      </>
    );
  }
}

export default DocumentRequestReplyOptions;
