import React, { CSSProperties } from 'react';

import { Col, Row, Spin } from 'antd';
import { RowProps } from 'antd/lib/row';

import { EMPTY_FIELD } from '@mighty-justice/utils';

import styles from './ScrollItem.module.less';

interface IProps {
  style: CSSProperties;
  index: number;
}

const rowProps: RowProps = { align: 'middle', justify: 'space-between' };

function EmptyListScrollItem (props: IProps) {
  const { style, index } = props;

  return (
    <div key={index} style={style}>
      <Spin spinning>
        <Row {...rowProps} className={styles.root}>
          <Col>
            <div className={styles.name}>Plaintiff Name</div>
            <div className={styles.subtitle}>DOB: {EMPTY_FIELD} | DOL: {EMPTY_FIELD}</div>
          </Col>
        </Row>
      </Spin>
    </div>
  );
}

export default EmptyListScrollItem;
