import React, { Component } from 'react';
import autoBindMethods from 'class-autobind-decorator';
import { inject, observer } from 'mobx-react';
import { observable } from 'mobx';
import { get } from 'lodash';

import { Col, notification, Row } from 'antd';

import SmartBool from '@mighty-justice/smart-bool';

import Client from '../../base-modules/client';
import { Button, LinkButton } from '../common';
import { IManualDedupeCase } from '../../models/ManualDedupeCase';
import { IManualDedupeMatch, ManualDedupeMatch } from '../../models/ManualDedupeMatch';
import { URLS } from '../../utils/constants';
import { showError } from '../../utils/utils';
import StoresClass from '../../stores/StoresClass';

import LegalOrganizationSearch from './LegalOrganizationSearch';
import CaseCard from './CaseCard';

interface IInjected {
  client: Client;
  stores: StoresClass;
}

@inject('client', 'stores')
@autoBindMethods
@observer
class ManualDedupePageContent extends Component {
  @observable private match: IManualDedupeMatch;
  @observable private isSubmitting = new SmartBool();

  private get injected () { return this.props as IInjected; }

  public constructor (props: {}) {
    super(props);
    this.match = ManualDedupeMatch.create(
      { baseUrl: URLS.INTERNAL_MANUAL_CASE_DEDUPE },
      this.injected.stores.dependencies,
    );
  }

  private onSearchChange (value: { id: string } | null) {
    const id = get(value, 'id', null);
    this.match.setLegalOrganization(id);
  }

  private getSearchFilters (otherCase: IManualDedupeCase | null) {
    const defaultFilters = {
      legal_organization: this.match.legalOrganizationId as string,
      page_size: 20,
    };

    if (!otherCase) {
      return defaultFilters;
    }

    return {
      ...defaultFilters,
      exclude: otherCase.id,
      law_firm: otherCase.law_firm.id,
    };
  }

  private async mergeCases () {
    try {
      this.isSubmitting.setTrue();
      await this.match.mergeDuplicates();
      notification.success({
        description: '',
        message: 'The cases have been merged!',
      });
    }
    catch (err) {
      showError(err);
    }
    finally {
      this.isSubmitting.setFalse();
    }
  }

  public render () {
    const canMerge = this.match.hasDuplicates;

    return (
      <div>
        <LegalOrganizationSearch onChange={this.onSearchChange} />
        {this.match.legalOrganizationId && (
          <>
            <LinkButton onClick={this.match.swapMatch}>
              Swap cases
            </LinkButton>
            <Row gutter={64}>
              <Col span={12}>
                <CaseCard
                  _case={this.match.canonical}
                  isCanonical
                  onChange={this.match.setCanonical}
                  previewSummary={this.match.previewSummary}
                  searchFilters={this.getSearchFilters(this.match.duplicate)}
                />
              </Col>
              <Col span={12}>
                <CaseCard
                  _case={this.match.duplicate}
                  onChange={this.match.setDuplicate}
                  previewSummary={this.match.previewSummary}
                  searchFilters={this.getSearchFilters(this.match.canonical)}
                />
              </Col>
            </Row>
            <Button
              disabled={!canMerge || this.isSubmitting.isTrue}
              id='merge-cases-button'
              onClick={this.mergeCases}
              type='primary'
            >
              {this.isSubmitting.isTrue ? 'Merging...' : 'Merge cases'}
            </Button>
          </>
        )}
      </div>
    );
  }
}

export default ManualDedupePageContent;
