import autoBindMethods from 'class-autobind-decorator';
import { observable } from 'mobx';
import { find } from 'lodash';

import SmartBool from '@mighty-justice/smart-bool';

import * as constants from '../utils/constants';
import Client from '../base-modules/client';

export interface IOption {
  name: string;
  value: string;
}

@autoBindMethods
class OptionsStore {
  @observable public isLoading = new SmartBool(true);

  public options: {[key: string]: any} = {};
  private client: Client;

  public constructor (client: Client) {
    this.client = client;
  }

  public async fetch () {
    this.options = await this.isLoading.until(this.client.get('/options/'));
  }

  public getOptions (key: string, includeNone = false): IOption[] {
    const noneArray: IOption[] = includeNone ? [constants.NULL_OPTION] : [];

    if (key in this.options) {
      return this.options[key].concat(noneArray);
    }

    if (key in constants) {
      const constant = (constants as any)[key] as { [key: string]: string };
      return Object.keys(constant)
        .map(constKey => ({ value: constKey, name: constant[constKey] }))
        .concat(noneArray);
    }

    // istanbul ignore next
    return ([] as IOption[]).concat(noneArray);
  }

  public getOption (optionKey: string, key: string): IOption | undefined {
    const options = this.getOptions(optionKey);

    return find(options, { value: key });
  }

  public getOptionName (optionKey: string, key: string): string {
    const option = this.getOption(optionKey, key);

    return option ? option.name : '';
  }

  public checkOption (optionKey: string, key: string): string | undefined {
    const option = this.getOption(optionKey, key);

    return option ? option.value : undefined;
  }

  public getFilters (key: string, includeNone = false): Array<{ label: string, value: string }> {
    return this.getOptions(key, includeNone).map(({ value, name }) => ({ value, label: name }));
  }
}

export default OptionsStore;
