import React, { Component } from 'react';
import { observer } from 'mobx-react';
import autoBindMethods from 'class-autobind-decorator';
import cx from 'classnames';

import { List } from 'antd';

import { InitialsAvatar } from '../../../common/Avatars';
import NotificationCreatedAt from '../NotificationCreatedAt';
import NotificationContent from '../NotificationContent';

import { IDefaultNotificationProps } from './DefaultMightyNotification';

import styles from './DefaultLawFirmToLienholderNotification.module.less';

@autoBindMethods
@observer
class DefaultLawFirmToLienholderNotification extends Component<IDefaultNotificationProps> {
  private onClick () {
    const { onClick, notification } = this.props;
    onClick(notification);
  }

  public render () {
    const { className, notification, notification: { context }, style } = this.props;

    return (
      <List.Item key={notification.id} style={style} className={cx(styles.root, className)} onClick={this.onClick}>
        <List.Item.Meta
          avatar={<InitialsAvatar name={context.law_firm_name} />}
          description={<NotificationCreatedAt notification={notification} />}
          title={<NotificationContent content={notification.body} notification={notification} />}
        />
      </List.Item>
    );
  }
}

export default DefaultLawFirmToLienholderNotification;
