import { ORGANIZATION_TYPES } from '../../../utils/constants';

export const LH_ORGANIZATION_OPTIONS = [
  { value: ORGANIZATION_TYPES.MEDICAL_PRACTICE, name: 'Medical Practice' },
  { value: ORGANIZATION_TYPES.FUNDER, name: 'Funder' },
  { value: ORGANIZATION_TYPES.SERVICER, name: 'Servicer' },
  { value: ORGANIZATION_TYPES.HEALTH_INSURANCE, name: 'Health Insurance' },
  { value: ORGANIZATION_TYPES.DEFENDANT_INSURANCE, name: 'Defendant Insurance' },
  { value: ORGANIZATION_TYPES.REFERRAL_LAW_FIRM, name: 'Referral Law Firm' },
  { value: ORGANIZATION_TYPES.OTHER, name: 'Other' },
] as Array<{ value: string, name: string }>;

export const ONBOARDING_STEPS = [
  { page: 'account', title: 'Get Started' },
  { page: 'cases', title: 'Register Cases' },
  { page: 'details', title: 'Verify Contact Information' },
];

export const FINAL_STEP_IDX = 2;

export const REGISTRATION_URLS = {
  ACCOUNT_CREATION: '/register-lh/account',
  ACCOUNT_DETAILS: '/register-lh/details',
  CASE_CREATION: '/register-lh/cases',
  CONFIRMATION: '/register-lh/confirmation',
};
