import React, { Component } from 'react';
import autoBindMethods from 'class-autobind-decorator';
import { inject, observer } from 'mobx-react';

import { Row } from 'antd';

import StoresClass from '../../../stores/StoresClass';
import { ISettingLegalOrganization } from '../../../models/User';

import MultiSelectLawFirmSearch from './MultiSelectLawFirmSearch';

import styles from './NotificationSettings.module.less';

interface IInjected {
  stores: StoresClass;
}

@inject('stores')
@autoBindMethods
@observer
class NotificationSubscriptionForm extends Component<{}> {
  private get injected () { return this.props as IInjected; }

  private async onChange (value: ISettingLegalOrganization[]): Promise<void> {
    const { stores: { settings: { upsertNotificationSubscriptionSetting }, users } } = this.injected
      , userId = users?.user?.id
      , data = { mighty_user: userId, registry_legal_organizations: value.map(v => v.id) }
      ;

    await upsertNotificationSubscriptionSetting(data);
  }

  public render () {
    const { stores: { settings } } = this.injected
      , value: ISettingLegalOrganization[] =
        settings.notificationSubscriptionSetting?.registry_legal_organizations || []
      ;

    return (
      <div>
        <Row className={styles.subtitle}>
          <p>If you wish to receive case updates from a specific set of law firms only, add those law firms here.</p>
        </Row>
        <Row>
          <MultiSelectLawFirmSearch onChange={this.onChange} value={value} />
        </Row>
      </div>
    );
  }
}

export default NotificationSubscriptionForm;
