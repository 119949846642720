import React, { Component } from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import autoBindMethods from 'class-autobind-decorator';
import cx, { Argument as IClassName } from 'classnames';

import { Col, Row, Switch } from 'antd';

import SmartBool from '@mighty-justice/smart-bool';

import { ANT_THIRD_COL_WIDTH } from '../../../../../utils/constants';
import { Text } from '../../../../common';

import styles from './SettingToggle.module.less';

interface IProps {
  className?: IClassName;
  description: string;
  label: string;
  onChange: (value: boolean) => Promise<any> | any;
  value: boolean;
}

@autoBindMethods
@observer
class SettingToggle extends Component<IProps> {
  @observable private isLoading = new SmartBool();

  private async onChange (value: boolean) {
    const { onChange } = this.props;
    this.isLoading.until(onChange(value));
  }

  public render () {
    const { label, description, value, className } = this.props;

    return (
      <div className={cx(styles.root, className)}>
        <Row>
          <Col span={ANT_THIRD_COL_WIDTH * 2}>
            <Row><Text bold>{label}</Text></Row>
            <Row><Text size={2}>{description}</Text></Row>
          </Col>
          <Col className={styles.toggleCol} span={ANT_THIRD_COL_WIDTH}>
            <Switch
              checkedChildren='On'
              defaultChecked={value}
              loading={this.isLoading.isTrue}
              onChange={this.onChange}
              unCheckedChildren='Off'
            />
          </Col>
        </Row>
      </div>
    );
  }
}

export default SettingToggle;
