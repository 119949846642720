import React, { Component } from 'react';
import { observer } from 'mobx-react';
import autoBindMethods from 'class-autobind-decorator';

import { Popover } from 'antd';

import { ITableStoreAPI } from '../../stores/ITableStoreAPI';
import { Button, Icon } from '../common';

import { IFacetSet } from './interfaces';
import FacetInputWrapper from './FacetInputWrapper';
import { getFacetKey, numFacetsApplied } from './facetUtils';

import styles from './FacetsDropdown.module.less';

interface IProps {
  facetSet: IFacetSet;
  tableStore: ITableStoreAPI;
}

@autoBindMethods
@observer
class FacetsDropdown extends Component<IProps> {
  public render () {
    const { facetSet, tableStore } = this.props
      , isApplied = Boolean(numFacetsApplied([facetSet], tableStore.filters))
      , buttonType = isApplied ? 'primary' : 'default'
      , content = facetSet.facets.map(facet => (
        <FacetInputWrapper
          button
          collapsible={false}
          facet={facet}
          key={getFacetKey(facet)}
          tableStore={tableStore}
        />
      ));

    return (
      <div className={styles.root}>
        <Popover
          className='facet-button'
          content={content}
          overlayClassName={styles.popover}
          overlayStyle={{ width: '275px' }}
          placement='bottom'
          trigger='click'
        >
          <Button type={buttonType}>
            {facetSet.label}<Icon.CaretDownSolid />
          </Button>
        </Popover>
      </div>
    );
  }
}

export default FacetsDropdown;
