import React, { Component } from 'react';
import { observer } from 'mobx-react';
import autoBindMethods from 'class-autobind-decorator';
import cx from 'classnames';

import { Form } from '@mighty-justice/fields-ant';
import { formatDate, getNameOrDefault } from '@mighty-justice/utils';

import Card from '../Card';
import { ILegacyBaseCase } from '../../../models/Legacy';
import { IManualDedupeCase } from '../../../models/ManualDedupeCase';
import { objectSearchCreateConfig } from '../../../utils/configCommon';

import styles from './CaseDedupeSelectCard.module.less';

export interface IProps {
  children?: React.ReactNode;
  className?: string;
  endpoint: string;
  fieldOverrides: { [key: string]: any };
  onCaseSelect: ((_case: ILegacyBaseCase) => void) | ((_case: IManualDedupeCase) => void);
  searchFilters: { [key: string]: string | number };
  selectedCaseId?: string;
}

@autoBindMethods
@observer
class CaseDedupeSelectCard extends Component<IProps> {
  public static defaultProps = {
    fieldOverrides: {},
  };

  private get searchFilters () {
    const { searchFilters, selectedCaseId } = this.props
      , exclusion = selectedCaseId ? { exclude: selectedCaseId } : {}
      ;

    return {
      page_size: 1000,
      ...exclusion,
      ...searchFilters,
    };
  }

  private renderOption (option: ILegacyBaseCase | IManualDedupeCase) {
    const { onCaseSelect } = this.props
      , onClick = onCaseSelect as (_case: any) => void
      ;

    return (
      <div onClick={onClick.bind(this, option)} className={styles.option}>
        <b className={cx(styles.plaintiff, 'dd-privacy-mask')}>{getNameOrDefault(option.plaintiff)}</b>
        <p>Date of Loss <b>{formatDate(option.date_of_loss)}</b></p>
      </div>
    );
  }

  private get fieldsets () {
    const { endpoint, fieldOverrides } = this.props;

    return [[{
      editProps: {
        ...objectSearchCreateConfig.editProps,
        noSearchContent: `Type in plaintiff's name`,
        selectProps: {
          ...objectSearchCreateConfig.editProps.selectProps,
          className: cx(styles.search, 'dd-privacy-mask-user-input'),
          placeholder: 'Search for plaintiff',
        },
      },
      endpoint,
      field: '_case',
      label: 'Select duplicate case',
      renderOption: this.renderOption,
      renderSelected: () => null, // pass to remove title tooltip
      searchFilters: this.searchFilters,
      type: 'objectSearch',
      ...fieldOverrides,
    }]];
  }

  public render () {
    const { children, className } = this.props;

    return (
      <Card bordered={false} className={cx(styles.card, className)}>
        <Form
          fieldSets={this.fieldsets}
          showControls={false}
        />
        {children}
      </Card>
    );
  }
}

export default CaseDedupeSelectCard;
