import React, { Component } from 'react';
import autoBindMethods from 'class-autobind-decorator';

import { ReactComponent as Spy } from '../../assets/images/spy.svg';

import styles from './ImpersonatePage.module.less';

@autoBindMethods
class ImpersonatePage extends Component {
  public render () {
    return (
      <div className={styles.root}>
        <div className={styles.spy}>
          <Spy />
        </div>
        <h1>Impersonating user...</h1>
      </div>
    );
  }
}

export default ImpersonatePage;
