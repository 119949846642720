import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { lowerCase, startCase } from 'lodash';

import StoresClass from '../../../../stores/StoresClass';
import { OPTION_KEYS } from '../../../../utils/constants';

import { IActivityDetailComponentProps } from './interfaces';

interface IInjected extends IActivityDetailComponentProps {
  stores: StoresClass;
}

@inject('stores')
@observer
class DefaultActivity extends Component<IActivityDetailComponentProps> {
  private get injected () { return this.props as IInjected; }

  public render () {
    const { activity: { type } } = this.props
      , { stores } = this.injected
      , title = stores.options.getOptionName(OPTION_KEYS.ACTIVITY_TYPES, type) || startCase(lowerCase(type))
      ;

    return (
      <h4>{title}</h4>
    );
  }
}

export default DefaultActivity;
