import autoBindMethods from 'class-autobind-decorator';
import { action } from 'mobx';
import { omit } from 'lodash';
import '@datadog/browser-rum/bundle/datadog-rum';

import StoresClass from '../stores/StoresClass';
import { getNextOrDefault, getQuery, getUrl, IRedirectTo, IURL } from '../utils/navigationUtils';
import { ISetupParams } from '../interfaces';
import { URLS } from '../utils/constants';
import { User } from '../models/User';

import Analytics from './analytics';
import Auth, { AUTH_URL_PARAM_KEYS } from './auth';
import Client from './client';
import { Environment, fetchEnvironment } from './environment';

@autoBindMethods
class Controller {
  public analytics: Analytics;
  public auth: Auth;
  public client: Client;
  public environment: Environment;
  public stores: StoresClass;

  public constructor (environment: Environment, client: Client) {
    this.client = client;
    this.environment = environment;
    this.stores = new StoresClass(client);
    this.auth = new Auth(environment, client, this.stores);
    this.analytics = new Analytics(client, environment, this.stores, this.auth);
  }

  public async init (url: IURL): Promise<IRedirectTo> {
    this.analytics.initPrePaint();

    const authRedirectTo: IRedirectTo = await this.auth.init(url)
      , accountRedirectTo: IRedirectTo = this.stores.users.setInitialAccountFromURL(authRedirectTo || url)
      , redirectTo: IRedirectTo = accountRedirectTo || authRedirectTo
      ;

    this.stores.setInfiniteTableStore();

    this.stores.infiniteTableStore.setInitialFilters(omit(getQuery(), AUTH_URL_PARAM_KEYS));

    // Wait for pre-paint first so it doesn't get slowed down by non-pre-paint calls
    await this.prePaint();

    await this.onLoad();

    return redirectTo;
  }

  private async prePaint (): Promise<void> {
    await this.stores.options.fetch();
    await this.stores.linkCopyItems.fetch();
  }

  private async onLoad (): Promise<void> {
    await this.analytics.initOnLoad();
    await this.onUserChange();
  }

  private async onUserChange (): Promise<void> {
    if (!this.stores.users.isLoggedIn) { return; }

    // This should be triggered first
    await this.stores.infiniteTableStore.refresh();

    this.analytics.identifyUser();

    this.stores.notifications.fetch();
    this.stores.settings.fetch();
    this.stores.externalSystems.fetch();

    if (this.stores.users.isLawFirmUser) {
      this.stores.pointsStore.fetch();
    }
  }

  public get provided () {
    return {
      analytics: this.analytics,
      auth: this.auth,
      client: this.client,
      controller: this,
      environment: this.environment,
      getEndpoint: this.client.get,
      getOptions: this.stores.options.getOptions,
      stores: this.stores,
    };
  }

  @action
  public async login (username: string, password: string, setupParams?: ISetupParams) {
    await this.client.invoke({
      baseURL: this.auth.authBaseUrl,
      data: { username, password },
      method: 'post',
      url: '/auth/login/',
    });

    await this.auth.checkSession();
    await this.auth.userSafetyCheck();
    this.stores.users.setInitialAccountFromURL(getUrl());

    if (setupParams && this.stores.users.account && this.stores.users.user) {
      await this.stores.users.account.update(setupParams, this.auth);
      this.stores.users.user.updateName(setupParams);
    }

    await this.onUserChange();
  }

  @action
  public async loginAndGetRedirect (username: string, password: string, setupParams?: ISetupParams): Promise<IURL> {
    await this.login(username, password, setupParams);
    return getNextOrDefault(getUrl(), URLS.PORTFOLIO_PAGE);
  }

  @action
  public async changeAccount (accountId: string): Promise<void> {
    this.stores.users.changeAccount(accountId);
    await this.onUserChange();
  }

  public async loginWithGoogleToken (accessToken: string) {
    const response = await this.auth.convertGoogleToken(accessToken),
      { access_token, refresh_token, expires_in } = response.data;

    this.auth.setAuthentication({ token: access_token, refreshToken: refresh_token, expiresIn: expires_in });

    const user = await this.auth.setUserForGoogleSignIn();

    this.stores.users.user = User.create(user, this.stores.dependencies);
    await this.auth.userSafetyCheck();
    this.stores.users.setInitialAccountFromURL(getUrl());

    await this.onUserChange();
  }
}

// Controller is passed as a prop in tests
// istanbul ignore next
async function fetchController (): Promise<Controller> {
  const environment = await fetchEnvironment()
    , client = new Client(environment);

  return new Controller(environment, client);
}

export { fetchController, Controller };
