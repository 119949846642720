import React, { Component } from 'react';
import { observer } from 'mobx-react';
import autoBindMethods from 'class-autobind-decorator';

import LoggedOutLandingPage from '../LoggedOutLandingPage';
import { LinkButton, Spacer } from '../common';

import SetupForm from './SetupForm';

@autoBindMethods
@observer
class SetupPage extends Component {
  public render () {
    return (
      <LoggedOutLandingPage title='Create your password'>
        <SetupForm title='Almost there! Complete your details to access your cases' />
        <Spacer/>
        Already have an account? <LinkButton href='/login'>Login</LinkButton>
      </LoggedOutLandingPage>
    );
  }
}

export default SetupPage;
