import React from 'react';
import { Link } from 'react-router-dom';

import { Spacer } from '../common';

export const TokenExpiredMessage = ({ type }: {type: string}) => (
  <div>
    <Spacer/>
    <h4>Your {type} token has expired.</h4>
    <div>
      Follow the instructions at <Link to='/request-password-reset'>this link</Link>
      <br />
      to reset your password.
    </div>
    <Spacer/>
  </div>
);
