import React, { Component } from 'react';
import autoBindMethods from 'class-autobind-decorator';
import { inject, observer } from 'mobx-react';
import { observable } from 'mobx';

import SmartBool from '@mighty-justice/smart-bool';

import { IDetailCase } from '../../../../models/Case';
import { IDetailLien } from '../../../../models/Lien';
import { ILegalOrganizationWithContacts } from '../../../../models/Party';
import { SelectOrganizationReturnData } from '../common/SelectOrganizationModal';
import StoresClass from '../../../../stores/StoresClass';

import AddContactsModal from './AddContactsModal';
import AddOrganizationButton from './AddOrganizationButton';

interface IProps {
  _case: IDetailCase;
  isLawFirm: boolean;
}

interface IInjected extends IProps {
  stores: StoresClass;
}

@inject('stores')
@autoBindMethods
@observer
class AddOrganizationAndContacts extends Component<IProps> {
  @observable private isAddingNew = new SmartBool();
  @observable private isModalVisible = new SmartBool();
  @observable private legalOrganization: ILegalOrganizationWithContacts | null = null;

  private get injected () { return this.props as IInjected; }

  private async addOrganization (id: string) {
    const { _case, isLawFirm } = this.props
      , { stores: { infiniteTableStore } } = this.injected;

    if (isLawFirm) {
      const caseId = await _case.lienholderChangeLawFirm(id);
      infiniteTableStore.onCaseIdChange(caseId);
      this.legalOrganization = _case.law_firm;
    }
    else {
      const lien = await _case.addLien(id) as IDetailLien;
      this.legalOrganization = lien.lienholder;
    }

    this.isModalVisible.setTrue();
  }

  private async onOrganizationCreate (data: SelectOrganizationReturnData) {
    this.isAddingNew.setTrue();
    await this.addOrganization(data.id);

    if (this.legalOrganization) {
      this.legalOrganization.setNewlyCreated();
    }
  }

  private async onOrganizationSelect (data: SelectOrganizationReturnData) {
    this.isAddingNew.setFalse();
    await this.addOrganization(data.id);
  }

  public render () {
    return (
      <>
        <AddOrganizationButton onCreate={this.onOrganizationCreate} onSelect={this.onOrganizationSelect} />
        <AddContactsModal
          isNewOrganization={this.isAddingNew.isTrue}
          isVisible={this.isModalVisible}
          legalOrganization={this.legalOrganization}
        />
      </>
    );
  }
}

export default AddOrganizationAndContacts;
