import React, { FunctionComponent } from 'react';
import cx from 'classnames';

import { FontAwesomeIcon, FontAwesomeIconProps } from '@fortawesome/react-fontawesome';
import { library, IconDefinition } from '@fortawesome/fontawesome-svg-core';
import * as faLight from '@fortawesome/pro-light-svg-icons';
import * as fa from '@fortawesome/pro-regular-svg-icons';
import * as faSolid from '@fortawesome/pro-solid-svg-icons';

type PartialFontAwesomeIconProps = Pick<FontAwesomeIconProps, Exclude<keyof FontAwesomeIconProps, 'icon'>>;

export interface IIconProps extends PartialFontAwesomeIconProps {
  className?: string;
  icon: IconDefinition;
  primary?: boolean;
  soft?: boolean;
  type?: 'info' | 'success' | 'warning' | 'danger';
}
export type PartialIconProps = Pick<IIconProps, Exclude<keyof IIconProps, 'icon'>>;

const ICON_PREFIX_CLS = 'm-icon';

const MightyIcon = (props: IIconProps) => {
  const { primary, type, soft, className, ...faProps } = props
    , fullClassName = cx(
      ICON_PREFIX_CLS,
      { [`${ICON_PREFIX_CLS}-primary`]: !!primary },
      { [`${ICON_PREFIX_CLS}-${type}`]: !!type },
      { [`${ICON_PREFIX_CLS}-soft`]: !!soft },
      className,
    );

  return <FontAwesomeIcon {...faProps} className={fullClassName} />;
};

function addAndGetIconComponent (iconDefinition: IconDefinition): FunctionComponent<PartialIconProps> {
  library.add(iconDefinition);
  return (props: PartialIconProps) => <MightyIcon {...props} icon={iconDefinition} />;
}

class MapIcons {
  // Naming structure: "IconName[?(Light|Solid)]"
  public static AlarmClock = addAndGetIconComponent(faLight.faAlarmClock);
  public static ArrowLeft = addAndGetIconComponent(faSolid.faArrowLeft);
  public static BadgeCheckLight = addAndGetIconComponent(faLight.faBadgeCheck);
  public static Ban = addAndGetIconComponent(fa.faBan);
  public static Bars = addAndGetIconComponent(faSolid.faBars);
  public static BellLight = addAndGetIconComponent(faLight.faBell);
  public static BriefcaseLight = addAndGetIconComponent(faLight.faBriefcase);
  public static CaretDownSolid = addAndGetIconComponent(faSolid.faCaretDown);
  public static CaretUpSolid = addAndGetIconComponent(faSolid.faCaretUp);
  public static Check = addAndGetIconComponent(fa.faCheck);
  public static CheckCircle = addAndGetIconComponent(fa.faCheckCircle);
  public static CheckCircleLight = addAndGetIconComponent(faLight.faCheckCircle);
  public static ChevronDoubleRight = addAndGetIconComponent(fa.faChevronDoubleRight);
  public static ChevronDown = addAndGetIconComponent(faSolid.faChevronDown);
  public static ChevronLeft = addAndGetIconComponent(fa.faChevronLeft);
  public static ChevronRight = addAndGetIconComponent(fa.faChevronRight);
  public static ChevronUp = addAndGetIconComponent(faSolid.faChevronUp);
  public static CircleSolid = addAndGetIconComponent(faSolid.faCircle);
  public static Clone = addAndGetIconComponent(fa.faClone);
  public static CloudDownload = addAndGetIconComponent(fa.faCloudDownload);
  public static CloudUploadAlt = addAndGetIconComponent(fa.faCloudUploadAlt);
  public static CogLight = addAndGetIconComponent(faLight.faCog);
  public static Comment = addAndGetIconComponent(fa.faComment);
  public static Copy = addAndGetIconComponent(fa.faCopy);
  public static CopyLight = addAndGetIconComponent(faLight.faCopy);
  public static Download = addAndGetIconComponent(faSolid.faArrowAltToBottom);
  public static Edit = addAndGetIconComponent(fa.faEdit);
  public static Envelope = addAndGetIconComponent(fa.faEnvelope);
  public static EnvelopeLight = addAndGetIconComponent(faLight.faEnvelope);
  public static ExclamationCircle = addAndGetIconComponent(fa.faExclamationCircle);
  public static ExclamationCircleLight = addAndGetIconComponent(faLight.faExclamationCircle);
  public static ExternalLink = addAndGetIconComponent(fa.faExternalLinkAlt);
  public static FileAltLight = addAndGetIconComponent(faLight.faFileAlt);
  public static FileInvoiceDollarLight = addAndGetIconComponent(faLight.faFileInvoiceDollar);
  public static FileMedical = addAndGetIconComponent(faLight.faFileMedical);
  public static FilePlusLight = addAndGetIconComponent(faLight.faFilePlus);
  public static FileSearch = addAndGetIconComponent(fa.faFileSearch);
  public static FolderOpenLight = addAndGetIconComponent(faLight.faFolderOpen);
  public static Folders = addAndGetIconComponent(fa.faFolders);
  public static HourGlassLight = addAndGetIconComponent(faLight.faHourglassStart);
  public static InboxLight = addAndGetIconComponent(faLight.faInbox);
  public static InfoCircle = addAndGetIconComponent(fa.faInfoCircle);
  public static InfoCircleLight = addAndGetIconComponent(faLight.faInfoCircle);
  public static Link = addAndGetIconComponent(faLight.faLink);
  public static LockAltSolid = addAndGetIconComponent(faSolid.faLockAlt);
  public static Plus = addAndGetIconComponent(fa.faPlus);
  public static PuzzlePiece = addAndGetIconComponent(fa.faPuzzlePiece);
  public static QuestionCircle = addAndGetIconComponent(fa.faQuestionCircle);
  public static QuestionCircleLight = addAndGetIconComponent(faLight.faQuestionCircle);
  public static Redo = addAndGetIconComponent(fa.faRedo);
  public static Reply = addAndGetIconComponent(faSolid.faReply);
  public static Search = addAndGetIconComponent(fa.faSearch);
  public static Share = addAndGetIconComponent(faSolid.faShare);
  public static SignalStream = addAndGetIconComponent(fa.faSignalStream);
  public static SignOut = addAndGetIconComponent(fa.faSignOut);
  public static SortAmountDown = addAndGetIconComponent(faSolid.faSortAmountDown);
  public static Sparkles = addAndGetIconComponent(faSolid.faSparkles);
  public static SpinnerThird = addAndGetIconComponent(fa.faSpinnerThird);
  public static Timeline = addAndGetIconComponent(fa.faHistory);
  public static Times = addAndGetIconComponent(fa.faTimes);
  public static TimesCircle = addAndGetIconComponent(fa.faTimesCircle);
  public static TimesCircleLight = addAndGetIconComponent(faLight.faTimesCircle);
  public static TimesCircleSolid = addAndGetIconComponent(faSolid.faTimesCircle);
  public static TrashAlt = addAndGetIconComponent(fa.faTrashAlt);
  public static TrashAltSolid = addAndGetIconComponent(faSolid.faTrashAlt);
  public static USDCircle = addAndGetIconComponent(faSolid.faUsdCircle);
  public static User = addAndGetIconComponent(fa.faUser);
  public static UserCogSolid = addAndGetIconComponent(faSolid.faUserCog);
  public static UserPlusSolid = addAndGetIconComponent(faSolid.faUserPlus);
  public static UsersLight = addAndGetIconComponent(faLight.faUsers);
  public static UserSolid = addAndGetIconComponent(faSolid.faUser);
  public static UsersSolid = addAndGetIconComponent(faSolid.faUsers);
}

export type IIcon = React.FC<PartialIconProps>;
export default MapIcons;
