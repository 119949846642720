import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import autoBindMethods from 'class-autobind-decorator';

import { FormModal } from '@mighty-justice/fields-ant';
import SmartBool from '@mighty-justice/smart-bool';

import { IDetailLien } from '../../../../models/Lien';
import StoresClass from '../../../../stores/StoresClass';
import { INavigateProps } from '../../../../utils/navigationUtils';
import navigationWrapper from '../../../../utils/navigationWrapper';

import { resolveLienModalChildrenBefore, resolveLienModalFieldSets } from './constants';

import styles from '../../../common/Modal.module.less';

interface IProps extends INavigateProps {
  isVisible: SmartBool;
  lien: IDetailLien;
}

interface IInjected extends IProps {
  stores: StoresClass;
}

@inject('stores')
@autoBindMethods
@observer
class ResolveLienModal extends Component<IProps> {
  private get injected () { return this.props as IInjected; }

  private async onSave (data: any) {
    const { lien, navigate } = this.props
      , { infiniteTableStore } = this.injected.stores
      ;

    await Promise.all([
      lien.updateResolutionStatus(data),
      infiniteTableStore.fetchTableData()
    ]);

    navigate(infiniteTableStore.firstCaseUrl);
  }

  public render () {
    const { isVisible } = this.props;

    return (
      <FormModal
        className={styles.root}
        childrenBefore={resolveLienModalChildrenBefore}
        fieldSets={resolveLienModalFieldSets}
        isVisible={isVisible}
        onSave={this.onSave}
        title='Resolve Lien'
      />
    );
  }
}

export default navigationWrapper(ResolveLienModal);
