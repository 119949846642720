/* eslint-disable max-lines */

import {
  Instance,
  applySnapshot,
  destroy,
  flow,
  getParent,
  hasParent,
  isAlive,
  types,
} from 'mobx-state-tree';
import { orderBy, omit } from 'lodash';
import copy from 'clipboard-copy';

import { message } from 'antd';

import { toKey } from '@mighty-justice/utils';

import { stateTreeDependencyGetters } from '../base-modules/state-tree-dependencies';
import {
  CASE_ACTIVITY_TYPES,
  NOTE_ACTIVITIES,
  ORGANIZATION_TYPES,
  PLAINTIFF_TREATMENT_STATUSES,
  REQUEST_STATUS_VALUES,
  SHARED_LIEN_DOCUMENT_TYPES,
  URLS,
  VERIFICATION_STATUSES,
} from '../utils/constants';
import { setValuesFromData } from '../utils/utils';
import { IActivitySubmitData } from '../interfaces';
import { mergePathAndQuery } from '../utils/navigationUtils';
import { AUTO_THREAD_RESPONSE_NOTE } from '../components/page-portfolio/portfolio-detail/tab-inbox/constants';
import { IBetaDocumentSubmitData } from '../components/page-portfolio/portfolio-detail/tab-documents/interfaces';

import { IDetailCase } from './Case';
import { ICaseActivity, ModelWithActivities } from './CaseActivity';
import { LienContact } from './Contact';
import { Document, IDocument, ModelWithDirectDocumentUpload } from './Document';
import { ICaseThread } from './CaseThread';
import { ILegalOrganizationExternalSystem } from './LegalOrganizationExternalSystem';
import { Lienholder } from './Party';
import { LienExternalKey } from './LienExternalKey';
import { IExternalKey } from './ExternalKey';
import { ModelWithTags } from './Tag';

export const DetailLien = types
  .compose(
    ModelWithActivities,
    ModelWithDirectDocumentUpload,
    ModelWithTags,
    types.model('DetailLien', {
      balance: types.maybeNull(types.number),
      balance_last_updated_at: types.maybeNull(types.string),
      balance_last_updated_by_organization_name: types.maybeNull(types.string),
      // used in DocumentPaymentPage to redirect to proper case
      case: types.string,
      created_at: types.string,
      created_by_organization: types.maybeNull(types.string),
      final_treatment_date: types.maybeNull(types.string),
      has_lienholder_finalized_documents: types.maybeNull(types.boolean),
      id: types.identifier,
      is_lienholder_blacklisted: types.maybeNull(types.boolean),
      lien_contacts: types.optional(types.array(LienContact), []),
      lien_documents: types.array(Document),
      lienholder: Lienholder,
      lienholder_last_active_at: types.maybeNull(types.string),
      plaintiff_treatment_status: types.maybeNull(types.string),
      resolution_status: types.maybeNull(types.string),
      share_documents: types.boolean,
      verification_status: types.maybeNull(types.string),

      // These fields are null until they are fetched
      external_keys: types.optional(types.maybeNull(types.array(LienExternalKey)), null),
      related_lienholders: types.optional(types.array(types.string), []),
      tagUrl: '/lien-tags/',
    }),
  )
  .views(stateTreeDependencyGetters)
  .views(self => ({
    get detailUrl (): string {
      return `${URLS.LIENHOLDER_LIEN_BETA}${self.id}/`;
    },

    get isResolved (): boolean {
      return !!self.resolution_status;
    },

    get _case (): any {
      if (!hasParent(self)) { return null; }
      return getParent(self, 2);
    },

    get allActivities (): ICaseActivity[] {
      const parentCase: IDetailCase = getParent(self, 2)
        , caseActivities = parentCase.activities || []
        , lienActivities = self.activities || []
        , allActivities = [...caseActivities, ...lienActivities]
        ;

      return orderBy(allActivities, ['created_at'], ['asc']);
    },

    get notes (): ICaseActivity[] {
      return self.activities?.filter(activity => NOTE_ACTIVITIES.includes(activity.type)) || [];
    },

    // the documents in the "Documents" section of the lienholder page
    get lienholderDocuments (): IDocument[] {
      const filteredDocuments = self.lien_documents.filter((document: IDocument) =>
        !document.added_by_law_firm && !SHARED_LIEN_DOCUMENT_TYPES.includes(document.type || ''));

      return orderBy(filteredDocuments, 'order');
    },

    get lienEndpoint (): string {
      return `beta/${self.baseEndpoint}/liens/`;
    },
  }))
  .views(self => ({
    get unreadNotes (): ICaseActivity[] {
      const currentOrgId = self.users.registryLegalOrganization?.id;
      return self.notes.filter(note => (note.created_by_organization_id !== currentOrgId) && !note.read_at);
    }
  }))
  /* eslint-disable max-statements */
  .actions(self => {
    const fetchActivities = flow(function* (type?: string) {
      const qs = toKey({ lien: self.id, type, page_size: 'off' })
        , response = yield self.client.get(`/${self.baseEndpoint}/activities/${qs}`)
        ;

      self.activities = response;
    });

    const refresh = flow(function* () {
      const lienData = yield self.client.get(self.detailUrl)
        , snapshotData = omit(lienData, [
          'case',
          'lienholder',
        ]);

      setValuesFromData(self, snapshotData);
    });

    const uploadDocument = flow(function* (fileData: any) {
      const response = yield self.uploadDocumentDirect(
        '/lienholder/lien-documents/',
        { ...fileData, lien: self.id },
        'file',
      );
      self.lien_documents.push(response.data);

      return response.data;
    });

    const bulkUploadDocuments = flow(function* (fileList: IBetaDocumentSubmitData[]) {
      yield Promise.all(fileList.map(async (file: IBetaDocumentSubmitData, idx: number) =>
        uploadDocument({ ...file, order: self.lienholderDocuments.length + idx })));
    });

    const updateBalance = flow(function* ({ balance }: { balance?: string }) {
      yield self.client.update(self.detailUrl, { balance });
      yield Promise.all([fetchActivities(), refresh()]);
    });

    const updateDocumentToggle = flow(function* (data) {
      const response = yield self.client.update(self.detailUrl, data);
      self.share_documents = response.data.share_documents;
      self.has_lienholder_finalized_documents = response.data.has_lienholder_finalized_documents;
      fetchActivities();
    });

    const updateReadAt = flow(function* () {
      const url = `/${self.lienEndpoint}${self.id}/update-activity-read-at/`;

      yield self.client.create(url, {});

      const time = new Date().toISOString();
      self.unreadNotes.forEach(note => note.read_at = time);
    });

    const createActivity = flow(function* (data: IActivitySubmitData) {
      const { _case, id } = self
        , response = yield self.client.create(`/${self.baseEndpoint}/activities/`, {
          activity_data: { lien: id, lienholder_name: self.lienholder.name },
          case: _case?.id,
          lien: id,
          ...data,
        });

      if (self.activities) {
        self.activities.unshift(response.data);
      }
      else {
        yield fetchActivities();
      }
    });

    const deleteActivity = flow(function* (activity: ICaseActivity) {
      yield self.client.delete(`/${self.baseEndpoint}/activities/${activity.id}/`);
      destroy(activity);
    });

    const getShareUrl = flow(function* () {
      const { _case } = self
        , lawFirm = _case?.law_firm?.id || ''
        , referralSource = self.users.account?.registry_legal_organization?.name || ''
        , response = yield self.client.create(URLS.SHARING, {
          model: 'registry.registrycase',
          object_id: _case?.id,
          url_parameters: {
            law_firm: lawFirm,
            referral_source: referralSource,
          }
        })
        ;

      return response.data.share_link;
    });

    const copyCaseLink = flow(function* () {
      const shareUrl = yield getShareUrl();

      yield copy(shareUrl);

      message.success('Copied!');
    });

    const removeLienFromPortfolio = flow(function* () {
      yield self.client.delete(self.detailUrl);
    });

    const updateResolutionStatus = flow(function* (data) {
      const { _case } = self
        , updateData = { resolution_status: data.resolution_status }
        , [response] = yield Promise.all([
          self.client.update(self.detailUrl, updateData),
          // FIXME - move logic to lien save function
          self.client.create('/lienholder/activities/', {
            activity_data: updateData,
            case: _case?.id,
            lien: self.id,
            note: data.note,
            type: CASE_ACTIVITY_TYPES.LIEN_RESOLUTION_STATUS_UPDATED,
          }),
        ])
        ;

      self.resolution_status = response.data.resolution_status;
      fetchActivities();
    });

    const updateTreatmentStatus = flow(function* (model: any) {
      const isResettingTreatmentStatus = model.plaintiff_treatment_status === PLAINTIFF_TREATMENT_STATUSES.TREATING
        , newFinalTreatmentDate = isResettingTreatmentStatus ? null : model.final_treatment_date
        // FIXME - move logic to lien save function
        , patchData = { ...model, final_treatment_date: newFinalTreatmentDate }
        ;

      yield Promise.all([
        self.client.update(self.detailUrl, patchData),
        // TODO - use different activity endpoint?
        self.client.create('/lienholder/activities/', {
          activity_data: patchData,
          case: (self as any)._case?.id,
          lien: self.id,
          type: CASE_ACTIVITY_TYPES.PLAINTIFF_TREATMENT_STATUS_UPDATED,
        }),
      ]);
      self.plaintiff_treatment_status = model.plaintiff_treatment_status;
      self.final_treatment_date = newFinalTreatmentDate;

      fetchActivities();
    });

    const updateVerificationStatus = flow(function* (lienData: { note: string, verification_status: string }) {
      yield self.client.update(`/beta/${self.baseEndpoint}/liens/${self.id}/`, {
        note: lienData.note,
        verification_status: lienData.verification_status,
      });
      self.verification_status = lienData.verification_status;

      // TODO: add logic to remove lien from current portfolio if the verification status is REJECTED
    });

    const resolveRejection = flow(function* (data, thread: ICaseThread) {
      // TODO: make sure this work when connected with backend
      const resolvedData = (yield self.client.update(`${self.detailUrl}resolve_rejection/`, data)).data
        , snapshotData = { ...resolvedData, lienholder: resolvedData.lienholder_data }
        ;

      thread.dismiss(AUTO_THREAD_RESPONSE_NOTE.RESOLVED_REJECTION);
      applySnapshot(self, snapshotData);
    });

    // Creates a law firm if necessary, always returns ID of existing or new law firm
    const upsertLawFirm = flow(function* (data) {
      if (data === null) { return null; }

      // Existing law firm
      if (data.id) { return data.id; }

      // Create new law firm
      const lawFirmData = { ...data, type: ORGANIZATION_TYPES.LAW_FIRM }
        , result = yield self.client.create('/lienholder/legal-organizations/', lawFirmData)
        ;

      return result.data.id;
    });

    const updateCaseDetails = flow(function* (data) {
      const { _case } = self;
      yield self.client.update(`/beta/lienholder/portfolio/${_case?.id}/`, data);
      yield Promise.all([fetchActivities(), refresh()]);
    });

    const upsertLawFirmAndCaseDetails = flow(function* (data) {
      const lawFirm = yield upsertLawFirm(data.law_firm);
      yield updateCaseDetails({ ...data, law_firm: lawFirm });
    });

    const updateFinalizeDocuments = flow(function* (hasFinalizedDocuments: boolean) {
      const updateData = { has_lienholder_finalized_documents: hasFinalizedDocuments }
        , response = yield self.client.update(self.detailUrl, updateData)
        , responseData = response.data.has_lienholder_finalized_documents;

      self.has_lienholder_finalized_documents = responseData;

      if (responseData) { fetchActivities(); }
    });

    const sendRequestResponse = flow(function* (data: any, thread: ICaseThread) {
      const submitData = { ...data, request: thread.request }
        , response = yield self.client.create('/lien-document-responses/', submitData)
        ;

      if (response.data.new_status === REQUEST_STATUS_VALUES.LIENHOLDER_UPLOADED_ALL_DOCUMENTS) {
        yield updateFinalizeDocuments(true);
      }
    });

    const updateDocumentSharingStatus = flow(function* (data) {
      const response = yield self.client.update(self.detailUrl, data);

      self.share_documents = response.data.share_documents;
    });

    const fetchExternalSystemIds = flow(function* () {
      const url = mergePathAndQuery(`/lienholder/external-keys/`, { lien: self.id })
        , response = yield self.client.get(url);

      if (self.external_keys === null) {
        self.external_keys = response.results;
      }
      else {
        self.external_keys.replace(response.results);
      }
    });

    const addNewExternalSystem = flow(function* (
      externalSystemIdentifier: string,
      externalSystem: ILegalOrganizationExternalSystem,
    ) {
      const submitData = {
          lien: self.id,
          registry_external_id: externalSystemIdentifier,
          registry_legal_organization_external_system: externalSystem,
        }
        , newExternalSystem = yield self.client.create('/lienholder/external-keys/', submitData);

      if (self.external_keys === null) {
        // istanbul ignore next
        yield fetchExternalSystemIds();
      }
      else {
        self.external_keys.push(newExternalSystem.data);
      }
    });

    const deleteExternalSystemId = flow(function* (externalSystem: IExternalKey) {
      yield self.client.delete(`/lienholder/external-keys/${externalSystem.id}`);

      destroy(externalSystem);
    });

    const addContact = flow(function* (contactId: string) {
      const response = yield self.client.update(`${self.lienEndpoint}${self.id}/add-contact/`, { contact: contactId })
        , contactData = response.data;

      self.lien_contacts.push(contactData);
    });

    const removeContact = flow(function* (contactId: string) {
      yield self.client.update(`${self.lienEndpoint}${self.id}/remove-contact/`, { contact: contactId });
    });

    // called by an escrow agent
    const removeFromCase = flow(function* (removeData: { note: string }) {
      yield updateVerificationStatus({ ...removeData, verification_status: VERIFICATION_STATUSES.REJECTED });

      (getParent(self, 2) as IDetailCase).removeLien(self as IDetailLien);
    });

    const fetchRelatedLienholders = flow(function* () {
      const response = yield self.client.get(`${self.detailUrl}related-lienholders/`);

      if (!isAlive(self)) { return; }

      self.related_lienholders = response.related_lienholders;
    });

    return {
      addContact,
      addNewExternalSystem,
      bulkUploadDocuments,
      copyCaseLink,
      createActivity,
      deleteActivity,
      deleteExternalSystemId,
      fetchActivities,
      fetchExternalSystemIds,
      fetchRelatedLienholders,
      removeContact,
      removeFromCase,
      removeLienFromPortfolio,
      resolveRejection,
      sendRequestResponse,
      updateBalance,
      updateDocumentSharingStatus,
      updateDocumentToggle,
      updateReadAt,
      updateResolutionStatus,
      updateTreatmentStatus,
      updateVerificationStatus,
      upsertLawFirm,
      upsertLawFirmAndCaseDetails,
    };
  })
  ;

export interface IDetailLien extends Instance<typeof DetailLien> {}
