import React, { Component } from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import autoBindMethods from 'class-autobind-decorator';

import SmartBool from '@mighty-justice/smart-bool';
import { Form } from '@mighty-justice/fields-ant';

import { IDetailCase } from '../../../../../models/Case';
import { OPTION_KEYS, POLICY_LIMITS } from '../../../../../utils/constants';
import { Icon } from '../../../../common';

import styles from './PolicyLimits.module.less';

interface IProps {
  _case: IDetailCase;
}

const COL_PROPS = { span: 3 };

@autoBindMethods
@observer
class AddPolicyLimitForm extends Component<IProps> {
  @observable private isFormVisible = new SmartBool();
  @observable private isMedPay = new SmartBool();

  private onChangeCoverage (coverageType: string) {
    this.isMedPay.set(coverageType === POLICY_LIMITS.MEDPAY.value);
  }

  private get fieldSets (): any {
    const { _case: { policy_limits } } = this.props
      , showLabel = !policy_limits.length;

    return [[
      {
        className: styles.select,
        colProps: COL_PROPS,
        editProps: { onChange: this.onChangeCoverage },
        field: 'coverage_type',
        label: 'Type',
        optionType: OPTION_KEYS.POLICY_LIMIT_TYPES,
        sorted: true,
        showLabel,
        type: 'optionSelect',
      },
      {
        className: styles.lower,
        colProps: COL_PROPS,
        field: 'coverage_limit_lower',
        label: 'Min',
        showLabel,
        type: 'money',
      },
      {
        className: styles.upper,
        colProps: COL_PROPS,
        field: 'coverage_limit_upper',
        insertIf: () => this.isMedPay.isFalse,
        label: 'Max',
        showLabel,
        type: 'money',
      },
    ]];
  }

  public render () {
    const { _case } = this.props;

    return (
      <div className={styles.form} id='policy-limit-form'>
        {this.isFormVisible.isFalse ? (
          <a id='add-policy-limit' onClick={this.isFormVisible.setTrue}>
            Add Policy Limit <Icon.Edit />
          </a>
        ) : (
          <Form
            fieldSets={this.fieldSets}
            onSave={_case.addPolicyLimit}
            onCancel={this.isFormVisible.setFalse}
            onSuccess={this.isFormVisible.setFalse}
          />
        )}
      </div>
    );
  }
}

export default AddPolicyLimitForm;
