import React from 'react';
import cx from 'classnames';

import { Button, Icon } from '../../../common';

import styles from './ReplyButton.module.less';

interface IProps {
  selectAlert: () => void;
  title: string;
}

function ReplyButton (props: IProps) {
  return (
    <Button
      className={cx(styles.root, styles.override)}
      id='thread-reply-button'
      onClick={props.selectAlert}
    >
      {props.title}<Icon.Reply className={styles.icon}/>
    </Button>
  );
}

export default ReplyButton;
