import React, { Component } from 'react';
import { observer } from 'mobx-react';
import autoBindMethods from 'class-autobind-decorator';

import { Popover } from 'antd';

import { Icon } from '../common';
import AppHeaderItem from './AppHeaderItem';

import { SUPPORT_EMAIL } from '../../utils/constants';

import styles from './Support.module.less';

@autoBindMethods
@observer
class Support extends Component {
  public render () {
    const supportInfo = (
      <div className={styles.popover}>
        <p>For any questions, contact {SUPPORT_EMAIL}</p>
      </div>
    );

    return (
      <Popover
        arrowPointAtCenter
        content={supportInfo}
        placement='bottomLeft'
        style={{ float: 'right' }}
        trigger='click'
      >
        <AppHeaderItem icon={Icon.QuestionCircleLight} />
      </Popover>
    );
  }
}

export default Support;
