import React from 'react';
import cx from 'classnames';

import { Icon, ModalButton } from '../../common';
import { IDetailCase } from '../../../models/Case';
import UpdateCaseStatusModal from '../UpdateCaseStatusModal';

import styles from './UpdateCaseButton.module.less';

interface IProps {
  _case: IDetailCase;
  style?: object;
}

function UpdateCaseButton (props: IProps) {
  return (
    <ModalButton
      buttonProps={{
        className: cx(styles.root, styles.override),
        id: 'update-case-button',
        style: props.style,
      }}
      passThroughProps={{ _case: props._case }}
      ModalClass={UpdateCaseStatusModal}
    >
      Update Now <Icon.Reply className={styles.icon} />
    </ModalButton>
  );
}

export default UpdateCaseButton;
