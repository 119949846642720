import React from 'react';
import { observer } from 'mobx-react';

import { Table } from '@mighty-justice/fields-ant';

import { IContact } from '../../../../models/Contact';
import { ILegalOrganizationWithContacts } from '../../../../models/Party';

import DeleteContactButton from './DeleteContactButton';
import { CONTACT_FIELDS } from './constants';

interface IProps {
  legalOrganization: ILegalOrganizationWithContacts;
}

const ManageContactsTable = (observer((props: IProps) => {
  const { legalOrganization } = props
    ;

  if (legalOrganization.contacts === null) {
    return null;
  }

  return (
    <Table
      locale={{
        emptyText: <div>No one at {legalOrganization.name} is on this case yet.</div>
      }}
      // ant design's table casts as an object, so we can't use a view
      model={legalOrganization.contacts.map((contact: IContact) => ({ ...contact, canDelete: contact.canDelete }))}
      fieldSets={[[
        ...CONTACT_FIELDS,
        {
          field: 'delete',
          label: '',
          render: (_value: any, _fieldConfig: any, contact: { [key: string]: any }) => (
            <DeleteContactButton
              // this is contact data in an object until fields-ant tables no longer cast MST objects
              contact={contact as IContact}
              onDelete={legalOrganization.removeContact}
            />
          ),
        },
      ]]}
    />
  );
}));

export default ManageContactsTable;
