import React from 'react';
import { observer } from 'mobx-react';

import { Divider, Row, Col } from 'antd';

import { IOption } from '../../../stores/OptionsStore';
import { NOTIFICATION_CATEGORIES } from '../../../utils/constants';
import { Card as AntdCard, Icon } from '../../common';

import NotificationSettingToggle from './NotificationSettingToggle';

const OUTER_COL_SPAN = 3
  , INNER_COL_SPAN = 18
  , LAW_FIRM_NOTIFICATION_STYLES = {
    [NOTIFICATION_CATEGORIES.CASE_STATUS_REQUESTS]: {
      helpText: 'Receive a notification when cases require an update.',
      iconColor: '#eb5e47',
      label: 'Case Status Requests',
      NotificationCategoryIcon: Icon.BriefcaseLight,
    },
    [NOTIFICATION_CATEGORIES.DOCUMENT_REQUESTS]: {
      helpText: 'Receive notifications related to documents uploaded by lienholders.',
      iconColor: '#3482eb',
      label: 'Document Uploads',
      NotificationCategoryIcon: Icon.FilePlusLight,
    },
    [NOTIFICATION_CATEGORIES.NEW_LIEN_NOTIFICATIONS]: {
      helpText: 'Get notified when lienholders list themselves on cases with your firm.',
      iconColor: '#40C982',
      label: 'New Lien Notifications',
      NotificationCategoryIcon: Icon.EnvelopeLight,
    },
    [NOTIFICATION_CATEGORIES.OTHER]: {
      helpText: '',
      iconColor: '#f2ab45',
      label: 'Other Emails',
      NotificationCategoryIcon: Icon.EnvelopeLight,
    },
  }
  ;

interface IProps {
  notificationCategories: IOption[];
}

const LawFirmNotificationSettings = (observer((props: IProps) => (
  <AntdCard title='Email Preferences'>
    {props.notificationCategories.map(notificationCategory => {
      const { label, helpText, NotificationCategoryIcon, iconColor } =
        LAW_FIRM_NOTIFICATION_STYLES[notificationCategory.value];

      return (
        <Row key={notificationCategory.value}>
          <Col span={OUTER_COL_SPAN} style={{ textAlign: 'center' }}>
            <NotificationCategoryIcon size='3x' color={iconColor} />
          </Col>
          <Col span={INNER_COL_SPAN}>
            <h3>{label}</h3>
            <p>{helpText}</p>
          </Col>
          <Col span={OUTER_COL_SPAN}>
            <NotificationSettingToggle notificationItem={notificationCategory} />
          </Col>
          <Divider/>
        </Row>
      );
    })
    }
  </AntdCard>
)));

export default LawFirmNotificationSettings;
