import { formatDate, varToLabel } from '@mighty-justice/utils';

import { IDetailCase } from '../../../../models/Case';
import { IBetaDocument } from '../../../../models/Document';
import { PRICED_DOCUMENT_TYPES } from '../../../../utils/constants';
import UserStore from '../../../../stores/UserStore';

import { DOCUMENTS_MAP } from './constants';

export function formatLastOpenedBy (_case: IDetailCase, document: IBetaDocument) {
  return document.last_opened_at && _case.law_firm?.name
    ? `${_case.law_firm.name} on ${formatDate(document.last_opened_at)}`
    : '';
}

export function formatOptionTypes (options: string[]) {
  return options.map((option: string) => ({ name: varToLabel(option), value: option }));
}

export function formatDocumentType (documentType: string): string {
  return DOCUMENTS_MAP.find((document: { [key: string]: string }) => (
    document.value === documentType
  ))?.name || varToLabel(documentType);
}

export function insertPriceIf (userStore: UserStore, documentType: string): boolean {
  const { isLawFirmUser, registryLegalOrganization } = userStore
    , hasStripeId = !!registryLegalOrganization?.stripe_connected_account_id
    ;

  return !isLawFirmUser && hasStripeId && PRICED_DOCUMENT_TYPES.includes(documentType);
}
