import React from 'react';

import { Badge as AntdBadge } from 'antd';
import { BadgeProps } from 'antd/lib/badge';

type PartialBadgeProps = Pick<BadgeProps, Exclude<keyof BadgeProps, 'color'>>;

export interface IBadgeProps extends PartialBadgeProps {
  children?: React.ReactNode;
}

const defaultProps = {
  dot: false,
  overflowCount: 99,
  showZero: false,
};

const Badge = (props: IBadgeProps) => <AntdBadge {...props} />;

Badge.defaultProps = defaultProps;

export default Badge;
