import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import autoBindMethods from 'class-autobind-decorator';
import { head, pick } from 'lodash';

import { FormModal, IFieldSetPartial } from '@mighty-justice/fields-ant';
import { IModel } from '@mighty-justice/fields-ant/dist/props';

import { RcFile } from 'antd/lib/upload/interface';

import { IBetaDocument } from '../../../../../models/Document';
import { IDetailLien } from '../../../../../models/Lien';
import StoresClass from '../../../../../stores/StoresClass';
import { LAW_FIRM_LIEN_DOCUMENT_TYPES } from '../../../../../utils/constants';
import { IOptions } from '../../../../../interfaces';

import { IBetaDocumentFields, IDocumentModalProps } from '../interfaces';
import { insertPriceIf } from '../utils';

import styles from './DocumentModal.module.less';

interface IInjected extends IDocumentModalProps {
  stores: StoresClass;
}

@inject('stores')
@autoBindMethods
@observer
class DocumentModal extends Component<IDocumentModalProps> {
  private get injected () {
    return this.props as IInjected;
  }

  private get documentsMap (): Array<RcFile|IBetaDocument> {
    const { documents, uploadFiles } = this.props;

    return uploadFiles || documents || [];
  }

  private get shareWithOrganizations (): IOptions[] {
    const { _case } = this.props;

    return _case?.liens?.map((lien: IDetailLien) => ({ name: lien.lienholder.name, value: lien.id })) || [];
  }

  private get fieldSets (): IFieldSetPartial[] {
    const { _case, hideFields, typeOptions } = this.props
      , { users, users: { isLawFirmUser }, options } = this.injected.stores
      , canShareWithLienholder = isLawFirmUser && !!_case && _case.hasLiens
      , document = head(this.documentsMap)
      , documentType = document?.type || ''
      , typeValue = typeOptions ? options.checkOption(typeOptions, documentType) : ''
      ;

    return [[
      {
        field: 'type',
        insertIf: () => !hideFields,
        label: 'Document Type',
        optionType: typeOptions,
        required: true,
        type: 'optionSelect',
        value: typeValue,
      },
      {
        editProps: { autoComplete: 'off' },
        field: 'name',
        label: 'Document Name',
        // if there are multiple documents uploaded, the name will be populated from RcFile
        insertIf: (_model: IModel) => this.documentsMap.length < 2,
        required: true,
      },
      {
        field: 'price',
        insertIf: (model: IModel) => insertPriceIf(users, model.type),
        label: 'Access Fee',
        type: 'money',
      },
      {
        field: 'lien',
        insertIf: (model: IModel) => (
          canShareWithLienholder && LAW_FIRM_LIEN_DOCUMENT_TYPES.includes(model.type)
        ),
        label: 'Share With',
        options: this.shareWithOrganizations,
        required: true,
        type: 'radio',
      },
    ]];
  }

  private mapFieldsForMultipleDocuments (submitData: IModel): IBetaDocumentFields[] {
    return this.documentsMap.map(document => ({
      // name comes from RcFile or BetaDocument name, all other fields optional
      ...pick(document, ['lien', 'name', 'price', 'type']) as IBetaDocumentFields,
      // fields from the form should be passed to all (name does not show for multiple)
      ...submitData,
    }));
  }

  private async onSave (model: IModel) {
    const { uploadDocuments, editDocuments } = this.props
      , submitData = this.mapFieldsForMultipleDocuments(model)
      ;

    if (uploadDocuments) {
      await uploadDocuments(submitData);
    }

    if (editDocuments) {
      await editDocuments(submitData);
    }
  }

  public render () {
    const { isVisible, model, saveText, title } = this.props;

    return (
      <FormModal
        className={styles['document-modal']}
        fieldSets={this.fieldSets}
        isVisible={isVisible}
        model={model}
        onSave={this.onSave}
        saveText={saveText}
        title={title}
      />
    );
  }
}

export default DocumentModal;
