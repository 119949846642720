import { includes } from 'lodash';

import { insertIf } from '@mighty-justice/utils';

import { addressConfig, optionSelectConfig } from '../../../../utils/configCommon';

export const verifiedMatchFieldSets = (isEditing: boolean, legalOrganizationTypes: any[], organizationTypes: any[]) => {
  const typeOptions = organizationTypes.filter(({ value }) => includes(legalOrganizationTypes, value))
    , emailField = { field: 'contact_emails[0]', label: 'Email', type: 'email' }
    ;

  return [[
    { field: 'name', required: true },
    {
      ...optionSelectConfig,
      field: 'type',
      options: typeOptions,
      required: true,
      type: 'optionSelect',
    },
    { ...addressConfig, required: true },
    { field: 'phone_number' },
    ...insertIf(!isEditing, emailField),
    { field: 'website' },
    { field: 'verification_website' },
  ]];
};
