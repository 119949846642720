import React from 'react';

import { Tooltip } from 'antd';

import Icon from '../../../common/Icon';

import styles from './StatusTooltips.module.less';

export const CaseIssueTooltip = (props: { title: React.ReactElement | string, info?: boolean }) => (
  <Tooltip overlayClassName={styles['status-tooltip']} title={props.title} placement='bottom'>
    {props.info ? <Icon.InfoCircle /> : <Icon.QuestionCircleLight />}
  </Tooltip>
);

export const BLACKLISTED_LIENHOLDER_TOOLTIP = (
  <>
    This law firm has opted not to share status updates with your company.{' '}
    Please contact them if you believe this is in error.
  </>
);
