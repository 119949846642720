import React, { Component } from 'react';
import { omit } from 'lodash';

import { Tooltip as AntdTooltip } from 'antd';
import { AbstractTooltipProps } from 'antd/lib/tooltip';

// TODO: In the next ant design upgrade, use TooltipPropsWithTitle not AbstractTooltipProps
interface ITooltipProps extends AbstractTooltipProps {
  show: boolean;
  title: string | React.ReactNode;
}

const TOOLTIP_DELAY = 0.5;

class Tooltip extends Component<ITooltipProps> {
  public render () {
    const { show } = this.props;

    if (!show) { return this.props.children; }

    return (
      <AntdTooltip
        mouseEnterDelay={TOOLTIP_DELAY}
        overlayStyle={{ fontSize: '12px' }}
        {...omit(this.props, ['show'])}
      />
    );
  }
}

export default Tooltip;
