import React from 'react';

import DefaultActivity from './DefaultActivity';
import { IActivityDetailComponentProps } from './interfaces';

const LienDeletedActivity = (props: IActivityDetailComponentProps) => {
  const { activity, activity: { activity_data: { lienholder_name }, note } } = props;

  return (
    <>
      <DefaultActivity activity={activity} />
      {lienholder_name && <p><b>Lienholder:</b> {lienholder_name}</p>}
      {note && <p className='quote'>{note}</p>}
    </>
  );
};

export default LienDeletedActivity;
