import autoBindMethods from 'class-autobind-decorator';

import Client from '../base-modules/client';
import { IPortfolio, Portfolio } from '../models/Portfolio';
import { IStateTreeDependencies } from '../base-modules/state-tree-dependencies';
import { BOOLEAN_STRING } from '../utils/constants';

import CaseFormStore from './CaseFormStore';
import DocumentsStore from './DocumentsStore';
import ExternalSystemsStore from './ExternalSystemsStore';
import InfiniteTableStore from './InfiniteTableStore';
import LinkCopyStore from './LinkCopyStore';
import NotificationsStore from './NotificationsStore';
import OptionsStore from './OptionsStore';
import PointsStore from './PointsStore';
import SettingsStore from './SettingsStore';
import TableStore from './TableStore';
import UiStore from './UiStore';
import UserStore from './UserStore';

@autoBindMethods
class StoresClass {
  // fetchers
  private client: Client;

  // state-tree roots
  public portfolio: IPortfolio;

  // TS stores that have access to client directly
  public caseFormStore: CaseFormStore;
  public externalSystems: ExternalSystemsStore;
  // a temporary hack to make sure the account is set before infiniteTableStore is constructed
  // setInfiniteTableStore will be called in controller.init
  public infiniteTableStore!: InfiniteTableStore;
  public linkCopyItems: LinkCopyStore;
  public notifications: NotificationsStore;
  public options: OptionsStore;
  public pointsStore: PointsStore;
  public settings: SettingsStore;
  public users: UserStore;

  public constructor (client: Client) {
    this.client = client;

    this.users = new UserStore(this.getDependencies);

    this.linkCopyItems = new LinkCopyStore(client);
    this.notifications = new NotificationsStore(client, this);
    this.pointsStore = new PointsStore(client, this);
    this.settings = new SettingsStore(client, this.users);
    this.externalSystems = new ExternalSystemsStore(client, this);
    this.caseFormStore = new CaseFormStore(client);
    this.options = new OptionsStore(client);

    this.portfolio = Portfolio.create({}, this.dependencies);

    this.dedupeTableStore = new TableStore({ client });
  }

  public documentsStore = new DocumentsStore();
  public dedupeTableStore: TableStore;
  public uiStore = new UiStore();

  // Injecting dependencies like this makes them accessible throughout the whole state tree via getEnv(self)
  public get dependencies (): IStateTreeDependencies {
    return {
      client: this.client,
      users: this.users,
    };
  }

  public setInfiniteTableStore () {
    const { isLawFirmUser } = this.users
      , baseQuery: object = isLawFirmUser
        ? { is_closed: BOOLEAN_STRING.FALSE, ordering: 'last_updated_at,id' }
        : { is_resolved: BOOLEAN_STRING.FALSE, ordering: '-last_updated_at,id' }
      , INITIAL_CASE_FILTERS = ['id']
      , INITIAL_LIEN_FILTERS = ['case', 'id']
      , ignoredFilters = isLawFirmUser ? INITIAL_CASE_FILTERS : INITIAL_LIEN_FILTERS
      ;

    this.infiniteTableStore = new InfiniteTableStore({
      baseQuery,
      client: this.client,
      ignoredFilters,
      portfolio: this.portfolio,
    });
  }

  public getDependencies (): IStateTreeDependencies {
    return this.dependencies;
  }
}

export default StoresClass;
