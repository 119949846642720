import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import ResizeObserver from 'rc-resize-observer';
import autoBindMethods from 'class-autobind-decorator';

import SmartBool from '@mighty-justice/smart-bool';

import StoresClass from '../../../stores/StoresClass';
import { IInfiniteScrollProps } from '../../../utils/InfiniteScroll';
import { INavigateProps } from '../../../utils/navigationUtils';
import navigationWrapper from '../../../utils/navigationWrapper';
import { isUUID } from '../../../utils/utils';
import { PageLoader } from '../../common';
import AddCaseFormDrawer from '../add-case-form-drawer/AddCaseFormDrawer';

import CaseLoader from './CaseLoader';
import NoCaseSelectedPlaceholder from './NoCaseSelectedPlaceholder';

interface IProps extends Partial<IInfiniteScrollProps>, INavigateProps {
  isDrawerAddCaseVisible: SmartBool;
  queryParamsSet: SmartBool;
}

interface IInjected extends IProps {
  stores: StoresClass;
}

@inject('stores')
@autoBindMethods
@observer
class PortfolioDetail extends Component<IProps> {
  private get injected () { return this.props as IInjected; }

  public render () {
    const { isDrawerAddCaseVisible, setScrollHeight, renderDetailRef, params: { id }, queryParamsSet } = this.props
      , { infiniteTableStore } = this.injected.stores
      , hasCases = isUUID(id) || infiniteTableStore.firstCase
      ;

    if (queryParamsSet.isFalse) { return <PageLoader />; }

    return (
      <ResizeObserver onResize={setScrollHeight}>
        <div ref={renderDetailRef}>
          {
            hasCases
              ? (
                <CaseLoader
                  caseId={id}
                  isDrawerAddCaseVisible={isDrawerAddCaseVisible}
                  key={id}
                />
              )
              : <NoCaseSelectedPlaceholder isDrawerAddCaseVisible={isDrawerAddCaseVisible} />
          }
          <AddCaseFormDrawer isDrawerAddCaseVisible={isDrawerAddCaseVisible} />
        </div>
      </ResizeObserver>
    );
  }
}

export default navigationWrapper(PortfolioDetail);
