import React, { Component } from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import autoBindMethods from 'class-autobind-decorator';

import SmartBool from '@mighty-justice/smart-bool';

import { Dropdown, Menu } from 'antd';

import { IDetailCase } from '../../../../models/Case';
import { Button, Icon } from '../../../common';

import DocumentRequestModal from '../../portfolio-detail/tab-documents/header/DocumentRequestModal';

import CaseDedupeModal from '../../dedupe/CaseDedupeModal';
import ChangedRepresentationModal from '../change-of-representation/ChangedRepresentationModal';

import styles from './ActionDropdown.module.less';

interface IProps {
  _case: IDetailCase;
}

@autoBindMethods
@observer
class LawFirmActionDropdown extends Component<IProps> {
  @observable private isDocumentRequestModalVisible = new SmartBool();
  @observable private isCaseDedupeModalVisible = new SmartBool();
  @observable private isChangedRepresentationModalVisible = new SmartBool();

  private get menu () {
    return (
      <Menu className={styles.overlay}>
        <Menu.Item
          id='request-documents'
          key='request-documents'
          onClick={this.isDocumentRequestModalVisible.setTrue}
        >
          <Icon.FileAltLight />Request Documents
        </Menu.Item>

        <Menu.Item
          id='case-dedupe'
          key='case-dedupe'
          onClick={this.isCaseDedupeModalVisible.setTrue}
        >
          <Icon.Clone />Mark as Duplicate
        </Menu.Item>

        <Menu.Item
          id='change-representation'
          key='change-representation'
          onClick={this.isChangedRepresentationModalVisible.setTrue}
        >
          <Icon.TimesCircle />Mark as No Longer Representing Plaintiff
        </Menu.Item>
      </Menu>
    );
  }

  public render () {
    const { _case } = this.props;

    return (
      <div>
        <Dropdown
          overlay={this.menu}
          placement='bottomLeft'
          trigger={['click']}
        >
          <Button id='law-firm-case-action'>
            Actions<Icon.CaretDownSolid />
          </Button>
        </Dropdown>
        <DocumentRequestModal
          _case={_case}
          isVisible={this.isDocumentRequestModalVisible}
        />
        <CaseDedupeModal
          _case={_case}
          isVisible={this.isCaseDedupeModalVisible}
          onCancel={this.isCaseDedupeModalVisible.setFalse}
        />
        <ChangedRepresentationModal
          _case={_case}
          isVisible={this.isChangedRepresentationModalVisible}
        />
      </div>
    );
  }
}

export default LawFirmActionDropdown;
