import React from 'react';
import { inject } from 'mobx-react';

import { Row } from 'antd';

import StoresClass from '../../../../stores/StoresClass';
import { IDetailCase } from '../../../../models/Case';
import { IDetailLien } from '../../../../models/Lien';

import SectionTitle from './SectionTitle';
import LawFirmStatusCard from './LawFirmStatusCard';
import LienholderStatusCard from './LienholderStatusCard';

import styles from './StatusTab.module.less';

interface IProps {
  _case: IDetailCase;
  stores?: StoresClass;
}

function OtherUpdatesSection (props: IProps) {
  const { _case } = props
    , isLawFirmUser = props.stores?.users.isLawFirmUser
    , showNoLiensMessage = isLawFirmUser && (_case.liens?.length === 0)
    ;

  return (
    <Row className={styles.others}>
      <SectionTitle title='Updates from others' />
      {!isLawFirmUser &&
        <Row className={styles.otherCard}>
          <LawFirmStatusCard _case={_case} />
        </Row>
      }
      {_case.otherLiens.map((lien: IDetailLien) => (
        <Row key={lien.id} className={styles.otherCard}>
          <LienholderStatusCard lien={lien} />
        </Row>
      ))}
      {showNoLiensMessage && (
        <div>
          You have no liens on this case.
        </div>
      )}
    </Row>
  );
}

const InjectedSection = inject('stores')(OtherUpdatesSection);

export default InjectedSection;
