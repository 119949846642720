import React from 'react';
import cx from 'classnames';
import { capitalize } from 'lodash';

import CreditCardInput from '../stripe-wrappers/CreditCardInput';

import { IPaymentMethod } from './interfaces';

import styles from './DocumentPaymentPage.module.less';

interface IProps {
  disabled: boolean;
  hasCard: boolean;
  paymentMethods: IPaymentMethod[];
}

function CreditCard (props: IProps) {
  const { disabled, hasCard, paymentMethods } = props;

  if (hasCard) {
    return <p>Use {capitalize(paymentMethods[0].brand)} ending in {paymentMethods[0].last4}</p>;
  }

  return (
    <>
      <p className={styles.label}>Card information</p>
      <div className={cx(styles.input, styles.bordered)}>
        <CreditCardInput disabled={disabled}/>
      </div>
    </>
  );
}

export default CreditCard;
