import React from 'react';

import { Col, Row } from 'antd';

const RequestResponseInstructions = () => (
  <Row>
    <Col span={12} offset={6}>
      <p>To complete this request:</p>
      <ol>
        <li>Compile records as requested</li>
        <li>Upload and submit documents below</li>
      </ol>
    </Col>
  </Row>
);

export default RequestResponseInstructions;
