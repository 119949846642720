import React from 'react';
import { Icon } from '../components/common';

type IItemType = 'page' | 'prev' | 'next' | 'jump-prev' | 'jump-next';

const IconClassName = 'ant-pagination-item-link';

export function itemRender (_page: number, type: IItemType, originalElement: React.ReactElement<HTMLElement>) {
  if (type === 'prev') {
    return <a className={IconClassName}><Icon.ChevronLeft /></a>;
  }
  if (type === 'next') {
    return <a className={IconClassName}><Icon.ChevronRight /></a>;
  }
  return originalElement;
}
