import { Component } from 'react';
import { reduce } from 'lodash';

import { CASE_ACTIVITY_TYPES } from '../../../utils/constants';

import AttorneyChangedActivity from './activities/AttorneyChangedActivity';
import AutomatedCaseUpdateRequestActivity from './activities/AutomatedCaseUpdateRequestActivity';
import CaseContactChangedActivity from './activities/CaseContactChangedActivity';
import CaseInfoUpdatedActivity from './activities/CaseInfoUpdatedActivity';
import CaseMergedActivity from './activities/CaseMergedActivity';
import CaseStatusNoChangeActivity from './activities/CaseStatusNoChangeActivity';
import CaseStatusUpdateActivity from './activities/CaseStatusUpdateActivity';
import DocumentRequestActivity from './activities/DocumentRequestActivity';
import DocumentRequestFulfilledActivity from './activities/DocumentRequestFulfilledActivity';
import DocumentUploadActivity from './activities/DocumentUploadActivity';
import LawFirmPostedNoteActivity from './activities/LawFirmPostedNoteActivity';
import LienDeletedActivity from './activities/LienDeletedActivity';
import LienDocumentActivity from './activities/LienDocumentActivity';
import LienResolutionActivity from './activities/LienResolutionActivity';
import LienVerificationStatusUpdateActivity from './activities/LienVerificationStatusUpdateActivity';
import LienholderPostedNoteActivity from './activities/LienholderPostedNoteActivity';
import NewLienActivity from './activities/NewLienActivity';
import PlaintiffTreatmentStatusUpdatedActivity from './activities/PlaintiffTreatmentStatusUpdatedActivity';

interface IComponentMap {
  [key: string]: typeof Component;
}

export const LIEN_DOCUMENT_ACTIVITY_TYPES = [
    CASE_ACTIVITY_TYPES.HIPAA_DELETED,
    CASE_ACTIVITY_TYPES.HIPAA_UPLOADED,
    CASE_ACTIVITY_TYPES.LIEN_LETTER_DELETED,
    CASE_ACTIVITY_TYPES.LIEN_LETTER_UPLOADED,
  ]
  , LIEN_DOCUMENT_ACTIVITY_COMPONENTS = reduce(
    LIEN_DOCUMENT_ACTIVITY_TYPES,
    (accumulated: IComponentMap, type: string) => { accumulated[type] = LienDocumentActivity; return accumulated; },
    {} as IComponentMap,
  )
  ;

export const ACTIVITY_DETAIL_COMPONENTS = {
  [CASE_ACTIVITY_TYPES.ATTORNEY_CHANGED]: AttorneyChangedActivity,
  [CASE_ACTIVITY_TYPES.AUTOMATED_CASE_UPDATE_REQUEST]: AutomatedCaseUpdateRequestActivity,
  [CASE_ACTIVITY_TYPES.BILLABLE_LIEN_REGISTERED]: NewLienActivity,
  [CASE_ACTIVITY_TYPES.CASE_CONTACT_CHANGED]: CaseContactChangedActivity,
  [CASE_ACTIVITY_TYPES.CASE_INFO_UPDATED]: CaseInfoUpdatedActivity,
  [CASE_ACTIVITY_TYPES.CASE_MERGED]: CaseMergedActivity,
  [CASE_ACTIVITY_TYPES.CASE_STATUS_NO_CHANGE]: CaseStatusNoChangeActivity,
  [CASE_ACTIVITY_TYPES.CASE_STATUS_UPDATED]: CaseStatusUpdateActivity,
  [CASE_ACTIVITY_TYPES.DOCUMENT_REQUEST_FULFILLED]: DocumentRequestFulfilledActivity,
  [CASE_ACTIVITY_TYPES.LAW_FIRM_POSTED_NOTE]: LawFirmPostedNoteActivity,
  [CASE_ACTIVITY_TYPES.LAW_FIRM_DOCUMENT_REQUEST]: DocumentRequestActivity,
  [CASE_ACTIVITY_TYPES.LIEN_DELETED]: LienDeletedActivity,
  [CASE_ACTIVITY_TYPES.LIEN_DOCUMENT_UPLOADED]: DocumentUploadActivity,
  [CASE_ACTIVITY_TYPES.LIEN_RESOLUTION_STATUS_UPDATED]: LienResolutionActivity,
  [CASE_ACTIVITY_TYPES.LIEN_VERIFICATION_STATUS_UPDATED]: LienVerificationStatusUpdateActivity,
  [CASE_ACTIVITY_TYPES.LIENHOLDER_POSTED_NOTE]: LienholderPostedNoteActivity,
  [CASE_ACTIVITY_TYPES.PLAINTIFF_TREATMENT_STATUS_UPDATED]: PlaintiffTreatmentStatusUpdatedActivity,
  ...LIEN_DOCUMENT_ACTIVITY_COMPONENTS,
} as IComponentMap;

const ACTIVITY_DRAWER_WIDTH = 460;

export const activityDrawerProps = {
  className: 'drawer-history',
  closable: true,
  width: ACTIVITY_DRAWER_WIDTH,
};
