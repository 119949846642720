import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import { IActivityDetailComponentProps } from './interfaces';
import StoresClass from '../../../../stores/StoresClass';
import { OPTION_KEYS } from '../../../../utils/constants';

interface IInjected extends IActivityDetailComponentProps {
  stores: StoresClass;
}

@inject('stores')
@observer
class LienResolutionActivity extends Component<IActivityDetailComponentProps> {
  private get injected () { return this.props as IInjected; }

  public render () {
    const { activity: { activity_data: { resolution_status }, note } } = this.props
      , { options: { getOptionName } } = this.injected.stores
      , isResolved = !!resolution_status
      , resolutionStatusName = isResolved && getOptionName(OPTION_KEYS.LIEN_RESOLUTION_STATUS, resolution_status || '')
      ;

    return (
      <>
        <h4>Lien {isResolved ? 'resolved' : 're-opened'}</h4>
        {isResolved && <p><b>Reason:</b> {resolutionStatusName}</p>}
        {note && <p><b>Note from lienholder:</b> {note}</p>}
      </>
    );
  }
}

export default LienResolutionActivity;
