import React, { Component } from 'react';
import { observer } from 'mobx-react';
import autoBindMethods from 'class-autobind-decorator';

import { Modal, Row } from 'antd';

import SmartBool from '@mighty-justice/smart-bool';
import { formatDate, getOrDefault } from '@mighty-justice/utils';

import { IAliasCaseData, ICaseActivity } from '../../models/CaseActivity';
import { Card } from '../common';

import styles from './MergedCaseDataModal.module.less';

interface IProps {
  activity: ICaseActivity;
  isVisible: SmartBool;
}

@autoBindMethods
@observer
class MergedCaseDataModal extends Component<IProps> {
  private renderFields (aliasCase: IAliasCaseData) {
    const { plaintiff_name, date_of_loss, date_of_birth, state_of_incident } = aliasCase;

    return (
      <Card bordered={false} className={styles['merged-case-data']}>
        <b>Plaintiff Name</b><br/><span className='dd-privacy-mask'>{plaintiff_name}</span><br/>
        <b>Date of Birth</b><br/><span className='dd-privacy-mask'>{formatDate(date_of_birth)}</span><br/>
        <b>Date of Loss</b><br/>{formatDate(date_of_loss)}<br/>
        <b>State of Incident</b><br/>{getOrDefault(state_of_incident)}<br/>
      </Card>
    );
  }

  public render () {
    const { activity_data: { alias_case_data } } = this.props.activity
      , { isVisible } = this.props
      ;

    if (isVisible.isTrue) {
      return (
        <Modal
          className={styles.root}
          visible={isVisible.isTrue}
          onCancel={isVisible.setFalse}
          title='Merged Case'
          footer={null}
        >
          <Row className={styles['case-data-row']}>
            {alias_case_data.map(this.renderFields)}
          </Row>
        </Modal>
      );
    }

    return null;
  }
}

export default MergedCaseDataModal;
