import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import autoBindMethods from 'class-autobind-decorator';

import { IDetailCase } from '../../../../../models/Case';
import StoresClass from '../../../../../stores/StoresClass';

import LienholderCaseDetail from './LienholderCaseDetail';
import LawFirmCaseDetail from './LawFirmCaseDetail';

interface IProps {
  _case: IDetailCase;
}

interface IInjected extends IProps {
  stores: StoresClass;
}

@inject('stores')
@autoBindMethods
@observer
class CaseDetailWrapper extends Component<IProps> {
  private get injected () { return this.props as IInjected; }

  private get isLawFirmUser (): boolean { return this.injected.stores.users.isLawFirmUser; }

  public render () {
    const { _case } = this.props;

    return this.isLawFirmUser
      ? <LawFirmCaseDetail _case={_case} />
      : <LienholderCaseDetail _case={_case} />
    ;
  }
}

export default CaseDetailWrapper;
