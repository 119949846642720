import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import autoBindMethods from 'class-autobind-decorator';

import { Popconfirm } from 'antd';
import { ColumnProps } from 'antd/lib/table';

import { IDetailCase } from '../../../../../models/Case';
import { IPolicyLimit } from '../../../../../models/PolicyLimit';
import StoresClass from '../../../../../stores/StoresClass';
import { fillInColumnProfile } from '../../../../../utils/columnCommon';
import { EXTERNAL_URL_PROPS } from '../../../../../utils/constants';
import { Icon, LinkButton, Table } from '../../../../common';

import AddPolicyLimitDocumentButton from './AddPolicyLimitDocumentButton';
import { policyLimitsColumns } from './tableColumns';

import styles from './PolicyLimits.module.less';

interface IProps {
  _case: IDetailCase;
}

interface IInjected extends IProps {
  stores: StoresClass;
}

@inject('stores')
@autoBindMethods
@observer
class PolicyLimitsTable extends Component<IProps> {
  private get injected () { return this.props as IInjected; }

  private renderDelete (policyLimit: IPolicyLimit) {
    const { stores: { users: { isLawFirmUser } } } = this.injected;
    const { _case } = this.props;

    if (!isLawFirmUser) return null;

    return (
      <Popconfirm
        cancelText='No'
        okText='Yes'
        onConfirm={_case.deletePolicyLimit.bind(this, policyLimit)}
        title='Are you sure?'
      >
        <LinkButton className='deleteButton'>
          Delete <Icon.TrashAltSolid />
        </LinkButton>
      </Popconfirm>
    );
  }

  private renderPolicyDocument (_value: any, policyLimit: IPolicyLimit) {
    const { _case } = this.props
      , { stores: { users: { isLawFirmUser } } } = this.injected
      , { document } = policyLimit;

    if (!document) {
      if (isLawFirmUser) {
        return <AddPolicyLimitDocumentButton _case={_case} policyLimit={policyLimit} />;
      }

      return <p>No document provided</p>;
    }

    return (
      <a
        {...EXTERNAL_URL_PROPS}
        href={`${document.download_url}&dl=0`}
      >
        {document.name}
      </a>
    );
  }

  private get columns () {
    return (
      [
        ...policyLimitsColumns,
        {
          field: 'document',
          render: this.renderPolicyDocument,
        },
        {
          className: styles['delete-button'],
          field: '',
          render: (_value: any, policyLimit: IPolicyLimit) => this.renderDelete(policyLimit),
          width: 120,
        },
      ].map(fillInColumnProfile) as unknown as Array<ColumnProps<any>>
    );
  }

  public render () {
    const { _case } = this.props;

    return (
      <Table
        className={styles.table}
        columns={this.columns}
        dataSource={_case.policy_limits.slice()}
        rowKey='id'
      />
    );
  }
}

export default PolicyLimitsTable;
