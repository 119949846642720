import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import autoBindMethods from 'class-autobind-decorator';

import { ITabProps } from '../CaseTabConfig';
import StoresClass from '../../../../stores/StoresClass';
import { IStake } from '../../../../models/stake';

import CaseDetailWrapper from './case-details/CaseDetailWrapper';
import ExternalSystemKeys from './external-system-keys/ExternalSystemKeys';
import PolicyLimitsWrapper from './policy-limits/PolicyLimitsWrapper';
import Tags from './Tags';

interface IInjected extends ITabProps {
  stores: StoresClass;
}

@inject('stores')
@autoBindMethods
@observer
class InfoTab extends Component<ITabProps> {
  private get injected () {
    return this.props as IInjected;
  }

  public render () {
    const { _case } = this.props
      , { isLawFirmUser } = this.injected.stores.users
      , stake: IStake | undefined = isLawFirmUser ? _case : _case.userLien
      ;

    return (
      <div>
        <CaseDetailWrapper _case={_case} />
        <PolicyLimitsWrapper _case={_case} />
        {stake && <Tags stake={stake} />}
        {stake && <ExternalSystemKeys key={stake.id} stake={stake} />}
      </div>
    );
  }
}

export default InfoTab;
