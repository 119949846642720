import React, { ReactNode } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

interface WithRecaptchaProps {
  children: (executeRecaptcha: (action: string) => Promise<string>) => ReactNode;
}

const WithRecaptcha: React.FC<WithRecaptchaProps> = ({ children }: WithRecaptchaProps) => {
  const { executeRecaptcha } = useGoogleReCaptcha();

  if (!executeRecaptcha) {
    // Execute recaptcha not yet available, do not render until it is available
    return null;
  }

  return <>{children(executeRecaptcha)}</>;
};

export default WithRecaptcha;
