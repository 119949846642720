import autoBindMethods from 'class-autobind-decorator';
import store from 'store';
import httpStatus from 'http-status-codes';
import { omit } from 'lodash';
import Cookies, { CookieAttributes } from 'js-cookie';

import StoresClass from '../stores/StoresClass';
import { stringOrUndefined } from '../utils/utils';
import { COOKIE_KEYS, GOOGLE_OAUTH_APP_NAME, LOCAL_STORAGE, LOCALHOST, METHODS, TOKEN, URLS } from '../utils/constants';
import { User } from '../models/User';

import {
  getNext,
  getPathFromURL,
  getQueryFromURL,
  IRedirectTo,
  IURL,
  mergePathAndQuery,
} from '../utils/navigationUtils';

import Client, { IHeader } from './client';
import { Environment, isDev } from './environment';

export interface IAuthToken {
  expires: number;
  token: string;
}

function isValidToken (authToken?: IAuthToken): authToken is IAuthToken {
  return !!authToken && !!authToken.token && authToken.expires > +new Date();
}

function shouldRefreshToken (jwt: IAuthToken): boolean {
  const NOW = +new Date();
  return jwt.expires > NOW && jwt.expires < (NOW + TOKEN.REFRESH_MINIMUM);
}

export const AUTH_URL_PARAM_KEYS = ['impersonated_user_id', 'jwt'];

@autoBindMethods
class Auth {
  private environment: Environment;
  private client: Client;
  private stores: StoresClass;

  private token?: string;

  public hasValidSession = false;

  public constructor (environment: Environment, client: Client, stores: StoresClass) {
    this.stores = stores;
    this.environment = environment;
    this.client = client;

    // TODO: Can remove this after session auth has been live for a while
    Cookies.remove(COOKIE_KEYS.REGISTRY_TOKEN);

    client.addHeaderProvider(this.impersonatedUserIdHeaderProvider);
    client.addHeaderProvider(this.registryAccountHeaderProvider);
    client.addHeaderProvider(this.tokenHeaderProvider);
    client.addHeaderProvider(this.csrfTokenHeaderProvider);

    client.transport.interceptors.response.use(
      config => config,
      error => {
        if (error.response && error.response.status === httpStatus.UNAUTHORIZED) {
          this.hasValidSession = false;
        }
        return Promise.reject(error);
      }
    );
  }

  private impersonatedUserIdHeaderProvider (): IHeader {
    const impersonatedUserId = store.get(LOCAL_STORAGE.IMPERSONATED_USER_ID);
    return impersonatedUserId && { 'X-Impersonated-User-Id': impersonatedUserId };
  }

  private registryAccountHeaderProvider (): IHeader {
    const registryAccountId = this.stores.users.account?.id;
    return !!registryAccountId && { 'Registry-Account': registryAccountId };
  }

  private tokenHeaderProvider (): IHeader {
    return (!isValidToken(this.authToken) && !!this.token) && { Authorization: `TOKEN ${this.token}` };
  }

  private csrfTokenHeaderProvider (): IHeader {
    const csrfToken = Cookies.get(this.environment.csrfCookieName) || '';
    return !!csrfToken && { 'X-CSRFToken': csrfToken };
  }

  public async init (url: IURL): Promise<IRedirectTo> {
    const path = getPathFromURL(url)
      , query = getQueryFromURL(url)
      , token = stringOrUndefined(query.token)
      , jwt = stringOrUndefined(query.jwt)
      , impersonatedUserId = stringOrUndefined(query.impersonated_user_id)
      , newUrl = mergePathAndQuery(path, omit(query, AUTH_URL_PARAM_KEYS))
      , next = getNext(url)
      ;

    if (impersonatedUserId) {
      store.set(LOCAL_STORAGE.IMPERSONATED_USER_ID, impersonatedUserId);
    }

    await this.checkSession();

    if (token) {
      this.token = token;
    }

    if (jwt) {
      await this.beginSessionWithToken(jwt);
    }

    if (next) { return next; }
    if (newUrl !== url) { return newUrl; }
    return null;
  }

  public get authBaseUrl () {
    return `https://${this.environment.apiHost}/api/v1/`;
  }

  public get authToken (): IAuthToken | undefined {
    const cookie = Cookies.get(COOKIE_KEYS.REGISTRY_TOKEN);
    return cookie && JSON.parse(cookie);
  }

  public get isAuthenticated (): boolean {
    return !!this.authToken?.token || this.hasValidSession;
  }

  public get isImpersonating (): boolean {
    return !!store.get(LOCAL_STORAGE.IMPERSONATED_USER_ID);
  }

  private get cookieOptions (): CookieAttributes {
    const cookieOptions: CookieAttributes = { path: '/', secure: true }
      , { host } = process.env
      ;
    // Unless developing locally, target the environment's appropriate subdomain
    if (isDev) { cookieOptions.domain = LOCALHOST; }
    else if (host) { cookieOptions.domain = host; }

    return cookieOptions;
  }

  public setAuthentication (tokenProps: { token: string, refreshToken?: string, expiresIn?: number }) {
    const { token, refreshToken, expiresIn } = tokenProps
      // eslint-disable-next-line no-magic-numbers
      , expirationDelta = expiresIn ? expiresIn * 1000 : TOKEN.EXPIRATION
      , expirationDate = +new Date() + expirationDelta
      , registryTokenCookieValue = {
        expires: expirationDate,
        token,
      };

    Cookies.set(
      COOKIE_KEYS.REGISTRY_TOKEN,
      JSON.stringify(registryTokenCookieValue),
      { ...this.cookieOptions, expires: expirationDate }
    );

    if (refreshToken) {
      Cookies.set(COOKIE_KEYS.REGISTRY_REFRESH_TOKEN, refreshToken, { ...this.cookieOptions });
    }
  }

  public async checkAuthentication () {
    if (!isValidToken(this.authToken)) {
      this.logout();
    }

    if (this.authToken && shouldRefreshToken(this.authToken)) {
      await this.refreshAuthentication();
    }
  }

  public async checkSession () {
    await this.client.invoke({
      baseURL: this.authBaseUrl,
      method: METHODS.GET,
      url: '/auth/user/',
    })
      .then(async response => {
        if (response?.status !== httpStatus.OK) {
          this.logout();
          return;
        }

        this.hasValidSession = true;

        const user = response.data;
        const accounts = await this.client.get(URLS.ACCOUNTS_AUTH);
        user.registry_accounts = accounts.results;

        await store.set(LOCAL_STORAGE.USER, user);
        this.stores.users.user = User.create(user, this.stores.dependencies);
      })
      .catch(() => this.logout());
  }

  // istanbul ignore next
  public async refreshAuthentication () {
    if (!this.authToken) { return; }

    try {
      if (this.stores.users.isStaff && !this.isImpersonating) {
        const data = {
            app_name: GOOGLE_OAUTH_APP_NAME,
            grant_type: 'refresh_token',
            refresh_token: Cookies.get(COOKIE_KEYS.REGISTRY_REFRESH_TOKEN),
          }
          , newToken = await this.refreshToken(data, URLS.REFRESH_TOKEN)
          , { access_token, expires_in, refresh_token } = newToken.data;

        this.setAuthentication({ token: access_token, refreshToken: refresh_token, expiresIn: expires_in });

        const user = await this.setUserForGoogleSignIn();
        store.set(LOCAL_STORAGE.USER, user);
      }
      else {
        const data = { token: this.authToken.token }
          , newJwt = await this.refreshToken(data, URLS.REFRESH_JWT)
          , accounts = await this.client.get(URLS.ACCOUNTS_AUTH);

        newJwt.data.user.registry_accounts = accounts.results;

        this.setAuthentication(newJwt.data);
        store.set(LOCAL_STORAGE.USER, newJwt.data.user);
      }
    }
    catch (error) {
      // eslint-disable-next-line no-console
      console.warn('Error refreshing token', error?.response);

      if (error?.response?.status === httpStatus.BAD_REQUEST) {
        // eslint-disable-next-line no-console
        console.warn('JwtAuthenticator :: Could not refresh token, logging out.');
        await this.logout();
      }
    }
  }

  public async beginSessionWithToken (token: string) {
    try {
      await this.logout();

      this.setAuthentication({ token });
      await this.refreshAuthentication();
    }
    catch (err) {
      // eslint-disable-next-line no-console
      console.error('Error beginning session', err);
    }
  }

  public clearUser () {
    this.hasValidSession = false;
    Object.values(LOCAL_STORAGE).forEach(localStorageKey => store.remove(localStorageKey));
    this.stores.users.removeUser();
  }

  public async logout () {
    await this.revokeGoogleToken();

    await this.client.invoke({
      baseURL: this.authBaseUrl,
      method: METHODS.POST,
      url: '/auth/logout/',
    });

    this.clearUser();
  }

  public async userSafetyCheck () {
    if (!this.stores.users.user) {
      const storedUser = store.get(LOCAL_STORAGE.USER);
      try {
        this.stores.users.user = User.create(storedUser, this.stores.dependencies);
      }
      catch {
        await this.refreshAuthentication();
      }
    }
  }

  public async getUser () {
    return (await this.client.invoke({
      baseURL: this.authBaseUrl,
      headers: { Authorization: `Bearer mighty ${this.authToken?.token}` },
      method: METHODS.GET,
      url: URLS.STAFF_LOGIN,
    }));
  }

  public async setUserForGoogleSignIn () {
    const { data: user } = await this.getUser()
      , { data: { results: accounts } } = await this.getGoogleSignInAccountsResponse();

    user.registry_accounts = accounts;
    store.set(LOCAL_STORAGE.USER, user);

    return user;
  }

  public async getGoogleSignInAccountsResponse () {
    return (await this.client.invoke({
      headers: { Authorization: `Bearer mighty ${this.authToken?.token}` },
      method: METHODS.GET,
      url: URLS.ACCOUNTS_AUTH,
    }));
  }

  public async convertGoogleToken (accessToken: string) {
    return await this.client.invoke({
      baseURL: this.authBaseUrl,
      data: {
        app_name: GOOGLE_OAUTH_APP_NAME,
        backend: 'google-oauth2',
        grant_type: 'convert_token',
        token: accessToken,
      },
      method: METHODS.POST,
      url: URLS.CONVERT_TOKEN,
    });
  }

  public async refreshToken (data: any, url: string) {
    return (await this.client.invoke({
      baseURL: this.authBaseUrl,
      data,
      method: METHODS.POST,
      url,
    }));
  }

  public async revokeGoogleToken () {
    if (Cookies.get(COOKIE_KEYS.REGISTRY_REFRESH_TOKEN)) {
      await this.client.invoke({
        baseURL: this.authBaseUrl,
        data: {
          app_name: GOOGLE_OAUTH_APP_NAME,
          token: this.authToken?.token,
        },
        method: METHODS.POST,
        url: URLS.REVOKE_TOKEN,
      });
    }
  }
}

export default Auth;
