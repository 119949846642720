import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import autoBindMethods from 'class-autobind-decorator';
import { observable } from 'mobx';

import { IFieldConfig } from '@mighty-justice/fields-ant';
import { IModel, IValue } from '@mighty-justice/fields-ant/dist/props';

import Client from '../../../base-modules/client';
import { LinkButton } from '../../common';
import { ENDPOINTS } from '../settingsUtils';

interface IProps {
  fieldConfig: IFieldConfig;
  model: IModel;
  render: IFieldConfig['render'];
  value: IValue;
}

interface IInjected extends IProps {
  client: Client;
}

const STATES = {
  PRISTINE: 'PRISTINE',
  SENDING: 'SENDING',
  SENT: 'SENT',
};

@inject('client')
@autoBindMethods
@observer
class InviteWrapper extends Component<IProps> {
  @observable private resendState = STATES.PRISTINE;

  private get injected () {
    return this.props as IInjected;
  }

  private async resendInvite () {
    const { model } = this.props
      , { client } = this.injected
      , endpoint = `${ENDPOINTS.ACCOUNT}${model.id}/resend-invite/`
      ;

    try {
      this.resendState = STATES.SENDING;
      await client.update(endpoint, {});
    }
    finally {
      this.resendState = STATES.SENT;
    }
  }

  public render () {
    const { fieldConfig, model, render, value } = this.props;

    if (model.is_registration_pending) {
      return (
        <div>
          <i>Invite Pending...</i><br/>
          <LinkButton onClick={this.resendInvite} disabled={this.resendState !== STATES.PRISTINE}>
            {this.resendState === STATES.PRISTINE && 'Resend invitation email'}
            {this.resendState === STATES.SENDING && 'Sending...'}
            {this.resendState === STATES.SENT && 'Sent!'}
          </LinkButton>
        </div>
      );
    }

    return render(value, fieldConfig, model);
  }
}

export default InviteWrapper;
