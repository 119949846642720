import React, { ChangeEvent, useState, useCallback, useEffect } from 'react';
import { debounce } from 'lodash';
import cx, { Argument as IClassName } from 'classnames';

import { Input } from 'antd';

import { DEBOUNCE_DELAY } from '../../utils/constants';

interface IProps {
  className?: IClassName;
  id?: string;
  onChange: (value: string) => void;
  placeholder?: string;
  prefix?: React.ReactNode;
  value: string;
}

export default function DebouncedControlledInput (props: IProps) {
  const {
      className,
      id,
      onChange,
      placeholder,
      prefix,
      value,
    } = props
    , [localValue, setLocalValue] = useState('')

    // useCallback here is a fix for the debounce below.
    // Without it, this function gets called for every input, not just the final one
    , commitChange = useCallback(onChange, [])

    // Here we wait for the user to finish typing before we apply this filter
    , debouncedCommitChange = useCallback(
      debounce(commitChange, DEBOUNCE_DELAY),
      [commitChange, localValue],
    )

    // We set the local value as the user types,
    // but we only apply that value after they have finished typing
    , onInputChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
      const newValue = event.target.value;
      setLocalValue(newValue);
      debouncedCommitChange(newValue);
    }, [])

    // This short-circuits the debounced function above
    // and is invoked when users hit enter
    , handleCommit = () => commitChange(localValue)
    ;

  // This gets triggered when the value prop is updated
  // for example on commit or on filter clear
  useEffect(() => {
    setLocalValue(value);
  }, [setLocalValue, value]);

  return (
    <Input
      className={cx(className)}
      id={id}
      onChange={onInputChange}
      onPressEnter={handleCommit}
      placeholder={placeholder || 'Search'}
      prefix={prefix}
      value={localValue}
    />
  );
}

