import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import autoBindMethods from 'class-autobind-decorator';

import { Col, Row } from 'antd';

import SmartBool from '@mighty-justice/smart-bool';

import FacetsDrawer from '../../common-facets/FacetsDrawer';
import FilterList from '../../common-facets/FilterList';
import StoresClass from '../../../stores/StoresClass';
import { IFacetSet } from '../../common-facets/interfaces';
import { RegisterCaseButton } from '../../common';
import { getAdvancedFacetSets } from '../../common-facets/config/advancedFacetSets';

import styles from '../PortfolioPage.module.less';

interface IProps {
  isDrawerAddCaseVisible: SmartBool;
}

interface IInjected extends IProps {
  stores: StoresClass;
}

@inject('stores')
@autoBindMethods
@observer
class PortfolioHeader extends Component<IProps> {
  private isAdvancedFacetsVisible = new SmartBool();

  private get injected () { return this.props as IInjected; }
  private get isLawFirmUser (): boolean { return this.injected.stores.users.isLawFirmUser; }

  private get advancedFacetSets (): IFacetSet[] {
    const { hasTags } = this.injected.stores.users;
    return getAdvancedFacetSets(this.isLawFirmUser, hasTags);
  }

  public render () {
    const { isDrawerAddCaseVisible } = this.props
      , { stores: { infiniteTableStore } } = this.injected;

    return (
      <Row align='middle' className={styles.row} justify='space-between'>
        <Col>
          <Row align='middle' justify='space-between'>
            <FacetsDrawer
              facetSets={this.advancedFacetSets}
              isVisible={this.isAdvancedFacetsVisible}
              tableStore={infiniteTableStore}
            />
            <FilterList
              advancedFacetSets={this.advancedFacetSets}
              isAdvancedFacetsVisible={this.isAdvancedFacetsVisible}
            />
          </Row>
        </Col>
        <Col className='flex-right'>
          <Row gutter={16}>
            <Col>
              <RegisterCaseButton onClick={isDrawerAddCaseVisible.setTrue} />
            </Col>
          </Row>
        </Col>
      </Row>
    );
  }
}

export default PortfolioHeader;
