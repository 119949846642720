import React from 'react';

import { OPTION_KEYS } from '../../utils/constants';

import Icon from './Icon';
import UploadWrapper, { IUploadWrapperProps } from './UploadWrapper';

import styles from './DocumentDropzone.module.less';

function DocumentDropzone (props: IUploadWrapperProps) {
  return (
    // base document types for document requests and on case create
    <UploadWrapper canDrag canClick typeOptions={OPTION_KEYS.LIENHOLDER_BASE_DOCUMENT_TYPES} { ...props }>
      <div className={styles.root}>
        <p>
          <Icon.Folders soft className={styles.icon} />
          <br />
          Upload
        </p>
        <p className='ant-upload-hint'>(Click or drag and drop your documents)</p>
      </div>
    </UploadWrapper>
  );
}

export default DocumentDropzone;
