import React, { Component } from 'react';
import { action, observable } from 'mobx';
import { observer, inject } from 'mobx-react';
import autoBindMethods from 'class-autobind-decorator';

import { Avatar, Dropdown, Menu } from 'antd';

import { getInitials, getNameOrDefault } from '@mighty-justice/utils';
import SmartBool from '@mighty-justice/smart-bool';

import Auth from '../../base-modules/auth';
import navigationWrapper from '../../utils/navigationWrapper';
import StoresClass from '../../stores/StoresClass';
import { getLegalOrganizationDropdownNames } from '../../utils/utils';
import { getNextOrDefault, getPath, getUrl, getUrlForNewPath, INavigateProps } from '../../utils/navigationUtils';
import { Icon } from '../common';
import { IAccount } from '../../models/User';
import { URLS } from '../../utils/constants';
import { Controller } from '../../base-modules/controller';

import AppHeaderItem from './AppHeaderItem';

import styles from './UserDropdown.module.less';

interface IInjected extends INavigateProps {
  auth: Auth;
  controller: Controller;
  stores: StoresClass;
}

const DROPDOWN_USER_PREFIX = 'dropdown-user'
  , DROPDOWN_COLORS = ['#f56a00', '#7265e6', '#ffbf00', '#00a2ae'];

@inject('auth', 'stores', 'controller')
@autoBindMethods
@observer
class UserDropdown extends Component<INavigateProps> {
  @observable private isUserDropdownVisible = new SmartBool();
  @observable private isOrganizationDropdownVisible = new SmartBool();

  private get injected () { return this.props as IInjected; }

  private goToLink (event: any) {
    if (!event.key) { return; }
    this.props.navigate(getUrlForNewPath(event.key));
  }

  private getIcon (isVisible: SmartBool) {
    return (
      isVisible.isTrue
        ? <Icon.ChevronUp className={styles.icon} />
        : <Icon.ChevronDown className={styles.icon} />
    );
  }

  private get menu () {
    const { isLawFirmUser, isStaff } = this.injected.stores.users;

    return (
      <Menu
        className={`${DROPDOWN_USER_PREFIX}-menu`}
        // eslint-disable-next-line no-magic-numbers
        style={{ width: isLawFirmUser ? 190 : 250 }}
      >

        <Menu.Item onClick={this.goToLink} key={URLS.ACCOUNT_PAGE}>
          <Icon.UserCogSolid /> Account Settings
        </Menu.Item>

        <Menu.Item onClick={this.goToLink} key={URLS.ORGANIZATION_PAGE}>
          <Icon.UsersSolid /> Organization
        </Menu.Item>

        {!isLawFirmUser && (
          <Menu.Item onClick={this.goToLink} key={URLS.COMMUNICATION_PREFERENCES_PAGE}>
            <Icon.Envelope /> Communication Preferences
          </Menu.Item>
        )}

        <Menu.Item onClick={this.goToLink} key={URLS.EXTERNAL_SYSTEMS_PAGE}>
          <Icon.PuzzlePiece /> External Systems
        </Menu.Item>

        {isStaff && (
          <Menu.Item onClick={this.goToLink} key={URLS.DEDUPE_PAGE}>
            <Icon.Copy /> Dedupe
          </Menu.Item>
        )}

        {isStaff && (
          <Menu.Item onClick={this.goToLink} key={URLS.MANUAL_DEDUPE_PAGE}>
            <Icon.FileSearch /> Manual Dedupe
          </Menu.Item>
        )}

        <Menu.Item onClick={this.onLogout} key='logout'>
          <Icon.SignOut /> Sign out
        </Menu.Item>
      </Menu>
    );
  }

  @action
  private async onOrganizationChange (account: IAccount) {
    const { navigate } = this.props
      , { controller } = this.injected
      , path = getPath()
      ;

    await controller.changeAccount(account.id);

    if (path !== URLS.PORTFOLIO_PAGE) {
      navigate(getNextOrDefault(getUrl(), URLS.PORTFOLIO_PAGE));
    }

    this.isOrganizationDropdownVisible.setFalse();
  }

  private get organizationMenu () {
    const { stores: { users } } = this.injected
      , accounts = users.accounts || []
      , legalOrgs = accounts.map((account: IAccount) => account.registry_legal_organization)
      , dropdownNames = getLegalOrganizationDropdownNames(legalOrgs)
      ;

    return (
      <Menu id='accounts-dropdown' style={{ maxHeight: '500px', overflowY: 'scroll' }}>
        {accounts.map((account: IAccount, idx: number) => {
          const backgroundColor = DROPDOWN_COLORS[idx % DROPDOWN_COLORS.length]
            , { id, name } = account.registry_legal_organization
            ;

          return (
            <Menu.Item onClick={this.onOrganizationChange.bind(this, account)} key={`registry-account-${idx}`}>
              <Avatar style={{ backgroundColor, verticalAlign: 'middle' }} size='large'>
                {getInitials(name)}
              </Avatar>{' '}
              <span className='lienholder-name'>{dropdownNames[id]}</span>
            </Menu.Item>
          );
        })}
      </Menu>
    );
  }

  private onLogout () {
    const { auth, navigate } = this.injected;
    auth.logout();
    navigate(getUrlForNewPath(URLS.LOGIN));
  }

  public render () {
    const { stores: { users } } = this.injected
      , userName = getNameOrDefault(users.user)
      , selectedAccountName = getNameOrDefault(users.registryLegalOrganization)
      , hasMoreThanOneAccount = users.accounts && users.accounts.length > 1
      ;

    if (!users.isLoggedIn) { return null; }

    return (
      <>
        {hasMoreThanOneAccount && (
          <AppHeaderItem className={styles.root}>
            <Dropdown
              className={DROPDOWN_USER_PREFIX}
              onVisibleChange={this.isOrganizationDropdownVisible.set}
              overlay={this.organizationMenu}
              placement='bottomRight'
              trigger={['click']}
            >
              <a>{selectedAccountName}{this.getIcon(this.isOrganizationDropdownVisible)}</a>
            </Dropdown>
          </AppHeaderItem>
        )}

        <AppHeaderItem className={styles.root}>
          <Dropdown
            className={DROPDOWN_USER_PREFIX}
            onVisibleChange={this.isUserDropdownVisible.set}
            overlay={this.menu}
            placement='bottomRight'
            trigger={['click']}
          >
            <a>{userName}{this.getIcon(this.isUserDropdownVisible)}</a>
          </Dropdown>
        </AppHeaderItem>
      </>
    );
  }
}

export default navigationWrapper(UserDropdown);
