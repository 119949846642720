import React from 'react';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';

import { ButtonProps } from 'antd';

import { IBetaDocument } from '../../../../../models/Document';
import { Button, Icon } from '../../../../common';

import styles from './DocumentsTable.module.less';

interface IProps {
  buttonProps?: ButtonProps;
  document: IBetaDocument;
  isButton?: boolean;
  linkText?: string;
}

const PayToOpen = observer((props: IProps) => {
  const { buttonProps, isButton, document, linkText } = props
    , text = linkText || 'Pay to Open';

  return (
    <Link className={styles['pay-to-open']} to={document.paymentUrl}>
      {isButton
        ? <Button {...buttonProps} children={text} />
        : <>{text}<Icon.USDCircle className={styles.icon} /></>
      }
    </Link>
  );
});

export default PayToOpen;
