import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import autoBindMethods from 'class-autobind-decorator';
import { get, startCase } from 'lodash';

import { notification } from 'antd';

import Client from '../../../base-modules/client';
import StoresClass from '../../../stores/StoresClass';
import { ILegalOrganizationExternalSystem } from '../../../models/LegalOrganizationExternalSystem';
import { ModalButton } from '../../common';
import { APP_NAME } from '../../../utils/constants';

import { AddIntegrationData } from '../interfaces';
import { ADD_INTEGRATION_FIELD_SETS } from '../constants';

interface IProps {
  refresh: () => Promise<void>;
  system: ILegalOrganizationExternalSystem;
}

interface IInjected extends IProps {
  client: Client;
  stores: StoresClass;
}

@inject('client', 'stores')
@autoBindMethods
@observer
class AddIntegrationButton extends Component<IProps> {
  private get injected () { return this.props as IInjected; }

  private get fieldSets () {
    const { system: { external_system: { external_system_id } } } = this.props;

    return get(ADD_INTEGRATION_FIELD_SETS, external_system_id.toUpperCase(), [[]]);
  }

  private async onSave (data: AddIntegrationData) {
    await this.props.system.addIntegration(this.injected.client, data);
  }

  private async onSuccess () {
    const { refresh, system } = this.props;

    notification.success({
      description: `${APP_NAME} is now connected to your ${system.external_system.external_system_id} account. ` +
        'Note that it may take up to 24 hours for new cases to appear',
      duration: 10,
      message: 'Success!',
    });
    await refresh();
  }

  public render () {
    const { system } = this.props;

    return (
      <ModalButton
        buttonProps={{ size: 'small', type: 'primary', className: 'btn-add-api-key' }}
        buttonText={`Configure ${startCase(system.external_system.external_system_id)}`}
        passThroughProps={{
          fieldSets: this.fieldSets,
          onSave: this.onSave,
          onSuccess: this.onSuccess,
          title: 'Add API Key',
        }}
      />
    );
  }
}

export default AddIntegrationButton;
