import React, { Component } from 'react';

import { IActivityDetailComponentProps } from './interfaces';

class DocumentRequestActivity extends Component<IActivityDetailComponentProps> {
  public render () {
    return (
      <>
        <h4>Documents requested</h4>
        <p className='quote'>{this.props.activity.note}</p>
      </>
    );
  }
}

export default DocumentRequestActivity;
