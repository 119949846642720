import React from 'react';

import { Avatar } from 'antd';
import { AvatarProps } from 'antd/lib/avatar';

import { getInitials } from '../../utils/utils';

const AVATAR_SIZE = 50;

export const MightyAvatar = (props: AvatarProps) => (
  <Avatar
    size={AVATAR_SIZE}
    src='/assets/images/logo.svg'
    {...props}
  />
);

export const InitialsAvatar = (props: AvatarProps & { name?: string }) => {
  const { name, ...passThroughProps } = props;

  if (!name) { return null; }

  return (
    <Avatar size={AVATAR_SIZE}{...passThroughProps}>
      {getInitials(name)}
    </Avatar>
  );
};
