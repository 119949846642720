import React, { Component } from 'react';
import { observer } from 'mobx-react';
import autoBindMethods from 'class-autobind-decorator';
import { get } from 'lodash';

import { Row } from 'antd';

import { formatDate } from '@mighty-justice/utils';

import { ICaseThread } from '../../../../models/CaseThread';
import { CASE_THREAD_TYPES } from '../../../../utils/constants';
import { EmptyBlock, Icon, Section } from '../../../common';

import DocumentsHiddenThread from './threads/DocumentsHiddenThread';
import DocumentRequestThread from './threads/DocumentRequestThread';
import LawFirmChangedThread from './threads/LawFirmChangedThread';
import LienRejectedByLawFirmThread from './threads/LienRejectedByLawFirmThread';

import { InboxListProps } from './interfaces';
import { getActiveThreads } from './utils';

interface IProps {
  passThroughProps: InboxListProps;
  threads: ICaseThread[];
}

const INBOX_ITEM_COMPONENTS = {
  [CASE_THREAD_TYPES.DOCUMENTS_HIDDEN]: DocumentsHiddenThread,
  [CASE_THREAD_TYPES.DOCUMENT_REQUEST]: DocumentRequestThread,
  [CASE_THREAD_TYPES.LAW_FIRM_CHANGED]: LawFirmChangedThread,
  [CASE_THREAD_TYPES.LIEN_REJECTION]: LienRejectedByLawFirmThread,
};

@autoBindMethods
@observer
class InboxList extends Component<IProps> {
  private getComponent (type: string) {
    return get(INBOX_ITEM_COMPONENTS, type) as typeof Component;
  }

  public render () {
    const { passThroughProps: { _case, lien, selectThread, setTitle }, threads } = this.props
      , activeThreads = getActiveThreads(threads)
      ;

    // To show empty state with resolved document requests for v1
    // (eventually will show resolved documents in inboxlist)
    if (!activeThreads.length) {
      return <EmptyBlock description='No new items outstanding' icon={Icon.InboxLight} />;
    }

    return (
      <Row>
        {threads.map((thread: ICaseThread) => {
          if (!thread.type) { return null; }

          const ThreadComponent = this.getComponent(thread.type)
            , lastUpdatedAtDate = thread.latest_message ?
              thread.latest_message.created_at :
              thread.created_at
            ;

          return (
            <Section key={thread.id}>
              <ThreadComponent
                _case={_case}
                lien={lien}
                passThroughProps={{
                  topRightContent: `${formatDate(lastUpdatedAtDate)}`,
                  overrideClassName: { title: 'thread-title' },
                }}
                selectThread={selectThread}
                setTitle={setTitle}
                thread={thread}
              />
            </Section>
          );
        })}
      </Row>
    );
  }
}

export default InboxList;
