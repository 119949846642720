import React from 'react';

import { Col, Row } from 'antd';

import { Card } from '@mighty-justice/fields-ant';
import { getNameOrDefault } from '@mighty-justice/utils';

import { ILegacyBaseCase } from '../../models/Legacy';
import { OPTION_KEYS } from '../../utils/constants';

import styles from './styles/DocumentRequestResponsePage.module.less';

interface IProps {
   _case: Partial<ILegacyBaseCase>;
}

const FIELD_SETS = [[
  { className: 'dd-privacy-mask', field: 'plaintiff', render: getNameOrDefault },
  { className: 'dd-privacy-mask', field: 'plaintiff.birthdate', label: 'Date of Birth' },
  { field: 'date_of_loss' },
  { field: 'state_of_incident', optionType: OPTION_KEYS.US_STATES, type: 'optionSelect' },
]];

const RequestResponseCaseInfo = (props: IProps) => (
  <Row>
    <Col span={12} offset={6}>
      <Card className={styles.caseInfo} fieldSets={FIELD_SETS} model={props._case} />
    </Col>
  </Row>
);

export default RequestResponseCaseInfo;
