import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import StoresClass from '../../../../stores/StoresClass';
import { OPTION_KEYS } from '../../../../utils/constants';

import { IActivityDetailComponentProps } from './interfaces';

interface IInjected extends IActivityDetailComponentProps {
  stores: StoresClass;
}

@inject('stores')
@observer
class CaseStatusUpdateActivity extends Component<IActivityDetailComponentProps> {
  private get injected () { return this.props as IInjected; }

  public render () {
    const { activity: { note, activity_data: { old_status, new_status, appearance_date } } } = this.props
      , { options: { getOptionName } } = this.injected.stores
      , oldStatusText = getOptionName(OPTION_KEYS.CASE_STATUS, old_status || '')
      , newStatusText = getOptionName(OPTION_KEYS.CASE_STATUS, new_status || '')
      ;

    return (
      <>
        <h4>Case status changed</h4>
        {oldStatusText && <p><b>From:</b> {oldStatusText}</p>}
        <p><b>To:</b> {newStatusText}</p>
        {appearance_date && <p><b>Appearance date:</b> {appearance_date}</p>}
        {note && <p className='quote'>{note}</p>}
      </>
    );
  }
}

export default CaseStatusUpdateActivity;
