import React from 'react';
import { Row } from 'antd';

import { formatDate, getNameOrDefault, getOrDefault, mapBooleanToText } from '@mighty-justice/utils';

import { IColumnProfile } from '../../../../utils/columnCommon';
import { IDedupeCase } from '../../../../models/DedupeMatch';
import { renderDocumentDownload } from '../../tableColumns';
import { renderExternalKeys } from '../../../../utils/utils';

const renderCaseInformation = (_value: any, record: IDedupeCase) => {
  const {
    plaintiff,
    plaintiff: { birthdate },
    date_of_loss,
    type,
    state_of_incident,
    created_by_organization,
    created_at,
    related_to_compulsion_firm,
    external_keys,
  } = record
  ;

  return (
    <div>
      <span className='dd-privacy-mask'>{getNameOrDefault(plaintiff)}</span><br/>
      <b>Date of Birth:</b> <span className='dd-privacy-mask'>{formatDate(birthdate)}</span><br/>
      <b>Date of Loss:</b> {formatDate(date_of_loss)}<br/>
      <b>Case Type:</b> {getOrDefault(type)}<br/>
      <b>State of Incident:</b> {getOrDefault(state_of_incident)}<br/>
      <br/>
      <b>Date Created:</b> {formatDate(created_at)}<br/>
      <b>Created By:</b> {getOrDefault(created_by_organization)}<br/>
      <b>Related to compulsion firm:</b> {mapBooleanToText(related_to_compulsion_firm)}<br />
      <br/>
      <b>External Keys:</b> {renderExternalKeys(external_keys)}
    </div>
  );
};

const renderCaseLienholders = (_value: Array<null | string>) => {
  const lienholders = _value.filter(value => !!value) as string[];

  if (!lienholders.length) {
    return null;
  }

  return (
    <div>
      {lienholders.map(lienholder => <Row key={lienholder}>{lienholder}</Row>)}
    </div>
  );
};

export const caseDedupeMatchColumnsPartial: Array<IColumnProfile<any>> = [
  {
    field: 'Case Information',
    minWidth: 60,
    render: renderCaseInformation,
  },
  {
    align: 'center',
    field: 'lienholders',
    minWidth: 60,
    render: renderCaseLienholders,
  },
  {
    align: 'center',
    field: 'Documents',
    render: renderDocumentDownload,
    width: 250,
  },
];
