import React, { Component } from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import autoBindMethods from 'class-autobind-decorator';

import App from './App';
import { Controller, fetchController } from './base-modules/controller';
import { PageLoader } from './components/common';
import { warnOutdatedBrowsers } from './utils/warnOutdatedBrowsers';
import ImpersonatePage from './components/page-impersonate/ImpersonatePage';
import { printPerformance } from './utils/utils';
import { getUrl, INavigateProps, IRedirectTo } from './utils/navigationUtils';
import navigationWrapper from './utils/navigationWrapper';

interface IProps {
  controller?: Controller;
}

interface IWrappedProps extends INavigateProps, IProps {}

@autoBindMethods
@observer
class AppInit extends Component<IWrappedProps> {
  @observable private controller?: Controller;
  @observable public isReady = false;

  public constructor (props: IWrappedProps) {
    super(props);
    this.controller = props.controller;
    this.initController();
  }

  public async componentDidMount () {
    warnOutdatedBrowsers();
  }

  public async initController () {
    const { navigate } = this.props;

    // Controller is passed as a prop in tests
    if (!this.controller) {
      this.controller = await fetchController();
    }

    printPerformance('Begin Controller Init');
    const redirectTo: IRedirectTo = await this.controller.init(getUrl());
    printPerformance('Finish Controller Init');

    if (redirectTo) {
      navigate(redirectTo);
    }

    this.isReady = true;
  }

  public render () {
    if (this.isReady && this.controller) {
      printPerformance('Render App');
      return <App controller={this.controller} />;
    }

    if (this.controller && this.controller.auth.isImpersonating) {
      return <ImpersonatePage />;
    }

    printPerformance('Render Full Page Loader');
    return <PageLoader />;
  }
}

export default navigationWrapper(AppInit);
