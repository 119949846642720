/* eslint-disable max-lines-per-function */
import React from 'react';

import LoggedOutLandingPage from '../LoggedOutLandingPage';
import { Button, Spacer, TermsOfServiceLink } from '../common';
import { URLS } from '../../utils/constants';
import { Controller } from '../../base-modules/controller';
import { INavigateProps } from '../../utils/navigationUtils';
import navigationWrapper from '../../utils/navigationWrapper';

interface IInjected extends INavigateProps {
  controller: Controller;
}

function LoginPage (props: IInjected) {
  const handleSignInClick = () => {
      const { controller: { environment } } = props;
      window.location.href = `${environment.authUrl}/?redirectUri=${environment.registryUrl}`;
    }
    , navigateToRegisterPage = () => {
      const { navigate } = props;

      navigate(URLS.REGISTER_NOW);
    }
    ;

  return (
    <LoggedOutLandingPage title='Sign in'>
      <Button type='primary' block onClick={handleSignInClick}>Sign In</Button>
      <Spacer />
      <p className='landing-register-text'>
        Need a login?{' '}
        <a className='landing-link-small' data-id={'register-here-link'} onClick={navigateToRegisterPage}>
          Register here
        </a>
      </p>
      <p className='landing-terms-of-service'>
        <TermsOfServiceLink />
      </p>
    </LoggedOutLandingPage>
  );
}

export default navigationWrapper(LoginPage);
