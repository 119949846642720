import React, { Component } from 'react';
import { observer } from 'mobx-react';
import autoBindMethods from 'class-autobind-decorator';

import { Avatar } from 'antd';

import styles from './CompareCardSeparator.module.less';

@autoBindMethods
@observer
class CompareCardSeparator extends Component<{}> {
  public render () {
    const verticalLine = <div className={styles.verticalLine}>|</div>;

    return (
      <div className={styles.root}>
        {verticalLine}
        <Avatar
          className={styles.orCircle}
          size='large'
        >
          OR
        </Avatar>
        {verticalLine}
      </div>
    );
  }
}

export default CompareCardSeparator;
