import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import autoBindMethods from 'class-autobind-decorator';

import SmartBool from '@mighty-justice/smart-bool';
import { FormModal } from '@mighty-justice/fields-ant';

import Client from '../../../../base-modules/client';
import StoresClass from '../../../../stores/StoresClass';
import { IVerifiedOrganization } from '../../../../models/VerifiedMatchPortfolio';
import { IVerifyOrganizationMatch } from '../../../../models/DedupeMatch';
import { OPTION_KEYS } from '../../../../utils/constants';
import { fixDoubleModalScrollBug } from '../../../../utils/utils';

import { verifiedMatchFieldSets } from './fieldSets';

interface IProps {
  isEditing: boolean;
  isVisible: SmartBool;
  legalOrganizationTypes: string[];
  match: IVerifiedOrganization | IVerifyOrganizationMatch;
  onSave: (match: any, data: any, isEditing: boolean) => Promise<void>;
  title: string;
}

interface IInjected extends IProps {
  client: Client;
  getOptions: (key: string) => any[];
  stores: StoresClass;
}

@inject('client', 'getOptions', 'stores')
@autoBindMethods
@observer
class VerifiedMatchModal extends Component<IProps> {
  private get injected () {
    return this.props as IInjected;
  }

  private async onSave (model: any) {
    const { isEditing, match, onSave } = this.props
      , { client } = this.injected
      , submitData = { ...model, last_verified_at: new Date() };

    const result = isEditing
      ? await client.update(`/internal/legal-organizations/${submitData.id}/`, submitData)
      : await client.create('/internal/legal-organizations/', submitData);

    onSave(match, result.data, isEditing);
    fixDoubleModalScrollBug();
  }

  public render () {
    const { isEditing, isVisible, legalOrganizationTypes, match, title } = this.props
      , organizationTypes = this.injected.getOptions(OPTION_KEYS.LEGAL_ORGANIZATION_TYPE)
      ;

    return (
      <FormModal
        fieldSets={verifiedMatchFieldSets(isEditing, legalOrganizationTypes, organizationTypes)}
        isVisible={isVisible}
        model={match}
        onSave={this.onSave}
        title={title}
      />
    );
  }
}

export default VerifiedMatchModal;
