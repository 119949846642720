import React, { Component } from 'react';
import { observable, toJS } from 'mobx';
import { observer } from 'mobx-react';
import autoBindMethods from 'class-autobind-decorator';

import SmartBool from '@mighty-justice/smart-bool';

import { getAssociatedOrganizations } from '../../../../utils/utils';
import { IDetailCase } from '../../../../models/Case';
import { TreeSelectButton } from '../../../common';
import { ITreeCategories } from '../../../common/TreeSelectButton';
import { IQuery, queryValueToStringArray } from '../../../../utils/navigationUtils';

import { THREAD_TYPES } from './constants';

interface IProps {
  _case: IDetailCase;
  filterApplied: SmartBool;
}

@autoBindMethods
@observer
class InboxFilterDropdown extends Component<IProps> {
  @observable private isLoading = new SmartBool();
  @observable private filters: IQuery = {};

  private get numberOfFilters (): number {
    return Object.values(toJS(this.filters))
      .map(value => queryValueToStringArray(value).length)
      .reduce((prev, curr) => prev + curr, 0)
    ;
  }

  private get treeCategories (): ITreeCategories {
    const { _case } = this.props
      , organizations = getAssociatedOrganizations(_case);

    return {
      type: {
        name: 'Type:',
        options: THREAD_TYPES,
      },
      created_by_organization_id: {
        name: 'From:',
        options: organizations.map(({ id, name }) => ({ name, value: id })),
      },
    };
  }

  private async onChange (query: IQuery) {
    const { _case, filterApplied } = this.props;

    this.filters = query;
    filterApplied.set(!!this.numberOfFilters);

    await this.isLoading.until(_case.fetchThreads(query));
  }

  public render () {
    const filterLabel = this.numberOfFilters ? `(${this.numberOfFilters} applied)` : '';

    return (
      <TreeSelectButton
        isLoading={this.isLoading.isTrue}
        onChange={this.onChange}
        treeCategories={this.treeCategories}
      >
        Filter {filterLabel}
      </TreeSelectButton>
    );
  }
}

export default InboxFilterDropdown;
