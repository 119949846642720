import React, { Component } from 'react';
import { observer } from 'mobx-react';
import autoBindMethods from 'class-autobind-decorator';

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

interface IProps {
  Form: React.ComponentType<any>;
  passThroughProps: object;
  publicKey: string;
}

@autoBindMethods
@observer
class StripeForm extends Component<IProps> {
  // necessary to override the account stripe is working on behalf of
  // e.g. when confirming payment intents, but does not apply to most requests
  private getStripeAccountInstance (accountId: string) {
    const { publicKey } = this.props;

    /* eslint-disable-next-line new-cap */
    return window.Stripe && window.Stripe(publicKey, { stripeAccount: accountId });
  }

  public render () {
    const { Form, passThroughProps, publicKey } = this.props;

    return (
      <div>
        <div className='Checkout'>
          <Elements stripe={loadStripe(publicKey)}>
            <Form {...passThroughProps} getStripeAccountInstance={this.getStripeAccountInstance} />
          </Elements>
        </div>
      </div>
    );
  }
}

export default StripeForm;
