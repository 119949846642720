import React, { Component } from 'react';
import { observer } from 'mobx-react';
import autoBindMethods from 'class-autobind-decorator';

import SmartBool from '@mighty-justice/smart-bool';
import { FormModal } from '@mighty-justice/fields-ant';

import { IDetailCase } from '../../models/Case';
import { ANT_FULL_COL_WIDTH } from '../../utils/constants';

import { getCaseStatusFieldSets } from './add-case-form-drawer/addCaseDrawerConfig';

interface IProps {
  _case: IDetailCase;
  isVisible: SmartBool;
}

@autoBindMethods
@observer
class UpdateCaseStatusModal extends Component<IProps> {
  private async onSave (model: any) {
    const { _case, isVisible } = this.props;

    if (!_case) { return; }

    await _case.updateCaseStatus(model);
    isVisible.setFalse();
  }

  public render () {
    const { _case, isVisible } = this.props
      , fieldSetProps = { colProps: { span: ANT_FULL_COL_WIDTH } }
      ;

    return (
      <FormModal
        fieldSets={getCaseStatusFieldSets(fieldSetProps)}
        isVisible={isVisible}
        model={_case}
        onSave={this.onSave}
        title='Update case status'
      />
    );
  }
}

export default UpdateCaseStatusModal;
