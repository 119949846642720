import React from 'react';
import { observer } from 'mobx-react';

import { ILegalOrganizationWithContacts } from '../../../../models/Party';
import { renderAddressAndWebsite } from '../../../../utils/utils';

import styles from './ContactsTab.module.less';

interface IProps {
  legalOrganization: ILegalOrganizationWithContacts;
}

const ContactSubtitle = observer((props: IProps) => {
  const { legalOrganization } = props
    , { address, website } = legalOrganization
    ;

  if (!address && !website) {
    return null;
  }

  return (
    <div id='contact-subtitle' className={styles.subtitle}>
      {renderAddressAndWebsite(legalOrganization)}
    </div>
  );
});

export default ContactSubtitle;
