import React, { Component, CSSProperties } from 'react';
import autoBindMethods from 'class-autobind-decorator';
import { inject, observer } from 'mobx-react';

import { IListCase } from '../../../models/Case';
import StoresClass from '../../../stores/StoresClass';
import { safeArrayAccess } from '../../../utils/utils';

import EmptyListScrollItem from './EmptyListScrollItem';
import PortfolioListScrollCase from './PortfolioListScrollCase';

interface IProps {
  index: number;
  style: CSSProperties;
}

interface IInjected extends IProps {
  stores: StoresClass;
}

@inject('stores')
@autoBindMethods
@observer
class PortfolioListScrollItem extends Component<IProps> {
  private get injected () { return this.props as IInjected; }

  public render () {
    const { stores: { portfolio, infiniteTableStore } } = this.injected
      , { index, style } = this.props;

    if (infiniteTableStore.isLoading) {
      return <EmptyListScrollItem style={style} index={index} />;
    }

    const id: string | null = safeArrayAccess(infiniteTableStore.data, index)
      , _case: IListCase | undefined = id ? portfolio.listCases.get(id) : undefined
      ;

    if (_case) {
      return <PortfolioListScrollCase _case={_case} index={index} style={style} />;
    }

    return <EmptyListScrollItem style={style} index={index} />;
  }
}

export default PortfolioListScrollItem;
