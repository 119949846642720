import React, { Component } from 'react';
import { Navigate } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import autoBindMethods from 'class-autobind-decorator';
import cx from 'classnames';

import { Col, Row } from 'antd';

import StoresClass from '../../stores/StoresClass';

import BulkUploadCaseForm from './BulkUploadCaseForm';
import LienholderRegistrationLayout from './LienholderRegistrationLayout';
import ManualCaseForm from './ManualCaseForm';
import { REGISTRATION_URLS } from './utils/constants';

import styles from './CaseCreationPage.module.less';

interface IInjected {
  stores: StoresClass;
}

function CaseCreationPageHeader () {
  return (
    <Row className={styles.title}>
      <Col>
        <h1>Choose a way to add cases</h1>
        <p>
          Get started by adding all the personal injury cases your company has with
          <br/>
          the law firm(s) that you work with. Once cases are added, you’ll be automatically notified
          <br/>
          when important changes occur and also be able to collaborate in many other ways.
        </p>
      </Col>
    </Row>
  );
}

@inject('stores')
@autoBindMethods
@observer
class CaseCreationPage extends Component<{}> {
  private get injected () { return this.props as IInjected; }

  public constructor (props: {}) {
    super(props);

    this.injected.stores.caseFormStore.addCase();
  }

  public render () {
    const { caseFormStore } = this.injected.stores;

    if (!caseFormStore.compulsionEventId) {
      return <Navigate to={REGISTRATION_URLS.ACCOUNT_CREATION} />;
    }

    return (
      <LienholderRegistrationLayout>
        <div className={cx(styles.root, styles.override)}>
          <Col>
            <CaseCreationPageHeader/>
            <BulkUploadCaseForm />
            <ManualCaseForm />
          </Col>
        </div>
      </LienholderRegistrationLayout>
    );
  }
}

export default CaseCreationPage;
