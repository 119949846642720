import { formatDollars } from '@mighty-justice/utils';

import { OPTION_KEYS } from '../../../../../utils/constants';

export const policyLimitsColumns = (
  [
    {
      field: 'coverage_type',
      optionType: OPTION_KEYS.POLICY_LIMIT_TYPES,
      title: 'Type',
      type: 'optionSelect',
      width: 160,
    },
    {
      field: 'coverage_limit_lower',
      title: 'Min',
      render: formatDollars,
    },
    {
      field: 'coverage_limit_upper',
      title: 'Max',
      render: formatDollars,
    },
  ]
);
