import { applySnapshot, flow, Instance, types } from 'mobx-state-tree';

import { IVerifiedOrganizationData, VerifiedOrganizationData } from './DedupeMatch';
import { stateTreeDependencyGetters } from '../base-modules/state-tree-dependencies';

export const VerifiedOrganization = types.compose(
  VerifiedOrganizationData,
  types.model({}).actions(self => {
    const update = function (data: IVerifiedOrganizationData) {
      applySnapshot(self, data);
    };

    return { update };
  }),
);

export const VerifiedMatchPortfolio = types
  .model({
    organizations: types.optional(types.array(VerifiedOrganization), []),
  })
  .views(stateTreeDependencyGetters)
  .actions(self => {
    const hydrate = flow(function* (queryString: string = '') {
      const matchData = yield self.client.get(`/internal/legal-organizations/${queryString}`);
      applySnapshot(self.organizations, matchData.results);

      return matchData;
    });

    const addMatch = function (match: IVerifiedOrganization) {
      self.organizations.unshift(match);
    };

    return { addMatch, hydrate };
  })
  ;

export interface IVerifiedOrganization extends Instance<typeof VerifiedOrganization> {}
export interface IVerifiedMatchPortfolio extends Instance<typeof VerifiedMatchPortfolio> {}
