import React, { Component } from 'react';
import Helmet from 'react-helmet';
import { observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import autoBindMethods from 'class-autobind-decorator';
import { omit, pick, isEmpty } from 'lodash';
import cx from 'classnames';

import { Layout } from 'antd';

import SmartBool from '@mighty-justice/smart-bool';

import AppContent from '../AppContent';
import StoresClass from '../../stores/StoresClass';
import { getQuery, IQuery } from '../../utils/navigationUtils';
import { getFilterValues } from '../../utils/utils';
import { IFacetSet } from '../common-facets/interfaces';
import { getAdvancedFacetSets } from '../common-facets/config/advancedFacetSets';

import PortfolioContentWrapper from './PortfolioContentWrapper';
import PortfolioHeader from './portfolio-header/PortfolioHeader';

import styles from './PortfolioPage.module.less';

interface IInjected {
  stores: StoresClass;
}

@inject('stores')
@autoBindMethods
@observer
class PortfolioPage extends Component {
  @observable private isDrawerAddCaseVisible = new SmartBool();
  @observable private queryParamsSet = new SmartBool();

  private get injected () { return this.props as IInjected; }

  public constructor (props: {}) {
    super(props);

    this.setTableStoreQueryParams();
  }

  private async setTableStoreQueryParams () {
    if (!isEmpty(getQuery())) {
      const { infiniteTableStore, users: { hasTags, isLawFirmUser } } = this.injected.stores
        , facetSets: IFacetSet[] = getAdvancedFacetSets(isLawFirmUser, hasTags)
        , query: IQuery = getQuery()
        , filterStrings: IQuery = omit(query, infiniteTableStore.ignoredFilters)
        , filters: IQuery = getFilterValues(facetSets, filterStrings)
        , initialFilters: IQuery = pick(query, infiniteTableStore.ignoredFilters)
        ;

      await infiniteTableStore.setQueryParams(filters, initialFilters);
    }
    this.queryParamsSet.setTrue();
  }

  public render () {
    const className = cx('page page-floating page-style-card page-facet-table', styles.root);

    return (
      <AppContent>
        <Layout className={className}>
          <Helmet title='Portfolio'/>
          <Layout.Header className={styles.header}>
            <PortfolioHeader isDrawerAddCaseVisible={this.isDrawerAddCaseVisible} />
          </Layout.Header>
          <Layout.Content className={styles.content}>
            <PortfolioContentWrapper
              isDrawerAddCaseVisible={this.isDrawerAddCaseVisible}
              queryParamsSet={this.queryParamsSet}
            />
          </Layout.Content>
        </Layout>
      </AppContent>
    );
  }
}

export default PortfolioPage;
