import autoBindMethods from 'class-autobind-decorator';
import { observable } from 'mobx';

import SmartBool from '@mighty-justice/smart-bool';

@autoBindMethods
class UiStore {
  @observable public addNewContactsModalVisible = new SmartBool();
  @observable public addLienholderInfoModalVisible = new SmartBool();
  @observable public isTransactionalEmailPromptVisible = new SmartBool();
}

export default UiStore;
