import React, { Component } from 'react';
import { observer } from 'mobx-react';
import autoBindMethods from 'class-autobind-decorator';

import SmartBool from '@mighty-justice/smart-bool';

import { Col, Row } from 'antd';

import { Button, LinkButton } from '../../common';

import styles from './PaymentFooter.module.less';

interface IProps {
  isLoading: SmartBool;
  onSubmit: (event: any) => void;
}

@autoBindMethods
@observer
class PaymentFooter extends Component<IProps> {
  public render () {
    const { isLoading, onSubmit } = this.props;

    return (
      <div className={styles.root}>
        <Row align='middle' justify='start'>
          <Col span={6} offset={2}>
            <div className={styles.back}>
              <LinkButton className={styles.link} href='/upgrade/choose'>BACK</LinkButton>
            </div>
          </Col>
          <Col span={4} offset={12} pull={2}>
            <Button size='large' type='primary' loading={isLoading.isTrue} onClick={onSubmit}>
              Upgrade
            </Button>
          </Col>
        </Row>
      </div>
    );
  }
}

export default PaymentFooter;
