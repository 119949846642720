import React, { Component } from 'react';

import { Card } from '../common';

import CardPaymentForm from './CardPaymentForm';
import { IPaymentMethod } from './interfaces';

import styles from './DocumentPaymentPage.module.less';
import { inject } from 'mobx-react';
import { Environment } from '../../base-modules/environment';

interface IProps {
  documentIds: string[];
  onSuccess: () => void;
  paymentMethods: IPaymentMethod[];
  total: number;
}

interface IInjected extends IProps {
  environment: Environment;
}

@inject('environment')
class CardPaymentSection extends Component<IProps> {
  private get injected () { return this.props as IInjected; }

  public render () {
    const { documentIds, onSuccess, paymentMethods, total } = this.props
      , { environment } = this.injected;

    return (
      <Card className={styles.payment} title='Pay with card' bordered={false}>
        <CardPaymentForm
          documentIds={documentIds}
          onSuccess={onSuccess}
          paymentMethods={paymentMethods}
          stripePublicKey={environment.stripePublicKey}
          total={total}
        />
      </Card>
    );
  }
}

export default CardPaymentSection;
