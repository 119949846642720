import React, { Component, HTMLProps } from 'react';
import { inject, observer } from 'mobx-react';
import autoBindMethods from 'class-autobind-decorator';
import cx from 'classnames';
import { omit } from 'lodash';
import { Link } from 'react-router-dom';

import { ButtonProps } from 'antd/lib/button/button';

import StoresClass from '../../stores/StoresClass';

import Button from './Button';
import Icon, { IIcon, PartialIconProps } from './Icon';

import styles from './styles/Buttons.module.less';

export const ANT_BTN_PREFIX_CLS = 'ant-btn';

//
// Link Button
// Desc: Text with underline buttons.
// Note: The button sizing will need refactor and research for proper typing
//
interface ILinkButtonProps extends HTMLProps<HTMLAnchorElement> {
  className?: any;
  block?: boolean;
  buttonSize?: 'small' | 'large';
  hasIcon?: boolean;
}

const buttonSizeClassNames = { small: 'sm', large: 'lg' };

export const LinkButton = (props: ILinkButtonProps) => {
  const className = cx(
    `${ANT_BTN_PREFIX_CLS}-link`,
    props.buttonSize ? `${ANT_BTN_PREFIX_CLS}-link-${buttonSizeClassNames[props.buttonSize]}` : null,
    { [`${ANT_BTN_PREFIX_CLS}-link-icon`]: props.hasIcon },
    { [`${ANT_BTN_PREFIX_CLS}-block`]: props.block },
    props.type ? `link-${props.type}` : null,
    props.className,
  );

  return <a {...omit(props, ['buttonSize', 'hasIcon'])} className={className} />;
};

//
// Icon Button
// Desc: Buttons with no border and text, an inlined icon.
//

interface IIconButtonProps extends HTMLProps<HTMLAnchorElement> {
  className?: any;
  icon: IIcon;
  iconProps?: PartialIconProps;
}

export const IconButton = (props: IIconButtonProps) => {
  const { className: classNameProp, icon: IconClass, iconProps, ...anchorProps } = props
    , className = cx(`${ANT_BTN_PREFIX_CLS}-icon`, classNameProp);
  return <a {...anchorProps} className={className}><IconClass {...iconProps} /></a>;
};

//
// Specific Buttons
// Desc: any shared button that has specific text or icon.
//
@inject('stores')
@autoBindMethods
@observer
export class RegisterCaseButton extends Component<ButtonProps> {
  private get injected () {
    return this.props as ButtonProps & { stores: StoresClass };
  }

  private get disabled () {
    const { account } = this.injected.stores.users;
    if (!account) return false;
    if (!account.registry_legal_organization) return false;
    return account.registry_legal_organization.can_create_registry_cases === false;
  }

  public render () {
    const { stores: { users: { isLawFirmUser } } } = this.injected;

    return (
      <>
        <Button
          className='btn-register-case'
          disabled={this.disabled}
          size='large'
          type='primary'
          {...this.props}
        >
          <Icon.Plus/> Register New Case
        </Button>
        {!isLawFirmUser && (
          <p className={styles['bulk-upload-cases']}>
            or <Link to='/bulk-case-upload'>upload cases in bulk</Link>
          </p>
        )
        }
      </>
    );
  }
}

export const FiltersButton = (props: ButtonProps & { count?: number }) => (
  <Button type='primary' style={{ marginLeft: 15, marginRight: 4, }} {...props}>
    <Icon.Bars /> Filter Cases {props.count ? `(${props.count})` : ''}
  </Button>
);

export const ClearFiltersButton = (props: { onClick: () => void }) => (
  <a {...props} className='link link-danger' style={{ marginLeft: 15 }}>
    Clear filters
  </a>
);
