import React from 'react';

import { OPTION_KEYS } from '../../../../utils/constants';
import { optionSelectConfig } from '../../../../utils/configCommon';

export const openLienModalChildrenBefore = (
  <p>
    By clicking submit, you are re-opening this lien and the law firm will see the lien as open in their portfolio.
  </p>
);

export const openLienModalFieldSets = [
  [
    {
      field: 'resolution_status',
      nullify: true,
      type: 'hidden',
      value: '',
    },
    { field: 'note' },
  ]
];

export const resolveLienModalChildrenBefore = (
  <p>
    The following will be viewable by the law firm on file.
  </p>
);

export const resolveLienModalFieldSets = [
  [
    {
      ...optionSelectConfig,
      field: 'resolution_status',
      label: 'Reason',
      optionType: OPTION_KEYS.LIEN_RESOLUTION_STATUS,
      required: true,
      type: 'optionSelect',
    },
    { field: 'note' },
  ]
];

export const removeCaseModalChildrenBefore = (
  <p>
    By clicking "Submit", you will also remove this lien from the law firm's portal.
  </p>
);
