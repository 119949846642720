import React, { Component } from 'react';
import { observer } from 'mobx-react';
import autoBindMethods from 'class-autobind-decorator';

import { ITableStoreAPI } from '../../stores/ITableStoreAPI';

import FacetDropdown from './FacetsDropdown';
import { IFacetSet } from './interfaces';

interface IProps {
  facetDropdownList: IFacetSet[];
  tableStore: ITableStoreAPI;
}

@autoBindMethods
@observer
class FacetsDropdownContainer extends Component<IProps> {
  public render () {
    const { facetDropdownList, tableStore } = this.props;

    return (
      <div className='facet-button' style={{ display: 'flex', flexWrap: 'wrap' }}>
        {facetDropdownList.map((facetSet: IFacetSet) => (
          <FacetDropdown
            facetSet={facetSet}
            key={facetSet.label}
            tableStore={tableStore}
          />
        ))}
      </div>
    );
  }
}

export default FacetsDropdownContainer;
