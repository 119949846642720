import React, { Component } from 'react';
import { observer } from 'mobx-react';
import autoBindMethods from 'class-autobind-decorator';
import { isBoolean, kebabCase } from 'lodash';
import cx from 'classnames';

import SmartBool from '@mighty-justice/smart-bool';
import { varToLabel } from '@mighty-justice/utils';

import { Card } from '../../common';
import { IFacetProps } from '../interfaces';

import FacetHeader from './FacetHeader';

interface IProps extends IFacetProps {
  hasValue: boolean;
  isCollapsed?: boolean;
}

import styles from './Facet.module.less';

@autoBindMethods
@observer
class Facet extends Component<IProps> {
  private isUserCollapsed: SmartBool;

  public static defaultProps = {
    collapsible: true,
  };

  public constructor (props: IProps) {
    super(props);

    const defaultIsUserCollapsed = isBoolean(props.isCollapsed) ? props.isCollapsed : true;
    this.isUserCollapsed = new SmartBool(defaultIsUserCollapsed);
  }

  private get isCollapsed (): boolean {
    const { collapsible, hasValue } = this.props;
    if (!collapsible || hasValue) { return false; }
    return this.isUserCollapsed.isTrue;
  }

  private get title () {
    const { label, field } = this.props.facet;
    return (label || varToLabel(field));
  }

  private renderBody () {
    if (this.isCollapsed) { return null; }
    return <div>{this.props.children}</div>;
  }

  private renderTopRight () {
    const { collapsible, facet: { field }, hasValue, tableStore } = this.props
      , toggleProps = collapsible ? { toggleCollapsed: this.isUserCollapsed.toggle } : {};

    return (
      <FacetHeader
        field={field}
        hasValue={hasValue}
        isCollapsed={this.isCollapsed}
        tableStore={tableStore}
        {...toggleProps}
      />
    );
  }

  public render () {
    const { button, collapsible, facet: { field } } = this.props
      , classNames = cx(
        styles.root,
        'facet-item',
        { 'facet-collapsible': collapsible },
      )
      , bodyStyle = this.isCollapsed ? { display: 'none' } : {}
      , id = `facet-${kebabCase(field)}`
      ;

    if (button) {
      return (
        <div
          className={classNames}
          id={id}
        >
          {this.renderBody()}
        </div>
      );
    }

    return (
      <Card
        bodyStyle={bodyStyle}
        className={classNames}
        extra={this.renderTopRight()}
        id={id}
        title={this.title}
      >
        {this.renderBody()}
      </Card>
    );
  }
}
export default Facet;
