import { flow, getType, Instance, types } from 'mobx-state-tree';
import { isEqual, orderBy } from 'lodash';

import { IDocumentSubmitData } from '../interfaces';
import { REQUEST_CLOSE_STATUS, SHARED_LIEN_DOCUMENT_TYPES } from '../utils/constants';
import { stateTreeDependencyGetters } from '../base-modules/state-tree-dependencies';

import { CaseActivity } from './CaseActivity';
import { CaseContact } from './Contact';
import { CaseDocument, Document, IDocument, ModelWithDirectDocumentUpload } from './Document';
import { CaseExternalKey } from './CaseExternalKey';
import { CaseTag } from './Tag';
import { ILegalOrganizationExternalSystem } from './LegalOrganizationExternalSystem';
import { LegalOrganization, Person } from './Party';
import { LienDocumentRequest } from './Request';
import { LienExternalKey } from './LienExternalKey';
import { PolicyLimit } from './PolicyLimit';

type IOrderedDocumentData = IDocumentSubmitData & { order?: number };

export const LegacyBaseCase = types
  .model({
    activities: types.optional(types.array(CaseActivity), []),
    appearance_date: types.maybeNull(types.string),
    case_contacts: types.optional(types.array(CaseContact), []),
    created_at: types.maybeNull(types.string),
    created_by_organization: types.maybeNull(types.string),
    date_expected_to_receive_check: types.maybeNull(types.string),
    date_of_loss: types.maybeNull(types.string),
    documents: types.optional(types.array(CaseDocument), []),
    has_single_lienholder: types.maybeNull(types.boolean),
    id: types.identifier,
    last_updated_at: types.maybeNull(types.string),
    law_firm: types.maybeNull(LegalOrganization),
    lawsuit_filed_date: types.maybeNull(types.string),
    plaintiff: Person,
    policy_limits: types.optional(types.array(PolicyLimit), []),
    state_of_incident: types.maybeNull(types.string),
    status: types.maybeNull(types.string),
    tags: types.array(CaseTag),
    type: types.maybeNull(types.string),
    unfulfilledDocumentRequests: types.optional(types.array(LienDocumentRequest), []),
  });

export const LegacyBaseLien = types
  .compose(
    ModelWithDirectDocumentUpload,
    types.model('BaseLien', {
      activities: types.optional(types.array(CaseActivity), []),
      balance: types.maybeNull(types.number),
      balance_last_updated_at: types.maybeNull(types.string),
      balance_last_updated_by_organization_name: types.maybeNull(types.string),
      // currently only used in DocumentPaymentPage for liens unassociated with cases
      case_id: '',
      case_last_updated_at: types.maybeNull(types.string),
      created_at: '',
      download_all_documents_url: types.maybeNull(types.string),
      external_keys: types.array(LienExternalKey),
      final_treatment_date: types.maybeNull(types.string),
      has_attorney_changed: types.maybeNull(types.boolean),
      has_lienholder_finalized_documents: types.maybeNull(types.boolean),
      id: types.identifier,
      is_lienholder_blacklisted: types.maybeNull(types.boolean),
      lien_documents: types.array(Document),
      lien_documents_count: types.optional(types.number, 0),
      lienholder: LegalOrganization,
      plaintiff_treatment_status: types.maybeNull(types.string),
      related_lienholders: types.array(types.string),
      resolution_status: types.maybeNull(types.string),
      share_documents: true,
      unfulfilledDocumentRequests: types.optional(types.array(LienDocumentRequest), []),
      verification_status: types.maybeNull(types.string),
      week_of_next_update_request: types.maybeNull(types.string),
    })
  )
  .views(stateTreeDependencyGetters)
  .views(self => ({
    get isLawFirm (): boolean {
      return isEqual(getType(self), LegacyBaseLien);
    },
  }))
  .views(self => ({
    get baseUrl (): string {
      return self.isLawFirm ? 'escrow-agent' : 'lienholder';
    },
  }))
  .actions(self => {
    // TODO: Used by post-redesign LienholderCaseFormDrawer
    const getDocumentFormData = (fileData: IOrderedDocumentData) => {
      const form = new FormData();

      form.append('lien', self.id);
      form.append('file', fileData.file);
      form.append('name', fileData.name);

      if (fileData.price) {
        form.append('price', fileData.price);
      }

      if (fileData.type) {
        form.append('type', fileData.type);
      }

      if (fileData.order) {
        form.append('order', fileData.order.toString());
      }

      return form;
    };

    // TODO: Used by post-redesign LienholderCaseFormDrawer
    const uploadDocument = flow(function* (fileData: IOrderedDocumentData) {
      const response = yield self.uploadDocumentDirect(
        `/${self.baseUrl}/lien-documents/`,
        { ...fileData, lien: self.id },
        'file',
      );
      self.lien_documents.push(response.data);

      return response.data;
    });

    // TODO: Used by post-redesign LienholderCaseFormDrawer
    const uploadDocumentWithToken = flow(function* (fileData: IOrderedDocumentData) {
      yield self.client.create('/logged-out-documents/', getDocumentFormData(fileData));
    });

    return {
      uploadDocument,
      uploadDocumentWithToken,
    };
  })
  .views(self => ({
    // TODO: Used by post-redesign LienholderCaseFormDrawer
    get lienholderDocuments (): IDocument[] {
      const filteredDocuments = self.lien_documents.filter((document: IDocument) =>
        !document.added_by_law_firm && !SHARED_LIEN_DOCUMENT_TYPES.includes(document.type || ''));

      return orderBy(filteredDocuments, 'order');
    },
  }))
  ;

const ObjectWithLegacyCase = types.model({
  case: types.late(() => LegacyBaseCase),
  created_by_organization: types.maybeNull(types.string),
});

export const ObjectWithLegacyLiens = types.model({
  liens: types.optional(types.array(types.late(() => LegacyBaseLien)), []),
});

export const LegacyCase = types
  .compose(
    LegacyBaseCase,
    ObjectWithLegacyLiens,
    types.model({
      external_keys: types.array(CaseExternalKey),
    })
  );

export const LegacyLien = types
  .compose(
    LegacyBaseLien,
    ObjectWithLegacyCase,
  )
  .actions(self => {
    // TODO: Used by post-redesign LienholderCaseFormDrawer
    const bulkUploadDocuments = flow(
      function* (fileList: IDocumentSubmitData[], unauthenticated: boolean = false) {
        yield Promise.all(fileList.map(async (file: IDocumentSubmitData, idx: number) => {
          if (unauthenticated) {
            return self.uploadDocumentWithToken(file);
          }
          return self.uploadDocument({ ...file, order: self.lienholderDocuments.length + idx });
        }));
      },
    );

    // TODO: Used post-redesign by DocumentRequestResponsePage
    const fulfillLoggedOutDocumentRequest = flow(function* (requestId) {
      const submitData = { new_status: REQUEST_CLOSE_STATUS.DOCUMENT_UPLOADED, request: requestId };

      yield self.client.create('/logged-out-document-responses/', submitData);
    });

    return {
      bulkUploadDocuments,
      fulfillLoggedOutDocumentRequest,
    };
  })
  .actions(self => {
    // TODO: Used by post-redesign LienholderCaseFormDrawer
    const addNewExternalSystem = flow(function* (
      externalSystemIdentifier: string,
      externalSystem: ILegalOrganizationExternalSystem,
    ) {
      const submitData = {
          lien: self.id,
          registry_external_id: externalSystemIdentifier,
          registry_legal_organization_external_system: externalSystem,
        }
        , newExternalSystem = yield self.client.create('/lienholder/external-keys/', submitData);

      self.external_keys.push(newExternalSystem.data);
    });

    return {
      addNewExternalSystem,
    };
  });

export interface ILegacyBaseCase extends Instance<typeof LegacyBaseCase> {}
export interface ILegacyCase extends Instance<typeof LegacyCase> {}

export interface ILegacyBaseLien extends Instance<typeof LegacyBaseLien> {}
export interface ILegacyLien extends Instance<typeof LegacyLien> {}
