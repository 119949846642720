import { Instance, types } from 'mobx-state-tree';

import { LegalOrganizationExternalSystem } from './LegalOrganizationExternalSystem';

export const ExternalKey = types
  .model('ExternalKey', {
    id: types.identifier,
    registry_external_id: types.string,
    registry_legal_organization_external_system: LegalOrganizationExternalSystem,
  })
  ;

export interface IExternalKey extends Instance<typeof ExternalKey> {}
