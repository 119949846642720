import { Params } from 'react-router';
import { head, isArray, isString, omit } from 'lodash';
import qs from 'qs';

import { LOCALHOST, URLS } from './constants';
import { isStringArray, isUUID, mapValues } from './utils';

/*
Navigation Glossary
url: /settings/organization?token=xyz
path: /settings/organization
params: { tab: 'organization' } (set by /settings/:tab in the router)
queryString: ?token=xyz
query: { 'token': 'xyz' }
*/

export type IURL = string;
export type IPath = string;
export type IQueryString = string;
export type IQueryValue = undefined | string | string[];
export type IQuery = { [key: string]: IQueryValue };

// Redirect functions should return either URL to redirect to, or null if no redirect is recommended
export type IRedirectTo = IURL | null;

export interface INavigateProps {
  navigate: (url: IURL) => void;
  params: Params;
  searchParams: [URLSearchParams, (query: IQuery) => void];
}

// This is just used as an argument to instantiate the URL constructor
// as a utility in two functions below, and does change the results
const URL_CONSTRUCTOR_BASE_URL = `http://${LOCALHOST}`;


// Pure functional utilities
// ========================================

export function cleanPathString (path?: IPath | null): IPath {
  const pathString = isString(path) ? path : '';
  return `${pathString.startsWith('/') ? '' : '/'}${pathString}`;
}

export function cleanQueryString (queryString?: IQueryString | null): IQueryString {
  if (queryString === null || queryString === undefined || ['?', ''].includes(queryString)) { return ''; }
  return queryString.startsWith('?') ? queryString : `?${queryString}`;
}

export function mergePathAndQueryString (path?: IPath | null, queryString?: IQueryString | null): IURL {
  return `${cleanPathString(path)}${cleanQueryString(queryString)}`;
}

export function queryToQueryString (query?: IQuery | null): IQueryString {
  return qs.stringify(query, { addQueryPrefix: true, arrayFormat: 'comma' });
}

export function removeUndefinedQueryValues (query: IQuery): IQuery {
  return Object.entries(query)
    .reduce((objOut: IQuery, [key, value]) => {
      if (value !== undefined) {
        objOut[key] = value;
      }
      return objOut;
    }, {});
}

export function unknownToQueryValue (value: unknown): IQueryValue {
  if (isString(value)) { return value; }
  if (isStringArray(value)) { return value; }
  return undefined;
}

export function unknownObjectToQuery (query: { [key: string]: unknown }): IQuery {
  return removeUndefinedQueryValues(mapValues(query, unknownToQueryValue));
}

export function queryValueToStringArray (valueArg: IQueryValue): string[] {
  if (isArray(valueArg)) { return valueArg.filter(isString); }
  if (isString(valueArg)) { return valueArg.split(','); }
  return [];
}

export function queryValueToString (valueArg: IQueryValue): string {
  return head(queryValueToStringArray(valueArg)) || '';
}

export function queryStringToQuery (query?: IQueryString | null): IQuery {
  const parsedQs = qs.parse(query || '', { ignoreQueryPrefix: true, comma: true });
  return mapValues(unknownObjectToQuery(parsedQs), value => (
    // This is due to an open bug in qs where comma arrays are not symmetric:
    // https://github.com/ljharb/qs/issues/345
    ((isString(value) && value.includes(',')) ? value.split(',') : value)
  ));
}

export function mergePathAndQuery (path?: IPath | null, query?: IQuery | null): IURL {
  return mergePathAndQueryString(path, queryToQueryString(query));
}

export function getQueryStringFromURL (url?: IURL | null): IQueryString {
  return (new URL(url || '', URL_CONSTRUCTOR_BASE_URL)).search;
}

export function getQueryFromURL (url?: IURL | null): IQuery {
  return queryStringToQuery(getQueryStringFromURL(url));
}

export function getPathFromURL (url?: IURL | null): IPath {
  return (new URL(url || '', URL_CONSTRUCTOR_BASE_URL)).pathname;
}

export function replaceUrlQuery (url?: IURL | null, query?: IQuery | null): IURL {
  return `${getPathFromURL(url)}${queryToQueryString(query)}`;
}

export function replaceUrlPath (url?: IURL | null, path?: IPath | null): IURL {
  return `${cleanPathString(path)}${getQueryStringFromURL(url)}`;
}


// Getter functions for part of the URL
// ========================================

export function getPath (): IPath {
  return global.location.pathname;
}

export function getKeyFromPath (): string | null {
  return getPath().split('/').find((s: string) => isUUID(s)) || null;
}

export function getQueryString (): IQueryString {
  return global.location.search;
}

export function getQuery (): IQuery {
  return queryStringToQuery(getQueryString());
}

export function getUrl (): IURL {
  return mergePathAndQueryString(getPath(), getQueryString());
}


// Methods to get transformations of current URL
// ========================================

export function getUrlForNewPath (path: IPath): IURL {
  return mergePathAndQueryString(path, getQueryString());
}

export function getUrlForNewQuery (query: IQuery): IURL {
  return mergePathAndQuery(getPath(), query);
}


// Special case utility functions
// ========================================

export function getLoginURL (currentURL: IURL): IURL {
  const query = ['', '/', URLS.LOGIN].includes(currentURL) ? {} : { next: currentURL };
  return mergePathAndQuery(URLS.LOGIN, query);
}

export function getNext (currentURL: IURL): IRedirectTo {
  const query = getQueryFromURL(currentURL)
    , next = query?.next;

  if (!next || !isString(next)) {
    return null;
  }

  const nextQuery = getQueryFromURL(next)
    , nextPath = getPathFromURL(next)
    , newQuery = {
      ...omit(query, 'next', 'username'),
      ...nextQuery,
    }
    ;

  return mergePathAndQuery(nextPath, newQuery);
}

export function getNextOrDefault (currentURL: IURL, defaultURL: IURL): IURL {
  const nextRedirect = getNext(currentURL);
  return nextRedirect ? nextRedirect : defaultURL;
}
