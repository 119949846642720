import React, { Component, ComponentType } from 'react';
import { observer } from 'mobx-react';
import autoBindMethods from 'class-autobind-decorator';

import { ITableStoreAPI } from '../../stores/ITableStoreAPI';
import { INavigateProps, IQuery } from '../../utils/navigationUtils';
import navigationWrapper from '../../utils/navigationWrapper';

import { IFacet, IFacetProps } from './interfaces';
import { COMPONENTS } from './consts';
import { getFacetClassName, getFacetKey } from './facetUtils';

interface IHooksProps {
  button?: boolean;
  collapsible?: boolean;
  facet: IFacet;
  tableStore: ITableStoreAPI;
}

interface IProps extends INavigateProps, IHooksProps {}

@autoBindMethods
@observer
class FacetInputWrapper extends Component<IProps> {
  private onFacetChange (queryChange: IQuery) {
    const { searchParams: [, setQuery], tableStore } = this.props;

    setQuery({ ...tableStore.unignoredFilterQuery, ...queryChange });

    tableStore.onFilterChange(queryChange);
    tableStore.fetchTableData();
  }

  public render () {
    const { button, collapsible, facet, tableStore } = this.props
      , FacetComponent: ComponentType<IFacetProps> = COMPONENTS[facet.type]
      ;

    return (
      <FacetComponent
        button={button}
        className={getFacetClassName(facet)}
        collapsible={collapsible}
        facet={facet}
        key={getFacetKey(facet)}
        onFacetChange={this.onFacetChange}
        tableStore={tableStore}
        {...facet.editComponentProps}
      />
    );
  }
}

export default navigationWrapper(FacetInputWrapper);
