import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { observable } from 'mobx';
import autoBindMethods from 'class-autobind-decorator';

import SmartBool from '@mighty-justice/smart-bool';

import { getQuery } from '../../utils/navigationUtils';
import { LoginLink } from '../../utils/utils';
import LoggedOutLandingPage from '../LoggedOutLandingPage';
import { Button, Spacer } from '../common';
import Client from '../../base-modules/client';

interface IInjected {
  client: Client;
}

@inject('client')
@autoBindMethods
@observer
class ResponseTokenExpiredPage extends Component {
  @observable private isRefreshed = new SmartBool();

  private get injected () { return this.props as IInjected; }

  private async onResendLinkClick () {
    const { request_id } = getQuery();
    await this.injected.client.get(`/logged-out-document-requests/${request_id}/refresh_token/`);
    this.isRefreshed.setTrue();
  }

  public render () {
    return (
      <LoggedOutLandingPage title='Token Expired'>
        <div>
          {this.isRefreshed.isTrue
            ? (
              <>
                <h2>Success! Your token has been refreshed</h2>
                <h3>Check your email for a new link</h3>
              </>
            )
            : (
              <>
                <h2>Your document request token has expired!</h2>
                <h3>Click here to receive a new token via email</h3>
                <Button type='primary' id='btn-resend-request' onClick={this.onResendLinkClick}>Resend</Button>
              </>
            )
          }
        </div>
        <Spacer />
        <LoginLink />
      </LoggedOutLandingPage>
    );
  }
}

export default ResponseTokenExpiredPage;
