import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import autoBindMethods from 'class-autobind-decorator';
import { isString } from 'lodash';

import {
  EditableCard,
  FormModal,
  IFieldSetPartial,
} from '@mighty-justice/fields-ant';

import { Spin } from 'antd';

import StoresClass from '../../../stores/StoresClass';
import { ISettingTabProps } from '../settingsUtils';
import { addressConfig } from '../../../utils/configCommon';
import { getQuery } from '../../../utils/navigationUtils';
import Auth from '../../../base-modules/auth';

import StripeConnectButton from './StripeConnectButton';

interface IInjected extends ISettingTabProps {
  auth: Auth;
  stores: StoresClass;
}

@inject('stores', 'auth')
@autoBindMethods
@observer
class PaymentInfoCard extends Component<ISettingTabProps> {
  private get injected () {
    return this.props as IInjected;
  }

  public componentDidMount () {
    const { stripe_connected_account_id } = getQuery()
      , { stores } = this.injected
      , legalOrganization = stores.users.registryLegalOrganization;

    if (isString(stripe_connected_account_id) && legalOrganization) {
      legalOrganization.updateStripeAccount(stripe_connected_account_id);
    }
  }

  private renderPaymentInfoNote () {
    return (
      <p>
        Add your check mailing address here so law firms can easily reference it.
      </p>
    );
  }

  private get paymentInfoFieldSets (): IFieldSetPartial[] {
    return [[
      {
        field: 'stripeRegistration',
        readOnly: true,
        render: () => <StripeConnectButton {...this.props} />,
        showLabel: false
      },
      { field: 'note', readOnly: true, render: this.renderPaymentInfoNote, showLabel: false },
      { ...addressConfig, field: 'check_mailing_address' },
      { field: 'employer_id_number', type: 'ein' },
    ]];
  }

  private async onPaymentInfoSave (data: any) {
    const { stores, auth } = this.injected
      , legalOrganization = stores.users.account?.registry_legal_organization
      ;

    if (legalOrganization) {
      await legalOrganization.update(data, auth);
    }
  }

  public render () {
    const { isAdmin, isLoading } = this.props
      , { stores } = this.injected
      , legalOrganization = stores.users.account?.registry_legal_organization
      ;

    if (this.injected.stores.users.isLawFirmUser || !isAdmin) { return null; }

    return (
      <Spin spinning={isLoading.isTrue}>
        <EditableCard
          fieldSets={this.paymentInfoFieldSets}
          ModalComponent={FormModal}
          model={legalOrganization}
          onSave={this.onPaymentInfoSave}
          title='Payment Information'
        />
      </Spin>
    );
  }
}

export default PaymentInfoCard;
