import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import autoBindMethods from 'class-autobind-decorator';

import { APP_NAME } from '../../../../../utils/constants';
import { IDetailLien } from '../../../../../models/Lien';
import StoresClass from '../../../../../stores/StoresClass';

import SettingToggle from './SettingToggle';
import SettingToggleList from './SettingToggleList';

interface IProps {
  lien: IDetailLien;
}

interface IInjected extends IProps {
  stores: StoresClass;
}

@inject('stores')
@autoBindMethods
@observer
class DocumentSettingToggles extends Component<IProps> {
  private get injected () { return this.props as IInjected; }

  private async onChangeFinalized (has_lienholder_finalized_documents: boolean) {
    await this.props.lien.updateDocumentToggle({ has_lienholder_finalized_documents });
  }

  private async onChangeShare (share_documents: boolean) {
    await this.props.lien.updateDocumentToggle({ share_documents });
  }

  public render () {
    const { users } = this.injected.stores
      , { lien } = this.props;

    return (
      <SettingToggleList>
        <SettingToggle
          className='toggle-has_lienholder_finalized_documents'
          description={(
            'Confirm that the patient has finished their treatment and that all medical bills and records have been'
            + ` uploaded to ${APP_NAME}. We’ll notify the law firm to proceed with the demand.`
          )}
          label='Confirm Final Documents Uploaded'
          value={!!lien.has_lienholder_finalized_documents}
          onChange={this.onChangeFinalized}
        />
        {(users.sharesDocumentsManually || !lien.share_documents) && (
          <SettingToggle
            className='toggle-share_documents'
            description={(
              `By default, ${APP_NAME} sends documents you upload to the law firm on the case.`
              + ' Turn this off to keep documents private until you’re ready to share them.'
            )}
            label='Make Documents Visible to the Law Firm'
            onChange={this.onChangeShare}
            value={lien.share_documents}
          />
        )}
      </SettingToggleList>
    );
  }
}

export default DocumentSettingToggles;
