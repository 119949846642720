import { ClassAttributes } from 'react';
import autoBindMethods from 'class-autobind-decorator';
import { action, computed, observable, toJS } from 'mobx';
import {
  find,
  includes,
  isEmpty,
  isNumber,
  omitBy,
  pickBy,
} from 'lodash';

import { ColumnProps } from 'antd/lib/table';
import { PaginationProps } from 'antd/lib/pagination';
import { SorterResult, SortOrder } from 'antd/es/table/interface';
import { Key } from 'rc-table/lib/interface';

import Client from '../base-modules/client';
import { formatSortingParams } from '../utils/utils';
import { IEndpointCount } from '../components/common-facets/inputs/FacetObjectSearch';
import { itemRender } from '../utils/pagination';
import { PAGE_SIZE } from '../utils/constants';
import { IQuery, IQueryValue, queryToQueryString } from '../utils/navigationUtils';
import Table, { ITableProps } from '../components/common/Table';

import { ITableStoreAPI } from './ITableStoreAPI';

type IHydrate = (queryString: string) => Promise<any>

export const DEFAULT_PAGINATION: Partial<PaginationProps> = { current: 1 }
  , asyncNoop: IHydrate = async () => ({ results: [], count: 0 });

interface IOrdering extends Partial<SorterResult<any>> {
  desc?: boolean;
  id?: string;
}

interface IInit {
  baseQuery?: object;
  columns?: Array<ColumnProps<any>>;
  defaultPageSize?: number;
  endpoint?: string;
  filters?: IQuery;
  hydrate?: IHydrate;
  ignoredFilters?: string[];
  ordering?: IOrdering;
  pagination?: PaginationProps;
}

interface IConstructor extends IInit {
  client: Client;
}

@autoBindMethods
class TableStore implements ITableStoreAPI {
  public hydrate: IHydrate = asyncNoop;

  @observable public count = 0;
  @observable public data: any[] = [];
  @observable public isLoading = false;

  @observable public filters: IQuery = {};
  @observable public pagination: PaginationProps = {};
  @observable public ordering: IOrdering = {};

  @observable public facetCounts: {[key: string]: IEndpointCount[]} = {};

  private baseQuery: object = {};
  private ignoredFilters: string[] = [];
  public endpoint: string | undefined;
  private defaultPageSize: number = PAGE_SIZE;

  private client: Client;

  private columns: Array<ColumnProps<any>> = [];

  public constructor (init: IConstructor) {
    this.client = init.client;
    this.init(init);
  }

  public init (init: IInit) {
    this.baseQuery = init.baseQuery || {};
    this.columns = init.columns || [];
    this.defaultPageSize = init.defaultPageSize || PAGE_SIZE;
    this.endpoint = init.endpoint;
    this.filters = init.filters || {};
    this.hydrate = init.hydrate || asyncNoop;
    this.ignoredFilters = init.ignoredFilters || [];
    this.ordering = init.ordering || {};
    this.pagination = init.pagination || {};
  }

  public setSortedColumn () {
    const sortedColumn = find(this.columns, { key: this.ordering.id });
    this.resetOrdering();
    if (sortedColumn) { sortedColumn.sortOrder = this.ordering.desc ? 'descend' : 'ascend'; }
  }

  public setPagination (overrides: Partial<PaginationProps> = {}) {
    this.pagination = { ...this.tableProps.pagination, ...this.pagination, ...overrides };
  }

  public resetOrdering () {
    this.columns.forEach(column => { column.sortOrder = null; });
  }

  @action
  public async resetAll () {
    await this.setAndFetch(DEFAULT_PAGINATION, this.ignoredFilterQuery, {});
    this.resetOrdering();
  }

  @action
  public onFilterChange (filters: IQuery) {
    const ordering = { field: this.ordering.id as Key, order: this.ordering.desc ? 'descend' : 'ascend' as SortOrder };

    this.setAndFetch(DEFAULT_PAGINATION, { ...this.filters, ...filters }, ordering);
    this.setSortedColumn();
  }

  @action
  public set (pagination?: PaginationProps, filters?: IQuery, ordering?: SorterResult<any> | SorterResult<any>[]) {
    this.filters = omitBy(filters, isEmpty);
    this.pagination = { ...this.tableProps.pagination, ...pagination };
    const singleSorter = ordering as SorterResult<any>;
    this.ordering = singleSorter?.columnKey
      ? { id: String(singleSorter.columnKey), desc: singleSorter.order === 'descend' }
      : {};
  }

  public async setAndFetch (
    pagination?: PaginationProps,
    filters?: IQuery,
    ordering?: SorterResult<any> | SorterResult<any>[],
  ) {
    this.set(pagination, filters, ordering);
    await this.fetchTableData();
  }

  public get filterQuery (): IQuery {
    return {
      ...toJS(this.baseQuery),
      ...toJS(this.filters),
    };
  }

  public get ignoredFilterQuery (): IQuery {
    return pickBy(this.filterQuery, this.isIgnoredFilter);
  }

  public get unignoredFilterQuery (): IQuery {
    return omitBy(toJS(this.filters), this.isIgnoredFilter);
  }

  private isIgnoredFilter (_value: IQueryValue, key: string) {
    return includes(this.ignoredFilters, key);
  }

  @computed
  private get loading () {
    return this.isLoading;
  }

  @computed
  public get query (): IQuery {
    const orderingData = toJS(this.ordering)
      , page: number | null = this.pagination.current || null
      , pageQuery: IQuery = isNumber(page) ? { page: `${page}` } : {}
      , pageSize: number = this.pagination.pageSize || this.defaultPageSize
      , ordering: string = (isEmpty(orderingData) || !orderingData.id) ? '' : formatSortingParams(orderingData)
      , orderingWithId: string = ordering ? `${ordering},id` : ''
      ;

    return {
      ordering: orderingWithId,
      page_size: `${pageSize}`,
      ...pageQuery,
      ...this.filterQuery,
    };
  }

  @computed
  public get queryString (): string {
    return queryToQueryString(this.query);
  }

  @action
  public async fetchTableData () {
    this.isLoading = true;

    this.fetchFacetCounts();
    const result = await this.hydrate(this.queryString);

    this.data = result.results;
    this.count = result.count;
    this.setSortedColumn();
    this.setPagination();
    this.isLoading = false;
  }

  @action
  public async fetchFacetCounts () {
    if (!this.endpoint) { return; }
    this.facetCounts = await this.client.get(`${this.endpoint}counts/${this.queryString}`);
  }

  public async handleChange (pagination: any, filters: object, sorter: SorterResult<any> | SorterResult<any>[]) {
    await this.setAndFetch(pagination, { ...this.filters, ...filters }, sorter);
  }

  @computed
  public get tableProps (): ITableProps<any> & ClassAttributes<typeof Table> {
    return {
      dataSource: toJS(this.data),
      loading: this.loading,
      onChange: this.handleChange,
      pagination: {
        current: Number(this.pagination.current) || 1,
        defaultPageSize: this.defaultPageSize,
        itemRender,
        showSizeChanger: false,
        total: this.count,
      },
    };
  }
}

export default TableStore;
