import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import autoBindMethods from 'class-autobind-decorator';

import { formatDate, formatMoney, getNameOrDefault, getOrDefault } from '@mighty-justice/utils';

import InfoCard from '../common/InfoCard';
import StoresClass from '../../../../stores/StoresClass';
import { IDetailLien } from '../../../../models/Lien';
import { LinkButton } from '../../../common';
import { OPTION_KEYS } from '../../../../utils/constants';

import styles from './LienBalanceListItem.module.less';

interface IProps {
  lien: IDetailLien;
  onLienSelect: (lien: IDetailLien) => void;
}

interface IInjected extends IProps {
  stores: StoresClass;
}

@inject('stores')
@autoBindMethods
@observer
class LienBalanceListItem extends Component<IProps> {
  private get injected () {
    return this.props as IInjected;
  }

  private handleLienSelect () {
    const { onLienSelect, lien } = this.props;

    onLienSelect(lien);
  }

  public render () {
    const { lien } = this.props
      , { options: { getOptionName } } = this.injected.stores
      , { isLawFirmUser } = this.injected.stores.users
      , lastUpdatedAt = formatDate(lien.balance_last_updated_at)
      , lastUpdatedBy = getOrDefault(lien.balance_last_updated_by_organization_name)
      , hasLastUpdatedInfo = !!lastUpdatedAt && !!lastUpdatedBy
      , lienBalanceDisplay = lien.balance ? formatMoney(lien.balance) : 'Not Posted'
      , resolutionStatus = getOptionName(OPTION_KEYS.LIEN_RESOLUTION_STATUS, lien.resolution_status || '')
      ;

    const content = (
      <div className={styles.content}>
        <div className={lien.balance ? styles.balance : styles.empty}>
          {lienBalanceDisplay}
        </div>
        {resolutionStatus && <div>Closed - {resolutionStatus}</div>}
      </div>
    );

    return (
      <div className={styles.root}>
        <InfoCard
          bottomRightRender={!isLawFirmUser && <LinkButton onClick={this.handleLienSelect}>Update Balance</LinkButton>}
          content={content}
          title={getNameOrDefault(lien.lienholder)}
          topRightContent={hasLastUpdatedInfo ? `Updated at ${lastUpdatedAt} by ${lastUpdatedBy}` : null}
        />
      </div>
    );
  }
}

export default LienBalanceListItem;
