import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { IActivityDetailComponentProps } from './interfaces';

@observer
class CaseContactChangedActivity extends Component<IActivityDetailComponentProps> {
  public render () {
    const { activity: { activity_data: { old_contact, new_contact } } } = this.props;

    return (
      <>
        <h4>Case contact changed</h4>
        {old_contact && <p><b>From:</b> {old_contact}</p>}
        <p><b>To:</b> {new_contact}</p>
      </>
    );
  }
}

export default CaseContactChangedActivity;
