import { SelectProps } from 'antd/es/select';

import { getNameOrDefault } from '@mighty-justice/utils';
import { fillInFieldConfig, IFieldConfigObjectSearchCreate } from '@mighty-justice/fields-ant';

import { URLS } from '../../../utils/constants';
import StoresClass from '../../../stores/StoresClass';

export const getMultiSelectLawFirmFieldConfig = (stores: StoresClass): IFieldConfigObjectSearchCreate => {
  const userId = stores.users.user?.id;

  return {
    createFields: [],
    endpoint: URLS.LEGAL_ORGANIZATIONS,
    renderOption: getNameOrDefault,
    renderSelected: getNameOrDefault,

    ...fillInFieldConfig({
      field: 'law_firms',
      searchFilters: {
        law_firms_for_lienholder_user: userId,
      },
      showLabel: true,
    })
  };
};

export const multiSelectSelectProps: SelectProps<any> = { mode: 'multiple', style: { display: 'block' } };
