import { observable } from 'mobx';
import autoBindMethods from 'class-autobind-decorator';
import { head } from 'lodash';
import { applySnapshot } from 'mobx-state-tree';

import { notification } from 'antd';

import SmartBool from '@mighty-justice/smart-bool';

import Client from '../base-modules/client';
import { ENDPOINTS } from '../components/page-settings/settingsUtils';
import {
  INotificationSetting,
  INotificationSubscriptionBlacklistSetting,
  INotificationSubscriptionSetting,
  IAccount,
  IUserFlag,
  NotificationSetting,
  UserFlag,
} from '../models/User';

import UserStore from './UserStore';

@autoBindMethods
class SettingsStore {
  client: Client;
  userStore: UserStore;

  @observable public isLoading = new SmartBool(true);
  @observable public users?: IAccount[];
  @observable public notificationSubscriptionSetting?: INotificationSubscriptionSetting;
  @observable public notificationSubscriptionBlacklistSetting?: INotificationSubscriptionBlacklistSetting;

  @observable private notificationSettings: INotificationSetting[] = [];
  @observable private userFlags?: IUserFlag[];

  public constructor (client: Client, userStore: UserStore) {
    this.client = client;
    this.userStore = userStore;
  }

  public async upsertNotificationSetting (data: any) {
    const setting = this.getNotificationSetting(data.notification_category);

    if (setting && setting.id) {
      const response = await this.client.update(`${this.notificationEndpoint}${setting.id}/`, data);
      applySnapshot(setting, response.data);
    }
    else {
      const response = (await this.client.create(this.notificationEndpoint, data)).data;
      this.notificationSettings.push(NotificationSetting.create(response));
    }
  }

  public async upsertNotificationSubscriptionSetting (data: any) {
    const id = this.notificationSubscriptionSetting?.id
      , url = `${ENDPOINTS.NOTIFICATION_SUBSCRIPTION_SETTING}${id ? `${id}/` : ''}`
      , method = id ? this.client.update : this.client.create
      , result = await method(url, data)
      ;

    this.notificationSubscriptionSetting = result.data;
  }

  public async upsertNotificationSubscriptionBlacklistSetting (data: any) {
    const id = this.notificationSubscriptionBlacklistSetting?.id
      , url = `${ENDPOINTS.NOTIFICATION_SUBSCRIPTION_BLACKLIST_SETTING}${id ? `${id}/` : ''}`
      , method = id ? this.client.update : this.client.create
      , result = await method(url, data)
      ;

    this.notificationSubscriptionBlacklistSetting = result.data;
  }

  public async fetch () {
    this.isLoading.setTrue();

    const organizationId = this.userStore.registryLegalOrganization?.id
      , organizationQuery = `?registry_legal_organization=${organizationId}&page_size=off`
      , [
        users,
        notificationSettings,
        notificationSubscriptionBlacklistSetting,
        notificationSubscriptionSetting,
      ] = await Promise.all([
        this.client.get(`${ENDPOINTS.ACCOUNT}${organizationQuery}`),
        this.client.get(this.notificationEndpoint),
        this.client.get(ENDPOINTS.NOTIFICATION_SUBSCRIPTION_BLACKLIST_SETTING),
        this.client.get(ENDPOINTS.NOTIFICATION_SUBSCRIPTION_SETTING),
        this.fetchUserFlags(),
      ])
      ;

    this.users = users;
    this.notificationSettings = notificationSettings.results.map(
      (settingData: INotificationSetting) => NotificationSetting.create(settingData),
    );
    this.notificationSubscriptionSetting = head(notificationSubscriptionSetting);
    this.notificationSubscriptionBlacklistSetting = head(notificationSubscriptionBlacklistSetting);

    // Staff will not show up on the organization
    if (!this.userStore.account) {
      notification.error({
        description: 'Logged-in user not found in organization user list.',
        message: 'Error',
      });
    }
    this.isLoading.setFalse();
  }

  public getNotificationSetting (notificationCategory: string | undefined): INotificationSetting | undefined {
    return (this.notificationSettings &&
      this.notificationSettings.find(s => s.notification_category === notificationCategory)
    );
  }

  public async fetchUserFlags () {
    const response = await this.client.get(ENDPOINTS.USER_FLAGS);
    this.userFlags = response.results.map((flagData: IUserFlag) => UserFlag.create(flagData));
  }

  // Remove the ignore comment below and set function to public to use user flags
  // istanbul ignore next
  private getUserFlag (flag: string, default_value: boolean = false): boolean {
    if (!this.userFlags) { return default_value; }
    const userFlag: IUserFlag | undefined = this.userFlags.find(f => f.flag === flag);
    if (!userFlag) { return default_value; }
    return userFlag.is_enabled;
  }

  // Remove the ignore comment below and set function to public to use user flags
  // istanbul ignore next
  private async setUserFlag (flag: string, is_enabled: boolean) {
    await this.client.create(ENDPOINTS.USER_FLAGS, { flag, is_enabled });
    await this.fetchUserFlags();
  }

  private get notificationEndpoint () {
    return this.userStore.isLawFirmUser
      ? ENDPOINTS.NOTIFICATION_SETTINGS
      : ENDPOINTS.NOTIFICATION_TYPE_SETTINGS
    ;
  }
}

export default SettingsStore;
