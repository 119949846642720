import React, { Component } from 'react';

import { Layout } from 'antd';
import cx from 'classnames';

import AppContent from '../AppContent';

import LienholderRegistrationSteps from './LienholderRegistrationSteps';

import styles from './LienholderRegistrationLayout.module.less';

class LienholderRegistrationLayout extends Component<{}> {
  public render () {
    const { children } = this.props
      , layoutClassName = cx(styles.root, 'page page-floating page-style-card');

    return (
      <AppContent>
        <Layout className={layoutClassName}>
          <Layout.Header className={styles.steps}>
            <LienholderRegistrationSteps />
          </Layout.Header>
          <Layout.Content>
            {children}
          </Layout.Content>
        </Layout>
      </AppContent>
    );
  }
}

export default LienholderRegistrationLayout;
