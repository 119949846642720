import React from 'react';
import cx from 'classnames';

import styles from './Text.module.less';

interface IProps {
  size?: number;
  children?: React.ReactNode;
  bold?: boolean;
  align?: 'left' | 'center' | 'right';
}

const SIZE_CX: { [key: number]: string } = {
  1: styles.size1,
  2: styles.size2,
  3: styles.size3,
  4: styles.size4,
  5: styles.size5,
  6: styles.size6,
  7: styles.size7,
};

export default function Text (props: IProps) {
  const { children, size, bold, align } = props;
  if (!children) { return null; }

  const sizeClass = (size && size in SIZE_CX) ? SIZE_CX[size] : SIZE_CX[3]
    , alignClass = align ? styles[align] : null
    , className = cx(
      { [styles.bold]: bold },
      sizeClass,
      alignClass,
    );

  return <span className={className}>{children}</span>;
}
