import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { IActivityDetailComponentProps } from './interfaces';

@observer
class DocumentUploadActivity extends Component<IActivityDetailComponentProps> {
  public render () {
    return (
      <h4>Documents uploaded</h4>
    );
  }
}

export default DocumentUploadActivity;
