import { optionSelectConfig } from '../../../../utils/configCommon';
import { addLawFirmField } from '../../add-case-form-drawer/addCaseDrawerConfig';

function insertRequiredInfoIf (model: any) { return model.isKnown === false; }
function insertLawFirmIf (model: any) { return model.isKnown; }
const NOTE_LABEL = 'Please share any additional information or documentation that you may have about the change';

export const changedRepresentationFieldSets = [
  [
    {
      ...optionSelectConfig,
      field: 'isKnown',
      label: 'Do you know who now represents the plaintiff?',
      required: true,
      type: 'boolean',
    },
    {
      ...addLawFirmField,
      // TODO: create a base rendering config for this field to avoid overriding colProps
      colProps: {},
      field: 'law_firm',
      insertIf: insertLawFirmIf,
      label: 'Please select the new law firm',
    },
    {
      field: 'note',
      insertIf: insertLawFirmIf,
      label: NOTE_LABEL,
    },
    {
      field: 'note',
      insertIf: insertRequiredInfoIf,
      label: NOTE_LABEL,
      required: true,
    },
  ],
];
