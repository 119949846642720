import React from 'react';

import { Form } from '@mighty-justice/fields-ant';

import { objectSearchCreateConfig } from '../../utils/configCommon';
import { URLS } from '../../utils/constants';

import styles from './ManualDedupePage.module.less';

interface IProps {
  onChange: (value: { id: string } | null) => void;
}

const FIELDSET = {
  editProps: {
    ...objectSearchCreateConfig.editProps,
    selectProps: {
      ...objectSearchCreateConfig.editProps.selectProps,
      placeholder: 'Search by name',
    },
  },
  colProps: { className: styles.search },
  endpoint: URLS.INTERNAL_LEGAL_ORGANIZATION_SEARCH,
  field: 'legal_organization',
  label: 'Select law firm or lienholder',
  type: 'objectSearch',
};

function LegalOrganizationSearch (props: IProps) {
  const fieldSet = { ...FIELDSET, editProps: { ...FIELDSET.editProps, onChange: props.onChange } };

  return (
    <Form
      fieldSets={[[fieldSet]]}
      showControls={false}
    />
  );
}

export default LegalOrganizationSearch;
