import { observable } from 'mobx';
import autoBindMethods from 'class-autobind-decorator';
import { filter } from 'lodash';

import SmartBool from '@mighty-justice/smart-bool';

import Client from '../base-modules/client';
import { INotification, Notification } from '../models/Notification';

import StoresClass from './StoresClass';

@autoBindMethods
class NotificationsStore {
  client: Client;
  stores: StoresClass;

  @observable public isLoading = new SmartBool(true);
  @observable public notifications: INotification[] = [];

  public constructor (client: Client, stores: StoresClass) {
    this.client = client;
    this.stores = stores;
  }

  public async fetch (): Promise<void> {
    if (!this.stores.users.isLoggedIn) { return; }

    const response = await this.client.get('/escrow-agent/in-app-notifications/');

    this.notifications = response.results.map((notification: INotification) => Notification.create(notification));
    this.isLoading.setFalse();
  }

  public get unviewedCount () {
    return filter(this.notifications, notification => !notification.viewed_at).length;
  }

  public async markAsViewed (): Promise<void> {
    await this.client.update('/escrow-agent/in-app-notifications/mark-as-viewed/');
    await this.fetch();
  }
}

export default NotificationsStore;
