import React, { Component } from 'react';
import { observer } from 'mobx-react';
import autoBindMethods from 'class-autobind-decorator';

import LoggedOutLandingPage from '../LoggedOutLandingPage';
import { BOOLEAN_STRING } from '../../utils/constants';
import { getQuery } from '../../utils/navigationUtils';
import { LoginLink } from '../../utils/utils';

const ACCOUNT_PENDING_COPY = {
    description: 'Our internal team is verifying your account and will reach out to you shortly',
    header: 'Almost there!',
  }
  , ACCOUNT_CREATED_COPY = {
    description: 'Please check your email to finish setting up your account',
    header: `Success! You've been auto-verified!`,
  };

@autoBindMethods
@observer
class RegistrationCompletePage extends Component {
  public render () {
    const isSuccessful = getQuery().success === BOOLEAN_STRING.TRUE
      , { header, description } = isSuccessful ? ACCOUNT_CREATED_COPY : ACCOUNT_PENDING_COPY;

    return (
      <LoggedOutLandingPage title='Register Account'>
        <h2>{header}</h2>
        <h3>{description}</h3>
        <LoginLink />
      </LoggedOutLandingPage>
    );
  }
}

export default RegistrationCompletePage;
