import React from 'react';

import { Row } from 'antd';

import { ICaseThread } from '../../../../models/CaseThread';
import { Text } from '../../../common';

import InboxList from './InboxList';
import { InboxListProps } from './interfaces';
import { getActiveThreads } from './utils';

import styles from './InboxSectio.module.less';

interface IProps {
  passThroughProps: InboxListProps;
  threads: ICaseThread[];
  title: string;
}

function InboxSection (props: IProps) {
  const { title, threads, passThroughProps } = props
    , activeThreads = getActiveThreads(threads);

  if (title === 'All' && !activeThreads.length) { return null; }

  return (
    <div className={styles.root}>
      <Row className={styles.title}><Text size={3} bold>{title}</Text></Row>
      <InboxList
        threads={threads}
        passThroughProps={passThroughProps}
      />
    </div>
  );
}

export default InboxSection;
