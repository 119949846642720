import { formatDate } from '@mighty-justice/utils';

import { IDetailCase } from '../../../../models/Case';

import { CASE_STATUS_WITH_DATE } from './constants';

export const caseExtraStatusDate = (_case: IDetailCase) => {
  switch (_case.status) {
  case (CASE_STATUS_WITH_DATE.CASE_SETTLED_NOT_YET_DISBURSED):
    return `Check Expected By: ${formatDate(_case.date_expected_to_receive_check)}`;
  case (CASE_STATUS_WITH_DATE.MEDIATION_OR_ARBITRATION_SCHEDULED):
    return `Appearance: ${formatDate(_case.appearance_date)}`;
  case (CASE_STATUS_WITH_DATE.IN_LITIGATION):
    return `Lawsuit filed: ${formatDate(_case.lawsuit_filed_date)}`;
  // not in mocks
  case (CASE_STATUS_WITH_DATE.TRIAL_SCHEDULED):
    return `Appearance: ${formatDate(_case.appearance_date)}`;
  default:
    return null;
  }
};
