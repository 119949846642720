import React, { Component } from 'react';
import autoBindMethods from 'class-autobind-decorator';
import { observer } from 'mobx-react';
import { Navigate } from 'react-router-dom';

import { Form } from '@mighty-justice/fields-ant';
import { IModel } from '@mighty-justice/fields-ant/dist/props';

import { isUUID, stringOrUndefined } from '../../utils/utils';
import navigationWrapper from '../../utils/navigationWrapper';
import { getQuery, getUrlForNewPath, INavigateProps } from '../../utils/navigationUtils';
import { URLS } from '../../utils/constants';

interface IHooksProps {
  registerAccount: (data: any) => Promise<void>;
  title: string;
}

interface IProps extends IHooksProps, INavigateProps {}

@autoBindMethods
@observer
class RegistrationForm extends Component<IProps> {
  private get fieldSets () {
    const { law_firm, referral_source } = getQuery();

    return [[
      { field: 'first_name', required: true },
      { field: 'last_name', required: true },
      { field: 'email', required: true },
      { field: 'legal_organization', type: 'hidden', value: stringOrUndefined(law_firm) },
      { field: 'referral_source', type: 'hidden', value: stringOrUndefined(referral_source) },
    ]];
  }

  private async handleSubmit (model: IModel) {
    const { registerAccount } = this.props;
    await registerAccount(model);
  }

  public render () {
    const { title } = this.props
      , { law_firm } = getQuery();

    if (!isUUID(law_firm)) {
      return <Navigate to={getUrlForNewPath(URLS.LOGIN)} />;
    }

    return (
      <Form
        blockSubmit
        fieldSets={this.fieldSets}
        onSave={this.handleSubmit}
        title={title}
      />
    );
  }
}

export default navigationWrapper(RegistrationForm);
