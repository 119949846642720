import React, { Component } from 'react';
import { observer } from 'mobx-react';
import autoBindMethods from 'class-autobind-decorator';
import cx from 'classnames';

import { Layout, Row } from 'antd';
import { RowProps } from 'antd/lib/grid/row';

import styles from './UpgradeTitle.module.less';

interface IProps {
  subtitle?: string;
  title: string;
}

@autoBindMethods
@observer
class UpgradeTitle extends Component<IProps> {
  public render () {
    const { subtitle, title } = this.props
      , rowProps: Partial<RowProps> = { align: 'middle', justify: 'center' }
      ;

    return (
      <Layout className={cx(styles.root, styles.override)}>
        {subtitle && (
          <Row className={styles.subtitle} {...rowProps}>
            {subtitle}
          </Row>
        )}
        <Row className={styles.title} {...rowProps}>
          {title}
        </Row>
      </Layout>
    );
  }
}

export default UpgradeTitle;
