import { DEFAULT_ROW_PROPS } from '../../../utils/constants';
import { LH_ORGANIZATION_OPTIONS } from './constants';

const CONTACT_INFO = {
    WEBSITE: 'WEBSITE',
    ADDRESS_AND_PHONE: 'ADDRESS',
  }
  , CONTACT_INFO_OPTIONS = [
    { value: CONTACT_INFO.WEBSITE, name: 'Company Website (Recommended)' },
    { value: CONTACT_INFO.ADDRESS_AND_PHONE, name: 'Full Company Address and Phone Number' },
  ]
  , colProps = { span: 12 }
  , rowProps = DEFAULT_ROW_PROPS
  , USER_FIELD_SET = [
    {
      fields: [
        {
          colProps,
          field: 'user_first_name',
          required: true,
          label: 'First Name',
        },
        {
          colProps,
          field: 'user_last_name',
          required: true,
          label: 'Last Name',
        },
      ],
      rowProps,
    },
    {
      fields: [
        { field: 'user_email', required: true, label: 'Work Email', },
        { label: 'Company Name', field: 'lienholder.name', required: true, },
        {
          field: 'lienholder.type',
          label: 'Company Type',
          options: LH_ORGANIZATION_OPTIONS,
          required: true,
          type: 'optionSelect',
        },
        {
          field: 'contactInfo',
          label: 'Preferred Company Contact Info',
          options: CONTACT_INFO_OPTIONS,
          type: 'radio',
        },
        {
          field: 'lienholder.website',
          insertIf: (model: any) => (model.contactInfo === CONTACT_INFO.WEBSITE),
          label: 'Company Website',
          required: true,
        },
        {
          field: 'lienholder.phone_number',
          insertIf: (model: any) => (model.contactInfo === CONTACT_INFO.ADDRESS_AND_PHONE),
          label: 'Company Phone Number',
          required: true,
        },
        {
          field: 'lienholder.address',
          insertIf: (model: any) => (model.contactInfo === CONTACT_INFO.ADDRESS_AND_PHONE),
          label: 'Full Company Address',
          required: true,
        },
      ]
    },
  ]
;

const EXPERIMENTAL_USER_FIELD_SET = [[
  { field: 'user_email', required: true, label: 'Work Email', },
]];

export {
  CONTACT_INFO,
  EXPERIMENTAL_USER_FIELD_SET,
  USER_FIELD_SET,
};
