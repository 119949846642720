import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import autoBindMethods from 'class-autobind-decorator';

import SmartBool from '@mighty-justice/smart-bool';
import { FormModal } from '@mighty-justice/fields-ant';

import { IDetailCase } from '../../../../models/Case';
import { IDetailLien } from '../../../../models/Lien';
import StoresClass from '../../../../stores/StoresClass';
import { CASE_ACTIVITY_TYPES } from '../../../../utils/constants';
import { INavigateProps } from '../../../../utils/navigationUtils';
import navigationWrapper from '../../../../utils/navigationWrapper';

import { removeCaseModalChildrenBefore } from './constants';

import styles from '../../../common/Modal.module.less';

interface IProps extends INavigateProps{
  isNoteRequired: boolean;
  isVisible: SmartBool;
  _case: IDetailCase;
  lien: IDetailLien;
}

interface IInjected extends IProps {
  stores: StoresClass;
}

@inject('stores')
@autoBindMethods
@observer
class RemoveCaseModal extends Component<IProps> {
  private get injected () { return this.props as IInjected; }

  private async onRemoveCaseConfirm (model: { note?: string }) {
    const { _case, navigate, lien } = this.props
      , { stores: { infiniteTableStore } } = this.injected
      ;

    await lien.removeLienFromPortfolio();
    if (_case.law_firm) {
      lien.createActivity({
        type: CASE_ACTIVITY_TYPES.LIEN_DELETED,
        ...model,
      });
    }

    await infiniteTableStore.fetchTableData();
    navigate(infiniteTableStore.firstCaseUrl);
  }

  private get fieldSets () {
    const { isNoteRequired } = this.props;

    return [[
      { field: 'note', required: isNoteRequired },
    ]];
  }

  public render () {
    const { isVisible } = this.props;

    return (
      <FormModal
        className={styles.root}
        childrenBefore={removeCaseModalChildrenBefore}
        fieldSets={this.fieldSets}
        isVisible={isVisible}
        onSave={this.onRemoveCaseConfirm}
        saveText='Remove'
        title='Are you sure?'
      />
    );
  }
}

export default navigationWrapper(RemoveCaseModal);
