import React from 'react';
import { Col, Row } from 'antd';

import { EMPTY_FIELD, formatDate, formatPhoneNumber, formatWebsite, mapBooleanToText } from '@mighty-justice/utils';

import {
  IDedupeCase,
  IVerifiedOrganizationData,
  IVerifyContactMatch,
  IVerifyMatch,
  IVerifyOrganizationMatch,
} from '../../models/DedupeMatch';

import { formatAddressHTML } from '../../utils/columnCommon';
import { ANT_HALF_COL_WIDTH } from '../../utils/constants';

export const renderDocumentDownload = (_value: any, record: IDedupeCase) => {
  const { download_all_documents_url, lien_documents_count } = record;

  if (!download_all_documents_url) {
    return <div>No Documents</div>;
  }

  return (
    <div>
      <a href={download_all_documents_url}>Download Documents</a>{' '}({lien_documents_count})
    </div>
  );
};

export const renderInfoDiv = (firstValue: any, secondValue: any) => (
  <div>
    {firstValue}
    {secondValue && (
      <Row style={{ fontSize: 12 }}>
        {secondValue}
      </Row>
    )}
  </div>
);

export function renderEnteredBy (_value: any, data: IVerifyMatch) {
  return (
    <div>
      {data.created_by_name && (
        <Row>
          {data.created_by_name}
          <br/><br/>
        </Row>
      )}
      <Row>{data.created_by_organization_name}</Row>
    </div>
  );
}

export function renderDate (date: string) {
  return formatDate(date);
}

export const renderEmails = (emails: string[]) => {
  const filledEmails = emails.filter(email => !!email);

  if (!filledEmails.length) {
    return null;
  }

  return (
    <div style={{ marginTop: '15px' }}>
      {filledEmails.map(email => <Row key={email}>{email}</Row>)}
    </div>
  );
};

export const renderLegalOrganizationDetails = (value: IVerifiedOrganizationData, _data: unknown) => (
  <>
    <Row gutter={4} style={{ wordWrap: 'break-word' }}>
      <Col span={ANT_HALF_COL_WIDTH}>
        {formatAddressHTML(value.address)}
      </Col>
      <Col span={ANT_HALF_COL_WIDTH}>
        {formatPhoneNumber(value.phone_number)}
        <br />
        {formatWebsite(value.website)}
      </Col>
    </Row>
    <Row>
      {renderEmails(value.contact_emails)}
    </Row>
  </>
);

export const renderVerifiedMatchNote = (_value: any, data: IVerifyOrganizationMatch | IVerifyContactMatch) => {
  const { dismissed_verification_note } = data;

  if (!dismissed_verification_note) {
    return EMPTY_FIELD;
  }

  return <Row>{dismissed_verification_note}</Row>;
};

export const renderRelatedToCompulsionText = (_value: any, data: IVerifyOrganizationMatch | IVerifyContactMatch) => {
  const { related_to_compulsion_firm } = data;

  return <Row>{mapBooleanToText(related_to_compulsion_firm)}</Row>;
};
