import React, { Component } from 'react';
import autoBindMethods from 'class-autobind-decorator';
import { observer, inject } from 'mobx-react';
import { action } from 'mobx';
import cx from 'classnames';

import StoresClass from '../stores/StoresClass';
import { INavigateProps, queryValueToString } from '../utils/navigationUtils';
import navigationWrapper from '../utils/navigationWrapper';

import { Icon, DebouncedControlledInput } from './common';

import styles from './PlaintiffFilter.module.less';

interface IInjected extends INavigateProps {
  stores: StoresClass;
}

@inject('stores')
@autoBindMethods
@observer
class PlaintiffFilter extends Component<INavigateProps> {
  private get value (): string {
    const { stores: { infiniteTableStore } } = this.injected;
    return queryValueToString(infiniteTableStore.filters.search);
  }

  private get injected () {
    return this.props as IInjected;
  }

  @action
  private onSearch (search: string) {
    const { searchParams: [, setQuery] } = this.props
      , { stores: { infiniteTableStore } } = this.injected
      ;

    setQuery({ ...infiniteTableStore.unignoredFilterQuery, search });

    infiniteTableStore.onFilterChange({ search });
    infiniteTableStore.fetchTableData();
  }

  private get placeholder () {
    const { stores } = this.injected;

    if (stores.externalSystems.hasSystems) {
      return 'Search by name or ID';
    }

    return stores.users.isLawFirmUser ? 'Search for plaintiff' : 'Search for patient';
  }

  public render () {
    return (
      <DebouncedControlledInput
        className={cx(styles.root, styles.override, styles['extra-override'], 'dd-privacy-mask-user-input')}
        onChange={this.onSearch}
        placeholder={this.placeholder}
        prefix={<Icon.Search className={styles.icon}/>}
        value={this.value}
      />
    );
  }
}

export default navigationWrapper(PlaintiffFilter);
