import React, { Component } from 'react';
import { observer } from 'mobx-react';
import autoBindMethods from 'class-autobind-decorator';
import { get, orderBy } from 'lodash';

import { Drawer } from 'antd';

import { formatDateTime } from '@mighty-justice/utils';
import SmartBool from '@mighty-justice/smart-bool';

import { ICaseActivity } from '../../../models/CaseActivity';
import { TimelineItem } from '../../common/Timeline';
import { Timeline } from '../../common';
import {
  APP_NAME,
  CASE_ACTIVITY_TYPES,
  DEFAULT_ACTIVITY_CREATED_BY,
} from '../../../utils/constants';

import { ACTIVITY_DETAIL_COMPONENTS, activityDrawerProps } from './constants';
import DefaultActivity from './activities/DefaultActivity';

const IGNORE_CREATED_BY_TYPES = [CASE_ACTIVITY_TYPES.AUTOMATED_CASE_UPDATE_REQUEST];

interface IProps {
  activities: null | ICaseActivity[];
  isVisible: SmartBool;
  title: string;
}

@autoBindMethods
@observer
class ActivityHistoryDrawer extends Component<IProps> {
  // eslint-disable-next-line complexity
  private getCreatedByText (activity: ICaseActivity) {
    const {
      created_by_name,
      created_by_organization,
      hide_created_by,
      is_internal_user,
      type,
      activity_data
    } = activity;

    switch (true) {
    case (hide_created_by || IGNORE_CREATED_BY_TYPES.includes(type)):
      return '';
    case (is_internal_user):
      return `By ${APP_NAME} Internal User`;
    case (!!created_by_name && !!created_by_organization):
      return `By ${created_by_name} (${created_by_organization})`;
    case (!!created_by_organization):
      return `By ${created_by_organization}`;
    case (!!created_by_name):
      return `By ${created_by_name}`;
    case (!!activity_data.case_manager_name):
      return `By ${activity_data.case_manager_name}`;
    default:
      return DEFAULT_ACTIVITY_CREATED_BY;
    }
  }

  private getComponent (activity: ICaseActivity) {
    return get(ACTIVITY_DETAIL_COMPONENTS, activity.type, DefaultActivity) as typeof Component;
  }

  public render () {
    const { activities, isVisible, title } = this.props
      , sortedActivities = orderBy(activities, ['created_at'], ['desc'])
      ;

    return (
      <Drawer
        onClose={isVisible.setFalse}
        placement='right'
        title={title}
        visible={isVisible.isTrue}
        {...activityDrawerProps}
      >
        {!sortedActivities.length &&
          <p>No {title.toLowerCase()} yet.</p>
        }
        <Timeline>
          {sortedActivities.map((activity: ICaseActivity) => {
            const ActivityDetailComponent = this.getComponent(activity);

            return (
              <TimelineItem key={activity.id}>
                <ActivityDetailComponent activity={activity} />
                <small>
                  {formatDateTime(activity.created_at)}
                  <br />
                  {this.getCreatedByText(activity)}
                </small>
              </TimelineItem>
            );
          })}
        </Timeline>
      </Drawer>
    );
  }
}

export default ActivityHistoryDrawer;
