import React, { Component } from 'react';
import { observer } from 'mobx-react';
import autoBindMethods from 'class-autobind-decorator';

import { IDetailLien } from '../../../../models/Lien';
import { ITabProps } from '../CaseTabConfig';
import { Section } from '../../../common';

import EmptyLienBalance from './EmptyLienBalance';
import LienBalanceList from './LienBalanceList';

@autoBindMethods
@observer
class FinanceTab extends Component<ITabProps> {
  private get hasLienBalance (): boolean {
    const { lien, liens } = this.props;

    if (lien) { return !!lien.balance || lien.balance === 0; }
    else if (liens) { return liens.some(listLien => listLien.balance || listLien.balance === 0); }

    return false;
  }

  private get liensList (): IDetailLien[] {
    const { liens, lien } = this.props;
    if (liens) { return liens; }

    return lien ? [lien] : [];
  }

  public render () {
    return (
      <Section>
        {this.hasLienBalance ?
          <LienBalanceList liens={this.liensList} /> :
          <EmptyLienBalance lien={this.props.lien} />
        }
      </Section>
    );
  }
}

export default FinanceTab;
