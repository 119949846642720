import React, { Component, CSSProperties } from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import autoBindMethods from 'class-autobind-decorator';
import cx from 'classnames';

import { Dropdown as AntDropdown, Menu, Row } from 'antd';

import SmartBool from '@mighty-justice/smart-bool';

import { Icon } from '../../components/common';

import styles from './Dropdown.module.less';

export interface IDropdownItem {
  label: string;
  value: string;
}

interface IProps {
  onOptionSelect: (option: IDropdownItem) => Promise<void>;
  options: IDropdownItem[];
  selectedOption?: IDropdownItem;
  buttonProps: {
    text: string;
    className?: string;
  };
}

@autoBindMethods
@observer
class Dropdown extends Component<IProps> {
  @observable private isDropdownVisible = new SmartBool();

  private get icon () {
    const IconClass = this.isDropdownVisible.isTrue ? Icon.CaretUpSolid : Icon.CaretDownSolid;

    return <IconClass className={styles.icon} />;
  }

  private renderButton () {
    const { buttonProps: { className, text } } = this.props
      , buttonClass = cx(styles.button, className)
      ;

    return (
      <u className={buttonClass}>
        {text}
        {this.icon}
      </u>
    );
  }

  private async onSelect (option: IDropdownItem) {
    const { onOptionSelect } = this.props;

    this.isDropdownVisible.setFalse();
    await onOptionSelect(option);
  }

  private get menu () {
    const { options, selectedOption } = this.props;

    return (
      <Menu className={styles.overlay}>
        {options.map((option: IDropdownItem, idx: number) => {
          const isSelected: boolean = !!selectedOption && option.value === selectedOption.value
            , optionStyle: CSSProperties | undefined = isSelected ? { fontWeight: 'bold' } : undefined;

          return (
            <Menu.Item onClick={this.onSelect.bind(this, option)} key={idx}>
              <span style={optionStyle}>
                {option.label}
              </span>
            </Menu.Item>
          );
        })}
      </Menu>
    );
  }

  public render () {
    return (
      <AntDropdown
        overlay={this.menu}
        placement='bottomLeft'
        onVisibleChange={this.isDropdownVisible.set}
        trigger={['click']}
      >
        <Row className={styles.root}>
          {this.renderButton()}
        </Row>
      </AntDropdown>
    );
  }
}

export default Dropdown;
