import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import autoBindMethods from 'class-autobind-decorator';

import { formatDate, getNameOrDefault } from '@mighty-justice/utils';
import SmartBool from '@mighty-justice/smart-bool';

import { ILegalOrganization } from '../../models/Party';
import { IListCase } from '../../models/Case';

import styles from './LienholderCaseSummary.module.less';

interface IProps {
  lienholder: ILegalOrganization;
}

interface ILienholderCases {
  count: number;
  results: IListCase[];
}

const MAX_CASES = 5;

@autoBindMethods
@observer
class LienholderCaseSummary extends Component<IProps> {
  @observable private casesOnLienholder: ILienholderCases = { results: [], count: 0 };
  @observable private isLoading = new SmartBool();

  public componentDidMount () {
    this.fetchCasesOnLienholder();
  }

  private async fetchCasesOnLienholder () {
    const { lienholder } = this.props
      , results = await this.isLoading.until(lienholder.fetchCases({ page_size: MAX_CASES }))
      ;

    this.casesOnLienholder = results;
  }

  private renderCase (_case: any) {
    const { plaintiff, date_of_loss } = _case
      , { date_of_birth } = plaintiff;

    return (
      <p className='dd-privacy-mask' key={_case.id}>
        {getNameOrDefault(plaintiff)}, {formatDate(date_of_loss)}, {formatDate(date_of_birth)}
      </p>
    );
  }

  public render () {
    const { results, count } = this.casesOnLienholder
      , isLoading = this.isLoading.isTrue;

    return (
      <div className={styles.root}>
        <p className={styles.title}>Cases listed with this lienholder:</p>
        {results.map(this.renderCase)}

        {isLoading && 'Loading...'}
        {(!isLoading && !count) && 'No cases'}

        {count > MAX_CASES &&
          <p className={styles.caseInfo}>...and {count - MAX_CASES} others</p>
        }
      </div>
    );
  }
}

export default LienholderCaseSummary;
