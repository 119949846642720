/* eslint-disable max-lines */
import React from 'react';
import { isArrayLike } from 'mobx';
import { differenceInCalendarDays, differenceInCalendarYears, parse, parseISO } from 'date-fns';
import store from 'store';
import { md5 } from 'hash-wasm';

import {
  get,
  isNil,
  isNumber,
  isString,
  merge,
  pickBy,
  range,
  set,
  some,
} from 'lodash';

import { Col, Row, notification } from 'antd';
import { RcFile, UploadProps } from 'antd/lib/upload';

import {
  formatOptionSelect,
  IFieldConfigPartial,
  IFieldSetPartial,
  mapFieldSetFields,
} from '@mighty-justice/fields-ant';
import { IModel } from '@mighty-justice/fields-ant/dist/props';

import {
  EMPTY_FIELD,
  formatCommaSeparatedNumber,
  getInitials as utilsGetInitials,
  getNameOrDefault,
  getOrDefault,
  hasStringContent,
} from '@mighty-justice/utils';

import { IDetailCase } from '../models/Case';
import { doesContactNeedUpdate, IContact } from '../models/Contact';
import { IDetailLien } from '../models/Lien';
import { IAddress, ILegalOrganization } from '../models/Party';
import { LinkButton, Tooltip } from '../components/common';
import { IFacet, IFacetSet } from '../components/common-facets/interfaces';
import { IQuery, IQueryValue } from './navigationUtils';
import { InitialsAvatar } from '../components/common/Avatars';
import { IExternalKey } from '../models/ExternalKey';
import { IOption } from '../stores/OptionsStore';
import { facetSetsToFacets } from '../components/common-facets/facetUtils';

import {
  BOOLEAN_STRING,
  CASE_STATUS_UPDATE_DUE_DAYS,
  EXTERNAL_URL_PROPS,
  LOCAL_STORAGE,
  MAX_FILE_SIZE,
  ORGANIZATION_TYPES,
} from './constants';

const performanceMarkers: number[] = []
  , performanceMarkerNames: string[] = [];
// istanbul ignore next
export function printPerformance (name: string) {
  // To enable performance markets, run this in browser then refresh:
  // window.localStorage.setItem('print-performance', 'true');
  const shouldPrintPerformance = store.get(LOCAL_STORAGE.PRINT_PERFORMANCE);
  if (!shouldPrintPerformance) { return; }

  // Save name so we only profile first render
  if (performanceMarkerNames.includes(name)) { return; }
  performanceMarkerNames.push(name);

  performanceMarkers.push(window.performance.now());

  // eslint-disable-next-line no-magic-numbers
  const [prev, cur] = [0, ...performanceMarkers].slice(-2)
    , waitTime: number = Math.trunc(cur - prev)
    , total: number = Math.trunc(cur - performanceMarkers[0])

    // Colors output from blue to green to red
    // eslint-disable-next-line no-magic-numbers
    , color: string = `color: hsl(${Math.max(200 - (waitTime / 10), 0)},100%,40%)`
    ;

  // eslint-disable-next-line no-console
  console.log(`%c ${name}: ${total} (+${waitTime})`, color);
}

export function safeArrayAccess<T> (arr: T[], idx: number): null | T {
  return (idx < 0 || (idx > arr.length - 1)) ? null : arr[idx];
}

// Similar to _.mapValues spec,
// but included second optional filter function
export function mapValues<ValueIn, ValueOut> (
  objIn: { [key: string]: ValueIn },
  mapFn: (value: ValueIn, key: string) => ValueOut,
  filterFn?: (value: ValueIn, key: string) => boolean,
): { [key: string]: ValueOut } {
  return Object.entries(objIn)
    .reduce((objOut: { [key: string]: ValueOut }, [key, value]) => {
      if (!filterFn || filterFn(value, key)) {
        objOut[key] = mapFn(value, key);
      }
      return objOut;
    }, {});
}

export function overrideFieldSets (fieldSets: IFieldSetPartial[], overrides: { [key: string]: object }) {
  const overrideField = (fieldConfig: IFieldConfigPartial) => {
    const override = overrides[fieldConfig.field];
    return !override ? fieldConfig : merge(fieldConfig, override);
  };

  return fieldSets.map(fieldSet => mapFieldSetFields(fieldSet, overrideField));
}

export function unformatOrderingParams (params: string = '') {
  const trueSortingParam = params.split(',')[0]
    , desc = trueSortingParam[0] === '-'
    , id = desc ? trueSortingParam.slice(1) : trueSortingParam
    ;

  return { desc, id: id.split('__').join('.') };
}

export function formatSortingParams (ordering: { desc?: boolean, id?: string }) {
  if (!ordering || !ordering.id) { return ''; }
  const orderingString = ordering.id.split('.').join('__');
  return ordering.desc ? `-${orderingString}` : orderingString;
}

export function formatShortAddress (address?: IAddress | null) {
  if (!address) { return null; }

  return `${getOrDefault(address.city)}, ${getOrDefault(address.state)} ${address.zip_code} `;
}

export function renderExternalSystem (option: any) {
  return option.external_system_id;
}

export function renderOrganizationWithWebsite (option: ILegalOrganization) {
  const COL_LEFT = 3
    , COL_RIGHT = 21
    ;

  return (
    <div>
      <Row className='organization-wrapper'>
        <Col span={COL_LEFT}>
          <InitialsAvatar name={option.name} />
        </Col>
        <Col span={COL_RIGHT}>
          <b>{option.name}</b>
          <div>
            <small>
              {formatShortAddress(option.address)}
              {option.address && <span>&#183; </span>}
              {getOrDefault(option.website)}
            </small>
          </div>
        </Col>
      </Row>
    </div>
  );
}

export function renderSimpleOrganization (option: ILegalOrganization) {
  return (
    <div>
      <Row className='organization-wrapper'>
        <Col>
          <b>{option.name}</b>
          <div>
            <small>
              {formatShortAddress(option.address)}
              {option.address && <span>&#183; </span>}
              {getOrDefault(option.website)}
            </small>
          </div>
        </Col>
      </Row>
    </div>
  );
}

export function renderContactOption (option: IContact) {
  const needsUpdate = doesContactNeedUpdate(option)
    , contactDiv = <div title=''>{getNameOrDefault(option)}{needsUpdate && ' (deactivated)'}</div>
    ;

  return (
    <Tooltip
      children={contactDiv}
      placement='left'
      show={!!option.email}
      title={option.email}
    />
  );
}

export function formatOption (value: unknown, optionType: string) {
  return formatOptionSelect(value, { optionType } as any);
}

export function nullifyEmptyObject (submitObject: object) {
  const hasInformation = some(submitObject, (field: any) => !!field);

  return hasInformation ? submitObject : null;
}

export function getPrefixedName (model: object, prefix: string) {
  const nameFields = ['first_name', 'last_name', 'name']
    , prefixedModel: { [key: string]: string } = {};

  nameFields.map((field: string) => {
    const prefixedKey = `${prefix}${field}`
      , value = get(model, prefixedKey);

    if (isString(value)) {
      prefixedModel[field] = value;
    }
  });

  return getNameOrDefault({
    ...model,
    ...prefixedModel,
  });
}

export function getFilterValues (facetSets: IFacetSet[], query: IQuery): IQuery {
  const facets = facetSetsToFacets(facetSets);

  return mapValues(query, (facetValue, field) => {
    const filterString: string = `${facetValue}`
      , facetConfigEntry: IFacet | undefined = facets.find(facet => facet.field === field)
      , isMultiSelect: boolean = ['objectSearch', 'optionSelect'].includes(facetConfigEntry?.type || '')
      , isSingleValue: boolean = (
        !!facetConfigEntry?.editComponentProps
        && 'radio' in facetConfigEntry.editComponentProps
        && facetConfigEntry.editComponentProps.radio === true
      )
      , shouldSplit: boolean = isMultiSelect && !isSingleValue
      , filterValue: IQueryValue = shouldSplit ? filterString.split(',') : filterString
      ;

    return filterValue;
  });
}

export function isStringArray (value: unknown): value is string[] {
  return value && isArrayLike(value) && value.every(isString);
}

export function stringOrUndefined (value: unknown): string | undefined {
  return isString(value) ? value : undefined;
}

const REGEXP_UUID_V4 = new RegExp(/^[0-9A-F]{8}-?[0-9A-F]{4}-?[1345][0-9A-F]{3}-?[0-9A-F]{4}-?[0-9A-F]{12}$/i);

export function isUUID (value?: unknown): value is string {
  if (!value) { return false; }
  if (!isString(value)) { return false; }

  return !!value.match(REGEXP_UUID_V4);
}

// istanbul ignore next
export function setIframeWidth () {
  if (window.location !== window.parent.location) {
    try {
      document.getElementsByTagName('body')[0].style.minWidth = '0';
    }
    catch (error) {
      // eslint-disable-next-line no-console
      console.error('Issue setting iframe width', error);
    }
  }
}

export function getInitials (value?: string | null): string {
  if (!hasStringContent(value)) { return ''; }

  const LOW_INFORMATION_ABBREVIATIONS = ['LLC', 'LLP', 'PLLC', 'PA', 'PC']
    , cleanedValue = value

      // Remove "Law Firm" and periods
      .replace(/(law firm|\.)/ig, '')

      // Remove common abbreviations that don't add value to initials
      .split(' ')
      .filter(s => !LOW_INFORMATION_ABBREVIATIONS.includes(s))
      .join('')
      ;

  return utilsGetInitials(cleanedValue);
}

export function getPagesForRange (pageSize: number, startIndex: number, stopIndex: number): number[] {
  const begin = Math.floor(startIndex / pageSize) + 1
    , end = Math.floor(stopIndex / pageSize) + 2;

  return range(begin, end);
}

function _isUpdateOlderThan (numDays: number, lastUpdate?: string | null): boolean {
  return !lastUpdate || differenceInCalendarDays(new Date(), parseISO(lastUpdate)) >= numDays;
}

export function isStatusOutOfDate (lastUpdate?: string | null): boolean {
  return _isUpdateOlderThan(CASE_STATUS_UPDATE_DUE_DAYS, lastUpdate);
}

export function joinJsx (array: React.ReactNode[], separator: React.ReactNode = ', '): React.ReactNode {
  const end = array.length - 1;
  return array.map((elem, idx) => (
    <React.Fragment key={idx}>{elem}{idx < end && separator}</React.Fragment>
  ));
}

function _getOxfordComma (idx: number, arrLen: number): string | null {
  const useOxfordComma = arrLen > 2
    , isNotLast = idx < arrLen - 1;

  return isNotLast && useOxfordComma ? ', ' : null;
}

function _getOxfordWord (idx: number, arrLen: number, conjunction: string): string | null {
  const useOxfordComma = arrLen > 2
    , isSecondLast = idx === arrLen - 2;

  return isSecondLast ? `${useOxfordComma ? '' : ' '}${conjunction} ` : null;
}

export function joinOxfordStrings (array: string[], conjunction = 'and'): string {
  return array.map((elem, idx) => {
    const comma = _getOxfordComma(idx, array.length)
      , word = _getOxfordWord(idx, array.length, conjunction);

    return `${elem}${comma || ''}${word || ''}`;
  }).join('');
}

export function joinOxford (array: React.ReactNode[], conjunction = 'and'): React.ReactNode {
  return array.map((elem, idx) => {
    const comma = _getOxfordComma(idx, array.length)
      , word = _getOxfordWord(idx, array.length, conjunction);

    return (<React.Fragment key={idx}>{elem}{comma}{word}</React.Fragment>);
  });
}

export function applyBold (item: React.ReactNode, key: string | number): React.ReactNode {
  return <b key={key}>{item}</b>;
}

export function hasValueChanged (model: any, data: any, field: string): boolean {
  const newValue = get(data, field)
    , oldValue = get(model, field);
  return newValue && (newValue !== oldValue);
}

export function setValueFromData (model: any, data: any, field: string) {
  const newValue = get(data, field);
  set(model, field, newValue);
}

export function setValuesFromData (model: any, data: any, _fields?: string[]) {
  const fields = _fields || Object.keys(data);
  fields.forEach(field => setValueFromData(model, data, field));
}

interface ISortValue {
  [key: string]: unknown;
}

export function defaultSorter (field: string) {
  return function (a: ISortValue, b: ISortValue) {
    const prev = a[field]
      , next = b[field];

    if (isString(prev) && isString(next)) {
      return prev.localeCompare(next);
    }

    if (isNumber(prev) && isNumber(next)) {
      return Number(prev) - Number(next);
    }

    // Nulls first, similar to localeCompare
    return Number(isNil(next)) - Number(isNil(prev));
  };
}

export function validateBirthdateOrDOL (data: IModel) {
  if (!(data.plaintiff.birthdate || data.date_of_loss)) {
    throw {
      response: {
        data: {
          missing_field_errors: ['Either Birthdate or Date of Loss is required'],
        },
        status: 400,
      },
    };
  }
}

export function validateLawFirmWebsiteOrAddress (data: IModel) {
  if (!data.law_firm.id &&
    !(data.law_firm.website ||
      (data.law_firm.address && data.law_firm.address.city && data.law_firm.address.state))) {
    throw {
      response: {
        data: {
          missing_field_errors: ['Either website or both city and state is required for law firm'],
        },
        status: 400,
      },
    };
  }
}

export function renderOnOff (option: boolean) {
  return option ? 'On' : 'Off';
}

export function booleanToString (value: boolean) {
  return value ? BOOLEAN_STRING.TRUE : BOOLEAN_STRING.FALSE;
}

export function mapKeyToValue (object: { [key: string]: { name: string } }): { [key: string]: IOption } {
  return mapValues(object, (value, key) => ({ ...value, value: key }));
}

export function mapValueToKeyAndValue (array: string[]): object {
  return Object.assign({}, ...array.map((key: string) => ({ [key]: key })));
}

export function fixDoubleModalScrollBug (): void {
  // Fixes bug that happens when closing a modal-in-a-drawer combo
  // https://github.com/ant-design/ant-design/issues/21539
  document.body.style.removeProperty('overflow');
}

export const LoginLink = () => <LinkButton href='/login'>Go to login</LinkButton>;

export function showError (error: Error, extraMessage?: string) {
  const errorText = extraMessage ? ` ${extraMessage}` : '';

  notification.error({
    description: get(error, 'response.data[0]') || `An error occurred${errorText}.` +
      'Please refresh your tab and try again',
    message: 'Error',
  });
}

export function renderExternalKeys (externalKeys: IExternalKey[]) {
  // istanbul ignore next
  if (!externalKeys || !externalKeys.length) {
    return EMPTY_FIELD;
  }

  return (
    <>
      {externalKeys.map((externalKey: IExternalKey) =>
        <Row key={externalKey.id}>
          {externalKey.registry_legal_organization_external_system.external_system.external_system_id}{' '}
          - {externalKey.registry_external_id}
        </Row>)
      }
    </>
  );
}

export function getLegalOrganizationDropdownNames (
  legalOrganizations: ILegalOrganization[]
): { [id: string]: string } {
  const namesMap: { [id: string]: {count: number, types: Set<string>} } = {};
  legalOrganizations.forEach((org: ILegalOrganization) => {
    const name = org.name;
    if (!namesMap[name]) {
      namesMap[name] = { count: 0, types: new Set() };
    }
    namesMap[name]['types'].add(org.type as string);
    namesMap[name]['count'] += 1;
  });
  const duplicates = Object.keys(pickBy(namesMap, value => value['count'] > 1 && value['types'].size === 2));

  const result: { [id: string]: string } = {};
  legalOrganizations.forEach((org: ILegalOrganization) => {
    if (legalOrganizations.length === 2 && duplicates.length === 1 && duplicates.includes(org.name)) {
      const name = org.type === ORGANIZATION_TYPES.LAW_FIRM ? 'My Firm\'s Cases' : 'Cases referred out';
      result[org.id] = name;
    }
    else {
      result[org.id] = org.name;
    }
  });
  return result;
}

// for handling backend errors on form submits that aren't understandable to users
// e.g. violations of unique constraints
export async function getHumanReadableError (request: Promise<any>, errorIdentifier: string, readableError: string) {
  try {
    return await request;
  }
  catch (error) {
    const errorData = get(error, 'response.data');

    if (errorData && isString(errorData) && errorData.includes(errorIdentifier)) {
      notification.error({
        description: readableError,
        duration: null,
        message: 'Error',
      });

      throw { ...error, response: { data: {}, status: 400 } };
    }

    throw error;
  }
}

export function getAssociatedOrganizations (_case: IDetailCase): { [key: string]: string}[] {
  const { law_firm, liens } = _case
    , lienholders = liens?.map((lien: IDetailLien) => (
      { id: lien.lienholder.id, name: lien.lienholder.name }
    )) || []
    , lawfirm = law_firm ? [{ id: law_firm.id, name: law_firm.name }] : [];

  return [...lienholders, ...lawfirm];
}

export function renderAddressAndWebsite (organization: ILegalOrganization) {
  const { address, website } = organization;

  if (!address && !website) {
    return null;
  }

  return (
    <>
      <div>{address?.address1}</div>
      <div>{address?.address2}</div>
      <div>{address?.city && `${address.city}, `}{address?.state && `${address.state} `}{address?.zip_code}</div>
      {website && <a {...EXTERNAL_URL_PROPS} href={website}>{website}</a>}
    </>
  );
}

// wraps a beforeUpload function to ensure no files bigger than MAX_FILE_SIZE are uploaded
export function restrictDocumentSize (beforeUpload: UploadProps['beforeUpload']) {
  // istanbul ignore next
  if (!beforeUpload) { return beforeUpload; }

  return async function (currentFile: RcFile, fileList: RcFile[]) {
    const hasLargeFile = fileList.filter(file => file.size > MAX_FILE_SIZE.value);

    if (!hasLargeFile.length) {
      await beforeUpload(currentFile, fileList);
    }
    // only show error once per large file uploaded
    else if (currentFile.size > MAX_FILE_SIZE.value) {
      // eslint-disable-next-line no-magic-numbers
      const sizeMb: string = formatCommaSeparatedNumber(currentFile.size / (1024 ** 2));

      notification.error({
        message: 'File Size Limit Reached',
        description: `No files larger than ${MAX_FILE_SIZE.label} may be uploaded. Selected file size: ${sizeMb}mb.`,
      });
    }
  };
}

export function calculateAgeFromDob (dob: string, format: string = 'yyyy-MM-dd') {
  return differenceInCalendarYears(new Date(), parse(dob, format, new Date()));
}

export async function calculateFileMd5 (file: File) {
  const arrayBuffer = await file.arrayBuffer();
  const md5HexString = await md5(new Uint8Array(arrayBuffer));

  let md5BinaryString = '';
  for (let i = 0; i < md5HexString.length; i += 2) {
    md5BinaryString += String.fromCharCode(parseInt(md5HexString.substr(i, 2), 16));
  }

  return btoa(md5BinaryString);
}
