import React from 'react';

import { Col, Row } from 'antd';

import { IDocument } from '../../models/Document';
import { EXTERNAL_URL_PROPS } from '../../utils/constants';

import { Icon, IconButton } from '../common';

interface IProps {
  release?: IDocument;
}

const RequestResponseRelease = (props: IProps) => {
  if (!props.release) { return null; }

  return (
    <Row>
      <Col span={12} offset={6}>
        <span>HIPAA Release:</span>
        <div style={{ paddingLeft: '40px' }}>
          <a href={`${props.release.download_url}&dl=0`} {...EXTERNAL_URL_PROPS}>{props.release.name}</a>
          {'  '}
          <IconButton icon={Icon.Download} href={props.release.download_url} />
        </div>
      </Col>
    </Row>
  );
};

export default RequestResponseRelease;
