import React, { Component } from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import autoBindMethods from 'class-autobind-decorator';

import { Row } from 'antd';

import SmartBool from '@mighty-justice/smart-bool';
import { formatDateTime } from '@mighty-justice/utils';

import { IDetailCase } from '../../../../models/Case';
import { ICaseThread, ICaseMessage } from '../../../../models/CaseThread';
import { IDetailLien } from '../../../../models/Lien';
import { CASE_THREAD_TYPES } from '../../../../utils/constants';
import { Button, Icon, LoadingBlock } from '../../../common';
import InfoCard from '../common/InfoCard';
import DocumentRequestReplyOptions from './threads/DocumentRequestReplyOptions';

import styles from './InboxTab.module.less';

interface IProps {
  _case: IDetailCase;
  lien?: IDetailLien;
  selectThread: (thread: ICaseThread | null) => void;
  thread: ICaseThread;
  title: string | null;
}

@autoBindMethods
@observer
class InboxThreadPage extends Component<IProps> {
  @observable private isLoading = new SmartBool();

  public async componentDidMount () {
    const { thread } = this.props;

    this.isLoading.until(thread.fetchAllMessages());
  }

  private goBack () {
    this.props.selectThread(null);
  }

  public render () {
    const { _case, lien, title, thread } = this.props
      , isDocumentRequest = thread.type === CASE_THREAD_TYPES.DOCUMENT_REQUEST
      ;

    if (this.isLoading.isTrue) {
      return (
        <div>
          <Row className={styles['thread-header']} justify='start'>
            <Button id='back-button' disabled>
              <Icon.ArrowLeft />Back
            </Button>
            <p>{title}</p>
          </Row>
          <Row><LoadingBlock /></Row>
        </div>
      );
    }

    return (
      <div>
        <Row className={styles['thread-header']} justify='start'>
          <Button id='back-button' onClick={this.goBack}>
            <Icon.ArrowLeft />Back
          </Button>
          <p>{title}</p>
        </Row>
        <Row className={styles['thread-content']}>
          <Row>
            {thread.messages.map((message: ICaseMessage) => (
              <Row key={message.id} className={styles['thread-response-row']}>
                <InfoCard
                  title={message.created_by_organization_name}
                  subtitle={message.note}
                  bottomRightRender={null}
                  content={null}
                  topRightContent={formatDateTime(message.created_at)}
                />
              </Row>
            ))}
          </Row>
          <Row>
            {isDocumentRequest && (
              <DocumentRequestReplyOptions thread={thread} _case={_case} lien={lien} goBack={this.goBack} />
            )}
          </Row>
        </Row>
      </div>
    );
  }
}

export default InboxThreadPage;
