import React, { Component } from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import autoBindMethods from 'class-autobind-decorator';

import SmartBool from '@mighty-justice/smart-bool';

import { IDetailLien } from '../../../../models/Lien';

import LienBalanceListItem from './LienBalanceListItem';
import UpdateLienBalanceModal from './UpdateLienBalanceModal';

interface IProps {
  liens: IDetailLien[];
}

@autoBindMethods
@observer
class LienBalanceList extends Component<IProps> {
  @observable isUpdatingLienBalance = new SmartBool();
  @observable selectedLien?: IDetailLien;

  private onLienSelect (lien: IDetailLien): void {
    this.isUpdatingLienBalance.setTrue();
    this.selectedLien = lien;
  }

  public render () {
    const { liens } = this.props;
    return (
      <div>
        {liens.map(lien => <LienBalanceListItem key={lien.id} lien={lien} onLienSelect={this.onLienSelect} />)}
        <UpdateLienBalanceModal isUpdatingLienBalance={this.isUpdatingLienBalance} lien={this.selectedLien} />
      </div>
    );
  }
}

export default LienBalanceList;
