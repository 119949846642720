import { Instance, types } from 'mobx-state-tree';

import { ExternalKey } from './ExternalKey';

export const LienExternalKey = types.compose(
  ExternalKey,
  types.model('LienExternalKey', {
    lien: types.string,
  }),
);

export interface ILienExternalKey extends Instance<typeof LienExternalKey> {}
