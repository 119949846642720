import React, { Component } from 'react';
import { observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import autoBindMethods from 'class-autobind-decorator';

import { Dropdown, Menu } from 'antd';

import SmartBool from '@mighty-justice/smart-bool';

import { IDocumentFilterData } from '../../../../../interfaces';
import { IDetailCase } from '../../../../../models/Case';
import StoresClass from '../../../../../stores/StoresClass';
import { MAX_ZIP_FILE_COUNT } from '../../../../../utils/constants';
import { Button, Icon, Tooltip } from '../../../../common';
import { ANT_BTN_PREFIX_CLS } from '../../../../common/Buttons';

import AddDocumentButton from '../common/AddDocumentButton';
import { IBetaDocumentSubmitData } from '../interfaces';
import DocumentRequestModal from './DocumentRequestModal';

import styles from './DocumentsHeaderActions.module.less';

interface IProps extends IDocumentFilterData {
  _case: IDetailCase;
}

interface IInjected extends IProps {
  stores: StoresClass;
}

@inject('stores')
@autoBindMethods
@observer
class DocumentsHeaderActions extends Component<IProps> {
  @observable private isDocumentRequestModalOpen = new SmartBool();

  private get injected () {
    return this.props as IInjected;
  }

  private get menu () {
    const { _case } = this.props;

    return (
      <Menu className={styles['documents-header-action-dropdown']}>
        <Menu.Item key='upload' id='upload'>
          <AddDocumentButton
            _case={_case}
            buttonProps={{ className: `${ANT_BTN_PREFIX_CLS}-empty` }}
            buttonText='Document Upload'
            icon={Icon.CloudUploadAlt}
            iconPosition='front'
            uploadDocuments={this.uploadDocuments}
          />
        </Menu.Item>
        <Menu.Item key='request' id='request' onClick={this.isDocumentRequestModalOpen.setTrue}>
          <Icon.FileAltLight />Document Request
        </Menu.Item>
      </Menu>
    );
  }

  private async uploadDocuments (documents: IBetaDocumentSubmitData[]) {
    const { _case, filters, isDocumentsLoading } = this.props;

    await isDocumentsLoading.until(_case.bulkUploadDocuments(documents, filters));
  }

  private async handleDocumentsViewed () {
    const { _case } = this.props;

    _case.createViewLienDocumentActivities(_case.documents);
  }

  public render () {
    const { _case } = this.props
      , { download_all_documents_count, download_all_documents_url } = _case
      , { users: { isLawFirmUser } } = this.injected.stores
      , exceedsMaxDocuments: boolean = !!download_all_documents_count &&
        download_all_documents_count > MAX_ZIP_FILE_COUNT
      ;

    return (
      <>
        <div className={styles.actions}>
          {isLawFirmUser
            ? (
              <>
                <Tooltip
                  arrowPointAtCenter
                  show={exceedsMaxDocuments}
                  title={`A maximum of ${MAX_ZIP_FILE_COUNT} documents can be downloaded at one time`}
                  trigger='hover'
                >
                  <div>
                    <Button
                      className={styles['download-all']}
                      disabled={exceedsMaxDocuments}
                      href={download_all_documents_url || ''}
                      id='download-all'
                      onClick={this.handleDocumentsViewed}
                      loading={download_all_documents_count === null}
                    >
                      Download All {!!download_all_documents_count && `(${download_all_documents_count})`}
                      <Icon.CloudDownload className={styles['download-all-icon']} />
                    </Button>
                  </div>
                </Tooltip>
                <Dropdown
                  overlay={this.menu}
                  trigger={['click']}
                >
                  <Button className={styles['button-new']} type='primary' id='button-new'>
                    New<Icon.CaretDownSolid />
                  </Button>
                </Dropdown>
              </>
            )
            : (
              <AddDocumentButton
                _case={_case}
                buttonProps={{
                  className: styles['button-upload-document'],
                  id: 'button-upload-document',
                  type: 'primary',
                }}
                buttonText='Upload Documents'
                uploadDocuments={this.uploadDocuments}
              />
            )
          }
        </div>
        <DocumentRequestModal
          _case={_case}
          isVisible={this.isDocumentRequestModalOpen}
        />
      </>
    );
  }
}

export default DocumentsHeaderActions;
