import React, { Component } from 'react';
import { observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import autoBindMethods from 'class-autobind-decorator';

import SmartBool from '@mighty-justice/smart-bool';
import { Form } from '@mighty-justice/fields-ant';
import { IModel } from '@mighty-justice/fields-ant/dist/props';

import Client from '../../base-modules/client';
import LoggedOutLandingPage from '../LoggedOutLandingPage';
import { Environment } from '../../base-modules/environment';
import { Spacer } from '../common';

interface IInjected {
  client: Client;
  environment: Environment;
}

@inject('client', 'environment')
@autoBindMethods
@observer
class PasswordResetRequestPage extends Component {
  @observable private isSubmitted = new SmartBool();

  private get injected () { return this.props as IInjected; }

  private async handleSubmit (model: IModel) {
    const { client, environment: { apiHost } } = this.injected;
    await client.create(`https://${apiHost}/api/v1/auth/password/reset/`, model);
  }

  private renderBody () {
    if (this.isSubmitted.isTrue) {
      return (
        <>
          <Spacer/>
          <h2>Email sent!</h2>
          <p>
            Check your inbox to reset your password.
            If an account associated with that email address exists,
            an email will be sent with further instructions.
          </p>
        </>
      );
    }

    return (
      <>
        <p>Please enter the email address you use to sign in and a password reset link will be sent to you.</p>
        <Form
          blockSubmit
          fieldSets={[[{ field: 'email', required: true }]]}
          onSave={this.handleSubmit}
          onSuccess={this.isSubmitted.setTrue}
          saveText='Request reset link'
          successText={null}
        />
      </>
    );
  }

  public render () {
    return (
      <LoggedOutLandingPage title='Request Password Reset'>
        {this.renderBody()}
      </LoggedOutLandingPage>
    );
  }
}

export default PasswordResetRequestPage;
