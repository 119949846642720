import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { autoBindMethods } from 'class-autobind-decorator';

import SmartBool from '@mighty-justice/smart-bool';

import { Col, Row } from 'antd';

import StoresClass from '../../stores/StoresClass';
import { ANT_QUARTER_COL_WIDTH, ANT_THREE_QUARTER_COL_WIDTH } from '../../utils/constants';
import { IInfiniteScrollProps } from '../../utils/InfiniteScroll';
import { INavigateProps } from '../../utils/navigationUtils';
import navigationWrapper from '../../utils/navigationWrapper';

import PortfolioDetail from './portfolio-detail/PortfolioDetail';
import PortfolioList from './portfolio-list/PortfolioList';

import styles from './PortfolioPage.module.less';

export interface IPortfolioContentProps extends IInfiniteScrollProps {
  isDrawerAddCaseVisible: SmartBool;
  queryParamsSet: SmartBool;
}
interface IInjected extends IPortfolioContentProps, INavigateProps {
  stores: StoresClass;
}

@inject('stores')
@autoBindMethods
@observer
class PortfolioContent extends Component<IPortfolioContentProps & INavigateProps> {
  private get injected () { return this.props as IInjected; }

  public async componentDidMount () {
    const { navigate, params: { id } } = this.props
      , { stores: { infiniteTableStore } } = this.injected
      ;

    if (id === 'null' && infiniteTableStore.firstCase) {
      navigate(infiniteTableStore.firstCaseUrl);
    }
    else {
      await infiniteTableStore.setSelectedKeyFromUrlParam(id);
    }
  }

  public render () {
    const {
      isDrawerAddCaseVisible,
      infiniteScrollHeight,
      queryParamsSet,
      renderDetailRef,
      setScrollHeight,
    } = this.props;

    return (
      <Row align='top' justify='space-between'>
        <Col span={ANT_QUARTER_COL_WIDTH} className={styles['col-scroll']}>
          <PortfolioList infiniteScrollHeight={infiniteScrollHeight} queryParamsSet={queryParamsSet} />
        </Col>
        <Col span={ANT_THREE_QUARTER_COL_WIDTH} className={styles['col-detail']}>
          <PortfolioDetail
            isDrawerAddCaseVisible={isDrawerAddCaseVisible}
            queryParamsSet={queryParamsSet}
            renderDetailRef={renderDetailRef}
            setScrollHeight={setScrollHeight}
          />
        </Col>
      </Row>
    );
  }
}

export default navigationWrapper(PortfolioContent);
