import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import autoBindMethods from 'class-autobind-decorator';
import { get } from 'lodash';

import { Col, Row } from 'antd';

import {
  fillInFieldConfig,
  IFieldSetPartial,
  Table,
} from '@mighty-justice/fields-ant';
import { IModel } from '@mighty-justice/fields-ant/dist/props';

import {
  Card as AntdCard,
  ModalButton,
  Spacer,
} from '../../common';

import StoresClass from '../../../stores/StoresClass';
import { OPTION_KEYS } from '../../../utils/constants';
import { renderExternalSystem } from '../../../utils/utils';
import { ENDPOINTS, ISettingTabProps } from '../settingsUtils';

interface IInjected extends ISettingTabProps {
  stores: StoresClass;
}

@inject('stores')
@autoBindMethods
@observer
class ExternalSystemsCard extends Component<ISettingTabProps> {
  private get injected () {
    return this.props as IInjected;
  }

  private get isLoading () {
    return this.injected.isLoading.isTrue;
  }

  private get createFieldSets (): IFieldSetPartial[] {
    const { stores } = this.injected;

    return [[
      {
        createFields: [
          {
            field: 'external_system_id',
            label: 'External System ID',
            populateFromSearch: true,
          },
          {
            field: 'external_system_type',
            optionType: OPTION_KEYS.EXTERNAL_SYSTEM_TYPE,
            required: true,
            type: 'optionSelect',
          },
        ].map(fillInFieldConfig),
        endpoint: ENDPOINTS.EXTERNAL_SYSTEMS,
        field: 'external_system',
        label: 'External System ID',
        renderOption: renderExternalSystem,
        renderSelected: renderExternalSystem,
        type: 'objectSearchCreate',
      },
      {
        field: 'registry_legal_organization',
        type: 'hidden',
        value: get(stores.users, 'account.registry_legal_organization.id'),
      },
    ]];
  }

  private get tableFieldSets (): IFieldSetPartial[] {
    return [[
      {
        field: 'external_system.external_system_id',
        label: 'External System ID',
      },
      {
        field: 'external_system.external_system_type',
        optionType: 'external_system_type',
        type: 'optionSelect',
      },
    ]];
  }

  private async submitNew (model: IModel) {
    const { stores } = this.injected;
    await stores.externalSystems.create(model);
  }

  public render () {
    const { stores, refresh } = this.injected
      , externalSystems = stores.externalSystems.allSystems
      ;

    return (
      <AntdCard>
        <Row align='middle' justify='space-between'>
          <Col><h2 style={{ margin: 0 }}>External Systems</h2></Col>
          <Col>
            <ModalButton
              buttonProps={{
                disabled: this.isLoading,
                type: 'primary',
              }}
              buttonText='Add External System'
              passThroughProps={{
                fieldSets: this.createFieldSets,
                onSave: this.submitNew,
                onSuccess: refresh,
                title: 'Add External System',
              }}
            />
          </Col>
        </Row>
        <Spacer />
        <Table
          className='ant-table-simple'
          fieldSets={this.tableFieldSets}
          isLoading={this.isLoading}
          model={externalSystems}
        />
      </AntdCard>
    );
  }
}

export default ExternalSystemsCard;
