import { observable } from 'mobx';
import store from 'store';

import { ICurrentService, IUser, IAccount, User } from '../models/User';
import { ILegalOrganization } from '../models/Party';
import { LOCAL_STORAGE, ORGANIZATION_TYPES } from '../utils/constants';
import { IStateTreeDependencies } from '../base-modules/state-tree-dependencies';
import { getQueryFromURL, IRedirectTo, IURL, replaceUrlQuery } from '../utils/navigationUtils';


class UserStore {
  @observable private _user?: IUser;

  private getDependencies: () => IStateTreeDependencies;

  public constructor (getDependencies: () => IStateTreeDependencies) {
    this.getDependencies = getDependencies;
  }

  public removeUser () {
    this._user = undefined;
  }

  public get user (): undefined | IUser {
    if (!this._user) {
      const storedUser = store.get(LOCAL_STORAGE.USER);
      if (storedUser) {
        try {
          // TODO: Change this to stores.dependencies
          this.user = User.create(storedUser, this.getDependencies());
        }
        catch (e) {
          // eslint-disable-next-line no-console
          console.error(e);
        }
      }
    }

    return this._user;
  }

  public set user (value: undefined | IUser) {
    this._user = value;
  }

  public get accounts (): IAccount[] | null {
    const user = this.user;
    return user ? user.registry_accounts : null;
  }

  public changeAccount (accountId: string) {
    store.set(LOCAL_STORAGE.ACCOUNT_ID, accountId);
  }

  public get account (): IAccount | null {
    const accounts: IAccount[] | null = this.accounts;

    if (!accounts || !accounts.length) { return null; }

    const currentId = store.get(LOCAL_STORAGE.ACCOUNT_ID)
      , account: IAccount = accounts.find(a => a.id === currentId) || accounts[0];

    if (currentId !== account.id) {
      this.changeAccount(account.id);
    }

    return account;
  }

  public setInitialAccountFromURL (url: IURL): IRedirectTo {
    const { account_organization_id, ...query } = getQueryFromURL(url)
      , accountFromParam: IAccount | undefined = this.accounts?.find((account: IAccount) => (
        account?.registry_legal_organization?.id === account_organization_id)
      )
      ;

    if (accountFromParam) {
      this.changeAccount(accountFromParam.id);
      return replaceUrlQuery(url, query);
    }

    return null;
  }

  public get accountStage (): string | null {
    return this.account?.currentStage || null;
  }

  public get registryLegalOrganization (): ILegalOrganization | null {
    return this.account?.registry_legal_organization || null;
  }

  public get orgType (): string | null {
    return this.registryLegalOrganization?.type || null;
  }

  public get hasTags (): boolean {
    return !!this.registryLegalOrganization?.has_tags;
  }

  public get sharesDocumentsManually (): boolean {
    return !!this.registryLegalOrganization?.shares_documents_manually;
  }

  public get isLoggedIn (): boolean {
    return !!this.orgType;
  }

  public get isLawFirmUser (): boolean {
    return this.orgType === ORGANIZATION_TYPES.LAW_FIRM;
  }

  public get baseEndpoint () {
    return this.isLawFirmUser ? 'escrow-agent' : 'lienholder';
  }

  public get currentService (): null | ICurrentService {
    return this.account?.current_service || null;
  }

  public get isStaff (): boolean {
    return !!this.user?.is_staff;
  }

  public get isNewCaseEmailEnabled (): boolean {
    return !!this.account?.registry_legal_organization?.is_new_case_email_enabled;
  }

  public get isRewardsParticipant (): boolean {
    return !!this.account?.registry_legal_organization?.is_rewards_participant;
  }

  public get isLawFirmUploadedDocumentEnabled (): boolean {
    return !!this.account?.registry_legal_organization?.lienholder_document_upload_notifications_enabled;
  }

  public get hasMultipleAccounts (): boolean {
    return this.accounts ? this.accounts.length > 1 : false;
  }

  public hasFeature (feature: string): boolean {
    const currentService = this.currentService;
    return currentService ? currentService.features.includes(feature) : false;
  }
}

export default UserStore;
