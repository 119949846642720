import React from 'react';
import { inject } from 'mobx-react';

import { EXTERNAL_URL_PROPS } from '../../utils/constants';
import StoresClass from '../../stores/StoresClass';

interface IProps {
  stores?: StoresClass;
}

function TermsOfServiceLink (props: IProps) {
  const termsOfService = props.stores?.linkCopyItems.termsOfService;

  return <a href={termsOfService?.link} {...EXTERNAL_URL_PROPS}>{termsOfService?.copy}</a>;
}

const InjectedSection = inject('stores')(TermsOfServiceLink);
export default InjectedSection;
