import React from 'react';
import cx from 'classnames';

import { Empty } from 'antd';

import Spacer from './Spacer';
import Icon, { IIcon } from './Icon';

import styles from './EmptyBlock.module.less';

interface IProps {
  bordered?: boolean;
  children?: React.ReactNode;
  description?: React.ReactNode;
  fullMargin?: boolean;
  icon?: IIcon;
  iconNode?: React.ReactNode;
  padded?: boolean;
}

function getClassName (props: IProps): string {
  return cx(styles.root, {
    [styles.bordered]: props.bordered === undefined ? true : props.bordered,
    [styles.fullMargin]: props.fullMargin === undefined ? false : props.fullMargin,
    [styles.padded]: props.padded === undefined ? true : props.padded,
  });
}

function getIcon (props: IProps): React.ReactNode {
  if (props.iconNode) { return props.iconNode; }
  if (props.icon) { return <props.icon size='3x' />; }
  return <Icon.FolderOpenLight size='3x' />;
}

export const EmptyBlock = (props: IProps) => {
  const className = getClassName(props)
    , description = <span className={styles.description}>{props.description}</span>
    ;

  return (
    <div className={className}>
      <Empty
        description={description}
        image={getIcon(props)}
      />
      {props.children && (
        <>
          <Spacer />
          {props.children}
        </>
      )}
    </div>
  );
};

export const LoadingBlock = (props: IProps) => (
  <EmptyBlock
    description='Loading...'
    iconNode={<Icon.SpinnerThird primary spin size='2x' />}
    {...props}
  />
);

export const ErrorBlock = (props: IProps) => (
  <EmptyBlock
    iconNode={<Icon.ExclamationCircleLight type='danger' size='2x' />}
    {...props}
  />
);

