import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import autoBindMethods from 'class-autobind-decorator';
import cx from 'classnames';

import { formatDateTime } from '@mighty-justice/utils';

import StoresClass from '../../../../../stores/StoresClass';
import UploadWrapper from '../../../../common/UploadWrapper';
import { IBetaDocument } from '../../../../../models/Document';
import { IDetailCase } from '../../../../../models/Case';
import { IDocumentFilterData } from '../../../../../interfaces';
import { EXTERNAL_URL_PROPS, OPTION_KEYS } from '../../../../../utils/constants';
import { Icon, Section, Table } from '../../../../common';
import ModalLink from '../../../../common/ModalLink';
import { defaultSorter, formatOption } from '../../../../../utils/utils';
import { fillInColumnProfile } from '../../../../../utils/columnCommon';
import { formatLastOpenedBy } from '../utils';

import DocumentTableActions from './DocumentTableActions';
import DocumentsTableDragOverlay from './DocumentsTableDragOverlay';
import EmptyTableSection from './EmptyTableSection';
import LawFirmAccessDocumentModal from './LawFirmAccessDocumentModal';

import styles from './DocumentsTable.module.less';

interface IProps extends IDocumentFilterData {
  _case: IDetailCase;
}

interface IInjected extends IProps {
  stores: StoresClass;
}

@inject('stores')
@autoBindMethods
@observer
class DocumentsTable extends Component<IProps> {
  private get injected () {
    return this.props as IInjected;
  }

  private handleDocumentView (document: IBetaDocument) {
    const { _case } = this.props;

    _case.createViewLienDocumentActivities([document]);
  }

  private renderDocumentName (document: IBetaDocument) {
    const sharedProps = { className: 'dd-privacy-mask', id: 'open' }
      , linkText = <>{document.name} <Icon.ExternalLink className={styles.icon} /></>
      ;

    if (document.needsPayment) {
      return (
        <ModalLink
          linkProps={sharedProps}
          linkText={linkText}
          ModalClass={LawFirmAccessDocumentModal}
          passThroughProps={{ document }}
        />
      );
    }

    return (
      <a
        {...sharedProps}
        {...EXTERNAL_URL_PROPS}
        href={`${document.download_url}&dl=0`}
        onClick={this.handleDocumentView.bind(this, document)}
      >
        {linkText}
      </a>
    );
  }

  private get columns () {
    const { _case, ...passDownProps } = this.props
      , { isLawFirmUser } = this.injected.stores.users
      , baseColumns = [
        {
          className: styles.name,
          field: 'name',
          // Need to add render function here so document table names are observable
          render: (_value: any, document: IBetaDocument) => this.renderDocumentName(document),
          showSorterTooltip: false,
          sorter: defaultSorter('name'),
        },
        {
          field: 'type',
          optionType: OPTION_KEYS.DOCUMENT_TYPES,
          render: (_value: any, document: IBetaDocument) => formatOption(document.type, OPTION_KEYS.DOCUMENT_TYPES),
          showSorterTooltip: false,
          sorter: defaultSorter('type'),
          type: 'optionSelect',
        },
        {
          field: 'uploadedBy',
          showSorterTooltip: false,
          sorter: defaultSorter('uploadedBy'),
        },
        {
          field: 'created_at',
          render: (_value: any, document: IBetaDocument) => formatDateTime(document.created_at),
          showSorterTooltip: false,
          sorter: defaultSorter('created_at'),
          title: 'Date Uploaded',
        },
        {
          field: 'last_opened_by',
          render: (_value: any, document: IBetaDocument) => formatLastOpenedBy(_case, document),
        },
        {
          className: styles['actions-column'],
          dataIndex: 'actions',
          field: '',
          render: (_value: any, document: IBetaDocument) => (
            <DocumentTableActions _case={_case} document={document} {...passDownProps} />
          ),
        },
      ]
      , columns = isLawFirmUser ? baseColumns.filter(item => item.field !== 'last_opened_by') : baseColumns;

    return columns.map(fillInColumnProfile);
  }

  public render () {
    const { _case } = this.props
      , tableProps = {
        className: styles.table,
        columns: this.columns,
        dataSource: _case.documents.slice(),
        rowClassName: (record: any) => (record.needsPayment && styles.disabled),
        rowKey: 'id',
      };

    if (!_case.documents.length) {
      return (
        <EmptyTableSection _case={_case} />
      );
    }

    return (
      <Section className={cx(styles.root, styles.override)}>
        <UploadWrapper
          _case={_case}
          canClick={false}
          canDrag
          uploadDocuments={_case.bulkUploadDocuments}
        >
          <DocumentsTableDragOverlay />
          <Table {...tableProps} bordered={false} pagination={false} />
        </UploadWrapper>
      </Section>
    );
  }
}

export default DocumentsTable;
