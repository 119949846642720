import React, { Component } from 'react';
import { observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import autoBindMethods from 'class-autobind-decorator';

import { Popover } from 'antd';

import { Form } from '@mighty-justice/fields-ant';
import SmartBool from '@mighty-justice/smart-bool';

import StoresClass from '../../../../stores/StoresClass';
import { LinkButton } from '../../../common';
import { DEDUPE_STATUS } from '../../../../utils/constants';

import Client from '../../../../base-modules/client';

interface IProps {
  match: any;
}

interface IInjected extends IProps {
  client: Client;
  stores: StoresClass;
}

@inject('client', 'stores')
@autoBindMethods
@observer
class VerificationNeedsMoreInformationButton extends Component<IProps> {
  @observable private isVisible = new SmartBool();

  private get injected () {
    return this.props as IInjected;
  }

  private get fieldSets (): any[] {
    const { match: { dismissed_verification_note } } = this.props;

    return [[
      { field: 'note', label: 'Note', value: dismissed_verification_note },
    ]];
  }

  private get buttonText (): string {
    const { stores: { dedupeTableStore: { filters: { verification_status } } } } = this.injected
      , buttonText = verification_status === DEDUPE_STATUS.NEEDS_MORE_INFORMATION
        ? 'Add/Edit Note'
        : 'Needs More Information'
      ;

    return buttonText;
  }

  private dismissVerificationNeedsMoreInfo (model: object) {
    const { match } = this.props
      , { client, stores: { dedupeTableStore: { filters: { verification_status } } } } = this.injected
      , remove = verification_status !== DEDUPE_STATUS.NEEDS_MORE_INFORMATION
      ;

    match.dismissVerificationNeedsMoreInfo(client, model, remove);
  }

  public render () {
    return (
      <Popover
        arrowPointAtCenter
        content={
          <Form
            fieldSets={this.fieldSets}
            onSave={this.dismissVerificationNeedsMoreInfo}
            resetOnSuccess
            onCancel={this.isVisible.setFalse}
            onSuccess={this.isVisible.setFalse}
          />
        }
        onVisibleChange={this.isVisible.toggle}
        trigger='click'
        visible={this.isVisible.isTrue}
      >
        <LinkButton
          buttonSize='small'
          className='btn-needs-more-info'
          type='danger'
        >
          {this.buttonText}
        </LinkButton>
      </Popover>
    );
  }
}

export default VerificationNeedsMoreInformationButton;
