import React, { Component } from 'react';
import { observer } from 'mobx-react';
import autoBindMethods from 'class-autobind-decorator';
import cx from 'classnames';

import { Row } from 'antd';

import { RowProps } from 'antd/es/grid';

import styles from './CompareCardBody.module.less';

@autoBindMethods
@observer
class CompareCardBody extends Component<RowProps> {
  public render () {
    const { children, className, ...passThroughProps } = this.props;

    return (
      <Row className={cx(styles.root, className)} {...passThroughProps}>
        {children}
      </Row>
    );
  }
}

export default CompareCardBody;
