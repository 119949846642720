import { applySnapshot, flow, getSnapshot, Instance, types } from 'mobx-state-tree';
import { isBoolean } from 'lodash';

import Auth from '../base-modules/auth';
import { ROLE, SERVICE_STAGES, URLS } from '../utils/constants';
import { stateTreeDependencyGetters } from '../base-modules/state-tree-dependencies';

import { LegalOrganization } from './Party';

const CurrentService = types
  .model({
    days_until_expiry: types.maybeNull(types.number),
    features: types.array(types.string),
    is_eligible_for_trial: types.boolean,
    is_in_expired_trial: types.boolean,
    is_paying_customer: types.boolean,
    is_trial_customer: types.boolean,
    stage: types.enumeration(Object.values(SERVICE_STAGES)),
    valid_to: types.maybeNull(types.string),
    was_trial_customer: types.boolean,
  })
  ;

export const Account = types
  .model('Account', {
    created_at: types.string,
    current_service: types.maybeNull(CurrentService),
    id: types.identifier,
    is_active: types.boolean,
    is_email_visible: types.boolean,
    linked_contact: types.maybeNull(types.string),
    mighty_organization: types.string,
    phone_number: types.maybeNull(types.string),
    registry_legal_organization: LegalOrganization,
    role: types.string,
  })
  .views(stateTreeDependencyGetters)
  .actions(self => {
    const update = flow(function* (data, auth: Auth) {
      const result = yield self.client.update(`/accounts/${self.id}/`, data);

      yield auth.refreshAuthentication();
      applySnapshot(self, {
        // ENDPOINT_USER doesn't return roles, and possibly other fields
        ...getSnapshot(self),
        ...result.data,
      });
    });

    const refreshCurrentAccount = flow(function* (auth: Auth) {
      yield auth.refreshAuthentication();
    });

    const moveToTrial = flow(function* (auth: Auth) {
      yield self.client.update(`${URLS.LEGAL_ORGANIZATIONS}${self.registry_legal_organization.id}/start-trial/`);
      yield refreshCurrentAccount(auth);
    });

    return {
      moveToTrial,
      update,
    };
  })
  .views(self => ({
    get currentStage (): string {
      return self.current_service?.stage || '';
    },

    get isEligibleForTrial (): boolean {
      const is_eligible_for_trial: undefined | boolean = self.current_service?.is_eligible_for_trial;
      return isBoolean(is_eligible_for_trial) ? is_eligible_for_trial : true;
    },

    get isAdmin (): boolean {
      return self.role === ROLE.ADMIN;
    },
  }))
;

export const CompulsionRequest = types
  .model('CompulsionRequest', {
    id: types.identifier,
    created_at: types.string,
    // Optional fields
    fax_number: '',
    to_email: '',
    from_email: '',
    from_name: '',
    from_law_firm_name: '',
    to_lienholder: types.maybeNull(LegalOrganization),
    to_name: '',
  })
  ;

export const User = types
  .model('User', {
    email: types.maybeNull(types.string),
    first_name: types.maybeNull(types.string),
    id: types.identifier,
    invites: types.optional(types.array(CompulsionRequest), []),
    is_first_login: types.maybeNull(types.boolean),
    is_staff: types.maybeNull(types.boolean),
    last_login: types.maybeNull(types.string),
    last_name: types.maybeNull(types.string),
    registry_accounts: types.maybeNull(types.array(Account)),
    username: types.string,
  })
  .views(stateTreeDependencyGetters)
  .actions(self => {
    const updateName = (data: { first_name: string, last_name: string }) => {
      self.first_name = data.first_name;
      self.last_name = data.last_name;
    };

    const fetchInvites = flow(function* () {
      self.invites = yield self.client.get('/escrow-agent/compulsion-requests/');
    });

    const sendCompulsionRequest = flow(function* (data) {
      const submitData = {
        ...data,
        from_account: self.users.account?.id,
        from_email: self.email,
      };
      yield self.client.create('/invites/', submitData);
      yield fetchInvites();
    });

    return {
      fetchInvites,
      sendCompulsionRequest,
      updateName,
    };
  })
;

// Currently, we are testing out a more granular email notification type setting for lienholder users only.
// We may eventually extend this format to law firm users.
// Until then, in order to avoid having two very similar notification setting mobx models
// (with the only difference being the 'notification_category' vs. 'notification_type' field),
// we are returning the same field name ('notification_category') for both LH and LF notification settings.
export const NotificationSetting = types
  .model('NotificationSetting', {
    id: types.identifier,
    is_enabled: types.boolean,
    notification_category: types.string,
  })
;

export const SettingLegalOrganization = types
  .model('SettingLegalOrgaization', {
    id: types.identifier,
    name: types.string,
  })
;

export const NotificationSubscriptionSetting = types
  .model('NotificationSubscriptionSetting', {
    id: types.identifier,
    registry_legal_organizations: types.array(SettingLegalOrganization),
  })
;

export const NotificationSubscriptionBlacklistSetting = types
  .model('NotificationSubscriptionBlacklistSetting', {
    blacklisted_legal_organizations: types.array(SettingLegalOrganization),
    id: types.identifier,
  })
;

export const Invite = types
  .model('Invite', {
    email: '',
    first_name: '',
    last_name: '',
    organization: '',
    phone_number: '',
    token: '',
    uid: '',
  })
;

export const UserFlag = types
  .model('UserFlag', {
    flag: types.string,
    id: types.string,
    is_enabled: types.boolean,
  })
;

export interface ICurrentService extends Instance<typeof CurrentService> {}
export interface IAccount extends Instance<typeof Account> {}
export interface IInvite extends Instance<typeof Invite> {}
export interface ICompulsionRequest extends Instance<typeof CompulsionRequest> {}
export interface IUser extends Instance<typeof User> {}
export interface ISettingLegalOrganization extends Instance<typeof SettingLegalOrganization> {}
export interface INotificationSetting extends Instance<typeof NotificationSetting> {}
export interface INotificationSubscriptionSetting extends Instance<typeof NotificationSubscriptionSetting> {}
// eslint-disable-next-line max-len
export interface INotificationSubscriptionBlacklistSetting extends Instance<typeof NotificationSubscriptionBlacklistSetting> {}
export interface IUserFlag extends Instance<typeof UserFlag> {}
