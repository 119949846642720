import React, { Component } from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import autoBindMethods from 'class-autobind-decorator';

import SmartBool from '@mighty-justice/smart-bool';

import { IDocumentFilter } from '../../../../interfaces';
import { LoadingBlock, Section } from '../../../common';
import { ITabProps } from '../CaseTabConfig';

import DocumentsHeader from './header/DocumentsHeader';
import DocumentsTable from './table/DocumentsTable';
import DocumentSettingToggles from './settings/DocumentSettingToggles';

@autoBindMethods
@observer
class DocumentsTab extends Component<ITabProps> {
  @observable private filters: IDocumentFilter = {};
  @observable private isDocumentsLoading = new SmartBool();
  @observable private isLoading = new SmartBool();

  public async componentDidMount () {
    const { _case } = this.props;

    this.isLoading.until(_case.fetchDocuments());
  }

  private onFiltersChange (filters: IDocumentFilter) {
    this.filters = filters;
  }

  public render () {
    const { _case, lien } = this.props;

    if (this.isLoading.isTrue) { return <LoadingBlock />; }

    return (
      <Section>
        <DocumentsHeader
          _case={_case}
          filters={this.filters}
          isDocumentsLoading={this.isDocumentsLoading}
          onFiltersChange={this.onFiltersChange}
        />
        <DocumentsTable
          _case={_case}
          filters={this.filters}
          isDocumentsLoading={this.isDocumentsLoading}
          onFiltersChange={this.onFiltersChange}
        />
        {lien && <DocumentSettingToggles lien={lien} />}
      </Section>
    );
  }
}

export default DocumentsTab;
