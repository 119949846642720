import React, { Component } from 'react';
import { observer } from 'mobx-react';
import autoBindMethods from 'class-autobind-decorator';
import { observable } from 'mobx';

import { Dropdown, Menu } from 'antd';

import { Button, Icon } from '../../../common';
import { IDetailCase } from '../../../../models/Case';
import { IDetailLien } from '../../../../models/Lien';

import CaseDedupeModal from '../../dedupe/CaseDedupeModal';

import OpenLienModal from '../lienholder-specific/OpenLienModal';
import RemoveCaseModal from '../lienholder-specific/RemoveCaseModal';
import ResolveLienModal from '../lienholder-specific/ResolveLienModal';

import styles from './ActionDropdown.module.less';
import SmartBool from '@mighty-justice/smart-bool';

interface IProps {
  _case: IDetailCase;
  lien: IDetailLien;
}

@autoBindMethods
@observer
class LienholderActionDropdown extends Component<IProps> {
  @observable private isCaseDedupeModalVisible = new SmartBool();
  @observable private isResolveLienModalVisible = new SmartBool();
  @observable private isOpenLienModalVisible = new SmartBool();
  @observable private isRemoveCaseModalVisible = new SmartBool();

  private async onCopy () {
    const { lien } = this.props;

    await lien.copyCaseLink();
  }

  private get menu () {
    const { _case, lien } = this.props
      , hasLawFirm = !!_case.law_firm
      ;

    return (
      <Menu className={styles.overlay}>
        {document && document.queryCommandSupported('copy') && (
          <Menu.Item onClick={this.onCopy} id='copy-case-link' key='copy-case-link'>
            <Icon.Link />Copy Case Link
          </Menu.Item>
        )}
        {hasLawFirm && (
          <Menu.Item onClick={this.isCaseDedupeModalVisible.setTrue} id='lien-dedupe' key='lien-dedupe'>
            <Icon.Clone />Mark as Duplicate
          </Menu.Item>
        )}
        {!lien.isResolved ? (
          <Menu.Item onClick={this.isResolveLienModalVisible.setTrue} id='lien-resolved' key='lien-resolved'>
            <Icon.Check />Mark as Resolved
          </Menu.Item>
        ) : (
          <Menu.Item onClick={this.isOpenLienModalVisible.setTrue} id='reopen-lien' key='reopen-lien'>
            <Icon.Check />Re-open Lien
          </Menu.Item>
        )}
        <Menu.Item onClick={this.isRemoveCaseModalVisible.setTrue} id='remove-case' key='remove-case'>
          <Icon.TrashAlt />Remove Case
        </Menu.Item>
      </Menu>
    );
  }

  public render () {
    const { _case, lien } = this.props
      , isLawFirmOwned = !!lien.created_by_organization && lien.created_by_organization === _case.law_firm?.id;

    return (
      <div className={styles.root}>
        <Dropdown
          className={styles['case-action-dropdown']}
          overlay={this.menu}
          placement='bottomLeft'
          trigger={['click']}
        >
          <Button id='lienholder-case-action'>
            Actions<Icon.CaretDownSolid />
          </Button>
        </Dropdown>
        <CaseDedupeModal
          _case={_case}
          isVisible={this.isCaseDedupeModalVisible}
          onCancel={this.isCaseDedupeModalVisible.setFalse}
        />
        <ResolveLienModal
          isVisible={this.isResolveLienModalVisible}
          lien={lien}
        />
        <OpenLienModal
          isVisible={this.isOpenLienModalVisible}
          lien={lien}
        />
        <RemoveCaseModal
          isNoteRequired={isLawFirmOwned}
          isVisible={this.isRemoveCaseModalVisible}
          _case={_case}
          lien={lien}
        />
      </div>
    );
  }
}

export default LienholderActionDropdown;
