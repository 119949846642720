import React from 'react';
import { keyBy, merge } from 'lodash';

import {
  BOOLEAN_STRING,
  DEDUPE_STATUS,
  LAW_FIRM_TYPES,
  LIENHOLDER_TYPES,
  MODELS,
  URLS,
} from '../../utils/constants';
import { Icon } from '../common';
import { IFacet } from '../common-facets/interfaces';

export const URL_QUERY_KEYS = ['from_import'];

export const TYPES = {
  CASES: 'CASES',
  CONTACTS: 'CONTACTS',
  LAW_FIRM_CONTACTS: 'LAW_FIRM_CONTACTS',
  LAW_FIRMS: 'LAW_FIRMS',
  LIENHOLDER_CONTACTS: 'LIENHOLDER_CONTACTS',
  LIENHOLDERS: 'LIENHOLDERS',
};

export const ACTIONS = {
  DEDUPE: 'dedupe',
  VERIFICATION: 'verification',
};

export const ACTION_ICONS = {
  [ACTIONS.DEDUPE]: <Icon.CopyLight />,
  [ACTIONS.VERIFICATION]: <Icon.BadgeCheckLight />,
};

export const ACTION_ATTRIBUTES = {
  [ACTIONS.DEDUPE]: {
    filters: { status: DEDUPE_STATUS.NEW },
    ordering: { field: 'last_created_at', order: 'descend' },
  },
  [ACTIONS.VERIFICATION]: {
    filters: { verification_status: DEDUPE_STATUS.NEW },
  },
};

export function getMenuKey (action: string, type: string) {
  return `${action}.${type}`;
}

export interface IMenuDefaults {
  buttonProps: object;
  extraFacets: IFacet[];
  filters: object;
  searchFields: string[];
  showDismissedMatchFilter: boolean;
  showEditMatches: boolean;
}

export interface IMenuRequired {
  action: string;
  baseUrl: string;
  label: string;
  type: string;
}

export interface IMenu extends IMenuDefaults, IMenuRequired {
  key: string;
  modelName?: string;
  ordering?: object;
}

const MENU_DEFAULTS: IMenuDefaults = {
  buttonProps: {},
  extraFacets: [],
  filters: {},
  searchFields: [],
  showDismissedMatchFilter: true,
  showEditMatches: false,
};

const _MENUS: Array<Partial<IMenu> & IMenuRequired> = [
  {
    action: ACTIONS.VERIFICATION,
    baseUrl: URLS.INTERNAL_LEGAL_ORGANIZATIONS,
    buttonProps: { legalOrganizationTypes: LIENHOLDER_TYPES },
    filters: { is_verified: BOOLEAN_STRING.FALSE, type: LIENHOLDER_TYPES.join(',') },
    label: 'Lienholders',
    searchFields: ['name'],
    showEditMatches: true,
    type: TYPES.LIENHOLDERS,
  },
  {
    action: ACTIONS.VERIFICATION,
    baseUrl: URLS.INTERNAL_LEGAL_ORGANIZATIONS,
    buttonProps: { legalOrganizationTypes: LAW_FIRM_TYPES },
    filters: { is_verified: BOOLEAN_STRING.FALSE, type: LAW_FIRM_TYPES.join(',') },
    label: 'Law Firms',
    searchFields: ['name'],
    showEditMatches: true,
    type: TYPES.LAW_FIRMS,
  },
  {
    action: ACTIONS.VERIFICATION,
    baseUrl: URLS.INTERNAL_CONTACTS,
    filters: { is_user: BOOLEAN_STRING.FALSE, is_law_firm: BOOLEAN_STRING.FALSE },
    label: 'LH Contacts',
    searchFields: ['name', 'email'],
    type: TYPES.LIENHOLDER_CONTACTS,
  },
  {
    action: ACTIONS.VERIFICATION,
    baseUrl: URLS.INTERNAL_CONTACTS,
    filters: { is_user: BOOLEAN_STRING.FALSE, is_law_firm: BOOLEAN_STRING.TRUE },
    label: 'LF Contacts',
    searchFields: ['name', 'email'],
    type: TYPES.LAW_FIRM_CONTACTS,
  },
  {
    action: ACTIONS.DEDUPE,
    baseUrl: URLS.INTERNAL_DEDUPE_CASES,
    extraFacets: [
      { field: 'law_firm__name', label: 'Law firm name', type: 'string' },
      { field: 'liens__lienholder__name', label: 'Lienholder name', type: 'string' }
    ],
    filters: { model: MODELS.CASE },
    label: 'Cases',
    modelName: 'case',
    searchFields: ['plaintiff name'],
    type: TYPES.CASES,
  },
  {
    action: ACTIONS.DEDUPE,
    baseUrl: URLS.INTERNAL_DEDUPE_CONTACTS,
    filters: { model: MODELS.CONTACT },
    label: 'Contacts',
    modelName: 'contact',
    searchFields: ['name', 'email', 'legal organization name'],
    type: TYPES.CONTACTS,
  },
];

export const MENUS: IMenu[] = _MENUS.map(values => merge(
  {},
  MENU_DEFAULTS,
  ACTION_ATTRIBUTES[values.action],
  { key: getMenuKey(values.action, values.type) },
  values,
));

export const DEFAULT_SELECTED_KEY = getMenuKey(ACTIONS.VERIFICATION, TYPES.LIENHOLDERS);

export const MENU_MAP: { [key: string]: IMenu } = keyBy(MENUS, 'key');
