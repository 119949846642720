import React from 'react';
import cx from 'classnames';

import { Divider } from 'antd';

import { IDocument } from '../../models/Document';
import { Card } from '../common';

import DocumentOrderItem from './DocumentOrderItem';
import SummaryLineItem from './SummaryLineItem';

import styles from './DocumentPaymentPage.module.less';

interface IProps {
  documents: IDocument[];
  total: number;
}

function DocumentOrderSummary (props: IProps) {
  const { documents, total } = props;

  return (
    <Card className={cx(styles.summary, styles.bordered)} title='Order Summary'>
      {documents.map(document => <DocumentOrderItem document={document} key={document.id} />)}
      <Divider />
      <SummaryLineItem className={styles.total} name='Total' price={total} />
    </Card>
  );
}

export default DocumentOrderSummary;
