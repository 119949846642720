import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import autoBindMethods from 'class-autobind-decorator';
import { CardElement, ElementsConsumer } from '@stripe/react-stripe-js';
import { PaymentIntent, PaymentIntentResult, Stripe, StripeElements } from '@stripe/stripe-js';

import { notification } from 'antd';

import { FormCard } from '@mighty-justice/fields-ant';
import { formatMoney } from '@mighty-justice/utils';

import Client from '../../base-modules/client';
import StripeForm from '../stripe-wrappers/StripeForm';

import CreditCard from './CreditCard';
import { IPaymentMethod } from './interfaces';

import { SUPPORT_EMAIL } from '../../utils/constants';

import styles from './DocumentPaymentPage.module.less';

interface IProps {
  documentIds: string[];
  onSuccess: () => void;
  paymentMethods: IPaymentMethod[];
  total: number;
}

interface IFormProps extends IProps {
  stripePublicKey: string;
}

interface IFormBaseProps extends IProps {
  elements: StripeElements | null;
  stripe: Stripe | null;
}

interface IInjected extends IFormBaseProps {
  client: Client;
  getStripeAccountInstance: (accountId: string) => ({
    confirmCardPayment: (secret: string) => Promise<PaymentIntentResult>,
  });
}

@inject('client')
@autoBindMethods
@observer
class FormBase extends Component<IFormBaseProps> {
  private get injected () { return this.props as IInjected; }

  private get hasCard () {
    return !!this.props.paymentMethods.length;
  }

  private async getPaymentMethod () {
    const { stripe, elements } = this.props
      , cardElement = elements?.getElement(CardElement);

    if (this.hasCard) {
      return this.props.paymentMethods[0];
    }

    if (cardElement && stripe) {
      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: 'card',
        card: cardElement,
      });

      if (error) {
        notification.error({ message: error.message, description: 'Double check your info and try again' });
      }

      return paymentMethod;
    }

    return null;
  }

  private throwUnknownError () {
    notification.error({
      description: 'An error occurred in the payment process. Please refresh your tab and try again. ' +
        `If the problem persists, please contact ${SUPPORT_EMAIL}.`,
      duration: null,
      message: 'Payment error',
    });
  }

  public async onSubmit (data: { [key: string]: any }) {
    const { documentIds, onSuccess } = this.props
      , { client } = this.injected
      , paymentMethod = await this.getPaymentMethod()
      ;

    // most payment method errors are validations, so handle them differently
    if (!paymentMethod) { return; }

    const paymentResponse = await client.create('stripe/document-payments/', {
        documents: documentIds,
        payment_method: paymentMethod.id,
        save_card_for_future_use: data.save_card_for_future_use,
      })
      , { payment_intents, stripe_account, stripe_client_secret } = paymentResponse.data
      ;

    const stripeAccountInstance = this.injected.getStripeAccountInstance(stripe_account)
      , confirmResponse = await stripeAccountInstance.confirmCardPayment(stripe_client_secret)
      ;

    if (confirmResponse.error) {
      throw new Error(confirmResponse.error.message);
    }

    const updateData = { status: (confirmResponse.paymentIntent as PaymentIntent).status };
    await Promise.all(
      payment_intents.map((id: string) => client.update(`stripe/document-payment-intents/${id}/`, updateData))
    );

    onSuccess();
  }

  private async wrappedOnSubmit (data: { [key: string]: any }) {
    try {
      await this.onSubmit(data);
    }
    catch (error) {
      this.throwUnknownError();

      // eslint-disable-next-line no-console
      console.error(`${error.name}: ${error.message}`);
    }
  }

  public render () {
    const { paymentMethods, total } = this.props;

    return (
      <FormCard
        blockSubmit
        fieldSets={[[
          {
            className: styles['fields-ant-credit-card'],
            editComponent: (props: { disabled: boolean }) => (
              <CreditCard disabled={props.disabled} hasCard={this.hasCard} paymentMethods={paymentMethods} />
            ),
            field: 'creditCard',
            showLabel: false,
          },
          {
            editProps: {
              description: 'Save this CC for later use',
            },
            field: 'save_card_for_future_use',
            insertIf: () => !this.hasCard,
            showLabel: false,
            type: 'checkbox',
          },
        ]]}
        onSave={this.wrappedOnSubmit}
        saveText={`Pay ${formatMoney(total)}`}
        successText={null}
      />
    );
  }
}

const InjectedForm = (props: IFormBaseProps) => (
  <ElementsConsumer>
    {({ stripe, elements }) => (
      <FormBase stripe={stripe} elements={elements} {...props} />
    )}
  </ElementsConsumer>
);

function CardPaymentForm (props: IFormProps) {
  const { stripePublicKey, ...passThroughProps } = props;

  return (
    <StripeForm Form={InjectedForm} passThroughProps={passThroughProps} publicKey={stripePublicKey} />
  );
}

export default CardPaymentForm;
