import { has, omit } from 'lodash';
import { applySnapshot, flow, getSnapshot, Instance, types } from 'mobx-state-tree';

import { stateTreeDependencyGetters } from '../base-modules/state-tree-dependencies';
import { ORGANIZATION_TYPES } from '../utils/constants';
import { nullifyEmptyObject } from '../utils/utils';

import { DetailCase, IDetailCase, ListCase, IListCase } from './Case';
import { ILegalOrganizationExternalSystem } from './LegalOrganizationExternalSystem';
import { DetailLien } from './Lien';

export const Portfolio = types
  .model({
    detailCases: types.optional(types.map(DetailCase), {}),
    listCases: types.optional(types.map(ListCase), {}),
    listLiens: types.optional(types.map(DetailLien), {}),
  })
  .views(stateTreeDependencyGetters)
  .views(self => ({
    get baseEndpoint (): string {
      return self.users.isLawFirmUser ? '/beta/escrow-agent/' : '/beta/lienholder/';
    },

    get endpoint (): string {
      return `${this.baseEndpoint}portfolio/`;
    },

    get lienEndpoint (): string {
      return `${this.baseEndpoint}liens/`;
    }
  }))
  .actions(self => {
    const setListCase = (caseData: any) => {
      const caseId = caseData.id;

      // sometimes a result from case creation is already in the map
      // i.e. if a duplicate case that exists on the page is added
      const foundListCase = self.listCases.get(caseId);

      if (foundListCase) {
        applySnapshot(foundListCase, { ...getSnapshot(foundListCase), ...caseData });
        return foundListCase;
      }

      const _case = ListCase.create({ ...caseData });

      self.listCases.put(_case);
      return _case;
    };

    const setListLien = (lienData: any) => {
      // existing liens may be returned if duplicates are created
      if (self.listLiens.has(lienData.id)) { return; }

      const lien = DetailLien.create(lienData);

      self.listLiens.put(lien);
    };

    const clearHydrate = () => {
      self.listCases.clear();
    };

    const hydrate = flow(function* (
      getIgnoredId: null | (() => string | null),
      queryString: string = '',
    ) {
      const caseListData = yield self.client.get(`${self.endpoint}${queryString}`)
        , ignoredCaseId = getIgnoredId && getIgnoredId()
        , shouldFilterResults = ignoredCaseId && self.listCases.has(ignoredCaseId)
        , filteredResults = shouldFilterResults
          ? caseListData.results.filter((_case: IListCase) => (_case.id !== ignoredCaseId))
          : caseListData.results
        ;

      filteredResults.forEach(setListCase);
      return caseListData;
    });

    const setDetailCase = (data: any): IDetailCase => self.detailCases.put(data);

    const fetchCase = flow(function* (caseId: string) {
      const _case = yield self.client.get(`${self.endpoint}${caseId ? `${caseId}/` : 'null'}`);

      if (!_case) { return; }

      const setCase = setDetailCase(_case);
      yield Promise.all([setCase.fetchLiens(), setCase.fetchExternalSystemIds()]);
      setCase.load();
    });

    const createExternalKeys = flow(function* (
      _case: IDetailCase,
      external_keys: any[],
      externalSystems: ILegalOrganizationExternalSystem[],
    ) {
      yield Promise.all(
        external_keys
          .map((external_key, idx) => ({
            ...external_key,
            externalSystem: externalSystems[idx],
          }))
          .filter(external_key => external_key.externalSystemIdentifier)
          .map(external_key => _case.addNewExternalSystem(
            external_key.externalSystemIdentifier,
            external_key.externalSystem,
          ))
      );
    });

    const createCase = flow(function* (
      formData: any,
      externalSystems: ILegalOrganizationExternalSystem[],
    ) {
      const { external_keys, contact, ...caseData } = formData
        , submitData = { ...caseData, liens: [] }
        , response = yield self.client.create(self.endpoint, submitData)
        , _case = setDetailCase({ ...response.data, law_firm: response.data.law_firm_data })
        ;

      if (!!contact) {
        // contacts cannot be created by law firm on case create
        // so there is always an id
        yield _case.addContact(contact.id, false);
      }

      if (external_keys) {
        yield createExternalKeys(_case, external_keys, externalSystems);
      }

      return _case.id;
    });

    const getOrCreateOrg = flow(function* (lawFirmData: any) {
      if (!has(lawFirmData, 'id')) {
        const response = yield self.client.create('/beta/organizations/', {
          type: ORGANIZATION_TYPES.LAW_FIRM,
          ...lawFirmData,
        });

        return response.data || null;
      }

      return lawFirmData;
    });

    const createCaseForLien = flow(function* (caseSubmitData: any) {
      const response = yield self.client.create(self.endpoint, caseSubmitData);

      return response.data || null;
    });

    const createCaseLien = flow(function* (lienSubmitData: any) {
      const createResponse = yield self.client.create(`${self.baseEndpoint}liens/`, lienSubmitData)
        , lienId = createResponse.data?.id
        , response = yield self.client.get(`${self.baseEndpoint}liens/${lienId}/`)
        ;

      return response || null;
    });

    const createAddContact = flow(function* (contactSubmitData: any, lawFirmId: string, caseId: string) {
      if (!!contactSubmitData) {
        if (has(contactSubmitData, 'id')) {
          yield self.client.update(`${self.endpoint}${caseId}/add-contact/`, { contact: contactSubmitData.id });
        }
        else {
          const response =
            yield self.client.create('/beta/contacts/', { ...contactSubmitData, legal_organization: lawFirmId });
          yield self.client.update(`${self.endpoint}${caseId}/add-contact/`, { contact: response.data.id });
        }
      }
    });

    const createLien = flow(function* (lienData: any) {
      lienData.plaintiff_treatment_status = lienData.plaintiff_treatment_status || undefined;
      const { case: { law_firm } } = lienData
        , lawFirm = yield getOrCreateOrg(law_firm)
        , caseSubmitData = { ...lienData.case, law_firm: lawFirm.id }
        , _case = yield createCaseForLien(caseSubmitData)
        , lienSubmitData = { ...omit(lienData, 'external_keys'), verification_status: 'PENDING', case: _case.id }
        , lienObj = yield createCaseLien(lienSubmitData)
        , nulledContact = nullifyEmptyObject(lienData.case.contacts[0])
        ;

      yield createAddContact(nulledContact, lawFirm.id, _case.id);

      const lien = DetailLien.create(lienObj);
      setListLien(lien);

      return lien;
    });

    return {
      clearHydrate,
      createCase,
      createExternalKeys,
      createLien,
      fetchCase,
      hydrate,
      setDetailCase,
      setListCase,
    };
  })
  ;

export interface IPortfolio extends Instance<typeof Portfolio> {}
