import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import autoBindMethods from 'class-autobind-decorator';

import SmartBool from '@mighty-justice/smart-bool';
import { FormModal } from '@mighty-justice/fields-ant';

import { IDetailCase } from '../../../../models/Case';
import { ICaseThread } from '../../../../models/CaseThread';
import { IDetailLien } from '../../../../models/Lien';
import { INotification } from '../../../../models/Notification';
import Client from '../../../../base-modules/client';
import { addLawFirmField } from '../../add-case-form-drawer/addCaseDrawerConfig';

import { AUTO_THREAD_RESPONSE_NOTE } from '../tab-inbox/constants';

interface IProps {
  _case: IDetailCase;
  isVisible: SmartBool;
  lien: IDetailLien;
  notification?: INotification;
  thread?: ICaseThread;
}

interface IInjected extends IProps {
  client: Client;
}

@inject('client')
@autoBindMethods
@observer
class UpdateLawFirmModal extends Component <IProps> {
  private get injected () {
    return this.props as IInjected;
  }

  private async onSave (model: any) {
    const { lien, notification, thread } = this.props
      , { client } = this.injected
      ;

    await lien.upsertLawFirmAndCaseDetails(model);

    if (notification) {
      notification.markAsDismissed(client);
    }

    if (thread) {
      await thread.dismiss(AUTO_THREAD_RESPONSE_NOTE.UPDATED_LAW_FIRM);
    }
  }

  public render () {
    const { _case, isVisible } = this.props
      , lawFirmFieldSet = {
        fields: [{ ...addLawFirmField, required: false, field: 'law_firm' }],
      };

    return (
      <FormModal
        fieldSets={[lawFirmFieldSet]}
        isVisible={isVisible}
        model={_case}
        onSave={this.onSave}
        title='Set Law Firm'
      />
    );
  }
}

export default UpdateLawFirmModal;
