import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { IActivityDetailComponentProps } from './interfaces';

@observer
class NewLienActivity extends Component<IActivityDetailComponentProps> {
  public render () {
    return (
      <h4>Lien registered</h4>
    );
  }
}

export default NewLienActivity;
