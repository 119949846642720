import React, { Component } from 'react';
import { observer } from 'mobx-react';
import autoBindMethods from 'class-autobind-decorator';

import { IDetailCase } from '../../../../../models/Case';
import { IPolicyLimit } from '../../../../../models/PolicyLimit';
import { Icon } from '../../../../common';
import AddDocumentButton from '../../tab-documents/common/AddDocumentButton';
import { IBetaDocumentSubmitData } from '../../tab-documents/interfaces';

interface IProps {
  _case: IDetailCase;
  policyLimit: IPolicyLimit;
}

@autoBindMethods
@observer
class AddPolicyLimitDocumentButton extends Component<IProps> {
  private async uploadDocuments (documents: IBetaDocumentSubmitData[]) {
    const { policyLimit } = this.props
      , document = documents[0]
      ;

    await policyLimit.uploadDocument(document);
  }

  public render () {
    const { _case } = this.props;

    return (
      <AddDocumentButton
        _case={_case}
        buttonText='Add Document'
        hideFields
        icon={Icon.FileAltLight}
        iconPosition='front'
        multiple={false}
        uploadDocuments={this.uploadDocuments}
      />
    );
  }
}

export default AddPolicyLimitDocumentButton;
