import React from 'react';
import { get } from 'lodash';

import { ColProps, RowProps } from 'antd/lib/grid';

import {
  IFieldSetPartial,
  isPartialFieldSetSimple,
} from '@mighty-justice/fields-ant';

import Icon from '../components/common/Icon';

import { ANT_FULL_COL_WIDTH, DEFAULT_ROW_PROPS } from './constants';

const selectSuffixIcon = <span className='ant-select-arrow-icon'><Icon.CaretDownSolid /></span>
  , removeIcon = <Icon.TimesCircleSolid />;

export const optionSelectConfig = {
  editProps: {
    clearIcon: removeIcon,
    suffixIcon: selectSuffixIcon,
  },
};

export const addressConfig = {
  className: 'fields-ant-address',
  field: 'address',
  stateProps: optionSelectConfig,
  type: 'address',
};

export const objectSearchCreateConfig = {
  editProps: {
    loadingIcon: <Icon.SpinnerThird primary spin />,
    selectProps: {
      clearIcon: removeIcon,
    },
  },
};

export function withColumns (numColumns: number, override = false) {
  const colProps: ColProps = { span: ANT_FULL_COL_WIDTH / numColumns }
    , rowProps: RowProps = { ...DEFAULT_ROW_PROPS };

  return (fieldSet: IFieldSetPartial): IFieldSetPartial => {
    const fields = isPartialFieldSetSimple(fieldSet) ? fieldSet : fieldSet.fields;

    // If override flag is passed, rewrite all existing layout information
    if (override) {
      return {
        ...fieldSet,
        fields: fields.map(fieldConfig => ({ ...fieldConfig, colProps })),
        rowProps,
      };
    }

    // When override is false, always preserve original values when present
    return {
      ...fieldSet,
      fields: fields.map(fieldConfig => ({ colProps, ...fieldConfig })),
      rowProps: get(fieldSet, 'rowProps', rowProps),
    };
  };
}
