import React, { Component } from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import autoBindMethods from 'class-autobind-decorator';
import { get, noop } from 'lodash';

import SmartBool from '@mighty-justice/smart-bool';

import { Col, Row } from 'antd';

import UpgradeLayout from '../UpgradeLayout';

import CreditCardForm from './CreditCardForm';
import OrderSummary from './OrderSummary';
import PaymentFooter from './PaymentFooter';

import styles from './PaymentPage.module.less';

const images = {
  chip: 'https://mighty-v2-static.s3.amazonaws.com/icons/card_chip.svg',
  logo: 'https://mighty-v2-static.s3.amazonaws.com/icons/card_logo.svg',
};

@autoBindMethods
@observer
class PaymentPage extends Component {
  @observable public isLoading = new SmartBool();
  @observable public creditCardFormRef = null;

  public getCreditCardFormRef (element: any) { this.creditCardFormRef = element; }

  private get footer () {
    return (
      <PaymentFooter
        isLoading={this.isLoading}
        onSubmit={get(this.creditCardFormRef, 'props.onSubmit', noop)}
      />
    );
  }

  public render () {
    return (
      <UpgradeLayout title='Review your plan and upgrade' footer={this.footer}>
        <div className={styles.root}>
          <Row align='middle' className={styles.bodyRow}>
            <Col className={styles.creditCard} span={10}>
              <div className={styles.inner}>
                <img alt='Card chip' height='40' src={images.chip} width='60' />
                <h3>
                  Credit Card{' '}
                  <img alt='Card logo' height='36' src={images.logo} width='50' />
                </h3>
              </div>
              <div className={styles.formWrapper}>
                <CreditCardForm
                  getStripeFormRef={this.getCreditCardFormRef}
                  isLoading={this.isLoading}
                />
              </div>
            </Col>
            <Col span={10} offset={4}>
              <OrderSummary />
            </Col>
          </Row>
        </div>
      </UpgradeLayout>
    );
  }
}

export default PaymentPage;
