import React, { Component } from 'react';
import moment from 'moment';

import { INotification } from '../../../models/Notification';

import styles from './NotificationCreatedAt.module.less';

interface IProps {
  notification: INotification;
}

class NotificationCreatedAt extends Component<IProps> {
  public render () {
    const { notification } = this.props;

    return (
      <div className={styles.root}>
        {moment(notification.created_at).fromNow()}
      </div>
    );
  }
}

export default NotificationCreatedAt;
