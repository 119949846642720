import styles from './Modal.module.less';

export const CONTACT_FIELDS = [
  { field: 'first_name', populateNameFromSearch: true, required: true },
  { field: 'last_name', populateNameFromSearch: true },
  { field: 'email', label: 'Email Address', required: true },
];

export const DEFAULT_MODAL_WIDTH = 620;

export const DEFAULT_SELECT_PROPS = {
  selectProps: {
    className: styles.selectDropdown,
  }
};

// importing the dist from fields-ant wasn't working
// import { OPTION_KEYS } from '@mighty-justice/fields-ant/dist/inputs/ObjectSearch';
export const ADD_OPTION_KEY = 'fields-ant-input-object-search-add';
