import React, { Component } from 'react';
import cx from 'classnames';
import { inject, observer } from 'mobx-react';
import autoBindMethods from 'class-autobind-decorator';

import { IDetailCase } from '../../../../../models/Case';
import { ICaseThread } from '../../../../../models/CaseThread';
import { IDetailLien } from '../../../../../models/Lien';
import StoresClass from '../../../../../stores/StoresClass';
import { Icon, ModalButton } from '../../../../common';
import InfoCard from '../../common/InfoCard';
import ResolveRejectionModal from '../ResolveRejectionModal';

import { AUTO_THREAD_RESPONSE_NOTE } from '../constants';

import { InboxItemProps } from '../interfaces';

import styles from './InboxActionButtons.module.less';

interface IProps {
  _case: IDetailCase;
  lien?: IDetailLien;
  passThroughProps: InboxItemProps;
  thread: ICaseThread;
}

interface IInjected extends IProps {
  stores: StoresClass;
}

@inject('stores')
@autoBindMethods
@observer
class LienRejectedByLawFirmThread extends Component<IProps> {
  private get injected () { return this.props as IInjected; }

  private get updateButton () {
    const { _case, lien, thread } = this.props;

    return (
      <div className={cx(styles.root, styles.override)}>
        <ModalButton
          buttonProps={{
            className: styles.primary,
            id: 'resolve-rejection-button',
          }}
          passThroughProps={{ _case, lien, thread }}
          ModalClass={ResolveRejectionModal}
        >
          Update <Icon.Reply />
        </ModalButton>
      </div>
    );
  }

  private get lawFirmDescription () {
    const { thread } = this.props
      , lienholder = thread.to_organization_name
      , defaultDescription = `You rejected a lien with ${lienholder}`;

    if (!thread.is_resolved) {
      return defaultDescription;
    }

    switch (thread.latest_message?.note) {
    case AUTO_THREAD_RESPONSE_NOTE.RESUBMITTED_LIEN:
      return `${lienholder} resubmitted a lien`;

    case AUTO_THREAD_RESPONSE_NOTE.RESOLVED_REJECTION:
      return defaultDescription;

    default:
      return 'This thread has been resolved';
    }
  }

  private get lienholderDescription () {
    const { thread } = this.props
      , lawFirm = thread.created_by_organization_name;

    if (!thread.is_resolved) {
      return `${lawFirm} says that you don’t have a lien with them`;
    }

    switch (thread.latest_message?.note) {
    case AUTO_THREAD_RESPONSE_NOTE.RESOLVED_REJECTION:
      return `You resolved a lien rejection with ${lawFirm}`;

    case AUTO_THREAD_RESPONSE_NOTE.RESUBMITTED_LIEN:
      return `You resubmitted a lien with ${lawFirm}`;

    default:
      return 'This thread has been resolved';
    }
  }

  public render () {
    const { passThroughProps, thread } = this.props
      , { stores: { users: { isLawFirmUser } } } = this.injected
      ;

    return (
      <InfoCard
        bottomRightRender={!thread.is_resolved && !isLawFirmUser && this.updateButton}
        content={!thread.is_resolved && thread.latest_message?.note}
        subtitle={isLawFirmUser ? this.lawFirmDescription : this.lienholderDescription}
        title='Contacts'
        {...passThroughProps}
      />
    );
  }
}

export default LienRejectedByLawFirmThread;
