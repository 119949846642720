import React from 'react';
import { observer } from 'mobx-react';

import makeInfinitelyScrollable, { IInfiniteScrollProps } from '../../utils/InfiniteScroll';

import PortfolioContent, { IPortfolioContentProps } from './PortfolioContent';

interface IProps extends IPortfolioContentProps, IInfiniteScrollProps {}

const PortfolioContentWrapper = observer((props: IProps) => <PortfolioContent {...props} />);

export default makeInfinitelyScrollable(PortfolioContentWrapper);
