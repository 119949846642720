import React from 'react';

import { CardElement } from '@stripe/react-stripe-js';

interface IProps {
  disabled: boolean;
}

const style = {
  base: {
    '::placeholder': {
      color: '#aab7c4',
    },
    'fontSize': '18px',
  },
  invalid: {
    color: '#9e2146',
  },
};

function CreditCardInput (props: IProps) {
  const { disabled } = props;

  return (
    <div>
      <CardElement options={{ hideIcon: true, style, disabled }} />
    </div>
  );
}

export default CreditCardInput;
