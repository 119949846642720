import React, { Component } from 'react';
import { observer } from 'mobx-react';

import { Table } from 'antd';

import { formatDate, formatPhoneNumber, getNameOrDefault, pluralize } from '@mighty-justice/utils';

import { ICompulsionRequest } from '../../models/User';

import styles from './CompulsionRequestTable.module.less';

interface IProps {
  invites: Array<ICompulsionRequest>;
}

@observer
class CompulsionRequestTable extends Component<IProps> {
  private get invitationsCount () { return this.props.invites.length; }

  private get tableColumns () {
    return [
      {
        title: 'Your Invitations',
        key: 'name',
        render: (_string: any, record: ICompulsionRequest) =>
          ((record.to_name && record.to_lienholder) ? (
            <>
              <div>{record.to_name}</div>
              <div className={styles['lienholder-name']}>{getNameOrDefault(record.to_lienholder)}</div>
            </>
          ) : (
            record.to_email ? (
              <span>{record.to_email}</span>
            ) : (
              <>
                <div>Fax: {formatPhoneNumber(record.fax_number)}</div>
                <div className={styles['lienholder-name']}>{record.to_name}</div>
              </>
            )
          ))
      },
      {
        title: `${this.invitationsCount} ${pluralize('Invitation', 's', this.invitationsCount)} Sent`,
        key: 'created_at',
        alight: 'right',
        width: 200,
        render: (_value: string, record: ICompulsionRequest) =>
          (record.to_lienholder ? (
            <div className={styles['invite-status']}>
              <div className={styles.accepted}>Invite Accepted</div>
              <div className={styles['invite-date']}>{formatDate(record.to_lienholder.created_at)}</div>
            </div>
          ) : (
            <div className={styles['invite-status']}>
              <div className={styles.pending}>Invite sent</div>
              <div className={styles['invite-date']}>{formatDate(record.created_at)}</div>
            </div>
          ))
      },
    ];
  }

  public render () {
    return (
      <Table
        className={styles.root}
        columns={this.tableColumns}
        dataSource={this.props.invites.slice()}
        pagination={false}
        rowKey='id'
      />
    );
  }
}

export default CompulsionRequestTable;
