import React, { Component } from 'react';
import autoBindMethods from 'class-autobind-decorator';
import { inject, observer } from 'mobx-react';

import { Empty } from 'antd';

import StoresClass from '../../../../stores/StoresClass';
import { CASE_ACTIVITY_TYPES } from '../../../../utils/constants';
import { ICaseActivity } from '../../../../models/CaseActivity';
import { IDetailLien } from '../../../../models/Lien';
import { safeArrayAccess } from '../../../../utils/utils';

import ChatBubble from './ChatBubble';
import { IBubble } from './interfaces';

interface IProps {
  lien?: IDetailLien;
}

interface IInjected extends IProps {
  stores: StoresClass;
}

@inject('stores')
@autoBindMethods
@observer
class ChatThread extends Component<IProps> {
  private get injected () { return this.props as IInjected; }

  private async onDelete (id: string) {
    const { lien } = this.props
      , activity: undefined | ICaseActivity = lien?.activities?.find(a => a.id === id);

    if (!lien || !activity) { return; }
    await lien.deleteActivity(activity);
  }

  private get bubbles (): IBubble[] {
    const { lien } = this.props
      , { stores: { users: { isLawFirmUser } } } = this.injected
      , myType = isLawFirmUser
        ? CASE_ACTIVITY_TYPES.LAW_FIRM_POSTED_NOTE
        : CASE_ACTIVITY_TYPES.LIENHOLDER_POSTED_NOTE
      , byCreatedAt = (a: ICaseActivity, b: ICaseActivity) => a.created_at.localeCompare(b.created_at)
      ;

    if (!lien || !lien.activities) { return []; }

    return lien.notes
      .sort(byCreatedAt)
      .map((activity, idx, activities) => {
        const { created_at, created_by_name, created_by_organization, id, note, type } = activity
          , sentByMe = type === myType
          , nextCreatedByOrg = safeArrayAccess(activities, idx + 1)?.created_by_organization
          , sameSenderNext = nextCreatedByOrg === created_by_organization
          , lastBubble = idx === activities.length - 1
          , showSpacer = sentByMe || (!sameSenderNext && !lastBubble)
          ;

        return { created_at, created_by_name, created_by_organization, id, note, sentByMe, showSpacer };
      })
    ;
  }

  public render () {
    const { lien } = this.props;
    if (!lien) { return null; }

    if (this.bubbles.length === 0) {
      return <Empty description='Send your first message below' />;
    }

    return (
      <div>
        {this.bubbles.map(bubble => (
          <ChatBubble key={bubble.id} {...bubble} onDelete={this.onDelete} />
        ))}
      </div>
    );
  }
}

export default ChatThread;
