import React, { Component } from 'react';
import { observer } from 'mobx-react';

import { ILegalOrganizationExternalSystem } from '../../../models/LegalOrganizationExternalSystem';

interface IProps {
  system: ILegalOrganizationExternalSystem;
}

@observer
class IntegrationSourceLogo extends Component<IProps> {
  public render () {
    const { system } = this.props;

    if (!system.integratedSystemLogoPath) { return null; }

    return (
      <img
        alt={system.external_system.external_system_id}
        src={system.integratedSystemLogoPath}
        height={36}
        width={36}
      />
    );
  }
}

export default IntegrationSourceLogo;
