import React, { Component } from 'react';
import autoBindMethods from 'class-autobind-decorator';
import { observer } from 'mobx-react';

import { Button, Icon } from '../../common';
import { IFacetProps, IToggleButtonProps } from '../interfaces';
import { getFacetKey, isFacetApplied, toggleFacet } from '../facetUtils';
import { queryValueToStringArray } from '../../../utils/navigationUtils';

interface IHooksProps extends IFacetProps, IToggleButtonProps {}

@autoBindMethods
@observer
class FacetToggle extends Component<IHooksProps> {
  private get isApplied (): boolean {
    const { facet, tableStore } = this.props;

    return isFacetApplied(facet, tableStore.filterQuery);
  }

  private onClick () {
    const { facet, facet: { field }, appliedValue, onFacetChange, tableStore } = this.props
      , appliedValueStringArray = queryValueToStringArray(appliedValue)
      , newValue = toggleFacet(facet, tableStore.filters, appliedValueStringArray)
      ;

    onFacetChange({ [field]: newValue });
  }

  public render () {
    const { facet, disabled } = this.props
      , type = this.isApplied ? 'primary' : 'default';

    return (
      <Button
        disabled={disabled}
        key={getFacetKey(facet)}
        onClick={this.onClick}
        type={type}
      >
        {facet.label}
        {this.isApplied && <Icon.TimesCircle />}
      </Button>
    );
  }
}

export default FacetToggle;
