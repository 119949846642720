import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import autoBindMethods from 'class-autobind-decorator';

import { message } from 'antd';

import Client from '../../base-modules/client';
import LoggedOutLandingPage from '../LoggedOutLandingPage';
import { default as PasswordResetForm, IPasswordResetSubmitData } from './PasswordResetForm';
import { INavigateProps } from '../../utils/navigationUtils';
import { URLS } from '../../utils/constants';
import navigationWrapper from '../../utils/navigationWrapper';

interface IInjected extends INavigateProps {
  client: Client;
}

@inject('client')
@autoBindMethods
@observer
class PasswordResetPage extends Component<INavigateProps> {
  private get injected () { return this.props as IInjected; }

  private async resetPassword ({ passwordResetURL, newPassword, reNewPassword }: IPasswordResetSubmitData) {
    const { params: { uid, token }, navigate } = this.props
      , { client } = this.injected
      ;

    await client.create(passwordResetURL, { new_password: newPassword, re_new_password: reNewPassword, uid, token });
    message.success('Success! Please log in with your new password.');
    navigate(URLS.LOGIN);
  }

  public render () {
    return (
      <LoggedOutLandingPage title='Reset Password'>
        <PasswordResetForm title='Reset password' resetPassword={this.resetPassword} />
      </LoggedOutLandingPage>
    );
  }
}

export default navigationWrapper(PasswordResetPage);
