import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import autoBindMethods from 'class-autobind-decorator';

import SmartBool from '@mighty-justice/smart-bool';

import { ILegalOrganizationWithContacts } from '../../../../models/Party';
import Client from '../../../../base-modules/client';
import StoresClass from '../../../../stores/StoresClass';
import { URLS } from '../../../../utils/constants';
import Button from '../../../common/Button';

interface IProps {
  legalOrganization: ILegalOrganizationWithContacts;
  linkedContact: string;
}

interface IInjected extends IProps {
  client: Client;
  stores: StoresClass;
}

@inject('stores', 'client')
@autoBindMethods
@observer
class AssignToMeButton extends Component<IProps> {
  private isLoading: SmartBool = new SmartBool();

  private get injected () { return this.props as IInjected; }

  private get userIsAContact () {
    const { legalOrganization, linkedContact } = this.props
      , contactIds = legalOrganization.contacts?.map(contact => contact.id) || [];

    return contactIds.includes(linkedContact);
  }

  private async onClick () {
    this.isLoading.setTrue();

    const { legalOrganization, linkedContact } = this.props
      , { client } = this.injected;

    if (!this.userIsAContact) {
      const response = await client.get(`${URLS.CONTACTS_BETA}?id=${linkedContact}`);

      await legalOrganization.addExistingContact(response.results[0]);
    }
    else {
      await legalOrganization.removeContact(linkedContact);
    }

    this.isLoading.setFalse();
  }

  public render () {
    return (
      <Button
        onClick={this.onClick}
        loading={this.isLoading.isTrue}
      >
        {this.userIsAContact ? 'Unassign from me' : 'Assign to me'}
      </Button>
    );
  }
}

export default AssignToMeButton;
