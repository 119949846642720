import React from 'react';

import { Row } from 'antd';
import { ColumnProps } from 'antd/lib/table';

import { formatDate, getNameOrDefault } from '@mighty-justice/utils';
import { IModel } from '@mighty-justice/fields-ant/dist/props';

import CouldNotVerifyButton from '../legal-organizations/CouldNotVerifyButton';
import VerificationNeedsMoreInformationButton from '../legal-organizations/VerificationNeedsMoreInformationButton';
import VerifyButton from '../../dedupe/contact/VerifyButton';
import { fillInColumnProfile } from '../../../../utils/columnCommon';
import { ILegacyBaseCase } from '../../../../models/Legacy';
import {
  renderRelatedToCompulsionText,
  renderDate,
  renderEnteredBy,
  renderLegalOrganizationDetails,
  renderVerifiedMatchNote,
} from '../../tableColumns';

const renderVerifyButton = (_value: any, data: any) => (
  <>
    <VerifyButton match={data} />
    <br/><br/>
    <CouldNotVerifyButton match={data} />
    <br/><br/>
    <VerificationNeedsMoreInformationButton match={data} />
  </>
);

export const renderCaseDetails = (value: ILegacyBaseCase, _data: unknown) => {
  if (!value) { return null; }

  return (
    <>
      <Row className='dd-privacy-mask'>
        {getNameOrDefault(value.plaintiff)}
      </Row>
      <Row className='dd-privacy-mask'>
        DOB {formatDate(value.plaintiff.birthdate)}
      </Row>
      <Row>
        DOL {formatDate(value.date_of_loss)}
      </Row>
    </>
  );
};

export const contactVerificationColumns: Array<ColumnProps<IModel>> = [
  {
    field: 'created_at',
    minWidth: 50,
    render: renderDate,
    sorter: true,
    title: 'Created',
  },
  {
    field: '',
    minWidth: 80,
    render: renderEnteredBy,
    sorter: true,
    title: 'Entered by',
  },
  {
    field: 'legal_organization_name',
    label: 'Lienholder Name',
  },
  {
    field: 'legal_organization',
    label: 'Lienholder Details',
    render: renderLegalOrganizationDetails,
  },
  {
    field: 'first_name',
  },
  {
    field: 'last_name',
  },
  {
    field: 'email',
  },
  {
    field: 'case',
    render: renderCaseDetails,
  },
  {
    field: '',
    minWidth: 60,
    render: renderRelatedToCompulsionText,
    title: 'Related to compulsion firm',
  },
  {
    field: '',
    minWidth: 120,
    render: renderVerifiedMatchNote,
    title: 'Note',
  },
  {
    field: 'verify',
    render: renderVerifyButton,
  },
].map(fillInColumnProfile);
