import React from 'react';

import SmartBool from '@mighty-justice/smart-bool';
import { FormModal } from '@mighty-justice/fields-ant';

import { IDetailLien } from '../../../../models/Lien';

interface IProps {
  isUpdatingLienBalance: SmartBool;
  lien?: IDetailLien;
}

const UpdateLienBalanceModal = (props: IProps) => (
  <FormModal
    fieldSets={[[{ field: 'balance', required: true, type: 'money' }]]}
    isVisible={props.isUpdatingLienBalance}
    onSave={props.lien?.updateBalance}
    title='Update Balance'
  />
);

export default UpdateLienBalanceModal;
