import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { observable } from 'mobx';

import Client from '../../../../base-modules/client';
import StoresClass from '../../../../stores/StoresClass';
import { IDocumentActivityData } from '../../../../models/CaseActivity';
import { EXTERNAL_URL_PROPS } from '../../../../utils/constants';

import DefaultActivity from './DefaultActivity';
import { IActivityDetailComponentProps } from './interfaces';

interface IInjected extends IActivityDetailComponentProps {
  client: Client;
  stores: StoresClass;
}

@inject('client', 'stores')
@observer
class LienDocumentActivity extends Component<IActivityDetailComponentProps> {
  @observable private downloadUrl: string | null = null;

  private get injected () { return this.props as IInjected; }

  public async componentDidMount () {
    const { activity } = this.props
      , { client, stores: { users } } = this.injected
     ;

    // the downloadUrl expires after 5 minutes, so it can't be stored in activity data
    this.downloadUrl = await activity.fetchDocumentUrl(client, users.baseEndpoint);
  }

  public render () {
    const { activity, activity: { activity_data: { document } } } = this.props
      , { name } = document as IDocumentActivityData
    ;

    return (
      <>
        <DefaultActivity activity={activity} />
        <p>
          {this.downloadUrl ? (
            <a {...EXTERNAL_URL_PROPS} href={`${this.downloadUrl}&dl=0`}>
              {name}
            </a>
          ) : name
          }
        </p>
      </>
    );
  }
}

export default LienDocumentActivity;
