import React, { Component } from 'react';
import { observer } from 'mobx-react';
import autoBindMethods from 'class-autobind-decorator';
import cx from 'classnames';

import styles from './AppHeaderItem.module.less';

import { IIcon } from '../common/Icon';
import { Badge } from '../common';

interface IProps {
  className?: string;
  count?: number;
  icon?: IIcon;
  isLoading?: boolean;
  onClick?: () => void;
}

@autoBindMethods
@observer
class AppHeaderItem extends Component<IProps> {
  private onClick () {
    const { onClick } = this.props;
    if (onClick) { onClick(); }
  }

  public render () {
    const { icon: Icon, count, isLoading } = this.props
      , className = cx(
        styles.root,
        this.props.className,
        { [styles.loading]: isLoading }
      );

    return (
      <div
        className={className}
        onClick={this.onClick}
      >
        {Icon && (
          <Badge className={styles.badge} count={count}>
            <Icon className={styles.icon} />
          </Badge>
        )}

        {this.props.children}
      </div>
    );
  }
}

export default AppHeaderItem;
