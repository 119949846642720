import React, { Component } from 'react';
import { toJS } from 'mobx';

import { IDocumentSubmitData } from '../../interfaces';
import { lienDocumentCaseCreateTableColumns } from '../page-portfolio/portfolio-detail/config/tableColumns';

import Table from './Table';
import Spacer from './Spacer';

interface IProps {
  documents: IDocumentSubmitData[];
}

class DocumentUploaderTable extends Component<IProps> {
  public tableRowKey (record: any) {
    return record.file.uid;
  }

  public render () {
    const { documents } = this.props;

    if (!documents.length) { return null; }

    return (
      <>
        <Table
          columns={lienDocumentCaseCreateTableColumns}
          dataSource={toJS(documents.slice())}
          pagination={false}
          rowKey={this.tableRowKey}
          showHeader={false}
          simple
        />
        <Spacer />
      </>
    );
  }
}

export default DocumentUploaderTable;
