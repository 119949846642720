import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import Helmet from 'react-helmet';

import { Layout } from 'antd';

import Auth from '../base-modules/auth';
import { INavigateProps } from '../utils/navigationUtils';
import navigationWrapper from '../utils/navigationWrapper';

interface IHooksProps {
  allowLoggedIn?: boolean;
  children?: React.ReactNode;
  title: string;
}

interface IProps extends IHooksProps, INavigateProps {}

interface IInjected extends IProps {
  auth: Auth;
}

@inject('auth')
@observer
class LoggedOutLandingPage extends Component<IProps> {
  public render () {
    const { title, children } = this.props;

    return (
      <Layout id='page-logged-out-landing'>
        <Layout.Content>
          <div className='wrapper-logged-out-landing'>
            <Helmet title={title} />
            <header>
              <div className='logo' />
            </header>
            <div className='inner-logged-out-landing'>
              {children}
            </div>
          </div>
        </Layout.Content>
      </Layout>
    );
  }
}

export default navigationWrapper(LoggedOutLandingPage);
