import React, { Component } from 'react';
import { observer } from 'mobx-react';
import autoBindMethods from 'class-autobind-decorator';

import SmartBool from '@mighty-justice/smart-bool';
import { FormModal } from '@mighty-justice/fields-ant';

import { IDetailLien } from '../../../../models/Lien';

import { openLienModalChildrenBefore, openLienModalFieldSets } from './constants';

import styles from '../../../common/Modal.module.less';

interface IProps {
  isVisible: SmartBool;
  lien: IDetailLien;
}

@autoBindMethods
@observer
class OpenLienModal extends Component<IProps> {
  public render () {
    const { isVisible, lien } = this.props;

    return (
      <FormModal
        className={styles.root}
        childrenBefore={openLienModalChildrenBefore}
        fieldSets={openLienModalFieldSets}
        isVisible={isVisible}
        onSave={lien.updateResolutionStatus}
        title='Are you sure you want to re-open this lien?'
      />
    );
  }
}

export default OpenLienModal;
