import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { IActivityDetailComponentProps } from './interfaces';

@observer
class CaseStatusNoChangeActivity extends Component<IActivityDetailComponentProps> {
  public render () {
    const { activity: { note } } = this.props;
    return (
      <>
        <h4>Case status verified - no update</h4>
        {note && <p className='quote'>{note}</p>}
      </>
    );
  }
}

export default CaseStatusNoChangeActivity;
