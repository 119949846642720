import React from 'react';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react';

import { Spin } from 'antd';

import { Card } from '@mighty-justice/fields-ant';
import SmartBool from '@mighty-justice/smart-bool';

import { Button, Icon } from '../../common';
import { ILegalOrganization } from '../../../models/Party';
import { URLS } from '../../../utils/constants';

import { LEGAL_ORGANIZATION_FIELD_SETS } from './accountTabFieldSets';

interface IProps {
  isLoading: SmartBool;
  legalOrganization?: ILegalOrganization;
}

function GoToOrgButton () {
  return (
    <Link to={URLS.ORGANIZATION_PAGE}>
      <Button
        icon={<Icon.ChevronDoubleRight />}
        size='small'
        type='primary'
      >
        Organization Page
      </Button>
    </Link>
  );
}

const OrganizationCard = (props: IProps) => {
  const { isLoading, legalOrganization } = props;

  return (
    <Spin spinning={isLoading.isTrue}>
      <Card
        fieldSets={LEGAL_ORGANIZATION_FIELD_SETS}
        model={legalOrganization}
        renderTopRight={GoToOrgButton}
        title='Organization'
      />
    </Spin>
  );
};

export default observer(OrganizationCard);
