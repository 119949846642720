import { Instance, types } from 'mobx-state-tree';

import { ExternalKey } from './ExternalKey';

export const CaseExternalKey = types.compose(
  ExternalKey,
  types.model('CaseExternalKey', {
    case: types.string,
  }),
);

export interface ICaseExternalKey extends Instance<typeof CaseExternalKey> {}
