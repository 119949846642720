import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import autoBindMethods from 'class-autobind-decorator';

import { Layout } from 'antd';

import AppContent from '../AppContent';
import Client from '../../base-modules/client';
import StoresClass from '../../stores/StoresClass';
import { INavigateProps } from '../../utils/navigationUtils';
import navigationWrapper from '../../utils/navigationWrapper';

import SettingsPageHeader from './SettingsPageHeader';
import SettingsPageContent from './SettingsPageContent';
import { TAB_KEYS } from './settingsUtils';

import styles from './SettingsPage.module.less';

interface IInjected extends INavigateProps {
  client: Client;
  stores: StoresClass;
}

@inject('client', 'stores')
@autoBindMethods
@observer
class SettingsPage extends Component<INavigateProps> {
  private get injected () {
    return this.props as IInjected;
  }

  public componentDidMount () {
    const { stores } = this.injected;
    stores.settings.fetch();
    stores.externalSystems.fetch();
  }

  public render () {
    const tab = this.props.params.tab || Object.values(TAB_KEYS)[0];

    return (
      <AppContent className={styles.root} narrow>
        <Layout className='page'>
          <Layout.Header>
            <SettingsPageHeader tab={tab} />
          </Layout.Header>
          <Layout.Content>
            <SettingsPageContent tab={tab} />
          </Layout.Content>
        </Layout>
      </AppContent>
    );
  }
}

export default navigationWrapper(SettingsPage);
