import React, { Component, CSSProperties } from 'react';
import { observer } from 'mobx-react';
import autoBindMethods from 'class-autobind-decorator';

import { List } from 'antd';

import SmartBool from '@mighty-justice/smart-bool';

import { INotification } from '../../../../models/Notification';
import { MightyAvatar } from '../../../common/Avatars';

import NotificationContent from '../NotificationContent';
import NotificationCreatedAt from '../NotificationCreatedAt';

export interface IDefaultNotificationProps {
  className: string;
  isVisible: SmartBool;
  notification: INotification;
  onClick: (notification: INotification) => Promise<void>;
  style: CSSProperties;
}

@autoBindMethods
@observer
class DefaultMightyNotification extends Component<IDefaultNotificationProps> {
  private onClick () {
    const { onClick, notification } = this.props;
    onClick(notification);
  }

  public render () {
    const { className, notification, style } = this.props;

    return (
      <List.Item key={notification.id} style={style} className={className} onClick={this.onClick}>
        <List.Item.Meta
          avatar={<MightyAvatar />}
          description={<NotificationCreatedAt notification={notification} />}
          title={<NotificationContent content={notification.body} notification={notification} />}
        />
      </List.Item>
    );
  }
}

export default DefaultMightyNotification;
