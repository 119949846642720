import React from 'react';
import { observer } from 'mobx-react';

import { Row } from 'antd';

import { IDetailCase } from '../../../../models/Case';
import { CASE_ACTIVITY_TYPES } from '../../../../utils/constants';
import InfoCard from '../common/InfoCard';

import styles from './ContactsTab.module.less';

interface IProps {
  _case: IDetailCase;
}

const OldLawFirmCard = observer((props: IProps) => {
  const { _case } = props
    , activity = _case.latestActivity(CASE_ACTIVITY_TYPES.ATTORNEY_CHANGED)
    , oldName = activity?.created_by_organization || 'The law firm you had on file'
    ;

  if (!activity) {
    return null;
  }

  return (
    <Row className={styles.card}>
      <InfoCard
        overrideClassName={{ wrapper: styles.disabledCard }}
        title={oldName}
        topRightContent='No longer on case'
      />
    </Row>
  );
});

export default OldLawFirmCard;
