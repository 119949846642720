import React from 'react';

import { Button as AntdButton } from 'antd';
import { ButtonProps } from 'antd/lib/button';
import cx from 'classnames';

import styles from './Button.module.less';

const defaultProps = {
  block: false,
  disabled: false,
  ghost: false,
  loading: false,
  type: 'default',
};

const Button = (props: ButtonProps) => {
  const classesArray: string[] = props.className ? props.className.split(' ') : []
    , stylesArray = classesArray.map(className => styles[className])
    , classes = cx(props.className, styles.root, styles.override, stylesArray)
    ;

  return (
    <AntdButton
      {...props}
      className={classes}
    />
  );
};

Button.defaultProps = defaultProps;

Button.Group = AntdButton.Group;

export default Button;
