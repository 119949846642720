import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import autoBindMethods from 'class-autobind-decorator';

import { Col, Row } from 'antd';

import { IDocumentSubmitData } from '../../interfaces';

import { Button, Spacer } from '../common';
import { TermsAndConditions } from '../modal-action-items-list/common';
import DocumentUploaderTable from '../common/DocumentUploaderTable';
import DocumentDropzone from '../common/DocumentDropzone';

interface IProps {
  onSubmit: (documents: IDocumentSubmitData[]) => Promise<void>;
}

@autoBindMethods
@observer
class RequestResponseForm extends Component<IProps> {
  @observable private documents: IDocumentSubmitData[] = [];

  private async handleFileSelect (documentsToUpload: IDocumentSubmitData[]) {
    this.documents = this.documents.concat(documentsToUpload);
  }

  private async handleSubmit () {
    await this.props.onSubmit(this.documents);
  }

  public render () {
    return (
      <Row>
        <Col span={12} offset={6}>
          <DocumentDropzone uploadDocuments={this.handleFileSelect} />
          <DocumentUploaderTable documents={this.documents} />
          <div style={{ clear: 'both' }}>
            <Button
              id='submit-document-response'
              style={{ float: 'right' }}
              disabled={!this.documents.length}
              onClick={this.handleSubmit}
              type='primary'
            >
              Submit
            </Button>
            <Spacer small />
            <TermsAndConditions />
          </div>
        </Col>
      </Row>
    );
  }
}

export default RequestResponseForm;
