import React, { Component } from 'react';
import { observer } from 'mobx-react';
import autoBindMethods from 'class-autobind-decorator';

import { Checkbox, Row } from 'antd';

import SmartBool from '@mighty-justice/smart-bool';

import { Alert, Button } from '../../common';

import styles from './CaseDedupeModal.module.less';

interface IProps {
  hasAgreed: SmartBool;
  isSubmitting: SmartBool;
  mergeCases: () => void;
}

@autoBindMethods
@observer
class CaseDedupeMergeSection extends Component<IProps> {
  public get alertMessage () {
    return (
      <div className={styles.warning}>
        <b>When you merge cases, data will be handled according to the following rules:</b>
        <br />
        <br />
        <p className={styles.rules}>
          1. The data above with a check mark next to it will be saved
          <br />
          2. All documents will be retained
          <br />
          3. The most recent case status will be selected
        </p>
        You will be able to view the old case in the case history.
      </div>
    );
  }

  public render () {
    const { hasAgreed, isSubmitting, mergeCases } = this.props
      , canMerge = hasAgreed.isTrue && isSubmitting.isFalse
      ;

    return (
      <Row className={styles.footer}>
        <Row>
          <Alert message={this.alertMessage} type='warning' />
        </Row>
        <Row className={styles.actions}>
          <Row className={styles.checkbox}>
            <Checkbox onChange={hasAgreed.toggle}>
              <b className={styles.agreeText}>
                I confirm that I want to merge the two cases listed above
              </b>
            </Checkbox>
          </Row>
          <Button disabled={!canMerge} onClick={mergeCases} type='primary'>
            {isSubmitting.isTrue ? 'Merging...' : 'Merge cases'}
          </Button>
        </Row>
      </Row>
    );
  }
}

export default CaseDedupeMergeSection;
