import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import { Spin } from 'antd';

import { EditableCard } from '@mighty-justice/fields-ant';
import { getNameOrDefault } from '@mighty-justice/utils';
import SmartBool from '@mighty-justice/smart-bool';

import StoresClass from '../../../stores/StoresClass';
import { getUserCardFieldSets } from './accountTabFieldSets';
import { IAccount } from '../../../models/User';

interface IProps {
  account: IAccount;
  isAdmin: boolean;
  isLoading: SmartBool;
  onSave: (model: any) => Promise<void>;
}

interface IInjected extends IProps{
  stores: StoresClass;
}

@inject('stores')
@observer
class UserCard extends Component<IProps> {
  private get injected () { return this.props as IInjected; }

  public render () {
    const { account, isAdmin, isLoading, onSave } = this.props
      , { user, isLawFirmUser } = this.injected.stores.users
      , fieldSets = getUserCardFieldSets(account.is_email_visible, isAdmin, isLawFirmUser)
      ;

    return (
      <Spin spinning={isLoading.isTrue}>
        <EditableCard
          fieldSets={fieldSets}
          model={{ ...user, ...account }}
          onSave={onSave}
          title={getNameOrDefault(user)}
        />
      </Spin>
    );
  }
}

export default UserCard;
