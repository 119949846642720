import autoBindMethods from 'class-autobind-decorator';
import { observable } from 'mobx';
import { IAnyModelType, Instance } from 'mobx-state-tree';
import { findIndex } from 'lodash';

import SmartBool from '@mighty-justice/smart-bool';

import { IListResponse } from '../base-modules/client';
import { IStateTreeDependencies } from '../base-modules/state-tree-dependencies';

// This store is meant to generically store lists of items that need an action taken on them
// such as lienholders who need contacts or cases that need case updates

@autoBindMethods
class ActionItemsListStore<T extends IAnyModelType> {
  @observable public actions: Array<Instance<T>> = [];
  @observable public isLoading = new SmartBool(true);
  private fetch: () => Promise<IListResponse>;
  private Model: T;
  private dependencies: IStateTreeDependencies;

  @observable private index = 0;

  public constructor (Model: T, fetch: () => Promise<IListResponse>, dependencies: IStateTreeDependencies) {
    this.fetch = fetch;
    this.Model = Model;
    this.dependencies = dependencies;
    this.fetchAndStore();
  }

  public async fetchAndStore (): Promise<void> {
    const response = await this.isLoading.until(this.fetch());
    this.actions = response.results.map((data: any) => this.Model.create(data, this.dependencies));
  }

  public get noActions (): boolean {
    return !this.isLoading.isTrue && this.actions.length < 1;
  }

  public remove (): void {
    const currentIndex = this.index
      , nextIndex = currentIndex + 1;

    // Wrap around
    // must reset index before splicing actions or there will be an index error
    if (nextIndex >= this.actions.length) {
      this.index = nextIndex % this.actions.length;
    }

    this.actions.splice(currentIndex, 1);
  }

  public total (): number {
    return this.actions.length;
  }

  public setIndexTo (findQ: object): void {
    const index = findIndex(this.actions, findQ);
    if (index >= 0) {
      this.index = index;
    }
  }

  public get action (): Instance<T> | null {
    if (this.index < this.actions.length) {
      return this.actions[this.index];
    }

    // istanbul ignore next
    if (this.actions.length > 0) {
      throw new Error(`Error in ActionItemsListStore: ${this.actions.length} actions but index at ${this.index}`);
    }

    // This shouldn't be reached by the view component,
    // since it will check isLoading or noActions
    // istanbul ignore next
    return null;
  }
}

export default ActionItemsListStore;
