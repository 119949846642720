import React, { Component } from 'react';
import { observer } from 'mobx-react';
import autoBindMethods from 'class-autobind-decorator';

import { IDetailCase } from '../../../../../models/Case';
import { Icon } from '../../../../common';
import { ANT_BTN_PREFIX_CLS } from '../../../../common/Buttons';

import AddDocumentButton from '../common/AddDocumentButton';
import { IBetaDocumentSubmitData } from '../interfaces';
import { formatDocumentType } from '../utils';

interface IProps {
  _case: IDetailCase;
  documentType: string;
  lienId: string;
}

@autoBindMethods
@observer
class AddSingleLienDocumentTypeButton extends Component <IProps> {
  private async uploadDocuments (documents: IBetaDocumentSubmitData[]) {
    const { _case, documentType, lienId } = this.props
      , submitData = documents.map((document: IBetaDocumentSubmitData) => (
        { ...document, type: documentType, lien: lienId }
      ));

    await _case.bulkUploadDocuments(submitData);
  }

  public render () {
    const { _case, documentType } = this.props
      , modalProps = {
        title: `Upload ${formatDocumentType(documentType)}`,
        saveText: 'Upload',
        hideFields: true,
      };

    return (
      <AddDocumentButton
        _case={_case}
        buttonProps={{ className: `${ANT_BTN_PREFIX_CLS}-empty`, id: 'upload-document' }}
        buttonText='Upload Document'
        icon={Icon.CloudUploadAlt}
        iconPosition='front'
        uploadDocuments={this.uploadDocuments}
        modalProps={modalProps}
        multiple={false}
      />
    );
  }
}

export default AddSingleLienDocumentTypeButton;
