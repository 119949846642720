import React, { Component } from 'react';
import { observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import autoBindMethods from 'class-autobind-decorator';

import { Col, Divider, Popover, Row } from 'antd';

import SmartBool from '@mighty-justice/smart-bool';

import StoresClass from '../../../stores/StoresClass';
import { Icon } from '../../common';

import RadioOpen, { getLabelForOpen } from './RadioOpen';
import RadioSort, { getLabelForSort } from './RadioSort';

import styles from './PortfolioListScrollHeader.module.less';

interface IInjected {
  stores: StoresClass;
}

@inject('stores')
@autoBindMethods
@observer
class PortfolioListScrollHeader extends Component {
  @observable private isFilterPopoverVisible = new SmartBool();

  private get injected () { return this.props as IInjected; }

  private get label () {
    const {
        stores: {
          users: { isLawFirmUser },
          infiniteTableStore: { filterQuery, ignoredFilterQuery },
        },
      } = this.injected
      , openLabel: string = getLabelForOpen(isLawFirmUser, filterQuery)
      , sortLabel: string | undefined = getLabelForSort(ignoredFilterQuery)
      ;

    return `${openLabel}${sortLabel ? ` by ${sortLabel}` : ''}`;
  }

  private renderDropdown () {
    return (
      <div className={styles.dropdown}>
        <RadioOpen isPopoverVisible={this.isFilterPopoverVisible} />
        <Divider />
        <RadioSort isPopoverVisible={this.isFilterPopoverVisible} />
      </div>
    );
  }

  public render () {
    const { stores: { infiniteTableStore } } = this.injected;

    return (
      <Row align='top' className={styles.root} justify='space-between'>
        <Col>
          <Popover
            className='sort-by-button'
            content={this.renderDropdown()}
            onVisibleChange={this.isFilterPopoverVisible.set}
            placement='bottomLeft'
            title='Sort by:'
            trigger='click'
            visible={this.isFilterPopoverVisible.isTrue}
          >
            <Icon.SortAmountDown />
            {' '}<span className={styles.toggle}> {this.label}</span>
            {' '}<span>({infiniteTableStore.count})</span>
          </Popover>
        </Col>
      </Row>
    );
  }
}

export default PortfolioListScrollHeader;
