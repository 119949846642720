import React, { Component } from 'react';
import { IActivityDetailComponentProps } from './interfaces';
import { observer } from 'mobx-react';

@observer
class CaseInfoUpdatedActivity extends Component<IActivityDetailComponentProps> {
  public render () {
    return <h4>Case info updated</h4>;
  }
}

export default CaseInfoUpdatedActivity;
