/* eslint-disable max-lines */
import { find, groupBy, has, isEmpty, isEqual, sortBy, sum } from 'lodash';
import { differenceInCalendarDays, parseISO } from 'date-fns';
import jsonToFormData from 'json-form-data';
import {
  applySnapshot,
  cast,
  destroy,
  flow,
  getParent,
  getSnapshot,
  Instance,
  isAlive,
  types,
} from 'mobx-state-tree';

import { toKey } from '@mighty-justice/utils';

import { IDocumentFilter } from '../interfaces';
import { getQueryString, IQuery, mergePathAndQuery } from '../utils/navigationUtils';
import { stateTreeDependencyGetters } from '../base-modules/state-tree-dependencies';
import { IMergeCaseData } from '../components/page-portfolio/dedupe/interfaces';
import { IBetaDocumentSubmitData } from '../components/page-portfolio/portfolio-detail/tab-documents/interfaces';
import {
  CASE_ACTIVITY_TYPES,
  CASE_STATUS_FIELDS,
  CASE_STATUS_WITH_DATE,
  CLOSED_CASE_STATUSES,
  CONVERSATION_CASE_THREAD_TYPES,
  DOCUMENT_TYPES,
  NON_REJECTED_STATUS_QUERY_STRING,
  ORGANIZATION_TYPES,
  REQUEST_CLOSE_STATUS,
  URLS,
} from '../utils/constants';

import {
  hasValueChanged,
  isStatusOutOfDate,
  mapValues,
  setValuesFromData,
  validateBirthdateOrDOL,
} from '../utils/utils';

import { DetailLien, IDetailLien } from './Lien';
import { ICaseActivity, ModelWithActivities } from './CaseActivity';
import { CaseThread, ICaseThread } from './CaseThread';
import { CaseContact, IContact } from './Contact';
import { BetaDocument, IBetaDocument, ModelWithDirectDocumentUpload } from './Document';
import { CaseExternalKey } from './CaseExternalKey';
import { ModelWithTags } from './Tag';
import { IExternalKey } from './ExternalKey';
import { ILegalOrganizationExternalSystem } from './LegalOrganizationExternalSystem';
import { IPolicyLimit, PolicyLimit } from './PolicyLimit';
import { IPortfolio } from './Portfolio';
import { ILegalOrganization, LawFirm, LegalOrganization, Person } from './Party';

const ListCaseFields = {
  date_of_loss: types.maybeNull(types.string),
  has_warning: types.maybeNull(types.boolean),
  id: types.identifier,
  last_updated_at: types.maybeNull(types.string),
  law_firm: types.maybeNull(LegalOrganization),
  plaintiff: Person,
  status: types.maybeNull(types.string),
};

export const ListCase = types
  .model('ListCase', ListCaseFields)
  .views(stateTreeDependencyGetters)
  .views(self => ({
    get isOpen () {
      return self.status ? !has(CLOSED_CASE_STATUSES, self.status) : true;
    },

    get isOutOfDate () {
      return isStatusOutOfDate(self.last_updated_at) && this.isOpen;
    },

    get updatedDaysAgo (): null | number {
      if (self.last_updated_at === null) { return null; }
      return differenceInCalendarDays(new Date(), parseISO(self.last_updated_at));
    },
  }))
  ;

export const DetailCase = types
  .compose(
    ModelWithActivities,
    ModelWithDirectDocumentUpload,
    ModelWithTags,
    types.model('DetailCase', {
      ...ListCaseFields,
      // overrides list law firm to have contacts field
      law_firm: types.maybeNull(LawFirm),
      law_firm_last_active_at: types.maybeNull(types.string),
      appearance_date: types.maybeNull(types.string),
      case_contacts: types.array(CaseContact),
      created_at: types.maybeNull(types.string),
      created_by_organization: types.maybeNull(types.string),
      date_expected_to_receive_check: types.maybeNull(types.string),
      documents: types.optional(types.array(BetaDocument), []),
      download_all_documents_count: types.maybeNull(types.number),
      download_all_documents_url: types.maybeNull(types.string),
      has_single_lienholder: types.maybeNull(types.boolean),
      is_editable_by_lienholder: types.maybeNull(types.boolean),
      lawsuit_filed_date: types.maybeNull(types.string),
      last_filevine_sync_at: types.maybeNull(types.string),
      policy_limits: types.optional(types.array(PolicyLimit), []),
      state_of_incident: types.maybeNull(types.string),
      type: types.maybeNull(types.string),

      // These fields are null until they are fetched
      external_keys: types.optional(types.maybeNull(types.array(CaseExternalKey)), null),
      liens: types.optional(types.maybeNull(types.array(DetailLien)), null),
      tagUrl: '/cases-tags/',
      threads: types.optional(types.maybeNull(types.array(CaseThread)), null),
    }),
  )
  .views(stateTreeDependencyGetters)
  .views(self => ({
    get portfolio (): IPortfolio {
      return getParent(self, 2);
    },
  }))
  .views(self => ({
    findLien (lienId: string): IDetailLien | undefined {
      return self.liens?.find((lien: IDetailLien) => lien.id === lienId);
    },

    get userLien (): IDetailLien | undefined {
      const legalOrganizationId = self.users.registryLegalOrganization?.id;

      if (self.users.isLawFirmUser || !legalOrganizationId) { return undefined; }

      return self.liens?.find((lien: IDetailLien) => lien.lienholder.id === legalOrganizationId);
    },

    get otherLiens (): IDetailLien[] {
      const legalOrganizationId = self.users.registryLegalOrganization?.id;

      return self.liens?.filter((lien: IDetailLien) => lien.lienholder.id !== legalOrganizationId) || [];
    },

    get hasLiens (): boolean {
      return !!self.liens?.length;
    },

    get numUnreadNotes (): null | number {
      if (!this.hasLiens) { return null; }

      return sum(self.liens?.map(lien => lien.unreadNotes.length));
    },

    get firstExternalKey (): string | undefined {
      if (self.external_keys?.length) {
        return self.external_keys[0].registry_external_id;
      }

      return undefined;
    },

    getOrganization (organizationId: string | null): ILegalOrganization | null {
      const isLawFirm = self.law_firm?.id === organizationId
        , lienholder = self.liens?.find((lien: IDetailLien) => lien.lienholder.id === organizationId)?.lienholder;

      if (isLawFirm) {
        return self.law_firm;
      }

      if (lienholder?.id === organizationId) {
        return lienholder;
      }

      return null;
    },

    get newThreads (): ICaseThread[] {
      // istanbul ignore next
      if (!self.threads) { return []; }

      return self.threads.filter((thread: ICaseThread) => {
        if (thread.type && CONVERSATION_CASE_THREAD_TYPES.includes(thread.type)) {
          return thread.action_needed;
        }
        return !thread.is_resolved;
      });
    },

    get resolvedThreads (): ICaseThread[] {
      // istanbul ignore next
      if (!self.threads) { return []; }

      return self.threads.filter((thread: ICaseThread) => {
        if (thread.type && CONVERSATION_CASE_THREAD_TYPES.includes(thread.type)) {
          return !thread.action_needed;
        }
        return !!thread.is_resolved;
      });
    },

    get lienEndpoint (): string {
      return `/beta/${self.baseEndpoint}/liens/`;
    },

    get detailEndpoint (): string {
      return `/beta/${self.baseEndpoint}/portfolio/${self.id}/`;
    },

    getLienDocument (lienId: string, type: string): IBetaDocument | undefined {
      // Reverse documents to get most recent
      // this is temporary solution for handling HIPAA releases and Letter of requests on document requests
      return sortBy(self.documents, 'created_at').reverse().find((document: IBetaDocument) => (
        document.lien === lienId && document.type === type
      ));
    },

    getDocumentsQueryString (filters?: IDocumentFilter): string {
      const allFilters = { case: self.id, ...filters };
      return toKey(mapValues(allFilters, v => v, v => !isEmpty(v)));
    },
  }))
  // eslint-disable-next-line max-statements
  .actions(self => {
    const setLiens = (data: any[]) => {
      const caseLiens: IDetailLien[] = data.map((lienData: IDetailLien) => DetailLien.create(lienData));

      if (self.liens) {
        self.liens.replace(caseLiens);
      }
      else {
        self.liens = cast(caseLiens);
      }
    };

    const updateListCase = (caseData: IDetailCase) => {
      self.portfolio.setListCase(caseData);
    };

    const fetchLiens = flow(function* () {
      const rejectedFilter = self.users.isLawFirmUser ? { verification_status: NON_REJECTED_STATUS_QUERY_STRING } : {}
        , endpoint = mergePathAndQuery(self.lienEndpoint, {
          page_size: 'off',
          case: self.id,
          ...rejectedFilter,
        })
        , liens = yield self.client.get(endpoint);

      if (!isAlive(self)) { return; }

      setLiens(liens);

      // TODO: Find a better place to fetch related_lienholders
      if (self.users.hasMultipleAccounts && self.userLien) {
        yield self.userLien.fetchRelatedLienholders();
      }
    });

    const createDetailLien = (lienData: IDetailLien): IDetailLien => {
      const lien = DetailLien.create(lienData);
      // lien contacts will be empty on create
      lien.lienholder.setContacts([]);

      if (self.liens === null) {
        self.liens = cast([lien]);
      }
      else {
        self.liens.push(lien);
      }

      return lien;
    };

    const removeLien = (lien: IDetailLien) => {
      destroy(lien);
    };

    // TODO: IDetailLien should be passed into this function from createLawFIrmDocumentRequest
    const addNewLien = flow(function* (lienholderId: string) {
      const lienEndpoint = mergePathAndQuery(self.lienEndpoint, { case: self.id, lienholder: lienholderId })
        , lien = (yield self.client.get(lienEndpoint)).results[0];

      return createDetailLien(lien);
    });

    const createLawFirmDocumentRequest = flow(function* (submitData, isNewLien: boolean) {
      const currentLien = self.findLien(submitData.lien.id)
        , letterOfRequest = currentLien && self.getLienDocument(currentLien.id, DOCUMENT_TYPES.LETTER_OF_REQUEST)
        ;

      if (letterOfRequest) {
        submitData.letter_of_request = letterOfRequest.id;
      }

      const response = yield self.client.create('/escrow-agent/lien-document-requests/', submitData);

      if (isNewLien) {
        // TODO: Send over response data as IDetailLien once IDetailLien objcet is returned from backend
        yield addNewLien(response.data.lien.lienholder.id);
      }
    });

    const fetchActivities = flow(function* (caseOnly: boolean | null = null) {
      const qs = toKey({ case: self.id, case_only: caseOnly, page_size: 'off' })
        , response = yield self.client.get(`/${self.baseEndpoint}/activities/${qs}`)
      ;

      if (!isAlive(self)) { return; } // Case may have been destroyed during yield

      const activitiesByLien: { [key: string]: ICaseActivity[] } = groupBy(response, 'lien')
        , caseActivities: ICaseActivity[] = activitiesByLien.null || [];

      self.setActivities(caseActivities);

      if (self.liens) {
        self.liens.forEach(lien => {
          const lienActivities = activitiesByLien[lien.id];

          if (lienActivities) {
            lien.setActivities(lienActivities);
          }
        });
      }
    });

    const setDownloadAllDocumentsLink = flow(function* (filters?: IDocumentFilter) {
      if (self.users.isLawFirmUser) {
        const response = yield self.client.get(
          `${URLS.DOCUMENTS_BETA}/download-all-documents-url/${self.getDocumentsQueryString(filters)}`
        );

        setValuesFromData(self, response);
      }
    });

    const fetchDocuments = flow(function* (filters?: IDocumentFilter) {
      setDownloadAllDocumentsLink(filters);

      const response = yield self.client.get(`${URLS.DOCUMENTS_BETA}/${self.getDocumentsQueryString(filters)}`);

      if (!isAlive(self)) { return; } // Case may have been destroyed during yield
      self.documents.replace(response.results);
    });

    const uploadDocument = flow(function* (fileData: IBetaDocumentSubmitData, newDocumentFetch: boolean) {
      const createPath = fileData.lien ? `/${self.baseEndpoint}/lien-documents/` : '/case-documents/'
        , data = { case: self.id, ...fileData }
        , createResponse = yield self.uploadDocumentDirect(createPath, data, 'file')
        ;

      if (!isAlive(self)) { return; } // Case may have been destroyed during yield

      if (!newDocumentFetch) {
        self.documents.push(createResponse.data);
      }
    });

    const bulkUploadDocuments = flow(function* (fileList: IBetaDocumentSubmitData[], filters?: IDocumentFilter) {
      const newDocumentFetch = self.users.isLawFirmUser && !isEmpty(filters);

      yield Promise.all(fileList.map(async (file: IBetaDocumentSubmitData) => uploadDocument(file, newDocumentFetch)));

      if (newDocumentFetch) {
        yield fetchDocuments(filters);
      }
      else {
        yield setDownloadAllDocumentsLink(filters);
      }

      fetchActivities();
    });

    const editDocument = flow(function* (document: any) {
      const original = find<IBetaDocument>(self.documents, { id: document.id });

      if (original === undefined) { throw new Error('No lien document found'); }

      const endpoint = original.lien ? `/${self.baseEndpoint}` : ''
        , result = yield self.client.update(`${endpoint}/${original.path}/${document.id}/`, document);

      setValuesFromData(original, result.data);
    });

    const deleteDocument = flow(function* (document: IBetaDocument, filters?: IDocumentFilter) {
      const baseEndpoint = document.lien ? `${self.baseEndpoint}/` : '';

      yield self.client.delete(`/${baseEndpoint}${document.path}/${document.id}`);

      destroy(document);

      yield setDownloadAllDocumentsLink(filters);
      fetchActivities();
    });

    const filterDocumentsToLienDocuments = (
      documents: IBetaDocument[],
      lawFirmId: string | undefined
    ) =>
      documents.filter((document: IBetaDocument) => {
        const isLienholderDocument =
          document.created_by_organization !== lawFirmId;

        return isLienholderDocument && document.isLienholderBaseDocument;
      });


    const createViewDocumentActivityData = (documents: IBetaDocument[]) => {
      const submitData = documents.map((document: IBetaDocument) => (
        {
          activity_data: { document_id: document.id },
          case: self.id,
          lien: document.lien,
          type: CASE_ACTIVITY_TYPES.LIEN_DOCUMENT_VIEWED,
        }
      ));

      return submitData;
    };

    const createViewLienDocumentActivities = flow(function* (documents: IBetaDocument[]) {
      const lienDocuments = filterDocumentsToLienDocuments(documents, self.law_firm?.id);

      if (self.users.isLawFirmUser && !!lienDocuments.length) {
        const submitData = createViewDocumentActivityData(lienDocuments)
          , response = yield self.client.create('/escrow-agent/activities/', submitData)
          ;

        lienDocuments.forEach((document: IBetaDocument) => {
          const responseDocument = response.data.find((data: any) => (
              data.activity_data.document_id = document.id
            ))
            , last_opened_at = responseDocument.created_at
            ;

          setValuesFromData(document, { last_opened_at }, ['last_opened_at']);
        });
      }
    });

    const fetchPolicyLimits = flow(function* () {
      const response = yield self.client.get(`/policy-limits/?case=${self.id}`);
      if (!isAlive(self)) { return; } // Case may have been destroyed during yield
      self.policy_limits = response.results;
    });

    const addPolicyLimit = flow(function* (data: object) {
      const policyLimit = yield self.client.create(`/policy-limits/`, { ...data, case: self.id });
      if (!isAlive(self)) { return; } // Case may have been destroyed during yield
      self.policy_limits.push(policyLimit.data);
      fetchActivities();
    });

    const deletePolicyLimit = flow(function* (policyLimit: IPolicyLimit) {
      yield self.client.delete(`/policy-limits/${policyLimit.id}`);
      destroy(policyLimit);
    });

    const update = flow(function* (data: any) {
      validateBirthdateOrDOL(data);

      const response = yield self.client.update(`/beta/${self.baseEndpoint}/portfolio/${self.id}/`, data)
        , activityData = { activity_data: data, case: self.id, type: CASE_ACTIVITY_TYPES.CASE_INFO_UPDATED }
        , fieldsToUpdate = ['date_of_loss', 'state_of_incident', 'plaintiff', 'type']
        , caseData = response.data
        ;

      if (self.users.isLawFirmUser) {
        yield self.client.create(`/${self.baseEndpoint}/activities/`, activityData);
      }

      if (!isAlive(self)) { return; } // Case may have been destroyed during yield
      fetchActivities(true);
      setValuesFromData(self, caseData, fieldsToUpdate);
      applySnapshot(self.plaintiff, caseData.plaintiff);

      updateListCase(caseData);
    });

    const mergeCases = flow(function* (data: IMergeCaseData) {
      const response = yield self.client.create(`/beta/${self.baseEndpoint}/portfolio/merge/`, data);
      return response.data.canonical_case_id;
    });

    const fetchPreviewSummary = flow(function* (case_ids: string[]) {
      const previewSummary = yield self.client.create(
        `/beta/${self.baseEndpoint}/portfolio/preview-merge/`,
        { case_ids },
      );
      return previewSummary.data;
    });

    const addNewExternalSystem = flow(function* (
      externalSystemIdentifier: string,
      externalSystem: ILegalOrganizationExternalSystem,
    ) {
      const submitData = {
          case: self.id,
          registry_external_id: externalSystemIdentifier,
          registry_legal_organization_external_system: externalSystem,
        }
        // TODO - use base endpoint?
        , newExternalSystem = yield self.client.create('/escrow-agent/external-keys/', submitData);

      if (!isAlive(self)) { return; } // Case may have been destroyed during yield

      if (self.external_keys !== null) {
        self.external_keys.push(newExternalSystem.data);
      }
    });

    const _getOrCreateLawFirm = flow(function* (lawFirmData?: { id?: string }) {
      if (!lawFirmData) { return null; }
      if (lawFirmData.id) { return lawFirmData.id; }

      const response = yield self.client.create('/beta/organizations/', {
        type: ORGANIZATION_TYPES.LAW_FIRM,
        ...lawFirmData,
      });

      return response?.data?.id || null;
    });

    const changeLawFirm = flow(function* (caseData) {
      const lawFirmId = yield _getOrCreateLawFirm(caseData.law_firm)
        , form = jsonToFormData({
          note: caseData.note,
          law_firm: lawFirmId,
          documents: caseData.documents.map((document: IBetaDocumentSubmitData) => ({
            case: self.id,
            file: document.file,
            name: document.name,
            type: DOCUMENT_TYPES.CHANGE_OF_REPRESENTATION,
          })),
        });

      yield self.client.update(`/beta/${self.baseEndpoint}/portfolio/${self.id}/change-law-firm/`, form);
      if (!isAlive(self)) { return; } // Case may have been destroyed during yield
      self.portfolio.hydrate(null, getQueryString());
    });

    const updateCaseStatus = flow(function* (caseData: any) {
      // TODO: refactor to handle hasStatusChanged and hasStatusDateChanged logic on the backend
      const hasStatusChanged = !isEqual(self.status, caseData.status)
        , statusDateFields = Object.values(CASE_STATUS_WITH_DATE).map(config => config.field)
        , hasStatusDateChanged = statusDateFields.some(field => hasValueChanged(self, caseData, field))
        , shouldIncludeData = hasStatusChanged || hasStatusDateChanged
        , updateData = shouldIncludeData ? caseData : { status_no_change: true, note: caseData.note }
        ;

      const response = yield self.client.update(`/beta/${self.baseEndpoint}/portfolio/${self.id}/`, updateData);
      if (!isAlive(self)) { return; } // Case may have been destroyed during yield

      // cannot applySnapshot here or other case data, like liens, disappear
      setValuesFromData(self, response.data, CASE_STATUS_FIELDS);
      fetchActivities(true);

      updateListCase(response.data);
    });

    const fetchThreads = flow(function* (queryArg?: IQuery) {
      const query: IQuery = {
        case: self.id,
        page_size: 'off',
        ...queryArg,
      }
        , response = yield self.client.get(mergePathAndQuery('/case-threads/', query))
      ;

      if (!isAlive(self)) { return; } // Case may have been destroyed during yield
      self.threads = response;
    });

    const setThreads = (threadData: ICaseThread) => {
      const foundThread = self.threads?.find((thread: ICaseThread) => thread.id === threadData.id);

      if (foundThread) {
        applySnapshot(foundThread, { ...getSnapshot(foundThread), ...threadData });
      }
    };

    const sendRequestResponse = flow(function* (data: any, thread: ICaseThread) {
      const submitData = { ...data, request: thread.request }
        , closeRequest = data.new_status === REQUEST_CLOSE_STATUS.CLOSED_BY_LAW_FIRM
        , response = yield self.client.create('/lien-document-responses/', submitData)
        ;

      if (closeRequest) {
        yield thread.resolve();
      }

      fetchActivities(true);
      return response;
    });

    const fetchExternalSystemIds = flow(function* () {
      const url = mergePathAndQuery(`/escrow-agent/external-keys/`, { case: self.id })
        , response = yield self.client.get(url);

      if (!isAlive(self)) { return; } // Case may have been destroyed during yield

      if (self.external_keys === null) {
        self.external_keys = response.results;
      }
      else {
        self.external_keys.replace(response.results);
      }
    });

    const deleteExternalSystemId = flow(function* (externalSystem: IExternalKey) {
      yield self.client.delete(`/escrow-agent/external-keys/${externalSystem.id}`);
      destroy(externalSystem);
    });

    const addLien = flow(function* (lienholderId: string) {
      yield self.client.create(self.lienEndpoint, { lienholder: lienholderId, case: self.id });

      const lienEndpoint = mergePathAndQuery(self.lienEndpoint, { case: self.id, lienholder: lienholderId })
        , createdLienResult = (yield self.client.get(lienEndpoint)).results
        ;

      if (!createdLienResult.length) {
        throw new Error('An error occurred. Please refresh the page.');
      }

      return createDetailLien(createdLienResult[0]);
    });

    const fetchContacts = flow(function* () {
      const response = yield self.client.get(`${URLS.CONTACTS_BETA}?case=${self.id}`);
      if (!isAlive(self)) { return; } // Case may have been destroyed during yield

      // group contacts by legal_organization and set on lawFirm and lienholder
      const contactsByLegalOrganization: { [key: string]: IContact[] } = groupBy(response.results, 'legal_organization')
        , lawFirmContacts = self.law_firm ? contactsByLegalOrganization[self.law_firm.id] : []
        ;

      if (self.law_firm) {
        self.law_firm.setContacts(lawFirmContacts || []);
      }

      if (self.liens) {
        self.liens.forEach(lien => {
          const lienholderContacts = contactsByLegalOrganization[lien.lienholder.id] || [];

          lien.lienholder.setContacts(lienholderContacts);
        });
      }
    });

    const addContact = flow(function* (contactId: string, shouldFetchActivities: boolean = true) {
      const response = yield self.client.update(`${self.detailEndpoint}add-contact/`, { contact: contactId })
        , contactData = response.data;

      self.case_contacts.push(contactData);

      if (shouldFetchActivities) {
        fetchActivities();
      }
    });

    const removeContact = flow(function* (contactId: string) {
      yield self.client.update(`${self.detailEndpoint}remove-contact/`, { contact: contactId });

      fetchActivities();
    });

    // This function returns a case ID which, if different, should be navigated to
    const lienholderChangeLawFirm = flow(function* (lawFirmId: string) {
      const endpoint = `${self.detailEndpoint}change-law-firm/`
        , response = yield self.client.update(endpoint, { law_firm: lawFirmId })
        , { id: newCaseId, law_firm: newLawFirmID } = response.data;

      if (newCaseId === self.id) {
        self.law_firm = (yield self.client.get(`/beta/organizations/${newLawFirmID}/`));
      }

      return newCaseId;
    });

    // To be called after initialization
    const load = () => {
      fetchThreads();
      fetchActivities();
      fetchContacts();
    };

    return {
      addContact,
      addLien,
      addNewExternalSystem,
      addPolicyLimit,
      bulkUploadDocuments,
      changeLawFirm,
      createViewLienDocumentActivities,
      createLawFirmDocumentRequest,
      deleteDocument,
      deleteExternalSystemId,
      deletePolicyLimit,
      editDocument,
      fetchContacts,
      fetchDocuments,
      fetchExternalSystemIds,
      fetchLiens,
      fetchPolicyLimits,
      fetchPreviewSummary,
      fetchThreads,
      lienholderChangeLawFirm,
      load,
      mergeCases,
      removeContact,
      removeLien,
      sendRequestResponse,
      setLiens,
      setThreads,
      update,
      updateCaseStatus,
    };
  })
  ;

export interface IListCase extends Instance<typeof ListCase> {}
export interface IDetailCase extends Instance<typeof DetailCase> {}
