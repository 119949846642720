import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import autoBindMethods from 'class-autobind-decorator';

import SmartBool from '@mighty-justice/smart-bool';

import { Button, Modal, ModalProps } from 'antd';

import { IBetaDocument } from '../../../../../models/Document';

import PayToOpen from './PayToOpen';

import styles from './LawFirmAccessDocumentModal.module.less';

interface IProps extends ModalProps {
  document: IBetaDocument;
  isVisible: SmartBool;
}

@inject('stores')
@autoBindMethods
@observer
class LawFirmAccessDocumentModal extends Component<IProps> {
  public render () {
    const { document, isVisible } = this.props;

    return (
      <Modal className={styles.root} footer={null} onCancel={isVisible.setFalse} visible={isVisible.isTrue}>
        <p className={styles.message}>
          This is a paid document, you will need to make the required payment before you can access it
        </p>
        <div className={styles['access-document-buttons']}>
          <Button className={styles.cancel} onClick={isVisible.setFalse}>Cancel</Button>
          <PayToOpen buttonProps={{ type: 'primary' }} document={document} isButton linkText='Make payment' />
        </div>
      </Modal>
    );
  }
}

export default LawFirmAccessDocumentModal;
