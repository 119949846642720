import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import autoBindMethods from 'class-autobind-decorator';

import { Row } from 'antd';

import StoresClass from '../../../../../stores/StoresClass';
import { IAccount } from '../../../../../models/User';
import { IDetailCase } from '../../../../../models/Case';

import RelatedLienholdersLinkItem from './RelatedLienholdersLinkItem';

interface IProps {
  _case: IDetailCase;
}

interface IInjected extends IProps {
  stores: StoresClass;
}

@inject('stores')
@autoBindMethods
@observer
class RelatedLienholdersLinkList extends Component<IProps> {
  private get injected () { return this.props as IInjected; }

  private get relatedLienholdersListItem () {
    const { _case, _case: { userLien } } = this.props
      , { stores: { users: { accounts } } } = this.injected
      ;

    return userLien?.related_lienholders.map((id: string, index: number) => {
      const account = accounts?.find((registryAccount: IAccount) => (
        registryAccount.registry_legal_organization.id === id
      ));

      return account && <RelatedLienholdersLinkItem _case={_case} account={account} key={index} />;
    });
  }

  public render () {
    return (
      <Row>
        {this.relatedLienholdersListItem}
      </Row>
    );
  }
}

export default RelatedLienholdersLinkList;
