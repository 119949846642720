import React from 'react';
import { observer } from 'mobx-react';

import { getNameOrDefault } from '@mighty-justice/utils';

import { IContact } from '../../../../models/Contact';
import { ILegalOrganizationWithContacts } from '../../../../models/Party';
import { joinOxford } from '../../../../utils/utils';
import { Icon, Tooltip } from '../../../common';

import styles from './ContactsTab.module.less';

interface IProps {
  legalOrganization: ILegalOrganizationWithContacts;
}

const ContactDisplay = observer((props: IProps) => {
  const { legalOrganization } = props
    , contacts = legalOrganization.contacts
    ;

  if (contacts === null) {
    return (
      <>
        <Icon.SpinnerThird primary spin size='1x' /> Loading...
      </>
    );
  }

  if (contacts.length === 0) {
    return (
      <Tooltip
        arrowPointAtCenter
        overlayClassName={styles.missingContactTooltip}
        placement='bottom'
        show
        title={`
          At the moment, we don't know which specific person at ${legalOrganization.name}
          we should contact to join this case. We'll do our best to find generally available contact information
        `}
      >
        <Icon.ExclamationCircle /> Missing contact information
      </Tooltip>
    );
  }

  const contactNames = contacts.map((contact: IContact) => getNameOrDefault(contact))
    , contactCount = contacts.length
    , contactsToRender = contacts.length > 3
      ? [contactNames[0], contactNames[1], `${contactCount - 2} others`]
      : contactNames
  ;

  return (
    <>
      {joinOxford(contactsToRender)}
    </>
  );
});

export default ContactDisplay;
