import { ComponentType } from 'react';

import FacetDate from './inputs/FacetDate';
import FacetObjectSearch from './inputs/FacetObjectSearch';
import FacetOptionSelect from './inputs/FacetOptionSelect';
import FacetString from './inputs/FacetString';
import FacetToggle from './inputs/FacetToggle';

export const COMPONENTS: { [type: string]: ComponentType<any> } = {
  date: FacetDate,
  objectSearch: FacetObjectSearch,
  optionSelect: FacetOptionSelect,
  string: FacetString,
  toggle: FacetToggle,
};
