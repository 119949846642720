import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import autoBindMethods from 'class-autobind-decorator';

import StoresClass from '../../../../../stores/StoresClass';
import { IExternalKey } from '../../../../../models/ExternalKey';
import { IStake } from '../../../../../models/stake';
import { Tag, Tooltip } from '../../../../common';

import styles from '../TagsAndSystemKeys.module.less';

const MAX_LENGTH = 20;

interface IProps {
  stake: IStake;
  externalKey: IExternalKey;
}

interface IInjected extends IProps {
  stores: StoresClass;
}

@inject('stores')
@autoBindMethods
@observer
class ExternalKey extends Component<IProps> {
  private get injected () { return this.props as IInjected; }

  private async remove () {
    const { stake, externalKey } = this.props
      , { stores: { infiniteTableStore } } = this.injected;

    await stake.deleteExternalSystemId(externalKey);

    // This is to refresh the external key facet counts
    infiniteTableStore.fetchFacetCounts();
  }

  public render () {
    const { externalKey, externalKey: { id, registry_external_id } } = this.props
      , showTooltip = registry_external_id.length > MAX_LENGTH;

    return (
      <Tooltip show={showTooltip} title={registry_external_id} key={id}>
        <Tag
          closable
          key={id}
          onClose={this.remove}
          onCloseKey={externalKey}
        >
          <span className={styles.text}>
            <span className={styles['text-inner']}>
              {registry_external_id}
            </span>
          </span>
        </Tag>
      </Tooltip>
    );
  }
}

export default ExternalKey;
