import React, { Component } from 'react';
import { observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import autoBindMethods from 'class-autobind-decorator';
import { head } from 'lodash';

import { Badge, Dropdown, Menu } from 'antd';
import { MenuInfo } from 'rc-menu/es/interface';

import SmartBool from '@mighty-justice/smart-bool';

import { IDetailCase } from '../../../../models/Case';
import { IDetailLien } from '../../../../models/Lien';
import StoresClass from '../../../../stores/StoresClass';
import { CASE_ACTIVITY_TYPES } from '../../../../utils/constants';
import { Button, Icon, Tooltip } from '../../../common';

import ChatModal from './ChatModal';

interface IProps {
  _case: IDetailCase;
  lien?: IDetailLien;
}

interface IInjected extends IProps {
  stores: StoresClass;
}

@inject('stores')
@autoBindMethods
@observer
class ChatButton extends Component<IProps> {
  @observable private isDropdownVisible = new SmartBool();
  @observable private isModalVisible = new SmartBool();
  @observable private isModalSaving = new SmartBool();
  @observable private inputValue = '';
  @observable private selectedLienId: string | undefined;

  private get injected () { return this.props as IInjected; }

  private get liens (): null | IDetailLien[] {
    const { _case, lien } = this.props;
    if (lien) { return [lien]; }
    return _case.liens;
  }

  private get lien (): undefined | IDetailLien {
    if (!this.liens) {
      return undefined;
    }

    if (this.liens.length === 1) { return head(this.liens); }
    return this.liens.find(liensLien => liensLien.id === this.selectedLienId);
  }

  private openModal (): void {
    if (!!this.lien?.unreadNotes.length) {
      this.lien.updateReadAt();
    }
    this.isModalVisible.setTrue();
  }

  private handleVisibleChange (isVisible: boolean): void {
    if (this.liens && this.liens.length > 1) {
      this.isDropdownVisible.set(isVisible);
    }
    else {
      this.openModal();
    }
  }

  private handleCancel (): void {
    this.isModalVisible.setFalse();
    this.selectedLienId = undefined;
  }

  private handleDropdownClick (clickParam: MenuInfo): void {
    this.isDropdownVisible.setFalse();
    this.selectedLienId = clickParam.key;
    this.openModal();
  }

  private onInputChange (value: string) {
    this.inputValue = value;
  }

  private async onModalSave () {
    const { isLawFirmUser } = this.injected.stores.users
      , type = isLawFirmUser
        ? CASE_ACTIVITY_TYPES.LAW_FIRM_POSTED_NOTE
        : CASE_ACTIVITY_TYPES.LIENHOLDER_POSTED_NOTE
      ;

    if (!this.inputValue || !this.lien) { return; }

    await this.isModalSaving.until(this.lien.createActivity({ note: this.inputValue, type }));

    this.inputValue = '';
  }

  private get menu (): React.ReactElement {
    return (
      <Menu onClick={this.handleDropdownClick}>
        {this.liens?.map(lien => {
          const name = lien.lienholder.name
            , notesLen = lien.notes.length;

          return (
            <Menu.Item key={lien.id}>
              {name} {!!notesLen && `(${notesLen})`}
            </Menu.Item>
          );
        })}
      </Menu>
    );
  }

  public render () {
    const { _case } = this.props
      , disabled = !this.liens?.length
      , loading = !_case.activities || !this.liens
      , chatModalPassDownProps = {
        isSaving: this.isModalSaving,
        inputValue: this.inputValue,
        onChange: this.onInputChange,
        onSave: this.onModalSave
      };

    return (
      <>
        {this.isModalVisible.isTrue && this.lien && (
          <ChatModal lien={this.lien} onCancel={this.handleCancel} {...chatModalPassDownProps} />
        )}

        <Tooltip
          show={disabled}
          title='Add contacts to send messages'
        >
          <Dropdown
            disabled={disabled}
            onVisibleChange={this.handleVisibleChange}
            overlay={this.menu}
            placement='bottomLeft'
            trigger={['click']}
            visible={this.isDropdownVisible.isTrue}
          >
            <Badge count={_case.numUnreadNotes}>
              <Button disabled={disabled} loading={loading}>Chat<Icon.Comment /></Button>
            </Badge>
          </Dropdown>
        </Tooltip>
      </>
    );
  }
}

export default ChatButton;
