import React, { Component } from 'react';
import cx from 'classnames';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import autoBindMethods from 'class-autobind-decorator';

import SmartBool from '@mighty-justice/smart-bool';

import { IDetailCase } from '../../../../../models/Case';
import { ICaseThread } from '../../../../../models/CaseThread';
import { IDetailLien } from '../../../../../models/Lien';
import InfoCard from '../../common/InfoCard';

import { Icon, GuardedButton, LinkButton } from '../../../../common';

import { InboxItemProps } from '../interfaces';

import { AUTO_THREAD_RESPONSE_NOTE } from '../constants';

import styles from './InboxActionButtons.module.less';

interface IProps {
  _case: IDetailCase;
  lien?: IDetailLien;
  passThroughProps: InboxItemProps;
  thread: ICaseThread;
}

@autoBindMethods
@observer
class DocumentsHiddenThread extends Component<IProps> {
  @observable private isLoading = new SmartBool();

  private async releaseDocument () {
    const { lien, thread } = this.props;

    if (lien) {
      await this.isLoading.until(lien.updateDocumentSharingStatus({ share_documents: true }));
    }

    await thread.dismiss(AUTO_THREAD_RESPONSE_NOTE.RELEASED_DOCUMENT);
  }

  private async dismissThread () {
    const { thread } = this.props;
    await thread.dismiss(AUTO_THREAD_RESPONSE_NOTE.DISMISSED_THREAD);
  }

  private get actionButtons () {
    return (
      <div className={cx(styles.root, styles.override)}>
        <LinkButton id='dismiss-documents-hidden' className={styles.default} onClick={this.dismissThread}>
          Dismiss <Icon.Ban />
        </LinkButton>
        <GuardedButton
          className={styles.primary}
          confirm
          id='release-document'
          loading={this.isLoading.isTrue}
          onClick={this.releaseDocument}
          type='primary'
        >
          Share <Icon.Check />
        </GuardedButton>
      </div>
    );
  }

  private get description () {
    const { thread } = this.props
      , { new_law_firm } = thread.context
      , initialDescription = `Share documents with ${new_law_firm}?`
      ;

    if (!thread.is_resolved) {
      return initialDescription;
    }

    switch (thread.latest_message?.note) {
    case AUTO_THREAD_RESPONSE_NOTE.DISMISSED_THREAD:
      return `You dismissed: ${initialDescription}`;

    case AUTO_THREAD_RESPONSE_NOTE.RELEASED_DOCUMENT:
      return `You shared documents with ${new_law_firm}`;

    default:
      return 'This thread has been resolved';
    }
  }

  public render () {
    const { thread, passThroughProps } = this.props
      , { new_law_firm } = thread.context
      ;

    return (
      <InfoCard
        bottomRightRender={!thread.is_resolved && this.actionButtons}
        content={
          !thread.is_resolved && (
            `To protect against HIPAA violations, we’ve hidden your documents from ${new_law_firm}`
          )
        }
        subtitle={this.description}
        title='Documents'
        {...passThroughProps}
      />
    );
  }
}

export default DocumentsHiddenThread;
