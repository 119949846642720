import React from 'react';

import SelectOrganizationModal, { onOrganizationClick } from '../common/SelectOrganizationModal';
import { ModalButton } from '../../../common';

interface IProps {
  onCreate: onOrganizationClick;
  onSelect: onOrganizationClick;
}

function AddOrganizationButton (props: IProps) {
  return (
    <ModalButton
      buttonProps={{ type: 'primary' }}
      buttonText='Add an Organization'
      ModalClass={SelectOrganizationModal}
      passThroughProps={{
        onCreate: props.onCreate,
        onSelect: props.onSelect,
      }}
    />
  );
}

export default AddOrganizationButton;
