import React from 'react';

import { IColumnProfile } from '../../../../utils/columnCommon';
import { IDedupeContact } from '../../../../models/DedupeMatch';

import { formatDate, getNameOrDefault, mapBooleanToText } from '@mighty-justice/utils';

const renderContactInformation = (_value: any, record: IDedupeContact) => (
  <div>
    {getNameOrDefault(record)}<br/>
    <b>Email:</b> {record.email}<br/>
  </div>
);

export const contactDedupeMatchColumnsPartial: Array<IColumnProfile<any>> = [
  {
    field: 'Contact Information',
    minWidth: 60,
    render: renderContactInformation,
  },
  {
    align: 'center',
    field: 'Is a User',
    render: (_value: any, record: IDedupeContact) => mapBooleanToText(record.is_user),
    width: 120,
  },
  {
    align: 'center',
    field: 'Is related to compulsion',
    render: (_value: any, record: IDedupeContact) => mapBooleanToText(record.related_to_compulsion_firm),
    width: 120,
  },
  {
    align: 'center',
    field: 'Deactivated',
    render: (_value: any, record: IDedupeContact) => mapBooleanToText(record.is_account_deactivated),
    width: 120,
  },
  {
    align: 'center',
    field: 'Suspended',
    render: (_value: any, record: IDedupeContact) => mapBooleanToText(record.is_account_suspended),
    width: 120,
  },
  {
    align: 'center',
    field: 'Last login',
    render: (_value: any, record: IDedupeContact) => (
      <div>{record.last_login ? formatDate(record.last_login) : 'None'}</div>
    ),
    width: 120,
  },
];
