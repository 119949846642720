import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import autoBindMethods from 'class-autobind-decorator';
import cx from 'classnames';

import { Layout } from 'antd';

import SmartBool from '@mighty-justice/smart-bool';

import StoresClass from '../../stores/StoresClass';
import { PageLoader } from '../common';
import { setIframeWidth } from '../../utils/utils';
import CasePage from '../page-portfolio/portfolio-detail/CasePage';
import navigationWrapper from '../../utils/navigationWrapper';
import { INavigateProps } from '../../utils/navigationUtils';

interface IInjected extends INavigateProps {
  stores: StoresClass;
}

@inject('stores')
@autoBindMethods
@observer
class EmbeddedCasePage extends Component<INavigateProps> {
  private isLoading = new SmartBool(true);

  private get injected () { return this.props as IInjected; }
  private get portfolio () { return this.injected.stores.portfolio; }

  public componentDidMount () {
    setIframeWidth();
    this.hydrate();
  }

  private async hydrate () {
    const { params: { id } } = this.props;

    if (id) {
      await this.isLoading.until(this.portfolio.fetchCase(id));
    }
  }

  private renderCase () {
    const { id } = this.props.params
      , _case = id && this.portfolio.detailCases.get(id)
      ;

    if (this.isLoading.isTrue || !_case) { return <PageLoader />; }

    return (
      <CasePage _case={_case} key={_case.id} />
    );
  }

  public render () {
    const className = cx('page page-facet-table page-floating page-style-card');

    return (
      <Layout className={className}>
        <Layout.Content>
          {this.renderCase()}
        </Layout.Content>
      </Layout>
    );
  }
}

export default navigationWrapper(EmbeddedCasePage);
