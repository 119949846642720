import React from 'react';
import cx from 'classnames';

import styles from './Section.module.less';

interface IProps {
  children?: React.ReactNode;
  className?: string;
}

const Section = (props: IProps) => (
  <div className={cx(styles.root, props.className)}>
    {props.children}
  </div>
);

Section.displayName = 'Section';

export default Section;
