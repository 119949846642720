import React, { Component } from 'react';
import { action } from 'mobx';
import { inject, observer } from 'mobx-react';
import autoBindMethods from 'class-autobind-decorator';

import { FormDrawer } from '@mighty-justice/fields-ant';
import SmartBool from '@mighty-justice/smart-bool';

import Client from '../../../base-modules/client';
import { IDetailLien } from '../../../models/Lien';
import StoresClass from '../../../stores/StoresClass';
import { ANT_HALF_COL_WIDTH, DEFAULT_ROW_PROPS, URLS } from '../../../utils/constants';
import { getUrlForNewPath, INavigateProps } from '../../../utils/navigationUtils';
import navigationWrapper from '../../../utils/navigationWrapper';
import { overrideFieldSets, validateBirthdateOrDOL, validateLawFirmWebsiteOrAddress } from '../../../utils/utils';

import LienholderCaseFormDrawer from './LienholderCaseFormDrawer';
import { registerCaseFieldSets } from './addCaseDrawerConfig';

interface IProps extends INavigateProps {
  isDrawerAddCaseVisible: SmartBool;
}

interface IInjected extends IProps {
  client: Client;
  stores: StoresClass;
}

@inject('stores', 'client')
@autoBindMethods
@observer
class AddCaseFormDrawer extends Component<IProps> {
  private get injected () { return this.props as IInjected; }
  private get isLawFirmUser (): boolean { return this.injected.stores.users.isLawFirmUser; }

  private get externalSystems () {
    return this.injected.stores.externalSystems.externalSystems;
  }

  private get infiniteTableStore () {
    return this.injected.stores.infiniteTableStore;
  }

  private get lawFirmFieldSets () {
    const organizationId = this.injected.stores.users?.account?.registry_legal_organization?.id
      , overrides = { contact: { searchFilters: { legal_organization: organizationId } } }
      , externalSystemFieldSet = this.externalSystems.map((system, index) => ({
        colProps: { span: ANT_HALF_COL_WIDTH },
        field: `external_keys[${index}].externalSystemIdentifier`,
        label: system.external_system.external_system_id,
      }))
      , fieldSets = overrideFieldSets(registerCaseFieldSets, overrides)
      ;

    if (externalSystemFieldSet.length > 0) {
      return [...fieldSets, {
        fields: externalSystemFieldSet,
        legend: 'External System',
        rowProps: DEFAULT_ROW_PROPS,
      }];
    }

    return fieldSets;
  }

  private goToNewCase (caseId: string) {
    const { navigate } = this.props;

    this.infiniteTableStore.setSelectedKeyFromUrlParam(caseId);
    navigate(getUrlForNewPath(`${URLS.PORTFOLIO_PAGE}/${caseId}`));
  }

  @action
  private async onAddCase (caseData: any) {
    const { portfolio } = this.injected.stores;

    validateBirthdateOrDOL(caseData);

    const caseId = await portfolio.createCase(caseData, this.externalSystems);

    this.goToNewCase(caseId);
  }

  private async onAddLien (args: any): Promise<IDetailLien> {
    const { portfolio } = this.injected.stores;

    validateBirthdateOrDOL(args.case);
    validateLawFirmWebsiteOrAddress(args.case);

    const lien = await portfolio.createLien(args);

    this.goToNewCase(lien.case);

    return lien;
  }

  public render () {
    const { isDrawerAddCaseVisible } = this.props;

    if (this.isLawFirmUser) {
      return (
        <FormDrawer
          fieldSets={this.lawFirmFieldSets}
          isVisible={isDrawerAddCaseVisible}
          onSave={this.onAddCase}
          title='Register Case'
        />
      );
    }

    return (
      <LienholderCaseFormDrawer
        isVisible={isDrawerAddCaseVisible}
        onSave={this.onAddLien}
      />
    );
  }
}

export default navigationWrapper(AddCaseFormDrawer);
