import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import autoBindMethods from 'class-autobind-decorator';
import cx from 'classnames';

import { Row, Spin } from 'antd';

import StoresClass from '../../../../../stores/StoresClass';
import { IExternalKey } from '../../../../../models/ExternalKey';
import { IStake } from '../../../../../models/stake';

import AddExternalKeyForm from './AddExternalKeyForm';
import ExternalKey from './ExternalKey';

import styles from '../TagsAndSystemKeys.module.less';

interface IProps {
  stake: IStake;
}

interface IInjected extends IProps {
  stores: StoresClass
}

@inject('stores')
@autoBindMethods
@observer
class ExternalSystemKeys extends Component<IProps> {
  private get injected () { return this.props as IInjected; }

  public async componentDidMount () {
    const { stake } = this.props;

    if (stake && stake.external_keys === null) {
      stake.fetchExternalSystemIds();
    }
  }

  public render () {
    const { stake } = this.props
      , { stores } = this.injected
      , externalKeys: null | IExternalKey[] = stake.external_keys
      , isLoading = externalKeys === null;

    if (!stores.externalSystems.hasSystems) { return null; }

    return (
      <Row>
        <div className={cx(styles.root, styles.override)}>
          <Spin spinning={isLoading}><span className={styles.label}>External Database Identifiers:</span></Spin>

          {externalKeys && externalKeys.map((externalKey: IExternalKey) => (
            <ExternalKey key={externalKey.id} externalKey={externalKey} stake={stake} />
          ))}

          {!isLoading && <AddExternalKeyForm stake={stake} />}
        </div>
      </Row>
    );
  }
}

export default ExternalSystemKeys;
