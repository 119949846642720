import {
  BOOLEAN_STRING,
  OPTION_KEYS,
} from '../../../utils/constants';

import { ICheckboxOption, IFacetSet } from '../interfaces';

export function getCheckboxOption (label: string): ICheckboxOption[] {
  return [{
    label,
    value: BOOLEAN_STRING.TRUE,
  }];
}

const POLICY_LIMIT_FACET_SET: IFacetSet = {
  facets: [
    {
      editComponentProps: {
        options: [
          { label: 'Policy Limit Provided', value: BOOLEAN_STRING.TRUE },
          { label: 'Policy Limit Not Provided', value: BOOLEAN_STRING.FALSE },
        ],
        radio: true,
      },
      field: 'has_policy_limits',
      type: 'optionSelect',
    },
  ],
  label: 'Policy Limits',
}
  , lawFirmDropdownFacetSets: IFacetSet[] = [
    {
      facets: [
        {
          editComponentProps: { options: getCheckboxOption('Needs Response') },
          field: 'law_firm_document_request_needs_response',
          type: 'optionSelect',
        },
        {
          editComponentProps: { options: getCheckboxOption('Pending') },
          field: 'law_firm_document_request_response_pending',
          type: 'optionSelect',
        },
        {
          editComponentProps: { options: getCheckboxOption('Has Unviewed Documents') },
          field: 'law_firm_has_unviewed_documents',
          type: 'optionSelect',
        },
        {
          editComponentProps: { options: getCheckboxOption('Has Viewed Documents') },
          field: 'law_firm_has_viewed_documents',
          type: 'optionSelect',
        },
      ],
      label: 'Lien Documents',
    },
    POLICY_LIMIT_FACET_SET,
    {
      facets: [
        {
          editComponentProps: { optionType: OPTION_KEYS.LIENHOLDER_REQUEST_STATUS },
          field: 'liens__document_requests__status',
          type: 'optionSelect',
        },
      ],
      label: 'Document Requests',
    },
  ]
  , lienholderDropdownFacetSets: IFacetSet[] = [
    {
      facets: [
        {
          editComponentProps: { options: getCheckboxOption('No Case Contact') },
          field: 'has_no_contacts',
          type: 'optionSelect',
        },
        {
          editComponentProps: { options: getCheckboxOption('No Lawfirm') },
          field: 'has_no_law_firm',
          type: 'optionSelect',
        },
        {
          editComponentProps: { options: getCheckboxOption('Lien Rejected by Lawfirm') },
          field: 'lien_is_rejected',
          type: 'optionSelect',
        },
      ],
      label: 'At Risk Cases',
    },
    POLICY_LIMIT_FACET_SET,
  ]
  ;

export const getDropdownFacetSets: (isLawFirmUser: boolean) => IFacetSet[] = (isLawFirmUser: boolean) => (
  isLawFirmUser ? lawFirmDropdownFacetSets : lienholderDropdownFacetSets
);
