import React, { Component } from 'react';

import { Col, Row } from 'antd';

import { ANT_HALF_COL_WIDTH } from '../../utils/constants';

import ApplicationInfo from './ApplicationInfo';

import styles from './LienholderRegistration.module.less';

class AccountDetailsPageExplainer extends Component {
  public render () {
    return (
      <Col span={ANT_HALF_COL_WIDTH}>
        <div className={styles.assets}>
          <Row className={styles.image}>
            <div>
              <img
                alt='Application Sample Screen'
                src={`${process.env.PUBLIC_URL}/assets/images/app-screen.png`}
                width={500}
              />
            </div>
          </Row>
          <Row>
            <ApplicationInfo />
          </Row>
        </div>
      </Col>
    );
  }
}

export default AccountDetailsPageExplainer;
