import { toJS } from 'mobx';

import { varToLabel } from '@mighty-justice/utils';

import { IQuery, IQueryValue, queryValueToString, queryValueToStringArray } from '../../utils/navigationUtils';

import { IFacet, IFacetSet, IToggleButtonProps } from './interfaces';

export function getFacetClassName (facet: IFacet): string {
  return `facet-type-${facet.type}`;
}

function _isToggleFacet (ecp: IFacet['editComponentProps']): ecp is IToggleButtonProps {
  return !!(ecp && 'appliedValue' in ecp);
}

function _getAppliedValue (facet: IFacet): undefined | IQueryValue {
  const editComponentProps = facet.editComponentProps;

  if (_isToggleFacet(editComponentProps)) {
    return editComponentProps.appliedValue;
  }

  return undefined;
}

export function getFacetKey (facet: IFacet): string {
  const appliedValue = queryValueToString(_getAppliedValue(facet))
    , appendAppliedValue = appliedValue ? `-${appliedValue}` : '';

  return `${facet.field}${appendAppliedValue}`;
}

export function isFacetApplied (facet: IFacet, query: IQuery): boolean {
  const { field } = facet
    , appliedValue = _getAppliedValue(facet)
    , queryValue = toJS(query[field]) || query[`${field}_after`] || query[`${field}_before`]
    ;

  if (appliedValue) {
    const appliedValues: string[] = queryValueToStringArray(appliedValue)
      , queryValues: string[] = queryValueToStringArray(queryValue)
      ;

    // If every single filter value is in the existing table values, it's enabled
    return appliedValues.every(value => queryValues.includes(value));
  }

  return !!queryValue;
}

export function numFacetsApplied (facetSets: IFacetSet[], query: IQuery): number {
  const facetCountReducer = (sum: number, facet: IFacet) => (sum + Number(isFacetApplied(facet, query)))
    , countFacetSetApplied = (facetSet: IFacetSet) => facetSet.facets.reduce(facetCountReducer, 0)
    , facetSetCountReducer = (sum: number, facetSet: IFacetSet) => (sum + countFacetSetApplied(facetSet))
    ;

  return facetSets.reduce(facetSetCountReducer, 0);
}

export function facetsToFacetSets (facets: IFacet[]): IFacetSet[] {
  return facets.map(facet => ({
    facets: [facet],
    label: facet.label || varToLabel(facet.field),
  }));
}

export function facetSetsToFacets (facetSets: IFacetSet[]): IFacet[] {
  return facetSets.reduce((result: IFacet[], current) => ([...result, ...current.facets]), []);
}

export function toggleFacet (facet: IFacet, query: IQuery, appliedValueArg: IQueryValue): string[] {
  const { field, editComponentProps } = facet
    , isApplied: boolean = isFacetApplied(facet, query)
    , existingValue: string[] = queryValueToStringArray(query[field])
    , exclusive: boolean = _isToggleFacet(editComponentProps) && Boolean(editComponentProps.exclusive)
    , appliedValue: string[] = queryValueToStringArray(appliedValueArg)
    ;

  // For exclusive filters, we want to always overwrite the existing value
  if (exclusive) {
    return isApplied ? [] : appliedValue;
  }

  // For non-exclusive filters, we want to add or remove the newValues from the ExistingValues
  return isApplied

    // If enabled, remove every newValue from existingValue
    ? existingValue.filter(value => !appliedValue.includes(value))

    // If not enabled, add newValue to existingValue
    : Array.from(new Set(existingValue.concat(appliedValue)))
  ;
}
