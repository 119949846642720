import React, { Component } from 'react';
import { observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import autoBindMethods from 'class-autobind-decorator';
import cx from 'classnames';

import { Row, Popover, Spin } from 'antd';

import { Form, FormManager } from '@mighty-justice/fields-ant';
import SmartBool from '@mighty-justice/smart-bool';

import StoresClass from '../../../../stores/StoresClass';
import { FEATURES } from '../../../../utils/constants';
import { ICaseTag, ILienTag, ITag } from '../../../../models/Tag';
import { Icon, Tag, Tooltip } from '../../../common';
import { objectSearchCreateConfig } from '../../../../utils/configCommon';
import { IStake } from '../../../../models/stake';

import styles from './TagsAndSystemKeys.module.less';

interface IProps {
  stake: IStake;
}

interface IInjected extends IProps {
  stores: StoresClass;
}

const MAX_LENGTH = 20;

@inject('stores')
@autoBindMethods
@observer
class Tags extends Component<IProps> {
  private formManager?: FormManager;
  @observable private isLoading = new SmartBool();

  private get injected () {
    return this.props as IInjected;
  }

  public async componentDidMount () {
    const { stake } = this.props;
    if (stake.tags === null) { stake.fetchTags(); }
  }

  private setRefFormManager (formManager: FormManager) {
    this.formManager = formManager;
  }

  private async remove (tag: ICaseTag | ILienTag) {
    const { stake } = this.props
      , { stores: { infiniteTableStore } } = this.injected
      ;

    await stake.deleteTag(tag);
    infiniteTableStore.fetchFacetCounts();
  }

  private async onFinish () {
    const { stores: { infiniteTableStore } } = this.injected;

    if (this.formManager) { this.formManager.onFinish(); }
    infiniteTableStore.fetchFacetCounts();
  }

  private async addNewTagToCase (tagName: string) {
    const { stake } = this.injected;

    this.onFinish();
    await this.isLoading.until(stake.addNewTag(tagName));
  }

  private async addExistingTagToCase (tag: ITag) {
    const { stake } = this.props;

    this.onFinish();
    await this.isLoading.until(stake.addExistingTag(tag));
  }

  private get fieldSets () {
    return [[{
      editProps: {
        ...objectSearchCreateConfig.editProps,
        onAddNew: this.addNewTagToCase,
        onChange: this.addExistingTagToCase,
        selectProps: {
          ...objectSearchCreateConfig.editProps.selectProps,
          className: styles['tag-search-dropdown'],
        },
      },
      endpoint: '/tags/',
      field: 'tags',
      showLabel: false,
      type: 'objectSearch',
    }]];
  }

  private renderForm () {
    return (
      <div className={styles.form}>
        <Form
          blockSubmit
          fieldSets={this.fieldSets}
          saveText='Add'
          setRefFormManager={this.setRefFormManager}
          successText={null}
        />
      </div>
    );
  }

  private renderTag (tag: ICaseTag | ILienTag) {
    const { id, tag: { name } } = tag
      , showTooltip = name.length > MAX_LENGTH;

    return (
      <Tooltip show={showTooltip} title={name} key={id}>
        <Tag
          closable
          key={id}
          onClose={this.remove}
          onCloseKey={tag}
        >
          <span className={styles.text}>
            <span className={styles['text-inner']}>
              {name}
            </span>
          </span>
        </Tag>
      </Tooltip>
    );
  }

  public render () {
    const { stake, stores: { users } } = this.injected
      , hasFeature = users.hasFeature(FEATURES.ADD_TAG)
      , { hasTags } = users
      , isLoading = stake.tags === null
      , tagsArray: Array<ICaseTag | ILienTag> = stake.tags || []
      ;

    if (!hasTags && !hasFeature) {
      return null;
    }

    return (
      <Row>
        <div className={cx(styles.root, styles.override)}>
          <div className={cx(styles.inner)}>
            <Popover
              arrowPointAtCenter
              content={
                'Tags are custom labels you can add to help organize cases. ' +
                'They’re only visible to other users within your company. ' +
                'You can filter on tags under the “Advanced” tab in the ' +
                'filter bar at the top of the screen.'
              }
              overlayStyle={{ width: '495px' }}
              placement='bottomLeft'
              trigger='hover'
            >
              <Icon.QuestionCircle className={styles.icon}/>
            </Popover>
            <Spin spinning={isLoading}><span className={styles.label}>Tags:</span></Spin>

            {tagsArray.map(this.renderTag)}

            {this.isLoading.isTrue && (
              <Tag closable key='loading'>
                <span className={styles.text}>
                  <span className={styles['text-inner']}>
                    Loading...
                  </span>
                </span>
              </Tag>
            )}

            {hasFeature && !isLoading && (
              <Popover
                content={this.renderForm()}
                placement='bottom'
                trigger='click'
              >
                <Tag
                  className='btn-toggle-add'
                  type='success'
                >
                  + Add
                </Tag>
              </Popover>
            )}
          </div>
        </div>
      </Row>
    );
  }
}

export default Tags;
