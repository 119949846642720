import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import autoBindMethods from 'class-autobind-decorator';
import cx from 'classnames';
import { some } from 'lodash';

import { Layout, Row } from 'antd';
import { RowProps } from 'antd/lib/grid';

import AppContent from '../AppContent';
import { REGISTRY_ENVS, URLS } from '../../utils/constants';
import { Environment } from '../../base-modules/environment';
import { getPath } from '../../utils/navigationUtils';
import Auth from '../../base-modules/auth';

import Logo from './Logo';
import Notifications from './notifications/Notifications';
import Rewards from './Rewards';
import Support from './Support';
import UserDropdown from './UserDropdown';

import styles from './AppHeader.module.less';

interface IInjected {
  auth: Auth;
  environment: Environment;
}

const HEADER_PREFIX_CLS = 'app-header'
  , COMPACT_PAGES = [
    URLS.ACCOUNT_PAGE,
    URLS.COMMUNICATION_PREFERENCES_PAGE,
    URLS.DOCUMENT_PAYMENT_PAGE,
    URLS.MANUAL_DEDUPE_PAGE,
    URLS.ORGANIZATION_PAGE,
  ];

@inject('environment', 'auth')
@autoBindMethods
@observer
class AppHeader extends Component {
  private get injected () {
    return this.props as IInjected;
  }

  private get className () {
    const { registryEnv } = this.injected.environment
      , qaEnv = registryEnv.startsWith('qa') && 'qa'
      , envClassname = `${HEADER_PREFIX_CLS}-env-${qaEnv || registryEnv || REGISTRY_ENVS.DEVELOPMENT}`
      , pathName = getPath()
      , isCompact = !!pathName && some(COMPACT_PAGES, (url: string) => pathName.startsWith(url))
      ;

    return cx(
      styles.root,
      styles[envClassname],
      HEADER_PREFIX_CLS,
      `${HEADER_PREFIX_CLS}-env-${registryEnv}`,
      { [`${HEADER_PREFIX_CLS}-compact`]: isCompact },
    );
  }

  public render () {
    const ROW_PROPS: RowProps = { align: 'middle' };

    return (
      <Layout.Header id='app-header' className={this.className}>
        <AppContent>
          <header>
            <Row {...ROW_PROPS}>
              <Logo />
            </Row>
            <Row {...ROW_PROPS}>
              <Notifications />
              <Support />
              <Rewards />
              <UserDropdown />
            </Row>
          </header>
        </AppContent>
      </Layout.Header>
    );
  }
}

export default AppHeader;
