import React, { Component } from 'react';
import autoBindMethods from 'class-autobind-decorator';
import cx, { Argument as IClassName } from 'classnames';

import { Form } from 'antd';

import styles from './ButtonToolbar.module.less';

interface IProps {
  align?: 'right';
  className?: IClassName;
  noSpacing: boolean;
}

@autoBindMethods
class ButtonToolbar extends Component<IProps> {
  public static defaultProps = {
    noSpacing: false,
  };

  public render () {
    const { align, noSpacing } = this.props
      , className = cx(
        styles.root,
        {
          [styles.alignRight]: align === 'right',
          [styles.noSpacing]: noSpacing,
        },
        this.props.className,
      )
      ;

    return <Form.Item className={className}>{this.props.children}</Form.Item>;
  }
}

export default ButtonToolbar;
