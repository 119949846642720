import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import autoBindMethods from 'class-autobind-decorator';

import { Col, Popconfirm, Row } from 'antd';

import StoresClass from '../../../stores/StoresClass';
import { APP_NAME, EXTERNAL_URL_PROPS } from '../../../utils/constants';
import { Button } from '../../common';
import { ISettingTabProps } from '../settingsUtils';

import styles from './StripeConnectButton.module.less';

interface IInjected extends ISettingTabProps {
  stores: StoresClass;
}

@inject('stores')
@autoBindMethods
@observer
class StripeConnectButton extends Component<ISettingTabProps> {
  private get injected () {
    return this.props as IInjected;
  }

  private async connectStripe () {
    const { stores } = this.injected
      , legalOrganization = stores.users.registryLegalOrganization;

    if (legalOrganization) {
      await legalOrganization.connectStripe();
    }
  }

  private async disconnectStripe () {
    const { stores } = this.injected
      , legalOrganization = stores.users.registryLegalOrganization;

    if (legalOrganization) {
      await legalOrganization.disconnectStripe();
    }
  }

  private renderDisconnectButton () {
    return (
      <Popconfirm
        cancelText='No'
        okText='Yes'
        onConfirm={this.disconnectStripe}
        title='Are you sure?'
      >
        <Button id='disconnect-stripe'>Disconnect Stripe</Button>
      </Popconfirm>
    );
  }

  public render () {
    const { stores } = this.injected
      , legalOrganization = stores.users.registryLegalOrganization
      , hasStripeAccount = legalOrganization && !!legalOrganization.stripe_connected_account_id
      ;

    return (
      <Row className={styles.stripeCard}>
        <Col>
          <p>
            To charge for medical records that are transferred in {APP_NAME}, setup a payment profile,&nbsp;
            <a
              href='https://thatsmighty.zendesk.com/hc/en-us/articles/360051482113-Medical-Record-Payment-Processing'
              {...EXTERNAL_URL_PROPS}
            >
              learn more
            </a>
            .
          </p>
          {hasStripeAccount
            ? this.renderDisconnectButton()
            : (
              <a onClick={this.connectStripe} className={styles['stripe-connect']} id='stripe-connect'>
                <span>Connect with Stripe</span>
              </a>
            )
          }
        </Col>
      </Row>
    );
  }
}

export default StripeConnectButton;
