import React, { Component } from 'react';
import { observer } from 'mobx-react';
import autoBindMethods from 'class-autobind-decorator';
import cx from 'classnames';

import { Badge, Menu } from 'antd';
import { MenuInfo } from 'rc-menu/es/interface';

import { IDetailCase } from '../../../models/Case';
import { Section } from '../../common';

import { TABS } from './CaseTabConfig';

import styles from './CaseTabs.module.less';

interface IProps {
  _case: IDetailCase;
  currentTab: string;
  onChangeTab: (newTab: string) => void;
}

@autoBindMethods
@observer
class CaseTabs extends Component<IProps> {
  private handleClick (e: MenuInfo) {
    this.props.onChangeTab(e.key);
  }

  render () {
    const { _case, currentTab } = this.props;

    return (
      <Section className={cx(styles.root, styles.override)}>
        <Menu onClick={this.handleClick} selectedKeys={[currentTab]} mode='horizontal'>
          {TABS.map(tab => (
            <Menu.Item id={`tab_${tab.key}`} key={tab.key} disabled={tab.disabled}>
              {tab.label === 'Inbox' ? (
                <Badge count={_case.newThreads.length}>
                  {tab.label}
                </Badge>
              ) : (
                <> {tab.label} </>
              )}
            </Menu.Item>
          ))}
        </Menu>
      </Section>
    );
  }
}

export default CaseTabs;
