import React from 'react';

import { ITableStoreAPI } from '../../stores/ITableStoreAPI';

import ResetAll from './building-blocks/ResetAll';
import { IFacetSet } from './interfaces';
import { getFacetKey } from './facetUtils';
import FacetInputWrapper from './FacetInputWrapper';

interface IProps {
  collapsible?: boolean;
  facetSets: IFacetSet[];
  tableStore: ITableStoreAPI;
}

export default function FacetSets (props: IProps) {
  const { collapsible, facetSets, tableStore } = props;

  return (
    <div className='facet-list'>
      <ResetAll facetSets={facetSets} tableStore={tableStore} />

      {facetSets.map(facetSet => (
        facetSet.facets.map(facet => (
          <FacetInputWrapper
            collapsible={collapsible}
            facet={facet}
            key={getFacetKey(facet)}
            tableStore={tableStore}
          />
        ))
      ))}
    </div>
  );
}
