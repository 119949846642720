import React, { Component } from 'react';
import autoBindMethods from 'class-autobind-decorator';
import { observer } from 'mobx-react';

import { IAntFormField, IFormFieldProps } from '@mighty-justice/fields-ant';

import { Upload } from 'antd';
import { RcFile } from 'antd/lib/upload';

import { restrictDocumentSize } from '../../utils/utils';
import Button from '../common/Button';
import { Icon } from '../common';

import styles from './FileUpload.module.less';

@autoBindMethods
@observer
class FileUpload extends Component<IAntFormField> {
  private get injected () {
    return this.props as IFormFieldProps & IAntFormField;
  }

  public onChange (_file: RcFile, fileList: RcFile[]) {
    this.injected.onChange(fileList[0]);
  }

  public render () {
    const { id, value, disabled } = this.injected;

    return (
      <div className={styles.root}>
        <Upload
          accept='.xls, .xlsx, .csv, .numbers'
          beforeUpload={restrictDocumentSize(this.onChange)}
          disabled={disabled}
          id={id}
          name={id}
          showUploadList={false}
        >
          <Button disabled={disabled}>
            <div className={styles.buttonText}>
              <Icon.CloudUploadAlt />{!value ? 'Choose a Spreadsheet File' : value.name}
            </div>
          </Button>
        </Upload>
      </div>
    );
  }
}

export default FileUpload;
