import { flow, Instance, types } from 'mobx-state-tree';

import Client from '../base-modules/client';

const NotificationContext = types
  .model({
    case_id: types.maybe(types.string),
    detail_url: types.maybe(types.string),
    law_firm_name: types.maybe(types.string),
    plaintiff_name: types.maybe(types.string),
  });

export const Notification = types
  .model('Notification', {
    body: types.string,
    context: NotificationContext,
    created_at: types.string,
    dismissed_at: types.maybeNull(types.string),
    id: types.identifier,
    read_at: types.maybeNull(types.string),
    subject: types.maybeNull(types.string),
    type: types.string,
    viewed_at: types.maybeNull(types.string),
  })
  .actions(self => {
    const markAsRead = flow(function* (client: Client, notificationIds: any) {
      const response = yield client.update('/escrow-agent/in-app-notifications/mark-as-read/', notificationIds);
      if (response && response.data) { self.read_at = response.data; }
    });

    const markAsDismissed = flow(function* (client: Client) {
      const response = yield client.update(
        `/escrow-agent/in-app-notifications/${self.id}/mark-as-dismissed/`
      );
      if (response && response.data) { self.dismissed_at = response.data; }
    });

    return {
      markAsRead,
      markAsDismissed,
    };
  });

export interface INotification extends Instance<typeof Notification> {}
