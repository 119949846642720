import React from 'react';
import cx from 'classnames';
import { get } from 'lodash';

import styles from './Pill.module.less';

interface IPillProps {
  text: string;
  type: string;
}

const Pill = (props: IPillProps) => (
  <span className={cx(styles.root, get(styles, props.type, null))}>{props.text}</span>
);

Pill.displayName = 'Pill';

export default Pill;
