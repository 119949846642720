import autoBindMethods from 'class-autobind-decorator';
import { action, observable, toJS } from 'mobx';

import {
  debounce,
  DebouncedFunc,
  includes,
  isEmpty,
  omitBy,
  pickBy,
  some,
} from 'lodash';

import { toKey } from '@mighty-justice/utils';

import { AUTH_URL_PARAM_KEYS } from '../base-modules/auth';
import Client, { IListResponse } from '../base-modules/client';
import { getPagesForRange, isUUID, printPerformance } from '../utils/utils';
import { IEndpointCount } from '../components/common-facets/inputs/FacetObjectSearch';
import { IPortfolio } from '../models/Portfolio';
import { getKeyFromPath, getUrlForNewPath, IQuery, IQueryValue } from '../utils/navigationUtils';
import { PAGE_SIZE, LONG_DEBOUNCE_DELAY, URLS } from '../utils/constants';

import { ITableStoreAPI } from './ITableStoreAPI';

interface IConstructor {
  baseQuery: object;
  client: Client;
  ignoredFilters: string[];
  portfolio: IPortfolio;
}

@autoBindMethods
class InfiniteTableStore implements ITableStoreAPI {
  private portfolio: IPortfolio;
  private client: Client;
  private baseQuery: object;
  public ignoredFilters: string[];

  @observable public count = 0;
  @observable public data: Array<string | null> = [];
  @observable private isLoadingQuery = new Map();

  @observable public filters: IQuery = {};

  @observable public facetCounts: {[key: string]: IEndpointCount[]} = {};

  private pageSize: number = PAGE_SIZE;

  private debouncedFetchFacetCounts: DebouncedFunc<() => Promise<void>>;

  public constructor (init: IConstructor) {
    this.baseQuery = init.baseQuery;
    this.client = init.client;
    this.ignoredFilters = [...init.ignoredFilters, 'ordering', 'page', 'page_size', ...AUTH_URL_PARAM_KEYS];
    this.portfolio = init.portfolio;

    this.debouncedFetchFacetCounts = debounce(this.fetchFacetCounts, LONG_DEBOUNCE_DELAY);
  }

  public get endpoint () {
    return this.portfolio.endpoint;
  }

  public get isLoading (): boolean {
    return Boolean(this.isLoadingQuery.size);
  }

  public get firstCase (): string | null {
    return (this.data.length && this.data[0]) || null;
  }

  public get firstCaseUrl (): string {
    return getUrlForNewPath(`${URLS.PORTFOLIO_PAGE}/${this.firstCase}`);
  }

  public async setQueryParams (filters: IQuery, initialFilters: IQuery) {
    if (isEmpty(filters) && isEmpty(initialFilters)) { return; }
    await this.fetchDataWithOverrides(initialFilters, filters);
  }

  private getSelectedKey (): string | null {
    return getKeyFromPath();
  }

  private async hydrate (queryString?: string) {
    return (await this.portfolio.hydrate(this.getSelectedKey, queryString));
  }

  @action
  public async fetchDataWithOverrides (overridingFilters: IQuery, filters: IQuery) {
    // Used to fetch data that might not show in the regular fetch due to page size or other filters
    // but should still be fetched, e.g. a case based on its case id
    if (overridingFilters && some(overridingFilters)) {
      const queryString = toKey(overridingFilters);

      await this.hydrate(queryString);
    }

    await this.setFilters(filters);
  }

  @action
  public async resetAll () {
    await this.setFilters(this.ignoredFilterQuery);
  }

  @action
  public async onFilterChange (filters: IQuery) {
    await this.setFilters({ ...toJS(this.filters), ...filters });
  }

  @action
  public async refresh () {
    // Prioritize table data over facet counts
    await this.fetchTableData();

    // Do not await facet counts
    this.debouncedFetchFacetCounts();
  }

  @action
  public async onCaseIdChange (caseId: string) {
    if (this.getSelectedKey() === caseId) { return; }
    await this.refresh();
  }

  @action
  public async setSelectedKeyFromUrlParam (caseId?: string | null) {
    if (isUUID(caseId)) {
      await this.fetchTableData();
      await this.portfolio.fetchCase(caseId);
    }
    else {
      await this.fetchTableData();
    }
  }

  public setInitialFilters (filters: IQuery) {
    this.filters = omitBy(filters, isEmpty);
  }

  @action
  public async setFilters (filters: IQuery) {
    this.filters = omitBy(filters, isEmpty);
    await this.refresh();
  }

  private isIgnoredFilter (_value: IQueryValue, key: string) {
    return key.startsWith('utm') || includes(this.ignoredFilters, key);
  }

  public get filterQuery (): IQuery {
    return {
      ...this.baseQuery,
      page_size: this.pageSize.toString(),
      ...toJS(this.filters),
    };
  }

  public get unignoredFilterQuery (): IQuery {
    return omitBy(toJS(this.filters), this.isIgnoredFilter);
  }

  public get ignoredFilterQuery (): IQuery {
    return pickBy(this.filterQuery, this.isIgnoredFilter);
  }

  private getQueryString (additionalParams: IQuery = {}): string {
    return toKey({ ...this.filterQuery, ...additionalParams });
  }

  @action
  public async fetchTableData () {
    // Need to reset data so that InfiniteLoader properly loads more items
    this.data = [];
    await this.fetchPage(1);
  }

  @action
  private loadPageResults (page: number, response: IListResponse) {
    const { count, results } = response;

    if (this.count !== count) {
      this.count = count;
    }

    // The user could be looking at any range in the results and they won't always be loaded
    // in order, so when the data array differs from the results length, we resize it.
    if (this.data.length !== count) {
      this.data = new Array(count).fill(null);
    }

    // Here we calculate where in the array we should insert these results
    const pageOffset = (page - 1) * this.pageSize;
    results.forEach((item: { id: string }, idx: number) => {
      // The full result was stored in hydrate, so we only store the ID here
      this.data[pageOffset + idx] = item.id;
    });
  }

  private async fetchPage (pageNumber: number) {
    printPerformance(`Table Store Fetch Page ${pageNumber}`);
    // Get query string with filters and the page we want
    const page = pageNumber.toString()
      , queryString = this.getQueryString({ page })
      ;

    // This function will be called a lot as the user scrolls.
    // We don't want to load an endpoint we're already loading.
    // We store all in-progress query strings in isLoadingQuery,
    // and skip if one is already loading.
    if (this.isLoadingQuery.has(queryString)) { return; }

    try {
      this.isLoadingQuery.set(queryString, true);

      const response = await this.hydrate(queryString);

      // if the filters have not changed since the fetch
      if (queryString === this.getQueryString({ page })) {
        this.loadPageResults(pageNumber, response);
      }
    }
    finally {
      // We can now clear isLoadingQuery so this endpoint can be refreshed in the future
      this.isLoadingQuery.delete(queryString);
    }
  }

  public async fetchTableDataRange (startIndex: number, stopIndex: number) {
    // startIndex and stopIndex may span multiple pages
    const pages: number[] = getPagesForRange(this.pageSize, startIndex, stopIndex);
    await Promise.all(pages.map(this.fetchPage));
  }

  @action
  public async fetchFacetCounts () {
    this.facetCounts = await this.client.get(`${this.portfolio.endpoint}counts/${this.getQueryString()}`);
  }
}

export default InfiniteTableStore;
