import React, { Component } from 'react';
import autoBindMethods from 'class-autobind-decorator';
import { observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import { merge } from 'lodash';

import { FormDrawer } from '@mighty-justice/fields-ant';
import SmartBool from '@mighty-justice/smart-bool';

import DocumentUploaderTable from '../../common/DocumentUploaderTable';
import StoresClass from '../../../stores/StoresClass';
import { ANT_HALF_COL_WIDTH, DEFAULT_ROW_PROPS } from '../../../utils/constants';
import { Spacer } from '../../common';
import { fixDoubleModalScrollBug } from '../../../utils/utils';
import { IOnSave } from '../../common/ConfirmationOnErrorModal';
import { IDetailLien } from '../../../models/Lien';
import DocumentDropzone from '../../common/DocumentDropzone';
import { IBetaDocumentSubmitData } from '../portfolio-detail/tab-documents/interfaces';

import ConfirmationOnContactErrorModal from './ConfirmationOnContactErrorModal';
import { ExternalSystemSubmitData } from './interfaces';
import { registerLienFieldSet } from './addCaseDrawerConfig';

interface IProps {
  isVisible: SmartBool;
  onSave: (args: any) => Promise<IDetailLien>;
}

interface IInjected extends IProps {
  stores: StoresClass;
}

@inject('stores')
@autoBindMethods
@observer
class LienholderCaseFormDrawer extends Component<IProps> {
  @observable private documentSubmitData: IBetaDocumentSubmitData[] = [];

  private get injected () {
    return this.props as IInjected;
  }

  private async setDocumentSubmitData (documentSubmitData: IBetaDocumentSubmitData[]) {
    this.documentSubmitData = this.documentSubmitData.concat(documentSubmitData);
  }

  private renderDocumentUpload () {
    return (
      <div>
        <h3>Documents</h3>
        <p>All documents are shared with the law firm on file.</p>
        <DocumentUploaderTable documents={this.documentSubmitData} />
        <DocumentDropzone uploadDocuments={this.setDocumentSubmitData} />
        <Spacer />
      </div>
    );
  }

  public async onSave (args: any) {
    const { stores: { documentsStore, externalSystems } } = this.injected
      , lien = await this.props.onSave(args);

    if (!!this.documentSubmitData.length) {
      await lien.bulkUploadDocuments(this.documentSubmitData);
    }

    this.documentSubmitData = [];
    documentsStore.tableDataSource = lien.lienholderDocuments;

    externalSystems.externalSystems.forEach((system, index) => {
      args.external_keys[index].externalSystem = system;
    });

    if (args.external_keys) {
      args.external_keys.forEach((external_key: ExternalSystemSubmitData) => {
        if (external_key.externalSystemIdentifier) {
          lien.addNewExternalSystem(external_key.externalSystemIdentifier, external_key.externalSystem);
        }
      });
    }
  }

  public onCancel () {
    const { isVisible } = this.props;

    isVisible.setFalse();
    this.documentSubmitData = [];
  }

  public renderFieldSets () {
    const { stores: { externalSystems } } = this.injected
      , externalSystemFieldSet = externalSystems.externalSystems.map((system, index) => ({
        colProps: { span: ANT_HALF_COL_WIDTH },
        field: `external_keys[${index}].externalSystemIdentifier`,
        label: system.external_system.external_system_id,
      }))
      ;

    if (externalSystemFieldSet.length > 0) {
      return [...registerLienFieldSet, {
        fields: externalSystemFieldSet,
        legend: 'External System',
        rowProps: DEFAULT_ROW_PROPS,
      }];
    }

    return registerLienFieldSet;
  }

  private async onErrorConfirm (onSave: IOnSave, model: any) {
    const confirmedModel = merge(model, { case: { contacts: [{ is_confirmed: true }] } });
    await onSave(confirmedModel);
    fixDoubleModalScrollBug();
  }

  public render () {
    const { isVisible } = this.props;

    return (
      <ConfirmationOnContactErrorModal
        FormComponent={FormDrawer}
        errorModalProps={{
          saveText: 'Add',
          title: 'Confirm Contact',
        }}
        dataPath='case'
        onConfirm={this.onErrorConfirm}
        onSave={this.onSave}
        passThroughProps={{
          children: this.renderDocumentUpload(),
          className: 'drawer-register-case drawer-register-case-lienholder',
          fieldSets: this.renderFieldSets(),
          isVisible,
          onCancel: this.onCancel,
          onSuccess: isVisible.setFalse,
          title: 'Register Case',
        }}
      />
    );
  }
}

export default LienholderCaseFormDrawer;
