import { Instance, types } from 'mobx-state-tree';

import { LegalOrganization } from './Party';

const Response = types
  .model('Response', {
    created_at: types.maybeNull(types.string),
    created_by_name: types.string,
    id: types.identifier,
    new_status: types.maybeNull(types.string),
    note: types.maybeNull(types.string),
  });

const Request = types
  .model('Request', {
    created_at: types.maybeNull(types.string),
    created_by_organization_name: types.string,
    id: types.identifier,
    is_fulfilled: types.boolean,
    note: types.maybeNull(types.string),
    responses: types.optional(types.array(Response), []),
    status: types.maybeNull(types.string),
  })
;

export const LienDocumentRequest = types.compose(
  Request,
  types.model('LienDocumentRequest', {
    lien: types.model({
      case: types.string,
      id: types.string,
      lienholder: LegalOrganization,
    }),
    lienholder: types.string,
  }),
);

export interface ILienDocumentRequest extends Instance<typeof LienDocumentRequest> {}
export interface IResponse extends Instance<typeof Response> {}
