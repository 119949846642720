import React, { Component } from 'react';
import { observer } from 'mobx-react';
import autoBindMethods from 'class-autobind-decorator';

import SmartBool from '@mighty-justice/smart-bool';
import { FormModal } from '@mighty-justice/fields-ant';

import styles from './Modal.module.less';

import { APP_NAME } from '../../../../utils/constants';

interface IProps {
  isVisible: SmartBool;
  onSave: (model: any) => Promise<void>;
  plaintiffName: string;
}

// Copied from legacy's RejectLienVerificationModal
@autoBindMethods
@observer
class RemoveLienModal extends Component <IProps> {
  private get fieldSets () {
    return [[
      {
        field: 'note',
        label: 'This lien is rejected for the following reasons',
        required: true,
        type: 'text',
      },
    ]];
  }

  private get children () {
    return (
      <p><i>
        The lienholder will be removed from your {APP_NAME} Case for
        <span className='dd-privacy-mask'> {this.props.plaintiffName}</span>
      </i></p>
    );
  }

  private get childrenBefore () {
    return <p><b>For plaintiff:</b> <span className='dd-privacy-mask'>{this.props.plaintiffName}</span></p>;
  }

  public render () {
    const { isVisible, onSave } = this.props;

    return (
      <FormModal
        className={styles.modal}
        children={this.children}
        childrenBefore={this.childrenBefore}
        fieldSets={this.fieldSets}
        isVisible={isVisible}
        onSave={onSave}
        saveText='Reject'
        title='Reject Lien Verification'
      />
    );
  }
}

export default RemoveLienModal;
