import React from 'react';
import { inject } from 'mobx-react';

import { Row } from 'antd';

import StoresClass from '../../../../stores/StoresClass';
import { IDetailCase } from '../../../../models/Case';

import SectionTitle from './SectionTitle';
import LawFirmStatusCard from './LawFirmStatusCard';
import LienholderStatusCard from './LienholderStatusCard';

interface IProps {
  _case: IDetailCase;
  stores?: StoresClass;
}

function YourUpdateSection (props: IProps) {
  const { _case } = props
    , isLawFirmUser = props.stores?.users.isLawFirmUser
    ;

  return (
    <Row>
      <SectionTitle title='Your Latest Update' />
      {isLawFirmUser
        ? <LawFirmStatusCard _case={_case} canUpdate />
        : <LienholderStatusCard canUpdate lien={_case.userLien} />
      }
    </Row>
  );
}

const InjectedSection = inject('stores')(YourUpdateSection);

export default InjectedSection;
