import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import autoBindMethods from 'class-autobind-decorator';

import { Col, Row } from 'antd';

import { EMPTY_FIELD, formatDate } from '@mighty-justice/utils';

import StoresClass from '../../../../stores/StoresClass';
import UpdateCaseButton from '../../common/UpdateCaseButton';
import { IDetailCase, IListCase } from '../../../../models/Case';
import { OPTION_KEYS } from '../../../../utils/constants';

import styles from './CaseHeader.module.less';

interface IProps {
  _case: IDetailCase | IListCase;
}

interface IInjected extends IProps {
  stores: StoresClass;
}

function isDetailCase (_case: IDetailCase | IListCase): _case is IDetailCase {
  return 'created_at' in _case;
}

@inject('stores')
@autoBindMethods
@observer
class CaseStatusInfo extends Component<IProps> {
  private get injected () { return this.props as IInjected; }

  public render () {
    const { _case, stores } = this.injected
      , { getOptionName } = stores.options
      , { isLawFirmUser } = stores.users;

    return (
      <Row className={styles['case-status']}>
        <Row gutter={16} className={styles['status-details']}>
          <Col>
            <p>Law Firm Status: {_case.status ? getOptionName(OPTION_KEYS.CASE_STATUS, _case.status) : EMPTY_FIELD}</p>
          </Col>
          <Col>
            {(isLawFirmUser && isDetailCase(_case)) && <UpdateCaseButton _case={_case} />}
          </Col>
        </Row>
        {_case.last_updated_at &&
          <p className={styles['last-update']}>
            Last Updated by {_case.law_firm?.name} on {formatDate(_case.last_updated_at)}
          </p>
        }
      </Row>
    );
  }
}

export default CaseStatusInfo;
