import React, { Component } from 'react';
import cx from 'classnames';
import { isEmpty } from 'lodash';
import { observer } from 'mobx-react';
import autoBindMethods from 'class-autobind-decorator';

import { EditableCard, IFieldSetPartial } from '@mighty-justice/fields-ant';

import { optionSelectConfig } from '../../../../../utils/configCommon';
import { OPTION_KEYS } from '../../../../../utils/constants';
import { IDetailCase } from '../../../../../models/Case';

import RelatedLienholdersLinkList from './RelatedLienholdersLinkList';

import styles from './CaseDetail.module.less';

interface IProps {
  _case: IDetailCase;
  isGuarded?: boolean;
  onSave: (model: any) => Promise<void>;
  title?: React.ReactNode;
}

@autoBindMethods
@observer
class CaseDetail extends Component<IProps> {
  private get fieldSets (): IFieldSetPartial[] {
    const { _case } = this.props
      , relatedLienholders = _case.userLien?.related_lienholders
      , hasRelatedLienholders = !!relatedLienholders && !isEmpty(relatedLienholders)
      ;

    return [[
      {
        className: 'dd-privacy-mask-user-input',
        field: 'plaintiff.first_name',
        required: true,
        editProps: { autoComplete: 'off' },
      },
      {
        className: 'dd-privacy-mask-user-input',
        field: 'plaintiff.last_name',
        required: true,
        editProps: { autoComplete: 'off' },
      },
      {
        className: 'dd-privacy-mask-user-input',
        field: 'plaintiff.birthdate',
        label: 'Date of Birth',
        type: 'date',
      },
      { field: 'date_of_loss' },
      {
        ...optionSelectConfig,
        field: 'type',
        label: 'Incident Type',
        optionType: OPTION_KEYS.CASE_TYPE,
        sorted: true,
        type: 'optionSelect',
      },
      {
        field: 'state_of_incident',
        optionType: OPTION_KEYS.US_STATES,
        showSearch: true,
        sorted: true,
        type: 'optionSelect',
      },
      { field: 'plaintiff.id', type: 'hidden', writeOnly: true },
      {
        editProps: { autoComplete: 'off' },
        field: 'related_lienholders',
        insertIf: () => hasRelatedLienholders,
        label: 'Also Treating At',
        render: () => <RelatedLienholdersLinkList _case={_case} />,
      },
    ]];
  }

  public render () {
    const { _case } = this.props;

    return (
      <EditableCard
        className={cx(styles['case-detail-card'], styles.override)}
        classNameSuffix='edit'
        colon
        fieldSets={this.fieldSets}
        layout='horizontal'
        model={_case}
        title={'Case Details'}
        {...this.props}
      />
    );
  }
}

export default CaseDetail;
