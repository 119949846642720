import React, { Component } from 'react';
import { isString } from 'lodash';

import { Col, Row } from 'antd';

import { parseAndPreserveNewlines } from '@mighty-justice/utils';

import { ANT_FULL_COL_WIDTH } from '../../../utils/constants';
import { INotification } from '../../../models/Notification';
import { Icon } from '../../common';

import styles from './NotificationContent.module.less';

interface IProps {
  content: string | React.ReactNode;
  notification: INotification;
}

const ICON_SPAN = 2
  , BODY_SPAN = ANT_FULL_COL_WIDTH - ICON_SPAN;

class NotificationContent extends Component<IProps> {
  public render () {
    const { content, notification } = this.props
      , richContent = isString(content) ? parseAndPreserveNewlines(content) : content;

    return (
      <Row className={styles.root}>
        <Col span={BODY_SPAN}>
          <span className={styles.content}>{richContent}</span>
        </Col>
        {!notification.read_at && (
          <Col span={ICON_SPAN} className={styles['read-at']}>
            <Icon.CircleSolid className={styles.icon} />
          </Col>
        )}
      </Row>
    );
  }
}

export default NotificationContent;
