import React, { Component } from 'react';
import { observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import autoBindMethods from 'class-autobind-decorator';
import { noop } from 'lodash';

import { Popover } from 'antd';

import { Form } from '@mighty-justice/fields-ant';
import SmartBool from '@mighty-justice/smart-bool';

import { GuardedButton } from '../../../common';

import Client from '../../../../base-modules/client';

interface IProps {
  match: any;
}

interface IInjected extends IProps {
  client: Client;
}

@inject('client')
@autoBindMethods
@observer
class CouldNotVerifyButton extends Component<IProps> {
  @observable private isVisible = new SmartBool();

  private get injected () {
    return this.props as IInjected;
  }

  private get fieldSets (): any[] {
    return [[
      { field: 'could_not_manually_verify_note', label: 'Note' },
    ]];
  }

  private async couldNotVerify (model: object) {
    const { match } = this.props
      , { client } = this.injected;

    await match.couldNotManuallyVerify(client, model);
  }

  public render () {
    return (
      <Popover
        arrowPointAtCenter
        content={
          <Form
            fieldSets={this.fieldSets}
            onSave={this.couldNotVerify}
            resetOnSuccess
            onCancel={this.isVisible.setFalse}
            onSuccess={this.isVisible.setFalse}
          />
        }
        onVisibleChange={this.isVisible.toggle}
        trigger='click'
        visible={this.isVisible.isTrue}
      >
        <GuardedButton className='btn-could-not-verify' danger onClick={noop}>
          Can't verify
        </GuardedButton>
      </Popover>
    );
  }
}

export default CouldNotVerifyButton;
