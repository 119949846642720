import React, { Component } from 'react';
import { observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import autoBindMethods from 'class-autobind-decorator';

import { Popover } from 'antd';

import SmartBool from '@mighty-justice/smart-bool';

import AppHeaderItem from '../AppHeaderItem';
import StoresClass from '../../../stores/StoresClass';
import { Icon } from '../../common';

import NotificationsHeader from './NotificationsHeader';
import NotificationsMenu from './NotificationsMenu';

import styles from './Notifications.module.less';

interface IInjected {
  stores: StoresClass;
}

@inject('stores')
@autoBindMethods
@observer
class Notifications extends Component {
  @observable private isVisible = new SmartBool();

  private get injected () { return this.props as IInjected; }

  public render () {
    const { notifications } = this.injected.stores;

    if (notifications.isLoading.isTrue) {
      return (
        <AppHeaderItem
          className='btn-notifications'
          icon={Icon.BellLight}
          isLoading
        />
      );
    }

    return (
      <Popover
        arrowPointAtCenter
        content={<NotificationsMenu isVisible={this.isVisible} notifications={notifications.notifications} />}
        onVisibleChange={this.isVisible.toggle}
        overlayClassName={styles.popover}
        overlayStyle={{ width: '350px' }}
        placement='bottomRight'
        title={<NotificationsHeader />}
        trigger='click'
        visible={this.isVisible.isTrue}
      >
        <AppHeaderItem
          className='btn-notifications'
          count={notifications.unviewedCount}
          icon={Icon.BellLight}
          onClick={notifications.markAsViewed}
        />
      </Popover>
    );
  }
}

export default Notifications;
