import React, { Component } from 'react';
import { observer } from 'mobx-react';

import { APP_NAME } from '../../../../utils/constants';
import { IActivityDetailComponentProps } from './interfaces';

@observer
class AutomatedCaseUpdateRequestActivity extends Component<IActivityDetailComponentProps> {
  public render () {
    return (
      <h4>Case update request email sent by {APP_NAME}</h4>
    );
  }
}

export default AutomatedCaseUpdateRequestActivity;
