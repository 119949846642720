import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import autoBindMethods from 'class-autobind-decorator';

import { Row } from 'antd';

import { Icon, LinkButton } from '../../../../common';
import { IAccount } from '../../../../../models/User';
import { IDetailCase } from '../../../../../models/Case';
import StoresClass from '../../../../../stores/StoresClass';
import { Controller } from '../../../../../base-modules/controller';
import navigationWrapper from '../../../../../utils/navigationWrapper';
import { INavigateProps } from '../../../../../utils/navigationUtils';
import { URLS } from '../../../../../utils/constants';

interface IHooksProps {
  _case: IDetailCase;
  account: IAccount;
}

interface IProps extends IHooksProps, INavigateProps {}

interface IInjected extends IProps {
  controller: Controller;
  stores: StoresClass;
}

@inject('stores', 'controller')
@autoBindMethods
@observer
class RelatedLienholderLinkItem extends Component<IProps> {
  private get injected () { return this.props as IInjected; }

  private async changeAccount () {
    const { _case, account, navigate } = this.props
      , { controller, stores: { infiniteTableStore } } = this.injected
      ;

    navigate(`${URLS.PORTFOLIO_PAGE}/${_case.id}`);
    await controller.changeAccount(account.id);
    await infiniteTableStore.fetchTableData();

    await _case.fetchLiens();
  }

  public render () {
    const { account } = this.props;

    return (
      <Row>
        <LinkButton onClick={this.changeAccount}>
          {account.registry_legal_organization.name} <Icon.Share/>
        </LinkButton>
      </Row>
    );
  }
}

export default navigationWrapper(RelatedLienholderLinkItem);
