import { flow, getParent, getSnapshot, hasParent, Instance, types } from 'mobx-state-tree';
import { has } from 'lodash';

import Client from '../base-modules/client';
import { MODELS } from '../utils/constants';
import { ACTIONS, getMenuKey } from '../components/page-dedupe/consts';

import { Address, LegalOrganization } from './Party';
import { LegacyBaseCase } from './Legacy';
import { ExternalKey } from './ExternalKey';
import { Contact } from './Contact';
import { CreatedByData } from './Base';

export const CompulsionFlag = types.model({
  related_to_compulsion_firm: types.maybeNull(types.boolean),
});

// Structures for deduped objects
export const VerifiedOrganizationData = types.model({
  address: types.maybeNull(Address),
  case_count: types.maybe(types.number),
  contact_emails: types.array(types.string),
  id: types.identifier,
  lien_count: types.maybe(types.number),
  name: types.string,
  phone_number: '',
  type: types.maybeNull(types.string),
  website: '',
});

export const DedupeCase = types.compose(
  LegacyBaseCase,
  CompulsionFlag,
  types.model({
    download_all_documents_url: types.maybeNull(types.string),
    external_keys: types.optional(types.array(ExternalKey), []),
    lien_documents_count: types.number,
    lienholders: types.array(types.maybeNull(types.string)),
  }),
);

export const DedupeContact = types.compose(
  CompulsionFlag,
  Contact,
  types.model({
    is_account_deactivated: types.boolean,
    is_user: types.boolean,
    last_login: types.maybeNull(types.string),
    legal_organization_name: types.string,
  }),
);

export const DedupeModel = types.compose(
  DedupeCase,
  DedupeContact,
  types.model({
    id: types.identifier,
  })
);

// Structures for match information
const Match = types
  .model({})
  .actions(self => {
    const portfolio = hasParent(self) && (getParent(self, 2) as any);

    if (!portfolio) {
      // istanbul ignore next
      throw new Error('Match may only be used within DedupePortfolio');
    }

    return {
      couldNotManuallyVerify: flow(function* (client: Client, submitData: object) {
        yield portfolio.couldNotManuallyVerifyMatch(self, client, submitData);
      }),

      dismiss: flow(function* (client: Client) {
        yield portfolio.dismissMatch(self, client);
      }),

      dismissNeedsMoreInfo: flow(function* (client: Client) {
        yield portfolio.dismissMatchNeedsMoreInfo(self, client);
      }),

      dismissVerificationNeedsMoreInfo: (client: Client, submitData: object, remove: boolean) => {
        portfolio.verificationNeedsMoreInfo(self, client, submitData, remove);
      },

      merge: flow(function* (client: Client, submitData: object) {
        yield portfolio.mergeMatch(self, client, submitData);
      }),

      remove: () => portfolio.removeMatch(self),
    };
  });

export const VerifyMatch = types.compose(
  CreatedByData,
  Match,
  types.model({
    created_at: types.string,
  }),
);

export const VerifyOrganizationMatch = types.compose(
  CompulsionFlag,
  VerifyMatch,
  VerifiedOrganizationData,
  types.model({
    associated_law_firms: types.array(types.maybeNull(types.string)),
    associated_lienholders: types.array(types.maybeNull(types.string)),
    dismissed_verification_note: types.maybeNull(types.string),
    verified_match: types.maybeNull(VerifiedOrganizationData),
  }),
).actions(self => {
  const setMatch = function (match: IVerifiedOrganizationData) {
    self.verified_match = getSnapshot(match as any);
  };

  return { setMatch };
});

export const VerifyContactMatch = types.compose(
  CompulsionFlag,
  VerifyMatch,
  types.model({
    case: types.maybeNull(LegacyBaseCase),
    dismissed_verification_note: types.maybeNull(types.string),
    email: types.string,
    first_name: types.string,
    id: types.identifier,
    is_account_deactivated: types.boolean,
    is_account_suspended: types.boolean,
    last_name: types.string,
    legal_organization: LegalOrganization,
    legal_organization_name: types.string,
  }),
);

const MultiMatch = types.compose(
  Match,
  types.model({
    id: types.identifier,
    matched_ids: types.array(types.string),
    matched_objects: types.array(DedupeModel),
    model_type: types.string,
  }),
);

export const DedupeCaseMatch = types.compose(
  MultiMatch,
  types.model({
    matched_objects: types.array(DedupeCase),
  }),
);

export const DedupeContactMatch = types.compose(
  MultiMatch,
  types.model({
    matched_objects: types.array(DedupeContact),
  }),
);

const dispatcher = (snapshot: any) => {
  const snapshotMapping = {
      [getMenuKey(ACTIONS.DEDUPE, MODELS.CASE)]: DedupeCaseMatch,
      [getMenuKey(ACTIONS.DEDUPE, MODELS.CONTACT)]: DedupeContactMatch,
      [getMenuKey(ACTIONS.VERIFICATION, MODELS.CONTACT)]: VerifyContactMatch,
      [getMenuKey(ACTIONS.VERIFICATION, MODELS.LEGAL_ORGANIZATION)]: VerifyOrganizationMatch,
    }
    , action = has(snapshot, 'matched_objects') ? ACTIONS.DEDUPE : ACTIONS.VERIFICATION
    , menuKey = getMenuKey(action, snapshot.model_type)
    ;

  if (!Object.keys(snapshotMapping).includes(menuKey)) {
    // istanbul ignore next
    throw new Error(`Action + Model ${menuKey} missing in DeDupeMatch.ts dispatcher.`);
  }

  return snapshotMapping[menuKey];
};

export const DedupeMatch = types.union(
  { dispatcher },
  DedupeCaseMatch,
  DedupeContactMatch,
  VerifyContactMatch,
  VerifyOrganizationMatch,
);

export interface IMultiMatch extends Instance<typeof MultiMatch> {}
export type IDedupeMatch = Instance<typeof DedupeMatch>;

export interface IDedupeCase extends Instance<typeof DedupeCase> {}
export interface IDedupeContact extends Instance<typeof DedupeContact> {}
export interface IDedupeModel extends Instance<typeof DedupeModel> {}
export interface IVerifyOrganizationMatch extends Instance<typeof VerifyOrganizationMatch> {}
export interface IVerifiedOrganizationData extends Instance<typeof VerifiedOrganizationData> {}
export interface IVerifyContactMatch extends Instance<typeof VerifyContactMatch> {}
export interface IVerifyMatch extends Instance<typeof VerifyMatch> {}
