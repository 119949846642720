/* istanbul ignore file */
import WebFont from 'webfontloader';

WebFont.load({
  google: {
    families: [
      'Roboto:300,300i,400,400i,500,500i,700,700i:latin',
    ],
  },
});
