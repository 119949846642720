import { Decimal } from 'decimal.js';

export interface IPriceBucket {
  cases: string;
  cost: string;
  free: string;
  perCase: string;
  total: string;
}

export const PRICE_BUCKETS: IPriceBucket[] = [
  {
    cases: '100',
    cost: '1000',
    free: '10',
    planType: '110 Case Credits',
  },
  {
    cases: '250',
    cost: '2500',
    free: '38',
    planType: '288 Case Credits',
  },
  {
    cases: '500',
    cost: '5000',
    free: '100',
    planType: '600 Case Credits',
  },
].map(bucket => ({
  ...bucket,
  total: new Decimal(bucket.cases).plus(bucket.free).toFixed(0),
})).map(bucket => ({
  ...bucket,
  perCase: new Decimal(bucket.cost).div(bucket.total).toFixed(2),
}));
