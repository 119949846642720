import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import autoBindMethods from 'class-autobind-decorator';

import { Button } from '../../../common';

import Client from '../../../../base-modules/client';

interface IProps {
  match: any;
}

interface IInjected extends IProps {
  client: Client;
}

@inject('client')
@autoBindMethods
@observer
class AcceptMatchButton extends Component<IProps> {
  private get injected () {
    return this.props as IInjected;
  }

  private merge () {
    const { match } = this.props
      , { client } = this.injected
      , submitData = {
        canonical_id: match.verified_match && match.verified_match.id,
        merge_ids: [match.id],
      };

    match.merge(client, submitData);
  }

  public render () {
    const { match: { verified_match } } = this.props;

    if (!verified_match) {
      return null;
    }

    return (
      <Button className='accept-match' onClick={this.merge}>
        Accept match
      </Button>
    );
  }
}

export default AcceptMatchButton;
