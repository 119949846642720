import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import autoBindMethods from 'class-autobind-decorator';

import { Card } from '@mighty-justice/fields-ant';
import { IModel } from '@mighty-justice/fields-ant/dist/props';
import SmartBool from '@mighty-justice/smart-bool';
import { formatDate } from '@mighty-justice/utils';

import { Modal } from 'antd';

import { IBetaDocument } from '../../../../../models/Document';
import { OPTION_KEYS } from '../../../../../utils/constants';
import StoresClass from '../../../../../stores/StoresClass';
import { IDetailCase } from '../../../../../models/Case';
import { formatOption } from '../../../../../utils/utils';

import { insertPriceIf } from '../utils';

import DocumentDetailButtons from './DocumentDetailButtons';

import styles from './DocumentDetailModal.module.less';

interface IProps {
  _case: IDetailCase;
  document: IBetaDocument;
  isVisible: SmartBool;
}

interface IInjected extends IProps {
  stores: StoresClass;
}

@inject('stores')
@autoBindMethods
@observer
class DocumentDetailModal extends Component<IProps> {
  private get injected () {
    return this.props as IInjected;
  }

  private get fieldSets () {
    const { _case } = this.props
      , { users, users: { isLawFirmUser } } = this.injected.stores;

    return [{
      fields: [
        {
          field: 'type',
          label: 'Document Type',
          render: (_value: string) => formatOption(_value, OPTION_KEYS.DOCUMENT_TYPES),
        },
        {
          field: 'name',
          label: 'Document Name',
        },
        {
          field: 'lien',
          insertIf: (model: IModel) => isLawFirmUser && model.lien,
          label: 'Shared With',
          render: (_value: string) => _case.findLien(_value)?.lienholder.name,
        },
        {
          field: 'price',
          insertIf: (model: IModel) => insertPriceIf(users, model.type),
          label: 'Access Fee',
          type: 'money',
        },
        {
          field: 'last_opened_at',
          insertIf: (model: IModel) => !isLawFirmUser && model.lien && model.last_opened_at,
          label: 'Last Viewed By Law Firm',
          render: (_value: string) => formatDate(_value),
        }
      ],
    }];
  }

  public render () {
    const { _case, document, isVisible } = this.props;

    return (
      <Modal
        className={styles['document-detail-modal']}
        footer={null}
        onCancel={isVisible.setFalse}
        title='Document Details'
        visible={isVisible.isTrue}
      >
        <Card
          bordered={false}
          className={styles['case-detail-card']}
          fieldSets={this.fieldSets}
          layout='vertical'
          model={document}
        />
        <DocumentDetailButtons _case={_case} document={document} isVisible={isVisible}/>
      </Modal>
    );
  }
}

export default DocumentDetailModal;
