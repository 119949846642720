import React from 'react';

import UploadWrapper from '../../../../common/UploadWrapper';
import { IDetailCase } from '../../../../../models/Case';
import { LinkButton } from '../../../../common';
import Section from '../../../../common/Section';

import styles from './EmptyTableSection.module.less';

interface IProps {
    _case: IDetailCase;
}

function EmptyTableSection (props: IProps) {
  const { _case } = props;

  return (
    <Section className={styles.root}>
      <UploadWrapper
        _case={_case}
        canDrag
        uploadDocuments={_case.bulkUploadDocuments}
      >
        <div>
          Drag and drop documents here
          <br />
          or{' '}
          <LinkButton>
            select files
          </LinkButton>
          {' '}from your computer
        </div>
      </UploadWrapper>
    </Section>
  );
}

export default EmptyTableSection;
