import React, { Component } from 'react';
import { observer } from 'mobx-react';
import autoBindMethods from 'class-autobind-decorator';

import { Row } from 'antd';

import styles from './CompareCardHead.module.less';

@autoBindMethods
@observer
class CompareCardHead extends Component<{}> {
  public render () {
    const { children, ...passThroughProps } = this.props;

    return (
      <Row className={styles.root} {...passThroughProps}>
        {children}
      </Row>
    );
  }
}

export default CompareCardHead;
