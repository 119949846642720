import React from 'react';
import cx from 'classnames';

import { Menu } from 'antd';

import { Section } from '../../common';
import { TABS } from './CaseTabConfig';
import styles from './CaseTabs.module.less';

export function ListCaseTabs () {
  return (
    <Section className={cx(styles.root, styles.override)}>
      <Menu mode='horizontal'>
        {TABS.map(tab => <Menu.Item key={tab.key} disabled>{tab.label}</Menu.Item>)}
      </Menu>
    </Section>
  );
}

export default ListCaseTabs;
