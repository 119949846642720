import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import autoBindMethods from 'class-autobind-decorator';

import { getNameOrDefault } from '@mighty-justice/utils';

import {
  Card,
  IFieldConfigPartial,
} from '@mighty-justice/fields-ant';

import StoresClass from '../../../stores/StoresClass';
import { ISettingTabProps } from '../settingsUtils';
import { OPTION_KEYS } from '../../../utils/constants';
import { withColumns } from '../../../utils/configCommon';

import PaymentInfoCard from './PaymentInfoCard';
import UsersCard from './UsersCard';

interface IInjected extends ISettingTabProps {
  stores: StoresClass;
}

const FIELD_CONFIG_ORG_TYPE: IFieldConfigPartial = {
  field: 'type',
  optionType: OPTION_KEYS.LEGAL_ORGANIZATION_TYPE,
  type: 'optionSelect',
};

@inject('stores')
@autoBindMethods
@observer
class OrganizationTab extends Component<ISettingTabProps> {
  private get injected () {
    return this.props as IInjected;
  }

  public render () {
    const { account } = this.injected.stores.users
      , legalOrganization = account?.registry_legal_organization
      ;

    return (
      <>
        <Card
          title={getNameOrDefault(legalOrganization)}
          fieldSets={[[
            FIELD_CONFIG_ORG_TYPE,
            { field: 'phone_number' },
            { field: 'website', type: 'url', colProps: { span: 12 } },
          ]].map(withColumns(4))}
          model={legalOrganization}
        />

        <PaymentInfoCard {...this.props} />
        <UsersCard {...this.props} />
      </>
    );
  }
}

export default OrganizationTab;
