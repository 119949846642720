import React, { Component } from 'react';
import { computed, toJS } from 'mobx';
import { inject, observer } from 'mobx-react';
import autoBindMethods from 'class-autobind-decorator';
import { head, sortBy } from 'lodash';

import { RadioChangeEvent } from 'antd/lib/radio';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';

import Facet from '../building-blocks/Facet';
import FacetCheckboxList from '../building-blocks/FacetCheckboxList';
import StoresClass from '../../../stores/StoresClass';
import { IFacetProps, IOptionSelectProps } from '../interfaces';
import { IQueryValue, queryValueToStringArray } from '../../../utils/navigationUtils';

interface IProps extends IFacetProps, IOptionSelectProps {}

interface IInjected extends IProps {
  stores: StoresClass;
}

@inject('stores')
@autoBindMethods
@observer
class FacetOptionSelect extends Component<IProps> {
  private get injected () {
    return this.props as IInjected;
  }

  private getOptionCount (field: string, value: string): number {
    const { tableStore } = this.injected
      , options = tableStore.facetCounts[field] || []
      , matchingOption = head(options.filter(option => String(option.id) === String(value)))
      ;

    return matchingOption?.count || 0;
  }

  @computed
  private get options () {
    const { includeNone, options, optionType, stores } = this.injected
      , { button, facet: { field } } = this.props
      , passedOptions = optionType ? stores.options.getFilters(optionType, !!includeNone) : options;

    if (button) {
      const countOptions = passedOptions && passedOptions.map(option => ({
        count: this.getOptionCount(field, option.value),
        label: option.label,
        value: option.value,
      }));
      return countOptions || [];
    }

    return sortBy(passedOptions, 'value');
  }

  private get value (): string[] {
    const { facet: { field }, tableStore } = this.props;

    return queryValueToStringArray(toJS(tableStore.filters[field]));
  }

  private get hasValue () {
    return !!(this.value && this.value.length);
  }

  private handleFilterChange (value: IQueryValue) {
    const { onFacetChange, facet: { field } } = this.props;
    onFacetChange({ [field]: value });
  }

  private handleCheckboxFilterChange (checkboxValues: CheckboxValueType[]) {
    const value = checkboxValues as string[];

    this.handleFilterChange(value);
  }

  private handleRadioFilterChange (event: RadioChangeEvent) {
    this.handleFilterChange(event.target.value);
  }

  public render () {
    const { facet: { field }, radio } = this.props;

    return (
      <Facet hasValue={this.hasValue} {...this.props}>
        <FacetCheckboxList
          field={field}
          onRadioChange={this.handleRadioFilterChange}
          onCheckboxChange={this.handleCheckboxFilterChange}
          options={this.options}
          radio={radio}
          value={this.value}
        />
      </Facet>
    );
  }
}

export default FacetOptionSelect;
