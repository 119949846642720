import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import autoBindMethods from 'class-autobind-decorator';
import { Outlet } from 'react-router-dom';

import { Layout } from 'antd';

import { getNameOrDefault } from '@mighty-justice/utils';

import Auth from '../base-modules/auth';
import StoresClass from '../stores/StoresClass';
import { Environment } from '../base-modules/environment';

import AddLienholderInfoModal from './modal-action-items-list/AddLienholderInfoModal';
import AddNewContactsModal from './modal-action-items-list/AddNewContactsModal';
import AppHeader from './app-header/AppHeader';
import { Button, TermsOfServiceLink } from './common';

interface IProps {
  children?: React.ReactNode;
}

interface IInjected extends IProps {
  auth: Auth;
  environment: Environment;
  stores: StoresClass;
}

@inject('auth', 'stores', 'environment')
@autoBindMethods
@observer
class AppLayout extends Component<IProps> {
  private get injected () {
    return this.props as IInjected;
  }

  private async onImpersonationReleaseClick () {
    const { auth, environment } = this.injected;

    auth.clearUser();
    window.location.href = `https://${environment.apiHost}/backend/command/registry/registryaccount/`;
  }

  public renderImpersonationNotification () {
    const { auth, stores } = this.injected
      , { isImpersonating } = auth
      , { user } = stores.users
      ;

    if (!isImpersonating) { return null; }

    return (
      <div className='notification notification-warning'>
        <div className='notification-info'>
          You are currently working on behalf of <strong>{getNameOrDefault(user)}</strong>.
        </div>
        <div className='notification-actions'>
          <Button onClick={this.onImpersonationReleaseClick}>
            Release&nbsp;{getNameOrDefault(user)}
          </Button>
        </div>
      </div>
    );
  }

  public render () {
    const { children, stores } = this.injected;

    return (
      <Layout id='app-layout'>
        {this.renderImpersonationNotification()}
        {stores.uiStore.addNewContactsModalVisible.isTrue && <AddNewContactsModal />}
        {stores.uiStore.addLienholderInfoModalVisible.isTrue && <AddLienholderInfoModal />}
        <AppHeader />
        <Layout.Content>
          {children}
          <Outlet />
        </Layout.Content>
        <Layout.Footer>
          <TermsOfServiceLink />
        </Layout.Footer>
      </Layout>
    );
  }
}

export default AppLayout;
