import React, { Component } from 'react';
import { computed, isArrayLike, observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import URI from 'urijs';
import autoBindMethods from 'class-autobind-decorator';
import { debounce, get, uniq } from 'lodash';

import { Select } from 'antd';
import { LabeledValue } from 'antd/es/select';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';

import Client from '../../../base-modules/client';
import Facet from '../building-blocks/Facet';
import FacetCheckboxList from '../building-blocks/FacetCheckboxList';
import { DEBOUNCE_DELAY } from '../../../utils/constants';
import { ICheckboxOption, IFacetProps, IObjectSearchProps } from '../interfaces';
import { IQueryValue, unknownToQueryValue } from '../../../utils/navigationUtils';

interface IProps extends IFacetProps, IObjectSearchProps {}

interface IInjected extends IProps {
  client: Client;
}

interface ISearchResult {
  id: string;
  name: string;
}

export interface IEndpointCount {
  [key: string]: unknown;
  count: number;
  id: string;
}

@inject('client')
@autoBindMethods
@observer
class FacetObjectSearch extends Component<IProps> {
  private readonly debouncedOnSearch: (search: string) => void;
  @observable private searchResults: ISearchResult[] = [];

  public constructor (props: IProps) {
    super(props);
    this.debouncedOnSearch = debounce(this.onSearch, DEBOUNCE_DELAY);
  }

  private get injected () {
    return this.props as IInjected;
  }

  @computed
  private get options (): ICheckboxOption[] {
    const { facet: { field }, renderOption, tableStore } = this.injected
      , options = tableStore.facetCounts[field] || [];

    return options.map((option: IEndpointCount) => ({
      count: option.count,
      label: renderOption(option),
      value: option.id,
    }));
  }

  private get value (): string[] {
    const { facet: { field }, tableStore } = this.props
      , filterValue = get(tableStore.filters, field, [])
      ;

    return isArrayLike(filterValue) ? filterValue.slice() : filterValue.split(',');
  }

  private get hasValue () {
    return !!(this.value && this.value.length);
  }

  private handleFilterChange (checkboxValues: CheckboxValueType[]) {
    const { onFacetChange, facet: { field } } = this.props
      , value: IQueryValue = unknownToQueryValue(checkboxValues);

    onFacetChange({ [field]: value });
  }

  private async onSearchChange (value?: LabeledValue) {
    if (!value?.key) { return; }

    const newValue = uniq([...this.value, value.key]);
    this.handleFilterChange(newValue);
  }

  private async onSearch (search: string) {
    const { client, facet: { field }, tableStore } = this.injected
      , params = { facet: field, facet_search: search, ...tableStore.ignoredFilterQuery }
      , url = (new URI(`${tableStore.endpoint}search/`)).addSearch(params).toString()
      ;

    this.searchResults = await client.get(url);
  }

  private renderSearch () {
    const { facet: { label } } = this.props;

    return (
      <Select
        allowClear
        defaultActiveFirstOption={false}
        filterOption={false}
        labelInValue
        onChange={this.onSearchChange}
        onSearch={this.debouncedOnSearch}
        placeholder={label}
        showArrow={false}
        showSearch
        style={{ width: '100%' }}
      >
        {this.searchResults.map(option => (
          <Select.Option
            key={option.id}
            value={option.id}
          >
            {option.name}
          </Select.Option>
        ))}
      </Select>
    );
  }

  public render () {
    const { facet: { field } } = this.props;

    return (
      <Facet hasValue={this.hasValue} {...this.props}>
        {this.renderSearch()}
        <FacetCheckboxList
          field={field}
          onCheckboxChange={this.handleFilterChange}
          options={this.options}
          value={this.value}
        />
      </Facet>
    );
  }
}

export default FacetObjectSearch;
