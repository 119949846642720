import React from 'react';
import cx from 'classnames';
import { get, uniqBy } from 'lodash';

import { formatDate, formatWebsite } from '@mighty-justice/utils';
import { IEndpointOption } from '@mighty-justice/fields-ant/dist/interfaces';
import { IModel, IValue } from '@mighty-justice/fields-ant/dist/props';

import {
  IFieldConfigPartial,
  IFieldSetPartial,
  ObjectSearchCreate,
} from '@mighty-justice/fields-ant';

import { IDetailCase } from '../../../models/Case';
import { doesContactNeedUpdate, IContact } from '../../../models/Contact';
import { Icon } from '../../common';
import { optionSelectConfig, objectSearchCreateConfig } from '../../../utils/configCommon';
import { formatOption, renderContactOption, renderOrganizationWithWebsite } from '../../../utils/utils';
import {
  CASE_STATUS_WITH_DATE,
  DEFAULT_ROW_PROPS,
  OPTION_KEYS,
  ORGANIZATION_TYPES,
  URLS,
} from '../../../utils/constants';
import { treatmentStatusFields } from '../common/fieldSets';

export function renderLawFirmWebsite (value: IValue) {
  if (value && value.website) {
    return <span>Website: {formatWebsite(value.website)}</span>;
  }
  return null;
}

export const addLawFirmField = {
  className: cx('form-item-law-firm'),
  colProps: { span: 24 },
  createFields: [
    { field: 'name', populateFromSearch: true, required: true },
    { field: 'website', type: 'url' },
    { field: 'address', type: 'address' },
  ],
  editProps: {
    ...objectSearchCreateConfig.editProps,
    noSearchContent: `Type in law firm's name`,
    selectProps: {
      ...objectSearchCreateConfig.editProps.selectProps,
      placeholder: 'Search law firm...',
    },
  },
  endpoint: URLS.LEGAL_ORGANIZATIONS_LIGHT,
  field: 'case.law_firm',
  formItemRenderExtra: renderLawFirmWebsite,
  renderOption: renderOrganizationWithWebsite,
  required: true,
  searchFilters: { type: ORGANIZATION_TYPES.LAW_FIRM },
  type: 'objectSearchCreate',
};

const LienholderCaseContactObjectSearchCreate = (props: any) => {
  const legalOrganizationId = props.formManager.formModel?.case?.law_firm?.id
    , passThroughProps = !legalOrganizationId ? props :
      {
        ...props,
        fieldConfig: {
          ...props.fieldConfig,
          searchFilters: { legal_organization: legalOrganizationId, page_size: 1000 },
        },
        key: legalOrganizationId,
      };

  return (
    <ObjectSearchCreate {...passThroughProps} />
  );
};

const colProps = { span: 12 }
  , rowProps = DEFAULT_ROW_PROPS;

const plaintiffBirthdateField = {
  className: 'dd-privacy-mask-user-input',
  colProps,
  field: 'plaintiff.birthdate',
  label: 'Date of Birth',
  type: 'date',
  writeOnly: true,
};

function isCaseContactOptionDisabled (contact: IContact) {
  return doesContactNeedUpdate(contact);
}

export const registerCaseFieldSets: IFieldSetPartial[] = [
  {
    fields: [
      {
        className: 'dd-privacy-mask-user-input',
        colProps,
        field: 'plaintiff.first_name',
        required: true,
        editProps: { autoComplete: 'off' },
      },
      {
        className: 'dd-privacy-mask-user-input',
        colProps,
        field: 'plaintiff.last_name',
        required: true,
        editProps: { autoComplete: 'off' }
      },
      plaintiffBirthdateField,
    ],
    legend: 'Who is the plaintiff?',
    rowProps,
  },
  {
    fields: [
      {
        colProps: { span: 24 },
        editProps: {
          ...objectSearchCreateConfig.editProps,
          isOptionDisabled: isCaseContactOptionDisabled,
          searchIcon: <Icon.CaretDownSolid/>,
          searchOnEmpty: true,
          selectProps: {
            ...objectSearchCreateConfig.editProps.selectProps,
          },
        },
        endpoint: URLS.CONTACTS_BETA,
        field: 'contact',
        label: 'Law Firm Contact',
        renderOption: renderContactOption as (option: IEndpointOption) => React.ReactNode,
        searchFilters: { is_user: true },
        type: 'objectSearch',
      },
    ],
    legend: 'Who is working on this case?',
    rowProps,
  },
  {
    fields: [
      {
        ...optionSelectConfig,
        colProps,
        field: 'type',
        optionType: OPTION_KEYS.CASE_TYPE,
        required: true,
        showSearch: true,
        sorted: true,
        type: 'optionSelect',
      },
      {
        colProps,
        field: 'date_of_loss',
      },
      {
        ...optionSelectConfig,
        colProps,
        field: 'state_of_incident',
        optionType: OPTION_KEYS.US_STATES,
        showSearch: true,
        sorted: true,
        type: 'optionSelect',
      },
    ],
    legend: 'Information about the case',
    rowProps,
  },
];

function hasOldLawFirm (model: IModel) {
  return !!model?.case?.law_firm?.id;
}

function insertContactCreateIf (model: IModel) {
  const lawFirm = model?.case?.law_firm || {};

  return !hasOldLawFirm(model) && !!lawFirm.name;
}

function insertContactSearchCreateIf (model: IModel) {
  return hasOldLawFirm(model);
}

export const caseContactSearchCreateFields = [
  {
    colProps,
    field: 'first_name',
    populateNameFromSearch: true,
    required: true,
  },
  {
    colProps,
    field: 'last_name',
    populateNameFromSearch: true,
  },
  {
    colProps,
    field: 'email',
    required: true,
  },
];

const caseContactCreateFields = caseContactSearchCreateFields
  .map(field => ({
    ...field,
    field: `case.contacts[0].${field.field}`,
    insertIf: insertContactCreateIf,
  }));

export const baseCaseContactSearchCreateField = {
  colProps: { span: 24 },
  editProps: {
    ...objectSearchCreateConfig.editProps,
    isOptionDisabled: isCaseContactOptionDisabled,
    noSearchContent: `Type in contact's name`,
    searchOnEmpty: true,
    selectProps: {
      ...objectSearchCreateConfig.editProps.selectProps,
      placeholder: 'Search Law Firm Contact...',
    },
  },
  endpoint: URLS.CONTACTS_BETA,
  label: '',
  renderOption: renderContactOption,
  type: 'objectSearchCreate',
};

const caseContactSearchCreateField = {
  ...baseCaseContactSearchCreateField,
  createFields: { fields: caseContactSearchCreateFields, rowProps },
  editComponent: LienholderCaseContactObjectSearchCreate,
  field: 'case.contacts[0]',
  insertIf: insertContactSearchCreateIf,
};

export const registerLienFieldSet: IFieldSetPartial[] = [
  {
    fields: [
      {
        className: 'dd-privacy-mask-user-input',
        colProps,
        field: 'case.plaintiff.first_name',
        required: true,
        editProps: { autoComplete: 'off' }
      },
      {
        className: 'dd-privacy-mask-user-input',
        colProps,
        field: 'case.plaintiff.last_name',
        required: true,
        editProps: { autoComplete: 'off' }
      },
      {
        colProps,
        className: 'dd-privacy-mask-user-input',
        field: 'case.plaintiff.birthdate',
        label: 'Date of Birth',
        type: 'date',
      },
    ],
    legend: 'Who is the patient?',
    rowProps,
  },
  {
    fields: [
      addLawFirmField,
    ],
    legend: 'What is the law firm?',
    rowProps,
  },
  {
    fields: [
      caseContactSearchCreateField,
      ...caseContactCreateFields,
    ],
    legend: 'Who is the law firm contact?',
    rowProps,
    tooltip: 'The case manager, paralegal, legal assistant ' +
      'or attorney who is responsible for providing updates on this case.',
  },
  {
    fields: [
      {
        ...optionSelectConfig,
        colProps,
        field: 'case.type',
        optionType: OPTION_KEYS.CASE_TYPE,
        showSearch: true,
        sorted: true,
        type: 'optionSelect',
      },
      {
        colProps,
        field: 'case.date_of_loss',
      },
      {
        ...optionSelectConfig,
        colProps,
        field: 'case.state_of_incident',
        optionType: OPTION_KEYS.US_STATES,
        showSearch: true,
        sorted: true,
        type: 'optionSelect',
      },
    ],
    legend: 'Information about the case',
    rowProps,
  },
  {
    fields: treatmentStatusFields.map((config: IFieldConfigPartial) => ({ ...config, colProps })),
    legend: 'Information about your lien',
    rowProps,
  },
];

export function getCaseStatusDateInsertIf (field: string) {
  return (model: IDetailCase) => (!!model.status && get(CASE_STATUS_WITH_DATE, `${model.status}.field`) === field);
}

export const getCaseStatusFieldSets = (props?: any) => [
  {
    fields: [
      {
        ...optionSelectConfig,
        colProps: get(props, 'colProps', colProps),
        field: 'status',
        optionType: OPTION_KEYS.CASE_STATUS,
        render: (status: any, _col: any, _case: any) => (
          <>
            {formatOption(status, OPTION_KEYS.CASE_STATUS)}
            {_case.last_updated_at &&
              <p><small><i>Last Update Provided: {formatDate(_case.last_updated_at)}</i></small></p>
            }
          </>
        ),
        showSearch: false,
        type: 'optionSelect',
      },
      ...uniqBy(Object.values(CASE_STATUS_WITH_DATE), 'field').map(config => ({
        colIndex: 1, // colIndex is added here to work around how we split fields into the two column layout
        colProps: get(props, 'colProps', colProps),
        field: config.field,
        insertIf: getCaseStatusDateInsertIf(config.field),
        label: config.label,
      })),
      {
        field: 'note',
        writeOnly: true,
      },
    ],
    rowProps,
  },
];
