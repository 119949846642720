import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import autoBindMethods from 'class-autobind-decorator';

import { List } from 'antd';

import StoresClass from '../../../../stores/StoresClass';
import { InitialsAvatar } from '../../../common/Avatars';
import NotificationContent from '../NotificationContent';
import NotificationCreatedAt from '../NotificationCreatedAt';

import { IDefaultNotificationProps } from './DefaultMightyNotification';

interface IInjected extends IDefaultNotificationProps {
  stores: StoresClass;
}

@inject('stores')
@autoBindMethods
@observer
class LienholderLawFirmChangedNotification extends Component<IDefaultNotificationProps> {
  private get injected () {
    return this.props as IInjected;
  }

  private async onClick () {
    const { stores } = this.injected
      , { isVisible, notification, onClick } = this.props
      , caseId = notification.context.case_id
      ;

    onClick(notification);
    isVisible.setFalse();
    if (caseId) {
      await stores.infiniteTableStore.fetchDataWithOverrides({ id: caseId }, {});
    }
  }

  public render () {
    const { className, notification, style } = this.props
      , isLawFirmUser = this.injected.stores.users.isLawFirmUser
      , plaintiff = notification.context.plaintiff_name
      , content = isLawFirmUser ? notification.body : `Another lienholder reported a law firm change for ${plaintiff}`
      ;

    return (
      <List.Item key={notification.id} style={style} className={className} onClick={this.onClick}>
        <List.Item.Meta
          avatar={<InitialsAvatar name={plaintiff} />}
          description={<NotificationCreatedAt notification={notification} />}
          title={<NotificationContent content={content} notification={notification} />}
        />
      </List.Item>
    );
  }
}

export default LienholderLawFirmChangedNotification;
