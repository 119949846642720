import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import autoBindMethods from 'class-autobind-decorator';
import { Link } from 'react-router-dom';

import { Row, Col } from 'antd';

import NotificationSettingsWrapper from '../tab-communication/NotificationSettingsWrapper';
import StoresClass from '../../../stores/StoresClass';
import { ISettingTabProps } from '../settingsUtils';
import { ANT_HALF_COL_WIDTH, URLS } from '../../../utils/constants';
import Auth from '../../../base-modules/auth';

import { Card as AntdCard } from '../../common';

import OrganizationCard from './OrganizationCard';
import UserCard from './UserCard';

import styles from './AccountTab.module.less';

const GUTTER = 32;

interface IInjected extends ISettingTabProps {
  auth: Auth;
  stores: StoresClass;
}

@inject('stores', 'auth')
@autoBindMethods
@observer
class AccountTab extends Component<ISettingTabProps> {
  private get injected () {
    return this.props as IInjected;
  }

  private async onSave (data: any) {
    const { stores, auth } = this.injected
      , { users: { account, user } } = stores;

    if (account && user) {
      await this.props.isLoading.until(account.update(data, auth));
      user.updateName(data);
    }
  }

  public render () {
    const { isLoading, isAdmin, account } = this.props
      , { users: { isLawFirmUser } } = this.injected.stores
      , legalOrganization = account?.registry_legal_organization
      ;

    return (
      <>
        <Row gutter={GUTTER}>
          <Col span={ANT_HALF_COL_WIDTH}>
            <UserCard
              account={account}
              isAdmin={isAdmin}
              isLoading={isLoading}
              onSave={this.onSave}
            />
          </Col>
          <Col span={ANT_HALF_COL_WIDTH}>
            <OrganizationCard
              isLoading={isLoading}
              legalOrganization={legalOrganization}
            />
          </Col>
        </Row>
        <Row gutter={GUTTER}>
          <Col className={styles['email-preferences']}>
            {isLawFirmUser
              ? <NotificationSettingsWrapper />
              : (
                <AntdCard title='Email Preferences have moved to the Communication Preferences section'>
                  To access your notification preferences, visit the{' '}
                  <Link to={URLS.COMMUNICATION_PREFERENCES_PAGE}>Communication Preferences</Link>{' '}
                  section in the top-right menu
                </AntdCard>
              )
            }
          </Col>
        </Row>
      </>
    );
  }
}

export default AccountTab;
