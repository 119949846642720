import React, { Component, CSSProperties } from 'react';
import { inject, observer } from 'mobx-react';
import autoBindMethods from 'class-autobind-decorator';
import cx from 'classnames';
import { FixedSizeList } from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';

import { Layout } from 'antd';

import StoresClass from '../../../stores/StoresClass';
import { safeArrayAccess } from '../../../utils/utils';

import PortfolioListScrollItem from './PortfolioListScrollItem';

import styles from './PortfolioListScroll.module.less';

interface IProps {
  infiniteScrollHeight: number;
}

interface IInjected extends IProps {
  stores: StoresClass;
}

@inject('stores')
@autoBindMethods
@observer
class PortfolioListScroll extends Component<IProps> {
  private get injected () { return this.props as IInjected; }
  private get isLawFirmUser (): boolean { return this.injected.stores.users.isLawFirmUser; }

  private isItemLoaded (index: number): boolean {
    const { stores: { infiniteTableStore } } = this.injected;
    return !!safeArrayAccess(infiniteTableStore.data, index);
  }

  public renderItem (props: { index: number, style: CSSProperties }) {
    return <PortfolioListScrollItem {...props} />;
  }

  public render () {
    const { stores: { infiniteTableStore }, infiniteScrollHeight } = this.injected
      , LAW_FIRM_ITEM_HEIGHT = 71
      , LIENHOLDER_ITEM_HEIGHT = 90
      , ITEM_SIZE = this.isLawFirmUser ? LAW_FIRM_ITEM_HEIGHT : LIENHOLDER_ITEM_HEIGHT
      , className = cx(
        styles.root,
        styles.override,
        // This is only here to trigger mobx observability, as this is used in the arrow function below
        `list-height-${infiniteScrollHeight}`,
      )
      ;

    return (
      <Layout className={className}>
        <InfiniteLoader
          isItemLoaded={this.isItemLoaded}
          itemCount={infiniteTableStore.count}
          loadMoreItems={infiniteTableStore.fetchTableDataRange}
        >
          {({ onItemsRendered, ref }: any) => (
            <FixedSizeList
              height={infiniteScrollHeight}
              itemCount={infiniteTableStore.count}
              itemSize={ITEM_SIZE}
              onItemsRendered={onItemsRendered}
              ref={ref}
              width='100%'
            >
              {this.renderItem}
            </FixedSizeList>
          )}
        </InfiniteLoader>
      </Layout>
    );
  }
}

export default PortfolioListScroll;
