import React, { ComponentType } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { Subtract } from 'utility-types';

import { INavigateProps } from './navigationUtils';

export default function navigationWrapper<T extends INavigateProps> (Component: ComponentType<T>):
  ComponentType<Subtract<T, INavigateProps>> {
  return props => (
    <Component{...props as T} params={useParams()} navigate={useNavigate()} searchParams={useSearchParams()} />
  );
}
