import React from 'react';

import LoggedOutLandingPage from '../LoggedOutLandingPage';
import { LoginLink } from '../../utils/utils';

const ResponseCompletePage = () => (
  <LoggedOutLandingPage title='Request Complete'>
    <h2>Success! Your response has been submitted</h2>
    <h3>The law firm on file will be notified</h3>
    <LoginLink />
  </LoggedOutLandingPage>
);

export default ResponseCompletePage;
