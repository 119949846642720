import { optionSelectConfig } from '../../../utils/configCommon';
import { OPTION_KEYS, PLAINTIFF_TREATMENT_STATUSES } from '../../../utils/constants';

const plaintiffTreatmentStatusField = {
  ...optionSelectConfig,
  field: 'plaintiff_treatment_status',
  label: 'Treatment Status',
  optionType: OPTION_KEYS.TREATMENT_STATUS,
  type: 'optionSelect',
};

const finalTreatmentDateField = {
  field: 'final_treatment_date',
  insertIf: (model: any) => model.plaintiff_treatment_status === PLAINTIFF_TREATMENT_STATUSES.DONE_TREATING,
  label: 'Date of Final Treatment',
  type: 'datepicker',
};

export const treatmentStatusFields = [
  plaintiffTreatmentStatusField,
  finalTreatmentDateField,
];
