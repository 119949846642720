import { getParent, flow, Instance, types } from 'mobx-state-tree';
import { stateTreeDependencyGetters } from '../base-modules/state-tree-dependencies';

import { setValuesFromData } from '../utils/utils';
import { IDetailCase } from './Case';

export const CaseMessage = types
  .model('CaseMessage', {
    created_at: types.maybeNull(types.string),
    created_by_organization_name: types.maybeNull(types.string),
    created_by_name: types.maybeNull(types.string),
    id: types.identifier,
    note: types.maybeNull(types.string),
  });

export const CaseThreadContext = types
  .model('CaseThreadContext', {
    old_law_firm: types.maybeNull(types.string),
    new_law_firm: types.maybeNull(types.string),
    is_to_law_firm: types.maybeNull(types.boolean),
    is_from_lienholder: types.maybeNull(types.boolean),
  });

export const CaseThread = types
  .model('CaseThread', {
    action_needed: types.maybeNull(types.boolean),
    created_at: types.maybeNull(types.string),
    created_by_organization_name: types.maybeNull(types.string),
    context: CaseThreadContext,
    id: types.identifier,
    is_resolved: types.boolean,
    latest_message: types.maybeNull(CaseMessage),
    message_count: types.maybeNull(types.number),
    messages: types.optional(types.array(CaseMessage), []),
    request: types.maybeNull(types.string),
    to_organization_name: types.maybeNull(types.string),
    type: types.maybeNull(types.string),
  })
  .views(stateTreeDependencyGetters)
  .views(self => ({
    get _case (): IDetailCase {
      return getParent(self, 2);
    },
  }))
  .actions(self => {
    const fetchAllMessages = flow(function* () {
      const response = yield self.client.get(`/case-threads/${self.id}`);
      self.messages = response.messages;
    });

    const refresh = flow(function* () {
      const threadData = yield self.client.get(`/case-threads/${self.id}/`);

      setValuesFromData(self, threadData);
    });

    const resolve = flow(function* () {
      const response = yield self.client.update(`/case-threads/${self.id}/`, { is_resolved: true });
      self.is_resolved = true;
      self._case.setThreads(response.data);
    });

    const dismiss = flow(function* (note: string) {
      const submitData = {
        thread: self.id,
        note,
        resolves_thread: true,
      };

      yield self.client.create('/case-messages/', submitData);
      self._case.fetchThreads();
    });

    return { dismiss, fetchAllMessages, refresh, resolve };
  });

export interface ICaseThread extends Instance<typeof CaseThread> {}
export interface ICaseMessage extends Instance<typeof CaseMessage> {}
