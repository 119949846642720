import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import autoBindMethods from 'class-autobind-decorator';

import { Col, Row } from 'antd';

import { ILegalOrganizationWithContacts } from '../../../../models/Party';
import { Icon, ModalButton } from '../../../common';
import { IDetailLien } from '../../../../models/Lien';
import { IDetailCase } from '../../../../models/Case';
import StoresClass from '../../../../stores/StoresClass';

import NewOrganizationContactModal from './NewOrganizationContactModal';
import MoreDropdown from './MoreDropdown';
import AssignToMeButton from './AssignToMeButton';

import styles from './ContactsTab.module.less';

interface IProps {
  _case?: IDetailCase
  legalOrganization: ILegalOrganizationWithContacts;
  lien?: IDetailLien;
}

interface IInjected extends IProps {
  stores: StoresClass;
}

@inject('stores')
@autoBindMethods
@observer
class ContactToolbar extends Component<IProps> {
  private get injected () { return this.props as IInjected; }

  private get showAssignToMeButton () {
    const { legalOrganization } = this.props
      , { stores: { users } } = this.injected
      , contactList = legalOrganization.contacts
      ;

    if (contactList) { return legalOrganization.id === users.registryLegalOrganization?.id; }

    return false;
  }

  public render () {
    const { _case, legalOrganization, lien } = this.props
      , { stores: { users } } = this.injected
      , missingContacts = legalOrganization.contacts !== null && !legalOrganization.contacts.length
      , linkedContact = users.account?.linked_contact
      ;

    return (
      <Row align='middle' className={styles.contactToolBar} gutter={16}>
        {this.showAssignToMeButton && linkedContact && (
          <Col>
            <AssignToMeButton
              linkedContact={linkedContact}
              legalOrganization={legalOrganization}>
            </AssignToMeButton>
          </Col>
        )}
        {missingContacts && legalOrganization.isNewlyCreated && (
          <Col>
            <ModalButton
              buttonProps={{
                className: styles.linkButton,
              }}
              passThroughProps={{ legalOrganization }}
              ModalClass={NewOrganizationContactModal}
            >
              Add Contact Info<Icon.UserPlusSolid className={styles.icon} />
            </ModalButton>
          </Col>
        )}
        <Col>
          <MoreDropdown _case={_case} legalOrganization={legalOrganization} lien={lien} />
        </Col>
      </Row>
    );
  }
}

export default ContactToolbar;
