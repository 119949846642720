import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import autoBindMethods from 'class-autobind-decorator';
import { observable } from 'mobx';
import cx from 'classnames';

import SmartBool from '@mighty-justice/smart-bool';

import LienholderRegistrationLayout from './LienholderRegistrationLayout';
import StoresClass from '../../stores/StoresClass';
import { PageLoader } from '../common';

import EmailRegistrationForm from './EmailRegistrationForm';

import styles from './LienholderRegistration.module.less';

interface IInjected {
  stores: StoresClass;
}

@inject('stores')
@autoBindMethods
@observer
class EmailRegistrationPage extends Component<{}> {
  @observable private isLoadingUserData = new SmartBool(true);

  private get injected () { return this.props as IInjected; }

  public async componentDidMount () {
    const { stores: { caseFormStore } } = this.injected;
    this.isLoadingUserData.until(caseFormStore.setFormInfoInProgress());
  }

  public render () {
    return (
      <LienholderRegistrationLayout>
        {this.isLoadingUserData.isTrue ? <PageLoader /> : (
          <div className={cx(styles.root, styles.override)}>
            <EmailRegistrationForm />
          </div>
        )}
      </LienholderRegistrationLayout>
    );
  }
}

export default EmailRegistrationPage;
