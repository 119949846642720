import React, { Component } from 'react';
import { observer } from 'mobx-react';
import autoBindMethods from 'class-autobind-decorator';
import cx from 'classnames';

import { Row, Col } from 'antd';

import { Icon, LinkButton } from '../../../common';

import { IBetaDocumentSubmitData } from '../tab-documents/interfaces';

import styles from './ChangeRepresentationDocumentUpload.module.less';

interface IProps {
  document: IBetaDocumentSubmitData;
  index: number;
  removeDocument: (index: number) => void;
}

const COL_SPAN_ITEM = 20
  , COL_SPAN_DELETE = 4
  ;

@autoBindMethods
@observer
class DocumentTableRow extends Component<IProps> {
  private removeDocument () {
    const { index, removeDocument } = this.props;

    removeDocument(index);
  }

  public render () {
    const { document, index } = this.props;

    return (
      <Row className={cx({ [styles.item]: index !== 0 })}>
        <Col span={COL_SPAN_ITEM}>
          <p>{document.name}</p>
        </Col>
        <Col span={COL_SPAN_DELETE}>
          <LinkButton id='delete-representation' onClick={this.removeDocument}>
            <Icon.TrashAltSolid className={styles.delete} />
          </LinkButton>
        </Col>
      </Row>
    );
  }
}

export default DocumentTableRow;
