import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import autoBindMethods from 'class-autobind-decorator';

import { Spin } from 'antd';

import StoresClass from '../../../stores/StoresClass';
import { OPTION_KEYS } from '../../../utils/constants';

import LawFirmNotificationSettings from './LawFirmNotificationSettings';
import LienholderNotificationSettings from './LienholderNotificationSettings';

interface IInjected {
  stores: StoresClass;
}

@inject('stores')
@autoBindMethods
@observer
class NotificationSettingsWrapper extends Component<{}> {
  private get injected () { return this.props as IInjected; }

  public render () {
    const {
        stores: {
          options,
          options: { getOptions },
          settings,
          users: { isLawFirmUser },
        },
      } = this.injected
      , isLoading = options.isLoading || settings.isLoading
      , notificationCategories = isLawFirmUser
        ? getOptions(OPTION_KEYS.LAW_FIRM_NOTIFICATION_CATEGORY)
        : getOptions(OPTION_KEYS.LIENHOLDER_NOTIFICATION_CATEGORY)
      ;

    if (!notificationCategories) { return null; }

    return (
      <Spin spinning={isLoading.isTrue}>
        {isLawFirmUser
          ? <LawFirmNotificationSettings notificationCategories={notificationCategories} />
          : <LienholderNotificationSettings notificationCategories={notificationCategories} />
        }
      </Spin>
    );
  }
}

export default NotificationSettingsWrapper;
