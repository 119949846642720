import React, { Component } from 'react';
import { observer } from 'mobx-react';
import autoBindMethods from 'class-autobind-decorator';

import SmartBool from '@mighty-justice/smart-bool';
import { FormModal } from '@mighty-justice/fields-ant';
import { getNameOrDefault } from '@mighty-justice/utils';

import { IDetailCase } from '../../../../models/Case';
import { ICaseThread } from '../../../../models/CaseThread';
import { IDetailLien } from '../../../../models/Lien';
import { VERIFICATION_STATUSES } from '../../../../utils/constants';

import { resolveRejectionFieldSets, RESOLVE_REJECTION_PATHS, AUTO_THREAD_RESPONSE_NOTE } from './constants';

interface IProps {
  _case: IDetailCase;
  lien: IDetailLien;
  isVisible: SmartBool;
  thread: ICaseThread;
}

@autoBindMethods
@observer
class ResolveRejectionModal extends Component <IProps> {
  private async onSave (data: any) {
    const { lien, thread } = this.props
      , { path, ...payload } = data
      ;

    switch (path) {
    case RESOLVE_REJECTION_PATHS.ADD_NOTE:
      await lien.updateVerificationStatus({
        ...payload,
        verification_status: VERIFICATION_STATUSES.RESUBMITTED,
      });
      await thread.dismiss(AUTO_THREAD_RESPONSE_NOTE.RESUBMITTED_LIEN);
      break;

    case RESOLVE_REJECTION_PATHS.REMOVE_FIRM:
      await lien.resolveRejection({ law_firm: null }, thread);
      break;

    case RESOLVE_REJECTION_PATHS.CHANGE_FIRM:
      const lawFirmId = await lien.upsertLawFirm(data.law_firm);
      await lien.resolveRejection({ ...payload, law_firm: lawFirmId }, thread);
      break;

    // This is mostly carried over from the legacy code. Assume below is to alert the developer of an error.
      // istanbul ignore next
    default:
      // istanbul ignore next
      throw new Error(`Uncovered or malformed path in ResolveRejectionModal.onSave: '${path}'`);
    }
  }

  public render () {
    const { _case, isVisible } = this.props
      , plaintiffName = <span className='dd-privacy-mask'>{getNameOrDefault(_case.plaintiff)}</span>
      , beforeMessage = (
        <p>Choose from one of the options below to ensure your lien is on file with the correct law firm:</p>
      );

    return (
      <FormModal
        childrenBefore={beforeMessage}
        defaults={{ path: RESOLVE_REJECTION_PATHS.CHANGE_FIRM }}
        fieldSets={resolveRejectionFieldSets}
        isVisible={isVisible}
        onSave={this.onSave}
        title={<>Get {plaintiffName}'s lien on file with the correct law firm</>}
      />
    );
  }
}

export default ResolveRejectionModal;
