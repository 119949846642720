import React, { useEffect, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import Auth from '../base-modules/auth';

import { getLoginURL, getUrl } from './navigationUtils';

export const PrivateRoute = ({ auth, children }: { auth: Auth, children: JSX.Element }) => {
  const location = useLocation()
    , redirect = getLoginURL(getUrl())
    , [isAuthenticated, setIsAuthenticated] = useState<boolean | undefined>(undefined)
    ;

  useEffect(() => {
    setIsAuthenticated(auth.isAuthenticated);
  });

  if (isAuthenticated === undefined) {
    return null;
  }

  if (!isAuthenticated) {
    return <Navigate to={redirect} state={{ from: location }} replace />;
  }

  return children;
};
