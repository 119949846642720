import autoBindMethods from 'class-autobind-decorator';
import Axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import httpStatus from 'http-status-codes';

import { METHODS } from '../utils/constants';

import { Environment } from './environment';

export interface IListResponse {
  count: number;
  next: string | null;
  previous: string | null;
  results: any[];
}

export type IHeader = false | void | { [key: string]: string };
type IHeaderProvider = () => IHeader;

@autoBindMethods
class Client {
  public transport: AxiosInstance;
  private headerProviders: IHeaderProvider[] = [];
  public environment: Environment;

  public constructor (environment: Environment) {
    this.environment = environment;
    this.transport = Axios.create();
  }

  private get window () {
    return window as any;
  }

  private get apiBaseUrl () {
    return `https://${this.environment.apiHost}/api/registry/v1`;
  }

  public addHeaderProvider (headerProvider: IHeaderProvider) {
    this.headerProviders.push(headerProvider);
  }

  public get providedHeaders (): { [key: string]: string } {
    return {
      // For all header providers
      ...this.headerProviders
        // Call the function, which returns an object or void
        .map(provider => provider())
        // And then merge all the returned values
        .reduce((result, current) => ({ ...result, ...current }), {})
    };
  }

  public async invoke (config: Partial<AxiosRequestConfig>) {
    const contentType = config.data instanceof this.window.FormData
      ? 'multipart/form-data'
      : 'application/json';

    try {
      return (await this.transport.request({
        baseURL: this.apiBaseUrl,
        withCredentials: true,
        ...config,
        headers: {
          ...this.providedHeaders,
          'Accept': 'application/json; charset=utf-8;',
          'Content-Type': contentType,
          ...config.headers,
        },
      }));
    }
    catch (error) {
      // currently the components using token auth (logged out document requests) have custom error handling
      if (error.response.config.headers.Authorization?.includes('TOKEN')) {
        throw error;
      }

      // for 401 errors, we handle them in auth.ts interceptor and PrivateRoute render
      // for 404 errors, we return undefined so components can check if the object exists without parsing the response
      if (error.response.status === httpStatus.UNAUTHORIZED || error.response.status === httpStatus.NOT_FOUND) {
        return undefined;
      }

      // some components handle errors differently, so we return the whole response
      if (error.response.config.method === 'get') {
        return error.response;
      }

      throw error;
    }
  }

  // istanbul ignore next
  public async rawRequest (config: AxiosRequestConfig) {
    return await this.transport.request(config);
  }

  public async get (url: string) {
    const response = await this.invoke({ method: METHODS.GET, url });
    return response.data;
  }

  public async update (url: string, data: any = {}) {
    return this.invoke({ method: METHODS.PATCH, url, data });
  }

  public async create (url: string, data: object) {
    return this.invoke({ method: METHODS.POST, data, url });
  }

  public async delete (url: string) {
    return this.invoke({ method: METHODS.DELETE, url });
  }

  public async postFile (url: string, file: File, data: object) {
    const formData = new FormData()
      , isLocal = url.includes(this.environment.apiHost)
      , headers = {
        ...(isLocal ? this.providedHeaders : {}),
        'Content-Type': 'multipart/form-data',
      }
    ;

    Object.entries(data).forEach(([key, value]) => formData.append(key, value));
    formData.append('file', file); // Must be included last

    return this.rawRequest({ method: METHODS.POST, data: formData, url, headers });
  }
}

export default Client;
