import React from 'react';
import autoBindMethods from 'class-autobind-decorator';
import store from 'store';
import { computed, observable } from 'mobx';

import { notification } from 'antd';
import { RcFile } from 'antd/lib/upload';

import { IModel } from '@mighty-justice/fields-ant/dist/props';
import { IFieldSetPartial } from '@mighty-justice/fields-ant';

import Client from '../base-modules/client';
import DeleteButton from '../components/page-lh-registration/DeleteButton';
import { IRedirectTo } from '../utils/navigationUtils';
import { addLawFirmField } from '../components/page-portfolio/portfolio-detail/config/configUtils';
import { DEFAULT_ROW_PROPS } from '../utils/constants';
import { renderSimpleOrganization } from '../utils/utils';
import { REGISTRATION_URLS } from '../components/page-lh-registration/utils/constants';

const rowProps = { ...DEFAULT_ROW_PROPS, gutter: 16 }
  , emailInviteType = 'EMAIL'
  ;

interface UserData {
  user_first_name: string;
  user_last_name: string;
  user_email: string;
  lienholder: {
    address?: object;
    name: string;
    phone_number?: string;
    type: string;
    website?: string;
  };
}

// do not include with other LOCAL_STORAGE logic
export const COMPULSION_EVENT_KEY = 'compulsionEventId';

@autoBindMethods
class CaseFormStore {
  @observable caseKeys: number[] = [];
  @observable document: RcFile | null = null;
  @observable initialSearchValue = '';
  @observable userData: UserData | null = null;

  client: Client;

  public constructor (client: Client) {
    this.client = client;
  }

  public async updateOrCreateCompulsionEvent (model: any): Promise<IRedirectTo> {
    const baseUrl = '/register-lienholder/';
    if (this.compulsionEventId) {
      await this.client.update(`${baseUrl}${this.compulsionEventId}/`, model);
    }
    else {
      const result = await this.client.create(baseUrl, { ...model, compulsion_type: emailInviteType });
      this.setCompulsionEventId(result.data.id);
    }
    this.setUserData(model);
    return REGISTRATION_URLS.CASE_CREATION;
  }

  public async updateCompulsionEvent (model: any, isFinalized = false): Promise<IRedirectTo> {
    if (!this.compulsionEventId) {
      notification.error({
        description: 'There was an error processing your cases. Please refresh and try again',
        message: 'Error',
      });
      return null;
    }

    await this.client.update(`/register-lienholder/${this.compulsionEventId}/`, model);
    this.setUserData(model);
    // Future-proofing so this method is not incorrectly used for future updates
    if (isFinalized) {
      this.setCompulsionEventId(null);
    }
    return REGISTRATION_URLS.CONFIRMATION;
  }

  public async createCompulsionEventFromEmail (model: any): Promise<IRedirectTo> {
    const submitData = { ...model, compulsion_type: emailInviteType }
      , result = this.compulsionEventId ?
        await this.client.update(`/register-lienholder/${this.compulsionEventId}/`, submitData) :
        await this.client.create('/register-lienholder/', submitData);

    this.setCompulsionEventId(result.data.id);
    this.setUserData(model);
    return REGISTRATION_URLS.CASE_CREATION;
  }

  public async createCases (model: IModel) {
    const submitData = !!this.document
      ? this.getFileSubmitData(this.document)
      : this.getManualSubmitData(model)
      ;

    try {
      await this.client.create('/register-cases/', submitData);
      return REGISTRATION_URLS.ACCOUNT_DETAILS;
    }
    catch (error) {
      const isCompulsionEventIdError = error?.response?.data?.compulsion_event_id;
      if (!isCompulsionEventIdError) { throw error; }

      // remove the compulsion event since maybe it is malformed or the model is deleted
      // and go back to step 1 to generate a new one
      this.setCompulsionEventId(null);
      notification.error({
        description: 'There was an error processing your cases. Please refresh and try again',
        message: 'Error',
      });

      return REGISTRATION_URLS.ACCOUNT_CREATION;
    }
  }

  public async setFormInfoInProgress () {
    this.clearCases();
    // if there is already a compulsion event but no user data
    // then that means the user refreshed / left the page and came back
    // and the data should be refreshed
    if (this.compulsionEventId && !this.userData) {
      const result = await this.client.get(`/register-lienholder/${this.compulsionEventId}`);
      this.setUserData(result);
    }
  }

  public setCompulsionEventId (id: string | null) {
    if (!id) {
      store.remove(COMPULSION_EVENT_KEY);
    }
    else {
      store.set(COMPULSION_EVENT_KEY, id);
    }
  }

  private getManualSubmitData (model: IModel) {
    const cases = model.cases.reduce((result: any, _case: any) => {
      const { law_firm, plaintiff_first_name, plaintiff_last_name, plaintiff_birthdate, date_of_loss } = _case
        , data = { law_firm: law_firm.id, plaintiff_first_name, plaintiff_last_name, plaintiff_birthdate, date_of_loss }
        ;

      if (_case) { result.push(data); }
      return result;
    }, []);

    return { cases, compulsion_event_id: this.compulsionEventId };
  }

  private getFileSubmitData (fileData: RcFile | null) {
    const form = new FormData();
    if (fileData) {
      form.append('data_file', fileData);
    }
    form.append('compulsion_event_id', this.compulsionEventId);

    return form;
  }

  public removeDocument () {
    this.document = null;
    return null;
  }

  public setUserData (data: UserData) {
    this.userData = data;
  }

  public clearCases () {
    this.caseKeys = [];
    this.setLawFirm(null);
  }

  public removeKey (caseKeyRemove: number) {
    this.caseKeys = this.caseKeys.filter(caseKey => caseKey !== caseKeyRemove);
  }

  public addCase () {
    this.caseKeys.push(this.caseKeys.length);
  }

  public get compulsionEventId () {
    return store.get(COMPULSION_EVENT_KEY);
  }

  private setLawFirm (lawFirm: any) {
    this.initialSearchValue = lawFirm;
  }

  public setDocumentFromList (_file: RcFile, fileList: RcFile[]) {
    this.document = fileList[0];
    return !!this.document;
  }

  @computed
  public get caseFieldSets (): IFieldSetPartial[] {
    return this.caseKeys.map(caseKey => {
      const showLabel = this.caseKeys[0] === caseKey
        , required = !this.document
        , insertDelete = this.caseKeys.length > 1
        , editProps = { disabled: !!this.document }
        ;

      return {
        fields: [
          {
            ...addLawFirmField,
            colProps: { span: 5 },
            editProps: {
              ...addLawFirmField.editProps,
              ...editProps,
              onChange: caseKey === 0 && this.setLawFirm,
            },
            field: `cases.${caseKey}.law_firm`,
            required,
            endpoint: '/law-firms',
            renderOption: renderSimpleOrganization,
            showLabel,
            type: 'objectSearch',
            value: this.initialSearchValue,
          },
          {
            className: 'dd-privacy-mask-user-input',
            colProps: { span: 4 },
            editProps,
            field: `cases.${caseKey}.plaintiff_first_name`,
            required,
            label: 'First Name',
            showLabel,
          },
          {
            className: 'dd-privacy-mask-user-input',
            colProps: { span: 4 },
            editProps,
            field: `cases.${caseKey}.plaintiff_last_name`,
            required,
            label: 'Last Name',
            showLabel,
          },
          {
            className: 'dd-privacy-mask-user-input',
            colProps: { span: 5 },
            editProps,
            field: `cases.${caseKey}.plaintiff_birthdate`,
            label: 'Patient Date of Birth',
            showLabel,
          },
          {
            colProps: { span: 5 },
            editProps,
            field: `cases.${caseKey}.date_of_loss`,
            label: 'Patient Date of Loss',
            showLabel,
          },
          {
            colProps: { span: 1 },
            editComponent: () => <DeleteButton caseKey={caseKey} />,
            insertIf: () => insertDelete,
            field: `cases.${caseKey}.delete`,
            showLabel: false,
            value: caseKey,
          },
        ],
        key: caseKey,
        rowProps,
      };
    });
  }
}

export default CaseFormStore;
