import autoBindMethods from 'class-autobind-decorator';

import Client from '../base-modules/client';
import { TERMS_OF_SERVICE_DEFAULT } from '../utils/constants';

export interface ITermsOfService {
  link: string;
  copy: string;
}

@autoBindMethods
class LinkCopyStore {
  private client: Client;

  public termsOfService: ITermsOfService = {} as ITermsOfService;

  public constructor (client: Client) {
    this.client = client;
  }

  public get baseUrl () {
    return `https://${this.client.environment.apiHost}/api/v1/`;
  }

  public async fetch () {
    try {
      const linkCopyItems = await (this.client.invoke({
          baseURL: this.baseUrl,
          method: 'get',
          url: '/link-copy-items/',
        }))
        , { TERMS_OF_SERVICE } = linkCopyItems.data;

      this.termsOfService = TERMS_OF_SERVICE || TERMS_OF_SERVICE_DEFAULT;
    }
    catch (error) {
      this.termsOfService = TERMS_OF_SERVICE_DEFAULT;
    }
  }
}

export default LinkCopyStore;
