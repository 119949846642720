import React from 'react';
import cx from 'classnames';

import { Col, Row } from 'antd';

import styles from './InfoCard.module.less';

export interface IInfoCardProps {
  bottomRightRender?: React.ReactNode;
  content?: string | React.ReactNode | null;
  overrideClassName?: {
    subtitle?: string,
    title?: string,
    titleRow?: string;
    topRightContent?: string,
    wrapper?: string,
  }
  subtitle?: string | React.ReactNode;
  title: string | null;
  topRightContent?: string | null;
}

function InfoCard (props: IInfoCardProps) {
  const { bottomRightRender, content, overrideClassName, subtitle, title, topRightContent } = props;

  return (
    <Col className={cx(styles.root, overrideClassName?.wrapper)}>
      <Row justify='space-between' className={overrideClassName?.titleRow}>
        <Col className={cx(styles.title, overrideClassName?.title)}>
          {title}
        </Col>
        <Col className={cx(styles.topRightContent, overrideClassName?.topRightContent)}>
          {topRightContent}
        </Col>
      </Row>
      <Row justify='space-between'>
        <Col className={styles.leftColumn}>
          {subtitle && (
            <Row className={overrideClassName?.subtitle}>
              {subtitle}
            </Row>)}
          <Row className={styles.content}>
            {content}
          </Row>
        </Col>
        {bottomRightRender && (
          <Col className={styles.rightColumn}>
            {bottomRightRender}
          </Col>
        )}
      </Row>
    </Col>
  );
}

export default InfoCard;
