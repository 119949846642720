import React, { Component } from 'react';
import { observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import autoBindMethods from 'class-autobind-decorator';

import SmartBool from '@mighty-justice/smart-bool';
import { Form } from '@mighty-justice/fields-ant';
import { IBackendValidation } from '@mighty-justice/fields-ant/dist/utilities/FormManager';
import { IModel } from '@mighty-justice/fields-ant/dist/props';

import { Environment } from '../../base-modules/environment';
import { Icon } from '../common';

import { TokenExpiredMessage } from './renderUtils';

export interface IPasswordResetSubmitData {
  passwordResetURL: string;
  newPassword?: string;
  reNewPassword?: string;
}

interface IProps {
  renderChildrenAfter?: () => React.ReactNode;
  resetPassword: ({ passwordResetURL, newPassword, reNewPassword }: IPasswordResetSubmitData) => Promise<void>;
  title: string;
}

interface IInjected extends IProps {
  environment: Environment;
}

@inject('environment')
@autoBindMethods
@observer
class PasswordResetForm extends Component<IProps> {
  @observable private isTokenExpired = new SmartBool();

  private get injected () { return this.props as IInjected; }

  private get passwordResetURL () {
    const { environment: { apiHost } } = this.injected;
    return `https://${apiHost}/api/v1/auth/password/reset/confirm/`;
  }

  private processErrors (errors: IBackendValidation) {
    const INVALID_TOKEN = 'Invalid token for given user.'
      , errorMessagesFiltered = errors.errorMessages.filter(error => error.message !== INVALID_TOKEN);

    if (errors.errorMessages.length !== errorMessagesFiltered.length) {
      this.isTokenExpired.setTrue();
    }

    return {
      ...errors,
      errorMessages: errorMessagesFiltered,
    };
  }

  private async handleSubmit (model: IModel) {
    const { resetPassword } = this.props
      , submitData = { ...model, passwordResetURL: this.passwordResetURL };

    await resetPassword(submitData);
  }

  public render () {
    const { title } = this.props;

    if (this.isTokenExpired.isTrue) { return <TokenExpiredMessage type='password reset' />; }

    return (
      <Form
        blockSubmit
        children={this.props.renderChildrenAfter && this.props.renderChildrenAfter()}
        fieldSets={[[
          {
            editProps: { prefix: <Icon.LockAltSolid /> },
            field: 'newPassword',
            required: true,
            type: 'password',
          },
          {
            editProps: { prefix: <Icon.LockAltSolid /> },
            field: 'reNewPassword',
            label: 'Re-enter New Password',
            required: true,
            type: 'password',
          },
        ]]}
        onSave={this.handleSubmit}
        processErrors={this.processErrors}
        title={title}
      />
    );
  }
}

export default PasswordResetForm;
