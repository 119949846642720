import { applySnapshot, cast, destroy, flow, getParent, getSnapshot, Instance, isAlive, types } from 'mobx-state-tree';

import { ORGANIZATION_TYPES, URLS } from '../utils/constants';

import { getNameOrDefault, toKey } from '@mighty-justice/utils';

import Auth from '../base-modules/auth';
import { stateTreeDependencyGetters } from '../base-modules/state-tree-dependencies';

import { IDetailCase, IListCase } from './Case';
import { Contact, ICaseContact, IContact, ILienContact } from './Contact';
import { IDetailLien } from './Lien';

export const Address = types.model('Address', {
  address1: types.maybeNull(types.string),
  address2: types.maybeNull(types.string),
  city: types.maybeNull(types.string),
  state: types.maybeNull(types.string),
  zip_code: types.maybeNull(types.string),
});

export const Person = types.model('Person', {
  birthdate: types.maybeNull(types.string),
  first_name: types.string,
  id: types.identifier,
  last_name: types.string,
  phone_number: types.maybeNull(types.string),
})
  .views(self => ({
    get fullName () {
      return getNameOrDefault(self);
    }
  }))
  ;

export const LegalOrganization = types
  .model('LegalOrganization', {
    address: types.maybeNull(Address),
    // should only be serialized for legal organizations attached to registry accounts
    can_create_registry_cases: types.maybeNull(types.boolean),
    check_mailing_address: types.maybeNull(Address),
    contact_emails: types.array(types.string),
    created_at: types.string,
    employer_id_number: types.maybeNull(types.string),
    // should only be used for escrow agent lien / lienholder creation
    has_contacts: types.maybeNull(types.boolean),
    has_tags: types.maybeNull(types.boolean),
    id: types.identifier,
    is_new_case_email_enabled: types.maybeNull(types.boolean),
    is_partner_law_firm: types.maybeNull(types.boolean),
    is_rewards_participant: types.maybeNull(types.boolean),
    lienholder_document_upload_notifications_enabled: types.maybeNull(types.boolean),
    name: types.string,
    notes_disabled: types.maybeNull(types.boolean),
    phone_number: types.maybeNull(types.string),
    shares_documents_manually: false,
    stripe_connected_account_id: types.maybeNull(types.string),
    type: types.maybeNull(types.string),
    website: types.maybeNull(types.string),
  })
  .views(stateTreeDependencyGetters)
  .actions(self => {
    const update = flow(function* (data: any, auth: Auth) {
      const response = yield self.client.update(`legal-organizations/${data.id}/`, data);
      yield auth.refreshAuthentication();
      // legal organization endpoint does not contain auth legal organization fields
      applySnapshot(self, { ...getSnapshot(self), ...response.data });
    });

    const fetchCases = flow(function* (queryParams) {
      const qs = toKey({ liens__lienholder: self.id, ...queryParams })
        , response = yield self.client.get(`/beta/escrow-agent/portfolio/${qs}`)
        ;

      return response as { results: IListCase[], count: number };
    });

    const connectStripe = flow(function* () {
      const response = yield self.client.get('/stripe/connect/');

      window.location.replace(response.redirect_url);
    });

    const disconnectStripe = flow(function* () {
      yield self.client.get('/stripe/disconnect/');

      self.stripe_connected_account_id = null;
    });

    const updateStripeAccount = (stripeId: string) => {
      self.stripe_connected_account_id = stripeId;
    };

    return {
      connectStripe,
      disconnectStripe,
      fetchCases,
      update,
      updateStripeAccount,
    };
  });

// holds the majority of contact logic
// cases and liens have a contact API that contains
//     addContact (contactId: string)
//     removeContact (contactId: string)
// permissions also rely on IDetailCase's has_single_lienholder
// (this should be refactored to be a permission call to the backend)
export const LegalOrganizationWithContacts = types.compose(
  LegalOrganization,
  types.model({
    // a field only used on the frontend to show an add contact button only if
    // the organization was just created by the user
    isNewlyCreated: false,

    // These fields are null until they are fetched
    contacts: types.optional(types.maybeNull(types.array(Contact)), null),
  }),
)
  .views(stateTreeDependencyGetters)
  .views(self => ({
    get caseOrLien () {
      return getParent(self, 1) as any;
    },
  }))
  .views(self => ({
    canDeleteContact (contact: IContact): boolean {
      const isLawFirmContact = self.type === ORGANIZATION_TYPES.LAW_FIRM
        , linkedContacts: Array<ICaseContact | ILienContact> = isLawFirmContact ?
          (self.caseOrLien as IDetailCase).case_contacts : (self.caseOrLien as IDetailLien).lien_contacts
        , linkedContact = linkedContacts?.find(
          lc => lc.contact === contact.id
        );

      return !!linkedContact?.can_user_remove_contact;
    }
  }))
  .actions(self => {
    const setContacts = (contacts: IContact[]) => {
      if (!isAlive(self)) { return; }
      self.contacts = cast(contacts);
    };

    const setNewlyCreated = () => {
      if (!isAlive(self)) { return; }
      self.isNewlyCreated = true;
    };

    const addExistingContact = flow(function* (contact: IContact) {
      // is either a law firm on a case or a lienholder on a lien
      // either way, we want to call the parent's add contact function
      // we can't use IDetailCase | IDetailLien because they depend on this type
      yield self.caseOrLien.addContact(contact.id);

      if (!isAlive(self)) { return; }

      if (self.contacts) {
        self.contacts.push(contact);
      }
    });

    const addNewContact = flow(function* (contactData: IContact) {
      const response = yield self.client.create(URLS.CONTACTS_BETA, { ...contactData, legal_organization: self.id });

      yield addExistingContact(response.data);
    });

    const removeContact = flow(function* (contactId: string) {
      const caseOrLien = getParent(self, 1) as any;
      yield caseOrLien.removeContact(contactId);

      const foundContact = self.contacts?.find(contact => contact.id === contactId);
      if (foundContact) { destroy(foundContact); }
    });

    return {
      addExistingContact,
      addNewContact,
      removeContact,
      setContacts,
      setNewlyCreated,
    };
  });

export const Lienholder = types.compose(
  LegalOrganizationWithContacts,
  types.model({}),
);

export const LawFirm = types.compose(
  LegalOrganizationWithContacts,
  types.model({}),
);

export const SearchLegalOrganization = types
  .model('SearchLegalOrganization', {
    address: types.maybeNull(Address),
    created_at: types.string,
    id: types.identifier,
    name: types.string,
    type: types.maybeNull(types.string),
    website: types.maybeNull(types.string),
    phone_number: types.maybeNull(types.string),
  });

export interface IAddress extends Instance<typeof Address> {}
export interface IPerson extends Instance<typeof Person> {}
export interface ILegalOrganization extends Instance<typeof LegalOrganization> {}
export interface ILegalOrganizationWithContacts extends Instance<typeof LegalOrganizationWithContacts> {}
export interface ILawFirm extends Instance<typeof LawFirm> {}
export interface ISearchLegalOrganization extends Instance<typeof SearchLegalOrganization> {}
