import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import autoBindMethods from 'class-autobind-decorator';

import SmartBool from '@mighty-justice/smart-bool';

import { IDetailCase } from '../../../../../models/Case';
import { IBetaDocument } from '../../../../../models/Document';
import StoresClass from '../../../../../stores/StoresClass';
import { OPTION_KEYS } from '../../../../../utils/constants';

import DocumentModal from '../common/DocumentModal';
import { IBetaDocumentFields } from '../interfaces';

interface IProps {
  _case: IDetailCase;
  document: IBetaDocument;
  isVisible: SmartBool;
}

interface IInjected extends IProps {
  stores: StoresClass;
}

@inject('stores')
@autoBindMethods
@observer
class EditDocumentModal extends Component<IProps> {
  private get injected () {
    return this.props as IInjected;
  }

  private get typeOptions (): string {
    const { document } = this.props
      , { users: { isLawFirmUser } } = this.injected.stores
      , lawFirmOptions = document.lien
        ? OPTION_KEYS.LAW_FIRM_LIEN_DOCUMENT_TYPES
        : OPTION_KEYS.LAW_FIRM_CASE_DOCUMENT_TYPES
      , lienholderOptions = OPTION_KEYS.LIENHOLDER_DOCUMENT_TYPES
      , options = isLawFirmUser ? lawFirmOptions : lienholderOptions;

    return options;
  }

  private async onSave (documents: IBetaDocumentFields[]) {
    const { _case, document } = this.props;

    await _case.editDocument({ ...documents[0], id: document.id });
  }

  public render () {
    const { _case, document, ...passDownProps } = this.props;

    return (
      <DocumentModal
        _case={_case}
        documents={[document]}
        model={document}
        editDocuments={this.onSave}
        title='Edit Document Details'
        typeOptions={this.typeOptions}
        {...passDownProps}
      />
    );
  }
}

export default EditDocumentModal;
