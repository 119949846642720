import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import autoBindMethods from 'class-autobind-decorator';

import { IDetailCase } from '../../../models/Case';
import { ILegalOrganization } from '../../../models/Party';
import StoresClass from '../../../stores/StoresClass';

import CaseDedupeSelectCard from './CaseDedupeSelectCard';

interface IProps {
  _case: IDetailCase;
  onCaseSelect: (_case: IDetailCase | null) => void;
}

interface IInjected extends IProps {
  stores: StoresClass;
}

@inject('stores')
@autoBindMethods
@observer
class UserCaseDedupeSelectCard extends Component<IProps> {
  private get injected () {
    return this.props as IInjected;
  }

  private get lawFirm () {
    // law firm must exist because
    // 1. if there is an escrow agent, it is the law firm
    // 2. the modal only shows for a lienholder if the case has a law firm
    return this.props._case.law_firm as ILegalOrganization;
  }

  private renderChildren () {
    const { stores: { users: { isLawFirmUser } } } = this.injected;

    if (isLawFirmUser) {
      return null;
    }

    return (
      <div style={{ fontSize: '13px' }}>
        Cases can only be merged with other cases at the same law firm:
        <br/>
        <b>{this.lawFirm.name}</b>
      </div>
    );
  }

  public render () {
    const { _case, onCaseSelect } = this.props;

    return (
      <CaseDedupeSelectCard
        endpoint={`beta/${_case.baseEndpoint}/portfolio/`}
        onCaseSelect={onCaseSelect}
        searchFilters={{
          law_firm: this.lawFirm.id,
        }}
        selectedCaseId={_case.id}
        children={this.renderChildren()}
      />
    );
  }
}

export default UserCaseDedupeSelectCard;
