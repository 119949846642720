import React from 'react';
import cx from 'classnames';

import { IValue } from '@mighty-justice/fields-ant/dist/props';

import { objectSearchCreateConfig } from '../../../../utils/configCommon';
import { renderOrganizationWithWebsite } from '../../../../utils/utils';
import { ORGANIZATION_TYPES, URLS } from '../../../../utils/constants';
import { formatWebsite } from '@mighty-justice/utils';

export function renderLawFirmWebsite (value: IValue) {
  if (value && value.website) {
    return <span>Website: {formatWebsite(value.website)}</span>;
  }
  return null;
}

export const addLawFirmField = {
  className: cx('form-item-law-firm'),
  colProps: { span: 24 },
  createFields: [{ field: 'name', populateFromSearch: true, required: true }, { field: 'website', type: 'url' }],
  editProps: {
    ...objectSearchCreateConfig.editProps,
    noSearchContent: `Type in law firm's name`,
    selectProps: {
      ...objectSearchCreateConfig.editProps.selectProps,
      placeholder: 'Search law firm...',
    },
  },
  endpoint: URLS.LEGAL_ORGANIZATIONS_LIGHT,
  field: 'case.law_firm',
  formItemRenderExtra: renderLawFirmWebsite,
  renderOption: renderOrganizationWithWebsite,
  required: true,
  searchFilters: { type: ORGANIZATION_TYPES.LAW_FIRM },
  type: 'objectSearchCreate',
};
