import React from 'react';

import SmartBool from '@mighty-justice/smart-bool';

import { ILegalOrganizationWithContacts } from '../../../../models/Party';

import ConfirmationOnContactErrorModal from './ConfirmationOnContactErrorModal';
import { CONTACT_FIELDS, DEFAULT_MODAL_WIDTH } from './constants';

import styles from './Modal.module.less';

interface IProps {
  isVisible: SmartBool;
  legalOrganization: ILegalOrganizationWithContacts;
}

function NewOrganizationContactModal (props: IProps) {
  const { legalOrganization } = props;

  return (
    <ConfirmationOnContactErrorModal
      legalOrganization={legalOrganization}
      onSave={legalOrganization.addNewContact}
      passThroughProps={{
        className: styles.modal,
        cancelText: 'No current info',
        fieldSets: [CONTACT_FIELDS],
        isVisible: props.isVisible,
        saveText: 'Done',
        title: `Who at ${legalOrganization.name} is working on this case?`,
        childrenBefore: (
          <p>
            Provide specific contact information to ensure the right person at {legalOrganization.name} is notified.
          </p>
        ),
        width: DEFAULT_MODAL_WIDTH,
      }}
    />
  );
}

export default NewOrganizationContactModal;
