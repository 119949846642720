import React, { Component, CSSProperties } from 'react';
import { Link } from 'react-router-dom';
import autoBindMethods from 'class-autobind-decorator';
import { inject, observer } from 'mobx-react';
import cx from 'classnames';

import { Col, Row } from 'antd';
import { RowProps } from 'antd/lib/row';

import { formatDate, getNameOrDefault } from '@mighty-justice/utils';

import { IListCase } from '../../../models/Case';
import { CLOSED_CASE_STATUSES, OPTION_KEYS } from '../../../utils/constants';
import StoresClass from '../../../stores/StoresClass';
import { getUrlForNewPath, INavigateProps } from '../../../utils/navigationUtils';
import navigationWrapper from '../../../utils/navigationWrapper';
import { formatOption } from '../../../utils/utils';
import { Icon, Pill } from '../../common';

import styles from './ScrollItem.module.less';

interface IProps extends INavigateProps {
  _case: IListCase;
  index: number;
  style: CSSProperties;
}

interface IInjected extends IProps {
  stores: StoresClass;
}

const rowProps: RowProps = { align: 'middle', justify: 'space-between' };

@inject('stores')
@autoBindMethods
@observer
class PortfolioListScrollCase extends Component<IProps> {
  private get injected () { return this.props as IInjected; }

  private get isClosedWithoutSettlement () {
    const { _case } = this.props
      , { CASE_CLOSED_PAYMENT_DISBURSED } = CLOSED_CASE_STATUSES
      ;

    return _case.status !== CASE_CLOSED_PAYMENT_DISBURSED;
  }

  private get rowClassName () {
    const { _case, params: { id: caseId } } = this.props
      , isSelected = _case.id === caseId
      ;

    return cx(styles.root, {
      [styles['active']]: isSelected,
      [styles['needs-update']]: _case.isOutOfDate,
    });
  }

  private get warningIcon () {
    const { _case: { has_warning } } = this.props;

    return has_warning ? <Icon.TimesCircle color='red' /> : <Icon.AlarmClock />;
  }

  private renderLawFirmAdditionalInfo () {
    const { _case } = this.props
      , { isOpen, updatedDaysAgo } = _case
      , statusPillType = this.isClosedWithoutSettlement ? 'error' : 'success'
      ;

    return (
      <>
        {_case.isOutOfDate && (
          <Col className={styles.icon}>
            {updatedDaysAgo && `${updatedDaysAgo}d`}
            <span className={styles.dot} />
          </Col>
        )}

        {!isOpen && (
          <Col>
            <Pill text='Closed' type={statusPillType} />
          </Col>
        )}
      </>
    );
  }

  public render () {
    const { _case, style } = this.props
      , { stores: { users: { isLawFirmUser } } } = this.injected
      , { isOutOfDate, has_warning, plaintiff, date_of_loss } = _case
      , showIcon = has_warning || isOutOfDate
      , linkToURL = getUrlForNewPath(`/portfolio/${_case.id}`)
      ;

    return (
      <Link to={linkToURL} key={_case.id}>
        <div key={_case.id} style={style} className='scroll-item'>
          <Row {...rowProps} className={this.rowClassName}>
            <Col>
              <div className={cx(styles.name, 'dd-privacy-mask')}>{getNameOrDefault(plaintiff)}</div>
              <div className={cx(styles.subtitle, 'dd-privacy-mask')}>
                DOB: {formatDate(plaintiff.birthdate)} | DOL: {formatDate(date_of_loss)}
              </div>
              {!isLawFirmUser && (
                <div className={styles.subtitle}>
                  Status: {formatOption(_case.status, OPTION_KEYS.CASE_STATUS)}
                </div>
              )}
            </Col>

            {isLawFirmUser && this.renderLawFirmAdditionalInfo()}

            {showIcon && !isLawFirmUser && (
              <Col className={styles.icon}>
                <span>{this.warningIcon}</span>
              </Col>
            )}
          </Row>
        </div>
      </Link>
    );
  }
}

export default navigationWrapper(PortfolioListScrollCase);
