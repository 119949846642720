/* istanbul ignore file */

export default function injectZendeskWidget (apiKey: string) {
  // Configure the widget
  const widgetSettingsScript = document.createElement('script');
  widgetSettingsScript.type = 'text/javascript';
  widgetSettingsScript.innerHTML = `
    window.zESettings = {
      webWidget: {
        position: { horizontal: 'left', vertical: 'bottom' },
        color: {
          theme: '#1E2832',
          launcher: '#1E2832',
          launcherText: '#FFFFFF',
          button: '#40C982',
          header: '#1E2832',
          articleLinks: '#40C982'
        }
      }
    };
  `;
  document.body.appendChild(widgetSettingsScript);

  // Inject / load the widget
  const widgetScript = document.createElement('script');
  widgetScript.id = 'ze-snippet';
  widgetScript.type = 'text/javascript';
  widgetScript.src = `https://static.zdassets.com/ekr/snippet.js?key=${apiKey}`;
  document.body.appendChild(widgetScript);
}
