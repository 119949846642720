import React, { Component } from 'react';
import cx from 'classnames';
import { inject, observer } from 'mobx-react';
import autoBindMethods from 'class-autobind-decorator';

import { Icon } from '../../../../common';
import AddDocumentButton from '../../tab-documents/common/AddDocumentButton';
import { IDetailCase } from '../../../../../models/Case';
import StoresClass from '../../../../../stores/StoresClass';
import { IBetaDocumentSubmitData } from '../../tab-documents/interfaces';

import styles from './UploadDocumentButton.module.less';

interface IProps {
  _case: IDetailCase;
  goBack?: () => void;
}

interface IInjected extends IProps {
  stores: StoresClass;
}

@inject('stores')
@autoBindMethods
@observer
class UploadDocumentButton extends Component<IProps> {
  private get injected () { return this.props as IInjected; }

  private async uploadDocuments (documents: IBetaDocumentSubmitData[]) {
    const { _case, goBack } = this.props
      , { stores: { documentsStore } } = this.injected
      ;

    documentsStore.isLoading.setTrue();
    await _case.bulkUploadDocuments(documents);
    await _case.fetchThreads();

    if (_case.userLien) {
      documentsStore.tableDataSource = _case.userLien.lienholderDocuments;
    }

    documentsStore.isLoading.setFalse();
    if (goBack) { goBack(); }
  }

  public render () {
    const { _case } = this.props;

    return (
      <div className={cx(styles.root, styles.override)}>
        <AddDocumentButton
          _case={_case}
          buttonProps={{ className: styles['upload-document'], id: 'btn-upload-request-documents' }}
          buttonText='Upload Documents'
          icon={Icon.Check}
          iconPosition='back'
          uploadDocuments={this.uploadDocuments}
        />
      </div>
    );
  }
}

export default UploadDocumentButton;
