import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import autoBindMethods from 'class-autobind-decorator';
import { observable } from 'mobx';

import SmartBool from '@mighty-justice/smart-bool';
import { FormModal } from '@mighty-justice/fields-ant';

import SelectOrganizationModal, { SelectOrganizationReturnData } from '../common/SelectOrganizationModal';
import StoresClass from '../../../../stores/StoresClass';
import { IDetailCase } from '../../../../models/Case';

import { DEFAULT_MODAL_WIDTH } from './constants';

import styles from './Modal.module.less';
import navigationWrapper from '../../../../utils/navigationWrapper';
import { INavigateProps } from '../../../../utils/navigationUtils';

interface IProps extends INavigateProps {
  isVisible: SmartBool
  _case: IDetailCase;
}

interface IInjected extends IProps {
  stores: StoresClass;
}

@inject('stores')
@observer
@autoBindMethods
class ChangeLawFirmModal extends Component<IProps> {
  @observable isConfirming = new SmartBool();
  @observable newLawFirmData: SelectOrganizationReturnData | null = null;

  private get injected () { return this.props as IInjected; }

  private async setLawFirmData (data: SelectOrganizationReturnData) {
    this.newLawFirmData = data;
    this.isConfirming.setTrue();
  }

  private async onConfirm () {
    const { _case, navigate, params: { id } } = this.props
      , { stores: { infiniteTableStore } } = this.injected
      ;

    if (!this.newLawFirmData) { throw new Error('An error occurred. Please refresh the page and try again'); }

    const newCaseId = await _case.lienholderChangeLawFirm(this.newLawFirmData.id);
    await infiniteTableStore.onCaseIdChange(newCaseId);
    this.isConfirming.setFalse();

    if (id !== newCaseId) {
      navigate(infiniteTableStore.firstCaseUrl);
    }
  }

  public render () {
    const { _case, isVisible } = this.props;

    return (
      <>
        {isVisible.isTrue && (
          <SelectOrganizationModal
            isVisible={isVisible}
            onSelect={this.setLawFirmData}
            type='lawFirm'
          />
        )}
        {this.newLawFirmData && (
          <FormModal
            childrenBefore={(
              <p>
                By adding {this.newLawFirmData.name} as a new law firm on this case, you are indicating a
                change in plaintiff representation. We will notify {_case.law_firm?.name} to confirm that they no longer
                represent <span className='dd-privacy-mask'>{_case.plaintiff.fullName}</span>.
              </p>
            )}
            className={styles.modal}
            fieldSets={[[]]}
            isVisible={this.isConfirming}
            onSave={this.onConfirm}
            saveText='Confirm'
            title='Confirm New Plaintiff Representation'
            width={DEFAULT_MODAL_WIDTH}
          />
        )}
      </>
    );
  }
}

export default navigationWrapper(ChangeLawFirmModal);
