import React, { Component } from 'react';
import autoBindMethods from 'class-autobind-decorator';
import { inject, observer } from 'mobx-react';
import cx from 'classnames';

import { ButtonProps } from 'antd/lib/button';
import { Divider } from 'antd';

import SmartBool from '@mighty-justice/smart-bool';

import StoresClass from '../../stores/StoresClass';
import { ClearFiltersButton, FiltersButton } from '../common';
import { INavigateProps } from '../../utils/navigationUtils';
import navigationWrapper from '../../utils/navigationWrapper';

import { getDropdownFacetSets } from './config/dropdownFacetSets';
import FacetsDropdownContainer from './FacetsDropdownContainer';
import { IFacetSet, IFacet } from './interfaces';
import { getFacetKey, numFacetsApplied } from './facetUtils';
import { getToggleFacets } from './config/toggleFacets';
import { getAllUserFacetSets } from './config';
import FacetInputWrapper from './FacetInputWrapper';

import styles from './FilterList.module.less';

interface IHooksProps {
  advancedFacetSets: IFacetSet[];
  isAdvancedFacetsVisible: SmartBool;
}

interface IProps extends IHooksProps, INavigateProps {}

interface IInjected extends IProps {
  stores: StoresClass;
}

@inject('stores')
@autoBindMethods
@observer
class FilterList extends Component<IProps> {
  private get injected () { return this.props as IInjected; }

  private get hasFilters (): boolean {
    const { stores } = this.injected;
    return Boolean(numFacetsApplied(
      getAllUserFacetSets(stores.users),
      stores.infiniteTableStore.filterQuery,
    ));
  }

  private get toggleFacets (): IFacet[] {
    const { stores } = this.injected
      , { isLawFirmUser, account } = stores.users
      , linkedContact = account?.linked_contact || undefined
      ;
    return getToggleFacets(isLawFirmUser, linkedContact);
  }

  private get buttonFacetConfig (): IFacetSet[] {
    const { stores: { users: { isLawFirmUser } } } = this.injected;
    return getDropdownFacetSets(isLawFirmUser);
  }

  private get filtersButtonProps (): ButtonProps & { count?: number } {
    const { isAdvancedFacetsVisible, advancedFacetSets } = this.props
      , { stores: { infiniteTableStore } } = this.injected
      , count = numFacetsApplied(advancedFacetSets, infiniteTableStore.filters)
      ;

    return {
      count,
      onClick: isAdvancedFacetsVisible.toggle,
      type: !!count ? 'primary' : 'default',
    };
  }

  private async resetAll () {
    const { navigate, params: { id }, searchParams: [, setQuery] } = this.props
      , { stores: { infiniteTableStore } } = this.injected;

    setQuery({});

    await infiniteTableStore.resetAll();

    if (id === 'null') {
      navigate(infiniteTableStore.firstCaseUrl);
    }
  }

  public render () {
    const { stores: { infiniteTableStore } } = this.injected
      , facetDropdownList = this.buttonFacetConfig
      ;

    return (
      <div className={cx(styles.root, styles.override)}>
        <FiltersButton {...this.filtersButtonProps} />
        <Divider type='vertical' className={styles['facet-divider']}/>
        <div className={styles['facets-bar']}>
          {this.toggleFacets.map(facet => (
            <FacetInputWrapper
              facet={facet}
              key={getFacetKey(facet)}
              tableStore={infiniteTableStore}
            />
          ))}
          <FacetsDropdownContainer facetDropdownList={facetDropdownList} tableStore={infiniteTableStore} />
          {this.hasFilters && <ClearFiltersButton onClick={this.resetAll} />}
        </div>
      </div>
    );
  }
}

export default navigationWrapper(FilterList);
