import React from 'react';
import cx from 'classnames';

import { Icon, ModalButton } from '../../../common';
import { treatmentStatusFields } from '../../common/fieldSets';
import { IDetailLien } from '../../../../models/Lien';

import styles from './UpdateTreatmentStatusButton.module.less';

interface IProps {
  lien: IDetailLien;
}

function UpdateTreatmentStatusButton (props: IProps) {
  return (
    <ModalButton
      buttonProps={{ className: cx(styles.root, styles.override) }}
      passThroughProps={{
        fieldSets: [treatmentStatusFields],
        model: props.lien,
        onSave: props.lien.updateTreatmentStatus,
        title: 'Update Treatment Status',
      }}
    >
      Update Now <Icon.Reply className={styles.icon} />
    </ModalButton>
  );
}

export default UpdateTreatmentStatusButton;
