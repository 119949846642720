import { getEnv, IAnyModelType } from 'mobx-state-tree';
import { Instance } from 'mobx-state-tree/dist/internal';

import UserStore from '../stores/UserStore';

import Client from './client';

export interface IStateTreeDependencies {
  client: Client,
  users: UserStore;
}

export const stateTreeDependencyGetters = (self: Instance<IAnyModelType>) => ({
  get users (): UserStore {
    return getEnv(self).users;
  },

  get client (): Client {
    return getEnv(self).client;
  },

  get baseEndpoint (): string {
    return self.users.isLawFirmUser ? 'escrow-agent' : 'lienholder';
  },
});
