/* eslint-disable max-classes-per-file */
import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import autoBindMethods from 'class-autobind-decorator';
import { find, get } from 'lodash';

import SmartBool from '@mighty-justice/smart-bool';

import { Form, notification } from 'antd';

import {
  CardCvcElement,
  CardElement,
  CardExpiryElement,
  CardNumberElement,
  Elements,
  ElementsConsumer,
} from '@stripe/react-stripe-js';
import { loadStripe, Stripe, StripeElements } from '@stripe/stripe-js';

import Auth from '../../../base-modules/auth';
import Client from '../../../base-modules/client';
import { IAccount } from '../../../models/User';
import { URLS } from '../../../utils/constants';
import { PRICE_BUCKETS } from '../common';
import { Environment } from '../../../base-modules/environment';
import StoresClass from '../../../stores/StoresClass';
import { getQuery, getUrl, INavigateProps, replaceUrlPath } from '../../../utils/navigationUtils';
import navigationWrapper from '../../../utils/navigationWrapper';

const style = {
  base: {
    '::placeholder': {
      backgroundColor: 'white',
      color: '#aab7c4',
    },
    'color': '#424770',
    'fontFamily': 'Source Code Pro, monospace',
    'fontSize': '16px',
    'letterSpacing': '0.025em',
  },
  invalid: {
    color: '#9e2146',
  },
};

interface IProps {
  getStripeFormRef: any;
  isLoading: SmartBool;
}

interface ICardFormProps extends IProps, INavigateProps {}

interface IFormProps extends ICardFormProps {
  elements: StripeElements | null;
  stripe: Stripe | null;
}

interface IBaseInjected extends IFormProps {
  auth: Auth;
  client: Client;
  stores: StoresClass;
}

@inject('stores', 'auth', 'client')
@autoBindMethods
@observer
class CardFormBase extends Component <IFormProps> {
  private get injected () { return this.props as IBaseInjected; }

  // NB: these no-covs are here because mocking stripe is more trouble than it's worth in the browser
  // istanbul ignore next
  private async updateLegalOrganizationWithToken (account: IAccount, token?: any) {
    const { auth, client } = this.injected
      , { navigate } = this.props
      , { selection } = getQuery()
      , priceBucket = find(PRICE_BUCKETS, { cases: selection })
      ;

    if (!token) {
      notification.error({ message: 'Payment method invalid!', description: 'Double check your info and try again' });
      return;
    }

    await client.update(
      `/legal-organizations/${account.registry_legal_organization.id}/create-stripe-user/`,
      { stripe_token: token, plan_type: get(priceBucket, 'planType', 'Pay per case') },
    );
    await auth.refreshAuthentication();
    notification.success({ message: 'Success!' });
    navigate(replaceUrlPath(getUrl(), URLS.PORTFOLIO_PAGE));
  }

  // istanbul ignore next
  public async handleSubmit (event: any) {
    event.preventDefault();
    this.props.isLoading.setTrue();

    const { client, stores } = this.injected
      , { stripe, elements } = this.props
      , { account } = stores.users
      ;

    if (stripe && client && account) {
      const card = elements && elements.getElement(CardElement);
      if (card) {
        const { token } = await stripe.createToken(card);
        await this.updateLegalOrganizationWithToken(account, token);
      }
    }
    else {
      this.props.isLoading.setFalse();
      throw new Error('Stripe form submitted without proper setup!');
    }
    this.props.isLoading.setFalse();
  }

  public render () {
    const { isLoading } = this.props
      , options = { disabled: isLoading.isTrue, style };

    return (
      <Form ref={this.props.getStripeFormRef} onFinish={this.handleSubmit}>
        <div>
          <label>
            Card number <CardNumberElement options={options} />
          </label>
          <label>
            Expiration date <CardExpiryElement options={options} />
          </label>
          <label>
            CVC <CardCvcElement options={options} />
          </label>
        </div>
      </Form>
    );
  }
}

const CardForm = navigationWrapper((props: ICardFormProps) => (
  <ElementsConsumer>
    {({ stripe, elements }) => (
      <CardFormBase
        elements={elements}
        stripe={stripe}
        {...props}
      />
    )}
  </ElementsConsumer>
));

interface IInjected extends IProps {
  environment: Environment;
}

@inject('environment')
@autoBindMethods
@observer
class CreditCardForm extends Component<IProps> {
  private get injected () { return this.props as IInjected; }

  public render () {
    const { isLoading, getStripeFormRef } = this.props
      , { stripePublicKey } = this.injected.environment;

    return (
      <div>
        <div className='Checkout'>
          <Elements stripe={loadStripe(stripePublicKey)}>
            <CardForm getStripeFormRef={getStripeFormRef} isLoading={isLoading} />
          </Elements>
        </div>
      </div>
    );
  }
}

export default CreditCardForm;
