// This file is for adding page components to the Router and nothing else

import React, { useEffect } from 'react';
import { Provider } from 'mobx-react';
import { Route, Navigate, Routes } from 'react-router-dom';

import AppLayout from './components/AppLayout';

// Page Components
import AccountDetailsPage from './components/page-lh-registration/AccountDetailsPage';
import CaseCreationPage from './components/page-lh-registration/CaseCreationPage';
import ChoosePlanPage from './components/page-upgrade/page-choose/ChoosePlanPage';
import DedupePage from './components/page-dedupe/DedupePage';
import DocumentPaymentPage from './components/page-document-payments/DocumentPaymentPage';
import DocumentRequestResponsePage from './components/page-document-response/DocumentRequestResponsePage';
import EmailRegistrationPage from './components/page-lh-registration/EmailRegistrationPage';
import EmbeddedCasePage from './components/page-embedded-case/EmbeddedCasePage';
import InvitePage from './components/page-invite/InvitePage';
import LoginPage from './components/page-login/LoginPage';
import ManualDedupePage from './components/page-manual-dedupe/ManualDedupePage';
import OverviewPage from './components/page-upgrade/page-overview/OverviewPage';
import PasswordResetPage from './components/page-password-reset/PasswordResetPage';
import PasswordResetRequestPage from './components/page-password-reset/PasswordResetRequestPage';
import PaymentPage from './components/page-upgrade/page-payment/PaymentPage';
import PortfolioPage from './components/page-portfolio/PortfolioPage';
import RegistrationCompletePage from './components/page-login/RegistrationCompletePage';
import RegistrationConfirmationPage from './components/page-lh-registration/RegistrationConfirmationPage';
import RegistrationPage from './components/page-login/RegistrationPage';
import RequestAlreadyFulfilledPage from './components/page-document-response/RequestAlreadyFulfilledPage';
import ResponseCompletePage from './components/page-document-response/ResponseCompletePage';
import ResponseTokenExpiredPage from './components/page-document-response/ResponseTokenExpiredPage';
import SettingsPage from './components/page-settings/SettingsPage';
import RegisterNowPage from './components/page-login/RegisterNowPage';
import SetupPage from './components/page-login/SetupPage';
import SharePage from './components/page-share/SharePage';
import StyleGuidePage from './components/page-style-guide/StyleGuidePage';
import BulkCaseUpload from './components/page-bulk-case-upload/BulkCaseUploadPage';

// Non-Page imports
import { Controller } from './base-modules/controller';
import { URLS } from './utils/constants';
import { getLoginURL, getUrl } from './utils/navigationUtils';
import { PrivateRoute } from './utils/routerUtils';

// Moving this above the page components angers the style gods
import './components/styles/app.less';

interface IProps {
  controller: Controller;
}

const App = (props: IProps) => {
  const { controller } = props
    , { stores: { infiniteTableStore, users }, auth, analytics } = controller
    , loginUrl = getLoginURL(getUrl())
    , detailUrl = infiniteTableStore.firstCaseUrl
    , [defaultRedirect, detailRedirect] = auth.isAuthenticated ? [URLS.PORTFOLIO_PAGE, detailUrl] : [loginUrl, loginUrl]
    ;

  useEffect(() => analytics.trackPage(), []);

  return (
    <Provider {...controller.provided}>
      <Routes>
        <Route path='/login' element={<LoginPage controller={controller} />} />
        <Route path='/setup' element={<SetupPage />} />
        <Route path='/invite' element={<PrivateRoute auth={auth} children={<InvitePage />} />} />
        <Route path='/register' element={<RegistrationPage />} />
        <Route path='/share/:shareableLinkId' element={<SharePage />} />
        <Route path='/register-now' element={<RegisterNowPage />} />
        <Route path='/bulk-case-upload' element={<BulkCaseUpload />} />

        <Route path='/register-lh' element={<AppLayout />}>
          <Route index element={<Navigate to='/register-lh/account' replace />} />
          <Route path='account' element={<EmailRegistrationPage />} />
          <Route path='cases' element={<CaseCreationPage />} />
          <Route path='details' element={<AccountDetailsPage />} />
          <Route path='confirmation' element={<RegistrationConfirmationPage />} />
        </Route>

        <Route path='/registration-complete' element={<RegistrationCompletePage />} />
        <Route path='/password/reset/confirm/:uid/:token' element={<PasswordResetPage />} />
        <Route path='/password-reset-request' element={<PasswordResetRequestPage />} />
        <Route path='/document-request'>
          <Route path='response' element={<DocumentRequestResponsePage />} />
          <Route path='response-complete' element={<ResponseCompletePage />} />
          <Route path='already-fulfilled' element={<RequestAlreadyFulfilledPage />} />
          <Route path='token-expired' element={<ResponseTokenExpiredPage />} />
        </Route>
        <Route path='/embedded/case/:id' element={<EmbeddedCasePage />} />
        <Route path='/style-guide' element={<StyleGuidePage />} />

        <Route path='/' element={<PrivateRoute auth={auth} children={<AppLayout />} />}>
          <Route index element={<Navigate to={detailRedirect} replace />} />
          <Route path='settings/:tab' element={<SettingsPage />} />
          <Route path='dedupe' element={users.isStaff ? <DedupePage /> : <Navigate to='/' replace />} />
          <Route path='manual-dedupe' element={users.isStaff ? <ManualDedupePage /> : <Navigate to='/' replace />} />
          <Route path='portfolio/:id' element={<PortfolioPage />} />
          <Route path='document-payment' element={<DocumentPaymentPage />} />
          <Route path='upgrade'>
            <Route path='choose' element={<ChoosePlanPage />} />
            <Route path='overview' element={<OverviewPage />} />
            <Route path='payment' element={<PaymentPage />} />
          </Route>
          <Route path='*' element={<Navigate to={detailRedirect} replace />} />
        </Route>
        <Route path='*' element={<Navigate to={defaultRedirect} replace />} />
      </Routes>
    </Provider>
  );
};

export default App;
