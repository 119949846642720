import React from 'react';

import { Alert as AntdAlert } from 'antd';
import { AlertProps } from 'antd/lib/alert';

import Icon from './Icon';

const ICONS: { [key: string]: React.ReactNode } = {
  error: <Icon.TimesCircle />,
  errorLarge: <Icon.TimesCircleLight />,
  info: <Icon.InfoCircle />,
  infoLarge: <Icon.InfoCircleLight />,
  success: <Icon.CheckCircle />,
  successLarge: <Icon.CheckCircleLight />,
  warning: <Icon.ExclamationCircle />,
  warningLarge: <Icon.ExclamationCircleLight />,
};

const defaultProps = {
  type: 'info',
};

function getIcon (props: AlertProps) {
  if (props.icon) { return props.icon; }
  const type = props.type || defaultProps.type;
  return props.description ? ICONS[`${type}Large`] : ICONS[type];
}

const Alert = (props: AlertProps) => (
  <AntdAlert
    closeText={!!props.closable ? <Icon.Times /> : null}
    icon={getIcon(props)}
    showIcon
    {...props}
  />
);

Alert.defaultProps = defaultProps;

export default Alert;
