import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import autoBindMethods from 'class-autobind-decorator';

import StoresClass from '../../../../stores/StoresClass';

import { IDefaultNotificationProps } from './DefaultMightyNotification';
import DefaultLawFirmToLienholderNotification from './DefaultLawFirmToLienholderNotification';

interface IInjected extends IDefaultNotificationProps {
  stores: StoresClass;
}

@inject('stores')
@autoBindMethods
@observer
class DocumentRequestNotification extends Component<IDefaultNotificationProps> {
  private get injected () {
    return this.props as IInjected;
  }

  private async onClick () {
    const { stores } = this.injected
      , { isVisible, notification, onClick } = this.props
      , caseId = notification.context.case_id
      ;

    onClick(notification);
    isVisible.setFalse();
    if (caseId) {
      await stores.infiniteTableStore.fetchDataWithOverrides({ id: caseId }, { needs_documents: 'true' });
    }
  }

  public render () {
    return (
      <DefaultLawFirmToLienholderNotification {...this.props} onClick={this.onClick} />
    );
  }
}

export default DocumentRequestNotification;
