import React from 'react';

import { LinkButton, Icon } from '../../../common';
import { IContact } from '../../../../models/Contact';

import styles from './DeleteContactButton.module.less';

interface IProps {
  contact: IContact;
  onDelete: (id: string) => Promise<void>;
}

function DeleteContactButton (props: IProps) {
  const { contact, onDelete } = props;

  if (!contact.canDelete) { return null; }

  async function onClick () {
    await onDelete(contact.id);
  }

  return (
    <LinkButton id='delete-contact' className={styles.root} onClick={onClick}>
      <span>Delete</span> <Icon.TrashAlt />
    </LinkButton>
  );
}

export default DeleteContactButton;
