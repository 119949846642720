import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import autoBindMethods from 'class-autobind-decorator';
import { get } from 'lodash';

import { Radio, Row } from 'antd';

import SmartBool from '@mighty-justice/smart-bool';

import StoresClass from '../../../stores/StoresClass';
import { BOOLEAN_STRING } from '../../../utils/constants';
import { INavigateProps, IQuery } from '../../../utils/navigationUtils';
import navigationWrapper from '../../../utils/navigationWrapper';

import styles from './PortfolioListScrollHeader.module.less';

interface IProps extends INavigateProps {
  isPopoverVisible: SmartBool;
}

const getFilterKey = (isLawFirmUser: boolean): string => (isLawFirmUser ? 'is_closed' : 'is_resolved');

export function getLabelForOpen (isLawFirmUser: boolean, filters: IQuery): string {
  const filterKey: string = getFilterKey(isLawFirmUser)
    , filteredClosed: boolean = get(filters, filterKey) === BOOLEAN_STRING.TRUE
    , recordType: string = isLawFirmUser ? 'Cases' : 'Liens'
    , currentStatus: string = filteredClosed ? (isLawFirmUser ? 'Closed' : 'Resolved') : 'Open';

  return `${currentStatus} ${recordType}`;
}

interface IInjected extends IProps {
  stores: StoresClass;
}

@inject('stores')
@autoBindMethods
@observer
class RadioOpen extends Component<IProps> {
  private get injected () {
    return this.props as IInjected;
  }

  private get filterKey () {
    const { stores: { users } } = this.injected;
    return getFilterKey(users.isLawFirmUser);
  }

  private async onChange (event: any) {
    const value = event.target.value
      , { navigate, params: { id } } = this.props
      , { stores: { infiniteTableStore }, isPopoverVisible } = this.injected;

    if (id === 'null') {
      await infiniteTableStore.onFilterChange({ [this.filterKey]: value });
      navigate(infiniteTableStore.firstCaseUrl);
    }
    else {
      infiniteTableStore.onFilterChange({ [this.filterKey]: value });
    }

    isPopoverVisible.setFalse();
  }

  public render () {
    const { stores: { infiniteTableStore, users: { isLawFirmUser } } } = this.injected
      , filterKey = this.filterKey
      , value = get(infiniteTableStore.filterQuery, filterKey);

    return (
      <Row>
        <Radio.Group onChange={this.onChange} value={value}>
          {[BOOLEAN_STRING.FALSE, BOOLEAN_STRING.TRUE].map(radioValue => (
            <Radio className={styles.radio} value={radioValue} key={radioValue}>
              {getLabelForOpen(isLawFirmUser, { [filterKey]: radioValue })}
            </Radio>
          ))}
        </Radio.Group>
      </Row>
    );
  }
}

export default navigationWrapper(RadioOpen);
