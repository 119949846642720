import React, { Component } from 'react';
import { observer } from 'mobx-react';
import autoBindMethods from 'class-autobind-decorator';

import { ISettingTabProps } from '../settingsUtils';

import ExternalSystemsCard from './ExternalSystemsCard';
import IntegrationsCard from './IntegrationsCard';

@autoBindMethods
@observer
class ExternalSystemsTab extends Component<ISettingTabProps> {
  public render () {
    return (
      <>
        <ExternalSystemsCard {...this.props} />
        <IntegrationsCard {...this.props} />
      </>
    );
  }
}

export default ExternalSystemsTab;
