import React from 'react';
import cx from 'classnames';

interface ISpacerProps {
  className?: string;
  small?: boolean;
}

const Spacer = (props: ISpacerProps) => (
  <div className={cx('spacer', { 'spacer-small': props.small }, props.className)} />
);

Spacer.displayName = 'Spacer';

export default Spacer;
