import React from 'react';
import { inject } from 'mobx-react';

import { Col, Row } from 'antd';

import StoresClass from '../../../../stores/StoresClass';
import { IDetailCase } from '../../../../models/Case';
import { Section } from '../../../common';

import AddOrganizationAndContacts from './AddOrganizationAndContacts';
import LawFirms from './LawFirms';
import Lienholders from './Lienholders';

import styles from './ContactsTab.module.less';

interface IProps {
  _case: IDetailCase;
  stores?: StoresClass;
}

function ContactsTab (props: IProps) {
  const { _case, stores } = props
    , isLawFirmUser = stores?.users.isLawFirmUser
    , showAddOrganizationButton = isLawFirmUser || !_case.law_firm
    ;

  return (
    <Section className={styles.root}>
      <Row justify='end' gutter={8}>
        <Col>
          {showAddOrganizationButton && <AddOrganizationAndContacts isLawFirm={!isLawFirmUser} _case={_case} />}
        </Col>
      </Row>
      <LawFirms _case={_case} />
      <Lienholders _case={_case} />
    </Section>
  );
}

const InjectedSection = inject('stores')(ContactsTab);
export default InjectedSection;
