import React, { Component } from 'react';

import { Col } from 'antd';

class NotificationsHeader extends Component {
  public render () {
    return (
      <div>
        <Col>
          <b>Notifications</b>
        </Col>
      </div>
    );
  }
}

export default NotificationsHeader;
