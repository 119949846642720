import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import autoBindMethods from 'class-autobind-decorator';
import cx from 'classnames';

import StoresClass from '../../stores/StoresClass';
import { Icon, IconButton } from '../common';

import styles from './DeleteButton.module.less';

interface IProps {
  caseKey: number;
}

interface IInjected extends IProps {
  stores: StoresClass;
}

@inject('stores')
@autoBindMethods
@observer
class DeleteButton extends Component<IProps> {
  private get injected () { return this.props as IInjected; }

  private deleteCase () {
    const { caseKey } = this.props
      , { stores: { caseFormStore } } = this.injected
      ;

    caseFormStore.removeKey(caseKey);
  }

  public render () {
    const { caseKey } = this.props
      , isFirstCase: boolean = caseKey === 0
      , className = cx(styles.delete, { [styles.noLabel]: isFirstCase });

    return (
      <div className={className}>
        <IconButton
          id={`case-${caseKey}-delete-button`}
          icon={Icon.Times}
          onClick={this.deleteCase}
        />
      </div>
    );
  }
}

export default DeleteButton;
