import { ACTIONS } from '../../page-dedupe/consts';
import { BOOLEAN_STRING } from '../../../utils/constants';
import { facetsToFacetSets } from '../facetUtils';
import { IFacet, IFacetSet } from '../interfaces';
import { joinOxfordStrings } from '../../../utils/utils';

export function getDedupeFacetSets (action: string, searchFields: string[], extraFacets: IFacet[]): IFacetSet[] {
  const defaultFacets: IFacet[] = [
    {
      field: 'search',
      label: `Search by ${joinOxfordStrings(searchFields, 'or')}`,
      type: 'string',
    },
    ...extraFacets,
    {
      field: 'related_to_compulsion_firm',
      type: 'optionSelect',
      editComponentProps: {
        options: [
          {
            label: 'Related to compulsion firm',
            value: BOOLEAN_STRING.TRUE,
          },
        ],
      },
    },
  ];

  if (action === ACTIONS.VERIFICATION) {
    return facetsToFacetSets([
      ...defaultFacets,
      { field: 'created_by_organization', type: 'string' },
    ]);
  }

  return facetsToFacetSets(defaultFacets);
}
