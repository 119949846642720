import React, { Component } from 'react';
import { observer } from 'mobx-react';
import autoBindMethods from 'class-autobind-decorator';

import { IDetailCase } from '../../../../../models/Case';
import { Icon } from '../../../../common';

import CaseDetail from './CaseDetail';

import styles from './CaseDetail.module.less';

interface IProps {
  _case: IDetailCase;
}

@autoBindMethods
@observer
class LienholderCaseDetail extends Component<IProps> {
  private get canEditCase () {
    const { _case } = this.props;

    return _case.is_editable_by_lienholder || false;
  }

  public render () {
    const { _case } = this.props
      , title = (
        <>
          {!this.canEditCase && <Icon.LockAltSolid/>}
          {' '}Case Details{' '}
          {!this.canEditCase && <span className={styles.subtitle}>Locked by law firm</span>}
        </>
      );

    return (
      <div>
        <CaseDetail
          _case={_case}
          isGuarded={!this.canEditCase}
          onSave={_case.update}
          title={title}
        />
      </div>
    );
  }
}

export default LienholderCaseDetail;
