import React, { Component } from 'react';
import { inject } from 'mobx-react';
import autoBindMethods from 'class-autobind-decorator';

import ConfirmationOnErrorModal, {
  IOnSave,
  IProps as IConfirmationModalProps
} from '../../../common/ConfirmationOnErrorModal';
import { ILegalOrganization } from '../../../../models/Party';
import { Spacer } from '../../../common';

import styles from './Modal.module.less';

export const CONTACT_ERROR = 'matches a verified domain';

interface IProps extends Omit<IConfirmationModalProps, 'doesErrorMatch' | 'errorModalProps' | 'FormComponent'> {
  dataPath?: string
  errorModalProps?: IConfirmationModalProps['errorModalProps'];
  FormComponent?: IConfirmationModalProps['FormComponent'];
  legalOrganization?: ILegalOrganization | null;
}

@inject('stores')
@autoBindMethods
class ConfirmationOnContactErrorModal extends Component<IProps> {
  private get passThroughProps () {
    const { passThroughProps, passThroughProps: { isVisible, onSuccess } } = this.props;

    // set onSuccess to close modal by default
    if (isVisible && !onSuccess) {
      return { ...passThroughProps, onSuccess: isVisible.setFalse };
    }

    return passThroughProps;
  }

  private getErrorModalChildren (_error: any, model: any) {
    const { dataPath, legalOrganization } = this.props
      , errorQuestion = 'Are you sure you want to add this contact?'
      , contactEmail = model?.email || (dataPath && model?.[dataPath]?.email)
    ;

    // istanbul ignore next
    if (!legalOrganization) {
      return errorQuestion;
    }

    return (
      <p>
        This contact's email ({contactEmail}) does not appear to be associated with {legalOrganization.name}
        { legalOrganization.website && ` (${legalOrganization.website})` }.
        <Spacer />
        {errorQuestion}
      </p>
    );
  }

  private doesErrorMatch (error: any) {
    const errors = error?.response?.data?.non_field_errors;

    return !!errors && errors[0].includes(CONTACT_ERROR);
  }

  // pass confirm_email instead of is_confirmed
  private async onConfirm (onSave: IOnSave, model: any) {
    const { dataPath } = this.props
      , confirmation = { confirm_email: true }
      // if dataPath exists, override the nested model to have the confirmation
      , dataOverride = dataPath ? { [dataPath]: { ...model?.[dataPath], ...confirmation } } : confirmation
      ;

    await onSave({ ...model, ...dataOverride });
  }

  public render () {
    return (
      <ConfirmationOnErrorModal
        confirmOnError
        doesErrorMatch={this.doesErrorMatch}
        getErrorModalChildren={this.getErrorModalChildren}
        onConfirm={this.onConfirm}
        { ...this.props }
        passThroughProps={this.passThroughProps}
        errorModalProps={{
          saveText: 'Add',
          title: 'Confirm Contact',
          className: styles.modal,
          ...this.props.errorModalProps,
        }}
      />
    );
  }
}

export default ConfirmationOnContactErrorModal;
