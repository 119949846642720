import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import autoBindMethods from 'class-autobind-decorator';
import cx from 'classnames';
import Helmet from 'react-helmet';

import { Col, Layout, Row } from 'antd';

import AppContent from '../AppContent';
import { ANT_FULL_COL_WIDTH } from '../../utils/constants';
import StoresClass from '../../stores/StoresClass';

import styles from './UpgradeLayout.module.less';
import UpgradeSteps from './UpgradeSteps';
import UpgradeTitle from './UpgradeTitle';

const { Footer } = Layout;

interface IProps {
  subtitle?: string;
  title: string;
  footer?: React.ReactNode;
}

interface IInjected extends IProps {
  stores: StoresClass;
}

@inject('stores')
@autoBindMethods
@observer
class UpgradeLayout extends Component<IProps> {
  private get injected () { return this.props as IInjected; }

  public render () {
    const { subtitle, title, children, footer } = this.props
      , { account } = this.injected.stores.users
      , isEligibleForTrial: boolean = !!account?.isEligibleForTrial
      , CONTENT_MARGIN = 2
      , CONTENT_SPAN = ANT_FULL_COL_WIDTH - (CONTENT_MARGIN * 2)
      ;

    return (
      <Layout id='app-layout'>
        <Layout.Content>
          <AppContent>
            <Layout className={cx('page page-floating page-style-card')}>
              <Helmet title={title} />
              <Layout.Header>
                {!isEligibleForTrial && <UpgradeSteps />}
                <UpgradeTitle subtitle={subtitle} title={title}/>
              </Layout.Header>
              <Layout.Content>
                <Row>
                  <Col
                    offset={CONTENT_MARGIN}
                    span={CONTENT_SPAN}
                  >
                    {children}
                  </Col>
                </Row>
                {footer && (
                  <Footer className={cx(styles.footer, styles.override)}>
                    {footer}
                  </Footer>
                )}
              </Layout.Content>
            </Layout>
          </AppContent>
        </Layout.Content>
      </Layout>
    );
  }
}

export default UpgradeLayout;
