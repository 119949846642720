import { getParent, Instance, types } from 'mobx-state-tree';

import { stateTreeDependencyGetters } from '../base-modules/state-tree-dependencies';

import { ILegalOrganizationWithContacts } from './Party';

export function doesContactNeedUpdate (contact: IContact | undefined) {
  if (!contact) { return false; }

  return contact.is_account_deactivated || contact.is_account_suspended;
}

export const Contact = types
  .model('Contact', {
    created_by: types.maybeNull(types.string),
    created_by_organization: types.maybeNull(types.string),
    email: types.string,
    first_name: types.string,
    id: types.identifier,
    is_account_deactivated: types.boolean,
    is_account_suspended: false,
    last_name: types.string,
    legal_organization: types.string,
  })
  .views(stateTreeDependencyGetters)
  .views(self => ({
    get needsUpdate (): boolean {
      return doesContactNeedUpdate(self as any);
    },
  }))
  .views(self => ({
    get canDelete (): boolean {
      const legalOrganization = getParent(self, 2) as ILegalOrganizationWithContacts;

      return self.needsUpdate || legalOrganization.canDeleteContact(self as IContact);
    },
    get fullName (): string {
      return `${self.first_name} ${self.last_name}`;
    }
  }))
  ;

export const CaseContact = types
  .model('CaseContact', {
    can_user_remove_contact: types.boolean,
    case: types.string,
    contact: types.string,
    id: types.identifier,
  })
  ;

export const LienContact = types
  .model('LienContact', {
    can_user_remove_contact: types.boolean,
    contact: types.string,
    id: types.identifier,
    lien: types.string,
  })
  ;

export interface IContact extends Instance<typeof Contact> {}
export interface ICaseContact extends Instance<typeof CaseContact> {}
export interface ILienContact extends Instance<typeof LienContact> {}
