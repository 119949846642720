import React, { Component } from 'react';
import autoBindMethods from 'class-autobind-decorator';
import { inject, observer } from 'mobx-react';

import { Row, Col } from 'antd';

import StoresClass from '../../../stores/StoresClass';
import { ISettingLegalOrganization } from '../../../models/User';
import { Card as AntdCard } from '../../common';

import MultiSelectLawFirmSearch from './MultiSelectLawFirmSearch';

interface IInjected {
  stores: StoresClass;
}

@inject('stores')
@autoBindMethods
@observer
class NotificationSubscriptionBlacklistForm extends Component<{}> {
  private get injected () { return this.props as IInjected; }

  private async onChange (value: ISettingLegalOrganization[]): Promise<void> {
    const { users, settings } = this.injected.stores
      , { account } = users
      , { upsertNotificationSubscriptionBlacklistSetting } = settings
      , organizationId = account?.mighty_organization
      , data = { blacklisted_legal_organizations: value.map(v => v.id), mighty_organization: organizationId }
      ;

    await upsertNotificationSubscriptionBlacklistSetting(data);
  }

  public render () {
    const { stores: { settings } } = this.injected
      , { notificationSubscriptionBlacklistSetting } = settings
      , value: ISettingLegalOrganization[] =
        notificationSubscriptionBlacklistSetting?.blacklisted_legal_organizations || []
      ;

    return (
      <Row>
        <Col>
          <AntdCard title='Do not send reminder emails to the following law firms'>
            <MultiSelectLawFirmSearch onChange={this.onChange} value={value} />
          </AntdCard>
        </Col>
      </Row>
    );
  }
}

export default NotificationSubscriptionBlacklistForm;
