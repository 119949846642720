import React, { Component } from 'react';
import { observer } from 'mobx-react';

import { fillInFieldSet } from '@mighty-justice/fields-ant';
import { getNameOrDefault } from '@mighty-justice/utils';

import CaseDedupeCard from '../common/dedupe/CaseDedupeCard';
import CaseDedupeSelectCard, { IProps as SelectCardProps } from '../common/dedupe/CaseDedupeSelectCard';
import { IManualDedupeCase, IManualDedupeLegalOrganization } from '../../models/ManualDedupeCase';
import { IPreviewSummary } from '../../models/ManualDedupeMatch';
import { URLS } from '../../utils/constants';
import { renderExternalKeys } from '../../utils/utils';

import styles from './ManualDedupePage.module.less';

interface IProps {
  _case: IManualDedupeCase | null;
  isCanonical?: boolean;
  onChange: (value: IManualDedupeCase | null) => void;
  previewSummary: IPreviewSummary | null;
  searchFilters: SelectCardProps['searchFilters'];
}

function overridePreviewField (previewSummary: IPreviewSummary, field: string, caseId: string) {
  const previewData = { ...previewSummary.data, [field]: { source_id: caseId, value: undefined } };

  return { ...previewSummary, data: previewData };
}

const CASE_FIELD_SET = fillInFieldSet([
  { field: 'law_firm', render: (law_firm: IManualDedupeLegalOrganization) => law_firm.name },
  { className: 'dd-privacy-mask', field: 'plaintiff', render: getNameOrDefault },
  { className: 'dd-privacy-mask', field: 'plaintiff.birthdate', label: 'date of birth' },
  { field: 'date_of_loss' },
  { field: 'created_by_organization_name' },
  { field: 'external_keys', render: renderExternalKeys },
]);

@observer
class CaseCard extends Component<IProps> {
  private get previewSummary () {
    const { _case, previewSummary } = this.props;

    if (!previewSummary || !_case) {
      return previewSummary;
    }

    const overriddenPreviewSummary = overridePreviewField(previewSummary, 'law_firm', _case.id);

    // only show external keys as kept if they exist
    // istanbul ignore next
    if (!_case.external_keys.length) {
      return overriddenPreviewSummary;
    }

    return overridePreviewField(overriddenPreviewSummary, 'external_keys', _case.id);
  }

  public render () {
    const { _case, isCanonical, onChange, searchFilters } = this.props
      , caseDescriptor = isCanonical ? 'canonical' : 'duplicate'
      , label = `Select ${caseDescriptor} case`
      , fieldOverrides = { label }
      ;

    if (_case) {
      return (
        <CaseDedupeCard
          _case={_case}
          className={styles.display}
          fieldSet={CASE_FIELD_SET}
          onCaseSelect={onChange}
          previewSummary={this.previewSummary}
          title={<p className={styles.title}>{caseDescriptor} case</p>}
        />
      );
    }

    return (
      <CaseDedupeSelectCard
        className={styles.select}
        endpoint={URLS.INTERNAL_MANUAL_CASE_DEDUPE}
        fieldOverrides={fieldOverrides}
        onCaseSelect={onChange}
        searchFilters={searchFilters}
      />
    );
  }
}

export default CaseCard;
