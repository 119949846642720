import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import autoBindMethods from 'class-autobind-decorator';
import { flatten, isString } from 'lodash';

import { Radio, Row } from 'antd';

import SmartBool from '@mighty-justice/smart-bool';

import StoresClass from '../../../stores/StoresClass';
import { IQuery, IQueryValue } from '../../../utils/navigationUtils';

import styles from './PortfolioListScrollHeader.module.less';

interface ISortConfig {
  ascLabel: string;
  descLabel: string;
  descOrder: [boolean, boolean];
  label: string;
  lienholderPrefix?: string;
}

interface ISortOption {
  label: string;
  value: string;
}

interface IProps {
  isPopoverVisible: SmartBool;
}

interface IInjected extends IProps {
  stores: StoresClass;
}

export const SORT_OPTION_CONFIGS: { [key: string]: ISortConfig } = {
  last_updated_at: {
    ascLabel: 'Oldest',
    descLabel: 'Newest',
    descOrder: [true, false],
    label: 'Status Update',
    lienholderPrefix: 'case_',
  },
  plaintiff__first_name: {
    ascLabel: 'A-Z',
    descLabel: 'Z-A',
    descOrder: [false, true],
    label: 'First Name',
  },
  plaintiff__last_name: {
    ascLabel: 'A-Z',
    descLabel: 'Z-A',
    descOrder: [false, true],
    label: 'Last Name',
  },
  created_at: {
    ascLabel: 'Oldest',
    descLabel: 'Newest',
    descOrder: [true, false],
    label: 'Date Added',
  },
}
  , SORT_OPTIONS: ISortOption[] = flatten(Object.entries(SORT_OPTION_CONFIGS).map(([baseValue, option]) => (
    option.descOrder.map(isDesc => {
      const descendingPrefix = isDesc ? '-' : ''
        , value = `${descendingPrefix}${baseValue},id`
        , label = `${option.label}: ${isDesc ? option.descLabel : option.ascLabel}`
        ;

      return { label, value };
    })
  )))
  ;

export function getLabelForSort (filters: IQuery): undefined | string {
  const value: IQueryValue = filters?.ordering
    , selectedOption = SORT_OPTIONS.find(option => option.value === value);

  return selectedOption ? selectedOption.label : undefined;
}

@inject('stores')
@autoBindMethods
@observer
class RadioSort extends Component<IProps> {
  private get injected () { return this.props as IInjected; }

  private get selectedOption (): string | undefined {
    const { stores: { infiniteTableStore } } = this.injected
      , { ordering } = infiniteTableStore.ignoredFilterQuery;

    // Only return string, not string[]
    return isString(ordering) ? ordering : undefined;
  }

  private onOptionSelect (event: any) {
    const selected = event.target.value
      , { stores: { infiniteTableStore } } = this.injected
      , { isPopoverVisible } = this.props;

    if (this.selectedOption !== selected) {
      infiniteTableStore.onFilterChange({ ordering: selected });
    }
    isPopoverVisible.setFalse();
  }

  public render () {
    return (
      <Row>
        <Radio.Group onChange={this.onOptionSelect} value={this.selectedOption}>
          {SORT_OPTIONS.map(option => (
            <Radio className={styles.radio} value={option.value} key={option.value}>
              {option.label}
            </Radio>
          ))}
        </Radio.Group>
      </Row>
    );
  }
}

export default RadioSort;
