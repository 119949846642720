import { flow, Instance, types, isAlive, destroy } from 'mobx-state-tree';

import { stateTreeDependencyGetters } from '../base-modules/state-tree-dependencies';
import { mergePathAndQuery } from '../utils/navigationUtils';

export const Tag = types
  .model('Tag', {
    id: types.identifier,
    name: types.string,
  });

export const CaseTag = types
  .model('CaseTag', {
    case: types.string,
    id: types.identifier,
    tag: Tag,
  });

export const LienTag = types
  .model('LienTag', {
    id: types.identifier,
    lien: types.string,
    tag: Tag,
  });


export const ModelWithTags = types
  .model({
    id: types.identifier,
    tagUrl: types.union(types.literal('cases-tags'), types.literal('lien-tags')),
    tags: types.optional(types.maybeNull(types.array(types.union(CaseTag, LienTag))), null),
  })
  .views(stateTreeDependencyGetters)
  .views(self => ({
    get isCase (): Boolean {
      return self.tagUrl.includes('cases-tags');
    },
  }))
  .actions(self => {
    const fetchTags = flow(function* () {
      const query = self.isCase ? { case: self.id } : { lien: self.id }
        , url = mergePathAndQuery(self.tagUrl, query)
        , response = yield self.client.get(url)
        ;
      if (!isAlive(self)) { return; } // Case/Lien may have been destroyed during yield

      if (self.tags === null) {
        self.tags = response.results;
      }
      else {
        self.tags.replace(response.results);
      }
    });

    const addExistingTag = flow(function* (tag: ITag) {
      const stakeId = self.id
        , newTag = yield self.client.create(self.tagUrl,
          (self.isCase ? { case: stakeId, tag } : { lien: stakeId, tag }))
        ;

      if (!isAlive(self)) { return; } // Case/Lien may have been destroyed during yield

      if (self.tags === null) {
        // istanbul ignore next
        yield fetchTags();
      }
      else {
        self.tags.push(newTag.data);
      }
    });

    const addNewTag = flow(function* (tagName: string) {
      const submitData = {
          mighty_organization: self.users.account?.mighty_organization,
          name: tagName,
        }
        , newTag = (yield self.client.create(`/tags/`, submitData)).data
        ;

      yield addExistingTag(newTag);
    });

    const deleteTag = flow(function* (tag: ICaseTag | ILienTag) {
      yield self.client.delete(`${self.tagUrl}${tag.id}`);
      destroy(tag);
    });

    return { fetchTags, addNewTag, deleteTag, addExistingTag };
  });

export interface ICaseTag extends Instance<typeof CaseTag> {}
export interface ILienTag extends Instance<typeof LienTag> {}
export interface ITag extends Instance<typeof Tag> {}
