import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import autoBindMethods from 'class-autobind-decorator';
import { Link } from 'react-router-dom';

import { Environment } from '../../base-modules/environment';
import Auth from '../../base-modules/auth';

import AppHeaderItem from './AppHeaderItem';

interface IInjected {
  auth: Auth;
  environment: Environment;
}

@inject('auth', 'environment')
@autoBindMethods
@observer
class Logo extends Component {
  private get injected () { return this.props as IInjected; }

  public render () {
    const { auth, environment } = this.injected
      , { isProd, registryEnv } = environment
      , { isAuthenticated } = auth
      , LogoAndName = (
        <>
          <div className='logo' />
          {!isProd && <small>{registryEnv}</small>}
        </>
      )
      ;

    if (isAuthenticated) {
      return (
        <AppHeaderItem>
          <Link className='brand' to='/'>
            {LogoAndName}
          </Link>
        </AppHeaderItem>
      );
    }

    return (
      <AppHeaderItem>
        <a className='brand' href='https://mighty.com'>
          {LogoAndName}
        </a>
      </AppHeaderItem>
    );
  }
}

export default Logo;
