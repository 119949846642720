import { insertIf } from '@mighty-justice/utils';

import {
  OPTION_KEYS,
  ORGANIZATION_OPTIONS,
  STILL_TREATING_OPTIONS,
} from '../../../utils/constants';

import { getPrefixedName } from '../../../utils/utils';
import { IEndpointCount } from '../inputs/FacetObjectSearch';
import { IFacet, IFacetSet } from '../interfaces';
import { facetsToFacetSets } from '../facetUtils';

function getTagsFacetIfUserHasTags (isLawFirmUser: boolean, hasTags: boolean): IFacet[] {
  const fetchField = isLawFirmUser ? 'tags' : 'liens__tags';

  return insertIf(hasTags, {
    editComponentProps: {
      renderOption: (option: IEndpointCount) => getPrefixedName(option, `${fetchField}__`),
    },
    field: fetchField,
    label: 'Tags',
    type: 'objectSearch',
  });
}

const LIENHOLDER_FACET: IFacet = {
  editComponentProps: {
    renderOption: (option: IEndpointCount) => getPrefixedName(option, 'liens__lienholder__')
  },
  field: 'liens__lienholder',
  isCollapsed: false,
  label: 'Lienholder',
  type: 'objectSearch',
}
  , LAW_FIRM_TREATMENT_STATUS_FACET: IFacet = {
    editComponentProps: {
      options: STILL_TREATING_OPTIONS,
      radio: true,
    },
    field: 'still_treating',
    label: 'Treatment Status',
    type: 'optionSelect',
  }
  , LIENHOLDER_TREATMENT_STATUS_FACET: IFacet = {
    editComponentProps: { optionType: OPTION_KEYS.TREATMENT_STATUS },
    field: 'plaintiff_treatment_status',
    label: 'Treatment Status',
    type: 'optionSelect',
  }
  , CONTACTS_FACET: IFacet = {
    editComponentProps: { renderOption: (option: IEndpointCount) => getPrefixedName(option, 'contacts__') },
    field: 'contacts',
    label: 'Law Firm Contact',
    type: 'objectSearch',
  }
  , CASE_STATUS_FACET: IFacet = {
    editComponentProps: { optionType: OPTION_KEYS.CASE_STATUS },
    field: 'status',
    label: 'Case Status',
    type: 'optionSelect',
  }
  , CASE_TYPE_FACET: IFacet = {
    editComponentProps: { optionType: OPTION_KEYS.CASE_TYPE },
    field: 'type',
    label: 'Case Type',
    type: 'optionSelect',
  }
  , DOL_FACET: IFacet = {
    field: 'date_of_loss',
    type: 'date',
  }
  , LAW_FIRM_FACET: IFacet = {
    editComponentProps: { renderOption: (option: IEndpointCount) => getPrefixedName(option, 'law_firm__') },
    field: 'law_firm',
    isCollapsed: false,
    label: 'Law Firm',
    type: 'objectSearch',
  }
  , VERIFICATION_STATUS_FACET: IFacet = {
    editComponentProps: { optionType: OPTION_KEYS.LIEN_REJECTED },
    field: 'verification_status',
    label: 'Lien Rejected',
    type: 'optionSelect',
  }
  , LAST_CASE_UPDATE_FACET: IFacet = {
    field: 'last_updated_at',
    label: 'Last Case Update',
    type: 'date',
  }
  , ADDED_BY_ORG_FACET: IFacet = {
    editComponentProps: { options: ORGANIZATION_OPTIONS },
    field: 'created_by_self',
    label: 'Added by Organization',
    type: 'optionSelect',
  }
  ;

export function getAdvancedFacetSets (isLawFirmUser: boolean, hasTags: boolean): IFacetSet[] {
  if (isLawFirmUser) {
    return facetsToFacetSets([
      LIENHOLDER_FACET,
      CONTACTS_FACET,
      CASE_STATUS_FACET,
      CASE_TYPE_FACET,
      DOL_FACET,
      LAW_FIRM_TREATMENT_STATUS_FACET,
      ...getTagsFacetIfUserHasTags(isLawFirmUser, hasTags),
    ]);
  }

  return facetsToFacetSets([
    LAW_FIRM_FACET,
    CONTACTS_FACET,
    VERIFICATION_STATUS_FACET,
    CASE_STATUS_FACET,
    CASE_TYPE_FACET,
    DOL_FACET,
    LAST_CASE_UPDATE_FACET,
    LIENHOLDER_TREATMENT_STATUS_FACET,
    ...getTagsFacetIfUserHasTags(isLawFirmUser, hasTags),
    ADDED_BY_ORG_FACET,
  ]);
}
