import { observable } from 'mobx';
import autoBindMethods from 'class-autobind-decorator';

import SmartBool from '@mighty-justice/smart-bool';
import { IModel } from '@mighty-justice/fields-ant/dist/props';

import Client from '../base-modules/client';
import StoresClass from './StoresClass';
import { ENDPOINTS } from '../components/page-settings/settingsUtils';
import {
  ILegalOrganizationExternalSystem,
  LegalOrganizationExternalSystem,
} from '../models/LegalOrganizationExternalSystem';

@autoBindMethods
class ExternalSystemsStore {
  client: Client;
  stores: StoresClass;

  @observable public isLoading = new SmartBool(true);
  @observable public externalSystems: ILegalOrganizationExternalSystem[] = [];

  public constructor (client: Client, stores: StoresClass) {
    this.client = client;
    this.stores = stores;
  }

  public async fetch (): Promise<void> {
    if (!this.stores.users.isLoggedIn) { return; }

    const response = await this.client.get(ENDPOINTS.LEGAL_ORGANIZATION_EXTERNAL_SYSTEMS);

    this.externalSystems = response.results.map((externalSystem: ILegalOrganizationExternalSystem) =>
      LegalOrganizationExternalSystem.create(externalSystem));
    this.isLoading.setFalse();
  }

  public async create (data: IModel) {
    const response = (await this.client.create(ENDPOINTS.LEGAL_ORGANIZATION_EXTERNAL_SYSTEMS, data)).data;
    this.externalSystems.push(LegalOrganizationExternalSystem.create(response));
  }

  public get allSystems () {
    return this.externalSystems;
  }

  public get integratedSystems () {
    return this.externalSystems.filter((system: ILegalOrganizationExternalSystem) => system.canIntegrate);
  }

  public get hasSystems () {
    return !!this.allSystems.length;
  }

  public get hasMultipleSystems () {
    return this.allSystems.length > 1;
  }
}

export default ExternalSystemsStore;
