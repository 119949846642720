import { ICaseThread } from '../../../../models/CaseThread';
import { CONVERSATION_CASE_THREAD_TYPES } from '../../../../utils/constants';

export function getActiveThreads (threads: ICaseThread[]) {
  return threads.filter((thread: ICaseThread) => {
    if (thread.type && CONVERSATION_CASE_THREAD_TYPES.includes(thread.type)) {
      return !thread.is_resolved;
    }
    return thread;
  });
}
