import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import autoBindMethods from 'class-autobind-decorator';

import SmartBool from '@mighty-justice/smart-bool';
import { FormModal } from '@mighty-justice/fields-ant';
import { getNameOrDefault } from '@mighty-justice/utils';

import { IDocumentFilterData } from '../../../../../interfaces';
import { IDetailCase } from '../../../../../models/Case';
import { IBetaDocument } from '../../../../../models/Document';
import StoresClass from '../../../../../stores/StoresClass';

interface IProps extends IDocumentFilterData {
  _case: IDetailCase;
  document: IBetaDocument;
  isVisible: SmartBool;
}

interface IInjected extends IProps {
  stores: StoresClass;
}

@inject('stores')
@autoBindMethods
@observer
class DeleteDocumentModal extends Component<IProps> {
  private get injected () { return this.props as IInjected; }

  private get deleteModalChildren () {
    const { _case: { plaintiff }, document } = this.props
      , { stores: { users: { isLawFirmUser } } } = this.injected
      , plaintiffName = getNameOrDefault(plaintiff)
      ;

    return (
      <div>
        <p>
          By deleting this document, you are removing access rights including viewing and downloading for all{' '}
          users with access to this {isLawFirmUser && !document.isCaseDocument ? 'lien' : 'case'}.
        </p>
        <p>
          Are you sure you want to delete the following document from{' '}
          <b className='dd-privacy-mask'>{plaintiffName}'s</b> case?
        </p>
        <a href={document.download_url}>{document.name}</a>
      </div>
    );
  }

  public render () {
    const { _case, document, filters, isDocumentsLoading, isVisible } = this.props;

    return (
      <FormModal
        children={this.deleteModalChildren}
        fieldSets={[]}
        isVisible={isVisible}
        onSave={() => isDocumentsLoading.until(_case.deleteDocument(document, filters))}
        saveText='Yes, Delete'
        title='Confirm Document Deletion'
      />
    );
  }
}

export default DeleteDocumentModal;
