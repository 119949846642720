import React from 'react';

import { Steps as AntdSteps } from 'antd';
import { StepsProps } from 'antd/lib/steps';

import Icon from './Icon';

const ICONS: { [key: string]: React.ReactNode } = {
  error: <Icon.TimesCircleLight />,
  finish: <Icon.CheckCircleLight />,
};

//
// <Steps.Step />
//
/* StepProps are not available in 3.12, they will be in 3.16. */
type StatusType = 'wait' | 'process' | 'finish' | 'error';

export interface IStepProps {
  description?: React.ReactNode;
  onClick?: React.MouseEventHandler<any>;
  status?: StatusType;
  title?: React.ReactNode;
}

const getIcon = (props: IStepProps) => (props.status && ICONS[props.status]) || null;

const StepsItem = (props: IStepProps) => (
  <AntdSteps.Step {...props} icon={getIcon(props)} />
);

//
// <Steps />
//
export interface IStepsProps extends StepsProps {
  children: React.ReactNode;
}

const defaultProps = {
  current: 0,
  direction: 'horizontal',
  initial: 0,
  labelPlacement: 'horizontal',
  progressDot: false,
  size: 'default',
  status: 'process',
};

const Steps = (props: IStepsProps) => (
  <AntdSteps {...props} />
);

Steps.defaultProps = defaultProps;
Steps.Step = StepsItem;

export default Steps;
