import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { pick } from 'lodash';

import { ColumnProps } from 'antd/lib/table';

import { EMPTY_FIELD } from '@mighty-justice/utils';

import { fieldSetsToColumns } from '@mighty-justice/fields-ant';
import { IModel } from '@mighty-justice/fields-ant/dist/props';

const noWrap = (value: React.ReactNode) => <span style={{ whiteSpace: 'nowrap' }}>{value}</span>;
const minWidthTitle = (title: React.ReactNode, minWidth: string | number) => <div style={{ minWidth }}>{title}</div>;

function createObserver (render: any) {
  interface IProps {
    index: number;
    record: any;
    value: any;
  }

  @observer
  class ObserverComponent extends Component<IProps> {
    public render () {
      const { index, record, value } = this.props;
      return <span>{render(value, record, index)}</span>;
    }
  }

  return ObserverComponent;
}

export interface IColumnProfile<T> extends Partial<ColumnProps<T>> {
  field: string;
  minWidth?: number | string;
  optionType?: string;
  shouldWrapTitle?: boolean;
  shouldWrapValue?: boolean;
  type?: string;
}

export function fillInColumnProfile (columnProfile: IColumnProfile<any>): ColumnProps<IModel> {
  const fieldConfig = pick(columnProfile, ['field', 'type', 'optionType'])
    , fieldsAntColumn = fieldSetsToColumns([[fieldConfig]]).pop() as ColumnProps<IModel>
    , filledIn: ColumnProps<IModel> = {
      ...fieldsAntColumn,
      ...columnProfile,
    }
    ;

  if (columnProfile.minWidth) {
    filledIn.title = minWidthTitle(filledIn.title, columnProfile.minWidth);
  }

  if (columnProfile.shouldWrapTitle === false) {
    filledIn.title = noWrap(filledIn.title);
  }

  if (columnProfile.shouldWrapValue === false) {
    const originalRender = filledIn.render;
    if (originalRender) {
      filledIn.render = (value: any, record: any, index: number) =>
        noWrap(originalRender(value, record, index));
    }
    else {
      // istanbul ignore next
      filledIn.render = noWrap;
    }
  }

  if (filledIn.render) {
    const ObserverComponent = createObserver(filledIn.render);
    filledIn.render = (value: unknown, record: unknown, index: number) =>
      <ObserverComponent value={value} record={record} index={index}/>;
  }

  return filledIn;
}

export const formatAddressHTML = (address?: any) => {
  if (!address) {
    return EMPTY_FIELD;
  }
  const { address1, address2, city, state, zip_code } = address
    // add space after address1 if address2 exists
    , space = (address2) ? ' ' : '';

  return (
    <span>
      {address1 || EMPTY_FIELD}{space}{address2},
      <br />
      {city || EMPTY_FIELD}, {state || EMPTY_FIELD} {zip_code || EMPTY_FIELD}
    </span>
  );
};
