import React from 'react';

import { Row } from 'antd';

import { Icon } from '../../../../common';

import styles from './DocumentsTable.module.less';

function DocumentsTableDragOverlay () {
  return (
    <div className={styles.dragOverlay}>
      <Icon.CloudUploadAlt className={styles.dragIcon} />
      <Row className={styles.dragText}>
        Drop documents here to upload
      </Row>
    </div>
  );
}

export default DocumentsTableDragOverlay;
