import React, { Component } from 'react';
import { observer } from 'mobx-react';
import autoBindMethods from 'class-autobind-decorator';
import cx from 'classnames';

import { Button, Icon } from '../../common';
import { ITableStoreAPI } from '../../../stores/ITableStoreAPI';
import { IFacetSet } from '../interfaces';
import { numFacetsApplied } from '../facetUtils';
import navigationWrapper from '../../../utils/navigationWrapper';
import { INavigateProps } from '../../../utils/navigationUtils';

interface IHooksProps {
  facetSets: IFacetSet[];
  tableStore: ITableStoreAPI;
}

interface IProps extends IHooksProps, INavigateProps {}

@autoBindMethods
@observer
class ResetAll extends Component<IProps> {
  private resetAll () {
    const { searchParams: [, setQuery], tableStore } = this.props;

    setQuery({});
    tableStore.resetAll();
  }

  public render () {
    const { facetSets, tableStore } = this.props
      , className: string = cx('facet-item', 'facet-reset-all')
      , numApplied: number = numFacetsApplied(facetSets, tableStore.filterQuery)
      , hasFilters: boolean = Boolean(numApplied)
      ;

    return (
      <div className={className}>
        <Button
          block
          disabled={!hasFilters || tableStore.isLoading}
          onClick={this.resetAll}
          type={hasFilters ? 'primary' : 'default'}
        >
          <Icon.TimesCircleSolid /> Clear {numApplied} Filters
        </Button>
      </div>
    );
  }
}

export default navigationWrapper(ResetAll);
